import React, { useEffect } from 'react'
import { useWatchableRef } from 'lib/hooks'

function NewStructureCreatedModal({ open, onClose, onSubmit, address }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const handleClose = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', handleClose)
    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div
      ref={modalRef.ref}
      className="modal dome-modal dome-modal-centered dome-modal-text-centered"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <i className="fa-thin fa-circle-check fa-3x dome-color-dark-green"></i>
            <div>
              <span className="dome-text-w500 dome-h2 dome-color-dark-green">{`${address} created!`}</span>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel"
              data-dismiss="modal"
              onClick={onClose}
              type="button"
            >
              close
            </button>
            <button className="dome-btn dome-btn-base dome-btn-aqua-stroke" type="button" onClick={onSubmit}>
              create another address
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewStructureCreatedModal
