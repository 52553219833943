import React from 'react'

import Preview from './preview'

class Document extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.setupTooltip({html: true})
  }

  componentDidUpdate() {
    this.tryToClickDownload()
    this.setupTooltip({html: true})
  }

  setupTooltip() {
    if (!this.getPriceTooltip())
      return

    $(this.refs.document).find(".price").tooltip()
  }

  tryToClickDownload() {
    if (!this.refs.download_link)
      return

    this.refs.download_link.click()
    this.setState({download_url: null})
  }

  getPrice(other_price) {
    const price = other_price || this.props.document.price
    return (!price || parseInt(price) == 0) ? "Free" : asMoney(price)
  }

  getPriceTooltip() {
    if ((this.props.document.price || 0) == (this.props.document.real_price || 0))
      return null

    return `This document is free for you. The real price is <strong>${this.getPrice(this.props.document.real_price)}</strong>`
  }

  showRequireConfirmation() {

    const message = `<div class="dome-d-flex dome-flex-column dome-gap12">
      <i class="fa-thin fa-circle-exclamation" style="color: #005C4F; font-size: 45px"></i>
      <div class="dome-color-dark-green dome-h2">will require confirmation</div>
      <div class="dome-color-darkest-grey">The document you have selected requires Management confirmation.</div>
    </div>`

    return new Promise((res, rej) => {
      const options = {
        title: "",
        html: true,
        text: message,
        showCancelButton: true,
        cancelButtonText: "cancel",
        confirmButtonText: "OK",
        customClass: "will-require-confirmation-modal",
      }
      swal(options, confirm => confirm ? res() : rej())
    })
  }

  showRequiresUserLogin() {
    const text = `<div class='building-require-login-modal-header'">
      <i class="fa-thin fa-arrow-right-to-bracket" style="color: #005C4F; font-size: 45px"></i>
      <p class="title">login to continue</p>
    </div>
    <div class='building-require-login-modal-body'>
      <p class="require-login-content">Only logged in users may purchase documents.</p>
    </div>
    `

    swal({
      title: '',
      text: text,
      html: true,
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: "log in / sign up",
      confirmButtonColor: '#005C4F',
      closeOnConfirm: false,
      customClass: 'building-require-login-modal',
    }, confirm => {
      if (!confirm)
        return

      if (!inIframe())
        return (window.location = 'https://www.boardpackager.com/users/login')

      window.open('https://www.boardpackager.com/users/login', "_blank").focus()
    })
  }

  showRequireCaptcha() {
    const text = `<div class='building-captcha-modal-dialog'">
      <i class="fa-thin fa-shield-check" style="color: #005C4F; font-size: 45px"></i>
      <p class="title">confirm to continue</p>
    </div>
    <div style="display: flex;justify-content: center;" class="g-recaptcha" id="store-docs-showr-recaptcha"></div>`

    swal({
      title: null,
      text: text,
      html: true,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'cancel',
      customClass: 'building-captcha-modal'
    })

    const load_captcha = () => {
      grecaptcha.render("store-docs-showr-recaptcha", {
        sitekey: this.props.site_key,
        callback: token => {
          swal.close()
          this.setState({show_preview: true})
        }
      })
    }

    setTimeout(load_captcha, 750)
  }

  cartError(errors) {
    if (!Array.isArray(errors))
      errors = [errors]

    const lines = []
    lines.push("<small>This document could not be added to your cart for the following reasons:</small>")

    if (errors.length == 1)
      lines.push(errors[0])
    else
      lines.push(`<ol>${errors.map(stmt => `<li>${stmt}</li>`)}</ol>`)

    const swal_options = {
      title: "Cart Error",
      html: true,
      text: `<div id="store-documents-checkout-error">${lines.map(line => `<p>${line}</p>`).join("<br />")}</div>`,
      type: "error"
    }

    swal(swal_options)
  }

  addToLibrary() {
    const data = { building_id: this.props.building_id }
    $.ajax({
      url: `/store_documents/${this.props.document.id}/add_to_library`,
      type: "post",
      dataType: "json",
      data: hashToPostQueryString(data),
      success: res => this.props[res.requested ? "addToRequests" : "addToLibrary"]()
    })
  }

  addToCart() {
    const data = {
      item_id: this.props.document.id,
      building_id: this.props.building_id
    }

    $.ajax({
      url: `/shopping_cart_items`,
      type: "post",
      dataType: "json",
      data: hashToPostQueryString(data),
      success: () => {
        this.props.addToCart()
      },
      error: xhr => {
        try {
          const json = JSON.parse(xhr.responseText) || {}
          this.cartError(json.error || "The was a server problem. Please try again later.")
        }
        catch(e) {
          this.cartError("The was a server problem. Please try again later.")
        }
      }
    })
  }

  download() {
    $.ajax({
      url: `/store_documents/${this.props.document.id}/download_url`,
      success: res => this.setState({download_url: res.url})
    })
  }

  updatedAt() {
    const date = new Date(this.props.document.updated_at)
    return <div className="updated">
      {date.toLocaleDateString()}
    </div>
  }

  render() {
    return <div className="building-page-document" ref="document">
      <div className="label">
        <p>{this.props.document.label}</p>
        {this.updatedAt()}
      </div>
      <div className="price has-tooltip" data-html="true" title={this.getPriceTooltip()}>
        <span>{this.getPrice()}</span>
        {this.getPriceTooltip() ? <span className="asterisk">*</span> : null}
      </div>
      <div className="button">
        {this.renderDownloadLink()}
        {this.renderButton()}
      </div>
      {this.renderPreview()}
    </div>
  }

  renderPreview() {
    if (!this.state.show_preview)
      return

    return <Preview document={this.props.document}
              current_user={this.props.current_user}
              clickAddToCart={() => this.clickAddToCart()}
              onClose={() => this.setState({show_preview: null})} />
  }

  renderDownloadLink() {
    if (!this.state.download_url)
      return

    return <a href={this.state.download_url} download={true} ref="download_link"></a>
  }

  renderButton() {
    if (this.props.document.in_library)
      return this.renderInLibrary()

    // Already in Cart
    if (this.props.document.in_cart)
      return this.renderInCart()

    if (this.props.document.awaiting_confirmation)
      return this.renderAwaitingConfirmation()

    // We must pay for file
    if (this.props.document.price != 0)
      return this.renderAddToCart()

    if (this.props.document.need_to_confirm)
      return this.renderAddToLibrary()

    // We are logged in
    if (this.props.current_user)
      return this.renderAddToLibrary()

    return this.renderDownload()
  }

  renderRequiresLogin() {
    return <button className="dome-btn dome-btn-base dome-btn-disabled" disabled={true}>
      <span>requires login</span>
    </button>
  }

  renderAwaitingConfirmation() {
    return <button className="dome-btn dome-btn-base dome-btn-disabled" disabled={true}>
      requested
    </button>
  }

  renderInCart() {
    return <button className="dome-btn dome-btn-base dome-btn-disabled" disabled={true}>
      <span>in cart</span>
    </button>
  }

  renderInLibrary() {
    const isFree = this.props.document.price == 0
    const label = isFree ? "in library" : "purchased"
    const classes = ['dome-btn']
    isFree ? classes.push('dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right') : classes.push('dome-btn-base dome-btn-grey-stroke')
    const click = () => this.setState({show_preview: true})

    return <button className={classes.join(' ')} onClick={click}>
      {label}
    </button>
  }

  renderDownload() {
    const click = () => {
      if (!this.props.document.need_to_confirm)
        return this.showRequireCaptcha()

      this.showRequireConfirmation().then(() => this.showRequireCaptcha())
    }

    return <button className="dome-btn btn-base btn-link preview-link-btn" onClick={click}>
      <i className="fa-light fa-magnifying-glass" />
      <span>preview</span>
    </button>
  }

  clickAddToCart() {
    if (!this.props.current_user)
      return this.showRequiresUserLogin()

    if (!this.props.document.need_to_confirm)
      return this.addToCart()

    this.showRequireConfirmation().then(() => this.addToCart())
  }

  renderAddToCart() {
    const click = () => this.clickAddToCart()

    return <button className="dome-btn btn-base btn-green-stroke" onClick={click}>
      add to cart
    </button>
  }

  renderAddToLibrary() {
    const click = () => {
      if (!this.props.current_user)
        return this.showRequiresUserLogin()

      if (!this.props.document.need_to_confirm)
        return this.addToLibrary()

      this.showRequireConfirmation().then(() => this.addToLibrary())
    }

    return <button className="dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right" onClick={click}>
      add to library
    </button>
  }
}

export default Document
