import React from 'react'

class Enable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  notDirty() {
    return typeof(this.state.enabled) == "undefined" || this.state.enabled == null
  }

  updateSelect(event) {
    this.setState({enabled: event.target.value})
  }

  save() {
    if (this.notDirty())
      return

    const data = {
      building: {
        has_questionnaires: this.state.enabled
      },
      _method: "put"
    }

    $.ajax({
      url: `/buildings/${this.props.building_id}.json`,
      method: "put",
      data: data,
      success: data => {
        $("#collapseQuestionnaires .hidden-on-disabled")[this.state.enabled == 1 ? "removeClass" : "addClass"]("hidden")
        this.setState({enabled: null})
      }
    })
  }

  renderSelect() {
    return <select defaultValue={this.props.enabled ? 1 : 0} onChange={e => this.updateSelect(e)}>
      <option value={1}>Yes</option>
      <option value={0}>No</option>
    </select>
  }

  renderButton() {
    const disabled = this.notDirty()

    const classes = ["btn", "btn-success"]
    if (disabled)
      classes.push("inactive")

    return <button className={classes.join(" ")} disabled={disabled} onClick={() => this.save()}>Save</button>
  }

  renderBody() {
    return <div className="panel-body">
      <div className="enable-questionnaires">
        <span>Enable Questionnaires for this Building?</span>
        {this.renderSelect()}
        {this.renderButton()}
      </div>
    </div>
  }

  render() {
    return <div className="panel panel-default building-questionnaire-enabled">
      {this.renderBody()}
    </div>
  }
}

export default Enable