const OFFSET_PREVIOUS_ELEMENT = 2
const horizontalDimensions = ['x', 'width']
const verticalDimensions = ['y', 'height']

export const uniqueComponentKey = (type, id, extra) => `${type}.${id}` + (extra ? `.${extra}` : '')
export const splitComponentKey = (key) => (Array.isArray(key) ? key : key.split('.'))
export const reverseUniqueComponentKey = (key) => {
  const [type, id, subtype] = splitComponentKey(key)
  return { type, id, subtype }
}

export const isValidInput = (inputValue) => {
  const validNumber = /^[0-9]\d*(\.\d+)?$/.test(inputValue)
  return validNumber
}

export const isSameRect = (rectA, rectB) =>
  ['x', 'y', 'width', 'height'].every((dimension) => rectA[dimension] == rectB[dimension])

export const convertToPixels = (percent, maxPixels) => (+percent * maxPixels) / 100

export const convertToPerc = (pixels, maxPixels) => (pixels * 100) / maxPixels

export const chooseMaxPixels = (dimension, width, height) => (horizontalDimensions.includes(dimension) ? width : height)

export const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const calNewRectDuplicateEl = ({rect,page}) => {
  // determine if new duplicate elements are overflowing at the bottom of the page
  let isOverBottomPage = false
  const selectedEls = document.getElementsByClassName('draw-area-selected')
  const drawAreaEl = document.getElementsByClassName('draw-area-component')

  for (let selectedEl of selectedEls) {
    const offsetYnewElement =
      selectedEl.offsetHeight + OFFSET_PREVIOUS_ELEMENT + selectedEl.getBoundingClientRect().bottom
    if (offsetYnewElement > drawAreaEl[page].getBoundingClientRect().bottom || offsetYnewElement > window.innerHeight) {
      isOverBottomPage = true
      break
    }
  }
  const newY = isOverBottomPage ? -(OFFSET_PREVIOUS_ELEMENT + rect.height) : OFFSET_PREVIOUS_ELEMENT + rect.height

  return {...rect, y: rect.y + newY}
}
