import { round } from 'lodash'

const rectWithinRect = (rectA, rectB) => {
  return Math.round(rectA.width) <= Math.round(rectB.width) && Math.round(rectA.height) <= Math.round(rectB.height)
}

const generateElementClone = (el, { oneLine, width }) => {
  const clone = el.cloneNode(true)

  clone.style.width = width + "px"
  clone.style.position = 'absolute'
  clone.style.display = "inline-block"
  clone.style.visibility = "hidden"
  clone.style.height = "auto"

  if (oneLine) {
    clone.style.width = "auto"
    clone.style.whiteSpace = "nowrap"
  }

  return clone
}

const getStep = fontSize => {
  if (fontSize < 14)
    return 0.5

  if (fontSize < 20)
    return 1

  if (fontSize < 40)
    return 2.5

  if (fontSize < 60)
    return 4

  if (fontSize < 80)
    return 6

  return 10
}

async function checkElementWithinTarget(element, target) {
  return new Promise((res, rej) => {
    requestAnimationFrame(() => {
      rectWithinRect(element.getBoundingClientRect(), target) ? res() : rej()
    })
  })
}

async function iterate(element, target, options = {}) {
  const minFont = options.minFont || 8
  let fontSize = options.defaultSize

  while(true) {
    element.style.fontSize = `${fontSize}px`

    try {
      await checkElementWithinTarget(element, target)
      return round(fontSize, 1)
    } catch(e) {
      if (fontSize <= minFont)
        return round(Math.max(fontSize, minFont), 1)

      fontSize -= getStep(fontSize)
    }
  }
}

async function refreshPage() {
  return new Promise((res) => requestAnimationFrame(res))
}

function scaleRect(rect, scale) {
  return { ...rect, width: rect.width * scale, height: rect.height * scale }
}

async function findAppropriateSize(element, options = {}) {
  let target = element.getBoundingClientRect()
  target = scaleRect(target, 1 / (options.ratio || 1))

  const cloned = generateElementClone(element, { options, width: target.width })
  element.parentNode.append(cloned)

  const fontSize = await iterate(cloned, target, options)
  cloned.remove()

  return fontSize
}

export default findAppropriateSize
