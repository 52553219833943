import { createSlice, configureStore, createAsyncThunk, createListenerMiddleware  } from '@reduxjs/toolkit'
import { getUnitsForStructure, getPathValues } from './api'
import { compact, intersection, isEmpty, omit, pick } from 'lodash'

import { asArray } from 'lib/utilities'
import { metadataByPath } from 'lib/utilities/form'

export const fetchUnits = createAsyncThunk('fetchUnits', async ({building_id, structure_id}, {rejectWithValue}) => {
  try {
    const data = await getUnitsForStructure(building_id, structure_id)
    return data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const fetchCurrentPathsValues = createAsyncThunk('fetchCurrentPathValue', async ({building_id, structure_id, unit, paths}, { rejectWithValue}) => {
  try {
    const data = await getPathValues(paths, { building_id, structure_id, unit })
    return data
  } catch(error) {
    return rejectWithValue(error)
  }
})

async function internalValueReset(dispatch, options) {
  const { values, building } = options
  let paths = options.paths

  paths ||= Object.keys(values)
  if (!building || isEmpty(paths))
    return


  await dispatch(fetchCurrentPathsValues({
    paths,
    building_id: building.id,
    structure_id: options.structure?.id,
    unit: options.unit
  }))
}

function internalPathsRelateCorrectlyToValues(dispatch, { metadata, values, unit}) {
  const possibleAssociations = compact([ "building", "structure", unit ? "unit" : null])
  const paths = Object.keys(values).filter(path => {
    const field = metadataByPath(metadata, path)
    return intersection(field.associations, possibleAssociations).length > 0
  })

  dispatch(prunePaths(paths))
}

const initialState = {
  metadata: {},

  building: null,
  building_structures: null,
  building_units: null,
  selected_structure: null,
  selected_unit: null,

  current_building: null,
  current_structure: null,
  current_unit: null,

  values: {},
}

const setAssociations = (metadata, association = []) => {
  for (let field_id in metadata) {
    const field = metadata[field_id]
    field.association = asArray(field.association || association)

    if (field.fields)
      setAssociations(field.fields, field.association)
  }
}


const findDefaultStructure = structures => (structures || []).filter(structure => structure.default)[0]

const slice = createSlice({
  name: 'default',
  initialState,
  reducers: {
    setBuilding: (state, { payload: { building, building_structures, as_current } }) => {
      state.building = building
      state.building_structures = building_structures?.map(structure => structure.default ? { ...structure, name: "( Default )"} : structure)

      const defaultStructure = findDefaultStructure(state.building_structures)
      state.selected_structure = defaultStructure?.id

      if (as_current) {
        state.current_building = building
        state.current_structure = defaultStructure
        state.current_unit = null
      }
    },

    setStructure(state, { payload: { selected_structure }}) {
      const defaultStructure = (state.building_structures || []).filter(structure => structure.default)[0]
      state.selected_structure = selected_structure || defaultStructure?.id
    },

    setUnits(state, { payload: units }) {
      state.building_units = units
    },

    setUnit(state, { payload: unit}) {
      state.selected_unit = unit
    },

    setMetadata: (state, { payload: metadata}) => {
      setAssociations(metadata)
      state.metadata = metadata
    },

    setCurrent: (state, { payload: { building, structure, unit }}) => {
      state.current_building = building
      state.current_structure = structure
      state.current_unit = unit
    }, 

    prunePaths: (state, { payload: paths }) => {
      state.values = pick(state.values, paths)
    },

    addPath: (state, { payload: path }) => {
      state.values = { ...state.values, [path]: state.values[path] }
    },

    removePath: (state, { payload: path}) => {
      state.values = omit(state.values, [ path ])
    },

    updateValues: (state, { payload: values}) => {
      values = pick(values, Object.keys(state.values))
      state.values = { ...state.values, ...values }
    },

    clearValues: (state) => { state.values = {} }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnits.fulfilled, ( state, { payload: units }) => {
      state.building_units = units
    })
    .addCase(fetchCurrentPathsValues.fulfilled, ( state, {payload: pathmap}) => {
      state.values = { ...state.values, ...pathmap }
    })
  }
})


const { prunePaths } = slice.actions
export const { setBuilding, setStructure, setUnits, setUnit, setMetadata, setCurrent, addPath, removePath, updateValues, clearValues } = slice.actions

const listener = createListenerMiddleware()
listener.startListening({
  actionCreator: setStructure,
  effect: async ({payload: {selected_structure: id}}, listenerApi) => {
    const { building } = listenerApi.getState()
    await listenerApi.dispatch(fetchUnits({building_id: building.id, structure_id: id}))
  }
})

listener.startListening({
  actionCreator: setBuilding,
  effect: async ({payload: {building, building_structures}}, listenerApi) => {
    if (building_structures.length > 1)
      return

    await listenerApi.dispatch(fetchUnits({building_id: building.id, structure_id: building_structures[0].id}))
  }
})

listener.startListening({
  actionCreator: addPath,
  effect: async ({payload: path}, listenerApi) => {
    const { current_building: building, current_structure: structure, current_unit: unit } = listenerApi.getState()
    await internalValueReset(listenerApi.dispatch, { paths: [path], building, structure, unit })
  }
})

listener.startListening({
  actionCreator: setCurrent,
  effect: async({payload: { building, structure, unit }}, listenerApi) => {
    const { values } = listenerApi.getState()
    await internalValueReset(listenerApi.dispatch, { building, structure, unit, values })
  }
})

listener.startListening({
  actionCreator: setBuilding,
  effect: async({payload: { building, building_structures, as_current }}, listenerApi) => {
    if (!as_current) return

    const { values } = listenerApi.getState()
    const structure = findDefaultStructure(building_structures) 
    await internalValueReset(listenerApi.dispatch, { building, structure, values })
  }
})

listener.startListening({
  actionCreator: setCurrent,
  effect: ({payload: { unit }}, listenerApi) => {
    const { metadata, values } = listenerApi.getState()
    internalPathsRelateCorrectlyToValues(listenerApi.dispatch, { metadata, values, unit})
  }
})

listener.startListening({
  actionCreator: setBuilding,
  effect: ({payload: { as_current }}, listenerApi) => {
    if (!as_current) return

    const { metadata, values } = listenerApi.getState()
    internalPathsRelateCorrectlyToValues(listenerApi.dispatch, { metadata, values})
  }
})

export const store = configureStore({
  reducer: slice.reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listener.middleware)
})