import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { compact, pick, fill, mapKeys } from 'lodash'

import { asArray } from 'lib/utilities'
import { insertArrayIntoPathMap, extractArrayFromPathMap } from 'lib/utilities/form'
import { useMergedFields, useMappedFieldIds, save, updateValues } from '../field'
import { usePermissionsChecks } from '..'

function useSignBlocked() {
  const isSigned = useSelector(({form_signatures: state}) => Object.keys(state.signed_signatures).length > 0)
  const isSigning = useSelector(({form_signatures: state}) => !!state.signing_as)

  return isSigned || isSigning
}


function getFieldValue(field_id) {
  const field = useMergedFields([field_id])[field_id]
  const mappedId = useMappedFieldIds([field_id])[field_id]

  return useSelector(({form_fields: state}) => {
    if (field.multiple)
      return extractArrayFromPathMap(state.values, mappedId)
    
    return state.values[mappedId]
  }, shallowEqual)
}

function usePersist(path) {
  const field = useMergedFields([path])[path]
  const dispatch = useDispatch()
  const instance_id = useSelector(({form: state}) => state.form.id)
  
  return useCallback(update => {
    const updates = {}
    if (field.multiple && Array.isArray(update)) 
      insertArrayIntoPathMap(path, update, updates)
    else
      updates[path] = update 

    save({instance_id, updates }).then(() => dispatch(updateValues(updates)))
  }, [dispatch, field, path, instance_id])
}

function CheckboxPosition({ frozen, field_position, thumbnail }) {
  const { id, idx, edit_on_page: editOnPage } = field_position
  const { path, value } = editOnPage

  const field = useMergedFields([path])[path]
  const { multiple } = field
  const currentValue = getFieldValue(path)
  const signBlocked = useSignBlocked()
  const permissions = usePermissionsChecks('form:edit')
  const persist = usePersist(path)

  const isSet = useMemo(() => multiple ? asArray(currentValue).includes(value) : currentValue == value, [currentValue, value, multiple])

  const locked = frozen || !permissions['form:edit'] || signBlocked
  const ratio = useSelector(({form: state}) => thumbnail ? state.thumbnailRatio : state.ratio)
  
  const handleClick = useCallback(() => {
    if (locked) 
      return

    // For the sake of clarity
    const notSet = !isSet
    const single = !multiple
    
    // Remember, we are reversing course.
    if (isSet && multiple)
      return persist(asArray(currentValue).filter(item => item != value))
    if (isSet && single)
      return persist(currentValue == value ? null : currentValue)
    if (notSet && multiple)
      return persist(asArray(currentValue).concat([value]))
    if (notSet && single)
      return persist(value)
  }, [persist, currentValue, value, isSet, locked, multiple])

  const classes = compact([
    "position",
    "edit-on-page",
    "edit-on-page-checkbox",
    isSet ? "checked" : null,
    locked ? null : 'unlocked'
  ])

  const style = {}
  style.fontSize = (parseInt(field_position.font_size) * ratio) + "px"

  return <div style={style} className={classes.join(" ")} onClick={handleClick}>
    { isSet ? "X" : "" }
  </div>
}

function EditInPlace(props) {
  const { field_position } = props || {}
  const { edit_on_page: editOnPage } = field_position || {}
  const { type } = editOnPage || {}

  switch(type) {
  case 'checkbox':
    return <CheckboxPosition {...props} />
  }
}

export default EditInPlace