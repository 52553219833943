import React, { useCallback, useState, useMemo } from 'react'
import { useClasses } from 'lib/utilities/react'

const Input = React.memo(({ state, name, value = 1, offValue }) => {
  if (!state) return <input type="hidden" name={offValue ? name : null} value={offValue || ''} onChange={() => {}} />

  return <input type="hidden" name={name} value={value} onChange={() => {}} />
})

const variantsStyles = (color) => ({
  primary: {
    main: {
      color: color,
      borderColor: color,
    },
    on: {
      borderRadius: '19px',
      color: 'white',
      background: color,
    },
    off: {},
  },
  outlined: {
    main: {
      border: '1px solid var(--disabled, #DBDBDB)',
      background: 'var(--fill, #F9F9F9)',
    },
    on: {
      borderRadius: '19px',
      color: 'var(--dark-grey, #666)',
      border: '1px solid var(--dark-grey, #666)',
      background: 'white',
      boxShadow: 'var(--light-shadow)',
    },
    off: {
      color: 'var(--med-grey, #898989)',
      border: '1px solid transparent',
    },
  },
})

function Switch(props) {
  const { onChange, variant = 'primary', color = '#005C4F', onText = 'yes', offText = 'no' } = props
  const variantStyle = useMemo(() => variantsStyles(color)[variant], [color, variant])

  const [state, setState] = useState(props.on)

  const classes = useClasses(['switch-utility', state ? 'on' : 'off'])

  const handleClick = useCallback(() => {
    setState(!state)
    onChange ? onChange(!state) : null
  }, [setState, onChange, state])

  const mainStyle = variantStyle['main']
  const onStyle = variantStyle['on']
  const offStyle = variantStyle['off']

  return (
    <div className={classes} style={mainStyle} onClick={handleClick}>
      <Input state={state} {...props} />
      <div style={state ? offStyle : onStyle}>{offText}</div>
      <div style={state ? onStyle : offStyle}>{onText}</div>
    </div>
  )
}

export default Switch
