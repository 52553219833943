import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateInvoiceApi } from "./apis";

const initialState = {
  invoiceUpdated: false,
};

export const updateInvoice = createAsyncThunk("residentPaymentsInvoiceEdit/updateInvoice", async ({ data, id }) => {
  const response = await updateInvoiceApi(data, id);
  const { invoice } = response.data;
  return { invoice };
});

const invoiceEditSlice = createSlice({
  name: "residentPaymentsInvoiceEdit",
  initialState,
  reducers: {
    resetState: (_state, _action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateInvoice.fulfilled, (state, _action) => {
      state.invoiceUpdated = true;
    });
  },
});

export const selectInvoiceEditState = (state) => state.residentPaymentsInvoiceEdit;

export const { resetState } = invoiceEditSlice.actions;

export const residentPaymentsInvoiceEditReducer = invoiceEditSlice.reducer;
