import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { batchCreateBuildingUnitApi, uploadFileApi } from "./apis"

export const uploadFile = createAsyncThunk('ImportBuildingUnitModal/uploadFile', async (data, thunkAPI) => {
  try {
    const formData = new FormData()
    formData.append('building_unit[file]', data.payload)
    const response = await uploadFileApi(data.buildingStructureId, formData)
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else {
      throw error
    }
  }
})

export const batchCreateBuildingUnits = createAsyncThunk('ImportBuildingUnitModal/batchCreate', async (data, thunkAPI) => {
  try {
    const response = await batchCreateBuildingUnitApi(data.buildingStructureId, data.payload)
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else {
      throw error
    }
  }
})

const initialState = {
  building: null,
  building_structure: null,
  fileId: null,
  errorMessages: [],
  buildingUnits: [],
  isUploading: false,
  isImporting: false,
  totalBuildingUnitsImported: 0,
  buildingUnitImportSuccessModalVisible: false
}

const importBuildingUnitModalSlice = createSlice({
    name: "ImportBuildingUnitModal",
    initialState,
    reducers: {
      setInitialState: (state, action) => {
        state.building = action.payload.building
        state.building_structure = action.payload.building_structure
      },
      setFileId: (state, action) => {
        state.fileId = action.payload
      },
      resetBuildingUnits: (state, action) => {
        state.buildingUnits = []
      },
      resetErrorMessages: (state, action) => {
        state.errorMessages = []
      },
      setBuildingUnitImportSuccessModalVisible: (state, action) => {
        state.buildingUnitImportSuccessModalVisible = action.payload
      }
    },
    extraReducers: builder => {
      builder.addCase(uploadFile.pending, (state, action) => {
        state.isUploading = true
      })
      builder.addCase(uploadFile.fulfilled, (state, action) => {
        const { building_units } = action.payload
        state.buildingUnits = building_units
        state.isUploading = false
      })
      builder.addCase(uploadFile.rejected, (state, action) => {
        if (action.payload?.error) {
          const { error } = action.payload
          state.errorMessages = error.file
        } else {
          state.errorMessages = ["Some errors happened, please try again!"]
        }
        state.isUploading = false
      })
      builder.addCase(batchCreateBuildingUnits.pending, (state, action) => {
        state.isImporting = true
      })
      builder.addCase(batchCreateBuildingUnits.fulfilled, (state, action) => {
        const { total } = action.payload
        state.totalBuildingUnitsImported = total
        state.buildingUnitImportSuccessModalVisible = true
        state.isImporting = false
      })
      builder.addCase(batchCreateBuildingUnits.rejected, (state, action) => {
        if (action.payload?.error) {
          const { error } = action.payload
          state.errorMessages = error.building_units
        } else {
          state.errorMessages = ["Some errors happened, please try again!"]
        }
        state.isImporting = false
      })
    }
})

export const importBuildingUnitModalState = state => state.importBuildingUnitModal

export const { setInitialState, setFileId, resetBuildingUnits, resetErrorMessages, setBuildingUnitImportSuccessModalVisible } = importBuildingUnitModalSlice.actions

export const importBuildingUnitModalReducer = importBuildingUnitModalSlice.reducer
