import React from 'react'

const ensure_object = item => typeof(item) == "object" ? item : {}

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const is_blank = v => !v || String(v).match(/^\s*$/)

function niceDatetime(str) {
  try {
    const date = new Date(Date.parse(str))
    if (!date)
      return "N/A"

    out = []
    out.push(weekdays[date.getDay()])
    out.push([months[date.getMonth()], date.getDate(), date.getFullYear()].join(" "))

    const hour = (date.getHours() % 12) == 0 ? 12 : (date.getHours() % 12)
    const minutes = `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`
    out.push(`${hour}:${minutes} ${date.getHours() < 12 ? "AM" : "PM"}`)

    return out.join(", ")
  } catch(e) {
    return "N/A"
  }
}

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.errors = {}
    this.state.store_document = {}

    this.document_types = this.props.document_types.map(x => x[0])
  }

  componentDidMount() {
    if (this.props.global)
      window.edit_store_document = this

    if (typeof this.props.onMount == "function")
      this.props.onMount(this)

    $(this.refs.modal).on("hidden.bs.modal", () => this.setState({store_document: {}, show: false}))
  }

  componentWillUnmount() {
    if (this.props.global)
      delete window.edit_store_document

    if (typeof this.props.onUnmount == "function")
      this.props.onMount(this)
  }

  async open(id) {
    try {
      const store_document = await this.loadDocument(id || this.props.id) 
      this.setState({store_document, show: true}, () => {
        $(this.refs.modal).modal("show")
      })
    } catch(e) {

    }
  }

  url(id) {
    id = id || this.props.id
    return `/store_documents/${id}` 
  }

  getFileClass() {
    if (!this.document_types.includes(this.state.store_document.file_class))
      return "other"

    return this.state.store_document.file_class
  }

  loadDocument(id) {
    return new Promise( (res, rej) => {
      if (!id)
        rej()

      const url = this.url(id)
      $.ajax({
        url,
        dataType: 'json',
        success: store_document => res(store_document),
        error: xhr => rej(xhr)
      })
    })
  }


  gatherErrors(include_required) {
    const errors = {}

    if (include_required) {
      if (is_blank(this.state.store_document.label))
        errors.label = "The Document Title must not be blank"
    }

    const price = parseFloat(this.state.store_document.price)
    if (price < 0 || (price > 0 && price < 1))
      errors.price = "The price must be either free or greater than $1.00"

    this.setState({ errors })
    return Object.keys(errors).length > 0
  }

  updateValue(id, value) {
    const store_document = Object.assign({}, this.state.store_document)
    const errors = Object.assign({}, this.state.errors)

    store_document[id] = value
    delete errors[id]

    this.setState({store_document, errors})
  }

  checkSubmitting() {
    if (this.submitting)
      return true

    this.setState({submitting: (this.submitting = true)})
  }

  releaseSubmitting() {
    this.setState({submitting: null})
    delete this.submitting
  }

  gatherData() {
    const data = new FormData()
    const file_class = this.getFileClass()

    data.append("store_document[label]", this.state.store_document.label)
    data.append("store_document[file_class]", file_class)
    if (file_class == "other")
      data.append("store_document[other]", this.state.store_document.other || "")

    data.append("store_document[current_gl_code]", this.state.store_document.current_gl_code)
    data.append("store_document[current_charge_code]", this.state.store_document.current_charge_code)

    if (this.state.store_document.confirmable)
      data.append("store_document[confirmable]", [true, "true"].includes(this.state.store_document.confirmable) ? 1 : 0)

    if (this.refs.file.files.length > 0)
      data.append("store_document[file]", this.refs.file.files[0], this.refs.file.files[0].name)

    if (this.props.file_settings) {
      data.append("store_document[price]", this.state.store_document.price)
      data.append("store_document[days_until_expiration]", this.state.store_document.days_until_expiration)
    }

    return data
  }

  submit() {
    if (this.checkSubmitting())
      return

    if (this.gatherErrors(true))
      return this.releaseSubmitting()

    const url = this.url(this.state.store_document.id)
    const data = this.gatherData()

    $.ajax({
      url, data,
      type: "put",
      dataType: 'json',
      processData: false,
      contentType: false,
      success: res => {
        if (typeof this.props.onUpdate == "function")
          this.props.onUpdate(res)
        
        $(this.refs.modal).modal("hide")
      },
      error: xhr => {

      },
      complete: () => this.releaseSubmitting()
    })
  }

  renderLine(id, label, input, options) {
    options = ensure_object(options)

    const classes = ["form-group"]
    if (this.state.errors[id])
      classes.push("has-error")

    const description = options.description ? <div className="description text-muted">{options.description}</div> : null
    const error = this.state.errors[id] ? <div className="error-message">{this.state.errors[id]}</div> : null

    return <div className={classes.join(" ")}>
      <label className="control-label">{label}</label>
      {input}
      {error}
      {description}
    </div>
  }

  renderText(id, label, options) {
    options = ensure_object(options)

    if (options.value == "null")
      delete options.value

    let value = options.value || this.state.store_document[id] || ""
    if (value == "null")
      value = ""

    const input = <input type="text" defaultValue={value} className="form-control" onChange={ e => this.updateValue(id, e.target.value)} />
    return this.renderLine(id, label, input, options )
  }

  renderFile(id, label, options) {
    options = ensure_object(options)

    const input = <input type="file" className="form-control" ref={id} />
    return this.renderLine(id, label, input, options)
  }

  renderNumber(id, label, options) {
    options = ensure_object(options)

    const input = <input type="number" defaultValue={options.value || this.state.store_document[id]} step={options.step || 0.01} className="form-control" onChange={ e=> this.updateValue(id, e.target.value)} />
    return this.renderLine(id, label, input, options)
  }

  renderStatic(id, label, options) {
    options = ensure_object(options)

    const classes = ["form-control"]
    if (options.is_datetime)
      classes.push("is-datetime")

    const input = <input type="text" className={classes.join(" ")} value={options.value || this.state.store_document[id]} readOnly={true} />
    return this.renderLine(id, label, input, options)
  }

  renderSelect(id, label, values, options) {
    options = ensure_object(options)

    const placeholder =  `-- ${options.placeholder || `Select a ${label}`} --`
    const opts = values.map(value => <option key={value[0]} value={value[0]}>{value[1]}</option>)

    if (!options.no_placeholder)
      opts.unshift(<option key="_select_" value="">{placeholder}</option>)

    const select = <select className="form-control" defaultValue={options.defaultValue || this.state.store_document[id]} onChange={e => this.updateValue(id, e.target.value)}>
      {opts}
    </select>

    return this.renderLine(id, label, select, options)
  }

  renderFileSettings() {
    if (!this.props.file_settings)
      return null

    const expiration_options = [
      [0, "No Expiration"],
      [14, "14 Days"],
      [30, "30 Days"],
      [45, "45 Days"],
      [90, "90 Days"]
    ]

    const boolean_options = [
      [true, "Yes"],
      [false, "No"]
    ]

    return <fieldset className="fieldset">
      <legend>File Settings</legend>

      { this.renderNumber("price", "Price", { description: "If no price entered Document is set to FREE."}) }
      { this.renderSelect("confirmable", "Require Confirmation?", boolean_options, { description: "The purchase of this file will need to be confirmed before the file is transferred.", placeholder: "Default: NO"})}
      { this.renderSelect("days_until_expiration", "Days Until Link Expiration After Purchase", expiration_options, { no_placeholder: true})}
    </fieldset>
  }

  renderBody() {
    if (!this.state.show || !this.state.store_document.id)
      return null

    const file_class = this.getFileClass()

    return <div className="modal-body">
      {this.renderText("label", "Document Title")}
      {this.renderStatic("file_name", "File Name")}
      {this.renderStatic("updated_at", "Updated At", {value: niceDatetime(this.state.store_document.updated_at), is_datetime: true})}
      {this.renderFile("file", "Replace File")}
      {this.renderSelect("file_class", "Document Type", this.props.document_types, {defaultValue: file_class})}
      {file_class == "other" ? this.renderText("other", "Other Document Type") : null}
      {this.renderText("current_gl_code", "GL Code")}
      {this.renderText("current_charge_code", "Charge Code")}

      {this.renderFileSettings()}
    </div>
  }

  render() {
    const save_button_classes = ["btn", "btn-primary"]
    const has_errors = Object.keys(this.state.errors).length > 0

    const save_disabled = this.state.submitting || has_errors
    if (save_disabled)
      save_button_classes.push("disabled")

    const has_errors_div = has_errors ? <div className="has-errors">
      Errors have been found.
    </div> : null

    return <div className="modal store-documents-edit-component" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Document</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {this.renderBody()}
          <div className="modal-footer">
            {has_errors_div}
            <button type="button" disabled={save_disabled} className={save_button_classes.join(" ")} onClick={() => this.submit()}>Save</button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Edit