import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateEnableRpLeaseIdApi } from "./apis";

const initialState = {
  organization: null,
};

export const updateEnableRpLeaseId = createAsyncThunk(
  "adminResidentPayments/updateEnableRpLeaseId",
  async ({ organizationId, value }) => {
    const response = await updateEnableRpLeaseIdApi(organizationId, value);
    const { enable_rp_lease_id } = response.data;
    return { enable_rp_lease_id };
  }
);

const adminResidentPayments = createSlice({
  name: "adminResidentPayments",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.organization = action.payload.organization;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateEnableRpLeaseId.pending, (state, action) => {
      state.organization.enable_rp_lease_id = action.meta.arg.value;
    });
    builder.addCase(updateEnableRpLeaseId.fulfilled, (state, action) => {
      state.organization.enable_rp_lease_id = action.payload.enable_rp_lease_id;
    });
  },
});

export const selectAdminResidentPaymentsState = (state) => state.adminResidentPayments;
export const { setInitialState, resetState } = adminResidentPayments.actions;
export const adminResidentPaymentsReducer = adminResidentPayments.reducer;
