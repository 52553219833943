import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

export default class EnableAutoPricing extends Component {
  changeValue(isAutoPricingEnabled) {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/enable_auto_pricing`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({is_auto_pricing_on: isAutoPricingEnabled ? 1 : 0}),
      success: () => this.props.updateIsAutoPricingEnabled(isAutoPricingEnabled)
    })
  }

  renderChoices() {
    return <select defaultValue={this.props.isAutoPricingEnabled ? "1" : "0"}
                   onChange={e => this.changeValue(parseInt(e.target.value) == 1)}>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  }

  render() {
    return <div className="row">
      <div className="col-sm-4">Enable Auto Pricing?</div>
      <div className="col-sm-4">
        {this.renderChoices()}
      </div>
    </div>
  }
};