import React from 'react'

import RemoveCollaboratorButton from './remove_collaborator_button'

class DefaultTab extends React.Component {
  constructor(props) {
    super(props)
    this.selectPolicyClick = this.selectPolicyClick.bind(this)
    this.selectMessageClick = this.selectMessageClick.bind(this)
    this.allChecked = this.allChecked.bind(this)
    this.handleShowAllClick = this.handleShowAllClick.bind(this)
    this.handleReadOnlyClick = this.handleReadOnlyClick.bind(this)
    this.handleManuallySelectClick = this.handleManuallySelectClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.renderOptionStyle = this.renderOptionStyle.bind(this)
  }

  selectPolicyClick () {
    this.props.changeTab('POLICYTAB')
  }

  selectMessageClick () {
    this.props.changeTab('MESSAGETAB')
  }

  handleRemoveClick () {
    this.props.changeTab("REMOVETAB")
  }

  allChecked () {
    var allChecked = true
    var updatedPolicy = this.props.collaborator.updatedPolicy
    for (var i=0; updatedPolicy.length; i++) {
      if (updatedPolicy[i].visibility === false) {
        allChecked = false
        break
      }
    }
    return allChecked
  }

  handleShowAllClick () {
    this.props.toggleAttachAllRequirementPolicies(this.props.collaborator)
  }

  handleReadOnlyClick () {
    this.props.toggleReadOnly(this.props.collaborator)
  }

  handleManuallySelectClick () {
    this.props.resetForManualSelectClick(this.props.collaborator)
    this.props.changeTab('POLICYTAB')
  }

  renderOptionStyle () {
    return (
      this.props.collaborator.attached ? {fontWeight: 'bold', color: 'orange', paddingTop: '5px'} : {fontWeight: 'bold', paddingTop: '5px'}
    )
  }

  renderFirstOptionStyle() {
    return (
      this.props.collaborator.attached ? {fontWeight: 'bold', color: 'orange', padding: '5px 0'} : {fontWeight: 'bold', padding: '5px 0'}
      )
  }

  render () {
    var hrStyle = {
      marginTop: '10px',
      marginBottom: '10px',
      borderBottom: '1px solid black'
    }
    return (
      <div className='default-tab'>
        <span style={{marginBottom: '0px', paddingTop: '10px'}}>Visibility Options</span>
        <hr style={{marginBottom: '5px', marginTop: '0px', borderBottom: '1px solid black'}}></hr>
        <ul style={{listStyle: 'none', paddingLeft: '0px', paddingTop: '5px', paddingBottom: '5px'}}>
          <li style={this.renderFirstOptionStyle()} onClick={this.handleShowAllClick} className='collaborator-hover'>
            <input type='checkbox' checked={this.props.collaborator.attachAllRequirementPolicies} onChange={() => {}} />
            <span> Share All Requirements</span>
          </li>
          <li style={this.renderOptionStyle()} className='collaborator-hover'>
            <input
              type='checkbox'
              onChange={this.handleManuallySelectClick}
              checked={this.props.manuallySelecting()}
              >
            </input>
            <span style={this.renderOptionStyle()} onClick={this.handleManuallySelectClick}> Select Requirements to Share</span>
          </li>
          { /* These two fields should be coming back soon */ }
          { /* <li style={{fontWeight: 'bold', color: this.props.collaborator.message.length > 0 ? 'orange' : 'inherit'}} onClick={this.selectMessageClick}>Add/Edit Message</li> */ }
          { /* <li style={this.renderOptionStyle()} className='collaborator-hover'><input type='checkbox' onChange={this.handleReadOnlyClick} checked={this.props.collaborator.readOnly}></input><span>Read Only</span></li> */ }
        </ul>
        {
          this.props.collaborator.attached
          ? <RemoveCollaboratorButton
            handleRemoveClick={this.handleRemoveClick}
            hrStyle={hrStyle}
          /> : <hr style={hrStyle}></hr>
        }
      </div>
    )
  }
}

export default DefaultTab
