import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { flatten, omit, xor } from 'lodash-es'
import clsx from 'clsx'
import EditableField from '../EditableField'
import { selectPackageTemplatesForm } from '../../store/slice'
import PackageTemplateDocuments from '../PackageTemplateDocuments'
import MultiSelect from 'components/utility/multi_select'

export const PACKAGE_REQUIREMENT_TYPES = {
  'recognition_agreement': 'recognition_agreement',
  'insurance': 'insurance',
}

const INSURANCE_RISK_TYPES = { 'homeowners': 'Homeowners', 'renters': 'Renters' }

// const PACKAGE_ROLES = {
//   client: 'Client',
//   broker: 'Broker',
//   board_member: 'Board Member',
//   managing_agent: 'Managing Agent',
//   listing_agent: 'Listing Agent',
//   spectator: 'Spectator',
//   resident: 'Resident',
//   property_architect: 'Property Architect',
//   alterations_admin: 'Alterations Admin',
//   processor: 'Processor',
//   participant_and_viewer: 'Participant And Viewer',
//   viewer: 'Viewer',
//   participant: 'Participant',
// }

export default function RequirementField({
  dragProvided,
  field,
  index,
  remove,
  recognition_agreement_enabled,
  selectedInsuranceTypes,
  onViewMyDocumentsModal,
  onRemoveDocument,
  onNullifyOthers,
  nullifyInvalidInsuranceRequirements,
}) {
  const { control, watch, setValue, getValues } = useFormContext()

  const { fields: package_template_documents, remove: remove_template_document } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: `package_template.package_requirements.${index}.package_template_documents`,
  })

  const {
    is_super_admin,
    building: { enable_insurance_quoting },
  } = useSelector(selectPackageTemplatesForm)

  const clearInsuranceRiskTypes = () => {
    const key = `package_template.package_requirements.${index}.insurance_metadata.insurance_risk_types`
    const currentInsuranceRiskTypes = getValues(key)
    setValue(`package_template.package_requirements.${index}.insurance_metadata.insurance_risk_types`, [])
  }

  const onRecognitionChange = (checked, currentRequirementType, requirementSet) => {
    if (checked) {
      if (currentRequirementType === PACKAGE_REQUIREMENT_TYPES.insurance) {
        clearInsuranceRiskTypes()
      }
      requirementSet(PACKAGE_REQUIREMENT_TYPES.recognition_agreement)
      onNullifyOthers(PACKAGE_REQUIREMENT_TYPES.recognition_agreement, index)
    } else {
      if (currentRequirementType === PACKAGE_REQUIREMENT_TYPES.recognition_agreement) {
        requirementSet(null)
      }
    }
  }

  const onInsuranceChange = (checked, currentRequirementType, requirementSet) => {
    if (checked) {
      requirementSet(PACKAGE_REQUIREMENT_TYPES.insurance)
    } else {
      if (currentRequirementType === PACKAGE_REQUIREMENT_TYPES.insurance) {
        requirementSet(null)
        clearInsuranceRiskTypes()
      }
    }
  }

  const onInsuranceRiskTypesChange = (value, insuranceRiskTypesSet) => {
    insuranceRiskTypesSet(value)
    nullifyInvalidInsuranceRequirements()
  }

  const is_insurance_type =
    watch(`package_template.package_requirements.${index}.requirement_type`) === PACKAGE_REQUIREMENT_TYPES.insurance

  const allSelectedInsuranceTypes = flatten(Object.values(selectedInsuranceTypes))

  const renderSuperAdminOptions = () => {
    if (!is_super_admin) return null

    return (
      <>
        <div className="requirement-header__item recognition-agreement-input ">
          <Controller
            control={control}
            name={`package_template.package_requirements.${index}.requirement_type`}
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                disabled={!recognition_agreement_enabled}
                checked={value === PACKAGE_REQUIREMENT_TYPES.recognition_agreement}
                onChange={(e) => onRecognitionChange(e.currentTarget.checked, value, onChange)}
              />
            )}
          />
          <span>Recognition Agreement</span>
        </div>
        {enable_insurance_quoting && (
          <div className="requirement-header__item insurance-requirement-input">
            <Controller
              control={control}
              name={`package_template.package_requirements.${index}.requirement_type`}
              render={({ field: { onChange, value } }) => (
                <input
                  type="checkbox"
                  checked={value === PACKAGE_REQUIREMENT_TYPES.insurance}
                  disabled={
                    value !== PACKAGE_REQUIREMENT_TYPES.insurance &&
                    xor(allSelectedInsuranceTypes, Object.keys(INSURANCE_RISK_TYPES)).length === 0
                  }
                  onChange={(e) => onInsuranceChange(e.currentTarget.checked, value, onChange)}
                />
              )}
            />
            <span>Insurance</span>
          </div>
        )}
        {enable_insurance_quoting && is_insurance_type && (
          <>
            <div className="multiple-select-container" style={{ width: '145px' }}>
              <Controller
                control={control}
                name={`package_template.package_requirements.${index}.insurance_metadata.insurance_risk_types`}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    options={omit(INSURANCE_RISK_TYPES, xor(allSelectedInsuranceTypes, value || []))}
                    onChange={(newValue) => onInsuranceRiskTypesChange(newValue, onChange)}
                    defaultValue={value}
                    placeholder="select types"
                  />
                )}
              />
            </div>
            {/*<div className="multiple-select-container" style={{ width: '145px' }}>
              <Controller
                control={control}
                name={`package_template.package_requirements.${index}.insurance_metadata.insurance_user_roles`}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    options={PACKAGE_ROLES}
                    onChange={onChange}
                    defaultValue={value}
                    placeholder="select role"
                    max={1}
                  />
                )}
              />
            </div>*/}
          </>
        )}
      </>
    )
  }

  const removeRequirement = () => remove(index)

  const openMyDocumentsModal = () => onViewMyDocumentsModal({ index })

  const handle_remove_template_document = async (template_document_index) => {
    try {
      const requirement_id = field.id
      const document_id = package_template_documents[template_document_index].id
      await onRemoveDocument(requirement_id, document_id)
      remove_template_document(template_document_index)
    } catch (e) {}
  }

  return (
    <li className="requirement clearfix" {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
      <div className="col-xs-12 clearfix">
        <div className="requirement-header row">
          <div className="handle pull-left" {...dragProvided.dragHandleProps}>
            <i className="fa fa-arrows"></i>
          </div>
          {renderSuperAdminOptions()}
        </div>
        <div className="pull-left" style={{ width: '80%' }}>
          <Controller
            control={control}
            name={`package_template.package_requirements.${index}.title`}
            render={({ field: { onChange, value } }) => (
              <EditableField defaultValue={value || ''} onSubmit={onChange} btnClasses={['btn-xs']}>
                <span>
                  {value}
                  <i className="fa fa-pencil"></i>
                </span>
              </EditableField>
            )}
          />
          <Controller
            control={control}
            name={`package_template.package_requirements.${index}.description`}
            render={({ field: { onChange, value } }) => (
              <EditableField
                defaultValue={value || 'Enter a description for this requirement'}
                onSubmit={onChange}
                tag="textarea"
                btnClasses={['btn-xs']}
              >
                <span>
                  {value?.length > 0 ? value : 'Enter a description for this requirement'}
                  <i className="fa fa-pencil"></i>
                </span>
              </EditableField>
            )}
          />
        </div>
        <div className="actions pull-right">
          <button
            type="button"
            className={clsx('btn btn-xs btn-default btn-view-my-docs', { 'btn-disabled': !field.id })}
            onClick={openMyDocumentsModal}
            disabled={!field.id}
          >
            <i className="fa fa-plus"></i>
            Document/Form
          </button>
          <button type="button" className="btn btn-link">
            <i className="fa fa-trash text-danger" onClick={removeRequirement}></i>
          </button>
        </div>
        <PackageTemplateDocuments
          fields={package_template_documents}
          remove={handle_remove_template_document}
          requirement_index={index}
        />
      </div>
      {package_template_documents.length === 0 && (
        <p className="text-muted">There are no documents or forms for this requirement</p>
      )}
    </li>
  )
}
