import React, { Component } from "react"

import { addCSRF } from 'lib/document_utilities'

function cancelQuestionnaire(id) {
  const url = `/questionnaire/${id}/cancel`
  const data = addCSRF({})

  return new Promise((res, rej) => {
    $.ajax({
      url, data, type: "post",
      success: res
    })
  })
}

export default  class CancelConfirmationModal extends Component {
  constructor(props) {
    super(props)

    this.modal = null

    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    $(this.modal).on("hidden.bs.modal", () => this.props.onClose())
    this.open()
  }

  componentWillUnmount() {
    this.close()
  }

  open() {
    $(this.modal).modal('show')
  }

  handleCancel() {
    cancelQuestionnaire(this.props.id).then(this.props.onCancel)
  }

  close() {
    $(this.modal).modal('hide')
  }

  render(){
    const style = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      margin: "0px auto",
      paddingTop: "30px"
    }

    return (
      <div className="modal" ref={this.setModalRef}>
        <div className="modal-dialog modal-md questionnaire-modal-dialog">
          <div className="modal-body">
            <h4 className="text-center bopa-text questionnaire-form-header">Are you sure you want to cancel this questionnaire?</h4>

            <div style={style}>
              <button className="btn btn-danger" onClick={() => this.close()}>Close</button>
              <button className="btn btn-success payment-form-purchase-btn" onClick={() => this.handleCancel()}>Confirm Cancellation</button>        
            </div>
          </div>
        </div>
      </div>
    )
  }
};
