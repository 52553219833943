import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

function transformPrice(price) {
  const booleans = {
    with_file:    ["both", "with_file"].includes(price.requested_with_file),
    without_file: ["both", "without_file"].includes(price.requested_with_file)
  }
  return Object.assign({}, price, booleans)
}

function transformPrices(prices) {
  return prices.map(price => transformPrice(price))
}


export default class Pricing extends Component {
  add_url() {
    return `/super/organizations/${this.props.organization.id}/questionnaires/pricing`
  }

  edit_url(price_id) {
    return `/super/organizations/${this.props.organization.id}/questionnaires/pricing/${price_id}`
  }

  remove_url(price_id) {
    return `/super/organizations/${this.props.organization.id}/questionnaires/pricing/${price_id}`
  }

  constructor(props) {
    super(props)

    this.state = {}
    this.state.prices = transformPrices(this.props.prices || [])
    this.state.new_price = { dirty: true }
  }

  updateNewPriceAttribute(attribute, value) {
    const price = Object.assign({}, this.state.new_price)
    price[attribute] = value
    this.setState({new_price: price})
  }

  updatePriceAttribute(price, attribute, value) {
    if (price[attribute] == value)
      return

    const new_price = Object.assign({}, price, { dirty: true }),
          prices = this.state.prices.concat([]),
          index = prices.indexOf(price)

    new_price[attribute] = value
    prices.splice(index, 1, new_price)


    this.setState({prices: prices})
  }

  canSave(price) {
    if (!price.name || price.name.match(/^\s*$/))
      return false

    if (!price.dynamic_price && !String(price.price).match(/^\d+(.\d{2})?$/))
      return false

    if (!price.with_file && !price.without_file)
      return false

    return price.dirty
  }

  isAdding() {
    if (this.is_adding)
      return true

    this.is_adding = true
    return false
  }

  addPrice() {
    const price = this.state.new_price
    if (!this.canSave(price))
      return

    if (this.isAdding())
      return

    const data = {
      name: price.name,
      price: price.dynamic_price ? null : price.price,
      dynamic_price: price.dynamic_price ? 1 : 0,
      with_file: price.with_file ? (price.without_file ? "both" : "with_file") : "without_file"
    }

    $.ajax({
      url: this.add_url(),
      type: "post",
      data: addMeta({org_price: data}),
      success: price => {
        this.setState({
          prices: this.state.prices.concat([transformPrice(price)]),
          new_price: {dirty: true}
        })

        $(this.refs.new_price).find("input").val("")
      },
      complete: () => this.is_adding = false
    })
  }

  editPrice(price) {
    if (!this.canSave(price))
      return

    const data = {
      name: price.name,
      price: price.dynamic_price ? null : price.price,
      dynamic_price: price.dynamic_price ? 1 : 0,
      with_file: price.with_file ? (price.without_file ? "both" : "with_file") : "without_file"
    }

    $.ajax({
      url: this.edit_url(price.id),
      type: "put",
      data: addMeta({org_price: data}),
      success: () => {
        const new_price = Object.assign({}, price),
              prices = this.state.prices.concat([]),
              index = prices.indexOf(price)

        delete new_price.dirty
        prices.splice(index, 1, new_price)
        this.setState({prices: prices})
      }
    })
  }

  removePrice(price) {
    const pre_submit_options = {
      title: "Are you sure?",
      text: `Please confirm you wish to remove ${price.name} from the list of prices`,
      showCancelButton: true
    }

    const ajax_options = {
      url: this.remove_url(price.id),
      data: addMeta({ price: price.id }),
      type: "delete",
      success: () => {
        const prices = this.state.prices.concat([]),
              index = prices.indexOf(price)

        prices.splice(index, 1)
        this.setState({prices: prices})
      }
    }

    swal(pre_submit_options, confirm => confirm ? $.ajax(ajax_options) : null)
  }

  renderCheckbox(name, active, onClick) {
    return <div className={name}>
      <span onClick={e => onClick(e)}>{active ? <i className="fa fa-check" /> : ""}</span>
    </div>
  }

  renderPrice(price) {
    const disabled = !this.canSave(price)
    const button_classes = ["btn", "btn-primary", "btn-sm"]

    if (disabled)
      button_classes.push("disabled")

    return <div key={price.id}>
      <div className="name"><input type="text" defaultValue={price.name} onChange={e => this.updatePriceAttribute(price, "name", e.target.value)} /></div>
      <div className="pricing">
        <div className="price"><input type="text" disabled={price.dynamic_price} defaultValue={price.price} onChange={e => this.updatePriceAttribute(price, "price", e.target.value)} /></div>
        {this.renderCheckbox("dynamic_price", price.dynamic_price, e => this.updatePriceAttribute(price, "dynamic_price", !price.dynamic_price))}
      </div>
      {this.renderCheckbox("without_file", price.without_file, e => this.updatePriceAttribute(price, "without_file", (!price.without_file || price.with_file) ? !price.without_file : price.without_file))}
      {this.renderCheckbox("with_file", price.with_file, e => this.updatePriceAttribute(price, "with_file", (!price.with_file || price.without_file) ? !price.with_file : price.with_file))}
      <div className="buttons">
        <button type="button" disabled={disabled} className={button_classes.join(" ")} onClick={() => this.editPrice(price)}>Save</button>
        <i className="fa fa-times" onClick={() => this.removePrice(price)} />
      </div>
    </div>
  }

  renderAboveHeader() {
    return <div className="list-header list-above-header">
      <div className="name">&nbsp;</div>
      <div className="pricing">Pricing</div>
      <div className="type">Request Type</div>
      <div className="buttons"></div>
    </div>
  }

  renderHeaders() {
    return <div className="list-header">
      <div className="name">Label</div>
      <div className="pricing">
        <div className="price">Amount</div>
        <div className="dynamic_price"><div>Custom</div></div>
      </div>
      <div className="without_file"><div>Standard</div><div className="subtext">(Without File)</div></div>
      <div className="with_file"><div>Custom</div><div className="subtext">(With File)</div></div>
      <div className="buttons"></div>
    </div>
  }

  renderList() {
    return <div className="list">
      {this.state.prices.map(price => this.renderPrice(price))}
    </div>
  }

  renderAdd() {
    const disabled = !this.canSave(this.state.new_price) || this.is_adding
    const button_classes = ["btn", "btn-primary", "btn-sm"]

    if (disabled)
      button_classes.push("disabled")

    return <div className="add" ref="new_price">
      <div className="name"><input type="text" placeholder="Enter Name" onChange={e => this.updateNewPriceAttribute("name", e.target.value)} /></div>
      <div className="pricing">
        <div className="price"><input type="text" disabled={this.state.new_price.dynamic_price} placeholder="Price" onChange={e => this.updateNewPriceAttribute("price", e.target.value)} /></div>
        {this.renderCheckbox("dynamic_price", this.state.new_price.dynamic_price, e => this.updateNewPriceAttribute("dynamic_price", !this.state.new_price.dynamic_price))}
      </div>
      {this.renderCheckbox("without_file", this.state.new_price.without_file, e => this.updateNewPriceAttribute("without_file", !this.state.new_price.without_file))}
      {this.renderCheckbox("with_file", this.state.new_price.with_file, e => this.updateNewPriceAttribute("with_file", !this.state.new_price.with_file))}
      <div className="buttons">
        <button type="button" className={button_classes.join(" ")} disabled={disabled} onClick={() => this.addPrice()}>Add Price</button>
      </div>
    </div>
  }

  render() {
    return <div className="edit-questionnaire-pricing" id="questionnaire-request-admin-pricing">
      {this.renderAboveHeader()}
      {this.renderHeaders()}
      {this.renderList()}
      {this.renderAdd()}
    </div>
  }
};