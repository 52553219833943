import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { isPlainObject } from 'lodash-es'
import { useWatchableRef } from 'lib/hooks'
import { useClasses } from 'lib/utilities/react'

function DropdownItem({ id, value, component, onClick }) {
  const handleClick = useCallback(() => {
    onClick(id || value)
  }, [id, value])

  // tabIndex is required for blur event.relatedTarget
  return (
    <div tabIndex="-1" onClick={handleClick}>
      {component}
    </div>
  )
}

function formatOptions(options) {
  if (!options) return []

  if (isPlainObject(options)) return Object.entries(options).map(([id, value]) => ({ id, value, component: value }))

  if (!Array.isArray(options)) return [{ id: options, value: options, component: options }]

  return options.map((option) => {
    if (Array.isArray(option)) return { id: option[0], value: option[1] || option[0], component: option[2] || option[1] || option[0] }

    return isPlainObject(option) ? option : { id: option, value: option, component: option }
  })
}

const filterOptions = (options, search, searchByValue, caseSensitive = false) => {
  const key = searchByValue ? 'value' : 'id'
  return options.filter((option) =>
    caseSensitive
    ? option[key].includes(search)
    : option[key].toLowerCase().includes(search.toLowerCase())
  )
}

function Dropdown({ options, onSelect }) {
  const [isExpanded, setIsExpanded] = useState(false)

  if (options.length === 0) return null

  const handleToggle = (e) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const displayedOptions = isExpanded ? options : options.slice(0, 5)

  return (
    <div className="dropdown-area dome-scrollable">
      {displayedOptions.map((option) => (
        <DropdownItem key={option.id} {...option} onClick={onSelect} />
      ))}
      {options.length > 5 && !isExpanded && (
        <div className="more-options" onClick={handleToggle}>
          + {options.length - 5} more
        </div>
      )}
      {isExpanded && (
        <div className="more-options collapse-options" onClick={handleToggle}>
          show less
        </div>
      )}
    </div>
  );
}

const Autosuggest = React.forwardRef(
  ({ defaultValue, value: controlledValue, options, name, id, onChange, onBlur, onSelect, hideOnSelect, searchByValue, caseSensitive, closeOnSelect = true }, externalRef) => {
    const [value, setValue] = useState(controlledValue ? controlledValue : defaultValue || '')

    const [showOptions, setShowOptions] = useState(false)
    const watchedRef = useWatchableRef()
    const internalRef = useRef(null)
    const handleRef = useCallback(
      (node) => {
        if (typeof externalRef === 'function') externalRef(node)

        internalRef.current = node
      },
      [externalRef]
    )

    const handleChange = useCallback(
      (evt) => {
        setValue(evt.target.value)
      },
      [setValue]
    )

    const blurInput = useCallback(() => {
      if (!onBlur)
        return

      const event = new Event('blur')
      Object.defineProperty(event, 'target', { value: internalRef.current, enumerable: true })
      onBlur(event)
    }, [onBlur])

    const blurringRef = useRef(false)

    const handleSelectItem = useCallback(
      (value) => {
        setValue(value)

        console.log("VALUE", value)
        if (closeOnSelect) {
          setShowOptions(false)
        }

        blurringRef.current = true

        if (onSelect) onSelect(value)
        setValue(hideOnSelect ? '' : value)
      },
      [setValue, setShowOptions, onSelect, hideOnSelect]
    )

    const handleFocus = useCallback((_evt) => setShowOptions(true), [setShowOptions])
    const handleBlur = useCallback(
      (evt) => {
        if (!onBlur) return
        if (!watchedRef.current.contains(evt.relatedTarget)) onBlur(evt)
      },
      [onBlur, watchedRef.version]
    )

    useEffect(() => {
      const event = new Event('change')
      Object.defineProperty(event, 'target', { value: internalRef.current, enumerable: true })
      onChange && onChange(event)

      if (blurringRef.current == true) {
        blurringRef.current = false
        blurInput()
      }
    }, [onChange, value, watchedRef.version, blurInput, hideOnSelect])

    useEffect(() => {
      if (!showOptions || !watchedRef.current) return

      const clickEverywhere = (evt) => {
        if (!evt.target.isConnected) return
        if (watchedRef.current.contains(evt.target)) {
          return
        }

        setShowOptions(false)
      }

      document.addEventListener('click', clickEverywhere)
      return () => document.removeEventListener('click', clickEverywhere)
    }, [watchedRef.version, showOptions, setShowOptions])

    const formattedOptions = useMemo(() => formatOptions(options), [options])
    const filteredOptions = useMemo(() => filterOptions(formattedOptions, value, searchByValue, caseSensitive), [formattedOptions, value, searchByValue, caseSensitive])

    const fieldProps = useMemo(
      () => ({
        name,
        id,
        value,
        autoComplete: 'off',
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      }),
      [name, value, handleChange, handleBlur, handleFocus]
    )

    const classes = useClasses([
      'autosuggest-component',
      showOptions && filteredOptions.length > 0 ? 'showing-options' : null,
    ])

    return (
      <div className={classes} ref={watchedRef.ref}>
        <input type="text" ref={handleRef} {...fieldProps} />
        {showOptions ? <Dropdown options={filteredOptions} onSelect={handleSelectItem} /> : null}
      </div>
    )
  }
)

Autosuggest.defaultProps = {
  defaultValue: '',
}

export default Autosuggest
