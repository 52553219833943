import React from 'react'
import { compact } from 'lodash'

import { useSelector } from 'react-redux'
import { Popover } from 'react-tiny-popover'


function Group({id, position, isSigning, isSigned, frozen}) {
  const uniqueness = useSelector(({global: state}) => state.uniqueness)
  const {page} = position
  const classes = compact(['signature-group', isSigning ? "is-signing" : null, isSigned ? "is-signed" : null])
  const el_id = `${uniqueness}-signature_group-${id}-${page}`

  return <div id={frozen ? null : el_id} className={classes.join(" ")}>
  </div>
}

const LABEL_TEXT = {
  required: "Sign one of these fields",
  all: "<<roles>> must sign these fields",
  limited: "At least <<number>> <<role>> must sign.",
  optional: "<<roles>> may sign these fields"
}

function Label({id}) {
  const group = useSelector(state => state.form_signatures.signature_groups[id])
  const signable = useSelector(({form_signatures: state}) => state.signatures_to_sign.lookups[`sg-${id}`])
  const role = useSelector(({form_signatures: state}) => state.roles[group.role])

  let role_title = signable.min_required == 1 ? role.label_singular : null
  role_title ||= role.label

  const text = (LABEL_TEXT[signable.required || "optional"]).replace("<<roles>>", role.label).replace("<<role>>", role_title).replace("<<number>>", signable.min_required)

  return <div className="signature-popover">{text}</div>
}

export default function({id, frozen, page_id}) {
  const signature_group = useSelector(state => state.form_signatures.signature_groups[id])
  
  const isSigning = useSelector(state => state.form_signatures.signatures_to_sign.signature_groups[id])

  const isSigned = useSelector(state => {  
    const signature_is_signed = id => state.form_signatures.signatures[id].signature
    return signature_group.signatures.some(signature_is_signed)
  })

  const props = { id, isSigned, isSigning, frozen }
  const positions = signature_group.positions.filter(pos => pos.page == page_id)
  if (positions == null)
    return null

  return <>
    { isSigning && !isSigned && !frozen ? <Label id={signature_group.id} /> : null }
    { positions.map(position => <Group key={position.page} position={position} {...props} />)}
  </>
}