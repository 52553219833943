import React, { useRef, useState, useEffect } from 'react'
import $ from 'jquery'

function shouldClose(target, container) {
  return !container.contains(target) && !target.closest(".modal")
}

// YUo
export function useHideOnClickoutDropdown() {
  const container = useRef()
  const [show, setShow] = useState(false)
  
  useEffect(() => {
    if (!show || !container.current)
      return
    
    const windowClick = e => shouldClose(e.target, container.current) ? setShow(false) : null
    window.addEventListener('click', windowClick)
    return () => window.removeEventListener('click', windowClick)
  }, [show, setShow, container.current])

  return { container, show, setShow }
}

const reverser = ([n,v]) => [v,n]
export const reverseKeysAndValues = (hash) => Object.fromEntries(Object.entries(hash).map(reverser))