import React from 'react'
import PropTypes from 'prop-types'
import { pick } from 'lodash'

import FormsList from './forms_list'
import SelectFormRow from './select_form_row'
import UpdateTypeSelector from './update_type_selector'

const SELECT_FORM_PROPS_FROM_STATE = [
  "updateType",
  "originalForm", "replacementForm",
  "formListOpenForType", "templatesUsingFormPath"
]

class TemplateFormUpdater extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formsListPath: props.formsListPath,
      updateType: null,
      updateTypeList: ["original", "replacement"],
      originalForm: null,
      originalFormTemplates: [],
      replacementForm: null,
      formListOpenForType: null,
      saveLoading: false
    }

    this.setAppState = this.setAppState.bind(this)
  }

  componentDidUpdate(_, prevState) {
    if (prevState.saveLoading && !this.state.saveLoading)
      return this.renderSaveCompleteSwal(prevState)
  }

  setAppState(state) {
    this.setState(state)
  }

  // returns the intended target (original or replacement) for when selecting a form from the list
  selectedFormForList() {
    const selectedFormType = this.state.formListOpenForType
    if (!selectedFormType) return null
    if (selectedFormType == "original") return this.state.originalForm
    if (selectedFormType == "replacement") return this.state.replacementForm
  }

  renderFormsList() {
    if (!this.state.formListOpenForType) return

    const selectedForm = this.selectedFormForList()
    const selectedFormId = selectedForm ? selectedForm.id : null
    const props = {
      selectedFormId,
      setAppState: this.setAppState,
      formsListPath: this.state.formsListPath,
      formFor: this.state.formListOpenForType,
    }

    return (
      <div>{<FormsList {...props} />}</div>
    )
  }

  renderSelectFormRow() {
    if (!this.state.updateType) return

    const is_replacing = this.state.updateType == "replace"
    const selectFormList = this.state.updateTypeList.filter( type => type != "replacement" || is_replacing)

    const state_props = pick(this.state, SELECT_FORM_PROPS_FROM_STATE)
    const props = {
      setAppState: this.setAppState,
      templatesUsingFormPath: this.props.templatesUsingFormPath,
      ...state_props
    }

    return selectFormList.map( type => <SelectFormRow key={type} formRowType={type} {...props} />)
  }

  submitChanges() {
    this.state.updateType == "remove" ? this.postRemoveFormRequest() : this.postReplaceFormRequest()
  }

  postRemoveFormRequest() {
    this.setState({ saveLoading: true })
    $.ajax({
      url: this.props.removeFormPath,
      data: { form_id: this.state.originalForm.id },
      dataType: 'json',
      method: 'post',
      context: this,
      success: function (res) {
        if (res.success) return this.setState({ saveLoading: false })
        console.log(res.errors)
      }
    })
  }

  postReplaceFormRequest() {
    this.setState({ saveLoading: true })
    $.ajax({
      url: this.props.replaceFormPath,
      data: { form_to_replace_id: this.state.originalForm.id, replace_with_form_id: this.state.replacementForm.id },
      dataType: 'json',
      method: 'post',
      context: this,
      success: function (res) {
        if (res.success) return this.setState({ saveLoading: false })
        console.log(res.errors)
      }
    })
  }

  renderSavingSwal() {
    if (!this.state.saveLoading) return null
    swal({})
  }

  renderSaveCompleteSwal(prevState) {
    let completeSwalText = null
    if (prevState.updateType == "remove")
      completeSwalText = "Removed " + prevState.originalForm.name + " from all package templates"
    completeSwalText = completeSwalText || "Replaced " + prevState.originalForm.name + " with " + prevState.replacementForm.name

    this.clearSelectedForms()
    swal("Complete", completeSwalText, "success")
  }

  clearSelectedForms() {
    this.setState({ originalForm: null, replacementForm: null, formListOpenForType: null, originalFormTemplates: [] })
  }

  checkIfReadyToSubmit() {
    if (this.state.updateType == "remove")
      if (!this.state.originalForm) return false

    if (this.state.updateType == "replace")
      if (!this.state.originalForm || !this.state.replacementForm) return false
      if (this.state.originalForm == this.state.replacementForm) return false
      if (this.state.originalFormTemplates.length == 0) return false

    return true
  }

  renderSubmitButton() {
    if (!this.checkIfReadyToSubmit()) return null

    return (
      <button type="button" className="btn btn-success" onClick={() => this.renderConfirmSubmitSwal()}>
        Click here to continue
      </button>
    )
  }

  renderConfirmSubmitSwal() {
    const { updateType, originalForm, replacementForm } = this.state
    let confirmationMessage = null
    if (updateType == "remove")
      confirmationMessage = "Are you sure you want to remove " + originalForm.name + "?"
    confirmationMessage = confirmationMessage || "Are you sure you want to replace " + originalForm.name + " with " + replacementForm.name + "?"

    swal({
      title: confirmationMessage,
      type: 'warning',
      confirmButtonColor: "#f68a41",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
    }, (isConfirm) => { if (isConfirm) this.submitChanges() })
  }

  render() {
    const { updateType } = this.state

    return (
      <div className="container-fluid" id="template-form-updater-container">
        <div className="row">
          <div className="col-xs-12 col-md-8 col-md-offset-2 text-center">
            <UpdateTypeSelector setAppState={this.setAppState} selectedUpdateType={updateType} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8 col-md-offset-2">
            {this.renderSelectFormRow()}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8 col-md-offset-2">
            {this.renderFormsList()}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-8 col-md-offset-2 text-right">
            {this.renderSubmitButton()}
          </div>
        </div>
      </div>
    );
  }
}

TemplateFormUpdater.propTypes = {
  formsListPath: PropTypes.string.isRequired,
  removeFormPath: PropTypes.string.isRequired,
  replaceFormPath: PropTypes.string.isRequired,
  templatesUsingFormPath: PropTypes.string.isRequired
}

export default TemplateFormUpdater
