import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

// Signature Stuff
import {  useComponents as useHighlights,
          useTool as useHighlightTool,
          useGenerator as useHighlightGenerator,
          setHighlights,
          mergeHighlights,
          reducer as highlightReducer
        } from "./highlight"

// Strikethrough Stuff
import {  useComponents as useStrikethroughs,
          useTool as useStrikethroughTool,
          useGenerator as useStrikethroughGenerator,
          setStrikethroughs,
          mergeStrikethroughs,
          reducer as strikethroughReducer
        } from "./strikethrough"

import { mergeTools } from 'components/form_creator/tools/base'


/*
 * Tools must have the following items for implementation
 *
 * useGenerator
 * useComponents
 * useTool
 *
 */

// This function uses the useComponents
export function usePageToolComponents({page_id}) {
  const tools = useCurrentTools()

  const highlights = useHighlights(tools, page_id)
  const strikethroughs = useStrikethroughs(tools, page_id)

  return [
    highlights,
    strikethroughs
  ]
}

// This function uses the useGenerator
export function useToolGenerator({keepDrawing} = {}) {
  const highlightGenerator = useHighlightGenerator({keepDrawing})
  const strikethroughGenerator = useStrikethroughGenerator({keepDrawing})

  return useCallback((type, rect) => {
    switch(type) {
      case 'highlight':
        return highlightGenerator(rect)
      case 'strikethrough':
        return strikethroughGenerator(rect)
    }
  }, [keepDrawing])
}

// This function uses the useTool
export function useTools() {
  const highlights = useHighlightTool()
  const strikethroughs = useStrikethroughTool()

  return mergeTools([
    highlights,
    strikethroughs
  ])
}

export function getToolReducers() {
  return {
    highlights: highlightReducer,
    strikethroughs: strikethroughReducer
  }
}

export function useToolDataDispatcher() {
  const dispatch = useDispatch()

  return (form, { merge = false } = {}) => {
    dispatch(merge ? mergeHighlights(form.highlights || {}) : setHighlights(form.highlights || {}))
    dispatch(merge ?  mergeStrikethroughs(form.strikethroughs || {}) : setStrikethroughs(form.strikethroughs || {}))
  }
}


/***********************************/
/** DO NOT EDIT BEYOND THIS POINT **/
/***********************************/


// The Tool Context
const ToolContext = React.createContext()
export function Provider(props) {
  const tools = useTools()

  return React.createElement(ToolContext.Provider, { value: tools }, props.children)
}

export function useCurrentTools() {
  return React.useContext(ToolContext)
}

export function connectToCurrentTools(Component) {
  return function(props) {
    const children = tools => React.createElement(Component, Object.assign({ tools: tools }, props))

    return React.createElement(ToolContext.Consumer, null, children)
  }
}

// Components
export function useToolSidebars({selected}) {
  const tools = useCurrentTools()
  return tools.gatherToolbarsForSelected(selected)
}

// Generation functions
export function useToolPencil(page, type) {
  const generator = useToolGenerator({keepDrawing: true})

  return useCallback((rect) => {
    if (!type)
      return

    // Prepare the rect
    rect = { ...rect, page }
    rect.width ||= 0
    rect.height ||= 0

    generator(type, rect)
  }, [generator, type])
}


export function useDrawPageDrop(page) {
  const tools = useCurrentTools()
  const generator = useToolGenerator()

  return useCallback((componentId, rect) => {
    rect = { ...rect, page }
    const [check, type] = componentId.split(",")

    if (check == '__new')
      generator(type, rect)
    else
      tools.tryDroppingItem(componentId, rect)
  }, [generator, tools, page])
}

export function useGenerateComponent(selectedComponents, { generateKey }) {
  const tools = useCurrentTools()

  return useCallback(({type, id, props}) => {
    const key = generateKey ? generateKey(type, id) : [type, id].join("#")
    const opts = {
      allSelected: selectedComponents
    }

    return tools.generateComponent(type, { key, ...props }, opts)
  }, [ tools, selectedComponents ])
}
