import React, { useState, useEffect, useRef, useCallback } from "react"
import { omit } from 'lodash'
import StripeCard from 'components/utility/stripe_card'
import UploadArea from 'components/utility/upload_area'
import BuildingSelect from '../utility/building_select'

//TO-DO: break into smaller components, useContext.

const MODAL_HEADERS = {
  2: 'upload documents',
  3: 'include details',
  4: 'suggest a time',
  5: 'authorize payment',
  6: 'confirm choices',
}

const ProgressBar = ({ currentStep }) => {
  const percentageClasses = {
    2: 'one-fourth',
    3: 'half',
    4: 'three-fourth',
    5: 'full',
    6: 'full'
  }

  const progressBarClass = ['progress-bar', percentageClasses[currentStep]].join(" ")

  return (
    <div className="progress-bar-wrapper">
      <div className={progressBarClass}></div>
    </div>
  )
}

export default function ENotaryModal(props) {
  const ref = useRef()
  const [disabledButton, setDisabledButton] = useState(false);
  const [files, setFiles] = useState([])
  const [currentStep, setCurrentStep] = useState(props.step); //currentStep starts at 2

  const initialEmail = props?.current_user_email || null
  const [formDetails, setFormDetails] = useState({ email: initialEmail });

  const cleanup = () => props.onClose()
  const hide = () => { $(ref.current).modal("hide") }
  const enotaryPrice = props.enotary_price / 100

  //displays modal on component load, and ensures it's closed when hidden
  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    updateAttribute("started_at", new Date().toLocaleString() + "")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])

  const goBack = () => setCurrentStep(currentStep - 1)

  //updates formDetails, which holds all form data to be submitted
  function updateAttribute(attr, value) {
    const newDetails = {
      ...formDetails, [attr]: value
    }
    setFormDetails(newDetails)
  }

  function displayFileErrorModal() {
    swal({
      title: `Error. `,
      text: "An Uploaded file has encryption or is password protected. Please edit files to ensure valid file upload(s).",
      type: "error",
      closeOnConfirm: true,
    }, function (isConfirm) {
      if (isConfirm) {
        setCurrentStep(2)
      }
    })
  }


  //to be used to submit form to controller which will initiate the emails + payments
  function submitENotaryForm(event) {
    event.preventDefault();

    const formData = new FormData();
    const last4 = props?.cards?.map(card => card.id == formDetails?.payment_method ? card.last_4 : undefined)

    for (let attr of ["suggested_time", "first_name", "last_name", "email", "mobile_number", "application_type",
      "building_unit"])
      formData.append(attr, formDetails[attr])

    formData.append("payment_token", formDetails?.token_details !== undefined ? formDetails?.token_details?.id : null)
    formData.append("payment_method", formDetails?.payment_method !== undefined ? formDetails?.payment_method : null)
    formData.append("last4", formDetails?.token_details?.card?.last4 !== undefined ? formDetails?.token_details?.card?.last4 : last4)
    formData.append("address", formDetails?.building?.address)
    formData.append("building_id", formDetails?.building?.id)

    formData.append("started_at", formDetails?.started_at)
    formData.append("submitted_at", new Date().toLocaleString() + "") // appending date/time subbmitted manually since no table exists yet for enotary

    files.map(file => formData.append("files[]", file.file))

    $.ajax({
      url: `/about/submit_enotary_form`,
      method: "POST",
      dataType: "JSON",
      contentType: false,
      processData: false,
      data: formData,
    })
      .done((res) => {
        const error = res.error
        if (error == "File is encrypted" || error === "Inappropriate ioctl for device" || error === "Invalid password ()" || error === "xref stream not found when expected") {
          displayFileErrorModal();
        }
        else {
          hide();
          props.stepToEndAndPass(formDetails?.building?.address, formDetails?.building_unit);
        }
      })
  }

  const sharedProps = {
    currentStep,
    setCurrentStep,
    goBack,
    enotaryPrice,
    setDisabledButton,
    disabledButton,
    setDisabledButton,
    formDetails,
    updateAttribute,
    onCancel: hide
  }

  return (
    <div ref={ref} className='modal e-notary-modal-screens'>
      <div className='e-notary-modal-box mobile-width'>
        <div className="e-notary-modal-header">{MODAL_HEADERS[currentStep]}</div>
        <ProgressBar currentStep={currentStep} />
        {currentStep == 2 ? <UploadSection {...{ files, setFiles, setDisabledButton }} {...omit(sharedProps, ['formDetails'])} /> : null}
        {currentStep == 3 ? <IncludeDetailsSection  {...sharedProps} setFormDetails={setFormDetails} current_user_email={props.current_user_email} /> : null}
        {currentStep == 4 ? <SuggestATimeSection  {...sharedProps} /> : null}
        {currentStep == 5 ? <PaymentSection {...sharedProps} cards={props.cards} /> : null}
        {currentStep == 6 ? <ConfirmationSection cards={props.cards} files={files} submitENotaryForm={submitENotaryForm} {...sharedProps} /> : null}
      </div>
    </div>
  )
}


// SCREEN -- 2
function UploadSection({ files, setFiles, onCancel, onSubmit, setCurrentStep, disabledButton, setDisabledButton }) {

  const nextScreen = () => setCurrentStep(3)

  const removeFile = useCallback((file) => {
    setFiles(files.filter(f => f != file))
  }, [files, setFiles])

  const addFiles = useCallback((newFiles) => {
    setFiles([...files, ...newFiles])
  }, [files, setFiles])

  useEffect(() => {
    files.length == 0 ? setDisabledButton(true) : setDisabledButton(false)
  }, [files])

  return (
    <>
      <div className="hide-input">
        <p className="step-description adjust-margin">Upload files in need of notarization below:</p>
        <div className="mobile-box" style={{ fontSize: "12px" }}>
          <UploadArea inputChanged={addFiles} exclude_icons={true}>
            <span className="file-icons"><i className="fa-thin fa-cloud-arrow-up"></i></span>
            <span>choose file(s)</span>
          </UploadArea>
        </div>
        <FileDisplayArea files={files} onRemoveFile={removeFile} />
      </div>
      <ButtonSection disabledButton={disabledButton} onCancel={onCancel} onSubmit={nextScreen} submitText="next: details" />
    </>
  )
}

function FileDisplayArea({ files, onRemoveFile }) {
  const classes = files.length > 5 ? "e-notary-upload-details file-scroll-box" : "e-notary-upload-details"
  const hasFiles = files.length < 1
  return (
    <div className={classes}>
      <p className="e-notary-uploaded-files">
        uploaded files: {files.length == 0 ? '' : files.length}
      </p>
      {hasFiles ? <p className="subtitle">Add all documents that require notarization.</p> : null}
      {!hasFiles ? files.map(file => <FileRow key={file.name} file={file} onRemove={() => onRemoveFile(file)} />) : null}
    </div>
  )
}

function FileRow(props) {
  const originalFileName = props.file.name
  const fileSize = props.file.size
  const onRemove = () => props.onRemove(originalFileName)

  function formatFileSize(fileSize) {
    var size = fileSize
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(size) / Math.log(k))

    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
  }

  const displayFileName = originalFileName.length >= 37 ? originalFileName.substring(0, 25) + "..." : originalFileName
  return (
    <div key={props.value} className="file-row">{displayFileName}
      <span style={{ float: 'right' }}>{formatFileSize(fileSize)}
        <i onClick={onRemove} className="fa-light fa-times delete-file-icon"></i>
      </span>
    </div>
  )
}

const ContactInfo = [
  { label: "first name", attr: "first_name", required: true },
  { label: "last name", attr: "last_name", required: true },
  { label: "email", attr: "email", required: true },
  { label: "mobile number", attr: "mobile_number", required: false },
]

const isBlank = v => !v || String(v).match(/^\s*$/)

// SCREEN -- 3
function IncludeDetailsSection(props) {
  const { formDetails: details } = props

  const nextScreen = () => props.setCurrentStep(4)

  const disabled =
    isBlank(details.first_name) ||
      isBlank(details.last_name) ||
      isBlank(details.email) ||
      isBlank(details.building_unit) ||
      isBlank(details.application_type) ||
      isBlank(details.building) ||
      details.custom_building !== undefined ? isBlank(details.custom_building) : ''

  const grabBuildingDetails = (e) => {
    const { building, custom_building } = e
    props.setFormDetails({ ...props.formDetails, building, custom_building })
  }

  return (
    <>
      <div>
        <p className="include-details-header">
          application details
        </p>
        {!props.formDetails?.building ? <div className="include-details-subsection" style={{ textAlign: "left" }}>
          <p className="form-label">property<span className="app-type-star">*</span></p>
        </div> : null}
        <div className="building-select">
          <BuildingSelect key="building" show_magnifying_glass={true} show_empty_message={true}
            params={{ include_addresses: 1 }} onChange={e => grabBuildingDetails(e)}
            sorryMessage={
              <>
                <div>
                  Sorry, but we could not find any matching properties.
                  <br />
                  Please try your search again.
                </div>
              </>
            }
            editPropertyText='change property'
            defaultBuilding={props.formDetails.building} defaultCustom={props.formDetails.custom_building}
          />
        </div>
        <ApplicationTypeSelect formDetails={props.formDetails} updateAttribute={props.updateAttribute} />
      </div>

      <div>
        <p className="include-details-header">
          contact info
        </p>
        {ContactInfo.map((details) => {
          return (
            <>
              <InputDetails details={details} onChange={props.updateAttribute} formDetails={props.formDetails} current_user_email={props.current_user_email} />
            </>
          )
        })}
        <ButtonSection onCancel={props.goBack} goBack={true} disabledButton={disabled} onSubmit={nextScreen} submitText="next: suggest a time" />
      </div>
    </>
  )
}

function InputDetails(props) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true)
  const handleMouseLeave = () => setShowTooltip(false)

  const onChange = (e) => props.onChange(props.details.attr, e.target.value)
  const shouldDisable = !!props?.current_user_email && props.details.attr == 'email'

  return (
    <div style={{ textAlign: "left", marginTop: "6px" }}>
      <div className="include-details-subsection">
        <p className="form-label">{props.details.label} {props.details.required == true ?
          <span className="app-type-star">*</span> : ''}
          <div className="tooltip-container">
            {shouldDisable ? (
              <i
                className="fa-light fa-circle-question enotary-tooltip"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >

              </i>
            ) : null}

            {showTooltip && (
              <div className="tooltip-text">
                  Recipient email will match your account.
                  <p>
                    To request with a different email, please log out and visit <a href="about/enotary">https://www.boardpackager.com/about/enotary</a>
                  </p>
              </div>
            )}
          </div>
        </p>
      </div>

      <input
        type="text"
        disabled={shouldDisable}
        onChange={onChange}
        className={`include-details-input ${shouldDisable ? 'enotary-disabled-field' : ''}`}
        value={props?.formDetails[props?.details.attr] || ''}
        placeholder={props.details.placeholder || ""}
      />
    </div>
  )
}

function ApplicationTypeSelect(props) {
  const applicationTypeOptions = [
    { id: null, label: "" },
    { id: "coop_sale", label: "Co-Op Sale" },
    { id: "coop_sublease", label: "Co-Op Sublet" },
    { id: "condo_sale", label: "Condo Sale" },
    { id: "condo_lease", label: "Condo Lease" },
    { id: "refinance", label: "Refinance" },
    { id: "rental", label: "Rental" },
    { id: "alteration", label: 'Alteration' },
    { id: "other", label: "Other" },
  ]
  const optionElements = applicationTypeOptions.map(({ id, label }) => <option key={id} value={id}>{label}</option>)
  //add default blank first option

  return (
    <>
      <div className="flex-and-space include-details-subsection">
        <p className="form-label">application type<span className="app-type-star" >*</span></p>
        <p className="form-label" style={{ width: '27%', textAlign: 'left' }}>unit<span className="app-type-star">*</span></p>
      </div>
      <div className="flex-and-space">
        <select
          className="include-details-select"
          value={props?.formDetails.application_type || ''}
          onChange={(e) => props.updateAttribute('application_type', e.target.value)}
        >
          {optionElements}
        </select>
        <input
          style={{ width: "27%" }}
          type="text"
          className="include-details-input"
          value={props.formDetails?.building_unit || ''}
          onChange={(e) => props.updateAttribute("building_unit", e.target.value)}
        />
      </div>
    </>
  )
}

// SCREEN -- 4
function SuggestATimeSection(props) {
  const nextScreen = () => props.setCurrentStep(5)

  useEffect(() => {
    props.formDetails?.suggested_time?.length < 1 || props.formDetails?.suggested_time == undefined
      ? props.setDisabledButton(true) : props.setDisabledButton(false)
  }, [props.formDetails]) //gotta refactor

  return (
    <>
      <div>
        <textarea
          placeholder="Please include at least one date + time that works for you."
          style={{ border: "0.5px solid #898989" }}
          className="dotted-box suggest-time"
          value={props.formDetails.suggested_time || ""}
          onChange={(e) => props.updateAttribute("suggested_time", e.target.value)}
        >
        </textarea>
        <p style={{ textAlign: "left" }} className="step-description">
          <span style={{ fontWeight: 500, color: "#4a4a4a" }}>note: </span> Notary sessions can last up to 30 minutes but are usually much shorter.
        </p>
      </div>
      <ButtonSection onCancel={props.goBack} goBack={true} disabledButton={props.disabledButton} onSubmit={nextScreen} submitText="next: payment" />
    </>
  )
}

// SCREEN -- 5
function PaymentSection({ onSubmit, enotaryPrice, setCurrentStep, updateAttribute, disabledButton, currentStep, goBack, formDetails, cards }) {
  const stripe_card = useRef(null)
  const [paymentMethod, setPaymentMethod] = useState(null)

  const grabTokenAndStep = (tokenDetails) => {
    updateAttribute("token_details", tokenDetails)
    setCurrentStep(6)
  }

  const grabExistingPaymentMethod = (PM) => {
    updateAttribute("payment_method", PM)
    setCurrentStep(6)
  }

  useEffect(() => {
    if (paymentMethod == "new-card") updateAttribute("payment_method", null)
    else updateAttribute("token_details", null)
  }, [formDetails?.token_details, paymentMethod])

  const handleSubmit = useCallback(() => {
    if (!paymentMethod || !stripe_card.current)
      return

    if (paymentMethod != 'new-card')
      return grabExistingPaymentMethod(paymentMethod)

    stripe_card.current.generateAllTokenDetails().then(tokenDetails => grabTokenAndStep(tokenDetails))
  }, [stripe_card.current, paymentMethod, onSubmit])

  const changePaymentMethod = useCallback((pm) => setPaymentMethod(pm), [setPaymentMethod])
  return (
    <>
      <p style={{marginBottom: '18px'}}>
        A hold of ${enotaryPrice} will be placed on your card. Your card will be charged after e-notary appointment.
      </p>
      <StripeCard cards={cards} ref={stripe_card} onChangePaymentMethod={changePaymentMethod} hideCityField={true} />
      <ButtonSection onCancel={goBack} goBack={true} currentStep={currentStep} disabledButton={disabledButton} onSubmit={handleSubmit} />
    </>
  )
}

const getCardInfo = (cards, formDetails) => {
  let cardDetails = undefined
  let isNewCard = undefined


  const newCard = formDetails?.token_details?.card

  if (newCard) {
    isNewCard = true

    cardDetails = {
      last_4: newCard.last4,
      expires: {
        month: newCard.exp_month,
        year: newCard.exp_year,
      },
      name: newCard.name,
    }

    return [cardDetails, isNewCard]
  }

  const savedCard = cards?.find((card) => card.id == formDetails?.payment_method)

  if (savedCard) {
    isNewCard = false

    cardDetails = {
      last_4: savedCard.last_4,
      expires: {
        month: savedCard.expires[0],
        year: savedCard.expires[1],
      },
      name: savedCard.name,
    }
  }

  return [cardDetails, isNewCard]
}

//SCREEN -- 6
function ConfirmationSection(props) {
  const [cardDetails, isNewCard] = getCardInfo(props.cards, props.formDetails);

  const submit = (e) => {
    props.setDisabledButton(true);
    props.submitENotaryForm(e);
  }

  const returnApplicationTypeLabel = (type) => {

    switch (type) {
      case 'coop_sale':
        return 'Co-Op Sale'
      case 'coop_sublease':
        return 'Co-Op Sublet'
      case 'condo_sale':
        return 'Condo Sale'
      case 'condo_lease':
        return 'Condo Lease'
      case 'refinance':
        return 'Refinance'
      case 'rental':
        return 'Rental'
      case 'alteration':
        return 'Alteration'
      case 'other':
        return 'Other'
    }
  }

  const returnFileName = (name) => {
    const fileName = name.length >= 27 ? name.substring(0, 20) + "..." : name
    return fileName
  }

  const renderCardInfo = () => {
    if (!cardDetails) return null

    return (
      <>
        <p>{cardDetails.name}</p>
        <p>{isNewCard ? `...${cardDetails.last_4}` : `...${cardDetails.last_4} (saved card)`}</p>
        <p>{cardDetails.expires.month}/{cardDetails.expires.year}</p>
      </>
    )
  }

  // Need to reset token details when back to handle choosing existing payment methods in case e-notary to be used with existing users
  const onBack = () => {
    props.updateAttribute('token_details', null)
    props.goBack()
  }

  return (
    <>
      <div className="confirmation-box">
        <div className="confirmation-row">
          <div className="confirmation-label">file</div>
          <div className="confirmation-details">
            {props.files.map(file => (
              <p>{returnFileName(file.file.name)}</p>
            ))}
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label">property</div>
          <div className="confirmation-details">
            <p>{props.formDetails?.building?.address}</p>
            <p>Unit {props.formDetails?.building_unit}</p>
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label">app type</div>
          <div className="confirmation-details">
            <p>{returnApplicationTypeLabel(props.formDetails?.application_type)}</p>
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label">signatory</div>
          <div className="confirmation-details">
            <p>{props.formDetails?.first_name + ' ' + props.formDetails?.last_name}</p>
            <p>{props.formDetails?.email}</p>
            <p>{props.formDetails?.mobile_number}</p>
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label">suggested time</div>
          <div className="confirmation-details">
            <p>{props.formDetails.suggested_time || ""}</p>
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label">card info</div>
          <div className="confirmation-details">
            {renderCardInfo()}
          </div>
        </div>
        <div className="confirmation-row">
          <div className="confirmation-label" style={{ fontWeight: 500, fontSize: "16px" }}>total authorized:</div>
          <div className="confirmation-details" style={{ fontWeight: 500, fontSize: "16px" }}>${props.enotaryPrice}</div>
        </div>
      </div>
      <p style={{ marginTop: "1rem", textAlign: 'left' }}>
        By submitting, you agree to the <a href="/about/legal" className="link-aqua terms-conditions-link" target="_blank">Terms + Conditions</a> and to maintain a balance that is equal or greater than total authorized until payment is collected.
      </p>
      <ButtonSection currentStep={props.currentStep} disabledButton={props.disabledButton} onCancel={onBack} goBack={true} onSubmit={submit} submitText="confirm" />
    </>
  )
}

//Buttons at bottom of each screen
function ButtonSection({ onCancel, onSubmit, submitText = "submit request", disabledButton, currentStep, goBack }) {
  const classes = !disabledButton ? 'btn-base btn-green-stroke' : 'btn-base btn-disabled'
  const classesIfPayOrConfirm = !disabledButton ? 'btn-base btn-go-green' : 'btn-base btn-disabled'

  return (
    <form onSubmit={onSubmit} id="enotary-form" encType='multipart/form-data'>
      <div className="e-notary-modal-buttons">
        <button type="button" className={goBack ? "btn-link back-link" : "btn-link cancel-link"} onClick={onCancel}>{goBack ? "back" : "cancel"}</button>
        <button
          disabled={disabledButton}
          className={currentStep > 4 ? classesIfPayOrConfirm : classes}
          onClick={onSubmit}
          type="button"
        >
          {submitText}
        </button>
      </div>
    </form>
  )
}
