import React from 'react'

import DocumentsTable from './documents_table'
import NewDocuments from './new_documents'

class DocumentManager extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState(){
    return {
      activeTab: "new_document",
      documents: this.props.documents || [],
      docTypes: this.props.docTypes,
      buildingId: this.props.buildingId,
      librarySearchType: 'all',
      librarySearch: '',
      updateMultipleStoreDocumentsPath: this.props.update_multiple_store_documents_path,
      json_documents_path: this.props.json_documents_path,
      attach_library_document_to_building_path: this.props.attach_library_document_to_building_path,
      valid_building_store_documents_path: this.props.valid_building_store_documents_path,
      myDocuments: {},
      isActive: false
    }
  }

  componentWillMount() {
    Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
       return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
     };
  }

  componentDidMount(){
    // listen for open and closed modal to set isActive state
    $(this.refs.modal).on('shown.bs.modal', () => this.getBuildingStoreDocuments());
    $(this.refs.modal).on('hidden.bs.modal', () => this.onUpdateEditAccordion());

    this.getMyLibraryDocuments();
    this.getBuildingStoreDocuments(false)

    window.documents_manager_component = this
  }

  componentWillUnmount() {
    delete window.documents_manager_component
  }

  open(events) {
    if (typeof events != "object")
      events = {}

    if (typeof events.documentsChanged == "function")
      this.onDocumentsChangedHandler = events.documentsChanged

    if (typeof events.closed == "function")
      this.onCloseHandler = events.closed

    $(this.refs.modal).modal("show")
  }

  getMyLibraryDocuments(){
    $.getJSON('/my_documents',(data)=> {
        let myDocuments = data.library
        this.setState({
          myDocuments
        })
    })
  }

  getBuildingStoreDocuments(isActive=true){
    const docTypes = this.state.docTypes
    $.ajax({
      url: this.state.valid_building_store_documents_path,
      data: {building_id: this.state.buildingId},
      dataType: 'json',
      method: 'get',
      success: (data) => {
        let documents = data.valid_building_store_documents
        documents = documents.map(doc => this.state.docTypes[doc.file_class] != undefined ? doc : { ...doc, file_class: 'other' })
        this.setState({
          documents,
          isActive: isActive
        })
      }
    })
  }

  onUpdateEditAccordion(){
    // Remove documents change handler
    delete this.onDocumentsChangedHandler

    // to keep in sync with edit accordion, should be called when !isActive
    if (this.onCloseHandler) {
      this.onCloseHandler()
      delete this.onCloseHandler
      return
    }

    let data = {building_id: this.state.buildingId, open_panels: collectOpenPanels()}
    $.ajax({
      url: '/building_store_documents/:building_store_document_id/edit_accordion',
      data: data,
      dataType: 'script',
      method: 'get',
      success: (data) => {
        this.setState({
          isActive: false,
          activeTab: 'new_document'
        })
      }
    })
  }

  collectOpenPanels(){
    var openPanels = []
    $('#accordionDocuments .panel-collapse').each(function(idx, item){
        if (item.getAttribute('class').match('in$')){
          var splitId = item.getAttribute('id').split('-')
          var itemSection = splitId[splitId.length - 1]
          if (itemSection !== 'type'){
            openPanels.push(itemSection)
          }
        }
    })
    return openPanels
  }

  onDropzoneSuccessResponse(buildingData){
    let documents = this.state.documents
    let myDocuments = this.state.myDocuments

    buildingData['editable'] = true

    // return if document is already added
    if (documents.some(doc => doc.id == buildingData.id))
      return null

    documents.unshift(buildingData)
    myDocuments.unshift(buildingData)

    this.setState({
      documents,
      myDocuments
    })
  }

  onDocumentsChanged(documents) {
    if (typeof this.props.onDocumentsChanged == "function")
      this.onDocumentsChanged(documents)

    if (typeof this.onDocumentsChangedHandler == "function")
      this.onDocumentsChangedHandler(documents)
  }

  handleAttachDocument(doc){
    let data = {building_id: this.state.buildingId, document: doc}
    $.ajax({
      url: this.props.attach_library_document_to_building_path,
      data: data,
      method: 'POST',
      dataType: 'json',
      success: function(data){
        let documents = this.state.documents
        documents.unshift(data.store_document)
        this.setState({
          documents
        })

        this.onDocumentsChanged(documents)
      }.bind(this)
    })
  }

  handleDocumentDelete(idx){
    let params = {id: idx, building_id: this.state.buildingId}
    $.ajax({
      url: `/building_store_documents/detach`,
      data: params,
      method: 'DELETE',
      dataType: 'json',
      success: function(data){
        let docId = Number(data.document)
        let documents = this.state.documents.filter((doc) => doc.id !== docId )
        this.setState({
          documents
        })
        this.onDocumentsChanged(documents)
      }.bind(this)
    })
  }

  onTabChange(tab){
    if (this.state.activeTab !== 'library'){
      this.getMyLibraryDocuments()
    }
    this.setState({
      activeTab: tab
    })
  }

  onAllSubmit(){
    if (this.state.documents.length > 0){
      $.ajax({
        data: { store_documents: this.state.documents },
        url: this.state.updateMultipleStoreDocumentsPath,
        dataType: 'json',
        method: 'post',
        success: (data) => {
          this.onDocumentsChanged(this.state.documents)
        }
      })
    }
  }


  getBuildingStoreLibraryDocumentIds(){
    return this.state.documents.map((bsd, i) => { return bsd['library_document_id'] });
  }

  onDocumentInputChange(value, documentId, changedState){
    let documents = Object.keys(this.state.documents).map((key,i) => {
      if (this.state.documents[key].id === documentId){
        var newDocument = this.state.documents[key]
        newDocument[changedState] = value
        return newDocument
      } else {
        return this.state.documents[key]
      }
    })
    this.setState({
      documents
    })
  }

  onLibrarySearch(){
    return this.state.myDocuments.filter((doc) => {
      return (doc.file_class !== this.librarySearchType)
    })
  }

  onLibraryInputTypeChange(librarySearchType){
    this.setState({
      librarySearchType
    })
  }

  onLibrarySearchChange(librarySearch){
    this.setState({
      librarySearch
    })
  }

  visibleSection(){
    switch(this.state.activeTab) {
      case 'new_document':
        return (
          <div className="new-document-container">
            <NewDocuments
              buildingId={this.props.buildingId}
              authenticityToken={this.props.authenticity_token}
              onDropzoneSuccessResponse={this.onDropzoneSuccessResponse.bind(this)}
            />
            <DocumentsTable
              handleDocumentDelete={this.handleDocumentDelete.bind(this)}
              documents={this.state.documents}
              activeTab={this.state.activeTab}
              docTypes={this.state.docTypes}
              onDocumentInputChange={this.onDocumentInputChange.bind(this)}
              checkImagePath={this.props.checkImagePath}
              buildingId={this.props.buildingId}
            />
          </div>
        )
    }
  }


  render(){
    let headerStyle = {
      textAlign: 'center',
    }

    return (
      <div className="modal fade bd-example-modal-lg" ref="modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div style={{'padding': '20px'}}>
              <div className="col-xs-12">
                <h4 className='text-bopa' style={headerStyle}>Document Upload and Allocation</h4>
              </div>
              {this.visibleSection()}
              <div className='col-xs-12'>
              <button
                className='btn btn-default pull-right'
                data-dismiss="modal"
                style={{marginTop: '20px'}}
                onClick={this.onAllSubmit.bind(this)}>
                Save and Exit
              </button>
              </div>
              <br/><br/><br/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentManager
