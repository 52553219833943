import React from 'react'
import Button from './Button'
import ArrowIcon from './ArrowIcon'

const SSOCheck = (props) => {
  return (
    <>
      {props.isLoading ? (
        <div className="loader-spinner-container">
          <i className="fa-duotone fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <Button type="submit" className={`rounded-edges arrow-button ${props.isValidEmail ? 'valid' : ''}`}>
          <ArrowIcon />
        </Button>
      )}
    </>
  )
}

export default SSOCheck
