import React from 'react'
import PropTypes from 'prop-types'


function object_to_params(obj) {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&')
}

class BuildingSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      buildings: [],
      building: props.defaultBuilding,
      address: props.defaultAddress,
      custom: props.defaultCustom
    }
  }

  url(q) {
    const url = this.props.url || "/buildings/search_suggestions",
          query = { query: q || this.state.query, count_results: true }

    if (this.props.organization)
      query.organization_id = this.props.organization

    query.only_live = this.props.include_disabled ? false : true

    if (typeof(this.props.params) == "object")
      Object.assign(query, this.props.params, query)

    return url + "?" + object_to_params(query)
  }

  clearData() {
    this.setState({ buildings: [], addresses: [], building:null, address: null, query: null })
    if (typeof(this.props.onChange) == "function")
      this.props.onChange({building: null, address: null, custom_building: null})
  }

  rateLimitQueryUpdater(new_value) {
    this.setState({query: new_value})

    clearTimeout(this.rated_limited_lookup)
    this.rated_limited_lookup = setTimeout(() => this.findPossibleBuildings(), 500)
  }

  validQuery(query) {
    query = query || this.state.query
    return query && query.length >= 2
  }

  findPossibleBuildings(query) {
    if (!this.validQuery(query))
      return this.setState({buildings: [], addresses: []})

    this.setState({searching: true})
    $.ajax({
      url: this.url(query),
      success: response => this.setState({buildings: response.buildings, addresses: [], searching: null})
    })
  }

  setBuildingAndAddresses(building, addresses) {
    const state = {}
    state.building = building
    state.addresses = addresses || []
    state.address = state.addresses.length == 1 ? state.addresses[0] : null
    this.setState(state)

    if (typeof(this.props.onChange) != "function")
      return

    const response = Object.assign({}, state)
    delete response.addresses
    delete response.custom
    this.props.onChange(response)
  }

  transferCustomData() {
    if (typeof(this.props.onChange) != "function")
      return

    const is_blank = f => !this.state.custom[f] || String(this.state.custom[f]).match(/^\s*$/)
    for (let field of ["address", "city", "state", "zip"])
      if (is_blank(field))
        return this.props.onChange({custom_building: null, building: null, address: null})

    this.props.onChange({custom_building: this.state.custom, building: null, address: null})
  }

  setCustomBuildingField(event, field) {
    const custom = this.state.custom || {}
    custom[field] = event.target.value
    this.setState({custom: custom})
    this.transferCustomData()
  }

  renderSelectedBuilding() {
    const building = this.state.building

    if (!building)
      return

    if (this.props.page_name == "qr_processor") {

      const input = this.props.name ? <input type="hidden" name={this.props.name} value={building.id} /> : ""

      return (
        <div className="selected-building">
          <div className="address">
            <div className="form-control">{building.title}</div>
          </div>
          {input}
        </div>
      )
    } else {

      const new_buildings = this.props.is_static ? "" : <div className="new-building"><button className='btn-link back-link' onClick={() => this.clearData()}>{this.props.editPropertyText || 'change property'}</button></div>,
        input = this.props.name ? <input type="hidden" name={this.props.name} value={building.id} /> : ""

      return (
        <div className="selected-building">
          <div className="address">
            <div className="title">{building.title}</div>
            <div className="city">{building.city}, {building.state} {building.zip}</div>
          </div>
          {new_buildings}
          {input}
        </div>
      )
    }
  }


  renderBuildingPossibilities() {
    const buildings = []

    if (this.props.page_name == "qr_processor") {
      for ( let building of this.state.buildings )
        buildings.push(
          <div className={"suggestion-item-address col-xs-12"} key={building.id} onClick={e => this.setBuildingAndAddresses(building, building.addresses)}>
            <span>{building.title}</span>
              <br />
            <span>{building.address}</span>
          </div>
        )
    } else {
        for ( let building of this.state.buildings )
        buildings.push(<div key={building.id} onClick={e => this.setBuildingAndAddresses(building, building.addresses)}>{building.title}</div>)
    }

    if (buildings.length == 0)
      return this.renderNoPossibilities()

    return <div className="building-possibilities">
      {buildings}
    </div>
  }

  renderNoPossibilities() {
    if (!this.props.show_empty_message)
      return null

    if (!this.validQuery())
      return null

    if (this.state.searching)
      return null

    let bp_info = null
    if (this.props.contact_bp) {
      bp_info = <div className="bp-info">
        Contact <a href="mailto:info@boardpackager.com">info@boardpackager.com</a> if you have any questions or requests
      </div>
    }

    let start_custom = null
    if (this.props.allow_custom) {
      start_custom = <div className="start-custom">
        <a href="javascript:void(0)" onClick={e => this.setState({custom: {}})}>Click here</a> to enter your property details
      </div>
    }

    const sorryMessage = this.props.sorryMessage || "Sorry, we couldn't find your property."

    return <div className="no-matches">
      <div className='sorry-message'>{sorryMessage}</div>
      {bp_info}
      {this.props.custom_element || start_custom}
    </div>
  }

  renderBuildingSearch() {
    if (this.state.building || this.state.custom)
      return

    const inside_container = []
    const container_classes = ["input-container"]

    if (this.props.show_magnifying_glass) {
      container_classes.push("left-items")
      inside_container.push(<i className="fa-light fa-magnifying-glass" key="mag-glass" />)
    }

    if (this.props.page_name == "qr_processor") {
      inside_container.push(<input className={"form-control"} key="input" type="text" defaultValue={this.state.query} placeholder="Property" onChange={e => this.rateLimitQueryUpdater(e.target.value)} />)

    } else {
      inside_container.push(<input key="input" type="text" defaultValue={this.state.query} placeholder={this.props.placeholder || "find a property..."} onChange={e => this.rateLimitQueryUpdater(e.target.value)} />)
    }

    return <div className="building-select">
      <div className={container_classes.join(" ")}>{inside_container}</div>
      {this.renderBuildingPossibilities()}
    </div>
  }

  restartSearchFromCustom() {
    this.findPossibleBuildings(this.state.custom.address)
    this.setState({query: this.state.custom.address, custom: null})
  }

  renderCustomBuilding() {
    if (!this.state.custom)
      return

    return <div className="custom-building">
      <div className="title">Enter the Building Information here</div>
      <div className="address field"><input type="text" placeholder="Address" onChange={e => this.setCustomBuildingField(e, "address")} /></div>
      <div className="city-state-zip">
        <div className="city field"><input type="text" placeholder="City" onChange={e => this.setCustomBuildingField(e, "city")} /></div>
        <div className="state field"><input type="text" placeholder="State" onChange={e => this.setCustomBuildingField(e, "state")} /></div>
        <div className="zip field"><input type="text" placeholder="Zip / Postal Code" onChange={e => this.setCustomBuildingField(e, "zip")} /></div>
      </div>

      <a className="search-again" href="javascript:void(0)"
          onClick={e => this.restartSearchFromCustom()}>
          Search for this Building
      </a>
    </div>
  }

  render() {
    return <div className="building-select-area">
      {this.renderSelectedBuilding()}
      {this.renderCustomBuilding()}
      {this.renderBuildingSearch()}
    </div>
  }
}

export default BuildingSelect
