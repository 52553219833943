import React from 'react'

class OrganizationItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCheckDefault = this.handleCheckDefault.bind(this)
  }

  handleCheck(e) {
    let checked = e.target.checked
    this.props.handleCheck(this.props.organization.id, checked)
    if (checked && this.noSelectedOrgs() && this.hasDefault())
      this.props.handleCheckDefault(this.props.organization.id)
  }

  handleCheckDefault(e) {
    let checked = e.target.checked

    if (checked)
      this.props.handleCheckDefault(this.props.organization.id)
  }

  noSelectedOrgs() {
    return this.props.selected.length == 0 || false
  }

  isChecked() {
    return this.props.checked
  }

  isDisabled() {
    return !this.props.checked
  }

  isDefault() {
    return this.props.is_default || false
  }

  hasDefault() {
    return this.props.has_default || false
  }

  id() {
    return `${this.props.relationship}_${this.props.organization.id}`
  }

  default_id() {
    return this.id() + "_default"
  }

  renderCheckbox(checkbox) {
    return <div className='col-xs-2 text-center'>{checkbox}</div>
  }

  renderInput() {
    const name = `relation[${this.props.relationship}][selected]`
    const value = this.props.organization.id

    return this.renderCheckbox(<input type="checkbox" id={this.id()} name={name} value={value} checked={this.isChecked()} onChange={(e) => this.handleCheck(e)} />)
  }

  renderDefaultInput() {
    const name = `relation[${this.props.relationship}][default]`,
          value = this.props.organization.id

    return this.renderCheckbox(<input type="radio" disabled={this.isDisabled()} id={this.default_id()} name={name} value={value} checked={this.isDefault()} onChange={(e) => this.handleCheckDefault(e)} />)
  }

  render() {
    return (
      <div className='row'>
        <div className={this.hasDefault() ? "col-xs-1" : "col-xs-3"}></div>
        {this.renderInput()}
        {this.hasDefault() ? this.renderDefaultInput() : ""}
        <div className='col-xs-7'>
          <label htmlFor={this.id()}>{this.props.organization.name}</label>
        </div>
      </div>
    )
  }
}

export default OrganizationItem
