import React from 'react'
import PropTypes from 'prop-types'
import DateFilter from '../Filters/DateFilter'
import ActiveFilters from '../Filters/ActiveFilters'

const DEFAULT_COLUMNS = [
  "transactable_address",
  "transactable_unit",
  "transactable_current_amount",
  "transactable_name",
  "transaction_date",
  "stripe_payout_date",
  "transactable_captured_by_name",
  "transactable_type",
  "transactable_account_description"
]

export default class PaymentExport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      canSubmit: false
    }

    this.modal = null
    this.form = null
    this.columnsContainer = null
    this.selectAllCheckbox = null

    this.setModalRef = element => { this.modal = element }
    this.setFormRef = element => { this.form = element }
    this.setColumnsContainerRef = element => { this.columnsContainer = element }
    this.setSelectAllCheckboxRef = element => { this.selectAllCheckbox = element }

    this.submit = this.submit.bind(this)
    this.updateSubmittable = this.updateSubmittable.bind(this)
    this.handleChangeColumnCheckbox = this.handleChangeColumnCheckbox.bind(this)
    this.toggleSelectAllColumns = this.toggleSelectAllColumns.bind(this)
  }

  componentDidMount() {
    this.open()
    this.listenModalClose()
    this.updateSubmittable()
    $("._tooltip").tooltip()
  }

  open() {
    $(this.modal).modal('show')
  }

  close() {
    this.props.close()
  }

  submit(e) {
    e.preventDefault()

    $.ajax({
      url: "/api/v2/financials/export",
      method: "POST",
      dataType: "JSON",
      data: $(this.form).serialize(),
      success: function() {
        swal({
          title: "Success!",
          text: "Your export will be emailed to you shortly.",
          type: "success"
        })
      }
    })
  }

  listenModalClose() {
    $(this.modal).on("hidden.bs.modal", function () {
      this.close()
    }.bind(this));
  }

  handleChangeColumnCheckbox() {
    this.updateSubmittable()

    const checkboxes = $(this.columnsContainer).find("input:checkbox").toArray()

    if (checkboxes.some((c) => !c.checked))
      return this.selectAllCheckbox.checked = false

    if (checkboxes.every((c) => c.checked))
      return this.selectAllCheckbox.checked = true
  }

  updateSubmittable() {
    const canSubmit = $(".column-option input:checkbox").is(":checked")

    if (this.state.canSubmit != canSubmit)
      this.setState({canSubmit: canSubmit})
  }

  toggleSelectAllColumns() {
    const checkboxes = $(this.columnsContainer).find("input:checkbox")

    for (let i=0; i<checkboxes.length; i++) {
      checkboxes[i].checked = this.selectAllCheckbox.checked
    }

    this.updateSubmittable()
  }

  renderActiveFilters() {
    const { date, ...filters } = this.props.filters

    if (filters.length == 0)
      return

    return <div className="active-filters-container">
      <div className="active-filters-label">Filters Applied</div>
      {<ActiveFilters
        filters={filters}
        canDelete={false}
        accounts={this.props.accounts}
        renderHiddenInputs={true} />}
    </div>

  }

  renderDateFilter() {
    return <div>
      <div className="orange text-center">Set Date Range</div>
      <DateFilter date={this.props.filters["date"]} hideIcon={true} />
    </div>
  }

  renderCheckCollectionFilter() {
    const tooltipText = "Check collection fees are fees recorded in closing statements but haven't been processed through BoardPackager."

    return <div className="text-center check-colleted-fees-filter">
      <div>
        <span className="orange">Include Check Collection Fees</span>
        <i className="fa fa-question-circle-o _tooltip" data-placement="right" data-original-title={tooltipText}></i>
      </div>
      <div>
        <span className="input-label-container">
          <input type="radio"
            required
            id="filter-include-check-collected-fees-yes"
            className="radio-option"
            name={`filters[includeCheckCollectedFees]`}
            value={true}
            defaultChecked={false} />
          <label htmlFor="filter-include-check-collected-fees-yes">Yes</label>
        </span>
        <span className="input-label-container">
          <input type="radio"
            required
            id="filter-include-check-collected-fees-no"
            className="radio-option"
            name={`filters[includeCheckCollectedFees]`}
            value={false}
            defaultChecked={true} />
          <label htmlFor="filter-include-check-collected-fees-no">No</label>
        </span>
      </div>
    </div>
  }

  renderSelectAllCheckbox() {
    return <div className="columns-select-all-container text-center">
      <input type="checkbox"
        ref={this.setSelectAllCheckboxRef}
        id="columns-select-all"
        style={{"marginRight": 2}}
        onChange={this.toggleSelectAllColumns} />
      <label htmlFor="columns-select-all">Select All</label>
    </div>
  }

  renderColumns() {
    const { exportColumns } = this.props
    const columnsList = []
    const columnsWithRowsList = []

    for (const key in exportColumns) {
      const id = `column-${key}`

      columnsList.push(
        <div key={`option-${id}`} className="col-xs-3 column-option">
          <input type="checkbox"
            id={id}
            name={`columns[]`}
            value={key}
            onChange={this.handleChangeColumnCheckbox}
            defaultChecked={DEFAULT_COLUMNS.indexOf(key) > -1} />
          <label htmlFor={id}>{exportColumns[key]}</label>
        </div>
      )
    }

    let rowColumns = []

    for (let i=0; i<columnsList.length; i++) {
      if (i != 0 && i % 4 == 0) {
        columnsWithRowsList.push(<div className="row" key={`row-${i}`}>{rowColumns}</div>)
        rowColumns = []
      }
      rowColumns.push(columnsList[i])

      if (i == columnsList.length-1)
        columnsWithRowsList.push(<div className="row" key={`row-last`}>{rowColumns}</div>)
    }

    return <div className="columns-parent-container">
      <div className="columns-label">Columns</div>
      {this.renderSelectAllCheckbox()}
      <div className="columns-container" ref={this.setColumnsContainerRef}>
        {columnsWithRowsList}
      </div>
    </div>
  }

  render() {
    return <div className="modal payment-export-modal" role="dialog" ref={this.setModalRef}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Export Data</h5>
          </div>
          <form ref={this.setFormRef} onSubmit={event => this.submit(event)}>
            <div className="modal-body">
                {this.renderActiveFilters()}
                {this.renderDateFilter()}
                {this.renderCheckCollectionFilter()}
                {this.renderColumns()}
            </div>
            <div className="modal-footer">
              <div className="footer-text">
                <p><span className="orange">Note:</span> Filters applied will be reflected in the exported data.</p>
                <p>To edit the filters, select Cancel and edit on the previous screen.</p>
              </div>
              <div className="footer-buttons">
                <button key="modal-dismiss" type="button" className="btn btn-secondary cancel-btn" data-dismiss="modal">Cancel</button>
                <button key="modal-submit" type="submit" disabled={!this.state.canSubmit} className="btn btn-success export-btn">Export</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  }
}

PaymentExport.propTypes = {
  filters: PropTypes.object,
  close: PropTypes.func.isRequired,
  exportColumns: PropTypes.object.isRequired,
  accounts: PropTypes.array
}

PaymentExport.defaultProps = {
  filters: {}
}
