import React, { useState } from 'react'

export default function PackageTemplateYamlUpload({ package_hashed_id }) {
  const [uploadButtonVisible, setUploadButtonVisible] = useState(false)
  const showUploadButton = () => {
    setUploadButtonVisible(true)
  }
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = () => {
    setSubmitting(true)
  }

  return (
    <div className="well">
      <form
        action={`/package_templates/${package_hashed_id}/pt_yaml_dropzone_upload`}
        method="post"
        onSubmit={onSubmit}
        encType="multipart/form-data"
      >
        <input onChange={showUploadButton} className="btn btn-default" type="file" name="file" id="file" />
        <div id="uploadButton" style={{ display: uploadButtonVisible ? 'block' : 'none' }}>
          <input type="submit" name="commit" className="btn btn-default" value="Upload" disabled={submitting} />
        </div>
      </form>
      <form
        className="button_to"
        method="get"
        action={`/package_templates/${package_hashed_id}/download_yaml_template`}
      >
        <button className="btn btn-default" type="submit">
          Download template YAML file
        </button>
      </form>
    </div>
  )
}
