import React from 'react'
import PropTypes from 'prop-types'
import { DynamicComponents } from '../../DynamicComponents';
import AddNewDealPartyForm from './add_new_deal_party_form';


export function addComponent(component_name, component) {
  DynamicComponents[component_name] = component;
}

function renderComponent(component_name, component_properties) {
  const Component = DynamicComponents[component_name]

  if (!Component)
    return null

  if (typeof component_properties != "object")
    component_properties = {}

  return <Component {...component_properties} />
}

  class Modal extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        visible: false,
        recipients: this.initRecipientsState(),
        addNewRecipientsFormVisible: false,
        submitting: false
      }

      this.open = this.open.bind(this)
      this.addNewRecipient = this.addNewRecipient.bind(this)

      this.modal = null

      this.setModalRef = element => { this.modal = element }

    }

    initRecipientsState() {
      const recipientState = {}
      const { recipients, recipientEmailsSentAt } = this.props

      recipients.forEach(recipient => {
        let { 'email': email, ...recipientWithoutEmail } = recipient
        recipientWithoutEmail['sentAt'] = recipientEmailsSentAt[recipient['email']] || null
        recipientWithoutEmail['checked'] = false

        recipientState[recipient['email']] = recipientWithoutEmail
      })

      return recipientState
    }

    componentDidMount() {
      this.setGlobalVariables()
    }

    componentWillUnmount() {
      this.clearGlobalVariables()
    }

    setGlobalVariables() {
      window.send_email_modal = this
      window.send_email_modal_open = this.open
    }

    clearGlobalVariables() {
      delete window.send_email_modal
      delete window.send_email_modal_open
    }

    open() {
      $(this.modal).modal('show')
    }

    close() {
      $(this.modal).modal('hide')
    }

    submit() {
      this.setState({ submitting: true })

      const { submitUrl } = this.props

      let checkedRecipients = this.state.recipients
      let recipientsFormData = []

      for (const email in checkedRecipients) {
        const recipient = checkedRecipients[email]
        if (recipient['checked'])
        recipientsFormData.push({ email: email, name: recipient['full_name'], role: recipient['role'] })
      }

      $.ajax({
        url: submitUrl,
        data: { recipients: recipientsFormData },
        method: 'post',
        success: res => {
          this.submitSuccessSwal()
          this.close()
          this.setState({ submitting: false })
        }
      })
    }

    submitSuccessSwal() {
      swal({
        title: 'Survey Sent',
        text: 'The survey has been sent successfully to all requested parties.',
        type: 'success',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function(isConfirm) { isConfirm ? window.location.reload() : null })
    }

    toggleAddNewRecipient() {
      this.setState({ addNewRecipientsFormVisible: !this.state.addNewRecipientsFormVisible })
    }

    addNewRecipient(recipientName, recipientEmail, recipientRole) {
      let recipients = this.state.recipients

      // cannot add recipient if recipient is already in state
      if (recipients[recipientEmail])
        return null

      recipients[recipientEmail] = {
        full_name: recipientName,
        role: recipientRole,
        checked: false,
        sentAt: null
      }

      this.setState({ recipients: recipients })
    }

    checkOrUncheckAllRecipients(checked) {
      let updatedRecipients = this.state.recipients

      for (const email in updatedRecipients)
        updatedRecipients[email]['checked'] = checked

      this.setState({ recipients: updatedRecipients })
    }

    isEveryRecipientsChecked() {
      return Object.values(this.state.recipients).every(recipient => recipient['checked'])
    }

    isAnyRecipientChecked() {
      return Object.values(this.state.recipients).some(recipient => recipient['checked'])
    }

    isNoRecipientsChecked() {
      return Object.values(this.state.recipients).every(recipient => !recipient['checked'])
    }

    numberOfChecked() {
      let counter = 0

      for(const email in this.state.recipients) this.state.recipients[email]['checked'] ? counter++ : null

      return counter
    }

    renderAddNewRecipientForm() {
      if (!this.state.addNewRecipientsFormVisible)
        return null

      // dynamic components
      if (!this.props.addNewRecipientComponentName)
        return null

      const props = { ...this.props.addNewRecipientComponentProps }
      props['addRecipient'] = this.addNewRecipient

      return renderComponent(this.props.addNewRecipientComponentName, props)
    }

    clickRecipientCheckbox(recipientEmail, checked) {
      const recipientsState = this.state.recipients

      recipientsState[recipientEmail]['checked'] = !recipientsState[recipientEmail]['checked']

      this.setState({ recipients: recipientsState })
    }

    clickSelectAllCheckbox() {
      this.isEveryRecipientsChecked() ? this.checkOrUncheckAllRecipients(false) : this.checkOrUncheckAllRecipients(true)

    }

    recipientsTableRowList() {
      const recipientsList = []

      const { recipients } = this.state

      for(const email in recipients) {
        const recipient = recipients[email]
        recipientsList.push(
          <tr key={`recipient-tr-${email}`}>
            <td className="checkbox-td">
              <input type='checkbox'
                    className="survey-checkbox"
                     checked={recipient['checked']}
                     onChange={() => this.clickRecipientCheckbox(email)}/>
            </td>
            <td>{recipient['full_name']}</td>
            <td>{recipient['role']}</td>
            <td>{email}</td>
            <td>{this.props.recipientEmailsSentAt[email]}</td>
          </tr>
      )}

      return recipientsList
    }

    renderRecipientsTable() {
      return <table className='recipients-table'>
        <thead>
          <tr>
            <th className="select-all-tr"><input
              type='checkbox'
              checked={this.isEveryRecipientsChecked()}
              disabled={this.state.submitting}
              onChange={() => this.clickSelectAllCheckbox()}/>
                Select All
            </th>
            <th>Name</th>
            <th>Role</th>
            <th>Email</th>
            <th>Date Sent</th>
          </tr>
        </thead>
        <tbody>
          {this.recipientsTableRowList()}
        </tbody>
      </table>
    }

    renderAddNewRecipientButton() {
      if (!this.props.showAddNewRecipientButton)
        return null

      return <div>
        <button onClick={() => this.toggleAddNewRecipient()} className='add-recipient-button'>
          <span className='plus-icon-container'>
            <i id='add-recipient-plus-icon' className="fa-light fa-plus plus-style" />
          </span>
          <span>
            {this.props.addNewRecipientButtonText}
          </span>
        </button><br/><br/>
        {this.renderAddNewRecipientForm()}
      </div>
    }

    renderSubmitButton() {
      const numberOfChecked = this.numberOfChecked(),
            buttonText = numberOfChecked > 0 ? `Send to ${numberOfChecked} Deal Party` : 'Select Deal Party'
      let classNames = 'submit-btn'

      if (numberOfChecked == 0 || this.state.submitting) classNames += ' disabled'

      return <button className={classNames}
        disabled={numberOfChecked == 0 || this.state.submitting}
        onClick={() => this.submit()}>
          {buttonText}
      </button>
    }

    renderHeader() {
      const { header, subheader } = this.props

      return <div className="modal-header survey-header">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h2 className="modal-title survey-title">{header}</h2>
        <p className="modal-sub-header">{subheader}</p>
      </div>
    }

    renderFooter() {
      return <div className="modal-footer">
        <span className="pull-left">
          {this.renderAddNewRecipientButton()}
        </span>
        <span className="pull-right">
          {this.renderSubmitButton()}
        </span>
      </div>
    }

    render() {
      return <div className='modal send-email-modal' role="dialog" ref={this.setModalRef}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            {this.renderHeader()}
            <div className="modal-body">
              {this.renderRecipientsTable()}
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    }
  }

  Modal.defaultProps = {
    showAddNewRecipientButton: true,
    addNewRecipientButtonText: 'Add New Recipient',
    addNewRecipientComponentProps: {},
    recipientEmailsSentAt: {}
  }

  Modal.propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string,
    recipients: PropTypes.array.isRequired,
    addNewRecipientButtonText: PropTypes.string,
    showAddNewRecipientButton: PropTypes.bool,
    addNewRecipientComponentName: PropTypes.string,
    addNewRecipientComponentProps: PropTypes.object,
    recipientEmailsSentAt: PropTypes.object,
    submitUrl: PropTypes.string.isRequired
  }

  export default Modal
