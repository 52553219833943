import React, { useEffect, useState } from 'react'
import { useWatchableRef } from 'lib/hooks'
import useSortableTable from 'components/domeui/table/hooks/useSortableTable'
import usePagination from 'components/domeui/table/hooks/usePagination'
import TableHead from 'components/domeui/table/TableHead'
import TableBody from 'components/domeui/table/TableBody'
import UploadArea from 'components/utility/upload_area'
import { useDispatch } from 'react-redux'
import { batchCreateBuildingUnits, importBuildingUnitModalState, resetBuildingUnits, resetErrorMessages, setFileId, uploadFile } from './store/slice'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'
import ErrorMessages from 'components/BuildingUnits/components/shared/ErrorMessages'

const COLUMNS = [
  {
    label: 'unit',
    accessor: 'unit_number',
    classes: {
      th: ['col-xs-3', 'dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },
  {
    label: 'sq footage',
    accessor: 'square_footage',
    classes: {
      th: ['col-xs-3', 'dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },
  {
    label: 'bedrooms',
    accessor: 'bedroom_count',
    classes: {
      th: ['col-xs-3', 'dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },
  {
    label: 'bathrooms',
    accessor: 'bathroom_count',
    classes: {
      th: ['col-xs-3', 'dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },
]

const PER_PAGE = 4

const formatFileSize = (fileSize) => {
  var size = fileSize
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(size) / Math.log(k))

  return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

export default function ImportModal({ address, open, onClose }) {
  const [file, setFile] = useState(null)
  const modalRef = useWatchableRef(null)
  const dispatch = useDispatch()

  const { building_structure, errorMessages, isUploading, buildingUnits, fileId, totalBuildingUnitsImported } = useSelector(importBuildingUnitModalState)

  const displayFileInfo = !isUploading && errorMessages.length === 0 && fileId;

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const close = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', close)

    return () => $(localRefValue).off('hidden.bs.modal', close)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  useEffect(() => {
    if (file) {
      const params = {
        buildingStructureId: building_structure.id,
        payload: file
      }
      dispatch(uploadFile(params))
    }
  }, [file])

  useEffect(() => {
    if (errorMessages.length > 0) {
      onClickRemoveInputFile()
    }
  }, [errorMessages])

  const handleCancel = () => {
    onClose()
  }
  const handleSubmit = () => {
    const params = {
      buildingStructureId: building_structure.id,
      payload: {
        building_units: buildingUnits
      } 
    }

    dispatch(batchCreateBuildingUnits(params))
  }

  const onInputChanged = inputFiles => {
    dispatch(resetErrorMessages())
    dispatch(setFileId(nanoid()))
    setFile(inputFiles[0].file)
  }

  const onClickRemoveInputFile = () => {
    dispatch(setFileId(null))
    dispatch(resetBuildingUnits())
    setFile(null)
  }

  const {
    tableData: sortedUnits,
    handleSorting: handleUnitsSorting,
    sortField,
    sortOrder,
  } = useSortableTable(buildingUnits, 'created_at')
  const { currentPageData: units, component: Pagination, goToPage } = usePagination(sortedUnits, PER_PAGE)

  return (
    <div
      ref={modalRef.ref}
      className="modal dome-modal dome-modal-centered import-units-modal"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <div className="dome-color-dark-green dome-h3">import units</div>
              <div className="dome-p3">for {address}</div>
            </div>
          </div>
          <div className="modal-body">
            <div className="body-subheader">import units in three steps:</div>
            <div className="body-sections">
              <div className="section">
                <div className="dome-p1">1. Create CSV</div>
                <div className="padded-content dome-p2">
                  <a href="/building_units/download_template_csv" target="_blank" className="dome-link dome-link-aqua">
                    Download template
                  </a>
                  <span> and add unit information.</span>
                </div>
              </div>
              <div className="section">
                <div className="dome-p1">2. Upload CSV</div>
                <div className="files">
                  <ErrorMessages errorMessages={errorMessages}/>
                  {!file && <UploadArea inputChanged={file => onInputChanged(file)} exclude_icons={false} text="click to browse CSV file for upload or drag and drop here" valid_types={['text/csv']} single={true}/>}
                  {displayFileInfo && <div className="file-info">
                    <span className='dome-p1-lt'>{file?.name}</span>
                    <div className='right-section'>
                      <span className='dome-p1-lt'>{file?.size && formatFileSize(file.size)}</span>
                      <i className="fa fa-xmark dome-color-red-alert" onClick={onClickRemoveInputFile}></i>
                    </div>
                  </div>}
                </div>
              </div>
              <div className="section">
                <div className="dome-p1">3. Review and Confirm</div>
                {buildingUnits.length > 0 && <div className="padded-content dome-bg-white dome-light-shadow">
                  <table className="dome-table dome-table-striped dome-table-tight dome-margin-auto">
                    <TableHead
                      columns={COLUMNS}
                      handleItemsSorting={handleUnitsSorting}
                      sortField={sortField}
                      sortOrder={sortOrder}
                      actionsShown={false}
                    />
                    <TableBody
                      columns={COLUMNS}
                      items={units}
                      emptyMessage={<span className="dome-color-lite-grey">No data.</span>}
                    />
                  </table>
                  {buildingUnits.length > PER_PAGE && (
                    <div className="pagination-container">
                      <Pagination />
                    </div>
                  )}
                </div>}
                <div className='dome-p2 dome-text-right'>{buildingUnits.length} Units</div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={handleCancel}
              className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel"
            >
              cancel
            </button>
            <button onClick={handleSubmit} className={`dome-btn dome-btn-base ${file ? 'dome-btn-go-green' : 'dome-btn-disabled'}`} disabled={!file}>
              import
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
