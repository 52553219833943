import clsx from 'clsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import eNotaryIcon from '/app/assets/images/e-notary.svg'
import ResourceSection, {
  ResourceConciergeSection,
  ResourceENotarySection,
  ResourceInsuranceSection,
  ResourceContractorReportSection,
} from './section'
import requirements1Image from '/app/assets/images/beacon/new/requirements1.png'
import requirements2Image from '/app/assets/images/beacon/new/requirements2.png'
import requirements3Image from '/app/assets/images/beacon/new/requirements3.png'
import requirements4Image from '/app/assets/images/beacon/new/requirements4.png'

const RESOURCE_TYPE = {
  LINK: 'link',
  PROPERTY: 'property',
  COMPONENT: 'component',
}

const RESOURCE = [
  {
    icon: <i className="fa-xl fa-thin fa-umbrella" />,
    title: 'insurance',
    type: RESOURCE_TYPE.COMPONENT,
    component: ResourceInsuranceSection,
  },
  {
    icon: <i className="fa-xl fa-thin fa-screwdriver-wrench" />,
    title: 'contractor report',
    type: RESOURCE_TYPE.COMPONENT,
    component: ResourceContractorReportSection,
  },
  {
    icon: <img src={eNotaryIcon} width={21} className="dome-color-green-gradient" />,
    title: 'e-notary',
    type: RESOURCE_TYPE.COMPONENT,
    component: ResourceENotarySection,
  },
  {
    icon: <i className="fa-xl fa-thin fa-hand-wave" />,
    title: 'concierge',
    type: RESOURCE_TYPE.COMPONENT,
    component: ResourceConciergeSection,
  },
  {
    icon: <i className="fa-xl fa-thin fa-question" />,
    title: 'FAQ',
    type: RESOURCE_TYPE.LINK,
    link: 'https://boardpackager.freshdesk.com/support/solutions/43000020588',
  },
  { icon: <i className="fa-xl fa-thin fa-house" />, title: 'your property', type: RESOURCE_TYPE.PROPERTY },
]

const GroupIcons = ({ onClose, onTab }) => {
  return (
    <div className="resource-section-main">
      <div className="resource-section-content">
        {RESOURCE.map((item, index) => {
          return (
            <div
              key={item.title}
              onClick={() => onTab(item.type, index)}
              className="dome-d-flex dome-flex-column dome-gap3 dome-align-center dome-cursor-pointer"
            >
              <div className="resource-section-icon dome-d-flex dome-align-center dome-justify-center dome-color-dark-grey">
                <span className="dome-full-width dome-full-height dome-d-flex dome-align-center dome-justify-center dome-color-dark-grey">
                  {item.icon}
                </span>
              </div>
              <span className="dome-p3 dome-color-med-grey dome-text-center">{item.title}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default function ResourceSectionGroup({ className, onClose, property, ...componentProps }) {
  const [activeTab, setActiveTab] = useState(null)
  const resourceRef = useRef()

  const onBack = () => setActiveTab(null)

  const onTab = useCallback(
    (type, index) => {
      switch (type) {
        case RESOURCE_TYPE.LINK:
          window.open(RESOURCE[index].link, '_blank')
          break
        case RESOURCE_TYPE.PROPERTY:
          window.open(`/buildings/${property.id}`, '_blank')
          break
        case RESOURCE_TYPE.COMPONENT:
          setActiveTab(index)
          break
        default:
          break
      }
    },
    [RESOURCE_TYPE, property?.id]
  )

  useEffect(() => {
    // Only listen to the event handler inside wrapper element to avoid receiving the event handler from root-portal element (react portal)
    // because the user can be able to open the Modal with the react portal when interacting inside Active component
    const wrapperEl = document.getElementById('wrapper')
    function handleClickOutside(event) {
      if (resourceRef.current && !resourceRef.current.contains(event.target)) onClose()
    }
    wrapperEl.addEventListener('mousedown', handleClickOutside)
    return () => {
      wrapperEl.removeEventListener('mousedown', handleClickOutside)
    }
  }, [resourceRef])

  const ActiveComponent = useMemo(() => RESOURCE[activeTab]?.component, [activeTab])

  return (
    <div ref={resourceRef} className={clsx('resource-section-container relative', className)}>
      <i
        onClick={onClose}
        className="resource-section-icon-close fa-light fa-xmark dome-color-med-grey dome-cursor-pointer"
      />
      {activeTab === null ? (
        <GroupIcons onClose={onClose} onTab={onTab} />
      ) : (
        <ActiveComponent onBack={onBack} property={property} {...componentProps} />
      )}
      <div className="resource-section-footer dome-d-flex dome-justify-center">
        <div
          onClick={() => window.dispatchEvent(new CustomEvent('open-packages-beacon-modal'))}
          className="dome-d-flex dome-gap6 dome-align-center dome-cursor-pointer"
        >
          <div className="dome-d-flex dome-gap3">
            <span className="dome-p1 dome-text-500 dome-color-green-gradient">need help?</span>
            <span className="dome-p1 dome-color-med-grey">open our tour</span>
          </div>
          <i className="fa-regular fa-arrow-right dome-color-med-grey"></i>
        </div>
      </div>
    </div>
  )
}
