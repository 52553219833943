import React from 'react'

const tokenize = title => String(title).toLowerCase().trim().replace(/\s+/g, "_").replace(/[^a-z0-9_]/g, "")

const is_hash_empty = hash => Object.keys(hash).length == 0
const clear_falsy_hash_items = hash => {
  for (let key in hash)
    if (!hash[key])
      delete hash[key]
}

const is_blank = s => !s || String(s).match(/^\s*$/)
const checkCurrency = s => String(s).trim().match(/^\d+(.\d\d)?$/)
const checkPositiveNumber = s => String(s).trim().match(/^\d+(.\d+)?$/)


const findIndexOfHashes = (hashes, key, value) => {
  for (let i in hashes)
    if (hashes[i][key] == value)
      return i

  return -1
}

class Policy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.error = {}
    this.state.active = !!this.props.policy.value || !!this.props.active
    this.state.value = this.props.policy.value
    this.state.additional_info = this.props.policy.additional_info
    this.state.show_additional_info = !!this.state.additional_info

    for (let flag in this.props.flags)
      this.state[flag] = this.props.policy[flag]

    this.state.error.value = this.getError()
    clear_falsy_hash_items(this.state.error)
  }

  componentDidUpdate(prevProps) {
    const policy = this.props.policy
    const old_policy = prevProps.policy

    if (["value_type"].some(attr => policy[attr] != old_policy[attr]))
      this.checkValue()
  }

  toggleActive() {
    const state = { active: !this.state.active }

    if (state.active && this.props.policy.value_type == "boolean") {
      state.value = "1"
      state.error = {}
    }

    this.setState(state, () => this.sendUpdate())
  }

  toggleAdditionalInfo() {
    this.setState({show_additional_info: !this.state.show_additional_info })
  }

  setAdditionalInfo(info) {
    this.setState({additional_info: info}, () => this.sendUpdate())
  }

  toggleFlag(flag) {
    this.setState({[flag]: !this.state[flag]})
  }

  setValue(value) {
    value = String(value).trim()
    value = value == "" ? null : value

    const error = { ... this.state.error }
    delete error.value

    this.setState({value, error}, this.sendUpdate())
  }

  getError() {
    if (is_blank(this.state.value))
      return "This item is required"

    if (this.props.policy.value_type == "currency" && !checkCurrency(this.state.value))
      return "This is not a valid monetary amount"

    if (this.props.policy.value_type == "percentage" && !checkPositiveNumber(this.state.value))
      return "This is not a valid percentage"

    return null
  }

  hasErrors() {
    return !is_hash_empty(this.state.error)
  }

  checkValue() {
    const error = { ... this.state.errors, ...{ value: this.getError()} }
    clear_falsy_hash_items(error)
    this.setState({error})
  }

  sendUpdate() {
    if (typeof(this.props.onChange) != "function")
      return

    if (this.getError()) 
      return this.props.onChange({active: false})

    const update = { 
      active: this.state.active,
      value: this.state.value,
      additional_info: this.state.additional_info
    }

    this.props.onChange(update)
  }

  render() {
    return <div className="edit-policy">
      <div>
        <div className="checkbox">
          <input type="checkbox" onClick={() => this.toggleActive()} checked={this.state.active} value="1" onChange={() => {}} />
        </div>
        <div className="name">
          <span onClick={() => this.toggleActive()}>{this.props.policy.title}</span>
        </div>
        { this.renderInputs() }
      </div>
      { this.props.policy.user_generated ? this.renderEditor() : null }
    </div>
  }

  renderInputs() {
    if (!this.state.active)
      return

    return <div className="inputs">
      <div>
        {this.renderValue()}
        <button className="dome-btn dome-btn-link dome-btn-link-aqua" onClick={() => this.toggleAdditionalInfo()}>
          { this.state.show_additional_info ? this.renderButtonAdditionalInfo(false) : this.renderButtonAdditionalInfo(true)}
        </button>
      </div>
      { this.renderAdditionalInfo() }
      { this.renderOptionalLine() }
      { this.renderHiddenFields() }
    </div>
  }

  renderButtonAdditionalInfo(type) {
    if (!type) return (<><i className="fa-regular fa-minus"/>additional info</>)

    return (<><i className="fa-regular fa-plus"/>additional info</>)
  }

  renderValue() {
    const items = []
    const changeValue = e => this.setValue(e.target.value)
    const blur = () => this.checkValue()

    const classes = ["input-value", "rounded-border", "a-text-input"]
    if (this.state.error.value)
      classes.push("has-error")

    if (this.props.required)
      items.push(<span key="*" className="required">*</span>)

    if (this.props.policy.value_type == "currency")
      items.push(<span key="$" className="decorator dollar">$</span>)

    if (this.props.policy.value_type == "boolean")
      items.push(<select defaultValue={this.state.value} onChange={changeValue} onBlur={blur}>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </select>)
    else
      items.push(<input key="val" type="text" maxLength="64" defaultValue={this.state.value} onChange={changeValue} onBlur={blur} placeholder="Value" />)

    if (this.props.policy.value_type == "percentage")
      items.push(<span key="%" className="decorator percent">%</span>)

    return <div className={classes.join(" ")}>
      {items}
    </div>
  }

  renderAdditionalInfo() {
    if (!this.state.show_additional_info)
      return

    return <div className="additional-info">
      <textarea className="rounded-border a-text-input" placeholder="Add Additional Info" onChange={e => this.setAdditionalInfo(e.target.value)} defaultValue={this.state.additional_info} />
    </div>
  }

  renderOptionalLine() {
    if (!this.props.flags && !this.props.policy.user_generated)
      return

    const flags = []
    const user_generated = <div className="user-generated">
      <i className="fa fa-pencil" onClick={() => this.props.showEditor()} />
      <i className="fa fa-trash" onClick={() => this.props.remove()} />
    </div>

    for (let flag in this.props.flags) 
      flags.push(this.renderFlagButton(flag, this.props.flags[flag]))

    return <div className="optional-line">
      <div className="flags">
        { flags }
      </div>
      { this.props.policy.user_generated ? user_generated : null }
    </div>
  }

  renderFlagButton(flag, flagTitle) {
    const classes = ["btn", "btn-sm"]
    if (this.state[flag])
      classes.push("active")   

    return <button key={flag} className={classes.join(" ")} onClick={() => this.toggleFlag(flag)}>
      { this.state[flag] ? <i className="fa fa-check" /> : <i className="fa fa-minus" /> }
      <span>{flagTitle}</span>
    </button>
  }

  renderHiddenFields() {
    if (!this.state.active)
      return null

    if (this.hasErrors())
      return null

    const fields = []
    const generateProps = key => ({ 
        key, 
        type: "hidden", 
        form: "update-building-form", 
        name: `policies[${this.props.policy.key}][${key}]` 
    })

    fields.push(<input {...generateProps("allowed")} value="1" />)

    if (this.state.value)
      fields.push(<input {...generateProps("value")} value={this.state.value} />)

    if (this.state.additional_info && this.state.show_additional_info)
      fields.push(<input {...generateProps("additional_info")} value={this.state.additional_info} />)

    if (this.props.user_generated) {
      fields.push(<input {...generateProps("user_generated")} value="1" />)
      fields.push(<input {...generateProps("custom_type")} value={this.props.policy.type} />)
      fields.push(<input {...generateProps("custom_title")} value={this.props.policy.title} />)
      fields.push(<input {...generateProps("custom_value_type")} value={this.props.policy.value_type} />)
    }

    for (let flag in this.props.flags || [])
      if (this.state[flag])
        fields.push(<input {...generateProps(flag)} value="1" />)

    return fields
  }

  renderEditor() {
    if (!this.props.policy_editor)
      return

    return <div className="editor-container">
      { this.props.policy_editor }
    </div>  
  }
}

const VALUE_TYPE_OPTIONS = [
  <option key="select" value="">-- Select a Value Type --</option>,
  <option key="$" value="currency">Currency ($)</option>,
  <option key="%" value="percentage">Percentage (%)</option>,
  <option key="1+" value="positive_integer">Positive Integer</option>,
  <option key="T|F" value="boolean">Required (No Value)</option>
]

class PolicyEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.name = props.name
    this.state.value_type = props.value_type
    this.state.errors = {}
  }


  key() {
    if (!this.state.name)
      return null

    return [this.props.type, tokenize(this.state.name)].join("-")
  }

  setName(name) {
    const errors = {...this.state.errors}
    delete errors.name
    this.setState({errors, name: is_blank(name) ? null : String(name).trim()})
  }

  setValueType(value_type) {
    const errors = {...this.state.errors}
    delete errors.value_type
    this.setState({errors, value_type})
  }

  isNameAvailable() {
    if (!this.key())
      return true

    if (this.props.checkIfInUse(this.key()))
      return false


    // Skip the promise, for now. Just return true if it isn't in the list of keys
    return true
    
/*      return new Promise((res, rej) => {
      let url = `/buildings/${this.props.building_id}/policy/${this.key()}/available`
      if (this.props.id)
        url += `?id=${this.props.id}`

      $.ajax({
        url, success: () => res(true), error: () => res(false)
      })  
    }) */
  }

  findBlanks(errors) {
    if (is_blank(this.state.name))
      errors.name = "Name cannot be left blank"
    if (is_blank(this.state.value_type))
      errors.value_type = "Value Type cannot be left blank"

    return errors
  }

  async findErrors(errors) {
    const isNameAvailable = await this.isNameAvailable()

    if (!isNameAvailable)
      errors.name = "This name is already in use for this building"
    else if (!this.state.name && this.props.id)
      errors.name = "This policy must have a name. Please use the icon to delete, if you want to remove it."

    return errors
  }

  async checkErrors() {
    const errors = await this.findErrors({})
    clear_falsy_hash_items(errors)
    this.setState({ errors })
  }

  save() {
    if (this.saving)
      return

    this.saving = true
    this.doSave()
    setTimeout(() => { delete this.saving }, 500)
  }

  async doSave() {
    const errors = {}
    this.findBlanks(errors)
    await this.findErrors(errors)
    
    if (!is_hash_empty(errors)) 
      return this.setState({errors})

    // First update the onsave
    await this.props.onSave({name: this.state.name, value_type: this.state.value_type})
    // Perform close when that is all done.
    await this.close()
  }

  canClose() {
    return typeof(this.props.onClose) == "function"
  }

  async close() {
    if(this.canClose())
      await this.props.onClose()

    if (this.props.clearOnClose)
      this.clear()
  }

  clear() {
    this.refs.name.value = null
    this.refs.value_type.value = ""

    this.setState({name: null, value_type: null, errors: {}})
  }

  cancel() {
    this.close()
  }

  render() {
    const name_changed = e => this.setName(e.target.value)
    const value_changed = e => this.setValueType(e.target.value)

    const name_input = <input ref="name" className="rounded-border a-text-input" defaultValue={this.state.name} type="text" onChange={name_changed} onBlur={e => this.checkErrors()} />
    const type_input = <select ref="value_type" className="rounded-border a-text-input" defaultValue={this.state.value_type} onChange={value_changed}>{VALUE_TYPE_OPTIONS}</select>

    return <div className="policy-editor">
      { this.renderInputLine("name", "Name", name_input)}
      { this.renderInputLine("value_type", "Value Type", type_input)}

      <div className="save-line">
        { this.renderButtons() }
      </div>
    </div>
  }

  renderInputLine(id, label, input) {
    const error = this.state.errors[id] ? <div className="error">{this.state.errors[id]}</div> : null

    return <div className="input-line">
      <label>{ label }</label>
      <div className="input">
        <div>{ input }</div>
        { error }
      </div>
    </div>
  }

  renderButtons() {
    const cancel_button = this.canClose() ? <button className="btn btn-danger" onClick={() => this.cancel()}>Cancel</button> : null

    return <div className="buttons">
      { cancel_button }
      <button className="btn btn-bper" onClick={() => this.save()}>{this.props.create ? "Create" : "Update"}</button>
    </div>
  }
}

class Policies extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.state.policies = props.policies || []
  }

  findOpenId() {
    let id
    do {
      id = parseInt(Math.random() * -100000000)
    } while(this.state.policies.some(policy => policy.id == id))

    return id
  }

  isKeyInUse(key) {
    return findIndexOfHashes(this.state.policies, "key", key) != -1
  }

  addPolicy(data) {
    const policy = {}

    policy.id = this.findOpenId()
    policy.key = [this.props.type, tokenize(data.name)].join("-")
    policy.type = this.props.type
    policy.title = data.name
    policy.value_type = data.value_type
    if (data.value_type == "boolean")
      policy.value = "1"

    policy.user_generated = true
    policy.active = true

    this.setState({policies: this.state.policies.concat([policy])})
  }

  async showEditor(policy, show) {
    await this.updatePolicyState(policy, { show_editor: show })
  }

  async editPolicy(policy, data) {
    const updates = {
      key: [this.props.type, tokenize(data.name)].join("-"),
      title: data.name,
      value_type: data.value_type
    }

    await this.updatePolicyState(policy, updates)
  }

  removePolicy(policy) {
    const policies = this.state.policies.concat([])
    const index = findIndexOfHashes(policies, "id", policy.id)

    if (!policies[index].user_generated)
      return

    if (index >= 0)
      policies.splice(index, 1)

    this.setState({policies: policies})
  }

  updatePolicyState(policy, updates) {
    return new Promise((res, rej) => {
      const policies = this.state.policies.concat([])
      const index = findIndexOfHashes(policies, "id", policy.id)
      policies.splice(index, 1, { ...policies[index], ... updates})
      this.setState({policies}, () => res())
    })
  }

  render() {
    return <div className="building-edit-policy-component">
      <div className="policies">
      { this.state.policies.map(policy => this.renderPolicy(policy))}
      </div>
      { this.renderAddPolicy() }
    </div>
  }

  renderPolicy(policy) {
    const policy_editor = policy.show_editor ? this.renderPolicyEditor(policy) : null
    const user_generated = policy.user_generated || policy.custom_title
    const showEditor = async () => await this.showEditor(policy, true)
    const remove = async () => await this.removePolicy(policy)

    return <div key={policy.key}>
      <Policy active={policy.active} policy={policy} flags={this.props.flags} user_generated={user_generated} policy_editor={policy_editor} showEditor={showEditor} remove={remove} />
    </div>
  }

  renderPolicyEditor(policy) {
    const type = this.props.type
    const keyInUse = key => policy.key != key && this.isKeyInUse(key)
    const onSave = data => this.editPolicy(policy, data)
    const onClose = () => this.showEditor(policy, false)

    return <PolicyEditor id={policy.id} building_id={this.props.building_id} type={type} id={policy.id} name={policy.title} value_type={policy.value_type} checkIfInUse={keyInUse} onSave={onSave} onClose={onClose} />
  }

  renderAddPolicy() {
    return <div className="panel panel-form">
      <div className="panel-heading" role="tab">
        <h4 className="panel-title" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseAddPolicy" aria-expanded="false" aria-controls="collapseAddPolicy">
          <i className="fa-regular fa-plus" aria-hidden="true"></i>
          add {this.props.type_title.toLowerCase()}
        </h4>
      </div>

      <div id="collapseAddPolicy" className="panel-collapse collapse" role="tabpanel" aria-labelledby="collapseAddPolicy">
        <div className="panel-body">
          <PolicyEditor building_id={this.props.building_id} type={this.props.type} clearOnClose={true} checkIfInUse={key => this.isKeyInUse(key)} onSave={data => this.addPolicy(data)} create={true} />
        </div>
      </div>
    </div>
  }
}

export default Policies
