import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

import Image from 'components/signatures/image'
export { Image }

import { useFindFontSizeProps } from '../utilities/find_font_size'

// Rebuld?
export default function ({id, frozen, thumbnail}) {
  const container = useRef()
  const signature = useSelector(({form_signatures: state}) => state.signatures[id])
  const signed = useSelector(({form_signatures: state}) => state.signed_signatures[id])

  const fontSizeProps = useFindFontSizeProps({
    key: `signature|${id}`,
    ratioType: thumbnail ? "thumbnailRatio" : null,
    frozen: frozen || thumbnail})

  const props = {
  // below from stash conflicts
  // const { fontSize, isWorking } = useFindFontSize(container, {
  //   key: `signature|${id}`,
    defaultSize: signature.fontSize || 100,
    ...fontSizeProps
  }

  return <Image ref={container} signature={signed} {...props} />
}
