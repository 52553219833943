import React, { useCallback, useMemo } from 'react'
import Collapsible from 'react-collapsible'
import Field from 'components/utility/field'
import { isPlainObject, pick } from 'lodash'

const isFilled = val => val && !String(val).match(/^\s*$/)

function anyFieldsFilled(data) {
  const values = Object.values(data)
  const isData = val => !isPlainObject(val) && isFilled(val)

  if (values.some(isData))
    return true

  return values.filter(isPlainObject).some(anyFieldsFilled)
}

function isReadyToSubmit({fields, data, data_key}) {
  if (!anyFieldsFilled(data))
    return true

  // Submit if all required fields are filled
  return !fields.some(field => field.required && !isFilled(data[`${data_key}.${field.id}`]))
}

function collapsableTitle({data, data_key}) {
  const first = data[`${data_key}.name.first`]
  const last = data[`${data_key}.name.last`]
  const firm = data[`${data_key}.firm`]

  if (isFilled(first))
    return `${first} ${last}`
  else if (isFilled(firm))
    return firm
  
  return "Name"
}

function CollapsableFieldsArea({data, data_key, disabled, children}) {
  const styling = { 
    borderBottom: '1px solid rgb(221, 221, 221)', 
    padding: '10px 15px' 
  }
  const trigger = collapsableTitle({ data, data_key })

  return <Collapsible trigger={trigger}
                      triggerTagName='div'
                      triggerDisabled={disabled}
                      triggerStyle={styling}
                      contentOuterClassName='uncollapsed-fields-container'>
      {children}
  </Collapsible>
}

const MemoizedField = React.memo(props => {
  const { path } = props

  const handleUpdate = useCallback(value => onUpdate({path, value}), [path])

  const fieldProps = {
    handleUpdate,
    ...pick(props, ['value', 'required']),
    ...props.field.form_field
  }

  return <Field {...fieldProps} />
})

const fieldSort = (d1, d2) => d1.order < d2.order ? -1 : 1
export default function({data, data_key, fields, onUpdate}) {

  const sortedFields = fields.sort(fieldSort)

  const renderedFields = sortedFields.map(field => {
    const path = `${data_key}.${field.id}`
    
    const props = {
      field, path, onUpdate,
      value: data[path],
      required: field.required && !anyFieldsFilled(data)
    }

    return <MemoizedField key={field.id} { ...props } />
  })

  const collapsableProps = {
    data, data_key,
    disabled: !isReadyToSubmit({fields, data, data_key})
  }

  return <CollapsableFieldsArea {...collapsableProps}>
    { renderedFields }
  </CollapsableFieldsArea>
}
