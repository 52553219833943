import React, { useCallback } from 'react'

/**
 * @author narthur
 * @source https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=3413141#gistcomment-3413141
 * */
function pagination(current, total) {
  if (current === 1 && total === 1) return [1]

  const center = [current - 2, current - 1, current, current + 1, current + 2],
    filteredCenter = center.filter((p) => p > 1 && p < total),
    includeThreeLeft = current === 5,
    includeThreeRight = current === total - 4,
    includeLeftDots = current > 5,
    includeRightDots = current < total - 4

  if (includeThreeLeft) filteredCenter.unshift(2)
  if (includeThreeRight) filteredCenter.push(total - 1)

  if (includeLeftDots) filteredCenter.unshift('...')
  if (includeRightDots) filteredCenter.push('...')

  return [1, ...filteredCenter, total]
}

export default function Pagination({
  currentPage,
  totalPages,
  goForward,
  goPrevious,
  goToPage,
  isFirstPage,
  isLastPage,
  canGoBack,
  canGoForward,
}) {
  const pages = pagination(currentPage, totalPages)

  const onNavigateToPage = useCallback(
    (e) => {
      const page = e.currentTarget.parentElement.dataset?.page

      if (page) goToPage(parseInt(page))
    },
    [goToPage]
  )

  return (
    <ul className="dome-pagination">
      <li>
        <button onClick={goPrevious} disabled={!canGoBack}>
          <i className="fa-regular fa-chevron-left"></i>
        </button>
      </li>
      {pages.map((page) => {
        if (page === '...') return <li key={page}>{page}</li>

        return (
          <li key={page} data-page={page}>
            <button className={page === currentPage ? 'active' : undefined} onClick={onNavigateToPage}>
              {page}
            </button>
          </li>
        )
      })}
      <li>
        <button onClick={goForward} disabled={!canGoForward}>
          <i className="fa-regular fa-chevron-right"></i>
        </button>
      </li>
    </ul>
  )
}
