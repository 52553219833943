import React from 'react'

export default function({field: {placeholder, system_only}, id, onChange, value, onBlur, className, disabled}) {
  const handleChange = e => onChange({[id]: e.target.value})

  const props = {
    value: value || "", 
    disabled,
    placeholder,
    type: "phone",
    onChange: handleChange,
    onBlur
  }

  return <div className={className}>
    { system_only ? value || "" : <input {...props} /> }
  </div>
}