import React from 'react'
import PropTypes from 'prop-types'

import { capitalize } from 'lodash'

function Button({type, selected, onClick}) {
  const classes = ['btn']
  if (selected)
    classes.push('btn-primary')

  return <button value={type} className={classes.join(" ")} onClick={onClick}>
    {capitalize(type)}
  </button>
}

class UpdateTypeSelector extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      optionTypes: ["remove", "replace"],
      selectedType: null
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedType != this.state.selectedType)
      this.props.setAppState({ updateType: this.state.selectedType })
  }

  handleClick(type) {
    const selectedType = type == this.state.selectedType ? null : type
    this.setState({ selectedType })
  }

  renderButtons() {
    return this.state.optionTypes.map(type => {
      const props = {
        selected: this.props.selectedUpdateType == type,
        onClick: () => this.handleClick(type)
      }

      return <Button key={type} type={type} {...props} />
    })
  }

  render() {
    return (
      <div>{ this.renderButtons() }</div>
    )
  }
}

UpdateTypeSelector.propTypes = {
  selectedUpdateType: PropTypes.string,
  setAppState: PropTypes.func.isRequired
}

export default UpdateTypeSelector