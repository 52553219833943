import React from 'react'
import { DropzoneComponent } from 'react-dropzone-component'

const standard_dropzone_configs = {
  showFiletypeIcon: true,
  maxFilesize: 2500,
  maxFiles: 1,
  // Prevents Dropzone from uploading dropped files immediately
  autoProcessQueue: true,
  dictDefaultMessage: 'Drop files here to upload, or click to browse.',
  thumbnailWidth: "50px",
  thumbnailHeight: "50px",
  previewsContainer: '#dz-custom-modal .modal-body'
}

const djsConfig = () => {
  const token = $("meta[name=csrf-token]").attr("content")

  return {
    headers: {
      "X-CSRF-Token": token
    },
    addRemoveLinks: false,
    acceptedFiles: ".pdf",
    paramName: 'uploaded_form'
  }
}

export default class NewForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formId: null,
      formType: this.props.selector,
      formTitle: null
    }

  }

  readyToSubmit() {
    const { formId, formTitle, formType } = this.state

    return (formId && formTitle && formType)
  }

  dropzoneConfig() {
    console.log(Object.assign({}, standard_dropzone_configs, { postUrl: `/buildings/${this.props.buildingId}/create_lease_form` }))
    return Object.assign({}, standard_dropzone_configs, { postUrl: `/buildings/${this.props.buildingId}/create_lease_form` })
  }

  dropzoneDjsConfig() {
    return Object.assign({}, djsConfig(), { params: { form_id: this.state.formId, form_title: this.state.formTitle, form_type: this.state.formType } })
  }

  dropzoneHandlers() {
    const success = (file, data) => {
      this.props.createForm(data.lease_form)
      if (data.refresh_all_lease_forms)
        this.props.refreshForms()
      this.cancelFormCreation()
    }

    return {
      success,
      init: dropzone => this.dropzone = dropzone,
      dragover: () => $('#modal-dropzone .dz-message').addClass('dz-hovered'),
      dragleave: () => $('#modal-dropzone .dz-message').removeClass('dz-hovered'),
    }
  }

  renderDropzone() {
    return <DropzoneComponent ref="dropzone"
      config={this.dropzoneConfig()}
      eventHandlers={this.dropzoneHandlers()}
      djsConfig={this.dropzoneDjsConfig()} />
  }

  render() {
    return (
      <div className="new-building-lease-form">
        <div className="row" id={`new-building-${this.props.selector}-link`} onClick={() => this.showNewFormData()}>
          <div className="col-md-1">
            <div className="pull-right"><i className="fa fa-plus"></i></div>
          </div>
          <div className="col-md-11">Add Custom {this.props.title}</div>
        </div>
        <div className="row" id={`new-building-${this.props.selector}-form`} style={{ display: "none" }}>
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <input name='form_id' type="number" min="1" className="form-control" onChange={event => this.updateValue('form_id', event.target.value)} />
          </div>
          <div className="col-md-5">
            <input name='form_title' type="text" className="form-control" onChange={event => this.updateValue('form_title', event.target.value)} />
          </div>
          <div className="col-md-2">
            <button className='btn btn-success lease-form-button' disabled={!this.readyToSubmit()} onClick={() => this.createForm()}>Create</button>
          </div>
          <div className="col-md-2">
            <button className='btn btn-default lease-form-button' onClick={() => this.cancelFormCreation()}>Cancel</button>
          </div>
          <div className='col-md-12'>
            {this.renderDropzone()}
          </div>
        </div>
      </div>
    )
  }

  createForm() {
    const { formId, formTitle, formType } = this.state

    if (!formId || !formTitle || !formType) return

    $.ajax({
      url: '/buildings/' + this.props.buildingId + '/create_lease_form',
      method: "POST",
      dataType: "JSON",
      context: this,
      data: { form_id: this.state.formId, form_title: this.state.formTitle, form_type: this.state.formType },
      success: res => {
        this.props.createForm(res.lease_form)
        if (res.refresh_all_lease_forms)
          this.props.refreshForms()
      }, error: res => {
        renderErrorSwal('Failed to create lease form', res.responseJSON.error)
      }, complete: () => this.cancelFormCreation()
    })
  }

  updateValue(inputType, value) {
    switch (inputType) {
      case 'form_title':
        this.setState({ formTitle: value })
        break;
      case 'form_id':
        this.setState({ formId: value })
        break
    }
  }

  showNewFormData() {
    $('#new-building-' + this.props.selector + '-link').hide()
    $('#new-building-' + this.props.selector + '-form').show()
  }

  cancelFormCreation() {
    $('#new-building-' + this.props.selector + '-link').show()
    $('#new-building-' + this.props.selector + '-form').hide()
    $('#new-building-' + this.props.selector + '-form').find('input').val('')
  }
}
