import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  all: [],
  selected: 0,
  pageWidth: 1400,
  formName: 'Loading...'
}

const pageSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    load(state, { payload: { pages, page_files} }) {
      const pagesArray = Object.values(pages).map((page, index) => ({...page, url: page_files[index]}))
      state.all = pagesArray
    },

    setPageWidth(state, { payload: width}) {
      state.pageWidth = width
    },

    setSelected(state, { payload: selected}) {
      state.selected = selected
    },

    setFormName(state, { payload: formName}) {
      state.formName = formName
    }

  }
})

export const { load, setSelected, setPageWidth, setFormName } = pageSlice.actions
export default pageSlice.reducer
