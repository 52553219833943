import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { compact } from 'lodash'
import axios from "lib/axios"

import BuildingSearch from 'components/utility/building_search'

const lookupUrls = (building_id) => {
  const url = `/buildings/${building_id}/payment_urls`

  return new Promise((resolve, reject) => {
    const success = response => resolve(response.data.payment_urls)
    const failure = error => { reject(error) }

    axios.get(url, { params: {id: building_id}}).then(success).catch(failure)
  })
}

function ShowBuilding({building, resetBuilding}) {
  return <div className="show-building">
    <div className="building">{building.title}</div>
    <button className="btn btn-default select-new-building-btn" onClick={resetBuilding}>Select New Building</button>
  </div>
}

function Building({organization_id, building, setBuilding, setUrls, setSelected}) {
  const magGlassClass = 'mag-glass-thin'

  const resetBuilding = () => {
    setBuilding(null)
    setUrls([])
    setSelected(null)
  }

  return (
    <div className='building-input-area'>
      <i className={magGlassClass}></i>
      {building ? (
        <ShowBuilding building={building} resetBuilding={resetBuilding} />
      ) : (
        <BuildingSearch
          show_empty_message={true}
          show_magnifying_glass={true}
          classes={{iconClass: magGlassClass}}
          organization={organization_id}
          onSelect={b => (b?.id ? setBuilding(b) : null)}
        />
      )}
    </div>
  )
}

const Option = ({url, setSelected}) => {
  return (
    <div onClick={() => setSelected(url)} className='option-item'>
      {url.name}
    </div>
  )
}

const OptionsList = ({setSelected, urls}) => {
  return (
    <div className='options-container'>
      {urls.map((url, idx) => <Option key={idx} url={url} setSelected={setSelected} />)}
    </div>
  )
}

const OptionExtra = ({selected}) => {
  if (!selected?.details)
    return null
  
  return <div dangerouslySetInnerHTML={{__html: selected.details}} />
}

const PaymentDropdown = ({selected, setSelected, urls}) => {
  const [showOption, setShowOption] = useState(false)
  const showOptions = () => setShowOption(!showOption)

  useEffect(() => {
    if (selected) setShowOption(false)
  }, [selected])

  return (
    <div className='select-container '>
      <i className='money-bill-thin'></i>
      <div onClick={showOptions} className='payment-dropdown option-item'>
        <div>{selected?.name ?? 'Select Payment Option'}</div>
        <div><i className="fa-solid fa-caret-down"></i></div>
      </div>
      {showOption && <OptionsList setSelected={setSelected} urls={urls} /> }
    </div>
  )
}

const redirectToLink = (url) => window.location.href = url

const ContinueButton = ({url}) => {
  const classes = compact([
    url ? null : "disabled",
    'btn btn-default',
    !url ? null : 'continue-to-payment'
  ]).join(" ")

  return <button disabled={!url} className={classes} onClick={() => redirectToLink(url)}>
    Continue
  </button>
}

// Definitely not something Jeremy wrote
const STUPID_WINDOW_EVENT_NAME = "open-the-depm-payment-modal"

const BuildingPaymentModal = ({organization_id}) => {
  const [ open, setOpen ] = useState(false)
  const [ building, setBuilding ] = useState(null)
  const [ urls, setUrls ] = useState([])
  const [selected, setSelected] = useState(null)
  const urlsEmpty = urls?.length === 0
  const onlyOneUrl = urls?.length === 1

  useEffect(() => {
    if (!building?.id)
      return

    lookupUrls(building.id).then(urls => setUrls(urls))
  }, [building, setUrls])

	useEffect(() => {
    const listener = () => setOpen(true)

    window.addEventListener(STUPID_WINDOW_EVENT_NAME, listener)
    return () => window.removeEventListener(STUPID_WINDOW_EVENT_NAME, listener)
  }, [setOpen])

  useEffect(() => {
    if (onlyOneUrl)
      setSelected(urls[0])
  }, [urls])

  const openModal = () => {
    setOpen(true)
  }

  window.openBuildingPaymentModal = openModal


  const cleanup = () => {
    setOpen(false)
    setUrls([])
    setBuilding(null)
    setSelected(null)
  }

  return (
    <>
      <Modal show={open} onExited={cleanup} centered={true} contentClassName='building-payment-modal' >
        <Modal.Body>
          <Modal.Title>Make A Payment</Modal.Title>
          <div className="inner-container">
            <Building organization_id={organization_id} building={building} setBuilding={setBuilding} setUrls={setUrls} setSelected={setSelected} />
            {onlyOneUrl ? null : !urlsEmpty && <PaymentDropdown selected={selected} setSelected={setSelected} urls={urls} /> }
            <OptionExtra selected={selected} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='button-container'>
            <button className='btn btn-default' onClick={() => setOpen(false)}>Cancel</button>
            <ContinueButton url={selected?.url} />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default BuildingPaymentModal
