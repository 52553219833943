import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from 'lib/hooks'
import {
  selectPropertyTransfer,
  submitAccountChanges as submitAccountChangesAction,
  setStep as setStepAction,
  STEPS,
} from 'components/property_transfer/store/slice'
import TransferAccountsTable from './list/TransferAccountsTable'

const TransferAccountsModalContext = createContext()

const useTransferAccountsData = () => {
  const { open, onClose } = useContext(TransferAccountsModalContext)
  const { accountValues, buildingId } = useSelector(selectPropertyTransfer)
  const accountsLength = useMemo(() => Object.values(accountValues).filter((value) => value !== '-1').length, [accountValues])
  const canContinue = useMemo(() => {
    if (!open) return false
    if (accountsLength === 0) return false

    return true
  }, [open, accountsLength])

  return useMemo(
    () => ({
      buildingId,
      accountValues,
      accountsLength,
      canContinue,
      open,
      onClose,
    }),
    [buildingId, accountValues, accountsLength, canContinue, open, onClose]
  )
}

const ModalContentList = ({ setStep }) => {
  const { open, onClose, canContinue } = useTransferAccountsData()

  return (
    <>
      <div className="modal-body">{open && <TransferAccountsTable />}</div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-success"
          disabled={!canContinue}
          onClick={() => setStep(STEPS.confirm)}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-default" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  )
}

const ModalContentConfirm = ({ setStep }) => {
  const dispatch = useDispatch()
  const { open, onClose, canContinue, accountValues, accountsLength, buildingId } = useTransferAccountsData()
  const submitAccountChanges = useCallback(() => {
    dispatch(submitAccountChangesAction({ buildingId, accountValues }))
  }, [dispatch, buildingId, accountValues])

  return (
    <>
      <div className="modal-body">Do you want to transfer fees belonged to {accountsLength} accounts?</div>
      <div className="modal-footer">
        <button type="button" className="btn btn-default" onClick={() => setStep(STEPS.list)}>
          Back
        </button>
        <button type="button" className="btn btn-success" disabled={!canContinue} onClick={submitAccountChanges}>
          Save
        </button>
        <button type="button" className="btn btn-default" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  )
}

const ModalContentTransferred = ({ setStep }) => {
  const { transferred } = useSelector(selectPropertyTransfer)
  const { onClose } = useContext(TransferAccountsModalContext)
  const clipboardRef = useRef()

  const copyToClipboard = () => {
    const data = ['fee_id,from_remote_merchant_id,to_remote_merchant_id']
    transferred.fees.forEach((fee) => {
      data.push([fee.id, fee.source.remote_merchant_id, fee.destination.remote_merchant_id].join(','))
    })
    const text = data.join('\n')
    clipboardRef.current.value = text
    clipboardRef.current.select()
    document.execCommand('copy')
    clipboardRef.current.value = ''
  }

  return (
    <>
      <div className="modal-body">
        {transferred.fees.length > 0 ? (
          <>
            <div>Transferred fees</div>
            <div className="transferred-fees-table-wrap">
              <table className="table table-striped table-bordered transferred-fees-table">
                <thead>
                  <tr>
                    <th>Fee ID</th>
                    <th>From merchant id</th>
                    <th>To merchant id</th>
                  </tr>
                </thead>
                <tbody>
                  {transferred.fees.map((fee) => (
                    <tr key={fee.id}>
                      <td>{fee.id}</td>
                      <td>{fee.source.remote_merchant_id}</td>
                      <td>{fee.destination.remote_merchant_id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>Save in case you want to backup the result when there are issues.</div>
            <textarea className="clipboard-element" ref={clipboardRef}></textarea>
          </>
        ) : (
          'No fees were transferred!'
        )}
      </div>
      <div className="modal-footer">
        {transferred.fees.length > 0 && (
          <button type="button" className="btn btn-default" onClick={copyToClipboard}>
            Copy to clipboard
          </button>
        )}
        <button type="button" className="btn btn-default" onClick={onClose}>
          Close
        </button>
      </div>
    </>
  )
}

const ModalContent = () => {
  const dispatch = useDispatch()
  const { step } = useSelector(selectPropertyTransfer)
  const setStep = useCallback((payload) => dispatch(setStepAction(payload)), [dispatch])

  switch (step) {
    case STEPS.list: {
      return <ModalContentList setStep={setStep} />
    }
    case STEPS.confirm: {
      return <ModalContentConfirm setStep={setStep} />
    }
    case STEPS.transferred: {
      return <ModalContentTransferred setStep={setStep} />
    }
    default:
      return null
  }
}

const TransferAccountsModal = ({ open, onClose }) => {
  const { modalRef } = useModal({ open, onClose })
  const { accountValues, buildingId } = useSelector(selectPropertyTransfer)

  return (
    <div ref={modalRef.ref} className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Transfer Accounts</h4>
          </div>
          <TransferAccountsModalContext.Provider value={{ open, onClose }}>
            <ModalContent />
          </TransferAccountsModalContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default TransferAccountsModal
