import React from 'react'

import BuildingRow from './building_row'

class DateRow extends React.Component {
  static defaultProps = {
    buildings: []
  }

  handleAllReadClick() {
    this.props.fetchNotifications();
    this.props.fetchSpecificDate(this.props.dateKey)
    this.props.fetchAllNotifications()
  }

  render () {
    const buildings = this.props.buildings.map(building => <BuildingRow key={building.building.id} handleAllReadClick={e => this.handleAllReadClick()} building={building} />)
    const date = `${this.props.month} ${this.props.day}, ${this.props.year}`

    return(
      <div className="notification-row section">
        <div className="sectioned-container dome-d-flex">
          <div className="left-section">
            <h3 className='dome-h3'>{date.toLowerCase()}</h3>
          </div>
          <div className="right-section">
            { buildings }
          </div>
        </div>
      </div>
    )
  }
}

export default DateRow
