import React, { useState, useEffect } from 'react'

const EmailIcon = (props) => {
  const [toggleSuccess, setToggleSuccess] = useState(false)

  useEffect(() => {
    const re = /^.+@.+\..+$/.test(props.watchedField)
    const value = props.watchedField.length > 0 && re
    setToggleSuccess(value)

  }, [props.watchedField])
  return <i style={{color: toggleSuccess ? '#56a256' : '#b7b7b7'}} className={`fa-light fa-envelope ${props.errors ? 'error-icon' : ''}`}></i>
}

export default EmailIcon