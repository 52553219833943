import React from 'react'

import StripeCard from 'components/utility/stripe_card'

import { errorPopup, confirmRemoval } from './utilities'

const METHOD_OPTIONS = {
  same_card: "Retry with the above payment method",
  new_card: "Replace with a different payment method",
  remove: "Cancel all associated transactions"
}

class SelectMethod extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  confirmPayment() {
    const text = []
    text.push(`By clicking the "Use this Payment Method" button below, you are confirming that the selected payment method exists, is available to be charged and has a minimum amount of <strong>${asMoney(this.props.total)}</strong> in its accounts.`)
    text.push(`Additionally, you recognize that some of the charges may occur immediately and other may occur at a later date, dependent on confirmation by management.`)

    swal({
      type: "warning",
      title: "Confirm Payment Method",
      html: true,
      text: text.map(t => `<p>${t}</p>`),
      showCancelButton: true,
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, confirm => confirm ? this.submitPayment() : null)
  }

  confirmRemoval() {
    confirmRemoval(() => this.removeItems())
  }

  beforeSubmitting() {
    if (this.state.selected == "new_card" && this.state.payment_method == "add-new") {
      const success = token => {
        this.setState({token})
        this.confirmPayment()
      }

      this.refs.stripe_card.generateToken().then(success).catch(() => {})
      return
    }

    this.confirmPayment()
  }

  submitPayment() {
    const data = { }
    if (this.state.payment_method == "add-new")
      data.payment_token = options.token
    else if (this.state.payment_method)
      data.payment_method_id = this.state.payment_method

    const success = res => {
      swal.close()
      this.props.onComplete()
    }

    const error = xhr => {
      const error = JSON.parse(xhr.responseText).error
      errorPopup(error)
    }

    $.ajax({
      url: `/user_payment_method/${this.props.id}/resolve_errors`,
      type: 'post',
      data: hashToPostQueryString(data),
      success, error
    })
  }

  removeItems() {
    const success = res => {
      swal.close()
      this.props.onComplete()
    }

    const error = xhr => {
      const error = JSON.parse(xhr.responseText).error
      errorPopup(error)
    }

    $.ajax({
      url: `/user_payment_method/${this.props.id}/payment_errors`,
      type: 'delete',
      data: hashToPostQueryString({}),
      success, error
    })
  }

  changeSelected(selected) {
    const state = { selected } 
    if (selected != "new_card")
      state.payment_method = null

    this.setState(state)
  }

  render() {
    return <div className="select-decision">
      <h5>Please select 1 of the 3 options below to resolve these errors</h5>
      <div className="possible-decisions">
        {Object.keys(METHOD_OPTIONS).map(opt => this.renderInput(opt))}
      </div>  

      {this.renderSameCard()}
      {this.renderDifferentCard()}

      <div className="button">
        {this.renderButton()}
      </div>
    </div>
  }

  renderButton() {
    if (!this.state.selected)
      return null

    if (this.state.selected == "add-new" && !this.state.payment_method)
      return null

    return <button onClick={e => this.beforeSubmitting()} className="btn btn-lg btn-bper">Resolve Transactions</button>
  }

  renderInput(input_type) {
    const selected = this.state.selected == input_type

    return <div className="decision-input" key={input_type} onClick={() => this.changeSelected(input_type)}>
      <input type="radio" readOnly={true} checked={selected} onChange={() => {}} />
      <span>{METHOD_OPTIONS[input_type]}</span>
    </div>
  }

  renderSameCard() {
    if (this.state.selected != "same_card")
      return null

    return <div className="same-card">
      By selecting to resolve these errors by reusing the same card, you are confirming that the card is available for use and the associated account contains sufficient funds for all the transactions listed above.
      <br /><br />
      Your card may or may not be charged immediately for the above transactions, dependant on the type of transaction and whether it requires additional confirmation from management.
    </div>
  }

  renderDifferentCard() {
    if (this.state.selected != "new_card")
      return null

    const changePaymentMethod = payment_method => { console.log(payment_method); this.setState({payment_method}) }
    return <StripeCard ref="stripe_card" cards={this.props.cards} onChangePaymentMethod={changePaymentMethod} />
  }
}

export default SelectMethod