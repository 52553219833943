import React, { useEffect } from 'react';
import { useWatchableRef } from 'lib/hooks';

function BuildingUnitImportSuccessModal({ open, onClose, onSubmit, totalBuildingUnitsImported }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const handleClose = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', handleClose)
    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal building-unit-success-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-body">
            <i className="fa-thin fa-circle-check fa-3x dome-color-dark-green"></i>
            <div>
              <span className="dome-text-w500 dome-h2 dome-color-dark-green">{`${totalBuildingUnitsImported} units imported!`}</span>
            </div>
          </div>
          <div className="modal-footer" style={{ width: '100%' }}>
            <div className='modal-footer-actions-wrapper'>
              <button className="dome-btn dome-btn-base dome-btn-aqua-stroke" onClick={onSubmit}>OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildingUnitImportSuccessModal
