import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compact } from 'lodash'

import { setActiveTool } from '.'
import { useSigningCheck } from '../signature'
import { clearSigningAs } from '../signature/store'
import { usePermissionsChecks } from '..'

function toClass(...classes) {
  return compact(classes).join(" ")
}

function CenterSigning() {
  const dispatch = useDispatch()
  const permissions = usePermissionsChecks('form:edit')

  return <>
    { permissions['form:edit'] && <span className="stop-signing top-bar-btn" onClick={() => dispatch(clearSigningAs())}>Stop Signing</span> }
  </>
}

function CenterEditing() {
  const dispatch = useDispatch()
  const activeTool = useSelector(({form}) => form.active_tool )
  const permissions = usePermissionsChecks('form:edit')

  if (!permissions['form:edit'])
    return null

  return <>
    <i className={toClass("fa-light", "fa-arrow-pointer", !activeTool ? 'active' : null)} onClick={() => dispatch(setActiveTool(null))}></i>
    <i className={toClass("fa-light", "fa-highlighter", activeTool == 'highlight' ? 'active' : null)} onClick={() => dispatch(setActiveTool('highlight'))}></i>
    <i className={toClass("fa-light", "fa-strikethrough", activeTool == 'strikethrough' ? 'active' : null)} onClick={() => dispatch(setActiveTool('strikethrough'))}></i>
  </>
}


function CenterArea() {

  const { signing } = useSigningCheck()

  return  <div className="center-area">
    { signing ? <CenterSigning /> : <CenterEditing /> }
  </div>
}

export default function() {

  
return <div className="top-bar-wrapper">
  <div>
    <div></div>
    <CenterArea />
    <div></div>
  </div>
</div>
}