import React from 'react'
import { omit, isNil } from 'lodash'

import ReactSlider from 'react-slider'

export function SelectTag({label, defaultValue, value, options, onChange, disabled}) {
  const optionsList = options.map(([key,value]) => {
    return <option key={key} value={key}>{value}</option>
  })

  const props = {
    disabled,
    onChange: evt => onChange ? onChange(evt.target.value) : null
  }
  if (value)
    props.value = value
  else if (defaultValue)
    props.defaultValue = defaultValue


  return <div className="select-tag">
    <label>{label}</label>
    <select {...props}>
      { optionsList }
    </select>
  </div>
}

export function SliderTag({label, value, marks, min, max, onChange, disabled}) {
  const props = omit({min, max, marks, disabled, onChange}, isNil)
  
  return <div className="slider-tag">
    <label>{label}</label>
    <ReactSlider { ...props } value={value}  />
  </div>
}