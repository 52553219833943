import React, { useEffect } from 'react'
import { format } from 'date-fns'
import useSWR from 'swr'
import { compact } from 'lodash-es'
import { useWatchableRef } from 'lib/hooks'
import { swrFetcher } from 'lib/axios'

const generateRequestURL = (baseUrl, options) => {
  const urlParams = new URLSearchParams(options)

  return `${baseUrl}?${urlParams.toString()}`
}

const formatDate = (date) => {
  return format(date, 'MM/dd/yy')
}

const formatTime = (date) => {
  return format(date, 'hh:mmaaa')
}

export function BuildingNotesModal({ open, onClose, buildingId, orgLogoUrl }) {
  let building_notes = []
  const queryOptions = {}
  if (buildingId) queryOptions['building_id'] = buildingId
  const queryKey = generateRequestURL('/api/building_notes', queryOptions)
  const { data, mutate } = useSWR(queryKey, swrFetcher, { keepPreviousData: true })
  if (data) {
    building_notes = data.building_notes
  }

  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const close = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', close)

    return () => $(localRefValue).off('hidden.bs.modal', close)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
      mutate()
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  const handleCancel = () => {
    onClose()
  }

  const building = building_notes.length > 0 ? building_notes[0].building : undefined

  return (
    <div
      ref={modalRef.ref}
      className="modal dome-modal dome-modal-centered building-notes-modal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="dismiss-bar">
            <i className="fa-solid fa-x dismiss-icon" onClick={handleCancel}></i>
          </div>
          <div className="modal-header">
            <div className="modal-title">
              <div className="dome-color-dark-green dome-h3">organization notes</div>
              <div className="dome-p2">
                {building &&
                  `${compact([building.title, building.address]).join('—')}, ${building.city}, ${building.state} ${
                    building.zip
                  }`}
              </div>
            </div>
            {typeof orgLogoUrl === 'string' && (
              <div>
                <img src={orgLogoUrl} className="org-id-branding-logo" />
              </div>
            )}
          </div>
          <div className="modal-body">
            <div className="body-sections">
              {building_notes.map((note) => {
                const timestamp = new Date(note.updated_at)

                return (
                  <div className="section" key={note.id}>
                    <div className="dome-p2">
                      {note.user.full_name} noted on {formatDate(timestamp)} at {formatTime(timestamp)}:
                    </div>
                    <div className="dome-p2 note" dangerouslySetInnerHTML={{ __html: note.sanitized_note }} />
                  </div>
                )
              })}
              {building_notes?.length === 0 && 'There are no notes!'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
