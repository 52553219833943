import React, { useState } from 'react'
import { compact } from 'lodash'

let steppingTimer

function Quote({quote, active}) {
  const classes = compact([
    'slide',
    active ? 'active' : null
  ])
  return <div className={classes.join(" ")}>
  	{ quote }
  </div>
}

function Person({person, active}) {
  const classes = compact([
    'slide',
    active ? 'active' : null
  ])
  return <div className={classes.join(" ")}>
  	{ person }
  </div>
}

function StepButton({active, onClick}) {
  return <div className={active ? "active" : null} onClick={onClick}>
  </div>
}

function playCarousel({steps, stepNumber, setStepNumber}) {
  clearTimeout(steppingTimer)
  steppingTimer = setTimeout(() => setStepNumber((stepNumber + 1) % steps.length), 8500)
}

function pauseCarousel() {
  clearTimeout(steppingTimer)
}

function ConciergeCarousel({steps}) {
  const [stepNumber, setStepNumber] = useState(0)
  const playProps = {steps, stepNumber, setStepNumber}

  playCarousel({...playProps})

  return (
  <div className="specialist-carousel" onMouseEnter={pauseCarousel} onMouseLeave={() => playCarousel({...playProps})}>
		<div className="slide-deck">
    	{ steps.map((step, index) => <Quote key={index} quote={step.quote} active={index == stepNumber}/>) }
    </div>
		<div className="specialist-carousel-footer">
      <div className="step-buttons">
	    	{ steps.map((_, index) => <StepButton key={index} active={index == stepNumber} onClick={() => setStepNumber(index)} />)}
      </div>
      <div className="people">
    		{ steps.map((step, index) => <Person key={index} person={step.person} active={index == stepNumber} />) }
      </div>
    </div>
  </div>
  )
}

export default ConciergeCarousel
