import React from 'react'
import PropTypes from 'prop-types'

class SelectModeCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  handleToggleCheckbox(e) {
    let checked = e.target.checked;
    if (this.props.mode == 'select_terms') {
      this.props.handleUpdateSelectingTerms(checked)
    } else {
      this.props.handleUpdateSelectingFieldElement(checked);
    }
  }

  render () {
    const { mode, selectingFieldElement, selectingTerms, selectedFieldElementName } = this.props;
    let checkboxId, checkboxChecked, spanText, disabled;
    if (mode == 'select_terms') {
      checkboxId = 'selectingTerms'
      checkboxChecked = selectingTerms ? 'checked' : ''
      spanText = 'Selecting terms?'
      disabled = !selectedFieldElementName ? true : false
    } else {
      checkboxId = 'selectingAutocalculateElement'
      checkboxChecked = selectingFieldElement ? 'checked' : ''
      spanText = 'Selecting field for autocalculation?'
      disabled = false
    }
    return (
      <div className='div-table-row'>
        <div className='div-table-col'>
          <label htmlFor={checkboxId} className='switch'>
            <input type='checkbox' id={checkboxId} disabled={disabled} style={{marginRight: 5}} onChange={(e) => this.handleToggleCheckbox(e)} checked={checkboxChecked} />
            <span className='slider'></span>
          </label>
        </div>
        <div className='div-table-col'>
          <span>{spanText}</span>
        </div>
      </div>
    )
  }
}

SelectModeCheckbox.propTypes = {
  mode: PropTypes.string.isRequired
}

export default SelectModeCheckbox