import React, { createContext, useState } from 'react'

export const InterstitialsContext = createContext()

export const InterstitialsWrapper = ({ children }) => {
  const [interstitials, setInterstitials] = useState({})

  return (
    <InterstitialsContext.Provider value={{ interstitials, setInterstitials }}>
      {children}
    </InterstitialsContext.Provider>
  )
}

export const InterstitialContext = createContext()
