import React, { useCallback, useState } from 'react'
import { compact, omit, mapKeys, mapValues, castArray, startsWith } from 'lodash'

import { removeTrailingBlanks } from 'lib/utilities'
import { hashToPathMap, pathMapToHash, valueByPath } from 'lib/utilities/form'

import InternalField from 'components/form_field'
import Html from './html'

function isEmpty(val) {
  if (!val)
    return true

  if (Array.isArray(val) && val.length == 0)
    return true

  if (typeof val == 'object' && Object.keys(val).length == 0)
    return true

  return String(val).match(/^\s*$/)
}

export const API = {
  getField(path) {},
  getError(path) {},
  getErrorHash(path) { return {}},
  getValue(path) {},
  getValueHash(path) { return {} },
  updateValues(hash) { console.log(" Whoops", hash)},
  globalPathmap: {},
  events: {
    onBlur(path, field) {},
  },
  specialSections: {

  }
}

function findInSortedList(needle, hayStack = [], { equals = null, compare = (a,b) => a.localCompare(b)} = {}) {
  let start = 0
  let finish = hayStack.length
  let size, middle, value

  equals ||= (a,b) => compare(a,b) == 0

  while (true) {
    size = finish - start
    if (size <= 0)
      return false

    middle = start + parseInt(size / 2)
    value = hayStack[middle]

    if (equals(value, needle))
      return true

    if (compare(value, needle) < 0) {
      finish = middle
    } else {
      start = middle + 1
    }
  }
}

// TODO: Binary Search
function findPartialMatchInSortedList(needle, hayStack = []) {
  return findInSortedList(needle, hayStack, {
    compare: (a,b) => a > b ? -1 : 1,
    equals: (a,b) => a.startsWith(b) || b.startsWith(a)
  })
}

function findExactMatchInSortedList(needle, hayStack = []) {
  return findInSortedList(needle, hayStack)
}

function generateApi(api, template, {useAccordion = true, limitById = []} = {}) {
  const sortedLimitIds = limitById.sort()
  const limitHash = pathMapToHash(Object.fromEntries(limitById.map(key => [key, null])))

  const options = {
    useAccordion
  }

  return {
    ...API, 
    ...api,
    events: { ...API.events, ...(api.events || {})},
    options, 
    getTemplate(t_id) {
      return template.templates && template.templates[t_id]
    },

    shouldIncludeId(id) {
      if (sortedLimitIds.length == 0)
        return true

      return findPartialMatchInSortedList(id, sortedLimitIds)
    },

    allowExpandArray(id) {
      const value = valueByPath(limitHash, id)
      return !Array.isArray(value)
    }
  }
}

// The Tool Context
const OverridesContext = React.createContext()
function Provider({children, overrides}) {
  return React.createElement(OverridesContext.Provider, { value: overrides }, children)
}

export function useOverrides() {
  return React.useContext(OverridesContext)
}


function Sections({sections}) {
  return <div className="fftc-sections">
    { sections.map((section, index) => <MemoizedSection key={section.label || index} section={section} /> )}
  </div>
}

const MemoizedSections = React.memo(Sections)

function Fields({fields}) {
  const overrides = useOverrides()
  const classes = compact([
    'fftc-fields'
  ])

  const renderField = (field) => {
    const template = field.template && overrides.getTemplate(field.template)
    return template ? <Template key={field.id} { ... omit(field, 'template')} template={template} /> : <Field key={field.id} { ...field} />
  }

  const validFields = compact(fields).filter(field => overrides.shouldIncludeId(field.id))

  return <div className={classes.join(" ")}>
    <div>
      { validFields.map(renderField)}
    </div>
  </div>
}

const MemoizedFields = React.memo(Fields)

function StandardSection({section}) {
  const { label, type } = section
  const overrides = useOverrides()
  const [isOpen, setOpen] = useState(true)
  const [hoverLabel, setHoverLabel] = useState(true)
  const open = alwaysOpen || isOpen
  const alwaysOpen = !overrides.options.useAccordion

  const classes = compact([
    'fftc-section',
    hoverLabel ? 'hovering-label' : null
  ]).join(" ")

  return <div className={classes}>
    <Label label={label} openable={true} isOpen={open} onClick={() => alwaysOpen ? null : setOpen(!isOpen)} onHover={on => setHoverLabel(on)} />
    { open && type == 'sections' ? <MemoizedSections sections={section.sections} /> : null }
    { open && type == 'fields' ? <MemoizedFields fields={section.fields} /> : null }
  </div>
}

function Section({section}) {
  const { type } = section
  const overrides = useOverrides()

  const SpecialSection = overrides.specialSections[type]

  if (SpecialSection) 
    return <SpecialSection {...section} />

  switch(type) {
    case 'template':
      return <Html {...section.content} />
  }

  return <StandardSection section={section} />
}

const MemoizedSection = React.memo(Section)

function Template({id, template, size}) {
  const overrides = useOverrides()
  const { label } = overrides.getField(id)

  const classes = compact([
    'fftc-field-container',
    `size-${size}`
  ])

  const fields = template.fields.map(item => ({...item, id: `${id}.${item.id}`}))
  return <div className={classes.join(" ")}>
    <Label label={label} />
    <Fields fields={fields} />
  </div>
}

function isArrayItem(id) {
  return id.match(/\[[0-9]+\]$/)
}

function Field(props) {
  const overrides = useOverrides()
  const field = overrides.getField(props.id)

  if (field.multiple && !isArrayItem(props.id))
    return <MultipleValueField {...props} />

  return <SingleField field={field} {...props} />
}

const MemoizedField = React.memo(Field)

function MultipleValueField(props) {
  const overrides = useOverrides()
  const { id } = props
  const { label } = overrides.getField(id)
  const classes = compact([
    'fftc-field-container',
    'multiple-values-field',
    `size-${props.size || 10}`
  ])

  const values = removeTrailingBlanks(castArray(overrides.getValueHash(id)))

  const allowAdd = overrides.allowExpandArray(props.id)
  const addItem = useCallback(() => {
    overrides.updateValues({[`${id}[${values.length}].__new`]: true})
  }, [values.length])

  const fieldProps = { ...omit(props, 'id'), cannotRemove: !allowAdd }

  return <div className={classes.join(" ")}>
    <Label label={label} />
    <div className="multiple-values">
      { values.map((value, index) => <FieldWithinMultipleValueField key={index} hidden={value.__removed} id={`${id}[${index}]`} {...fieldProps} />)}
    </div>
    { allowAdd ? <button className="btn" onClick={addItem}>Add Another {label}</button> : null }
  </div>
}

function FieldWithinMultipleValueField(props) {
  const overrides = useOverrides()
  const { id, hidden } = props

  const remove = useCallback(() => {
    const valuesHash = overrides.getValueHash(id)
    const values = mapKeys(hashToPathMap(valuesHash || {}), (_, k) => `${id}.${k}`)
    const nulledValues = mapValues(values, () => null)
    nulledValues[`${id}.__removed`] = true

    overrides.updateValues(nulledValues)
  }, [overrides])

  if (hidden)
    return null

  const removeButton = <div className="remove">
    <i className="fa-solid fa-xmark" onClick={remove}></i>
  </div>

  return <div className="multiple-values-item">
    <MemoizedField {...props} skipLabel={true} />
    { props.cannotRemove ? null : removeButton }
  </div>
}

function ObjectField({ field: {label, fields, size: field_size}, id, size, skipLabel}) {
  const overrides = useOverrides()
  const [open, setOpen] = overrides.options.useAccordion ? useState(true) : [true, () => {}]
  const [hoverLabel, setHoverLabel] = useState(false)

  const classes = compact([
    'fftc-field-container',
    'object-field',
    `size-${size || field_size}`,
    hoverLabel ? 'hovering-label' : null,
  ])
  
  const sort_fields = (a,b) => (fields[a].order || 9999) - (fields[b].order || 9999)
  const field_ids = Object.keys(fields).sort(sort_fields).map(field_id => ({id: `${id}.${field_id}`}))
  return <div className={classes.join(" ")}>
    { skipLabel ? null : <Label label={label} openable={true} isOpen={open} onClick={() => setOpen(!open)} onHover={on => setHoverLabel(on)} /> }
    { open ? <Fields fields={field_ids} /> : null }
  </div>
}

function SingleField(props = {}) {
  const { id, size } = props
  const { type, size: field_size } = props.field || {}
  const overrides = useOverrides()
  const classes = compact([
    'fftc-field-container',
    'single-field',
    `size-${size || field_size || 10}`
  ])

  const value = type == 'object' ? overrides.getValueHash(id) : overrides.getValue(id)
  const error = type == 'object' ? overrides.getErrorHash(id) : overrides.getError(id)
  const handleChange = hash => overrides.updateValues(hash)
  const handleBlur = () => overrides.events.onBlur ? overrides.events.onBlur(id, props.field) : null

  return <div className={classes.join(" ")}>
    <InternalField onChange={handleChange} onBlur={handleBlur} value={value} error={error} globalPathmap={overrides.globalPathmap} {...props} />
  </div>
}

export function Label({label, openable, isOpen, onClick, onHover}) {
  const overrides = useOverrides()

  if (!label)
    return null

  const canOpen = overrides.options.useAccordion && openable
  const button = <i className={`fa fa-angle-${isOpen ? 'up' : 'down'}`} />
  const click = () => onClick ? onClick() : null
  const setHover = on => onHover ? onHover(on) : null

  const classes = compact([
    'fftc-title',
    canOpen ? 'openable-title' : null,
    isOpen ? "openable-title-is-open" : 'openable-title-is-closed'
  ])

  return <div className={classes.join(" ")} onClick={click} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
    <span>{label}</span>
    { canOpen ? button : null }
  </div>
}

function FormFromTemplate({template, overrides, limitById, useAccordion = true, useDefaultStyling = true, className }) {
  const api = generateApi(overrides, template, { limitById, useAccordion })
  
  const classes = compact([
    "form-from-template-component",
    useDefaultStyling ? "default-styling" : "custom-styling",
    className
  ])

  return <Provider overrides={api}>
    <div className={classes.join(" ")}>
      { template.sections ? <MemoizedSections sections={template.sections} /> : null }
      { template.fields ? <MemoizedFields fields={template.fields} /> : null }
    </div>
  </Provider>
}

function getAllFields({fields, sections}) {
  let ids = []

  if (fields)
    ids = ids.concat(fields)
  if (sections)
    ids = ids.concat(sections.map(listAllFieldIds))

  return ids.flat()
}

export function checkForRequiredValues({template, overrides, values}) {
  const fields = getAllFields(template)
  return fields.filter(field => field.required)
               .filter(({id}) => isEmpty(values[id]))
               .map(({id}) => id)
}

export default FormFromTemplate