import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

import { addResident, buildingUnitEditPageState, resetCurrentResident, setOwnerFormMode, setRenterFormMode, updateResident } from '../store/slice';

export default function BuildingResidentForm({ role, formVisibility, setFormVisibility }) {
  const { currentOwner, currentRenter, ownerFormMode, renterFormMode } = useSelector(buildingUnitEditPageState);
  const dispatch = useDispatch();
  const currentResident = role === 'owner' ? currentOwner : currentRenter;
  const { register, handleSubmit, reset, setFocus, formState: { errors } } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: ''
    }
  });

  // handle side effects
  useEffect(() => {
    reset({
      first_name: currentResident?.first_name,
      last_name: currentResident?.last_name,
      email: currentResident?.email,
      phone_number: currentResident?.phone_number
    },
    { keepDefaultValues: true })
  }, [currentResident])

  useEffect(() => {
    if (formVisibility) {
      setFocus('first_name');
    } else {
      reset();
      dispatch(resetCurrentResident(role));
    }
  }, [formVisibility]);

  // handle memoized variables
  const isSubmitNew = useMemo(() => {
    return (role === 'owner' && ownerFormMode === 'add') || (role === 'renter' && renterFormMode === 'add');
  }, [role, ownerFormMode, renterFormMode]);

  const isSubmitEdit = useMemo(() => {
    return (role === 'owner' && ownerFormMode === 'edit') || (role === 'renter' && renterFormMode === 'edit')
  }, [role, ownerFormMode, renterFormMode]);

  const submitButtonText = useMemo(() => {
    if (isSubmitNew) return 'add';

    if (isSubmitEdit) return 'update';
  }, [isSubmitNew, isSubmitEdit])

  // methods
  const onSubmit = data => {
    if (!data) return;

    if (isSubmitNew) {
      data.f_id = nanoid();
      dispatch(addResident(data));
    }

    if (isSubmitEdit) {
      const residentData = {...currentResident, ...data};
      dispatch(updateResident(residentData));
    }
    role === 'owner' ? dispatch(setOwnerFormMode('add')) : dispatch(setRenterFormMode('add'));
    setFocus('first_name');
    reset();
  }

  const onClickCancel = () => {
    setFormVisibility(false);
  }

  if (!formVisibility) return;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('resident_role', { value: role })}/>
        <div className='building-resident-form-group-wrapper-section'>
          <div className='building-resident-form-group'>
            <label htmlFor='building_resident_first_name' className='dome-p3'>first name*</label>
            <input {...register('first_name', { required: 'This is a required field' })} id='building_resident_first_name'></input>
            {errors?.first_name && <p className='dome-color-red-alert'>{errors.first_name.message}</p>}
          </div>
          <div className='building-resident-form-group'>
            <label htmlFor='building_resident_last_name' className='dome-p3'>last name*</label>
            <input {...register('last_name', { required: 'This is a required field' })} id='building_resident_last_name'></input>
            {errors?.last_name && <p className='dome-color-red-alert'>{errors.last_name.message}</p>}
          </div>
        </div>
        <div className='building-resident-form-group-wrapper-section'>
          <div className='building-resident-form-group'>
            <label htmlFor='building_resident_email' className='dome-p3'>email address*</label>
            <input
              {...register("email", {
                required: "This is a required field",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              id="building_resident_email"
            ></input>
            {errors?.email && <p className='dome-color-red-alert'>{errors.email.message}</p>}
          </div>
          <div className='building-resident-form-group'>
            <label htmlFor='building_resident_phone_number' className='dome-p3'>phone number</label>
            <input {...register('phone_number')} id='building_resident_phone_number'></input>
          </div>
        </div>
        <div className='building-resident-form-group-action-section'>
          <button type='button' className='dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel' onClick={onClickCancel}>cancel</button>
          <button type='submit' className='dome-btn dome-btn-base dome-btn-aqua-stroke'>{submitButtonText}</button>
        </div>
      </form>
    </>
  )
}
