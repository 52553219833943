/**
 * @param {string[]} values
 * @param {string} domID
 * */
export const updateValuesToElement = (values, domID) => {
  if (typeof domID === 'string' && domID.length > 0) {
    const dom = document.getElementById(domID)

    if (dom) {
      dom.value = values.join(',')
    }
  }
}
