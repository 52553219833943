import React from 'react'
import clsx from 'clsx'
import QuoteEstimateMonthlyContent from '../FinalizeQuote/QuoteEstimateMonthlyContent'

export default function AboutYouSidebarHeadline({
  type,
  monthlyAmount,
  writingCompany,
  mobileExpanded,
  onToggleExpand,
}) {
  return (
    <>
      <div className="about-you-risk-type-and-quote-monthly">
        <div className="about-you-risk-type">
          <div className="about-you-risk-type__title">quote estimate*</div>
          <div className="dome-p2">{type}</div>
        </div>
        <div className="about-you-quote-monthly">
          <QuoteEstimateMonthlyContent monthlyAmount={monthlyAmount} writingCompany={writingCompany} />
        </div>
      </div>
      <div className="about-you-headline-icon">
        <i
          className={clsx('dome-cursor-pointer fa-light', mobileExpanded ? 'fa-chevron-up' : 'fa-chevron-down')}
          onClick={onToggleExpand}
        />
      </div>
    </>
  )
}

AboutYouSidebarHeadline.defaultProps = {
  type: 'homeowners',
  monthlyAmount: null,
  writingCompany: 'N/A',
}
