
export const TYPES = {
    both: "Both With and Without Files",
    with_file: "With Files",
    without_file: "Without Files"
  }

export function transformWithFile(item, requested_with_file) {
  const with_file = ["both", "with_file"].includes(requested_with_file)
  const without_file = ["both", "without_file"].includes(requested_with_file)

  item.with_file = with_file
  item.without_file = without_file
  return item
}
