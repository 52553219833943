import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fromUnixTime, format } from 'date-fns'

import { compact } from 'lodash'
import { useFindFontSize } from 'components/signatures/utilities'
import { useFindFontSizeProps } from '../utilities/find_font_size'

export default function({id, thumbnail, frozen}) {
  const container = useRef()
  const signature = useSelector(state => state.form_signatures.signatures[id])
  const signed = useSelector(state => state.form_signatures.signed_signatures[id])
  
  const fontSizeProps = useFindFontSizeProps({ 
    key: `signature|${id}|time`,
    ratioType: thumbnail ? "thumbnailRatio" : null, 
    frozen: frozen || thumbnail}) 

  const { originalSize, fontSize, isWorking } = useFindFontSize(
    container, 
    { defaultSize: signed?.timeFontSize, ...fontSizeProps }, 
    [signed?.time, signature.time_format]
  )

  const time = signed?.time ? fromUnixTime(signed.time) : null
  const classes = compact([
    "signature-time"
  ]).join(" ")

  const style = {
    fontSize
  }

  if (isWorking)
    style.opacity = 0

  return <div className={classes} style={style} ref={container}>
    { time ? format(time, signature.time_format ) : null }
  </div>
}