import React from 'react'
import PropTypes from 'prop-types'

const SEARCH_BY_FIELD_TYPES = ["name", "email"]

export default class PurchaserFilter extends React.Component {
  constructor(props) {
    super(props)
  }

  renderInput() {
    return <input type="text" required name="filters[purchaser][fieldValue]" defaultValue={this.props.purchaser["fieldValue"]} />
  }

  renderFieldTypeRadio() {
    return <div className="type-container">
      {SEARCH_BY_FIELD_TYPES.map((type, index) => (
        <span key={type} className={`type-${type} input-label-container`}>
          <input
            id={`radio-${type}`}
            type="radio"
            name="filters[purchaser][fieldType]"
            value={type}
            required
            defaultChecked={this.props.purchaser["fieldType"] == type || (!this.props.purchaser["fieldType"] && index == 0)} />
          <label htmlFor={`radio-${type}`}>{humanize(type)}</label>
        </span>
      ))}
    </div>
  }

  render() {
    return <div className="purchaser-filter">
      {this.renderFieldTypeRadio()}
      <div className="inputs-container">
        <i className="fa fa-long-arrow-right"></i>
        {this.renderInput()}
      </div>
    </div>
  }
}

PurchaserFilter.propTypes = {
  purchaser: PropTypes.object
}

PurchaserFilter.defaultProps = {
  purchaser: {}
}
