import React from 'react'
import PropTypes from 'prop-types'

const Requirement = (props) => {
  const requirement = props.requirement
  const packageTemplateDocuments = requirement.package_template_documents

  function renderTitle() {
    return <div className="requirement-title text-bopa">
      <span style={{ width: 20, display: "inline-block" }}>{props.sortOrder}. </span>
      <span>{requirement.title}</span>
    </div>
  }

  function renderDescription() {
    if (!requirement.description) return null

    return <div className="requirement-description">
      <span style={{ width: 15, display: "inline-block" }}></span>
      <span>{requirement.description}</span>
    </div>
  }

  function documentTooltipText(document) {
    if (!document.pageNumber)
      return "Preview for this document is not available"

    return null
  }

  function documentClasses(document) {
    let classes = "document"

    if (!document.pageNumber)
      classes += " disabled"

    return classes
  }

  function renderDocumentsAndForms() {
    if (packageTemplateDocuments.length == 0) return

    const label = packageTemplateDocuments.length > 1 ? "Forms" : "Form"

    return <div className="documents-container">
        <div className="section-label-offset text-bopa">{label}: </div>
        <div style={{display: "inline-block"}}>
          { packageTemplateDocuments.map((document, index) =>
            <div key={`doc-${index}`}
              className={documentClasses(document)}
              onClick={(event) => props.jumpToPage(event, document.pageNumber)}
              data-toggle="tooltip"
              data-placement="top"
              data-doc-ready='true'
              title={documentTooltipText(document)}>
                {document.resource_name}
            </div>) }
        </div>
      </div>
  }

  return <div className="requirement" onClick={(event) => props.jumpToPage(event, requirement.pageNumber)}>
    {renderTitle()}
    {renderDescription()}
    {renderDocumentsAndForms()}
  </div>
}

Requirement.propTypes = {
  requirement: PropTypes.object.isRequired,
  sortOrder: PropTypes.number.isRequired,
  jumpToPage: PropTypes.func.isRequired
}

export default Requirement

