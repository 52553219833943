import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuildingStructuresPageState, getBuildingStructures } from './store/slice';

import Main from './components/Main';

export default function BuildingStructures({ buildingId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBuildingStructures(buildingId));
  }, [dispatch]);

  const { building, building_structures } = useSelector(selectBuildingStructuresPageState);

  if (!building) return null;
  
  return (
    <>
      <div className='container building-structures-container'>
        <div className='building-structures-header'>
          <h3 style={{ display: "inline" }}>{building.title}</h3>
          <span className="pull-right">
            <a className="dome-link dome-link-grey dome-link-chevron-left" href={`/buildings/${buildingId}/edit`}>
              back to edit property
            </a>
          </span>
        </div>
        <div className='building-structures-body'>
          <Main building={building} buildingStructures={building_structures}/>
        </div>
      </div>
    </>
  )
}
