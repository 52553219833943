import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getBuildingStructuresApi } from './apis'
import { createStructure } from 'components/buildings/edit/NewStructure/store/slice'

const initialState = {
  building: null,
  building_structures: null,
}

export const getBuildingStructures = createAsyncThunk(
  'buildingStructuresPageSlice/getBuildingStructuresApi',
  async (buildingId) => {
    const response = await getBuildingStructuresApi(buildingId)
    const { building, building_structures } = response.data
    return { building, building_structures }
  }
)

const buildingStructuresPageSlice = createSlice({
  name: 'buildingStructuresPage',
  initialState,
  reducers: {
    setInitialState: (_state, _action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getBuildingStructures.fulfilled, (state, action) => {
      const { building, building_structures } = action.payload
      state.building = building
      state.building_structures = building_structures
    })
    builder.addCase(createStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload

      if (!state.building?.id) return;

      if (state.building.id === building_structure?.building?.id) {
        state.building_structures.push(building_structure)
      }
    })
  },
})
export const selectBuildingStructuresPageState = (state) => state.buildingStructuresPage

export const { setInitialState } = buildingStructuresPageSlice.actions

export const buildingStructuresPageReducer = buildingStructuresPageSlice.reducer
