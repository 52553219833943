import React from 'react'
import { useEventListeners } from '@deathbyjer/react-event-manager'

import { eventForId } from 'components/utility/draw_area'

import { isMinimumSize } from '.'
import { useReadApi, createToolbar, toolGenerator } from './base'
import { SelectTag, SliderTag } from './utilities'

import Field from 'components/form_field'


function isId(id) {
  const idArray = Array.isArray(id) ? id : String(id).split(",")
  return idArray[0] == 'highlight'
}

function extractId(id) {
  if (Array.isArray(id))
    return id[1]

  return String(id).split(",")[1]
}

export const COLORS = {
  "black": "Black",
  "yellow": "Yellow"
}

const SIDEBAR_FIELDS = {
  opacity: {
    label: "Opacity",
    form_field: {
      type: 'slider',
      min: 2,
      max: 16,
      opacities: [ ... Array(15).keys() ].map(i => i + 2)
    }
  },

  color: {
    label: "Color",
    form_field: {
      type: 'color-list',
      colors: ['black', 'yellow']
    }
  }
}

export const generateEmpty = () => ({
  position: {},
  options: {
    color: "yellow",
    opacity: 0.5
  }
})

function HighlightComponent({id, setId, api}) {
  const fullId = ["highlight", id]
  setId ? setId(fullId) : null

  const highlight = useReadApi(api, id) || {}
  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({rect}) => {
      if (isMinimumSize(rect)) {
        const position = { ...highlight.position, ...rect }
        const options = { ...highlight.options }
        api.update(id, { ...highlight, position, options })
      } else {
        api.destroy(id)
      }
    }
  })

  if (!highlight.options)
    return <div></div>

  const classes = ["highlight-tool-component"]
  const style = {
    backgroundColor: highlight.options.color,
    opacity: highlight.options.opacity
  }

  return <div className={classes.join(" ")} style={style}></div>
}

function HighlightToolbar({api, componentIds}) {
  const id = componentIds[0][1]

  const highlight = useReadApi(api, id)
  const onChangeColor = ({color}) => api.update(id, { ...highlight, options: { ...highlight.options, color}})

  const opacities = []
  for (let i = 2; i <= 18; i++)
    opacities.push(i)

  const onChangeOpacity = ({opacity}) => {
    const options = { ...highlight.options, opacity: opacity / 20.0}
    api.update(id, { ...highlight, options })
  }

  return <div className="highlight-tool-toolbar-component">
    <div className="toolbar-title">
      Highlight Properties
    </div>

    <Field id="opacity" field={SIDEBAR_FIELDS.opacity} onChange={onChangeOpacity} value={parseInt(highlight.options.opacity * 20)} />
    <Field id="color" field={SIDEBAR_FIELDS.color} onChange={onChangeColor} value={highlight.options.color} />
  </div>
}

function doCommand({api, command, id}) {
  switch(command) {
  case 'DELETE':
    return api.destroy ? api.destroy(extractId(id)) : null
  }
}

export function prepareComponents(type, highlights) {
  return highlights.map(highlight => ({
    type: `highlight/${type}`,
    id: highlight.id,
    props: {
      id: highlight.id,
      rect: highlight.position,
      drawOnCreate: highlight.newlyAdded
    }
  }))
}


export default toolGenerator({
  components: {
    highlight: HighlightComponent
  },

  toolbars: {
    single: createToolbar(HighlightToolbar, {
      showForSelected(componentIds) {
        // Only if one is selected
        if (componentIds.length != 1)
          return false

        return isId(componentIds[0])
      }
    })
  }
}, {
  componentPropsFor({allSelected, props}) {
    return props
  },
  isId,
  doCommand
})
