import React from 'react'

const SelectField = props => {
  const { options, placeholder, name, handleUpdate, required, value, styling } = props

  const renderOptions = () => {
    const list = []

    list.push(
      <option key='blank' value=''>{placeholder}</option>
    )
    for (let i = 0; i < options.length; i++) {
      list.push(
        <option key={i} value={options[i]} selected={value == options[i]}>{options[i]}</option>
      )
    }

    return list
  }

  return (
    <select name={name}
            // style={styling || ''}
            className='form-control'
            placeholder={placeholder || ''}
            onChange={event => handleUpdate(event.target.value)}
            required={required || false}>
            {renderOptions()}
    </select>
  )
}

export default SelectField
