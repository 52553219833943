import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import InvoiceModal from "../InvoiceModal";
import { updateInvoice, selectInvoiceEditState, resetState } from "./store/slice";

export function InvoiceEdit(props) {
  const dispatch = useDispatch();
  const [newInvoiceModalVisible, setNewInvoiceModalVisible] = useState(false);

  const onUpdateInvoiceModalOpen = () => {
    setNewInvoiceModalVisible(true);
  };

  const onUpdateInvoiceModalClose = useCallback(() => {
    setNewInvoiceModalVisible(false);
    dispatch(resetState());
  }, []);

  const onSubmit = (data) => {
    dispatch(updateInvoice({ data, id: props.invoice.id }));
  };

  const { invoiceUpdated } = useSelector(selectInvoiceEditState);

  useEffect(() => {
    if (invoiceUpdated) {
      onUpdateInvoiceModalClose();
    }
  }, [invoiceUpdated]);

  return (
    <>
      <button onClick={onUpdateInvoiceModalOpen} disabled={props.disabled}>
        {props.children}
      </button>
      <InvoiceModal
        open={newInvoiceModalVisible}
        onClose={onUpdateInvoiceModalClose}
        onSubmit={onSubmit}
        title="Update Invoice"
        submitText="Update"
        invoice={props.invoice}
        buildingDisabled
        additionalAddressDisabled
      />
    </>
  );
}

InvoiceEdit.defaultProps = {
  disabled: false,
};

InvoiceEdit.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};
