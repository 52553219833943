import React, { useCallback } from 'react'
import usePagination from 'components/domeui/table/hooks/usePagination'
import useSortableTable from 'components/domeui/table/hooks/useSortableTable'
import TableHead from 'components/domeui/table/TableHead'
import TableBody from 'components/domeui/table/TableBody'

const COLUMNS = [
  {
    label: 'address',
    accessor: 'default_additional_address.address',
    classes: {
      th: ['col-xs-3'],
      td: [],
    },
  },
  {
    label: 'building name',
    accessor: 'name',
    classes: {
      th: ['col-xs-3'],
      td: [],
    },
  },
  {
    label: 'bldg units',
    accessor: 'number_of_units',
    classes: {
      th: ['col-xs-3', 'dome-text-right'],
      td: ['dome-text-right'],
    },
  },
]

const PER_PAGE = 8

export default function List({ building_structures, onStructureEditModalOpen, onStructureArchiveModalOpen, archiveVisible }) {
  const {
    tableData: sortedStructures,
    handleSorting: handleStructuresSorting,
    sortField,
    sortOrder,
  } = useSortableTable(building_structures, 'created_at')
  const { currentPageData: structures, component: Pagination, goToPage } = usePagination(sortedStructures, PER_PAGE)

  const isDropUp = useCallback((indexInPage) => indexInPage === structures.length - 1, [structures])

  const renderActions = useCallback(
    (item, indexInPage) => {
      return (
        <div className={isDropUp(indexInPage) ? 'dropup' : 'dropdown'}>
          <a
            role="button"
            className="dome-link dropdown-toggle"
            id="buildingResidentActions"
            data-toggle="dropdown"
            style={{ width: '20%' }}
          >
            <i className="fa-thin fa-pencil" />
          </a>
          <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="buildingResidentActions">
            <li>
              <a role="button" onClick={() => onStructureEditModalOpen(item)}>
                edit
              </a>
            </li>
            {archiveVisible &&
              <li>
                <a role="button" onClick={() => onStructureArchiveModalOpen(item)}>
                  <span className="dome-color-red-alert">remove</span>
                </a>
              </li>
            }
          </ul>
        </div>
      )
    },
    [onStructureEditModalOpen, onStructureArchiveModalOpen, isDropUp]
  )

  if (building_structures.length === 0) return null

  return (
    <div className="building-edit-additional-addresses-table-wrapper dome-bg-white">
      <table className="dome-table dome-table-striped dome-table-tight dome-full-width">
        <TableHead
          columns={COLUMNS}
          handleItemsSorting={handleStructuresSorting}
          sortField={sortField}
          sortOrder={sortOrder}
        />
        <TableBody
          columns={COLUMNS}
          items={structures}
          renderActions={renderActions}
          emptyMessage={<span className="dome-color-lite-grey">No data.</span>}
        />
      </table>
      {building_structures.length > PER_PAGE && (
        <div className="pagination-container">
          <Pagination />
        </div>
      )}
    </div>
  )
}
