import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

export default function TableBody({ columns, items, renderActions, emptyMessage, actionsShown = true }) {
  if (items?.length === 0) {
    if (!emptyMessage) return <tbody></tbody>

    return (
      <tbody>
        <tr>
          <td colSpan={columns.length + 1}>{emptyMessage}</td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {items.map((item, index) => {
        return (
          <tr key={item.id}>
            {columns.map((column) => {
              const tdClasses = []
              if (Array.isArray(column?.classes?.td)) {
                tdClasses.push(...column.classes.td)
              }
              const tdClassName = tdClasses.length > 0 ? tdClasses.join(' ') : undefined

              return (
                <td key={column.accessor} className={tdClassName}>
                  {typeof column.transformFn === 'function' ? column.transformFn(item) : get(item, column.accessor)}
                </td>
              )
            })}
            {actionsShown && (
              <td>
                <div className="actions">{typeof renderActions === 'function' ? renderActions(item, index) : null}</div>
              </td>
            )}
          </tr>
        )
      })}
    </tbody>
  )
}

TableBody.defaultProps = {}

TableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  renderActions: PropTypes.func,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
