import { isEmpty, castArray, mapValues, startsWith, endsWith } from 'lodash'

import { metadataByPath, valueByPath, generateRoute } from 'lib/utilities/form'

const ERRORS = {
  required: "This field is required",
  unique_: "Must have unique"
}

function hasSomeValue(value) {
  if (Array.isArray(value))
    return value.some(v => !isEmpty(v))

  if (typeof value == 'object')
    return Object.values(value).some(v => !isEmpty(v))

  return !isEmpty(value)
}

export function findErrors(path, metadata, values, errors, { fullRoute = true, required = false } = {}) {
  const route = fullRoute ? generateRoute(path) : [ path ]

  let field
  for (let stop of route) {
    field = metadataByPath(metadata, stop)
    if (!field)
      continue
      
    findFieldErrors(stop, field, values, errors, { required: stop == path && required})
  }
}

export function showError(error) {
  const error_array = castArray(error).concat([])
  error_array[0] = whichError(error_array[0]) || error_array[0]
  return error_array.join(" ")
}

function whichError(error) {
  for (let type in ERRORS) {
    if (type == error)
      return ERRORS[type]

    if (endsWith(type, '_') && startsWith(error, type))
      return ERRORS[type]
  }

  return null
}

function addError(errors, id, error) {
  errors[id] ||= []
  errors[id].includes(error) ? null : errors[id].push(error)
}

function removeError(errors, id, error) {
  if (isEmpty(errors[id]))
    return

  const index = errors[id].indexOf(error)
  index >= 0 ? errors[id].splice(index, 1) : null
}

function findFieldErrors(path, field, allValues, errors, { required = false} = {}) {
  const values = valueByPath(allValues, path)

  if (required && !hasSomeValue(values)) 
    addError(errors, path, 'required')
  else
    removeError(errors, path, 'required')

  if (field.unique_on) 
    unauthorizedDuplicates(path, field, valueByPath(allValues, path), errors)
}

function unauthorizedDuplicates(prefix, field, values, errors) {
  if (!field.multiple || isEmpty(field.unique_on))
    return {}

  const uniqueOn = field.unique_on.map(castArray)
  const ids = values.map((_, index) => `${prefix}[${index}]`)

  // Clear errors
  for (let uniqueness of uniqueOn) {
    const error = `unique_${uniqueness.join("|")}`

    for (let index in values) 
      removeError(errors, ids[index], error)
  }

  for (let uniqueness of uniqueOn) {
    const error = `unique_${uniqueness.join("|")}`

    // Values per Index
    const valueLists = values.map(item => uniqueness.map(key => valueByPath(item, key)))
    // Tokenize the values
    const tokenized = valueLists.map(values => hasSomeValue(values) ? values.join("--|--") : null)
    // Get value counts
    const counts = countOfValues(tokenized)
    // Find the indexes with duplicates
    const affectedValues = mapValues(counts, count => count && count > 1)
    // Get the actual indexes
    const isIndexAffected = index => affectedValues[tokenized[index]] ? true : false

    for (let index in tokenized) 
      isIndexAffected(index) ? addError(errors, ids[index], error) : removeError(errors, ids[index], error)
  }
}

function countOfValues(array) {
  const count = {}
  for (let item of array) {
    count[item] ||= 0
    count[item] += 1
  }
  return count
}