import React, { useState } from 'react'
import clsx from 'clsx'

export default function NewRequirementField({ onAddRequirement }) {
  const [title, setTitle] = useState('')
  const [notice, setNotice] = useState('')

  const addRequirement = () => {
    if (title.trim().length === 0) {
      setNotice('Please enter a title for this requirement.')
    } else {
      onAddRequirement(title.trim())
      setNotice('')
      setTitle('')
    }
  }

  const noticeClass = clsx('col-md-4', notice && 'has-error')

  return (
    <div className="row" id="new-requirement-container">
      <div className={noticeClass}>
        <input
          value={title}
          placeholder="What is this requirement called?"
          className="form-control"
          maxLength="255"
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
        <span className="text-danger">{notice}</span>
      </div>
      <button onClick={addRequirement} className="btn btn-default col-xs-2" type="button">
        <i className="fa fa-plus"></i>
        Requirement
      </button>
    </div>
  )
}
