import React from 'react'
import PropTypes from 'prop-types'

class RejectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      explanation: "",
      loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTextareaChange = this.handleTextareaChange.bind(this)
  }

  handleSubmit() {
    if (!this.state.explanation || this.state.explanation == "") return

    this.setState({loading: true})
    $.ajax({
      url: this.props.rejectRefundRequestPath,
      method: "POST",
      dataType: "JSON",
      data: {explanation: this.state.explanation},
      success: function (res) {
        window.location.reload()
      }
    })
  }

  renderModalHeader() {
    return (
      <div className="modal-header">
        <h4 className="modal-title text-center">Reject Refund Request</h4>
      </div>
    )
  }

  explanationIsFilled() {
    return (this.state.explanation && this.state.explanation != "")
  }

  renderModalContent() {
    if (this.state.loading) {
      return (
        <div className="modal-content">
          {this.renderModalHeader()}
          <div className="modal-body">
            <p className="loading-dots"></p>
          </div>
        </div>
      )
    }

    return (
      <div className="modal-content">
        {this.renderModalHeader()}
        <div className="modal-body">
          <p>Please include an explanation for rejecting this refund request.</p>
          <p>Upon confirmation, the refund request will be reset and the requesting party will be notified.</p>
          <textarea name="reject-refunds-explanation"
            id="reject-refunds-textarea"
            rows="3"
            value={this.state.explanation}
            onChange={(e) => this.handleTextareaChange(e)}
          ></textarea>
        </div>
        <div className="modal-footer text-align-center">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button"
            className="btn btn-success"
            onClick={(e) => this.handleSubmit(e)}
            disabled={!this.explanationIsFilled()}>Ok</button>
        </div>
      </div>
    )
  }

  handleTextareaChange(e) {
    const explanation = e.target.value
    this.setState({ explanation: explanation })
  }

  render() {
    return (
      <div id="reject-refunds-modal" className="fade modal in" data-backdrop="static">
        <div className="modal-dialog modal-md">
          {this.renderModalContent()}
        </div>
      </div>
    )
  }
}

RejectModal.propTypes = {
  rejectRefundRequestPath: PropTypes.string.isRequired
}

export default RejectModal