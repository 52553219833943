import React, { useMemo, useContext } from 'react'
import { uniq, groupBy, isEmpty, differenceBy } from 'lodash-es'
import { FeeTableContext } from './PackageFeesTable'

const getChargeId = fee => fee?.stripe_charge_id

const getUnselectedFeesByChargeId = (fees, selectedFees) => {
  const selectedChargeIds = uniq(selectedFees.filter(getChargeId).map(getChargeId))
  const unselectedFees = differenceBy(fees, selectedFees, 'id')

  const relatedUnselectedFees = unselectedFees.filter(fee =>
    fee.stripe_charge_id && selectedChargeIds.includes(fee.stripe_charge_id)
  )

  return groupBy(relatedUnselectedFees, 'stripe_charge_id')
}

const Fee = React.memo(({ fee }) => (
  <div>
    <p>
      {fee.name} (${fee.amount})
      {fee.stripe_charge_id && <span className="text-muted ml-2"> ({fee.user_email})</span>}
    </p>
  </div>
))

const GroupedFees = React.memo(({ fees }) => (
  <div className="group">
    {fees.map(fee => <Fee key={fee.id} fee={fee} />)}
    <br />
  </div>
))

const SelectedFees = ({ fees, action }) => {
  if (isEmpty(fees)) return null;

  return (
    <div className="mb-4 border-bottom pb-4">
      <div className="alert alert-warning w-100" role="alert">
        <p className="reset-warning">
          <span>The following selected fees will be {action}:</span>
        </p>
      </div>
      <div className="row">
        <div className="col-12 group">
          {fees.map(fee => (
            <Fee key={fee.id} fee={fee} />
          ))}
        </div>
      </div>
    </div>
  )
}

const UnselectedFeesSection = ({ groupedFees, action }) => {
  if (isEmpty(groupedFees)) return null;

  return (
    <div className="mb-4">
      <div className="alert alert-warning w-100" role="alert">
        <p className="reset-warning">
          <span>The following associated fees will be {action}:</span>
        </p>
      </div>
      <div className="row">
        {Object.entries(groupedFees).map(([groupId, feesInGroup]) => (
          <div className="col-12 mb-3" key={groupId}>
            <GroupedFees fees={feesInGroup} />
          </div>
        ))}
      </div>
    </div>
  )
}

const RenderGroupedFees = ({ action, feesAction }) => {
  const { fees, selectedFees } = useContext(FeeTableContext)

  const unselectedGroupedFees = useMemo(
    () => getUnselectedFeesByChargeId(fees, selectedFees),
    [fees, selectedFees]
  )

  if (isEmpty(selectedFees) && isEmpty(unselectedGroupedFees)) {
    return (
      <div className="group-wrapper">
        <div>No fees selected.</div>
      </div>
    )
  }

  return (
    <div className="container-fluid mt-4">
      <SelectedFees fees={selectedFees} action={action} />
      <UnselectedFeesSection groupedFees={unselectedGroupedFees} action={feesAction} />
    </div>
  )
}

export default RenderGroupedFees
