import React, { useCallback, useMemo, useState } from 'react'
import Pagination from '../Pagination'

export default function useServerPagination(
  currentPage,
  setCurrentPage,
  totalPages,
) {
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages

  const canGoBack = !isFirstPage
  const canGoForward = !isLastPage

  const goPrevious = useCallback(
    () => setCurrentPage((prevCurrentPage) => (canGoBack ? prevCurrentPage - 1 : prevCurrentPage)),
    [setCurrentPage, canGoBack]
  )

  const goForward = useCallback(
    () => setCurrentPage((prevCurrentPage) => (canGoForward ? prevCurrentPage + 1 : prevCurrentPage)),
    [setCurrentPage, canGoForward]
  )
  const goToPage = useCallback((page) => setCurrentPage(page), [setCurrentPage])

  const component = useCallback(
    (props) => (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        goForward={goForward}
        goPrevious={goPrevious}
        goToPage={goToPage}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        canGoBack={canGoBack}
        canGoForward={canGoForward}
        {...props}
      />
    ),
    [currentPage, goForward, goPrevious, goToPage, totalPages, isFirstPage, isLastPage, canGoBack, canGoForward]
  )

  return useMemo(
    () => ({
      component,
      currentPage,
      goForward,
      goPrevious,
      goToPage,
      totalPages,
      isFirstPage,
      isLastPage,
      canGoBack,
      canGoForward,
    }),
    [
      component,
      currentPage,
      goForward,
      goPrevious,
      goToPage,
      totalPages,
      isFirstPage,
      isLastPage,
      canGoBack,
      canGoForward,
    ]
  )
}
