/**
 * Set positions for categories
 **/
const resetCategoriesPositions = (state) => {
  state.categories.forEach((category, index) => {
    category.position = index + 1;
  });
};

/**
 * Set positions for services
 **/
const resetServicesPositions = (list, state) => {
  list.forEach((serviceId, index) => {
    state.services[serviceId].position = index + 1;
  });
};

const reorderItems = (items, sourceIndex, destinationIndex) => {
  const [removed] = items.splice(sourceIndex, 1);
  items.splice(destinationIndex, 0, removed);
};

/**
 * @param state: Immer writable state
 * @param result: React-beautiful-dnd DropResult
 * */
export const handleDragEnd = (state, result) => {
  const { destination, source, type } = result;

  if (!destination) {
    return;
  }

  // Dragging item but position is not changed
  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return;
  }

  // Dragging categories
  if (type === "categories") {
    reorderItems(state.categories, source.index, destination.index);
    resetCategoriesPositions(state);
    state.formTouched = true;

    return;
  }

  const sourceCategoryId = source.droppableId.replace("category-", "");
  const destinationCategoryId = destination.droppableId.replace("category-", "");

  if (!sourceCategoryId || !destinationCategoryId) {
    return;
  }

  // Dragging items within the same category
  if (source.droppableId === destination.droppableId) {
    const list = state.category_services[destinationCategoryId];

    if (!list) {
      return;
    }

    reorderItems(list, source.index, destination.index);
    resetServicesPositions(list, state);
    state.formTouched = true;

    return;
  }

  // Dragging items across categories
  const startList = state.category_services[sourceCategoryId];

  if (!startList) {
    return;
  }

  const destinationList = (state.category_services[destinationCategoryId] ||= []);

  const [movedServiceId] = startList.splice(source.index, 1);
  destinationList.splice(destination.index, 0, movedServiceId);

  // Set new category_id to the service just been moved
  state.services[movedServiceId].category_id = destinationCategoryId;

  resetServicesPositions(startList, state);
  resetServicesPositions(destinationList, state);
  state.formTouched = true;

  return;
};

export const handleMoveCategory = (state, currentIndex, position) => {
  switch (position) {
    case "top":
      if (currentIndex === 0) return;
      reorderItems(state.categories, currentIndex, 0);

      break;
    case "bottom":
      if (currentIndex === state.categories.length - 1) return;
      reorderItems(state.categories, currentIndex, state.categories.length - 1);

      break;
    case "oneUp":
      if (currentIndex === 0) return;
      reorderItems(state.categories, currentIndex, currentIndex - 1);

      break;
    case "oneDown":
      if (currentIndex === state.categories.length - 1) return;
      reorderItems(state.categories, currentIndex, currentIndex + 1);

      break;
    default:
  }

  resetCategoriesPositions(state);
};
