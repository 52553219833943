import React from 'react'
import { connect } from 'react-redux'
import Store from '../Store'
import { modalErrorKeys } from './Error'

class RemoveRoleConfirmationModalContent extends React.Component {
  constructor(props) {
    super(props)
  }

  updateResponsibleParty() {
    const { modalUserDetails, roles } = this.props
    const data = {
      building_responsible_party: {
        role_label: modalUserDetails.title,
        is_visible: modalUserDetails.is_visible,
        is_phone_hidden: modalUserDetails.is_phone_hidden,
        package_processor_package_types: roles.package_processor.package_types,
        package_processor: roles.package_processor.selected,
        package_processor_for_all_package_types: roles.package_processor.selected && roles.package_processor.package_types.length == 0,
        property_executive: roles.property_executive.selected,
        board_minutes_processor: roles.minutes_processor.selected,
        questionnaire_processor_with_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withFiles,
        questionnaire_processor_without_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withoutFiles,
        alterations_processor: roles.alterations_processor.selected,
        alterations_property_architect: roles.alterations_property_architect.selected,
        alterations_viewer: roles.alterations_viewer.selected,
        first_name: modalUserDetails.first_name,
        last_name: modalUserDetails.last_name,
        phone_number: modalUserDetails.phone_number,
        phone_ext: modalUserDetails.phone_ext
      }
    }

    $.ajax({
      url: `/buildings/${this.props.buildingId}/update_building_responsible_party/${modalUserDetails.id}`,
      method: 'PATCH',
      dataType: 'json',
      data: data,
      context: this,
      success: function(res) {
        this.props.updateBuildingResponsibleParty(res.building_responsible_party[0])
        this.props.updateBuildingResponsiblePartyRoles(modalUserDetails.email, res.roles)
        this.props.closeModal()
      }, error: err => {
        if (err.responseJSON.error.error == "There must be another Package processor assigned before you can remove this user's permissions.") {
          this.props.setErrorModalDetails({
            name: modalErrorKeys.package_processor_permissions_cannot_be_removed,
            package_types:  err.responseJSON.error.package_types
          })
          this.props.openModalForContext('Error')
        }
      }
    })
  }

  submit() {
    if (typeof(this.props.confirm) == 'function') {
      this.props.confirm()
    } else if (this.props.modalUserDetails.id) {
      this.updateResponsibleParty()
    }
  }

  renderFooter() {
    return <div className='modal-footer'>
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal" style={{ float: 'left' }}>Cancel</button>
      <button key="modal-submit"
              type='button'
              className="btn"
              style={{color: 'white', backgroundColor: '#B31F00'}}
              onClick={() => this.submit()}>Yes, revoke Permissions</button>
    </div>
  }

  render() {
    return <div className="modal-content remove-confirmation">
      <div className="modal-header">
        <i className='fa-light fa-triangle-exclamation' style={{ fontSize: 80, margin: '10px 0 10px', color: '#B31F00' }} />
        <h3 className="modal-title">Are you sure?</h3>
      </div>
      <div className='modal-body text-center'>
        <div>Are you sure you want to remove all <b>Package Processor</b> permissions for this user?</div>
        <div><i>Upon confirmation, the user will be removed from every package they are currently associated with.</i></div>
      </div>
      {this.renderFooter()}
    </div>

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buildingResponsiblePartyType: state.main.buildingResponsiblePartyType,
    modalUserDetails: state.modal.modalUserDetails,
    roles: state.modal.roles,
    buildingId: state.main.buildingId,
    confirm: ownProps.confirm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(Store.closeModal()),
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsiblePartyRoles: (userEmail, roles) => dispatch(Store.updateBuildingResponsiblePartyRoles(userEmail, roles)),
    setErrorModalDetails: details => dispatch(Store.setErrorModalDetails(details)),
    openModalForContext: context => dispatch(Store.openModalForContext(context))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveRoleConfirmationModalContent)
