import React from 'react'

import CommentBox from './box'

class CommentButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show_thread: this.props.showThread,
      package_user: this.props.packageUser,
      viewed_count: this.props.viewed_count,
      comments: this.props.comments || [],
      share_users: this.props.share_users || [],
      retrieved_share_users: false,
      loading: false
    }

    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  // to force refetching user list
  handleClickOutside(event) {
    if (!this.props.closeThreadOnBlur) return

    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.show_thread) {
      this.setState({ show_thread: false })
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  commentType () {
    return this.props.commentType || (this.props.requirement_id ? "requirement" : "board_member_package_processor")
  }

  onNewComment(comment){
    const autoShare = this.props.automaticallyShareAllCommentsWith
    const sendToSelf = comment.sent_to == this.props.commenterId

    if (!autoShare || sendToSelf) {
      const comments = this.state.comments.concat([comment])
      this.setState({ comments })
    }
  }

  toggleThreadData () {
    const data = {
      update_count: this.state.comments.length
    }

    if (this.props.requirement_id)
      data.requirement_id = this.props.requirement_id

    return data
  }

  toggleThread() {
    const { share_users, retrieved_share_users, show_thread, comments, viewed_count } = this.state

    if (this.props.requirement_id && viewed_count != comments.length) {
      $.ajax({
        url: "/comments/" + this.commentType() + "/for_package/" + this.props.package_id + "/viewed",
        dataType: 'json',
        type: 'POST',
        data: this.toggleThreadData()
      });
    }

    if ((this.props.always_refetch_eligible_users && !show_thread) || share_users.length == 0 && !retrieved_share_users && !show_thread) {
      this.setState({loading: true})
      $.ajax({
        url: '/packages/' + this.props.package_id + '/requirements/' + this.props.requirement_id + '/retrieve_package_users_who_can_receive_comments',
        dataType: 'json',
        type: 'get',
        context: this,
        success: function(res) {
          if (res.success) {
            this.setState({
              share_users: res.payload,
              loading: false,
              retrieved_share_users: true,
              show_thread: !show_thread,
              viewed_count: comments.length
            })
          }
        }
      })
    } else {
      this.setState({show_thread: !show_thread, viewed_count: comments.length})
    }
  }

  renderCountBadge() {
    if (this.state.comments.length != this.state.viewed_count && this.state.show_thread === false && !this.state.loading) {
      return (<span className="badge">{this.state.comments.length-this.state.viewed_count}</span>)
    }
  }

  renderBoardMemberButton() {
    if (this.props.packageUser.role != "board_member" ) {
      return (
      <div className="btn-toolbar text-right">
        <span onClick={() => this.toggleThread()} className={this.renderButtonClass()}>{this.renderCountBadge()}</span>
      </div>
      )
    }
  }

  renderButtonClass() {
    var commentsLength = this.state.comments.length
    let buttonClass = ''
    if (this.state.loading) {
      buttonClass = 'comment-icon-button pull-right fa fa-spinner fa-spin fa-2x'
    } else if (this.props.inModal) {
      buttonClass = 'btn form-edit-btn btn-default form-edit-btn-shadow comment-select'
    } else if (this.props.inLease) {
      buttonClass = 'hidden-comment-btn'
    } else if (commentsLength === 0) {
      buttonClass = 'comment-doc pull-right comment-icon-button';
    } else {
      if (commentsLength == this.state.viewed_count) {
        buttonClass = 'comment-doc-filled pull-right comment-icon-button';
      } else {
        buttonClass = 'comment-doc-filled pull-right comment-icon-button on';
      }
    }
    return buttonClass
  }

  renderCommentBox() {
    return <CommentBox
      share_users={this.state.share_users}
      filter={this.props.filter}
      reqId={this.props.requirement_id}
      packageUser={this.props.packageUser}
      comments={this.state.comments}
      showThread={this.state.show_thread}
      onNewComment={comment => this.onNewComment(comment)}
      inModal={this.props.inModal}
      packageId={this.props.package_id}
      automaticallyShareAllCommentsWith={this.props.automaticallyShareAllCommentsWith}
      commenterId={this.props.commenterId}
    />
  }

  render() {
    var container_class = this.props.inModal ? null : "comment-box"
    return <div className={container_class} ref={this.wrapperRef}>
      {this.props.inModal ? null : this.renderBoardMemberButton()}
      {this.renderCommentBox()}
    </div>
  }
}

export default CommentButton
