import React, { useContext } from 'react'
import BeaconLogo from '/app/assets/images/beacon/beacon.svg'
import { BeaconSliderContext } from './slider'
import clsx from 'clsx'

export default function Beacon({ children }) {
  return <div className="beacon-container">{children}</div>
}

const Body = ({ children, style }) => {
  return (
    <div
      style={style}
      className="beacon-container-content dome-full-height dome-d-flex dome-flex-column dome-justify-start dome-gap12"
    >
      {children}
    </div>
  )
}

const RadioGroup = () => {
  const { numberOfSteps, currentStep } = useContext(BeaconSliderContext)
  return (
    <ul className="beacon-radio dome-d-flex dome-gap6">
      {new Array(numberOfSteps).fill(null).map((e, index) => {
        return <li key={index} className={`beacon-radio-item ${currentStep === index && 'active'} `}></li>
      })}
    </ul>
  )
}

const Label = () => {
  return (
    <div className="dome-p2 dome-d-flex dome-gap3 dome-color-dark-grey ">
      <span> welcome to</span>
      <div className="dome-d-flex dome-align-center dome-gap3">
        <img src={BeaconLogo} alt="beacon" width={7} height={10} />
        <span className="dome-color-dark-green"> beacon</span>
      </div>
    </div>
  )
}

const Title = ({ children }) => {
  return <span className="dome-h2 dome-color-green-gradient">{children}</span>
}

const ListGroup = ({ children, gap = 8, className }) => {
  return (
    <div style={{ gap: gap }} className={clsx('dome-d-flex dome-flex-column', className)}>
      {children}
    </div>
  )
}

const Text = ({ children }) => <span className="dome-p2 dome-color-dark-grey">{children}</span>

const SubText = ({ children }) => {
  if (!children) return null
  return <span className="beacon-description dome-p3 dome-color-dark-grey">{children}</span>
}

const Category = ({ listCategory }) => {
  if (!listCategory) return null
  return (
    <ul className="beacon-category ">
      {listCategory.map((category) => (
        <li key={category} className="dome-p2 dome-color-dark-grey">
          {category}
        </li>
      ))}
    </ul>
  )
}

const Image = ({ src, width = 300, height = 200, note }) => {
  return (
    <div className='beacon-image-container dome-d-flex dome-flex-column dome-gap12'>
      <div className="beacon-image">
        <img src={src} width={width} height={height} />
      </div>
      {note && <span className='dome-p3 dome-color-dark-grey'>{note}</span>}
    </div>
  )
}

Beacon.Body = Body
Beacon.Image = Image
Beacon.Label = Label
Beacon.Title = Title
Beacon.ListGroup = ListGroup
Beacon.Text = Text
Beacon.SubText = SubText
Beacon.Category = Category
Beacon.RadioGroup = RadioGroup
