import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { uniq } from 'lodash'

const RequirementVisibility = props => {
  const { formData, mode } = useSelector(state => state.form)
  const { register, setValue, requirementsAndDocuments, close } = props
  const [allChecked, setAllChecked] = useState(requirementsAndDocuments.every(item => formData.requirement_visibility?.includes(item.requirement.id)))
  const [checkedRequirementIds, setCheckedRequirementIds] = useState(formData.requirement_visibility || [])

  const ALL_REQUIREMENT_IDS = requirementsAndDocuments.map(item => item.requirement.id)

  const checkIfAllChecked = () => checkedRequirementIds.length > 0 && ALL_REQUIREMENT_IDS.length == uniq(checkedRequirementIds).length

  useEffect(() => {
    setAllChecked(checkIfAllChecked())
  }, [checkedRequirementIds])

  const handleCheckAll = () => {
    const checkboxes = document.querySelectorAll(".requirement-checkbox")

    checkboxes.forEach((checkbox) => {
      checkbox.checked = !allChecked
    })

    if (allChecked) {
      setCheckedRequirementIds([])
      setValue('requirement_visibility', [])
    } else {
      setCheckedRequirementIds(ALL_REQUIREMENT_IDS)
      setValue('requirement_visibility', ALL_REQUIREMENT_IDS)
    }
  }

  const handleCheckboxChange = e => {
    const requirementId = parseInt(e.target.value)
    const checked = e.target.checked

    checked ? setCheckedRequirementIds([...checkedRequirementIds, requirementId]) : setCheckedRequirementIds(checkedRequirementIds.filter(id => id != requirementId))
  }

  const Buttons = () => {
    switch (mode) {
      case 'create':
        return <>
          <button type='button' className='btn btn-default' onClick={close}>Cancel</button>
          <button type="submit" className='btn btn-success pull-right'>Next: Review Choices</button>
        </>
      case 'edit_requirements':
        return <div className='text-center'>
          <button type="submit" className='btn btn-success'>Confirm</button>
        </div>
    }
  }

  const requirementsAndDocsList = () => {
    return requirementsAndDocuments.map(item => {
      const checked = checkedRequirementIds.includes(item.requirement.id)

      return <div key={item.order} className='requirement-doc-item'>
        <div>
          <input
            {...register(`requirement_visibility`)}
            value={item.requirement.id}
            type="checkbox"
            checked={checked}
            className='requirement-checkbox'
            onChange={e => handleCheckboxChange(e)} />
          {item.requirement.title}
        </div>
        {
          item.documents.map(document => {
            return <div style={{ paddingLeft: 20 }} key={document.id}>{document.label}</div>
          })
        }
      </div>
    })
  }

  return <>
    <div>Choose Requirement, then doc(s) requiring signature(s).</div>
    <div className='requirements-docs-list'>
      {requirementsAndDocsList()}
    </div>
    <div style={{ paddingLeft: 10 }}>
      <input type="checkbox" checked={allChecked} onChange={handleCheckAll} /> Select All
    </div>
    <br />

    <Buttons />
  </>
}

export default RequirementVisibility
