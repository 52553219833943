import React from 'react'

class SendSurveyButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { disabled: true };
  }

  render() {
    return <div>
      <button
        className='send-survey-button btn-disabled'
        onClick={() => this.handleClick()}
        disabled={this.state.disabled}
      >
        <span className="send-survey-text">Survey Sent</span>
      </button>
    </div>
  }
}

export default SendSurveyButton
