import React from 'react'
import { Provider } from 'react-redux'
import Store from './BuildingResponsibleParties/Store'
import Main from './BuildingResponsibleParties/Main'


export default class BuildingResponsibleParties extends React.Component {
  constructor(props) {
    super(props)

    this.store = Store.getStore()

    this.store.dispatch(Store.setBuildingResponsibleParties(props.buildingResponsibleParties))
    this.store.dispatch(Store.setBuildingResponsiblePartyTitles(props.titles))
    this.store.dispatch(Store.setBuildingId(props.buildingId))
    this.store.dispatch(Store.setBuildingResponsiblePartyRolesGroupedByEmail(props.buildingResponsiblePartyRolesGroupedByEmail))
    this.store.dispatch(Store.setBuildingResponsiblePartyType(props.type))
  }

  renderForType() {
    return(
      <>
        <Main
          package_types={this.props.package_types}
          alteration_processor_package_types={this.props.alteration_processor_package_types}
        />
      </>
    )
  }

  render() {
    return <Provider store={this.store}>
      {this.renderForType()}
    </Provider>
  }
}
