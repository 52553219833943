import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DealPartiesBeacon from './deal_parties_beacon'
import RequirementsBeacon from './requirements_beacon'
import FeesSubmitBeacon from './fees_submit_beacon'
import FeesForDealPartiesBeacon from './fees_for_deal_parties_beacon'
import { nanoid } from '@reduxjs/toolkit'

const Beacons = {
  deal_parties: DealPartiesBeacon,
  requirements: RequirementsBeacon,
  fees_submit: FeesSubmitBeacon,
  fees_for_deal_parties: FeesForDealPartiesBeacon
}

const BeaconModalWrapper = ({ beacon: beaconKey, package_hashed_id, package_user_id, disable_checkbox }) => {
  const [show, setShow] = useState(false)
  const [disableCheckBox, setDisableCheckBox] = useState(disable_checkbox)

  const onClose = useCallback(() => {
    setShow(false)
  }, [setShow])
  const openModal = useCallback(() => {
    setShow(true)
  }, [setShow])

  const onDisableCheckboxSucceed = useCallback(
    (payload) => {
      setDisableCheckBox(payload)
    },
    [setDisableCheckBox]
  )

  useEffect(() => {
    // This is to ensure that the event is dispatched after setting up event listeners.
    setTimeout(() => window.dispatchEvent(new CustomEvent('packages-beacon-modal-mounted')), 0)
  }, [])

  useEffect(() => {
    window.addEventListener('open-packages-beacon-modal', openModal)

    return () => window.removeEventListener('open-packages-beacon-modal', openModal)
  }, [openModal])

  const BeaconModal = useMemo(() => Beacons[beaconKey], [beaconKey])

  if (!BeaconModal) return null

  return (
    <BeaconModal
      isShow={show}
      onClose={onClose}
      packageHashedId={package_hashed_id}
      packageUserId={package_user_id}
      disableCheckBox={disableCheckBox}
      onDisableCheckboxSucceed={onDisableCheckboxSucceed}
    />
  )
}

export default BeaconModalWrapper
