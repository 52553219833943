import React from 'react'
import PropTypes from 'prop-types'

const ExportButton = props => {
  return <button className="btn dashboard-btns" onClick={() => props.handleClick()}>
    <img className="export-btn btn-icon"
      src={props.exportButtonIconPath}
      alt="Export Icon"/>
    Export
  </button>
}

ExportButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  exportButtonIconPath: PropTypes.string
}

export default ExportButton
