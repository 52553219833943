import React, { useRef } from 'react';

// todo: mask input
const SSNField = props => {
  const { placeholder, name, handleUpdate, value, required, styling } = props

  const inputRef = useRef(null)

  const formattedSSN = ssn => {
    if (!ssn)
      return ''

    const ssnWithoutDashes = ssn.replace(/-/g, ''),
          inputLength = ssnWithoutDashes.length
    let formatted = ''

    if (inputLength > 5) {
      formatted = ssnWithoutDashes.substr(0, 3) + '-' + ssnWithoutDashes.substr(3, 2) + '-' + ssnWithoutDashes.substr(5, 4)
    } else if (inputLength > 2) {
      formatted = ssnWithoutDashes.substr(0, 3) + '-' + ssnWithoutDashes.substr(3, 2)
    } else {
      formatted = ssnWithoutDashes
    }

    return formatted
  }

  const handleChange = value => {
    const sanitizedSSN = value.replace(/\D/g, '')

    if (sanitizedSSN !== formattedSSN(sanitizedSSN)) {
      inputRef.current.value = formattedSSN(sanitizedSSN)

      handleUpdate(formattedSSN(sanitizedSSN))
    }
  }

  const inputField = () => {
    return <input type="text"
                  ref={inputRef}
                  className='form-control'
                  placeholder={placeholder || ''}
                  maxLength={11}
                  name={name}
                  onChange={e => handleChange(e.target.value)}
                  defaultValue={formattedSSN(value) || ''}
                  required={required || false} />
  }

  return <>
    {inputField()}
  </>
}

export default SSNField
