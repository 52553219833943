import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { compact, pick } from 'lodash-es'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import axios from 'lib/axios'
import {
  attachDocument,
  attachForm,
  closeMyDocumentsModal,
  openMyDocumentsModal,
  selectMyDocumentsModalState,
  selectPackageTemplatesForm,
} from '../../store/slice'
import MyDocumentsModal from '../MyDocumentsModal'
import RequirementField, { PACKAGE_REQUIREMENT_TYPES } from './RequirementField'

export default function RequirementFields({
  fields,
  remove,
  recognition_agreement_enabled,
  onDragEnd,
  onRemoveDocument,
}) {
  const dispatch = useDispatch()
  const { getValues, setValue, control } = useFormContext()
  const { building, package_template } = useSelector(selectPackageTemplatesForm)
  const onViewMyDocumentsModal = ({ index }) => {
    dispatch(
      openMyDocumentsModal({
        buildingId: building.id,
        requirement: pick(fields[index], ['id', 'fieldId', 'hashed_id']),
      })
    )
  }

  const { visible, requirement, buildingId } = useSelector(selectMyDocumentsModalState)
  const onMyDocumentsModalClose = () => dispatch(closeMyDocumentsModal())
  const documentsFetchUrl = `/json_documents`

  const onAttachDocument = (type, doc) => {
    const full_requirement = fields.find((field) => field.id === requirement.id)
    if (!full_requirement) return

    let data = { document_model: doc, sort_order: full_requirement.package_template_documents.length + 1 }

    switch (type) {
      case 'form':
        data.form_id = doc.id
        dispatch(attachForm({ package_hashed_id: package_template.hashed_id, requirement_id: requirement.id, data }))

        break
      default:
        data.document_id = doc.id
        dispatch(
          attachDocument({ package_hashed_id: package_template.hashed_id, requirement_id: requirement.id, data })
        )
    }
  }

  const uploadDocument = async (file) => {}

  const onAttachFiles = async (files) => {
    const full_requirement = fields.find((field) => field.id === requirement.id)
    if (!full_requirement) return
    let sort_order = full_requirement.package_template_documents.length + 1

    for (const [index, file] of files.entries()) {
      const { data: response_data } = await axios.post(
        '/pt_dropzone_upload.js',
        {
          file: file.file,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      const data = {
        document_id: response_data.id,
        document_model: response_data,
        sort_order: sort_order + index,
      }

      dispatch(attachDocument({ package_hashed_id: package_template.hashed_id, requirement_id: requirement.id, data }))
    }
  }

  const package_requirements_watch = useWatch({ control, name: 'package_template.package_requirements' })
  const selectedInsuranceTypes = useMemo(() => {
    const selectedTypes = {}

    package_requirements_watch.forEach((requirement, index) => {
      const types = compact(requirement.insurance_metadata?.insurance_risk_types || [])
      if (types.length > 0) {
        selectedTypes[index] = types
      }
    })

    return selectedTypes
  }, [package_requirements_watch])

  const nullifyIfRequirementTypeRecognitionAgreement = useCallback(
    (index) => {
      const requirement_type_key = `package_template.package_requirements.${index}.requirement_type`
      const is_recognition_agreement =
        getValues(requirement_type_key) === PACKAGE_REQUIREMENT_TYPES.recognition_agreement

      if (is_recognition_agreement) setValue(requirement_type_key, null, { shouldDirty: true })
    },
    [getValues, setValue]
  )

  const nullifyIfRequirementTypeInsurance = useCallback(
    (index) => {
      const requirement_type_key = `package_template.package_requirements.${index}.requirement_type`
      const is_insurance = getValues(requirement_type_key) === PACKAGE_REQUIREMENT_TYPES.insurance

      if (is_insurance) setValue(requirement_type_key, null, { shouldDirty: true })
    },
    [getValues, setValue]
  )

  const onNullifyOthers = (type, except_indexes) => {
    const indexes = [...Array(fields.length).keys()]
    const excludes = Array.isArray(except_indexes) ? except_indexes : [except_indexes]

    for (const index of indexes) {
      if (excludes.includes(index)) continue

      switch (type) {
        case PACKAGE_REQUIREMENT_TYPES.recognition_agreement:
          nullifyIfRequirementTypeRecognitionAgreement(index)
          continue
        case PACKAGE_REQUIREMENT_TYPES.insurance:
          nullifyIfRequirementTypeInsurance(index)
          continue
      }
    }
  }

  const nullifyInvalidInsuranceRequirements = () => {
    const except_indexes = []
    const requirements = getValues('package_template.package_requirements')

    requirements.forEach((requirement, index) => {
      if (
        requirement.requirement_type === PACKAGE_REQUIREMENT_TYPES.insurance &&
        (requirement.insurance_metadata?.insurance_risk_types || []).length > 0
      ) {
        except_indexes.push(index)
      }
    })
    onNullifyOthers(PACKAGE_REQUIREMENT_TYPES.insurance, except_indexes)
  }

  useEffect(() => {
    if (recognition_agreement_enabled) return

    const indexes = [...Array(fields.length).keys()]
    for (const index of indexes) {
      nullifyIfRequirementTypeRecognitionAgreement(index)
    }
  }, [recognition_agreement_enabled, fields.length, nullifyIfRequirementTypeRecognitionAgreement])

  return (
    <div className="row">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-requirements" direction="vertical" type="requirements">
          {(provided) => (
            <ul id="requirements-list" className="col-xs-12" {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => {
                return (
                  <Draggable key={field.fieldId} draggableId={`requirement-${field.fieldId}`} index={index}>
                    {(provided) => (
                      <RequirementField
                        dragProvided={provided}
                        field={field}
                        key={field.fieldId}
                        index={index}
                        remove={remove}
                        recognition_agreement_enabled={recognition_agreement_enabled}
                        selectedInsuranceTypes={selectedInsuranceTypes}
                        onViewMyDocumentsModal={onViewMyDocumentsModal}
                        onRemoveDocument={onRemoveDocument}
                        onNullifyOthers={onNullifyOthers}
                        nullifyInvalidInsuranceRequirements={nullifyInvalidInsuranceRequirements}
                      />
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <MyDocumentsModal
        open={visible}
        onClose={onMyDocumentsModalClose}
        buildingId={buildingId}
        fetchUrl={documentsFetchUrl}
        onAttachDocument={onAttachDocument}
        onAttachFiles={onAttachFiles}
      />
    </div>
  )
}
