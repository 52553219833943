import React, { useCallback, useMemo, memo, useState } from 'react'
import clsx from 'clsx'

function Loading({ loading }) {
  if (!loading) return null

  return (
    <div id="loading">
      <i className="fa fa-lg fa-cog fa-spin"></i>
    </div>
  )
}

function useErrors() {
  const [errors, setErrors] = useState([])

  const Component = memo(({ errors }) => {
    const className = clsx('errors', errors.length > 0 && 'has-errors')

    return (
      <ul className={className}>
        {errors.map((error, i) => (
          <li key={i}>error</li>
        ))}
      </ul>
    )
  })

  const addError = useCallback(
    (error) => {
      setErrors((errors) => [...errors, error])
    },
    [setErrors]
  )

  // The original logic is
  // setTimeout(() => {
  //   setErrors([])
  // }, 3000)
  // when adding an error
  const clearErrors = useCallback(() => setErrors([]), [setErrors])

  const scrollToTop = useCallback(() => window.scroll(0, 0), [])

  return useMemo(
    () => ({
      errors,
      addError,
      clearErrors,
      component: Component,
      scrollToTop,
    }),
    [errors, addError, clearErrors, Component, scrollToTop]
  )
}

function PackageTemplatesForm({}) {
  return <div id="package-template-container"></div>
}

export default PackageTemplatesForm
