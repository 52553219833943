import React from 'react'

function SectionTitle({label}) {
  if (!label)
    return null

  return <div className="section-title">
    {label}
  </div>
}

export function Section(props) {
  
  return <div className="basic-info-section">
    <SectionTitle label={props.label} />
  </div>
}

export function Sections({sections}) {
  console.log(sections)
  return <div className="basic-info-sections">
    { sections.map((section, index) => <Section key={index} {...section} />) }
  </div>
}