import React, { Component } from "react"
import DashboardRequestRow from '../QuestionnaireRequest/DashboardRequestRow'
import Paginate from '../QuestionnaireRequest/Paginate'
export default class DashboardRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      questionnaire_requests: props.questionnaire_requests,
      questionnaire_count: props.questionnaire_count,
      page_limit: props.page_limit,
      current_page: props.page || 0,
      org_domain_hash: props.org_domain_hash
      }
  }

  nextPageOfRequests(page_number){
    this.setState({current_page: page_number})
    const paginateData = {page_number: page_number, page_limit: this.state.page_limit}

    $.ajax({
      url: 'questionnaire_requests/paginate',
      method: "POST",
      data: paginateData,
      dataType: 'json',
      success: data => {
        // console.log(data)

        // new questionnaire requests & org domain hash {hashed id, org slug}
        let data_requests = data.questionnaire_requests
        let org_domain_hash = data.org_domain_hash

        // add new questionnaires table + org domains for url
        this.setState({questionnaire_requests: data_requests})
        this.setState({org_domain_hash: org_domain_hash})
      }
    });
  }

  renderHeader(){
    return (
      <thead>
        <tr>
          <th>
            <span className='pull-left sortable-column-header'>Property</span>
          </th>
          <th>
            <span className='pull-left sortable-column-header'>
              Requesting Party
            </span>
          </th>
          <th>
            <span className='pull-left sortable-column-header'>Company</span>
          </th>
          <th>
            <span className='pull-left sortable-column-header'>Received</span>
          </th>
          <th>
            <span className='pull-left sortable-column-header'>Status</span>
          </th>
          <th>
            <span className='pull-left sortable-column-header'>Attachment</span>
          </th>
          <th></th>
        </tr>
      </thead>
    )
  }

  renderRows() {
    if ((this.state.questionnaire_requests || []).length == 0)
      return ""

    const requests = this.state.questionnaire_requests.map(request => <DashboardRequestRow key={request.id} questionnaire_request={request} org_domain_hash={this.state.org_domain_hash} />)

    return <tbody className="questionnaire-requests">{requests}</tbody>
  }

  renderPagination(){
    if (this.props.questionnaire_count < this.props.page_limit)
      return ""

    return <Paginate
              current_page={this.state.current_page}
              page_limit={this.state.page_limit}
              questionnaire_count={this.state.questionnaire_count}
              set_page={page => this.nextPageOfRequests(page)}/>
  }


  render() {
    if (this.props.questionnaire_count == 0)
      return <div className="jumbotron is-empty">
              <p>You have no questionnaire requests</p>
            </div>

    return <div>
      <table className="table mobile-table-display dome-table dome-table-striped">
        {this.renderHeader()}
        {this.renderRows()}
      </table>
      {this.renderPagination()}
    </div>
  }
};
