
import { createSlice } from '@reduxjs/toolkit'
import { uniq, mapValues } from 'lodash'

import { pathMapToHash, generateRoute } from 'lib/utilities/form'
import { findErrors } from '../../form_field/validations'

const initialData = {

  form_id: null,
  metadata: {},

  updates: {},
  conflicts: {},

  originalValues: {},
  values: {},
  valuesHash: {},
  errors: {},
  errorsHash: {},

  removedValues: [],
}

const nullIfEmpty = item => isEmpty(item) ? null : item

const DataStore = createSlice({
  name: "data",
  initialState: initialData,
  reducers: {
    load(state, { payload: { user_roles, user_permissions, form_id, values, metadata }}) {
      state.form_id = form_id

      state.metadata = metadata
      state.originalValues = values
      state.values = values
      state.valuesHash = pathMapToHash(values)
      state.user_roles = user_roles
      state.user_permissions = user_permissions

      // Find Errors
      const errors = {}
      const steps = []
      for (let path in values)
        steps.push(generateRoute(path))

      for (let path of uniq(steps.flat()))
        findErrors(path, metadata, state.valuesHash, errors, {fullRoute: false})

      state.errors = mapValues(errors, nullIfEmpty)
      state.errorsHash = pathMapToHash(state.errors)

      // Find Conflicts
      const conflicts = {}

      for (let path in state.updates)
        if (state.updates[path][0] != values[path])
          conflicts[path] = true

      state.conflicts = conflicts

      state.removedValues = []
    },

    updateValues(state, { payload: hash }) {
      const updates = {}

      for (let path in hash) {
        updates[path] = [
          (state.updates[path] ? state.updates[path][0] : state.values[path]),  // Original Value
          hash[path] // New Value
        ]
      }

      state.updates = { ... state.updates, ... updates }
      state.values = { ...state.values, ...mapValues(state.updates, v => v[1]) }
      state.valuesHash = pathMapToHash(state.values)
    },

    clearUpdates(state) {
      state.updates = {}
    },

    checkErrors(state, { payload: { path, required } }) {
      const errors = { ...state.errors }
      findErrors(path, state.metadata, state.valuesHash, errors, { required })

      state.errors = mapValues(errors, nullIfEmpty)
      state.errorsHash = pathMapToHash(state.errors)
    },

    clearPathErrors(state, { payload: path }) {
      const errors = { ... state.errors }

      for (let segment of generateRoute(path, { metadata: false }))
        delete errors[segment]

      state.errors = errors
      state.errorsHash = pathMapToHash(errors)
    },

    clearErrors(state) {
      state.errors = {}
      state.errorsHash = {}
    }
  }
})

export const reducer = DataStore.reducer
export const { load, updateValues, checkErrors, clearPathErrors, clearErrors, clearUpdates } = DataStore.actions