import React from 'react'

import {useSelector} from 'react-redux'

import { usePerson } from '..'

function findInactivePeople(state, role_id) {
  const { person_for_deal_party, people_by_party } = state
  const assignedPeopleIds = Object.values(person_for_deal_party)

  return people_by_party[role_id].filter(person_id => !assignedPeopleIds.includes(person_id))
}

function Person({person_id}) {
  const person = usePerson(person_id)

  return <div className="person">
    {person.name}
  </div>
}

function NoPeople({label}) {

  return
}

function InactivePeople({role_id}) {
  const inactivePeople = useSelector(state => findInactivePeople(state.data, role_id))

  return <>
    { inactivePeople.map(person_id => <Person key={person_id} person_id={person_id} />)}
  </>
}

export default function({role_id}) {
  const party = useSelector(({data}) => data.deal_party_metadata[role_id])
  const inactivePeople = useSelector(({ data }) => findInactivePeople(data, role_id))

  return <div className="inactive-people">
    <div className="title">Inactive {party.label}</div>
    { inactivePeople.length == 0 ? <NoPeople label={party.label} /> : <InactivePeople role_id={role_id} />}
  </div>
}
