import React from 'react'
import PropTypes from 'prop-types'

const CastVotesHeader = (props) => (
  props.completed ? <h3>You voted on <b>{props.address}: Unit {props.unit}</b></h3> : <h3>Cast your vote for <b>{props.address}: Unit {props.unit}</b></h3>
)

CastVotesHeader.propTypes = {
  address: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired
}

export default CastVotesHeader
