import React, { useEffect } from 'react'
import Section from './section'
import { useSelector, useDispatch } from 'react-redux'
import { setData, add, setDealParties } from './reducer/deal_party'

// import { useSelector, useDispatch } from 'react-redux'


const App = props => {
  const data = useSelector(state => state.dealParty.data)
  const deletedPaths = useSelector(state => state.dealParty.deletedPaths)
  const dispatch = useDispatch()

  useEffect(() => {
    mapPropDataToState()
  }, []);

  const mapPropDataToState = () => {
    for (let i = 0; i < sortedDealPartiesFormMetadata().length; i++) {
      const role = sortedDealPartiesFormMetadata()[i]['name']

      const roleDataKeys = Object.keys(props.data).filter(key => key.startsWith(`${role}[`))
      roleDataKeys.forEach(key => {
        const value = props.data[key]
        dispatch(add({ path: key, value: value }))
      })
    }
  }

  const sortedDealPartiesFormMetadata = () => {
    const propData = [...props.deal_parties_form_metadata]

    return propData.sort((d1, d2) => d1.order < d2.order ? -1 : 1)
  }

  const renderDealPartySections = () => {
    const sortedSections = sortedDealPartiesFormMetadata(),
          list = []

    for (let i = 0; i < sortedDealPartiesFormMetadata().length; i++) {
      list.push(
        <Section sectionMetadata={sortedSections[i]} />
      )
    }

    return list
  }

  const formattedFieldsForSubmission = (data, keys=[], formatted_fields=[]) => {
    Object.keys(data).forEach(key => {
      let field_keys = [...keys, key]
      if (typeof data[key] === 'object') {
        return formattedFieldsForSubmission(data[key], field_keys, formatted_fields)
      } else {
        formatted_fields.push({path: field_keys, value: data[key]})
      }
    })

    return formatted_fields
  }

  const dataAsHiddenInputs = () => {
    const dataForSubmission = {}
    Object.keys(data).forEach(path => {
      dataForSubmission[path] = data[path]
    })

    deletedPaths.forEach(path => {
      dataForSubmission[path] = ""
    })

    return <input type="hidden" name='deal_parties' value={JSON.stringify(dataForSubmission)} />
  }

  return <React.StrictMode>
    <div id='deal-parties-plugin'>
      {dataAsHiddenInputs()}
      {renderDealPartySections()}
    </div>
  </React.StrictMode>
}

export default App