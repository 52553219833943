import { buildingEditAdditionalAddressesReducer } from 'components/buildings/edit/AdditionalAddresses/store/slice'
import { buildingEditAddStructureReducer } from 'components/buildings/edit/NewStructure/store/slice'
import { buildingStructuresEditStructureReducer } from 'components/buildings/edit/StructureEdit/store/slice'
import { buildingStructuresArchiveStructureReducer } from 'components/buildings/edit/StructureArchive/store/slice'
import { buildingEditBuildingStructureReducer } from 'components/BuildingEditBuildingStructure/store/slice'
import { buildingStructuresPageReducer } from 'components/BuildingStructures/store/slice'
import { buildingUnitNewPageReducer } from 'components/BuildingUnits/New/store/slice'
import { buildingUnitShowPageReducer } from 'components/BuildingUnits/Show/store/slice'
import { buildingUnitEditPageReducer } from 'components/BuildingUnits/Edit/store/slice'
import { importBuildingUnitModalReducer } from 'components/BuildingEditBuildingStructure/components/ImportModal/store/slice'

export const buildingStructuresReducers = {
  buildingStructuresPage: buildingStructuresPageReducer,
  buildingEditAdditionalAddresses: buildingEditAdditionalAddressesReducer,
  buildingEditAddStructure: buildingEditAddStructureReducer,
  buildingStructuresEditStructure: buildingStructuresEditStructureReducer,
  buildingStructuresArchiveStructure: buildingStructuresArchiveStructureReducer,
  buildingEditBuildingStructure: buildingEditBuildingStructureReducer,
  buildingUnitNewPage: buildingUnitNewPageReducer,
  buildingUnitShowPage: buildingUnitShowPageReducer,
  buildingUnitEditPage: buildingUnitEditPageReducer,
  importBuildingUnitModal: importBuildingUnitModalReducer,
}
