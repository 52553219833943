import { configureStore } from '@reduxjs/toolkit'
import { listenerMiddleware } from 'components/global/listener'
import { packageTemplatesFormReducer } from './slice'
import { combineReducers } from 'redux'

export const store = configureStore({
  reducer: combineReducers({
    packageTemplatesForm: packageTemplatesFormReducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === 'development',
})
