import React from 'react'
import PropTypes from 'prop-types'
import PaymentExport from './Modals/PaymentExport'
import FilterButton from './Buttons/FilterButton'
import ExportButton from './Buttons/ExportButton'
import Filters from './Filters/Filters'
import ActiveFilters from './Filters/ActiveFilters'
import { debounce } from 'lodash'

function createObjFromURI() {
  let uri = decodeURI(location.search.substr(1));
  let chunks = uri.split('&'),
      params = Object();

  if (chunks.length == 1 && chunks[0] == "") return {}

  for (let i=0; i < chunks.length ; i++) {
    const chunk = chunks[i].split('=');
    chunk[1]=chunk[1].replace(/\+/g, '%20');

    if(chunk[0].search("\\[\\]") !== -1) {
      chunk[0]=chunk[0].replace(/\[\]$/,'');
      chunk[1]=unescape(chunk[1]);
      typeof params[chunk[0]] === 'undefined'
        ? params[chunk[0]] = [chunk[1]]
        : params[chunk[0]].push(chunk[1]);
    } else {
      params[chunk[0]] = unescape(chunk[1]);
    }
  }

  return params;
}

function formatDate(date) {
  if (!date) return null

  const datePieces = date.split('-'),
        year = datePieces[0],
        month = datePieces[1],
        day = datePieces[2].split('T')[0]

  return `${month}/${day}/${year}`
}

function toDate(stringDate) {
  if (!stringDate) return null

  return new Date(stringDate)
}

export default class Payments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      transactionItems: [],
      showFilters: false,
      showExports: false,
      isPaginating: false,
      page: 1,
      noMorePages: false
    }

    this.toggleFilters = this.toggleFilters.bind(this)
    this.toggleExportModal = this.toggleExportModal.bind(this)
    this.setPaymentsState = this.setPaymentsState.bind(this)
    this.handleClickHeader = this.handleClickHeader.bind(this)

    this.tableContainer = null
    this.table = null
    this.setTableContainerRef = element => { this.tableContainer = element };
    this.setTableRef = element => { this.table = element };
  }

  componentDidMount() {
    this.setState({ transactionItems: this.props.transactionItems })
    this.listenTableScroll()
  }

  setPaymentsState(newState) {
    this.setState(newState)
  }

  paginate() {
    const data = createObjFromURI()
    data["page"] = this.state.page

    $.ajax({
      url: "/api/v2/financials",
      method: "GET",
      dataType: "JSON",
      data: data,
      context: this,
      success: res => {
        if (res.transactionItems.length == 0)
          return this.setState({ noMorePages: true, isPaginating: false })

        const transactionItems = [...this.state.transactionItems, ...res.transactionItems]
        this.setState({transactionItems: transactionItems, isPaginating: false })
      }
    })
  }

  listenTableScroll() {
		// window.addEventListener('scroll', debounce(() => this.infiniteScrollCall(), 20) );

    $(this.tableContainer).scroll(debounce(() => this.handleScroll(), 100))

    // $(this.tableContainer).scroll($.debounce(100, () => this.handleScroll()))
  }

  handleScroll() {
    const { isPaginating, page, noMorePages } = this.state

    let $tableContainer = $(this.tableContainer)
    if (isPaginating || noMorePages)
      return

    // check if scrolled close to bottom of page
    if ($tableContainer.prop('scrollHeight') - $tableContainer.prop('offsetHeight') - $tableContainer.scrollTop() > 100)
      return

    this.setState({isPaginating: true, page: page+1})
    this.paginate()
  }

  handleClickHeader(header) {
    let params = createObjFromURI()

    // default sort is payout_date desc
    if ($.isEmptyObject(params) && header == "payout_date") {
      params["sort"] = header
      params["sortDirection"] = "asc"
    } else {
      if (params["sort"] && params["sort"] == header) {
        params["sortDirection"] = params["sortDirection"]
          ? params["sortDirection"] == "asc" ? "desc" : "asc"
          : "asc"
      } else {
        params["sort"] = header
        params["sortDirection"] = "asc"
      }
    }

    window.location = location.origin + "/api/v2/financials?" + $.param(params)
  }

  sortDirection(header) {
    let params = createObjFromURI()

    if ($.isEmptyObject(params) && header == "payout_date")
      return "desc"

    if (params["sort"] != header)
      return

    return params["sortDirection"]
  }

  toggleFilters() {
    this.setState({ showFilters: !this.state.showFilters })
  }

  toggleExportModal() {
    console.log('should show export modal')
    this.setState({ showExports: true })
  }

  renderExportsModal() {
    if (this.state.showExports)
      return <PaymentExport
        filters={this.props.activeFilters}
        exportColumns={this.props.exportColumns}
        accounts={this.props.accounts}
        close={() => this.setPaymentsState({showExports: false})} />
  }

  renderButtonsContainer() {
    return <div className="buttons-container">
      <div className="filters-container">
        <div className="filter-button-container">
          <FilterButton
            handleClick={this.toggleFilters}
            filterButtonIconPath={this.props.filterButtonIconPath} />
          <ExportButton
            handleClick={this.toggleExportModal}
            filters={this.props.activeFilters}
            exportButtonIconPath={this.props.exportButtonIconPath} />
        </div>
        {this.renderFilter()}
      </div>
    </div>
  }

  renderFilter() {
    return <Filters
      visible={this.state.showFilters}
      setPaymentsState={this.setPaymentsState}
      activeFilters={this.props.activeFilters || {}}
      accounts={this.props.accounts}
      transactionTypes={this.props.transactionTypes} />
  }

  renderActiveFilters() {
    if (this.props.activeFilters)
      return <ActiveFilters
        filters={this.props.activeFilters}
        accounts={this.props.accounts} />
  }

  renderSortIcon(header) {
    const direction = this.sortDirection(header)

    if (!direction)
      return <i className="sort-icon fa fa-lg fa-sort"></i>

    if (direction == "asc")
      return <i className="sort-icon fa fa-lg fa-sort-up" style={{"position": "relative", "top": "4"}}></i>

    if (direction == "desc")
      return <i className="sort-icon fa fa-lg fa-sort-down" style={{"position": "relative", "top": "-2"}}></i>
  }

  renderTransactionItemsHeader() {
    return <thead className="transaction-items-thead">
      <tr>
        <th onClick={() => this.handleClickHeader("address")} className="transaction-items-th">
          <span className="sortable">Address {this.renderSortIcon("address")}</span>
        </th>
        <th className="transaction-items-th">Unit</th>
        <th className="transaction-items-th">Amount</th>
        <th className="transaction-items-th">Description</th>
        <th onClick={() => this.handleClickHeader("transaction_date")} className="transaction-items-th sortable">
          <span className="sortable">Collected {this.renderSortIcon("transaction_date")}</span>
        </th>
        <th onClick={() => this.handleClickHeader("payout_date")} className="transaction-items-th sortable">
          <span className="sortable">Deposited {this.renderSortIcon("payout_date")}</span>
        </th>
        <th className="transaction-items-th">Payor</th>
        <th className="transaction-items-th">Type</th>
        <th onClick={() => this.handleClickHeader("sub_merchant_description")} className="transaction-items-th sortable">
          <span className="sortable">Account {this.renderSortIcon("sub_merchant_description")}</span>
        </th>
      </tr>
    </thead>
  }

  renderTransactionTbody() {
    let transactionItemsList = []

    for(let i = 0; i < this.state.transactionItems.length; i++) {
      const transactionItem = this.state.transactionItems[i],
            transactionDate = toDate(transactionItem.transaction_date), // for tooltip
            formattedTransactionDate = formatDate(transactionItem.transaction_date),
            payoutDate = toDate(transactionItem.payout_date), // for tooltip
            formattedPayoutDate = formatDate(transactionItem.payout_date)


      transactionItemsList.push(
        <tr key={`transaction-item-${transactionItem.id}`}
          className="transaction-items-tr">
          <td>{transactionItem.address}</td>
          <td>{transactionItem.unit}</td>
          <td>{asMoney(transactionItem.amount)}</td>
          <td>{transactionItem.name}</td>
          <td title={transactionDate}>{formattedTransactionDate}</td>
          <td title={payoutDate}>{formattedPayoutDate}</td>
          <td>{transactionItem.user_name || transactionItem.user_email}</td>
          <td>{typeToHumanFriendlyString(transactionItem.item_type)}</td>
          <td>{transactionItem.sub_merchant_description}</td>
        </tr>
      )
    }

    return <tbody className="transaction-items-tbody">
      {transactionItemsList}
    </tbody>
  }

  renderTransactionItemsTable() {
    return <table className="transaction-items-table" >
      {this.renderTransactionItemsHeader()}
      {this.renderTransactionTbody()}
    </table>
  }

  renderPaginationLoader() {
    if (!this.state.isPaginating)
      return

    return <div className="loading-dots"></div>
  }

  render() {
    const { } = this.state
    return <div className="payments">
      { this.renderExportsModal() }
      { this.renderActiveFilters() }
      { this.renderButtonsContainer() }
      <div className="transaction-table-container" ref={this.setTableContainerRef}>
        { this.renderTransactionItemsTable() }
        { this.renderPaginationLoader() }
      </div>
    </div>
  }
}

Payments.propTypes = {
  transactionItems: PropTypes.array,
  activeFilters: PropTypes.object,
  accounts: PropTypes.array,
  transactionTypes: PropTypes.array,
  exportColumns: PropTypes.object,
  exportButtonIconPath: PropTypes.string,
  filterButtonIconPath: PropTypes.string
}

Payments.defaultProps = {
  activeFilters: {}
}

