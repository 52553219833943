import React, { Component } from "react"

function email_with_file(email) {
  return ["both", "with_file"].includes(email.with_file)
}

function email_without_file(email) {
  return ["both", "without_file"].includes(email.with_file)
}

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

export default class Emails extends Component {
  add_url() {
    return `/super/organizations/${this.props.organization.id}/questionnaires/email`
  }

  edit_url() {
    return `/super/organizations/${this.props.organization.id}/questionnaires/email`
  }

  remove_url() {
    return `/super/organizations/${this.props.organization.id}/questionnaires/email`
  }

  constructor(props) {
    super(props)

    this.state = {}
    this.state.emails = this.props.emails || []
    this.state.new_email = {}
  }

  render() {
    return <div id="questionnaire-request-admin-emails" className="questionnaire-manager-emails">
      { this.renderHeaders() }
      { this.renderList() }
      { this.renderAdd() }
    </div>
  }

  renderHeaders() {
    return <div className="list-header">
      <div className="email">Email</div>
      <div className="with_file"><div>Standard</div><div className="subtext">(Without File)</div></div>
      <div className="without_file"><div>Custom</div><div className="subtext">(With File)</div></div>
      <div className="buttons"></div>
    </div>
  }

  renderList() {
    return <div className="list">
      { this.state.emails.map(email => this.renderListItem(email)) }
    </div>
  }

  renderListItem(email) {
    return <div key={email.questionnaire_manager_email_key}>
      <div className="email">{email.email}</div>
      {this.renderCheckbox("without_file", email_without_file(email), () => this.toggleWhenToSend(email, "without"))}
      {this.renderCheckbox("with_file", email_with_file(email), () => this.toggleWhenToSend(email, "with"))}
      <div className="cancel buttons">
        <i className="fa fa-times" onClick={() => this.remove(email)} />
      </div>
    </div>
  }

  renderAdd() {
    return <div className="add">
      <div className="email"><input onChange={e => this.updateNewEmail("email", e.target.value)} ref="new_email" type="text" placeholder="Enter Email" /></div>
      {this.renderCheckbox("without_file", this.state.new_email.without_file,   e => this.updateNewEmail("without_file", !this.state.new_email.without_file))}
      {this.renderCheckbox("with_file", this.state.new_email.with_file, e => this.updateNewEmail("with_file", !this.state.new_email.with_file))}
      <div className="buttons">
        <button type="button" onClick={() => this.submitNew()}>Add</button>
      </div>
    </div>
  }

  renderCheckbox(name, active, onClick) {
    return <div className={name}>
      <span onClick={e => onClick(e)}>{active ? <i className="fa fa-check" /> : ""}</span>
    </div>
  }

  updateNewEmail(attribute, value) {
    const new_email = Object.assign({}, this.state.new_email)
    new_email[attribute] = value
    this.setState({new_email: new_email})
  }

  toggleWhenToSend(email, option) {
    const request_type = { with: email_with_file(email), without: email_without_file(email)}
    request_type[option] = !request_type[option]

    if (!request_type.with && !request_type.without)
      return

    const data = {  email: email.email,
                    with_file:  request_type.with ? ( request_type.without ? "both" : "with_file" ) : "without_file" }

    this.replaceIntoList(email, Object.assign({}, email, { with_file: data.with_file }))
    $.ajax({
      url: this.edit_url(),
      data: addMeta(data),
      type: "put",
      success: res => this.replaceIntoList(email, res.email)
    })
  }

  remove(email) {
    const pre_submit_options = {
      title: "Are you sure?",
      text: `Please confirm you wish to remove ${email.email} from the list of managers`,
      showCancelButton: true
    }

    const ajax_options = {
      url: this.remove_url(),
      data: addMeta({ email: email.email }),
      type: "delete",
      success: res => this.replaceIntoList(email)
    }

    swal(pre_submit_options, success => success ? $.ajax(ajax_options) : null)
  }

  submitNew() {
    const data = { email: this.state.new_email.email,
                    with_file: this.state.new_email.with_file ? (this.state.new_email.without_file ? "both" : "with_file") : "without_file" }

    $.ajax({
      url: this.add_url(),
      type: "post",
      data: addMeta(data),
      success: res => this.replaceIntoList(null, res.email)
    })
  }

  replaceIntoList(old_email, new_email) {
    const emails = this.state.emails.concat([])

    if (!old_email && !new_email)
      return

    if (!old_email)
      return this.setState({emails: this.state.emails.concat([new_email])})

    const index = emails.indexOf(old_email)
    if (new_email)
      emails.splice(index, 1, new_email)
    else
      emails.splice(index, 1)

    this.setState({emails: emails})
  }
};