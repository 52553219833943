import React from 'react'
import ReactSlider from 'react-slider'
import { compact, pick, omitBy } from 'lodash'

const isEmpty = val => !val || String(val).match(/^\s*$/)

function Checkbox({value, toggle, disabled, onBlur}) {
  return <input type="checkbox" disabled={disabled} onBlur={onBlur} onChange={toggle} checked={value || false} />
}

function Static({value}) {
  return <div className="static-value">
    { value ? "Yes" : "No" }
  </div>
}

function Switch({value, toggle, disabled, onBlur}) {
  const classes = compact([
    'boolean-switch',
    value ? 'boolean-switch-on' : 'boolean-switch-off'
  ])

  return <div className={classes.join(" ")} onClick={() => toggle()}>
    <div>Yes</div>
    <div className="switch">
      <div></div>
    </div>
    <div>No</div>
  </div>
}

const VARIANTS = {
  checkbox: Checkbox,
  switch: Switch,
}

const getVariant = variant => VARIANTS[variant] || Switch

export default function(props) {
  const { field, id, value, onChange } = props
  const { system_only } = field
  const variant = props.variant || field.variant

  const toggle = () => onChange({[id]: !value})

  const boolProps = omitBy({ 
    ... pick(props, ['disabled', 'onBlur']),
    value,
    toggle
  }, isEmpty)
  
  const classes = compact([
    props.className
  ])

  const Variant = getVariant(variant)

  return <div className={classes.join(" ")}>
    { system_only ? <Static value={value} /> : <Variant { ...boolProps } /> }
  </div>
}