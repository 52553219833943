import React from 'react'
import PropTypes from 'prop-types'

class ReasonSelector extends React.Component {
  constructor(props) {
    super(props)
  }

  renderReasonOptions() {
    const options = ["-Select Reason for Refund-", "Fraudulent", "Duplicate", "Requested by customer", "Other"]
    let optionsList = []

    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      optionsList.push(
        <option key={`option_${i}`} value={option} disabled={i==0 ? true : null}>{option}</option>
      )
    }

    return optionsList
  }

  handleChange(e) {
    const selectedReason = e.target.value
    this.props.setAppState({ reason: selectedReason })
  }

  render() {
    return (
      <select name="refund-reason-select" onChange={(e) => this.handleChange(e)} value={this.props.reason}>
        {this.renderReasonOptions()}
      </select>
    )
  }
}

ReasonSelector.propTypes = {
  reason: PropTypes.string,
  setAppState: PropTypes.func.isRequired
}

export default ReasonSelector
