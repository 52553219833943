import React, { Component } from "react"
export default class EditModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: ''
    }

    this.modal = null

    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    this.open()
  }

  componentWillUnmount() {
    this.close()
  }

  close() {
    $(this.modal).modal('hide')
  }

  open() {
    $(this.modal).modal('show')
  }

  name() {
    return this.props.file.name
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    const {file} = this.props

    if (this.state.value != '')
      this.props.updateFileName(file.id, this.state.value)

    event.preventDefault();
    this.props.closeEditModal()
  }


  renderForm(){
    return (
    <div className="center-this-tag">
      <label>
        Name:
          <input type="text" value={this.state.value} onChange={event => this.handleChange(event)} />
      </label>
    </div>
    )
  }


  renderEditModal(){
    return (
      <div className="questionnaire-modal-container">
        {this.renderForm()}
      <div className="questionnaire-modal-center">
        <button className="questionnaire-pricing-btn-submit"  onClick={(event) => this.handleSubmit(event)} >OK</button>
      </div>
    </div>
    )
  }

  render(){
    return (
      <div className="modal" ref={this.setModalRef}>
        <div className="modal-dialog modal-md questionnaire-modal-dialog">
          <div className="modal-body">
            <h3 className="text-center bopa-text questionnaire-form-header">Edit Name</h3>
                {this.renderEditModal()}
          </div>
        </div>
      </div>
    )
  }
};
