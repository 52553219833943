import React from 'react'
import $ from 'jquery'

import DealParty from './deal_party'

class Request extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.submitted_reports = this.props.submitted_reports || {}
    this.state.new_reports = {}

    this.loadPartyDocuments()
  }

  loadPartyDocuments() {
    this.state.party_documents = {}
    for (let {party_id, doc_id, type_id } of this.props.party_documents || []) {
      this.state.party_documents[party_id] = this.state.party_documents[party_id] || [] 

      if (this.props.document_types[type_id])
        this.state.party_documents[party_id].push({ doc_id, type_id })
    }

    this.state.document_hashes = this.generateDocumentHashes()
  }

  missingDocumentCount(party_id, report_id) {
    const document_types = this.props.report_types[report_id].credit_document_types
    const party_documents = this.state.party_documents[party_id] || []

    let count = 0
    for (let document_type of document_types)
      if (!party_documents.some(({type_id}) => String(type_id) == String(document_type.id)))
        count++

    return count
  }

  generateDocumentHashes() {
    const document_hashes = {}
    for (let party of this.dealParties())
      document_hashes[party.id] = this.getDocumentHash(party.id)
    return document_hashes
  }

  getDocumentHash(party_id) {
    const docs = this.state.party_documents[party_id]
    return docs && docs.length > 0 ? JSON.stringify(docs) : null
  }

  hasNewReports() {
    for (let key in this.state.new_reports)
      if (this.state.new_reports[key].length > 0)
        return true

    return false
  }

  hasNewDocuments() {
    for (let party of this.dealParties())
      if (this.state.document_hashes[party.id] != this.getDocumentHash(party.id))
        return true

    return false
  }

  hasSubmittedReports() {
    return Object.values(this.state.submitted_reports).some(reports => reports.length > 0)
  }

  partiesWithReports() {
    const parties = []
    for (let party_id in this.state.submitted_reports)
      if (this.state.submitted_reports[party_id].length > 0)
        parties.push(party_id)

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    return parties.concat(Object.keys(this.state.new_reports)).filter(onlyUnique)
  }

  reportTypes() {
    return Object.values(this.props.report_types || {}) || []
  }

  dealParties() {
    return this.props.deal_parties || []
  }

  render() {
    return <div className="report-component" ref="component">
      <div className="table-container">
        <div className="table-responsive">{this.renderTable()}</div>
      </div>
      {this.renderButtons()}
    </div>
  }

  renderButtons() {
    const disabled = (!this.hasNewReports() && !this.hasNewDocuments()) || this.state.submitting

    const button_classes = ["btn", "btn-bper"]
    if (disabled)
      button_classes.push("disabled")

    const dirty_text = `Changes have been detected. Please do not forget to ${this.hasSubmittedReports() ? "update" : "submit"} the request.`

    return <div className="buttons text-right">
      {disabled ? null : <span className="dirty-text">{dirty_text}</span>}
      <button className={button_classes.join(" ")} onClick={() => this.confirmRequest()} disabled={disabled}>
        {this.hasSubmittedReports() ? "Update Request" : "Request Report" }
      </button>
    </div>
  }

  renderTable() {
    return <div className="credit-table">
      <div className="header-row">
        <div className="deal-party">Deal Party</div>
        <div className="client-report-request">Report</div>
        <div className="supporting-documents"></div>
      </div>
      {this.dealParties().map(party => this.renderDealPartyRow(party))}
    </div>
  }

  renderDealParties() {
    return this.dealParties
  }

  setPartyDocuments(party_documents) {
    for (let party_id in party_documents)
      if (party_documents[party_id].length == 0)
        delete party_documents[party_id]

    this.setState({party_documents})
  }

  renderDealPartyRow(party) {
    const onChangeReports = for_party => {
      const new_reports = Object.assign({}, this.state.new_reports)
      new_reports[party.id] = for_party
      this.setState({new_reports})
    }

    const onChangeDocuments = for_party => {
      const party_documents = Object.assign({}, this.state.party_documents)
      party_documents[party.id] = for_party
      this.setPartyDocuments(party_documents)
    }

    const { package_id, report_types, document_types } = this.props

    const documents = this.state.party_documents[party.id] || []
    const submitted_reports = this.state.submitted_reports[party.id]

    return <DealParty key={party.id} package_id={package_id} 
                      party={party} documents={documents} submitted_reports={submitted_reports}  
                      report_types={report_types || {}} document_types={document_types || {}} 
                      onChangeNewReports={onChangeReports} onChangeDocuments={onChangeDocuments} />
  }

  confirmNewReports() {
    const submitted_reports = Object.assign({}, this.state.submitted_reports)
    const new_reports = Object.assign({}, this.state.new_reports)
    const document_hashes = this.generateDocumentHashes()

    for (let party_id in new_reports) 
      submitted_reports[party_id] = (submitted_reports[party_id] || []).concat(new_reports[party_id])
    
    this.setState({submitted_reports, new_reports: {}, document_hashes})
  }

  submitRequest() {
    if (this.submitting)
      return 

    this.setState({submitting: (this.submitting = true)})

    const data = {}
    data.persons = this.partiesWithReports()
    data.reports = this.state.new_reports

    data.documents = {}

    for (let party_id in this.state.party_documents) {
      data.documents[party_id] = {}

      for (let {type_id, doc_id} of this.state.party_documents[party_id]) {
        data.documents[party_id][type_id] = data.documents[party_id][type_id] || {}
        data.documents[party_id][type_id][doc_id] = 1
      }
    }


    $.ajax({
      url: this.props.update_url,
      type: "post", 
      data: data,
      complete: () => {
        delete this.submitting
        this.setState({submitting: null})
      },
      success: () => {
        this.confirmNewReports()
        swal({
          title: 'Success',
          type: 'success',
          text: 'You have successfully submitted the requested report to the Credit Analyst'
        })
      }
    })
  }

  submittableParties() {
    const submittable_party = party => {
      if ((this.state.new_reports[party.id] || []).length > 0)
        return true

      if (this.state.document_hashes[party.id] != this.getDocumentHash(party.id))
        return true

      return true
    }

    return this.dealParties().filter(submittable_party)
  }

  orderedReports(report_ids) {
    if (!report_ids)
      report_ids = []

    return Object.keys(this.props.report_types).filter(id => report_ids.some(id2 => String(id2) == String(id)))
  }

  confirmRequest() {
    const generate_party = party => {
      const new_reports = this.state.new_reports[party.id] || []
      const old_reports = this.state.submitted_reports[party.id] || []
      const reports = this.orderedReports(old_reports.concat(new_reports)).map(report => generate_report(party.id, report))

      return `<div>
        <div class="user-name">${party.full_name}</div>
        <ul class="reports">${reports.join("")}</ul>
      </div>`
    }

    let missing_documents = false
    const generate_report = (party_id, report_id) => {
      const classes = []
      const old_reports = this.state.submitted_reports[party_id] || []
      if (old_reports.some(report => report == report_id))
        classes.push("already-submitted")

      const missing_count = this.missingDocumentCount(party_id, report_id)
      if (missing_count > 0)
        missing_documents = true

      const missing_documents_html = `<i class="fa fa-exclamation-triangle" data-toggle="tooltip" title="${`This report is missing ${missing_count} associated document type${missing_count == 1 ? '' : 's'}`}"></i>` 

      return `<li${classes.length == 0 ? "" : ` class="${classes.join(" ")}"`}>
        ${missing_count == 0 ? `<i class="fa fa-check"></i>` : missing_documents_html }
        <div>${this.props.report_types[report_id].title}</div>
      </li>`
    }

    const error_warning = `<div class="warning">
      <div>
        It looks like there are supporting documents missing for this request.
      </div>
      <div>
        If you Send Request, the Credit Analyst will be notified of a new credit report request.
      </div>
      <div class="even-smaller">
        You can update the request by adding additional reports and include supporting documents.
      </div>
    </div>`

    const content = `<div id="confirm-reports-modal">
      <p>Please confirm the report options below are accurate:</p>
      ${this.submittableParties().map(generate_party).join("")}
      ${missing_documents ? error_warning : ""}
    </div>`
    // ReactDOM.render(<div>HI</div>, wrapper)
  
    swal({
      title: "Request Report",
      html: true,
      text: content,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f68a41",
      confirmButtonText: "Send Request",
      closeOnConfirm: false,
      customClass: 'swal-request-report',
      }, confirm => {
        const modal = $("#confirm-reports-modal").closest(".sweet-alert")
        modal.find("button").prop("disabled", true).addClass("disabled")
        confirm ? this.submitRequest() : null
      }
    ) 

    setTimeout(() => $('#confirm-reports-modal [data-toggle=tooltip]').tooltip(), 1000)
  }
}

export default Request