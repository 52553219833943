import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'
import { removeFromNamespace, getFromNamespace } from 'lib/utilities'

class BuildingCards extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      buildingsList: [],
      maxBuildingsInQueue: 10,
      activeCard: null,
      markerBuildingQueue: null,
      selectedBuildingCardStatus: {
        incoming: null,
        current: null,
        outgoing: null,
        buildingRotationIntervalIds: []
      },
      featuredCardStatus: {
        incoming: null,
        current: null,
        outgoing: null,
        buildingRotationIntervalIds: []
      },
      isMobile: false
    }

    this.setAppState = this.setAppState.bind(this)
    this.stopBuildingRotationInterval = this.stopBuildingRotationInterval.bind(this)
    this.setBuildingRotationInterval = this.setBuildingRotationInterval.bind(this)
  }

  componentDidMount() {
    this.restockBuildings()
    this.setIsMobile()
    this.waitUntilMapBuildingDataIsLoaded()
    this.mapClickListener()
    this.setBuildingRotationInterval("featured")
  }

  checkIfMapBuildingDataIsLoaded() {
    const data = getFromNamespace('BuildingCards.Global.mapBuildingData')

    return data && data.length > 0
  }

  waitUntilMapBuildingDataIsLoaded() {
    if (this.checkIfMapBuildingDataIsLoaded() == true && this.state.loading == true) {
      this.setState({ loading: false })
      return this.setInitialCards()
    }

    const mapLoadInterval = setInterval(() => {
      if (this.checkIfMapBuildingDataIsLoaded() == true) {
        this.setState({ loading: false })
        this.setInitialCards()
        clearInterval(mapLoadInterval)
      }
    }, 1000)
  }

  setIsMobile() {
    const isMobile = window.innerWidth < this.props.mobileWidth

    if (isMobile != this.state.isMobile) return this.setState({ isMobile: isMobile })
  }

  setInitialCards() {
    if (this.state.buildingsList.length == 0)
      this.restockBuildings()

    let featuredCardStatus = this.state.featuredCardStatus,
        buildingsList = this.state.buildingsList

    featuredCardStatus.current = buildingsList.shift()
    featuredCardStatus.incoming = buildingsList.shift()

    this.setState({ featuredCardStatus: featuredCardStatus, buildingsList: buildingsList })
  }

  setBuildingRotationInterval(forCardType) {
    let cardStatus = forCardType == "featured" ? this.state.featuredCardStatus : this.state.selectedBuildingCardStatus
    let intervals = cardStatus.buildingRotationIntervalIds

    if (intervals.length > 0) this.stopBuildingRotationInterval(forCardType)

    const interval = setInterval(() => {
      this.rotateCardStatuses(forCardType)
      if (this.state.buildingsList.length < 3) this.restockBuildings()
    }, 5000)

    cardStatus.buildingRotationIntervalIds.push(interval)
    const newStatus = forCardType == "featured" ? { featuredCardStatus: cardStatus } : { selectedBuildingCardStatus: cardStatus }

    this.setState(newStatus)
  }

  resetBuildingRotationInterval(forCardType) {
    this.stopBuildingRotationInterval(forCardType)
    this.setBuildingRotationInterval(forCardType)
  }

  stopBuildingRotationInterval(forCardType) {
    let cardStatus = forCardType == "featured" ? this.state.featuredCardStatus : this.state.selectedBuildingCardStatus

    if (cardStatus.buildingRotationIntervalIds.length == 0) return

    for (let id of cardStatus.buildingRotationIntervalIds)
      clearInterval(id)

    cardStatus.buildingRotationIntervalIds = []

    const newStatus = forCardType == "featured" ? { featuredCardStatus: cardStatus } : { selectedBuildingCardStatus: cardStatus }
    this.setState(newStatus)
  }

  restockBuildings() {
    const numOfBuildingsNeeded = this.state.maxBuildingsInQueue - this.state.buildingsList.length
    let totalBuildingsArr = getFromNamespace('BuildingCards.Global.mapBuildingData')

    if (!totalBuildingsArr) return
    if (numOfBuildingsNeeded <= 0) return

    while (totalBuildingsArr.length < numOfBuildingsNeeded && totalBuildingsArr.length > 0)
      totalBuildingsArr = [].concat.apply(totalBuildingsArr, totalBuildingsArr)

    const newBuildings = getRandomElements(totalBuildingsArr, numOfBuildingsNeeded, this.state.buildingsList)
    const combinedBuildings = [...this.state.buildingsList, ...newBuildings]
    this.setState({
      buildingsList: combinedBuildings
    })
  }

  mapClickListener() {
    // Disable marker click for mobile
    if (this.state.isMobile) return
    $(this.props.mapContainerSelector).on("click", function () {
      console.log('mapClickListener')
      const clickedBuilding = this.getSelectedMarkerBuildingFromGlobal()

      if (!clickedBuilding) return

      let { selectedBuildingCardStatus } = this.state

      // Sets markerBuildingQueue to the globally set value if it's not the same
      // if (clickedBuilding != this.state.buildingCardStatus.selectedBuilding) {
      if (clickedBuilding != selectedBuildingCardStatus.current) {
        selectedBuildingCardStatus.outgoing = selectedBuildingCardStatus.current
        selectedBuildingCardStatus.current = clickedBuilding

        this.setState({ selectedBuildingCardStatus: selectedBuildingCardStatus })
        this.deleteGlobalSelectedBuilding()
        this.resetBuildingRotationInterval("selectedBuilding")
      }
    }.bind(this))
  }

  setAppState(newState) {
    this.setState(newState)
  }

  getSelectedMarkerBuildingFromGlobal() {
    return getFromNamespace('BuildingCards.Global.selectedMarkerBuilding')
  }

  deleteGlobalSelectedBuilding() {
    delete removeFromNamespace('BuildingCards.Global.selectedMarkerBuilding')
  }

  rotateCardStatuses(forCardType) {
    let { featuredCardStatus, buildingsList, selectedBuildingCardStatus } = this.state
    let newStatuses = {}

    if (forCardType == "featured") {
      newStatuses.featuredCardStatus = {
        incoming: buildingsList.shift(),
        current: featuredCardStatus.incoming,
        outgoing: featuredCardStatus.current,
        buildingRotationIntervalIds: featuredCardStatus.buildingRotationIntervalIds
      }
    } else {
      newStatuses.selectedBuildingCardStatus = {
        incoming: null,
        current: null,
        outgoing: selectedBuildingCardStatus.current,
        buildingRotationIntervalIds: selectedBuildingCardStatus.buildingRotationIntervalIds
      }
    }

    newStatuses.buildingsList = buildingsList

    this.setState(newStatuses)
  }

  renderCards() {
    if (this.state.loading) return

    const commonProps = {
      stopBuildingRotationInterval: this.stopBuildingRotationInterval,
      setBuildingRotationInterval: this.setBuildingRotationInterval,
      setAppState: this.setAppState,
      buildingsPath: this.props.buildingsPath,
      isMobile: this.state.isMobile
    }

    return (
      <div>
        <Card
          key={0}
          {...commonProps}
          type={"selectedBuilding"}
          buildingCardStatus={this.state.selectedBuildingCardStatus}
        />
        <Card
          key={1}
          {...commonProps}
          type={"featured"}
          buildingCardStatus={this.state.featuredCardStatus}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="cards-container">
        {this.renderCards()}
      </div>
    )
  }
}

BuildingCards.propTypes = {
  getFeaturedBuildingsPath:  PropTypes.string.isRequired,
  mapContainerSelector:      PropTypes.string.isRequired,
  buildingsPath:             PropTypes.string.isRequired,
  mobileWidth:               PropTypes.number.isRequired
}

export default BuildingCards
