import React from 'react'

import Single from './single'

const onlyUnique = (value, index, self) => self.indexOf(value) === index

class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.requests = {...this.props.requests}
    this.state.details  = {...this.props.details }
  }

  componentDidMount() {
    this.getProcessing()
  }

  removeRequests(user_id, request_ids) {
    if (!this.state.requests[user_id])
      return

    const requests = { ...this.state.requests }

    request_ids = request_ids.map(id => parseInt(id))
    requests[user_id] = requests[user_id].filter(({id}) => !request_ids.includes(id))
    this.setState({requests})
  }

  startProcessingUser(user_id, requests) {
    clearTimeout(this.get_processing_timeout)

    const details = {...this.state.details}
    const user_details = { ... details[user_id] } || {}

    for (let request of requests )
      user_details[request] = { ...(user_details[request] || {}), ...{approving: true}}

    details[user_id] = user_details
    this.setState({details})

    this.getProcessing()
  }

  areWeTrackingAnything() {
    for (let user_id in this.state.details) {
      if (!this.state.details[user_id])
        continue

      for (let request in this.state.details[user_id])
        if (this.state.details[user_id][request].approving)
          return true
    }

    return false
  }

  getProcessing() {
    if (!this.areWeTrackingAnything())
      return

    const url = "/store_document_request/check_progress"
    const data = hashToPostQueryString({user_id: this.props.users.map(user => user.id)})

    $.ajax({
      url, data, type: "post",
      success: res => this.reloadUsers(res.requests, res.report)
    })
  }

  requestAt(user_id, request_id) {
    const user_requests = this.state.requests[user_id] || []
    for (let request of user_requests)
      if (request.id == request_id)
        return request

    return null
  }

  reloadUsers(request_ids, details) {
    clearTimeout(this.get_processing_timeout)

    const requests = { }
    for (let user_id in request_ids)
      requests[user_id] = request_ids[user_id].map(request_id => this.requestAt(user_id, request_id)).filter(x => x)

    // Update and check again, if needed
    this.setState({ requests, details })
    this.get_processing_timeout = setTimeout(() => this.getProcessing(), 5000)
  }

  listedUsers() {
    const requests = this.state.requests
    return this.props.users.filter(({id}) => requests[id] && requests[id].length > 0)
  }

  renderSingle(user) {
    const requests = this.state.requests[user.id]
    const details = this.state.details[user.id]  || {}
    const building_count = requests.map(request => request.building_id).filter(onlyUnique).length

    return <Single key={user.id} user={user} requests={requests} details={details} building_count={building_count} buildings={this.props.buildings} documents={this.props.documents} removeRequests={r => this.removeRequests(user.id, r)} startProcessing={requests => this.startProcessingUser(user.id, requests)} />
  }

  renderHeader() {
    return <div className="store-document-request header-row">
      <div>
        <div className="user-name">name</div>
        <div className="email">email</div>
        <div className="number-of-documents"># of documents</div>
        <div className="number-of-buildings"># of buildings</div>
        <div className="button"></div>
      </div>
    </div>
  }

  render() {
    return <div className="store-document-request-list-component">
      {this.renderHeader()}
      {this.listedUsers().map(user => this.renderSingle(user) )}
    </div>
  }
}

export default List
