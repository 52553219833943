import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Portal } from 'components/utility/portal'
import FormContractorReport from './index'

export default function FormContractorReportModal({ open = false, onClose = () => {} }) {
  const [show, setShow] = useState(open)
  const overlayRef = useRef(null)

  useEffect(() => {
    setShow(open)
  }, [open])

  const handleClose = () => {
    setShow(false)
    onClose()
  }

  const handleClickOutsideModal = (e) => {
    if (e.target !== overlayRef.current) {
      return
    }
    handleClose()
  }

  return (
    <Portal>
      <div className={clsx('form-contractor-report-overlay dome-full-width dome-full-height', show && 'show')}>
        <div
          className="dome-full-width dome-full-height dome-d-flex dome-align-center dome-justify-center"
          onClick={handleClickOutsideModal}
          ref={overlayRef}
        >
          <FormContractorReport />
        </div>
      </div>
    </Portal>
  )
}
