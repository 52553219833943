import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Portal } from 'components/utility/portal'
import InstantQuoteLookup from './index'

export const InstantQuoteLookupOverlayContext = createContext({})

export default function InstantQuoteLookupOverlay({
  open = false,
  onClose = () => {},
  currentUser,
  building,
  packageId,
  insuranceType,
}) {
  const [show, setShow] = useState(open)
  const overlayRef = useRef(null)

  const handleClose = useCallback(() => {
    setShow(false)
    onClose()
  }, [setShow])

  const hideOverlay = (e) => {
    if (e.target !== overlayRef.current) {
      return
    }
    handleClose()
  }

  useEffect(() => {
    setShow(open)
  }, [open])

  useEffect(() => {
    document.body.classList.toggle('overlay-activated', show)
  }, [show])

  return (
    <Portal>
      <div className={clsx('quote-overlay', show && 'show')}>
        <div
          className="quote-overlay-wrapper dome-d-flex dome-align-center dome-justify-center"
          onClick={hideOverlay}
          ref={overlayRef}
        >
          <InstantQuoteLookupOverlayContext.Provider
            value={{ visible: show, onClose: handleClose, overlayAttached: true }}
          >
            <InstantQuoteLookup
              currentUser={currentUser}
              building={building}
              packageId={packageId}
              insuranceType={insuranceType}
            />
          </InstantQuoteLookupOverlayContext.Provider>
        </div>
      </div>
    </Portal>
  )
}
