import React from 'react'
import PropTypes from 'prop-types'

class SearchSuggestion extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    if (this.props.customApplicationSuggestion)
      return this.props.customApplicationSuggestionCallback(this.props.searchText)

    if (this.props.noResults)
      return

    if (this.props.searchText)
      return window.location.href = "/buildings?query=" + this.props.searchText.replace(/ /g, "+");

    window.location.href = `${this.props.buildingsPath}/${this.props.building.text_url}`
  }

  renderAddress() {
    const building = this.props.building

    if (building.address == building.title && !building.additional_addresses) return

    let addressText = ""

    if (building.additional_addresses) addressText = "Multiple Addresses"
    addressText = addressText || building.address

    return (
      <div className="list-suggestion-address">{ addressText }</div>
    )
  }

  renderCustomApplicationSuggestion() {
    return (
      <div className="list-suggestion-title">
        <div className="list-suggestion-image-container">
          <img src={this.props.customApplicationImagePath} alt="Application Image"/>
        </div>
        <div className="list-suggestion-right-of-image-text">
          Don't see the address you're looking for? <br/>
          <span className="text-grey">Click here</span> to create a custom application
        </div>
      </div>
    )
  }

  renderNoResults() {
    return (<div className="list-suggestion-title">No results found</div>)
  }

  renderSuggestion() {
    if (this.props.customApplicationSuggestion) return this.renderCustomApplicationSuggestion()

    if (this.props.noResults) return this.renderNoResults()

    if (this.props.additionalResultsCount) {
      return (
        <div>{this.props.additionalResultsCount} More Buildings</div>
      )
    }

    return (
      <div>
        <div className="list-suggestion-title">{this.props.building.title}</div>
        {this.renderAddress()}
      </div>
    )
  }

  containerClasses() {
    let classString = "list-suggestion"
    if (this.props.noResults) classString += " no-results"
    if (this.props.customApplicationSuggestion) classString += " custom-application-suggestion"
    if (this.props.additionalResultsCount) classString += " additional-results"

    return classString
  }

  render() {
    return (
      <li className={this.containerClasses()} onClick={this.handleClick}>
        {this.renderSuggestion()}
      </li>
    )
  }
}

SearchSuggestion.propTypes = {
  building: PropTypes.object,
  buildingsPath: PropTypes.string,
  hideAddress: PropTypes.bool,
  additionalResultsCount: PropTypes.number,
  searchText: PropTypes.string,
  customApplicationSuggestion: PropTypes.bool,
  customApplicationImagePath: PropTypes.string,
  customApplicationSuggestionCallback: PropTypes.func,
  noResults: PropTypes.bool
}

export default SearchSuggestion