import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import Tooltip from "components/utility/Tooltip"
import Money from "components/form_field/types/money";

function VariableTooltip({ variable }) {
  if (!variable) return null

  return <Tooltip placement="bottom" title="Variable Price Allowed">
    <i className="fa-light fa-exclamation-circle"></i>
  </Tooltip>
}

export default function PriceChanger(props) {
  const { value = 0, disabled = false, variable = false, onChange = () => null } = props;

  const [localValue, setLocalValue] = useState(value)
  useEffect(() => {
    setLocalValue(value)
  }, [value, setLocalValue])

  useEffect(() => {
    if (localValue == value)
      return

    const timeout = setTimeout(() => onChange(localValue), 600)
    return () => clearTimeout(timeout)
  }, [localValue, value])

  const handleChange = useCallback((hash) => {
    setLocalValue(hash.amount)
  }, [setLocalValue])

  return (
    <div className="price" >
      <VariableTooltip variable={variable} />
      {!disabled && variable ? <Money field={{ placeholder: "Amount" }} value={localValue} className="variable-price" id="amount" onChange={handleChange} disabled={disabled} /> : asMoney(value / 100)}
    </div >
  )
}

PriceChanger.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variable: PropTypes.bool
};
