import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { selectInsuranceQuoteInterstitialState } from '../slice/slice'

const observer = new ResizeObserver((entries) => {
  for (let entry of entries) {
    entry.target.classList.toggle('truncated', entry.target.scrollHeight > entry.contentRect.height)
  }
})

export default function ExpandableText({ maxLines, onClickExpandButton }) {
  const contentRef = useRef(null)

  const { quote } = useSelector(selectInsuranceQuoteInterstitialState)
  const { legalDisclaimer } = quote

  useEffect(() => {
    if (!contentRef.current) return

    const contentRefCurrent = contentRef.current

    observer.observe(contentRefCurrent)

    return () => observer.unobserve(contentRefCurrent)
  }, [])

  return (
    <>
      <div ref={contentRef} className="font-italic text-container" style={{ WebkitLineClamp: maxLines }}>
        {legalDisclaimer}
      </div>
      <button
        role="button"
        className="dome-btn dome-btn-link dome-btn-link-aqua pull-right expand-btn"
        onClick={onClickExpandButton}
      >
        + expand
      </button>
    </>
  )
}

ExpandableText.defaultProps = {
  maxLines: 3,
  onClickExpandButton: () => {},
}
