import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit'
import { setActiveComponent, uniqueComponentId } from '../page'
import { transform } from 'lodash'

function group_by_position_page(hash, value) {
  const page = value.position.page
  return hash[page] = (hash[page] || []).concat(value)
}

function group_by_position_field(hash, value) {
  for (let field of value.fields || [])
    hash[field] = (hash[field] || []).concat(value)

  return hash
}

const initialState = {
  focused_field_position: [],
  positions: [],
  by_page: {},
  by_field: {},
  clicked: null
}

const Store = createSlice({
  name: "field_positions",
  initialState,
  reducers: {
    focusFieldPosition(state, { payload: field_position_id }) {
      state.focused_field_position = field_position_id
    },

    unfocusFieldPosition(state, { payload: field_position_id }) {
      if (state.focused_field_position && state.focused_field_position == field_position_id) {
        state.focused_field_position = null
      }
    },

    setFieldPositions(state, { payload: positions }) {
      state.positions = positions.map((pos, idx) => ({ ...pos, ...{ idx } }))
      state.by_page = transform(state.positions, group_by_position_page, {})
      state.by_field = transform(state.positions, group_by_position_field, {})
    },

    setClicked(state, { payload: position_index }) {
      state.clicked = position_index
    }
  }
})

export const { setFieldPositions, setClicked, focusFieldPosition, unfocusFieldPosition } = Store.actions

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: focusFieldPosition,
  effect: ({ payload: id }, { dispatch }) => {
    const key = uniqueComponentId('field_position', id)

    dispatch(setActiveComponent(key))
  }
})

listenerMiddleware.startListening({
  actionCreator: unfocusFieldPosition,
  effect: ({ payload: id }, { dispatch, getState }) => {
    const { form_pages: state } = getState()
    const key = uniqueComponentId('field_position', id)
    if (key == state.activeComponent)
      dispatch(setActiveComponent(null))
  }
})

export const { middleware } = listenerMiddleware
export const reducer = Store.reducer
