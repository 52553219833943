import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Popover } from 'react-tiny-popover'
import { compact } from 'lodash'

import SignatureImage from './image'
import { useFindFontSize } from 'components/signatures/utilities'
import { useFindFontSizeProps } from '../utilities/find_font_size'

import { SignatureWrapper, useSigningStatuses } from '.'

function Label({id}) {
  const signable = useSelector(({form_signatures: state}) => state.signatures_to_sign.lookups[`s-${id}`])
  if (!signable)
    return

  const text = signable.required ? "Your signature is required" : "Signing this field is optional"

  return <div className="signature-popover">{text}</div>
}

function CanBeSigned({ id, frozen, thumbnail}) {
  const [hovering, setHovering] = useState(false)

  const signature = useSelector(({form_signatures: state}) => state.signatures[id])
  const signable = useSelector(({form_signatures: state}) => state.signatures_to_sign.lookups[`s-${id}`])
  const signableGroup = useSelector(({form_signatures: state}) => state.signatures_to_sign.lookups[`sg-${signature.deal_party.group}`])

  const container = useRef()
  const text = "Click to Sign"

  const fontSizeProps = useFindFontSizeProps({
    ratioType: thumbnail ? "thumbnailRatio" : null,
    frozen: frozen || thumbnail})

  const { fontSize, isWorking } = useFindFontSize(container, {
    ...fontSizeProps,
    defaultSize: 16
  }, [ text ] )

  const style = {
    fontSize
  }

  if (isWorking)
    style.opacity = 0

  const classes = compact([
    "signing-message",
    hovering ? "hovering" : null,
    signable ? "party-signature" : null,
    signableGroup ? "group-signature" : null
  ])

  if (signable) {
    classes.push(signable.required ? "signature-required" : "signature-optional")
  }

  if (signableGroup) {
    if (signableGroup.required == "required")
      classes.push("signature-required")
    else if (!signableGroup.required || signableGroup.required == "optional")
      classes.push("signature-optional")
    else
      classes.push("signature-needed")
  }

  return <>
    <Label id={id} />
    <div ref={container} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className={classes.join(" ")} style={style}>
      Click to Sign
    </div>
  </>
}

export default function({id, frozen, thumbnail}) {
  const {positions, deal_party: { group }} = useSelector(({form_signatures: state}) => state.signatures[id] || {})
  const { isSigned, canBeSigned } = useSigningStatuses(id, frozen)

  if (!positions?.signature)
    return null

  return <SignatureWrapper component="signature" id={id} frozen={frozen}>
    { isSigned ? <SignatureImage id={id} frozen={frozen} thumbnail={thumbnail} /> : null }
    { canBeSigned ? <Label id={id} /> : null }
    { canBeSigned ? <CanBeSigned id={id} frozen={frozen} thumbnail={thumbnail} /> : null }
  </SignatureWrapper>
}
