import { createSlice, createAsyncThunk, nanoid, createAction, createSelector } from "@reduxjs/toolkit";
import { trim } from "lodash-es";
import { updateBuildingResidentPaymentsApi, removeServiceApi, removeCategoryApi } from "./apis";
import { startAppListening } from "components/global/listener";
import { handleDragEnd, handleMoveCategory } from "./utils";
import {
  categoryValidationRules,
  checkSubMerchantId,
  serviceValidationRules,
  validateAllItems,
  validateItem,
} from "./validates";

const START_POSITION = 1;

const initialState = {
  building: null,
  categories: [],
  services: {},
  category_services: {},
  beRemovedService: { id: null, categoryId: null },
  beRemovedCategory: { id: null },
  newServicesIds: [],
  newCategoriesIds: [],
  form: {},
  formTouched: false,
};

export const updateBuildingResidentPayments = createAsyncThunk(
  "buildingEditResidentPayments/updateBuildingResidentPayments",
  async ({ data, id }) => {
    const response = await updateBuildingResidentPaymentsApi(data, id);
    return { data: response.data };
  }
);

export const deleteService = createAsyncThunk("buildingEditResidentPayments/deleteService", async ({ serviceId }) => {
  const response = await removeServiceApi({ serviceId });
  return { data: response.data };
});

/**
 * Action to delete existing categories
 * */
export const deleteCategory = createAsyncThunk(
  "buildingEditResidentPayments/deleteCategory",
  async ({ categoryId }) => {
    const response = await removeCategoryApi({ categoryId });
    return { data: response.data };
  }
);

export const save = createAction("buildingEditResidentPayments/save");

const buildingEditResidentPaymentsSlice = createSlice({
  name: "buildingEditResidentPayments",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.building = action.payload.building;
      state.categories = action.payload.categories;

      action.payload.services.forEach((service) => {
        state.services[service.id] = service;

        state.category_services[service.category_id] ||= [];
        state.category_services[service.category_id].push(service.id);
      });
    },
    updateService: (state, action) => {
      const { serviceId, key, value } = action.payload;
      state.services[serviceId][key] = value;
      validateItem(state.form, serviceValidationRules, { type: "service", id: serviceId, key, value });
      if (key === "sub_merchant_id") {
        checkSubMerchantId(state, serviceValidationRules, { type: "service", id: serviceId, key, value });
      }
      state.formTouched = true;
    },
    addService: (state, action) => {
      const { categoryId } = action.payload;

      let sub_merchant_id = undefined;
      const building_sub_merchant_dropdown = state.building?.sub_merchant_dropdown_with_default || [];

      if (building_sub_merchant_dropdown.length > 0) {
        const [_dropdown_sub_merchant_description, dropdown_sub_merchant_id] = building_sub_merchant_dropdown[0];
        sub_merchant_id = dropdown_sub_merchant_id;
      }

      const service = {
        id: nanoid(),
        category_id: categoryId,
        title: "",
        gl_code: "",
        charge_code: "",
        amount: "",
        sub_merchant_id: sub_merchant_id,
      };

      state.category_services[service.category_id] ||= [];

      const categoryServicesLength = state.category_services[service.category_id].length;
      if (categoryServicesLength > 0) {
        const lastServiceId = state.category_services[service.category_id][categoryServicesLength - 1];
        service.position = state.services[lastServiceId].position + 1;
      } else {
        service.position = START_POSITION;
      }

      state.category_services[service.category_id].push(service.id);
      state.services[service.id] = service;
      state.newServicesIds.push(service.id);
      state.formTouched = true;
    },
    openRemoveServiceModal: (state, action) => {
      state.beRemovedService = { id: action.payload.id, categoryId: action.payload.categoryId };
    },
    cancelRemoveService: (state, _action) => {
      state.beRemovedService = { id: null, categoryId: null };
    },
    removeService: (state, _action) => {
      const { categoryId, id } = state.beRemovedService;

      if (!state.category_services[categoryId]) {
        return;
      }

      const index = state.category_services[categoryId].findIndex((serviceId) => serviceId === id);

      if (index !== -1) {
        state.category_services[categoryId].splice(index, 1);
        delete state.services[id];
        delete state.form[`service-${id}`];

        state.beRemovedService = { id: null, categoryId: null };
      }
    },
    addCategory: (state, _action) => {
      let position;

      if (state.categories.length > 0) {
        const lastCategory = state.categories[state.categories.length - 1];
        position = lastCategory.position + 1;
      } else {
        position = START_POSITION;
      }

      const category = { id: nanoid(), title: "", position: position };

      state.categories.push(category);
      state.category_services[category.id] = [];
      state.newCategoriesIds.push(category.id);
      state.formTouched = true;
    },
    updateCategory: (state, action) => {
      const { categoryId, key, value } = action.payload;
      const category = state.categories.find((category) => category.id === categoryId);

      if (category) {
        category[key] = value;
      }
      validateItem(state.form, categoryValidationRules, { type: "category", id: categoryId, key, value });
      state.formTouched = true;
    },
    openRemoveCategoryModal: (state, action) => {
      state.beRemovedCategory = { id: action.payload.id };
    },
    cancelRemoveCategory: (state, _action) => {
      state.beRemovedCategory = { id: null };
    },
    // Action to remove new and empty categories
    removeCategory: (state, _action) => {
      const { id } = state.beRemovedCategory;

      const index = state.categories.findIndex((category) => category.id === id);

      if (index !== -1) {
        state.categories.splice(index, 1);
        delete state.form[`category-${id}`];

        state.beRemovedCategory = { id: null };

        // There's no need to handle like deleteCategory.fulfilled
        // since a category can only "removed" since they are new and have no services inside.
      }
    },
    toggleResidentPayments: (state, _action) => {
      state.building.enable_resident_payments = !state.building.enable_resident_payments;
      state.formTouched = true;
    },
    dragEnd: (state, action) => {
      handleDragEnd(state, action.payload.result);
    },
    moveCategory: (state, action) => {
      const { currentIndex, position } = action.payload;

      handleMoveCategory(state, currentIndex, position);
      state.formTouched = true;
    },
    validateForm: (state, _action) => {
      validateAllItems(state);
    },
    resetState: (_state, _action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteService.fulfilled, (state, action) => {
      const {
        service: { id, category_id, active },
      } = action.payload.data;

      if (active === false) {
        if (!state.category_services[category_id]) {
          return;
        }

        const index = state.category_services[category_id].findIndex((serviceId) => serviceId === id);

        if (index !== -1) {
          state.category_services[category_id].splice(index, 1);
          delete state.services[id];
          delete state.form[`service-${id}`];

          state.beRemovedService = { id: null, categoryId: null };
        }
      }
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      const {
        service: { id, active },
      } = action.payload.data;

      if (active === false) {
        const index = state.categories.findIndex((category) => category.id === id);

        if (index !== -1) {
          state.categories.splice(index, 1);
          state.beRemovedCategory = { id: null };

          if (!state.category_services[id]) {
            return;
          }

          state.category_services[id].forEach((serviceId) => {
            delete state.services[serviceId];
            delete state.form[`service-${serviceId}`];
          });

          delete state.category_services[id];
          delete state.form[`category-${id}`];
        }
      }
    });
  },
});

/**
 * Listen for the `save` action, re-structure payload then dispatch the async `update` action
 * Reference docs: https://redux-toolkit.js.org/api/createListenerMiddleware
 **/
startAppListening({
  actionCreator: save,
  effect: (_action, listenerApi) => {
    listenerApi.dispatch(validateForm());

    const state = listenerApi.getState();

    if (!selectIsFormValid(state)) return;

    const { building, categories, services } = state.buildingEditResidentPayments;

    const payload = {
      data: {
        categories: categories.map((category) => ({ ...category, title: trim(category.title) })),
        services: Object.values(services).map((service) => ({ ...service, title: trim(service.title) })),
        building: { enable_resident_payments: building.enable_resident_payments },
      },
      id: building.id,
    };

    listenerApi.dispatch(updateBuildingResidentPayments(payload));
  },
});

startAppListening({
  actionCreator: updateBuildingResidentPayments.fulfilled,
  effect: (_action, _listenerApi) => {
    window.location.reload();
  },
});

export const selectBuildingEditResidentPayments = (state) => state.buildingEditResidentPayments;

const selectForm = createSelector(selectBuildingEditResidentPayments, (state) => state.form);

export const selectServiceFormState = createSelector(
  [selectForm, (_state, serviceId) => serviceId],
  (form, serviceId) => form[`service-${serviceId}`]
);

export const selectCategoryFormState = createSelector(
  [selectForm, (_state, categoryId) => categoryId],
  (form, categoryId) => form[`category-${categoryId}`]
);

export const selectIsFormValid = createSelector(selectForm, (form) => {
  const formItems = Object.values(form);
  let valid = true;

  for (const formItem of formItems) {
    for (const attributeErrors of Object.values(formItem?.errors || {})) {
      if (attributeErrors.length > 0) {
        valid = false;
        break;
      }
    }
  }

  return valid;
});

export const {
  setInitialState,
  addService,
  updateService,
  removeService,
  openRemoveServiceModal,
  cancelRemoveService,
  addCategory,
  updateCategory,
  openRemoveCategoryModal,
  cancelRemoveCategory,
  removeCategory,
  toggleResidentPayments,
  dragEnd,
  moveCategory,
  validateForm,
  resetState,
} = buildingEditResidentPaymentsSlice.actions;

export const buildingEditResidentPaymentsReducer = buildingEditResidentPaymentsSlice.reducer;
