import React from 'react'

import SupportingDocumentsModal from './supporting_documents'

class DealParty extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.new_reports = []
  }

  submittedReports() {
    return this.props.submitted_reports || []
  }

  allReports() {
    return this.submittedReports().concat(this.state.new_reports)
  }

  reportAlreadyFilled(report_id) {
    return this.submittedReports().includes(report_id)
  }

  render() {
    return <div className="deal-party-row" key={this.props.party.id}>
      <div>
        {this.renderName()}
        {this.renderReports()}
        {this.renderDocumentsButton()}
      </div>
      {this.renderDocuments()}
    </div>
  }

  reportTypes() {
    return Object.values(this.props.report_types || {}) || []
  }

  renderDocumentsModal() {
    if (!this.state.documents_modal)
      return

    return <SupportingDocumentsModal  party={this.props.party} 
                                      package_id={this.props.package_id}
                                      reports={this.allReports()}
                                      documents={this.props.documents}
                                      reportTypes={this.props.report_types}
                                      documentTypes={this.props.document_types}
                                      onChanged={docs => this.props.onChangeDocuments(docs)}
                                      onClose={() => this.setState({documents_modal: null})} />
  }

  renderName() {
    return <div className="deal-party">
      <h4>{ this.props.party.full_name }</h4>
      <span><p>{ this.props.party.type }</p></span>
    </div>
  }

  renderReports() {
    return <div className="client-report-request">
      <div>{this.reportTypes().map(report => this.renderReportType(report))}</div>
    </div>
  }

  renderDocuments() {
    const required_documents = []
    for (let report_type of this.allReports())
      for (let doc_type of this.props.report_types[report_type].credit_document_types)
        if (!required_documents.includes(doc_type.id))
          required_documents.push(doc_type.id)

    if (required_documents.length == 0)
      return null

    const document_types = {}
    for (let {doc_id, type_id} of this.props.documents) 
      document_types[type_id] = (document_types[type_id] || 0) + 1

    const document_types_html = <div className="document-types" key="types">
      <div>Required Documents</div>
      <ul>
        {required_documents.map(type_id => {
          const doc_type = this.props.document_types[type_id]
          return <li  key={type_id}>
            {doc_type} ({document_types[type_id] || 0})
          </li>
        })}
      </ul>
    </div>

    return [document_types_html]
  }

  clickReport(report_id) {
    const new_reports = this.state.new_reports.concat([])
    const index = new_reports.indexOf(report_id)

    if (index < 0)
      new_reports.push(report_id)
    else
      new_reports.splice(index, 1)

    this.setState({new_reports})

    if (this.props.onChangeNewReports)
      this.props.onChangeNewReports(new_reports)
  }

  renderReportType(report) {
    const filled = this.reportAlreadyFilled(report.id)

    return <div key={report.id}>
      {filled ? this.renderReportCompleted() : this.renderReportCheckbox(report.id)}
      <label htmlFor={this.reportItemId(report.id)}>{report.title}</label>  
    </div>
  }

  reportItemId(report_id) {
    return `credit_report-${this.props.party.id}-${report_id}`
  }

  renderReportCompleted() {
    return <div className="already-submitted"><i className="fa fa-check"></i></div>
  }

  renderReportCheckbox(report_id) {
    return <input   type="checkbox" 
                    id={this.reportItemId(report_id)} 
                    readOnly={true} 
                    checked={this.state.new_reports.includes(report_id)}
                    onChange={() => {}} 
                    onClick={() => this.clickReport(report_id)}  />
  }

  renderDocumentsButton() {
    const disabled = this.state.new_reports.length == 0 && this.submittedReports().length == 0
    const button_classes = ["btn", "btn-default"]
    if (disabled)
      button_classes.push("disabled")

    const onClick = () => this.setState({documents_modal: true})

    return <div className="supporting-documents">
      <button type="button" disabled={disabled} className={button_classes.join(" ")} onClick={onClick}>
        <span><i className="fa fa-upload"></i><span>Include</span></span>
        <span>Supporting Documents</span>
      </button>
      {this.renderDocumentsModal()}
    </div>
  }
}

export default DealParty