import React from 'react'

const LoadingIcon = (props) => {
  return (
    <div id={props.id} className='loader-icon-container'>
      <svg xmlns="http://www.w3.org/2000/svg" className='loader-icon fa-spin' viewBox="0 0 576 512"><path fill={props.color} d="M304 112C304 120.8 296.8 128 288 128C279.2 128 272 120.8 272 112V16C272 7.164 279.2 0 288 0C296.8 0 304 7.164 304 16V112zM304 496C304 504.8 296.8 512 288 512C279.2 512 272 504.8 272 496V400C272 391.2 279.2 384 288 384C296.8 384 304 391.2 304 400V496zM32 256C32 247.2 39.16 240 48 240H144C152.8 240 160 247.2 160 256C160 264.8 152.8 272 144 272H48C39.16 272 32 264.8 32 256zM528 240C536.8 240 544 247.2 544 256C544 264.8 536.8 272 528 272H432C423.2 272 416 264.8 416 256C416 247.2 423.2 240 432 240H528zM469 74.98C475.3 81.23 475.3 91.36 469 97.61L401.1 165.5C394.9 171.7 384.8 171.7 378.5 165.5C372.3 159.2 372.3 149.1 378.5 142.9L446.4 74.98C452.6 68.73 462.8 68.73 469 74.98V74.98zM129.6 437C123.4 443.3 113.2 443.3 106.1 437C100.7 430.8 100.7 420.6 106.1 414.4L174.9 346.5C181.1 340.3 191.2 340.3 197.5 346.5C203.7 352.8 203.7 362.9 197.5 369.1L129.6 437zM106.1 74.98C113.2 68.73 123.4 68.73 129.6 74.98L197.5 142.9C203.7 149.1 203.7 159.2 197.5 165.5C191.2 171.7 181.1 171.7 174.9 165.5L106.1 97.61C100.7 91.36 100.7 81.23 106.1 74.98V74.98zM469 414.4C475.3 420.6 475.3 430.8 469 437C462.8 443.3 452.6 443.3 446.4 437L378.5 369.1C372.3 362.9 372.3 352.8 378.5 346.5C384.8 340.3 394.9 340.3 401.1 346.5L469 414.4z"/></svg>
    </div>
  )
}

export default LoadingIcon