import React, { useEffect, useState } from 'react'
import SelectTypeModal from './select_type_modal'
import ConciergeModal from './modal'

const AllStepsModal = (props) => {
  const {
    type: initialType = null,
    step: initialStep = 0,
    onClose = () => {},
    roles,
    package_types,
    service_types,
    agent_types,
  } = props
  const [step, setStep] = useState(initialStep)
  const [type, setType] = useState(initialType)

  useEffect(() => {
    setStep(initialStep)
  }, [initialStep])

  useEffect(() => {
    setType(initialType)
  }, [initialType])

  useEffect(() => {
    if (!step) onClose()
  }, [step])

  const closeFirst = (type) => {
    setType(type)
    setStep(type ? 2 : 0)
  }

  return (
    <>
      {step === 1 && <SelectTypeModal onClose={closeFirst} type={initialType || type} />}
      {step === 2 && (
        <ConciergeModal
          type={initialType || type}
          onClose={closeFirst}
          roles={roles}
          agent_types={agent_types}
          package_types={package_types}
          service_types={service_types}
        />
      )}
    </>
  )
}

export default AllStepsModal
