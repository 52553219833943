import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEventManager } from '@deathbyjer/react-event-manager'
import { removeContact } from './api'

import { actions } from './Store'

const TableRow = props => {
  const [optionsOpen, setOptionsOpen] = useState(false)
  const { user, packageId } = props
  const { removeUser } = actions
  const dispatch = useDispatch()
  const events = useEventManager()
  const optionsContainerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
        setOptionsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [optionsContainerRef])

  const handleClickRemoveContact = async (contactEmail) => {
    await removeContact(packageId, contactEmail)

    dispatch(removeUser({ email: contactEmail }))
  }

  const handleClickEditRequirements = user => {
    const formData = { email: user.email, requirement_visibility: user.requirement_visibility }
    events.applyEventListeners('additional_contacts:edit:requirements', { formData })
  }

  const renderRole = role => {
    switch (role) {
      case 'viewer':
        return 'Viewer'
      case 'participant':
        return 'Participant'
      case 'participant_and_viewer':
        return 'Participant, Viewer'
    }
  }

  const renderAccessIcons = () => {
    return <>
      <i style={{ cursor: 'pointer' }} className='fa fa-user-plus fa-lg bopa-orange' onClick={() => handleClickEditRequirements(user)} />
      {renderSentAtIcon()}
      {renderViewedAtIcon()}
      <i className='fa-regular fa-lg fa-ellipsis-vertical' style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => { setOptionsOpen(!optionsOpen)}} />
      {renderOptions(user)}
    </>
  }

  const renderOptions = user => {
    const containerStyle = {
      position: 'absolute',
      border: '1px solid #ddd',
      borderRadius: 5,
      backgroundColor: 'white',
      width: 185,
      padding: '5px 10px',
      display: optionsOpen ? 'block' : 'none'
    }

    return <div style={containerStyle} ref={optionsContainerRef}>
      {/* <div style={{ cursor: 'pointer', borderBottom: '1px solid #ddd' }}>View Full Contact Details</div> */}
      <div style={{ cursor: 'pointer', color: '#D2232A'}} onClick={() => handleClickRemoveContact(user.email)}>Remove Contact</div>
    </div>
  }

  const renderSentAtIcon = () => {
    return <i style={{ marginLeft: 10 }} className='fa fa-clock-o fa-lg bm-icon' data-toggle="tooltip" data-html='true' data-placement='bottom' title={`User sent application at ${user.sent_at}`} />
  }

  const renderViewedAtIcon = () => {
    if (!user.viewed_at)
      return null

    return <i style={{ marginLeft: 10 }} className='fa fa-eye fa-lg bm-icon' data-toggle="tooltip" data-html='true' data-placement='bottom' title={`User accessed application at ${user.viewed_at}`} />
  }

  return <tr key={user.email}>
    <td>
      <div>{`${user.first_name} ${user.last_name}`}</div>
      <div>{renderRole(user.role)}</div>
    </td>
    <td>{user.title}</td>
    <td>{user.email}</td>
    <td>
      {renderAccessIcons()}
    </td>
  </tr>
}

const Table = (props) => {
  const users = useSelector((state) => state.table.users)

  const { setUsers } = actions
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUsers(props.users))
    $(function () { $('[data-toggle="tooltip"]').tooltip() })
  }, [])

  const renderUsersRows = () => users.map(user =>
    <TableRow user={user} packageId={props.packageId} />
  )

  return <table id='additional-contacts-table'>
    <thead>
      <tr>
        <th>Party</th>
        <th>Title</th>
        <th>Email</th>
        <th>Access</th>
      </tr>
    </thead>
    <tbody>
      {renderUsersRows()}
    </tbody>
  </table>
}

export default Table
