import React, { useState, useContext, useCallback } from 'react'
import Modal from 'components/utility/modal'

import { FeeTableContext, ModalContext, errorForFeeAction } from './PackageFeesTable'
import RenderGroupedFees from './RenderGroupedFees'

function Radio({ label, value, onChange, checked, disabled }) {
  return (
    <div className={`form-check ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && onChange(value)}>
      <input
        type="radio"
        className="form-check-input"
        checked={checked}
        onChange={() => !disabled && onChange(value)}
        disabled={disabled}
      />
      <label className="form-check-label">{label}</label>
    </div>
  )
}

function ResetScreen({ url, text, onClose, allFeesSelected, associatedFeesExist }) {
  const { submitSelectedFees, removeAllFees } = useContext(FeeTableContext)
  const [option, setOption] = useState(allFeesSelected ? "reset_associated" : "other")

  const handleChange = useCallback(value => {
    setOption(value)
  }, [setOption])

  const handleSubmit = useCallback(() => {
    submitSelectedFees({ url, selected_option: option })
      .then(() => removeAllFees())
      .then(onClose).catch(errorForFeeAction)
  }, [submitSelectedFees, onClose, url, option, removeAllFees])

  const copies = (() => {
    const hash = {};

    switch (text.toLowerCase()) {
      case 'deauthorize':
        hash.title = 'Deauthorize';
        hash.action = 'deauthorized'
        hash.feesAction = option === 'other' ? 'reauthorized' : 'deauthorized';
        hash.firstRadioLabel = 'Deauthorize Selected and All Associated Fees'
        hash.secondRadioLabel = 'Deauthorize Selected and Reauthorize All Associated Fees';
        break;

      case 'reset':
        hash.title = 'Reset';
        hash.action = 'deauthorized and unnasigned'
        hash.feesAction = option === 'other' ? 'reauthorized' : 'deauthorized and unnasigned';
        hash.firstRadioLabel = 'Reset Selected and All Associated Fees'
        hash.secondRadioLabel = 'Reset Selected and Reauthorize All Associated Fees';

        break;

      case 'waive':
        hash.title = 'Waive';
        hash.action = 'deauthorized and waived'
        hash.feesAction = 'reauthorized';
        hash.firstRadioLabel = 'Reset Selected and All Associated Fees'
        hash.secondRadioLabel = 'Reset Selected and Reauthorize All Associated Fees';

        break;

      case 'remove':
        hash.title = 'Remove';
        hash.action = 'removed'
        hash.feesAction = 'reauthorized';
        hash.firstRadioLabel = 'Reset Selected and All Associated Fees'
        hash.secondRadioLabel = 'Reset Selected and Reauthorize All Associated Fees';

        break;

      case 'reset all':
        hash.title = 'Reset All';
        hash.action = 'deauthorized and unnasigned'
        hash.feesAction = 'deauthorized and unnasigned';
        hash.firstRadioLabel = 'Reset Selected and All Associated Fees'
        hash.secondRadioLabel = 'Reset Selected and Reauthorize All Associated Fees';

        break;
    }

    return hash;
  })();

  const RADIOS = [
    { label: `${copies.firstRadioLabel}`, value: "reset_associated" },
    { label: `${copies.secondRadioLabel}`, value: "other" }
  ];

  return (
    <>
      <p className="fee-reset-header">You have selected a {copies.title} action</p>
      <div className="well">
        <small>
          If you are changing the amount, we will undo all authorizations related to any <strong>associated fees</strong> (and possibly reset those fees). This is because the original user may want to reauthorize the connected fees at the new amounts.
          <br /><br />
          If you are just unassigning / deauthorizing some (not all) of the fees, we will reauthorize the remaining fees back to the original user.
        </small>
      </div>
      <div className="radio-btns">
        {(copies.title == 'Deauthorize' || copies.title == 'Reset') && !allFeesSelected && associatedFeesExist && RADIOS.map(opts => (
          <Radio
            key={opts.value}
            {...opts}
            onChange={handleChange}
            checked={option == opts.value}
          />
        ))}
      </div>

      <RenderGroupedFees feesAction={copies.feesAction} action={copies.action} />

      <div className="fee-buttons" style={{ marginTop: "3rem" }}>
        <button className="btn btn-danger auto-w" onClick={onClose}>Cancel</button>
        <button className="btn btn-success auto-w fee-btn-disable" onClick={handleSubmit}>Confirm</button>
      </div>
    </>
  )
}

const FeeModal = ({ text, url, allFeesSelected, associatedFeesExist }) => {
  const [open, setOpen] = useState(true)
  const { setCurrentModal } = useContext(ModalContext)

  const cleanup = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const feeActionProps = {
    url,
    text,
    allFeesSelected,
    associatedFeesExist,
    onClose: cleanup
  }

  const handleClose = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal])

  return (
    <Modal open={open} className="fee-modal" onClose={handleClose}>
      <div className='modal-box mobile-width body'>
        <ResetScreen {...feeActionProps} />
      </div>
    </Modal>
  )
}

export default FeeModal
