import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

import { groupBy } from 'lodash'
import { sortObjectKeys } from 'lib/utilities'

import { switchableParties } from '../utilities'

import { addPerson, addPersonFromUser, addUserFromPerson, editPerson, removePerson, changePersonParty, initializePeople } from './people'
import { initializeUsers, addUser, editUser, removeUser, makeUserEditable, removeUserIfEmpty, addUserRole, removeUserRole } from './users'

function assignToDealParty(state, { deal_party_id, person_id}) {
  const previous_person = state.deal_party_people[deal_party_id]
  const previous_deal_party = state.person_deal_parties[person_id]

  delete state.person_deal_parties[previous_person]
  delete state.deal_party_people[previous_deal_party]

  state.deal_party_people[deal_party_id] = person_id      
  state.person_deal_parties[person_id] = deal_party_id
}

function assignUserToPerson(state, { email, person_id }) {
  if (!person_id)
    return

  const previous_user = state.data_person_users[person_id]

  if (email) {
    state.data_person_users[person_id] = email

    state.user_data_people[email] ||= []
    state.user_data_people[email].push(person_id)
  } else {
    delete state.data_person_users[person_id]

    state.user_data_people[email] = state.user_data_people[email].filter(id => id != person_id)
    if (state.user_data_people[email].length == 0)
      delete state.user_data_people[email]
  }

  if (previous_user) {
    state.user_data_people[previous_user] = state.user_data_people[previous_user].filter(id => id != person_id)
    if (state.user_data_people[previous_user].length == 0)
      delete state.user_data_people[previous_user]
  }
}

function addToGroupPeople(state, group_id, person) {
  state.group_people[group_id] ||= []
  state.group_people[group_id].push(person)

  state.people_groups[person.email] ||= []
  state.people_groups[person.email].push(group_id)
}

function rebuildGroupPeople(state) {
  let person_id, party_id, role_id, email

  state.group_people = {}
  for (person_id in state.data_people) {
    party_id = state.person_parties[person_id]
    email = state.people_emails[person_id]
    addToGroupPeople(state, state.party_groups[party_id], { party_id, person_id, email })
  }
  
  for (email in state.user_people) {
    for (let role_id of (state.user_roles[email] || []))
      addToGroupPeople(state, state.role_groups[role_id], { role_id, email })

    addToGroupPeople(state, null, { email })
  }
}

export function useExportUpdates() {
  const people = useSelector(({roles}) => roles.people_updates)
  const users = useSelector(({roles}) => roles.user_updates)
  const person_parties = useSelector(({roles}) => roles.person_parties)
  const user_roles = useSelector(({roles}) => roles.editable_user_roles)
  const deal_party_people = useSelector(({roles}) => roles.deal_party_people)
  const data_person_users = useSelector(({roles}) => roles.data_person_users)
  const new_parties = useSelector(({roles}) => roles.new_parties)
  const removals = useSelector(({roles}) => roles.to_remove)

  const updates = {}
  for (let person_id in people)
    for (let path in people[person_id])
      updates[`${person_id}.${path}`] = people[person_id][path]

  return useMemo(() => ({
    people: updates,
    users,
    user_roles,
    deal_party_people,
    data_person_users,
    new_parties,
    removals
  }), [people, users, user_roles, deal_party_people, deal_party_people, data_person_users, new_parties, removals])
}

const initialState = {
  // First load data
  roles: {},
  deal_party_metadata: {},
  groups: {},
  group_order: [],
  deal_parties: {},
  party_deal_parties: {},
  deal_party_order: [],

  deal_party_groups: {},
  party_groups: {},
  role_groups: {},
  group_deal_parties: {},
  group_parties: {},
  group_roles: {},

  // Users who should have access based on system configurations
  locked_users: {},
  system_users: {},

  // State Data
  group_people: {},
  people_groups: {},
  party_people: {},
  
  data_people: {},
  user_people: {},

  people_hash: {},
  user_hash: {},

  // Monitor updates
  people_updates: {},
  user_updates: {},

  people_names: {},
  people_emails: {},
  user_names: {},
  
  person_parties: {}, // one party per data person
  user_roles: {}, // multiple roles per user
  editable_users: {},
  editable_user_roles: {},
  locked_user_roles: {},
  
  // Emails for data people to help sync with users
  data_person_emails: {},

  // Deal party assignments
  deal_party_people: {},
  person_deal_parties: {}, // one deal party per data person, not total representation

  // Users who represent data people
  data_person_users: {},
  user_data_people: {},

  // Email / Name pairings for data people
  user_possible_people: {},
  user_possible_names: {},

  // Operational
  new_parties: {},
  to_remove: [],
  new_person_index: -1,

  // UX
  edit_person_modal: null,

  current_user_permissions: { read: [], write: []}
}

const buildByGroups = hash => Object.fromEntries(Object.entries(hash).map(([id, {group}]) => [id, group]).filter(([a,b]) => a && b))
const buildGroupsBy = hash => groupBy(Object.keys(hash).filter(id => hash[id].group), id => hash[id].group)


export const store = createSlice({
  name: "roles",
  initialState,
  reducers: {
    load(state, {payload} ) {
      let deal_party_id, person_id, party_id, role_id, email
      state.metadata = payload.metadata
      state.roles = payload.roles
      state.deal_party_metadata = payload.deal_party_metadata
      state.groups = payload.deal_party_groups
      state.deal_parties = payload.deal_parties
      state.party_deal_parties = {}

      for (deal_party_id in state.deal_parties) {
        party_id = state.deal_parties[deal_party_id].party
        state.party_deal_parties[party_id] ||= {}
        state.party_deal_parties[party_id][deal_party_id] = state.deal_parties[deal_party_id]
      }

      for (party_id in state.party_deal_parties)
        state.party_deal_parties[party_id] = sortObjectKeys(state.party_deal_parties[party_id])

      state.group_order = sortObjectKeys(state.groups)

      state.deal_party_groups = buildByGroups(state.deal_parties)
      state.party_groups = buildByGroups(state.deal_party_metadata)
      state.role_groups = buildByGroups(state.roles)

      state.group_deal_parties = buildGroupsBy(state.deal_parties)
      state.group_parties = buildGroupsBy(state.deal_party_metadata)
      state.group_roles = buildGroupsBy(state.roles)

      initializePeople(state, payload)
      initializeUsers(state, payload)
      rebuildGroupPeople(state)

      state.to_remove = []

      state.current_user_permissions = payload.current_user_permissions
    },

    addUser(state, { payload: { person_id, role_id, data = {} }}) {
      const email = addUser(state, { role_id, data })

      if (role_id) 
        rebuildGroupPeople(state)

      // Add user for group_people
      assignUserToPerson(state, { person_id, email })
    },

    assignUserRole(state, { payload: { email, role_id }}) {
      if (state.user_roles[email]?.includes(role_id))
        return

      if (!state.editable_users[email])
        if (!addUserFromPerson(state, { email }))
          if (!makeUserEditable(state, { email }))
            return 
      
      addUserRole(state, { email, role_id })
      rebuildGroupPeople(state)
    },

    editUser(state, { payload: { email, data }}) {
      editUser(state, { email, data })
    },

    assignUser(state, { payload: { email, person_id}}) {
      assignUserToPerson(state, { person_id, email })
    },

    deleteUser(state, { payload: {email, role_id, person_id} }) {
      if (role_id)
        removeUserRole(state, { email, role_id })

      if (state.user_data_people[email] == person_id) 
        assignUserToPerson(state, { person_id, email: null })

      removeUser(state, { email })
      rebuildGroupPeople(state)
    },

    addDataPerson(state, { payload: { party_id, deal_party_id, data = {} }}) {
      const person_id = addPerson(state, { party_id, data })
      console.log(data)

      if (deal_party_id) 
        assignToDealParty(state, {deal_party_id, person_id})
      
      rebuildGroupPeople(state)
    },

    editDataPerson(state, { payload: { person_id, data }}) {
      editPerson(state, { person_id, data })
      rebuildGroupPeople(state)
    },

    changeDataPersonParty(state, { payload: { person_id, party_id, deal_party_id }}) {
      const old_party_id = state.person_parties[person_id]
      party_id = deal_party_id ? state.deal_parties[deal_party_id]?.party : party_id
      
      assignToDealParty(state, {deal_party_id, person_id})
      if (old_party_id == party_id || old_party_id == null || party_id == null)
        return

      const party = state.deal_party_metadata[party_id]
      const old_party = state.deal_party_metadata[old_party_id]
      // We do not allow changing of paths
      if (!switchableParties(party, old_party))
        return
        
      changePersonParty(state, { person_id, party_id })
      rebuildGroupPeople(state)
    },

    assignUserToParty(state, { payload: { email, party_id, deal_party_id}}) {
      party_id = deal_party_id ? state.deal_parties[deal_party_id]?.party : party_id
      const person_id = addPersonFromUser(state, { email, party_id })
      
      assignToDealParty(state, {deal_party_id, person_id})
      rebuildGroupPeople(state)
    },

    deleteDataPerson(state, { payload: { person_id } }) {
      const email = state.people_emails[person_id]

      removePerson(state, person_id)
      removeUserIfEmpty(state, email)
      rebuildGroupPeople(state)
    },

    openEditPerson(state, { payload: props }) {
      state.edit_person_modal = props
    },

    closeEditPerson(state) {
      state.edit_person_modal = null
    }
  }
})

export const reducer = store.reducer
export const { load, assignUserRole, openEditPerson, closeEditPerson } = store.actions