import { useEffect } from 'react'

const useBeaconDraggable = ({ beaconSliderRef, iconDragRef }) => {
  useEffect(() => {
    const beaconSliderEl = beaconSliderRef.current
    const iconDragEl = iconDragRef.current
    let offsetX, offsetY

    const onMouseDown = (e) => {
      offsetX = e.clientX - beaconSliderEl.getBoundingClientRect().left
      offsetY = e.clientY - beaconSliderEl.getBoundingClientRect().top

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    }

    const onMouseMove = (e) => {
      beaconSliderEl.style.left = `${e.clientX - offsetX}px`
      beaconSliderEl.style.top = `${e.clientY - offsetY}px`
    }

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    iconDragEl.addEventListener('mousedown', onMouseDown)

    return () => {
      iconDragEl.removeEventListener('mousedown', onMouseDown)
    }
  }, [beaconSliderRef, iconDragRef])
}

export default useBeaconDraggable
