import React from 'react'
import { connect } from 'react-redux'
import Store from './Store'
import UserBasicDetailsModalContent from './Modal/UserBasicDetailsModalContent'
import RolesAndPermissionsModalContent from './Modal/RolesAndPermissionsModalContent'
import RemoveRoleConfirmationModalContent from './Modal/RemoveRoleConfirmationModalContent'
import Error, { modalErrorKeys } from './Modal/Error'

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.modal = null
    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    this.open()
    this.listenModalClose()
  }

  componentWillUnmount() {
    this.close()
  }

  listenModalClose() {
    $(this.modal).on("hidden.bs.modal", function () {
      this.close()
    }.bind(this))
  }

  close() {
    $(this.modal).modal('hide');
    this.props.closeModal()
  }

  open() {
    $(this.modal).modal('show')
  }

  deleteBuildingResponsibleParty() {
    const { buildingId, modalUserDetails } = this.props

    $.ajax({
      url: `/buildings/${buildingId}/remove_building_responsible_party/${modalUserDetails.id}`,
      method: 'DELETE',
      dataType: 'json',
      context: this,
      success: res => {
        this.props.deleteBuildingResponsibleParty(modalUserDetails.id)
        this.props.closeModal()
      }, error: err => {
        if (err.responseJSON.error == "There must be another Package processor assigned before you can remove this user's permissions.") {
          this.props.setErrorModalDetails({
            name: modalErrorKeys.package_processor_permissions_cannot_be_removed,
            package_types:  err.responseJSON.package_types
          })
          this.props.openModalForContext('Error')
        }
      }
    })
  }

  renderNewPropertyContact() {
    return <UserBasicDetailsModalContent
      newUser={true}
      onSubmit={() => this.props.openModalForContext('Roles & Permissions')} />
  }

  renderEditPropertyContact() {
    return <UserBasicDetailsModalContent
      newUser={false}
      onSubmit={() => this.props.openModalForContext('Roles & Permissions')} />
  }

  renderRolesAndPermissions() {
    return <RolesAndPermissionsModalContent package_types={this.props.package_types}
                                            alteration_processor_package_types={this.props.alteration_processor_package_types} />
  }

  renderRemoveRoleConfirmation() {
    return <RemoveRoleConfirmationModalContent />
  }

  renderRemoveRoleConfirmationAndDelete() {
    return <RemoveRoleConfirmationModalContent confirm={() => this.deleteBuildingResponsibleParty()} />
  }

  renderError() {
    return <Error />
  }

  renderModalContent() {
    switch(this.props.modalOpenForContext) {
      case 'New Building Responsible Party':
        return this.renderNewPropertyContact()
      case 'Roles & Permissions':
        return this.renderRolesAndPermissions()
      case 'Edit Building Responsible Party':
        return this.renderEditPropertyContact()
      case 'Remove Role Confirmation':
        return this.renderRemoveRoleConfirmation()
      case 'Remove Role Confirmation And Delete':
        return this.renderRemoveRoleConfirmationAndDelete()
      case 'Error':
        return this.renderError()
      default:
        return null
    }
  }

  render() {
    return <div className="modal brp-modal" role="dialog" ref={this.setModalRef}>
      <div className="modal-dialog" role="document">
        {this.renderModalContent()}
      </div>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modalOpenForContext: state.modal.modalOpenForContext,
    buildingId: state.main.buildingId,
    modalUserDetails: state.modal.modalUserDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalForContext: context => dispatch(Store.openModalForContext(context)),
    closeModal: () => dispatch(Store.closeModal()),
    deleteBuildingResponsibleParty: BuildingResponsibleParty => dispatch(Store.deleteBuildingResponsibleParty(BuildingResponsibleParty)),
    setErrorModalDetails: details => dispatch(Store.setErrorModalDetails(details))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)
