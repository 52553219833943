import React from 'react'

class CalendarDate extends React.Component {
  render(){
    return(
      <div className='col-xs-12 text-center'>
        <div className='notification-calendar-container'>
          <i className='fa fa-calendar-o fa-4 notification-calendar-icon'>
            <div className= 'notification-calendar-date'>
              {this.props.month}
              <br/>
              <div className='day'>
                {this.props.day}
              </div>
            </div>
          </i>
          <div className='year text-bopa'>
            <div className="centered-absolute">
              {this.props.year}
            </div>
          </div>
        </div>
      </div>)
    }
  }

  export default CalendarDate
