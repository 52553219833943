import React, { useState } from 'react'
import { compact } from 'lodash'


export default function(props) {
  const [checked, setChecked] = useState(props.checked)
  const classes = compact([props.className, checked ? "checked" : null])

  const checkClasses = ['fa-check', `fa-${props.checkWeight || 'regular'}`]
  const onClick = () => {
    setChecked(!checked)
    if (props.onChange)
      props.onChange(!checked)
  }

  return <button className={classes.join(" ")} onClick={onClick}>
    <span>{props.children || props.title }</span>
    { checked ? <i className={checkClasses.join(" ")} /> : null }
    { checked && props.name ? <input type="hidden" name={props.name} vaiue={props.value || 1} /> : null }
  </button>
}