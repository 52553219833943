import React from 'react'

export default props => (
  <div className='div-table-row'>
    <div className='div-table-col'>
      {props.value}
    </div>
    <div className='div-table-col'>
      <span>{props.label}</span>
    </div>
  </div>
)
