import React, { useState } from 'react'

const LandlordFormFields = (props) => {
  return (
    <div className='form-items'>
      <div className='form-group'>
        <label className='pull-left email-label' htmlFor='email'>
          Landlord Email<span className='required'>&nbsp;*</span>
        </label>
        <div className="pull-right"><span className='thick-text'>Note: </span><span className='thin-text'>this email is <span className='thick-text'>autofilled</span> within the lease</span></div>
        {props.errors && (
          <span className='error'>{props.errors?.landlord?.email?.message}</span>
        )}
        <input
          id='email'
          className={`form-control ${
            props.errors?.landlord?.email ? "error-field" : ""
          }`}
          type='text'
          {...props.register("landlord[email]", {
            pattern: {
              value: /^.+@.+\..+$/,
              message: "Enter a valid email address.",
            },
            required: "Email required",
          })}
        />
      </div>
      <button
        type='submit'
        className={`btn green pull-right ${
          props.isValid ? "all-valid" : ""
        }`}
      >
        Save Landlord Info
      </button>
    </div>
  )
}

export default LandlordFormFields
