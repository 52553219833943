import React from 'react'
import PropTypes from 'prop-types'

import Modal from './modal'

const humanizeStatus = function(status){
  switch(status) {
    case 'assigned':
      return 'Assigned'
    case 'authorized':
      return 'Authorized'
    case 'captured':
      return 'Collected'
    case 'n/a':
      return 'N/A'
    case 'waived':
      return 'Waived'
  }
}

class AdditionalFeesApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      modalActive: false,
      modalPurpose: null,
      existingFees: [],
      sendModalSuccess: false,
      sendModalError: false,
      modalErrors: [],
      collectDeclineCodes: [],
      uncapturableFees: [],
      declineCodePerFeeId: {}
    }

    this.setAppState = this.setAppState.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(_, prevState) {
    if (this.state.sendModalSuccess && !prevState.sendModalSuccess)
      this.modalPurposeSucceeded()

    if (this.state.sendModalError && !prevState.sendModalError)
      this.modalPurposeErrored()
  }

  modalPurposeSucceeded() {
    switch(this.state.modalPurpose) {
      case "add":
        this.assignSuccessSwal()
        break
      case "edit":
        this.updateSuccessSwal()
        break
      case "collect":
        this.collectSuccessSwal()
        break
    }

    this.setState({ sendModalSuccess: false })
  }

  modalPurposeErrored() {
    switch(this.state.modalPurpose) {
      case "collect":
        this.collectErrorSwal()
        break
    }

    this.setState({ sendModalError: false })
  }

  resetApp() {
    //delete Components.AdditionalFees.global["availableFeeNamesByDueAt"]
    //delete Components.AdditionalFees.global["availableAssignees"]
    //delete Components.AdditionalFees.global["routingAccounts"]
    this.setState({
      loading: true,
      modalActive: false,
      modalPurpose: null,
      existingFees: [],
      sendModalSuccess: false,
      sendModalError: false,
      modalErrors: [],
      collectDeclineCodes: [],
      uncapturableFees: []
    })
    this.getData()
  }

  assignSuccessSwal() {
    swal({
      title: "Success",
      text: "Fees have been sent to the assigned parties.",
      type: "success",
      showCancelButton: false,
      closeOnConfirm: true,
    }, function(isConfirm){
      if (isConfirm) this.resetApp()
    }.bind(this))
  }

  updateSuccessSwal() {
    swal({
      title: "Success",
      text: "Fees have been updated.",
      type: "success",
      showCancelButton: false,
      closeOnConfirm: true,
    }, function(isConfirm){
      if (isConfirm) this.resetApp()
    }.bind(this))
  }

  collectSuccessSwal() {
    swal({
      title: "Success",
      text: "Fees have been collected.",
      type: "success",
      showCancelButton: false,
      closeOnConfirm: true,
    }, function(isConfirm){
      if (isConfirm) this.resetApp()
    }.bind(this))
  }

  collectErrorSwal() {
    collectFeesFailedSwal(this.state.uncapturableFees, this.state.declineCodePerFeeId, this.resetApp.bind(this))
  }

  edittableFeesExist() {
    return this.state.existingFees.some((fee) => ["Authorized", "Assigned"].includes(fee.status))
  }

  collectableFeesExist() {
    return this.state.existingFees.some((fee) => fee.status == "Authorized")
  }

  shouldAddFeeBtnBeDisabled() {
    return this.props.featureDisabled
  }

  handleAddFeesBtnClick() {
    this.setState({modalActive: true, modalPurpose: "add"})
  }

  handleEditFeesBtnClick() {
    this.setState({modalActive: true, modalPurpose: "edit"})
  }

  handleCollectFeesBtnClick() {
    this.setState({modalActive: true, modalPurpose: "collect"})
  }

  getData() {
    const url = "/packages/" + this.props.packageHashedId + "/additional_fees_data"

    this.setLoading(true)
    $.ajax({
      url: url, method: "GET", dataType: "JSON", context: this, success: function(res) {
        let fees = res.fees_data
        for (let i in fees) {
          fees[i].status = humanizeStatus(fees[i].status)
        }
        this.setState({ existingFees: fees })
        this.setLoading(false)
      }
    })
  }

  setAppState(newState) {
    this.setState(newState)
  }

  setLoading(bool) {
    this.setState({ loading: bool })
  }

  renderLoading() {
    // todo
    return (
      <div></div>
    )
  }

  renderDisabledFeatureText() {
    return <div>
      This area will activate after Submission Fees are collected.
    </div>
  }

  renderNoFeesText() {
    return (
      <div className="no-fees-section">There are currently no additional fees listed. Click the Add Fees button below to include fees here.</div>
    )
  }

  renderFeesTableContainer() {
    return (
      <div className="fees-table-container">
        {this.renderFeesTableContents()}
      </div>
    )
  }Payee

  feesRowList() {
    let list = []

    list.push(
      <div key="feesRowList-header" className="flex-container">
        <div className="flex-column table-header">Fee Name</div>
        <div className="flex-column table-header">Payor</div>
        <div className="flex-column table-header">Due Upon</div>
        <div className="flex-column table-header">GL Code</div>
        <div className="flex-column table-header">Charge Code</div>
        <div className="flex-column table-header">Routing Account</div>
        <div className="flex-column table-header">Amount</div>
        <div className="flex-column table-header">Fee Status</div>
      </div>
    )

    this.state.existingFees.forEach(function(fee) {
      list.push(
        <div key={fee.id} className="flex-container">
          <div className="flex-column">{fee.name}</div>
          <div className="flex-column">{fee.payee_name}</div>
          <div className="flex-column">{fee.due_at}</div>
          <div className="flex-column">{fee.gl_code}</div>
          <div className="flex-column">{fee.charge_code}</div>
          <div className="flex-column">{fee.routing_account}</div>
          <div className="flex-column">{asMoney(fee.amount)}</div>
          <div className="flex-column">{fee.status}</div>
        </div>
      )
    })

    return list
  }

  renderFeesTableContents() {
    if (this.props.featureDisabled)
      return this.renderDisabledFeatureText()

    if (this.state.existingFees.length == 0)
      return this.renderNoFeesText()

    return <div>
      {this.feesRowList()}
    </div>
  }

  renderBtns() {
    return (
      <div>
        {this.renderAddFeesBtn()}
        {this.edittableFeesExist() ? this.renderEditFeesBtn() : null}
        {this.state.existingFees.length > 0 ? this.renderCollectFeesBtn() : null}
      </div>
    )
  }

  renderAddFeesBtn() {
    return <button
      className={`btn btn-warning add-fees-btn ${this.shouldAddFeeBtnBeDisabled() ? "disabled" : ''}`}
      disabled={this.shouldAddFeeBtnBeDisabled()}
      onClick={() => this.handleAddFeesBtnClick()}>Add Fees</button>
  }

  renderEditFeesBtn() {
    if (this.props.featureDisabled) return null

    return <button
      className="btn btn-danger edit-fees-btn"
      onClick={() => this.handleEditFeesBtnClick()}>Edit Fees</button>
  }

  renderCollectFeesBtn() {
    if (this.props.featureDisabled) return null

    let classNames = "btn collect-fees-btn pull-right"
    classNames += this.collectableFeesExist() ? " btn-success" : " btn-secondary"

    return <button
      className={classNames}
      disabled={!this.collectableFeesExist()}
      onClick={() => this.handleCollectFeesBtnClick()}>Collect Fees</button>
  }

  renderModal() {
    // todo: move to own component

    return <Modal
      packageHashedId={this.props.packageHashedId}
      isAlteration={this.props.isAlteration}
      existingFees={this.state.existingFees}
      setAppState={this.setAppState}
      modalPurpose={this.state.modalPurpose}
    />
  }

  render() {
    return (
      <div id="additional-fees-app">
        {this.renderFeesTableContainer()}
        {this.renderBtns()}
        {this.state.modalActive ? this.renderModal() : null}
      </div>
    )
  }
}

AdditionalFeesApp.propTypes = {
  packageHashedId: PropTypes.string.isRequired
}

export default AdditionalFeesApp
