import React from 'react'

import OrganizationItem from './item'
import SelectedOrganizations from './selected'

class OrganizationRelationshipsContainer extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      default_organization: this.props.default_organization,
      selected: this.props.selected,
      dropdownActive: false
    }

    this.handleCheck = this.handleCheck.bind(this)
    this.handleCheckDefault = this.handleCheckDefault.bind(this)
    this.handleToggleDropdownActive = this.handleToggleDropdownActive.bind(this)
  }

  handleCheck(org_id, active) {
    const selected = this.state.selected.slice(0),
          idx = selected.indexOf(org_id),
          state = { selected }

    if (active && idx == -1)
      selected.push(org_id)
    else if (!active && idx >= 0) {
      selected.splice(idx, 1)

      if (this.isDefaultOrganizationId(org_id))
        state.default_organization = selected[0]
    }

    this.setState({selected})
  }

  handleCheckDefault(org_id) {
    this.setState({default_organization: org_id})
  }

  handleToggleDropdownActive() {
    this.setState({ dropdownActive: !this.state.dropdownActive })
  }

  hasDefault() {
    return this.props.has_default || false
  }

  selectedOrganizations() {
    return this.props.possible.filter(org => this.isOrganizationSelected(org))
  }

  isOrganizationSelected(organization) {
    return this.state.selected.includes(organization.id)
  }

  isDefaultOrganization(organization) {
    return this.isDefaultOrganizationId(organization.id)
  }

  isDefaultOrganizationId(org_id) {
    return this.state.default_organization == org_id
  }

  generateOrganizationItem(organization) {
    const props = {
      key: [this.props.relationship,organization.id].join(','),
      organization,
      relationship: this.props.relationship,
      selected: this.state.selected,
      checked: this.isOrganizationSelected(organization),
      is_default: this.isDefaultOrganization(organization),
      has_default: this.hasDefault(),
      handleCheck: this.handleCheck,
      handleCheckDefault: this.handleCheckDefault
    }

    return <OrganizationItem { ... props }/>
  }

  generateCheckboxLine(title) {
    return <div className="col-xs-2 text-center"><em>{title}</em></div>
  }

  generateOptionsList() {
    const list = this.props.possible.map(organization => this.generateOrganizationItem(organization))

    return <div>
      <div className="row">
        <div className={this.hasDefault() ? "col-xs-1" : "col-xs-3"}></div>
        {this.generateCheckboxLine("Select")}
        { this.hasDefault() ? this.generateCheckboxLine("Default") : ""}
        <div className="col-xs-7"><em>Organization</em></div>
      </div>
      {list}
    </div>
  }

  render() {
    const optionsList = this.generateOptionsList()

    const selected_props = {
      relationship: this.props.relationship,
      default_organization: this.state.default_organization,
      organizations: this.selectedOrganizations(),
      handleToggleDropdownActive: this.handleToggleDropdownActive,
    }

    return (
      <div name={`relation[${this.props.relationship}]`} id={`${this.props.relationship}_relation_container`}>
        <SelectedOrganizations { ... selected_props } />
        {this.state.dropdownActive ? optionsList : ""}
      </div>
    )
  }
}

export default OrganizationRelationshipsContainer