import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Tools } from "../../common/toolbar/Tools"

const Component = (props) => {
  const RenderedComponent = props.subtype ? Tools[props.type].components[props.subtype] : Tools[props.type].component

  return <RenderedComponent {...props} />
}

const usePreparedComponents = () => {
  const newest = useSelector(state => state.components?.newest)
  const components = useSelector(state => state.components?.all)
  const selected = useSelector(state => state.components?.selected || [], shallowEqual)

  const activeToolName = useSelector(({tools}) => tools.active)
  const tool = Tools[activeToolName]

  if (Object.keys(components).length === 0) return

  return Object.keys(components).map(key => {
    const component = components[key]
    const props = {
      key,
      id: component.id,
      type: component.type,
      subtype: component.subtype,
      rect: component.rect,
      drawOnCreate: key == newest,
      selectOnCreate: selected.includes(key),
      page: component.page,
      locked: tool?.createsComponent && component?.type != tool?.name
    }

    return <Component {...props} />
  })
}

export default usePreparedComponents
