import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createStructureApi } from './apis'

const initialState = {
  structure: null,
  structureCreated: false,
  structureCreating: false,
  errorMessages: [],
}

export const createStructure = createAsyncThunk(
  'buildingEditAddStructure/createStructure',
  async (data, thunkAPI) => {
    try {
      const response = await createStructureApi(data.building_id, data)
      const { building_structure } = response.data
      return { building_structure }
    } catch (err) {
      if (err?.response?.data) {
        return thunkAPI.rejectWithValue(err.response.data)
      } else {
        throw err
      }
    }
  }
)

const newStructureSlice = createSlice({
  name: 'buildingEditAddStructure',
  initialState,
  reducers: {
    clearErrorMessages: (state, action) => {
      state.errorMessages = []
    },
    resetState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createStructure.pending, (state, action) => {
      state.structureCreating = true
    })
    builder.addCase(createStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload

      state.structure = building_structure
      state.structureCreating = false
      state.structureCreated = true
    })
    builder.addCase(createStructure.rejected, (state, action) => {
      if (action.payload?.error_messages) {
        const { error_messages } = action.payload
        state.errorMessages = error_messages
      } else {
        state.errorMessages = ['Some errors happened, please try again!']
      }
      state.structureCreating = false
    })
  },
})

export const selectNewStructureState = (state) => state.buildingEditAddStructure

export const { clearErrorMessages, resetState } = newStructureSlice.actions

export const buildingEditAddStructureReducer = newStructureSlice.reducer
