import React from 'react'

import Text from "./text"
import Static from "./static"
import Number from './number'
import Money from "./money"
import Email from "./email"
import Phone from "./phone"
import Boolean from './boolean'
import Time from './time'
import Date from './date'
import Slider from './slider'
import ColorList from './color_list'
import Select from './select'
import USStates from './us_states'
import MeasureAndUnits from './measure_and_units'
import Duration from './duration'

const LOCKS = [ "duration", "date" ]

function transformIfLocked(props) {
  const { locked, system_only, field } = props

  if (!locked && !system_only) return props
  if ( LOCKS.includes(field.type) ) return props

  const newProps = { 
    ...props,
    field: { ...field, type: "static" }
  }

  return newProps
}

export default function(props) {
  // If the field is locked or system only, then it must just be a value
  props = transformIfLocked(props)

  const { type } = props.field || {}
  const classes = ['field', `form-field-${type}-type`].join(" ")

  switch(type) {

  case 'measure-and-units':
    return <MeasureAndUnits className={classes} { ...props } />
  case 'text':
    return <Text className={classes} {...props} />
  case 'email':
    return <Email className={classes} {...props} />
  case 'phone':
    return <Phone className={classes} {...props} />
  case 'number':
    return <Number className={classes} {...props} />
  case 'money':
    return <Money className={classes} {...props} />
  case 'boolean':
    return <Boolean className={classes} {...props} />
  case 'time':
    return <Time className={classes}  {...props} />
  case 'date':
    return <Date className={classes} {...props} />
  case 'duration':
    return <Duration className={classes} {...props} />
  case 'options':
  case 'select':
    return <Select className={classes} {...props} />
  case 'us-states':
    return <USStates className={classes} {...props} />
  case 'slider':
    return <Slider className={classes} {...props} />
  case 'color-list':
    return <ColorList className={classes} {...props} />
  }
  
  return <Static className={classes} {...props} />
}
