import React from 'react'
import Field from './Field'

const ContactDetails = props => {
  const { register, isValid, errors, getValues } = props
  const Asterisk = () => <span className='bopa-orange'>*</span>

  const UseField = fieldProps => <Field register={register} {...fieldProps} />

  const NextButton = () => {
    let classes = 'btn pull-right'
    classes += isValid ? ' btn-success' : ' btn-default disabled'

    return <button type="submit" className={classes} disabled={!isValid}>Next: Select Role</button>
  }

  const renderError = () => {
    const styling = {
      color: '#F74141',
      backgroundColor: '#FFF8F8',
      border: '1px solid #F74141',
      borderRadius: 5,
      width: '70%',
      textAlign: 'center',
      margin: '-10px auto 20px',
      padding: '5px 10px'
    }

    if (errors.email && getValues('email'))
      return <div style={styling}>
        {errors.email.message}
      </div>
  }

  return (
    <div id='additional-contacts-contact-details'>
      <div>
        <p>Full Name <Asterisk /></p>
        <div style={{ display: 'flex' }}>
          <UseField fieldName='first_name' fieldType='text' required={true} placeholder='First' style={{ marginRight: 20 }}  />
          <UseField fieldName='last_name' fieldType='text' required={true} placeholder='Last' />
        </div>
      </div>
      <div>
        <p>Email <Asterisk /></p>
        <UseField fieldName='email' fieldType='email' required={true} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '60%', marginRight: 20}}>
          <div>Phone Number</div>
          <UseField fieldName='phone_number' fieldType='tel'/>
        </div>
        <div>
          <div>EXT</div>
          <UseField fieldName='phone_number_ext' fieldType='number' />
        </div>
      </div>
      <div style={{ clear: 'both' }}>
        <p>Title <Asterisk /></p>
        <UseField fieldName='title' fieldType='select' required={true} select={{ hasEmptyOption: true, selectItemArray: props.contactTitles, emptyOptionText: 'Select Title' }} />
      </div>
      <br />
      {renderError()}
      <br />
      <button type='button' className='btn btn-default' onClick={props.close}>Cancel</button>
      <NextButton />
    </div>
  )
}

export default ContactDetails
