import React, { useMemo } from 'react'
import ExpandableSection from './ExpandableSection'
import { isEmpty } from 'lodash-es'
import { Tools } from '../../toolbar/Tools'
import Item from './Item'

const SortedList = ({ filteredLabelsList, componentsList }) => {
  const filteredComponents = useMemo(() => {
    const matchingComponents = componentsList.filter((component) =>
      filteredLabelsList.includes(component?.label?.toLowerCase())
    )
    const dataComponents = matchingComponents.filter((component) => component.type === 'data')
    const signatureComponents = matchingComponents.filter((component) => component.type === 'signature')
    return { dataComponents, signatureComponents }
  }, [componentsList, filteredLabelsList])

  return (
    <>
      {filteredComponents.dataComponents.map((component) => (
        <Item key={component.key} component={component} />
      ))}
      {filteredComponents.signatureComponents.map((component) => (
        <ExpandableSection key={component.id} component={component} />
      ))}
    </>
  )
}

const DefaultNoFields = () => {
  return (
    <div className="instructional">
      <p>All fields will display here.</p>
      <p>
        Add a field with
        <span>
          data <i className={Tools.data.icon}></i>
        </span>
      </p>
      <p>
        Add a signature field with
        <span>
          sign <i className={Tools.signature.icon}></i>
        </span>
      </p>
    </div>
  )
}

const LabelsList = ({ filteredLabelsList, componentsList }) => {
  return (
    <div className="section-container scrollable">
      {isEmpty(filteredLabelsList) ? (
        <DefaultNoFields />
      ) : (
        <SortedList filteredLabelsList={filteredLabelsList} componentsList={componentsList} />
      )}
    </div>
  )
}

export default LabelsList
