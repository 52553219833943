import React from 'react'
import Beacon from '.'
import dealparties1Image from '/app/assets/images/beacon/new/dealparties1.png'
import dealparties2Image from '/app/assets/images/beacon/new/dealparties2.png'
import dealparties3Image from '/app/assets/images/beacon/new/dealparties3.png'
import dealparties4Image from '/app/assets/images/beacon/new/dealparties4.png'
import BeaconSlider from './slider'
import withBeaconModal from './modal'

const DATA = [
  {
    label: 'deal parties',
    titles: ['Input info about your deal here—unit number, price, shares, and all transaction participants.'],
    image: dealparties1Image,
  },
  {
    label: 'enter your details',
    titles: ['Fill in unit number, price, and other blank fields in this section.'],
    image: dealparties2Image,
  },
  {
    label: 'add participants',
    titles: ['Enter names + email addresses of all involved.'],
    description: '*If you need an additional participant, use the “+” icon to create another entry. ',
    image: dealparties3Image,
  },
  {
    label: 'application processors',
    titles: ['This section lists Processors who review submitted applications.'],
    image: dealparties4Image,
  },
]

const DealPartiesBeacon = (props) => {
  return (
    <BeaconSlider numberOfSteps={DATA.length} {...props}>
      {(currentStep) => {
        const item = DATA[currentStep]
        return (
          <Beacon>
            <Beacon.Body>
              <Beacon.RadioGroup />
              <Beacon.ListGroup gap={0}>
                {currentStep === 0 && <Beacon.Label />}
                <Beacon.Title>{item.label}</Beacon.Title>
              </Beacon.ListGroup>
              <Beacon.ListGroup>
                {item.titles.map((title) => (
                  <Beacon.Text key={title}>{title}</Beacon.Text>
                ))}
                <Beacon.SubText>{item.description}</Beacon.SubText>
              </Beacon.ListGroup>
            </Beacon.Body>
            <Beacon.Image src={item.image} />
          </Beacon>
        )
      }}
    </BeaconSlider>
  )
}

const DealPartiesBeaconWithBeaconModal = withBeaconModal(DealPartiesBeacon)

export default DealPartiesBeaconWithBeaconModal
