import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compact } from 'lodash'

import InternalField from 'components/form_field'
import { metadataByPath, valueByPath } from 'lib/utilities/form'

import { updateValues } from '../index'
//import DealParties from './deal_parties'

import FormFromTemplate from 'components/form_from_template'

export default function(props) {
  const template = useSelector(state => state.data.basic_info_template)
  //const sections = useSelector(state => state.data.basic_info_template.sections || [])
  const metadata = useSelector(({data}) => data.metadata)
  const values = useSelector(({data}) => data.values )
  const valuesHash = useSelector(({data}) => data.valuesHash)
  const dispatch = useDispatch()

  const overrides = {
    getField(path) { return metadataByPath(metadata, path) },
    getValue(path) { return values[path] },
    getValueHash(path) { return valueByPath(valuesHash, path) },
    updateValues(hash) { dispatch(updateValues(hash)) },
    specialSections: {
      deal_parties: () => <div></div>,
      custom: () => <div></div>
    },
    
    globalPathmap: {
      getState: state => state.data.values
    }
  }
  
  return <div className="review-data">
    <p className="description">Review te Deal Data that will be used to autofill the selected documents in this agreement.</p>
    <p className="red-note sub-description">
      <strong>NOTE:</strong> Any changes to the Deal Data will be reflected across the entire agreement
    </p>

    <FormFromTemplate overrides={overrides} template={template} useAccordion={false} useDefaultStyling={false} />
  </div>
}