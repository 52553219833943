import React from 'react'
import PropTypes from 'prop-types'

function makeId(seed) {
  return Math.random(seed).toString(36).substring(5);
}

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      inputId: ""
    }

    this.input = null
    this.setInputRef = element => { this.input = element }

    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount() {
    const id = this.props.inputId != "" ? this.props.inputId : makeId(this.props.name)

    this.setState({inputId: id})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputId != this.state.inputId)
      return this.initDatePicker()

    if (prevProps.name != this.props.name)
      return this.initDatePicker()
  }

  // fixes conflict with jquery-ui datepicker
  useBootstrapDatePicker() {
    if (typeof($.fn.bootstrapDP) == "function")
      return

    const datepicker = $.fn.datepicker.noConflict(); // return $.fn.datepicker to previously assigned value
    $.fn.bootstrapDP = datepicker;                 // give $().bootstrapDP the bootstrap-datepicker functionality
  }

  initDatePicker() {
    this.useBootstrapDatePicker()

    $(`#${this.state.inputId}`).bootstrapDP({
      format: 'yyyy/mm/dd',
      todayHighlight: true,
      autoclose: true,
    })

    // sets selected date to passed date or today by default
    const defaultDate = this.props.date ? new Date(this.props.date) : new Date()

    $(`#${this.state.inputId}`).bootstrapDP('update', defaultDate);
    $(`#${this.state.inputId}`).change((event) => this.handleChangeDate(event.currentTarget.value))
  }

  handleChangeDate() {
    if (typeof(this.props.handleChangeDate) == "function")
      return this.props.handleChangeDate(this.input.value)
  }

  render() {
    const { name, required, allowManualInput } = this.props
    const inputAttributes = {
      id: this.state.inputId,
      className: "datepicker-utility",
      key: `key-${name}`,
      name: name,
      ref: this.setInputRef,
      readOnly: !allowManualInput,
      required: required
    }

    return <input {...inputAttributes} />
  }
}

DatePicker.propTypes = {
  name: PropTypes.string,
  handleChangeDate: PropTypes.func,
  date: PropTypes.string,
  inputId: PropTypes.string,
  required: PropTypes.bool,
  allowManualInput: PropTypes.bool
}

DatePicker.defaultProps = {
  name: "",
  inputId: "",
  required: false,
  allowManualInput: true
}

