import React from 'react'

class Row extends React.Component {
  static defaultProps = {
    addable: false
  }

  constructor(props) {
    super(props)

    this.state = {
      hover: false
    }
  }

  formatAsMoney(){
    let { price } = this.props.document
    if (typeof price === 'string'){
      price = Number(price)
    };
    return '$' + price.formatMoney(2)
  }

  onAttachDocument(){
    this.props.handleAttachDocument(this.props.document)
  }

  alreadyUploaded(){
    return (this.props.buildingStoreLibraryDocumentIds.includes(this.props.document.library_document_id))
  }

  toggleHover(){
    this.setState({ hover: !this.state.hover })
  }

  render(){

    let { label, file_class, days_until_expiration, id, price, base_filename } = this.props.document
    let alreadyUploaded = this.alreadyUploaded()
    let tableStyle = {display: 'table-cell', width: '100%'}
    let trStyle={ display: 'table-row', width: '100%'}
    let trStyleHover={ display: 'table-row', backgroundColor: '#EEEDEC', width: '100%'}
    let theadStyle={ display: 'table-header-group'}
    let tdStyle = alreadyUploaded ?
    { display: 'table-cell', color: 'orange', padding: '5px', textAlign: 'center'} :
    { display: 'table-cell', padding: '5px', textAlign: 'center'}
    let edgeSpan={ width: '5%'}

    return (
      <div className='tr'
        style={this.state.hover ? trStyleHover : trStyle }
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        >
        <span style={edgeSpan}></span>
        <span className='dont-break-out' style={tdStyle}>
          {this.props.truncateString(base_filename)}
        </span>
        <span className='dont-break-out' style={tdStyle}>{label}</span>
        <span style={tdStyle}>{file_class}</span>
        <span style={tdStyle}>{days_until_expiration}</span>
        <span style={tdStyle}>{this.formatAsMoney()}</span>
        <span style={edgeSpan}>
          <i className="fa fa-check"
            onClick={ alreadyUploaded ? null : this.onAttachDocument.bind(null, this)}>
          </i>
        </span>
      </div>
    )
  }
}

export default Row
