import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal, selectPropertyTransfer } from '../store/slice'
import TransferAccountsModal from './components/TransferAccountsModal'

const TransferAccounts = ({}) => {
  const dispatch = useDispatch()
  const { transferAccountsModalOpen } = useSelector(selectPropertyTransfer)
  const onClose = useCallback(() => {
    dispatch(closeModal())
  }, [dispatch])

  useEffect(() => {
    const openTransferModal = (e) => {
      dispatch(
        openModal({
          buildingId: e.detail.buildingId,
        })
      )
    }

    window.addEventListener('open-transfer-accounts-modal', openTransferModal)

    return () => window.removeEventListener('open-transfer-accounts-modal', openTransferModal)
  }, [dispatch])

  return (
    <>
      <TransferAccountsModal open={transferAccountsModalOpen} onClose={onClose} />
    </>
  )
}

export default TransferAccounts
