import React from 'react'
import { formattedMoney } from 'components/insurance/get_quote/utils'
import MonetaryAmount from '../../MonetaryAmount'

export default function QuoteEstimateMonthlyContent({ monthlyAmount, writingCompany }) {
  return (
    <>
      <div className="dome-p1 dome-d-flex dome-align-baseline" style={{ margin: '8px 0' }}>
        <div className="dome-text-w500 dome-color-dark-green dome-d-flex dome-align-start" style={{ lineHeight: 1 }}>
          <MonetaryAmount amount={monthlyAmount} dollarsClass={'text-40'} />
        </div>
        <span>/month</span>
      </div>
      {writingCompany && (
        <div className="dome-p3">
          <em>{`Insurance underwritten by: ${writingCompany}`}</em>
        </div>
      )}
    </>
  )
}
QuoteEstimateMonthlyContent.defaultProps = {
  monthlyAmount: 0,
  writingCompany: null,
}
