import React from 'react'

import ConfirmTab from './tabs/confirm'
import DefaultTab from './tabs/default'
import MessageTab from './tabs/message'
import PolicyTab from './tabs/policy'
import RemoveTab from './tabs/remove'
import ShowAllTab from './tabs/show_all'

import ButtonToolbar from './button_toolbar'

class OptionBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {selectedTab: "DEFAULTTAB"}
    this.savePolicyChange = this.savePolicyChange.bind(this)
    this.cancelPolicyChange = this.cancelPolicyChange.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.selectedTab = this.selectedTab.bind(this)
    this.saveTabChanges = this.saveTabChanges.bind(this)
    this.atLeastOneChecked = this.atLeastOneChecked.bind(this)
    this.manuallySelecting = this.manuallySelecting.bind(this)
    this.hasOptionSelected = this.hasOptionSelected.bind(this)
  }

  componentDidMount() {
    if (this.props.noRequirements) {
      if (this.props.collaborator.attached) return this.changeTab("REMOVETAB")
      return this.changeTab("CONFIRMTAB")
    }
  }

  selectedTab () {
    switch(this.state.selectedTab) {
      case 'POLICYTAB':
        return <PolicyTab
          packageRequirements={this.props.packageRequirements}
          collaborator={this.props.collaborator}
          toggleSelectedRequirement={this.props.toggleSelectedRequirement}
          selectAllRequirements={this.props.selectAllRequirements}
          deSelectAllRequirements={this.props.deSelectAllRequirements}
          />
      case 'MESSAGETAB':
        return <MessageTab
            message={this.props.message}
            updateMessage={this.props.updateMessage}
            collaborator={this.props.collaborator}
          />
      case 'CONFIRMTAB':
        return <ConfirmTab
            collaborator={this.props.collaborator}
            isAlteration={this.props.isAlteration}
            createPackageUser={this.props.createPackageUser}
            changeTab={this.changeTab}
            noRequirements={this.props.noRequirements}
          />
      case 'REMOVETAB':
        return <RemoveTab
            collaborator={this.props.collaborator}
            tag={this.props.tag}
            destroyPackageUser={this.props.destroyPackageUser}
            changeTab={this.changeTab}
            noRequirements={this.props.noRequirements}
            cancelPolicyChange={this.cancelPolicyChange}
          />
      case 'DEFAULTTAB':
        if (this.props.collaborator.sharingRole == 'broker') {
          return <ShowAllTab
              collaborator={this.props.collaborator}
              changeTab={this.changeTab}
            />
        } else {
          return <DefaultTab
              collaborator={this.props.collaborator}
              changeTab={this.changeTab}
              toggleReadOnly={this.props.toggleReadOnly}
              toggleAttachAllRequirementPolicies={this.props.toggleAttachAllRequirementPolicies}
              resetForManualSelectClick={this.props.resetForManualSelectClick}
              manuallySelecting={this.manuallySelecting}
            />
        }
    }
  }

  saveTabChanges () {
    switch(this.state.selectedTab) {
      case 'POLICYTAB':
        // update policy
      case 'MESSAGETAB':
        // nothing neccessary
      case 'CONFIRMTAB':
        // nothing necessary
      case 'DEFAULTTAB':
        // nothing neccessary
    }
  }

  atLeastOneChecked () {
    var anyChecked = false
    var updatedPolicy = this.props.collaborator.updatedPolicy
    for (var i=0; i < updatedPolicy.length; i++) {
      if (updatedPolicy[i].visibility === true) {
        anyChecked = true
        break
      }
    }
    return anyChecked
  }

  manuallySelecting () {
    return ((!this.props.collaborator.readOnly && !this.props.collaborator.attachAllRequirementPolicies) && this.atLeastOneChecked())
  }

  hasOptionSelected () {
    return (this.manuallySelecting() || this.props.collaborator.attachAllRequirementPolicies)
  }

  changeTab (selectedTab) {
    this.setState({ selectedTab })
  }

  savePolicyChange () {
    this.saveTabChanges(this.state.selectedTab)
    this.changeTab('DEFAULTTAB')
  }

  cancelPolicyChange () {
    this.props.togglePopover(this.props.collaborator.idx)
  }

  visibleButtonToolbar () {
    if (this.state.selectedTab === 'CONFIRMTAB') return false
    if (this.state.selectedTab === 'REMOVETAB') return false
    return true
  }

  render () {
    return (
        <div className="popover package-user-popover" data-container="body" style={{display: 'block', width: '300px', padding: '10px'}}>
            <div className="package-user-identifier" style={{paddingBottom: '5px'}}>
              <span className="text-bopa">{this.props.collaborator.userMetadata.section}:</span> {this.props.collaborator.userMetadata.full_name}
            </div>
            {this.selectedTab()}
            { this.visibleButtonToolbar() ?
              <ButtonToolbar
                collaborator={this.props.collaborator}
                savePolicyChange={this.savePolicyChange}
                cancelPolicyChange={this.cancelPolicyChange}
                changeTab={this.changeTab}
                updatePackageUser={this.props.updatePackageUser}
                selectedTab={this.state.selectedTab}
                hasOptionSelected={this.hasOptionSelected}
              /> : null
            }
        </div>
    )
  }
}

export default OptionBox
