import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compact } from 'lodash'

import { format, fromUnixTime } from 'date-fns'
import { ajax } from 'jquery'
import { addCSRF } from 'lib/utilities'

import { usePermissionsChecks } from '..'
import { focusSignature, setCurrentSignature, setSigningAs, clearSigningAs, unfocusSignature, removeSignature, useHasSignaturesToSign } from './store'
import { setSignatureForSigning } from './signing_area'
import { useEventManager } from '@deathbyjer/react-event-manager'

function useNextSignedIndex() {
  const { list, allSigned } = useSelector(({form_signatures: state}) => state.signatures_to_sign)
  const current = useSelector(({form_signatures: state}) => state.current_signature)

  // If they are all signed, then the next signature is just whatever is next
  if (allSigned)
    return (current + 1) % list.length

  // If there are some non-signed signatures, the next signature is the next avaialble signature to sign
  for (let i = current + 1; i < current + list.length; i++)
    if (!list[i % list.length].isSigned)
      return i % list.length

  return current
}

export function StartSigningButton({className, children}) {
  const isSigning = useSelector(({form_signatures: state}) => state.signing_as ? true : false)
  const hasSignaturesLeft = useHasSignaturesToSign()
  const currentUser = useSelector(({form_signatures: state}) => state.current_user)
  const { allSigned } = useSelector(({form_signatures: state}) => state.user_signatures)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(setSigningAs(currentUser))
  }, [currentUser.user_id, currentUser.party_id, currentUser.roles.join(",")])

  if (isSigning || !hasSignaturesLeft)
    return null

  const classes = ["start-signature-button"]

  return <div className={classes.join(" ")}>
    <button className={className} onClick={onClick}>
      {children || `${allSigned ? "Edit" : "Start"} Signing`}
    </button>
  </div>
}

export function NextSignatureButton({className, children}) {
  const isSigning = useSelector(({form_signatures: state}) => state.signing_as ? true : false)
  const signatures = useSelector(({form_signatures: state}) => state.signatures_to_sign)
  const current = useSelector(({form_signatures: state}) => state.current_signature)
  const nextSignatureIndex = useNextSignedIndex()
  const uniqueness = useSelector(({global}) => global.uniqueness)
  const dispatch = useDispatch()
  const events = useEventManager()

  const nextElement = () => {
    setTimeout(() => dispatch(setCurrentSignature(nextSignatureIndex)), 500)

    const { signature, signature_group } = signatures.list[current]
    if (signature)
      return document.getElementById(`${uniqueness}-signature-${signature.id}`)
    else if (signature_group)
      return document.getElementById(`${uniqueness}-signature_group-${signature_group.id}-${signature_group.positions[0].page}`)

    return null
  }

  const onClick = () => {
    // if all signatures signed, go to the next form with unsigned signatures
    if (signatures.allSigned)
      return events.applyEventListeners('goToNextFormWithSignatures')

    const element = nextElement()

    if (element?.scrollIntoView)
      element.scrollIntoView({behavior: "smooth", block: "center"})
    else if (element?.scrollIntoViewIfNeeded)
      element.scrollIntoViewIfNeeded(true)
  }

  if (!isSigning)
    return null

  const classes = [
    "next-signature-button"
  ]

  return <div className={classes.join(" ")}>
    <button className={className} onClick={onClick}>
      {children}
    </button>
  </div>
}

export function useSigningCheck() {
  const signing = useSelector(({form_signatures: state}) => state.signing_as ? true : false)
  const signaturesLeft = useSelector(({form_signatures: state}) => {
    return state.signatures_to_sign.list.filter(signature => !signature.isSigned).length
  })

  return { signing, signaturesLeft }
}

function useDrawerFocus(id, frozen) {
  const dispatch = useDispatch()
  const container = useRef()
  const focused = useSelector(({form_signatures: state}) => state.focused_signature == id)

  useEffect(() => {
    if (frozen || !container.current || !focused)
      return

    const clickElsewhere = ({target}) => container.current.contains(target) ? null : dispatch(unfocusSignature(id))
    window.addEventListener('click', clickElsewhere)
    return () => window.removeEventListener('click', clickElsewhere)
  }, [container.current, frozen, focused])

  return { container, focused }
}

export function useSigningStatuses(id, frozen) {
  const group = useSelector(state => state.form_signatures.signatures[id]?.deal_party?.group)

  const isSigned = useSelector(({form_signatures: state}) => !!state.signed_signatures[id])
  const isSigning = useSelector(({form_signatures: {signatures_to_sign: to_sign}}) => to_sign.signatures[id] || to_sign.signature_groups[group])

  const signedWithinGroup = useSelector(({form_signatures: state}) => group ? state.signatures_to_sign.signature_groups[group] == "signed" : false )
  const canBeSigned = isSigning && !isSigned && !frozen && !signedWithinGroup
  return { isSigned, isSigning, canBeSigned }
}

export function SignatureWrapper({children, id, frozen, component}) {
  const { container, focused } = useDrawerFocus(id, frozen)
  const {positions, deal_party: { group }} = useSelector(state => state.form_signatures.signatures[id] || {})
  const signature = useSelector(({form_signatures: state}) => state.signed_signatures[id])
  const uniqueness = useSelector(({global: state}) => state.uniqueness)
  const permissions = usePermissionsChecks(['form:remove_any_signature'])

  const dispatch = useDispatch()
  const { isSigned, isSigning, canBeSigned } = useSigningStatuses(id, frozen)

  const click = useCallback(evt => {
    if (frozen)
      return

    dispatch(focusSignature(id))
    canBeSigned ? dispatch(setSignatureForSigning(id)) : null
  }, [frozen, canBeSigned, dispatch, id])

  if (!positions?.signature)
    return null

  const classes = compact([
    'signature',
    `signature-${component}`,
    isSigned ? "is-signed" : null,
    isSigning ? "is-signing" : null,
    focused ? "focused" : null
  ]).join(" ")

  const showDropdown = focused && signature && !frozen && (isSigning || permissions['form:remove_any_signature'])
  const el_id = frozen ? null : `${uniqueness}-${component}-${id}`
  return <div id={el_id} onClick={click} className={classes} ref={container}>
    { children }
    { showDropdown ? <SignedSignatureDropdown id={id} /> : null }
  </div>
}

function removeSignatureRemote({instance_id, signature_id}) {
  const url = `/forms/v3/instance/${instance_id}/signature`
  const data = addCSRF({ signature: { signature_id }})

  return new Promise((res, rej) => {
    const success = () => res()
    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err)
      } catch(e) {
        rej("A server error has occurred")
      }
    }

    ajax({ url, data, type: 'delete', success, error})
  })
}

function RemoveButton({ instance_id, id, required }) {
  const dispatch = useDispatch()
  const events = useEventManager()

  const clickCancel = useCallback(evt => {
    removeSignatureRemote({ instance_id, signature_id: id })
      .then(() => dispatch(removeSignature(id)))
      .then(() => events.applyEventListeners('signatureDeleted', {id, required}))
  }, [id, instance_id, dispatch])

  return <div className="remove" key="remove" onClick={clickCancel}>
    <span>Remove this Signature</span>
  </div>
}

function SignedSignatureDropdown({id}) {
  const instance_id = useSelector(({global: state}) => state.instance_id)
  const signature = useSelector(({form_signatures: state}) => state.signed_signatures[id])
  const user_id = useSelector(({ global: state }) => state.user.id)
  const required = useSelector(state => state.form_signatures.signatures[id].required)

  const permissions = usePermissionsChecks(['form:remove_any_signature'])

  const canRemove = user_id == signature.user_id || permissions['form:remove_any_signature']
  const time = signature.time ? fromUnixTime(signature.time) : null

  return <div className="signed-signature-dropdown">
    <div className="time" key="time">
      <i className="fa-light fa-signature"></i>
      <span>Signed {format(time, 'MM/dd/yy hh:mm aaa')}</span>
    </div>
    <div className="by" key="by">
      <i className="fa-light fa-user-check"></i>
      <span>{ signature.user_email }</span>
    </div>
    {canRemove ? <RemoveButton id={id} instance_id={instance_id} required={required} /> : null }
  </div>
}
