import React from 'react'
import PropTypes from 'prop-types'
import { compact } from 'lodash'

export default class SliderToggle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      on: props.onByDefault || false
    }
  }

  toggle() {
    const toggleTo = !this.state.on
    this.setState({ on: toggleTo })

    const callback = this.props.onChange
    const directCallback = this.props[toggleTo ? 'onToggleOn' : 'onToggleOff']

    callback ? callback(toggleTo) : null
    directCallback ? directCallback() : null
  }

  sliderOnStyling() {
    const { toggleOnBackgroundColor } = this.props,
          style = {}

    if (toggleOnBackgroundColor)
      style['backgroundColor'] = toggleOnBackgroundColor

    return style
  }

  sliderOffStyling() {
    return {}
  }

  renderInput() {
    if (!this.props.inputName)
      return null

    return <input type="checkbox" name={this.props.inputName} checked={this.state.on} onChange={() => {}} />
  }

  render() {
    const { on } = this.state

    const classes = compact([
      'utility-switch',
      `utility-switch-${on ? 'on' : 'off'}`,
      this.props.className
    ]).join(" ")

    const { onText, offText, bubbleIcon } = this.props
    const iconClass = bubbleIcon ? `utility-bubble-icon-${on ? 'on' : 'off'}` : ''
    return <div className={classes} onClick={() => this.toggle()}>
      { this.renderInput() }

      <span className={`utility-slider round ${iconClass}`} style={on ? this.sliderOnStyling() : this.sliderOffStyling()} />
      { onText ? <div className="on-text text"><div>{onText}</div></div> : null }
      { offText ? <div className="off-text text"><div>{offText}</div></div> : null}
    </div>
  }
}

SliderToggle.propTypes = {
  className: PropTypes.string,
  inputName: PropTypes.string,
  onText: PropTypes.string,
  offText: PropTypes.string,
  onChange: PropTypes.func,
  onToggleOn: PropTypes.func,
  onToggleOff: PropTypes.func,
  toggleOnBackgroundColor: PropTypes.string,
  onByDefault: PropTypes.bool
}
