import React from 'react'

import { formattedMoney } from 'components/insurance/get_quote/utils'

export default function FinalizeQuoteSidebarDetail({
  liabilityLimit,
  homeAndContentsDeductible,
  policyEffectiveDate,
  policyExpirationDate,
  contentsAmount,
  additionsAndAlterationsAmount,
  sidebarDetails,
}) {
  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' })
  }

  return (
    <>
      <div>
        <div className="dome-h4 dome-color-dark-green">your coverage:</div>
        <div className="dome-p2">
          Effective:{' '}
          <span className="dome-text-w300">{`${formatDate(policyEffectiveDate)}-${formatDate(policyExpirationDate)}`}</span>
        </div>
        <div className="dome-p2">
          Liability Limit: <span className="dome-text-w300">{formattedMoney(liabilityLimit)}</span>
        </div>
        <div className="dome-p2">
          Base Deductible: <span className="dome-text-w300">{formattedMoney(homeAndContentsDeductible)}</span>
        </div>
        <div className="dome-p2">
          Contents Amount: <span className="dome-text-w300">{formattedMoney(contentsAmount)}</span>
        </div>
        <div className="dome-p2">
          Dwelling Amount: <span className="dome-text-w300">{formattedMoney(additionsAndAlterationsAmount)}</span>
        </div>
      </div>
      <div className="sage-line my-18"></div>
      <div className="quote-estimate-details">
        <div className="dome-h4 dome-color-dark-green">information</div>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> property
          </summary>
          <div>{sidebarDetails.title}</div>
          <div>{sidebarDetails.address}</div>
          <div>{`${sidebarDetails.city}, ${sidebarDetails.state} ${sidebarDetails.zip}`}</div>
          <div>{`Type: ${sidebarDetails.buildingTypeDisplay}`}</div>
        </details>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> unit
          </summary>
          <div>{sidebarDetails.buildingUnit}</div>
          <div>{`${sidebarDetails.squareFootage?.toLocaleString()} sq.ft`}</div>
        </details>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> policy holder
          </summary>
          <div>{`${sidebarDetails.firstName} ${sidebarDetails.middleName} ${sidebarDetails.lastName}`}</div>
          <div>{sidebarDetails.phoneNumber}</div>
          <div>{sidebarDetails.email}</div>
          <div>{`Type: ${sidebarDetails.occupancy}`}</div>
        </details>
      </div>
    </>
  )
}
