import React, { Component } from "react"

export default class DisclaimerMessage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      checkedDisclaimerCheckbox: false
    }
  }

  updateDisclaimerCheckbox(checked) {
    this.setState({ checkedDisclaimerCheckbox: checked })
  }

  onCancel() {
    this.props.onCancel()
    this.setState({ checkedDisclaimerCheckbox: false })
  }

  renderCheckBoxIfOnBuildingPage() {
    return <>
      <input
        type="checkbox"
        id="disclaimer-checkbox"
        name="disclaimer-checkbox"
        checked={this.state.checkedDisclaimerCheckbox || false}
        onChange={(e) => this.updateDisclaimerCheckbox(e.target.checked)}
        className="accent-dark-green"
      />
      <label className="disclaimer-checkbox-label" htmlFor="disclaimer-checkbox">
        By checking this box, you are confirming you have read and agree to the Terms and Conditions above.
      </label>
    </>
  }

  renderModalButtons() {
    return <div className="disclaimer-building-buttons">
      <button className="btn-link cancel-link" onClick={() => this.onCancel()}>cancel</button>
      <button
        className={`btn-base ${this.state.checkedDisclaimerCheckbox ? 'btn-go-green' : 'btn-disabled'}`}
        disabled={!this.state.checkedDisclaimerCheckbox}
        onClick={() => this.props.onAgreed()}>
        continue
      </button>
    </div>
  }

  renderEditTextArea() {
    return <textarea
      className="super-questionnaire-disclaimer-textbox building-page"
      onChange={(e) => null}
      disabled={true}
      value={this.state.disclaimerText}
    >
    </textarea>
  }

  renderReadOnlyText() {
    return <div className="disclaimer-scroll">
      {this.props.disclaimer}
    </div>
  }

  renderModalHeaderAndTextDisplay() {
    return (
      <div className="questionnaire-disclaimer-header">
        <div className="header__title header__title--success header__title--gap">
          <i style={{ fontSize: "24px" }} className="fa-thin fa-circle-info"></i>
          <p> disclaimer </p>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderModalHeaderAndTextDisplay()}
        {this.renderReadOnlyText()}
        {this.renderCheckBoxIfOnBuildingPage()}
        {this.renderModalButtons()}
      </>
    )
  }
};
