import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import dataReducer from '../features/data/dataSlice'
import componentReducer from '../features/components/componentSlice'
import pageReducer from '../features/pages/pageSlice'
import toolReducer from '../features/tools/toolSlice'

const reducers = combineReducers({
  components: componentReducer,
  pages: pageReducer,
  tools: toolReducer,
  data: dataReducer,
})

const store = configureStore({reducer: reducers})

export default store
