import React from 'react'

function removeFromArray(array, item) {
  const idx = array.indexOf(item)
  if (idx >= 0)
    array.splice(idx, 1)

  return array
}

const is_empty = val => !val || String(val).match(/^\s*$/)
const is_email = val => String(val).match(/^.+@.+\..+$/)

class Preauthorized extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.state.users = {}
    for (let user of this.props.users || [])
      this.state.users[user.id] = user

    this.state.user_list = (this.props.users || []).map(user => user.id)
    this.state.recently_added = {}
  }

  addUser(email) {
    if (this.adding_user || this.state.adding_user)
      return

    this.setState({adding_user: (this.adding_user = true)})

    const url = `/store_document_request/preauthorize`
    const data = hashToPostQueryString({ email })

    $.ajax({
      url, data, type: "post",

      success: ({user}) => this.setState({
        users: { ...this.state.users, ...{[user.id]: user}},
        user_list: this.state.user_list.concat([user.id]),
        email: null,
        recently_added: {...this.state.recently_added, ...{[user.id]: "success"}}
      }),
      error: xhr => {
        const error = JSON.parse(xhr.responseText).error
        this.setState({error})
      },
      complete: () => this.setState({adding_user: (this.adding_user = null)})
    })
  }

  removeUser(user_id) {
    if (["pending", "error"].includes(this.state.recently_added[user_id]))
      return

    this.setState({
      recently_added: { ...this.state.recently_added, ...{[user_id]: "pending"}}
    })

    const success = () => { 
      const user_list = removeFromArray(this.state.user_list, user_id)
      const recently_added = {...this.state.recently_added}
      delete recently_added[user_id]
      this.setState({user_list, recently_added})
    }

    const url = `/store_document_request/deauthorize`
    const data = hashToPostQueryString({ user_id })
    $.ajax({
      url, data, type: "delete",

      success,
      error: xhr => {
        this.setState({
          recently_added: {...this.state.recently_added, ...{[user_id]: "error"}}
        })
      }
    })
  }

  render() {

    return <div className="store-document-request-preauthorized-users-component">
      {this.renderAddAUser()}
      {this.renderUsers()}
    </div>
  }

  renderAddAUser() {
    const updateEmail = email => {
      this.setState({email, error: null})
    }

    const addUser = () => {
      if (is_empty(this.state.email))
        return this.setState({error: "This field is required"})
      if (!is_email(this.state.email))
        return this.setState({error: "You must refer to the user by their email address"})

      this.addUser(this.state.email)
    }

    const error = this.state.error ? <div className="error">{this.state.error}</div> : null
    const button_disabled = this.state.adding_user || this.state.error || is_empty(this.state.email)
    const button_classes = ["btn", "btn-bper", "btn-block"]

    if (button_disabled)
      button_classes.push("disabled")

    return <div className="add-a-user">
      <label className="form-label">Add a User</label>
      <div>
        <input type="text" placeholder="Email" className="form-label" onChange={e => updateEmail(e.target.value)} />
        {error}
      </div>
      <button className={button_classes.join(" ")} onClick={addUser}>Add this User</button>
    </div>
  }

  renderUsers() {
    if (this.state.users.length == 0)
      return null

    return this.state.user_list.map(user_id => this.renderUser(user_id))
  }

  renderUser(user_id) {
    const user = this.state.users[user_id]

    const click = () => {
      swal({
        title: "Remove this User?",
        type: "warning",
        text: `Are you sure you want to remove ${user.full_name} as a preauthorized user?`,
        showCancelButton: true
      }, confirm => this.removeUser(user_id))
    }

    return <div className="user" key={user.id}>
      <div>
        <div className="name">{user.full_name}</div>
        <div className="email">{user.email}</div>
      </div>
      <button className="btn btn-danger btn-block" onClick={click}>Remove</button>
    </div>
  }
}

export default Preauthorized