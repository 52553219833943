import React from 'react'

class MarkAllButton extends React.Component {

  onAllReadClick(){
    this.props.handleAllReadClick()
  }

  render() {
    return(
        <button
          className="dome-btn dome-btn-base dome-btn-aqua-stroke"
          type='button'
          onClick={this.onAllReadClick.bind(this)}>
          mark all as read
        </button>
      )
  }
}

export default MarkAllButton
