import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { selectBuildingEditBuildingStructure } from '../store/slice'

import TableHead from 'components/domeui/table/TableHead'
import TableBody from 'components/domeui/table/TableBody'

const COLUMNS = [
  {
    label: 'unit',
    accessor: 'unit_number',
    classes: {
      th: ['col-xs-2'],
    },
  },
  {
    label: 'sq footage',
    accessor: 'square_footage',
    classes: {
      th: ['dome-text-right'],
      td: ['dome-text-right'],
    },
  },
  {
    label: 'bedrooms',
    accessor: 'bedroom_count',
    classes: {
      th: ['dome-text-right'],
      td: ['dome-text-right'],
    },
  },
  {
    label: 'bathrooms',
    accessor: 'bathroom_count',
    classes: {
      th: ['dome-text-right'],
      td: ['dome-text-right'],
    },
  },
  {
    label: 'owners',
    accessor: 'owner_count',
    classes: {
      th: ['dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },
  {
    label: 'renters',
    accessor: 'renter_count',
    classes: {
      th: ['dome-text-right'],
      td: ['dome-text-right'],
    },
    sortable: false,
  },

  // {
  //   label: "Created",
  //   accessor: "created_at",
  //   transformFn: (unit) => {
  //     const date = new Date(unit["created_at"]);
  //     return date.toLocaleString();
  //   },
  // },
]

const BuildingUnitsBody = ({ structureId, units, handleUnitsSorting, sortField, sortOrder, searchQuery }) => {
  const renderActions = useCallback(
    (unit) => {
      return (
        <a
          href={`/building_structures/${structureId}/building_units/${unit.id}`}
          className="dome-link dome-is-button dome-link-green-stroke dome-link-chevron-right"
        >
          open
        </a>
      )
    },
    [structureId]
  )

  return (
    <div className="building-units-body">
      <table className="dome-table dome-table-striped dome-full-width">
        <TableHead
          columns={COLUMNS}
          handleItemsSorting={handleUnitsSorting}
          sortField={sortField}
          sortOrder={sortOrder}
        />
        <TableBody
          columns={COLUMNS}
          items={units}
          renderActions={renderActions}
          emptyMessage={
            <span className="dome-color-lite-grey">
              {searchQuery?.length > 0
                ? 'Empty result'
                : 'Create a new unit or upload a .CSV to add units to this address.'}
            </span>
          }
        />
      </table>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const buildingStructureState = selectBuildingEditBuildingStructure(state)

  return {
    building: buildingStructureState.building,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingUnitsBody)
