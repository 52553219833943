import React from "react"

import PreviewModal from "./PreviewModal"
import BuildingModal from "./BuildingModal"

class FormRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPreviewModal: false,
      showBuildingModal: false,
      buildingIdList: [],
    }

    this.deleteForm = this.deleteForm.bind(this)
  }

  convertCreatedDateTime() {
    const date = new Date(this.props.form.created_at)
    return date.toLocaleDateString()
  }

  deleteForm(e, form) {
    e.persist()

    $.ajax({
      url: `/super/forms/${form.id}`,
      dataType: "json",
      type: "DELETE",
      success: this.props.handleDeleteForm(form),
    })
  }

  sweetAlertConfirmDelete(e) {
    const { form } = this.props
    swal(
      {
        title: "Are you sure",
        text: `Write "${form.name}" to confirm:`,
        type: "input",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
        inputPlaceholder: "Write something",
      },
      inputValue => {
        if (inputValue === form.name) {
          this.deleteForm(e, form)
        } else {
          swal.showInputError("Text does not match")
          return false
        }

        swal("Success", "Form is being deleted")
      }
    )
  }

  clickBuilding() {
    $.ajax({
      url: "/super/forms/get_building_ids/" + this.props.form.id,
      method: "GET",
      dataType: "JSON",
      context: this,
      success: function (data) {
        this.setState({
          buildingIdList: data.building_ids,
          showBuildingModal: true,
        })
      },
    })
  }

  renderPreviewModal(e) {
    e.preventDefault()
    this.setState({ showPreviewModal: true })
  }

  renderDeleteButton() {
    if (!this.props.formDeletionAllowed)
      return

    return (
      <a
        href='javascript:void(0)'
        title='Delete'
        onClick={e => this.sweetAlertConfirmDelete(e)}
      >
        <i className='fa fa-trash text-danger destroy-form-icon'></i>
      </a>
    )
  }

  render() {
    const { form, packageRequirements } = this.props
    const id = form.id
    return (
      <tr key={id}>
        <td data-title='Form #'>
          <div className='right-black'>{id}</div>
        </td>
        <td data-title='name'>
          <div className='right-black'>
            {form.name} - {form.form_slug}&nbsp;
            <span
              onClick={e => this.renderPreviewModal(e)}
              title={`Preview: ${form.name}`}
            >
              <i className='icon-search' style={{ cursor: "pointer" }}></i>
            </span>
          </div>
        </td>
        <td data-title='Packages Using This Form'>
          <div className='right-black'>{packageRequirements[id]}</div>
        </td>
        <td data-title='Date Created'>{this.convertCreatedDateTime()}</td>
        <td
          data-title='Actions'
          className='action-icons-container'
          style={{ display: "flex" }}
        >
          <a href={`/super/forms/${id}/edit`} title='Edit'>
            <i className='fa fa-edit'></i>
          </a>
          <form action={`/super/forms/${id}/copy`} method='POST'>
            <button
              type='submit'
              title='Copy'
              style={{ background: "none", padding: "0px", border: "none" }}
            >
              <i className='fa fa-copy'></i>
            </button>
          </form>
          { this.renderDeleteButton() } 
          <div className='right-black'>
            <span
              title={`Buildings using: ${form.name} `}
              onClick={() => this.clickBuilding()}
            >
              <i className='fa fa-building' aria-hidden='true'></i>
            </span>
          </div>
        </td>
        <td>
          {this.state.showPreviewModal ? (
            <PreviewModal
              onClose={() => this.setState({ showPreviewModal: false })}
              form={form}
            />
          ) : null}
          {this.state.showBuildingModal ? (
            <BuildingModal
              onClose={() => this.setState({ showBuildingModal: false })}
              form={form}
              buildingIds={this.state.buildingIdList}
            />
          ) : null}
        </td>
      </tr>
    )
  }
}

export default FormRow
