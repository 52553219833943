import React, { useState } from 'react'
import { Controller, useFieldArray, useFormContext, useFormState } from 'react-hook-form'
import EditableField from './EditableField'
import { Droppable, Draggable } from '@hello-pangea/dnd'

const PackageTemplateDocument = ({ dragProvided, doc, remove, index, requirement_index }) => {
  const { control } = useFormState()

  const removeDocument = () => {
    const confirmed = confirm('Are you sure you want to remove this document?')

    if (confirmed) {
      remove(index)
    }
  }

  return (
    <li className="document row" {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
      <div className="col-xs-12">
        <span className="handle pull-left" {...dragProvided.dragHandleProps}>
          <i className="fa fa-arrows"></i>
        </span>
        <Controller
          control={control}
          name={`package_template.package_requirements.${requirement_index}.package_template_documents.${index}.resource_name`}
          render={({ field: { onChange, value } }) => (
            <EditableField
              defaultValue={value}
              onSubmit={onChange}
              btnClasses={['btn-xs']}
              wrapperClasses={['dome-d-inline']}
            >
              <span>
                {value}
                <i className="fa fa-pencil"></i>
              </span>
            </EditableField>
          )}
        />
        : {doc.resource_type}
        <div className="actions pull-right" onClick={removeDocument} style={{ cursor: 'pointer' }}>
          <i className="fa fa-trash text-danger"></i>
        </div>
      </div>
    </li>
  )
}

const PackageTemplateDocuments = ({ fields, remove, requirement_index }) => {
  return (
    <>
      <Droppable
        droppableId={`requirement_documents_${requirement_index}`}
        direction="vertical"
        type={`requirement_documents_${requirement_index}`}
      >
        {(provided) => (
          <ul
            className="documents-list documents-container col-xs-12"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {fields.map((field, index) => (
              <Draggable key={field.fieldId} draggableId={`template_document-${field.fieldId}`} index={index}>
                {(provided) => (
                  <PackageTemplateDocument
                    dragProvided={provided}
                    doc={field}
                    index={index}
                    requirement_index={requirement_index}
                    remove={remove}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </>
  )
}

export default PackageTemplateDocuments
