import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";

function NonBreakingTextArea({ value, onChange, placeholder }) {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData("text/plain");
    if (text) {
      document.execCommand("insertText", false, text.replace(/(\r\n|\n|\r)/gm, ""));
    }
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current.style.height !== inputRef.current.scrollHeight) {
      inputRef.current.style.height = "inherit";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={inputRef}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      onInput={onChange}
      placeholder={placeholder}
      value={value}
      rows={1}
      style={{ resize: "none" }}
    ></textarea>
  );
}

NonBreakingTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default memo(NonBreakingTextArea);
