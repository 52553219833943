import React from 'react'

import { createSlice } from '@reduxjs/toolkit'
import { useSelector, Provider, useDispatch } from 'react-redux'
import { useCallback } from 'react'

import { intersection, pickBy } from 'lodash'

const initialState = {
  meta: {},
  order: [],
  lookups: {},
  last_id: -1,
}

function containTheSameElements(list_1, list_2) {
  const shared = intersection(list_1, list_2)

  if (shared.length != list_1.length)
    return false
  if (shared.length != list_2.length)
    return false

  return true
}

const Store = createSlice({
  name: "deal_parties",
  initialState,
  reducers: {
    loadDealParties(state, { payload: {meta, parties}}) {
      state.meta = meta
      state.order = parties.map()
    },

    setDealParty(state, { payload: deal_party}) {
      deal_party.id ||= state.last_id
      const lookups = { ... lookups, [deal_party.id]: deal_party }

      state.lookups = lookups

      if (deal_party.id == state.last_id) {
        state.order = state.order.concat([state.last_id])
        state.last_id -= 1
      }
    },

    reorderDealParties(state, { payload: deal_party_ids}) {
      if (containTheSameElements(state.order, deal_party_ids))
        state.order = deal_party_ids
    },

    removeDealParty(state, { payload: deal_party_id }) {
      const lookups = { ... state.lookups }
      delete lookups[deal_party_id]

      state.lookups = lookups
      state.order = state.order.filter(id => id != deal_party_id)
    }
  }
})

export const { loadDealParties,
               setDealParty,
               reorderDealParties,
               removeDealParty } = Store.actions

const store = configureStore({
  reducer: Store.reducer
})

export function useDealParties({role} = {}) {
  const partyFilter = useCallback(party => party.role == role, [role])

  const dealParties = useSelector(state => pickBy(state.lookups, partyFilter))

  return useSelector(state => intersection(state.order, Object.keys(dealParties)))
}

export function useDealPartyMeta(role) {
  return useSelector(state => state.meta[role])
}

function Loader({form_instance_id, children}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadDealParties({
      meta: {},
      parties: {}
    }))
  }, [form_instance_id])

  return <>{children}</>
}

function Watcher({onChange, children}) {
  const dealParties = useSelector(state => state.order.map(id => state.lookups[id]))

  // If the deal parties change, then we'll call onChange with them
  useEffect(() => {
    onChange(dealParties)
  }, [dealParties])

  return <>{children}</>
}

export default function(props) {
  return <Provider store={store}>
    <Loader form_instance_id={props.form_instance_id}>
      <Watcher onChange={props.onChange}>

      </Watcher>
    </Loader>
  </Provider>
}
