import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

function PaymentRequestSentModal(props) {
  const { open, onClose, email } = props;
  const modalRef = useWatchableRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal payment-request-sent-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-check fa-3x"></i>
            <div>
              <h3 className="modal-title -modal__title">Invoice Sent</h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="payment-request-sent__text-inform">Your invoice was sent to</div>
            <div className="payment-request-sent__text-building">{email}</div>
            <div className="payment-request-sent__text-detail">
              <span className="text-orange text-bold">Next Steps</span>
              <span>
                : You will receive an email notification when this invoice has been paid. Review status in the{" "}
              </span>
              <span className="text-bold">Resident Payments</span>
              <span> located on your Dashboard.</span>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-custom-cancel" data-dismiss="modal" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentRequestSentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default PaymentRequestSentModal;
