import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { reverseUniqueComponentKey } from '../utility'

const useSelectedDependentFields = () => {
  const selectedComponents = useSelector(({components}) => components.selected || [], shallowEqual)
  return selectedComponents.filter((key)=> ['name','time'].includes(reverseUniqueComponentKey(key).subtype))
}

export default useSelectedDependentFields
