import React from 'react'

class CommentThread extends React.Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    var objDiv = this.refs.comment_thread_ref;
    if (this.refs.comment_thread_ref) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  setReply(user) {
    this.props.selectCurrentUser(user);
  }

  renderReplyButton(comment) {
    const self_comment = comment.user_id == (this.props.commenterId || this.props.packageUser.user_id)

    if (self_comment || this.props.noDirectedMessages)
      return null

    const onClickReply = () => this.props.selectCurrentUser({
      id: comment.user_id,
      name: comment.user_name,
      thumbnail: comment.user_thumbnail
    })

    return <div onClick={onClickReply} className="comment-info pull-right">
      <span className="text-bopa">
        <i style={{ cursor: 'pointer' }} className="fa fa-reply" /> reply
      </span>
    </div>
  }

  renderComment(comment) {
    const shared_with_me = [this.props.filter, '', 'all', null].includes(comment.share_with)
    if ( this.props.filter != 'all' && !shared_with_me )
      return null

    const self_comment = comment.user_id == (this.props.commenterId || this.props.packageUser.user_id)

    const float_comment = ['dont-break-out', self_comment ? 'pull-right self-comment' : 'pull-left'].join(" ")

    const comment_info_text = <div className="comment-info pull-right">
      <span className="text-bopa">{comment.created_at}</span>
    </div>

    const sent_as_role = comment.sent_to_as_role != '' && !this.props.noDirectedMessages
    const sent_to_user = <div className="comment-header">
      <span className="text-bopa">
        { comment.user_name } { sent_as_role ? <i className="fa fa-angle-double-right" /> : null } { sent_as_role ? comment.sent_to_as_role : null }
      </span>
    </div>

    return <li className={float_comment} key={comment.id} >
      {this.renderReplyButton(comment)}
      {sent_to_user}
      <p>{comment.body}</p>
      {comment_info_text}
    </li>

  }

  render() {
    var comment_list = this.props.comments.map(function(comment) {
      return (
        this.renderComment(comment)
      )
    }.bind(this));
    if (comment_list.length > 0) {
      return (
        <div className="comment-thread-div">
        <div>
        <ul ref="comment_thread_ref" className="comment-thread well well-sm">{comment_list}</ul>
        </div>
        </div>
        )
    } else {
      return(
        <div><br /></div>
        );
    }
  }
}

export default CommentThread
