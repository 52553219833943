import React, { useCallback, useRef, useEffect, useState } from "react";
import { useWatchableRef } from "lib/hooks";
import StripeCard from "components/utility/stripe_card";
import { PAYMENT_STATUSES } from "./store/slice";
// import PropTypes from 'prop-types';

function InvoicePaymentModal(props) {
  const { open, onClose, onSubmit, total, cards, paymentStatus } = props;
  const modalRef = useWatchableRef(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const close = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", close);

    return () => $(localRefValue).off("hidden.bs.modal", close);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  const stripe_card = useRef();
  const onChangePaymentMethod = useCallback((cardPaymentMethodId) => {
    setPaymentMethodId(cardPaymentMethodId);
  });

  const handleSubmit = async () => {
    if (paymentMethodId !== "new-card") {
      return onSubmit({ paymentMethodId: paymentMethodId, paymentToken: null });
    }

    try {
      const paymentToken = await stripe_card.current.generateToken();
      onSubmit({ paymentMethodId: null, paymentToken: paymentToken });
    } catch (_) {
      // there's nothing to handle because generateToken() does not log any errors when failed
    }
  };

  const isProcessing = paymentStatus === PAYMENT_STATUSES.processing;

  const renderCancelButton = () => {
    return (
      <button
        className="btn btn-custom-cancel"
        data-dismiss="modal"
        disabled={isProcessing}
        onClick={onClose}
        style={{ float: "left" }}
      >
        Cancel
      </button>
    );
  };

  const renderSubmitButton = () => {
    const submitText = isProcessing ? (
      <>
        <span>Processing</span>&nbsp;
        <i className="fas fa-spinner-third fa-fw fa-1x fa-spin" style={{ "--fa-animation-duration": "1s" }}></i>
      </>
    ) : (
      "Submit Payment"
    );

    return (
      <button type="submit" className="btn btn-custom-submit" disabled={isProcessing} onClick={handleSubmit}>
        {submitText}
      </button>
    );
  };

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal invoice-payment-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Total Payment: {total}</h3>
          </div>
          <div className="modal-body">
            <StripeCard
              cards={cards}
              ref={stripe_card}
              onChangePaymentMethod={onChangePaymentMethod}
              hideCityField={true}
            />
          </div>
          <div className="modal-footer">
            {renderCancelButton()}
            {renderSubmitButton()}
          </div>
        </div>
      </div>
    </div>
  );
}

// InvoicePaymentModal.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   email: PropTypes.string.isRequired
// };

export default InvoicePaymentModal;
