import React, { useEffect } from 'react'
// import PropTypes from "prop-types";
import { connect } from 'react-redux'

import {
  selectStructureArchiveState,
  archiveStructure as archiveStructureAction,
  openStructureArchivedModal as openStructureArchivedModalAction,
  closeStructureArchiveModal as closeStructureArchiveModalAction,
  closeStructureArchivedModal as closeStructureArchivedModalAction,
} from './store/slice'

import StructureArchiveModal from './modals/StructureArchiveModal'
import StructureArchivedModal from './modals/StructureArchivedModal'

function StructureArchive(props) {
  const {
    structure,
    structureArchiveModalVisible,
    building,
    structureArchived,
    structureArchiving,
    errorMessages,
    structureArchivedModalVisible,
    archiveStructure,
    closeStructureArchiveModal,
    openStructureArchivedModal,
    closeStructureArchivedModal,
    onArchived,
  } = props

  const onSubmit = (data) => {
    const payload = { ...data, building_id: building?.id, id: structure.id }

    archiveStructure(payload)
  }

  const onCloseStructureArchivedModal = () => {
    if (typeof onArchived === 'function') {
      onArchived()
    }

    closeStructureArchivedModal()
  }

  useEffect(() => {
    if (structureArchived && structureArchiveModalVisible) {
      closeStructureArchiveModal()
      openStructureArchivedModal()
    }
  }, [structureArchived, structureArchiveModalVisible, closeStructureArchiveModal, openStructureArchivedModal])

  return (
    <>
      <StructureArchiveModal
        title="archive address"
        submitText="archive"
        open={structureArchiveModalVisible}
        onClose={closeStructureArchiveModal}
        onSubmit={onSubmit}
        submitting={structureArchiving}
        errorMessages={errorMessages}
        structure={structure}
        building={building}
      />
      <StructureArchivedModal
        open={structureArchivedModalVisible}
        onClose={onCloseStructureArchivedModal}
        address={structure?.default_additional_address?.address || ''}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    structure,
    structureArchiveModalVisible,
    building,
    structureArchived,
    structureArchiving,
    errorMessages,
    structureArchivedModalVisible,
  } = selectStructureArchiveState(state)

  return {
    structure,
    structureArchiveModalVisible,
    building,
    structureArchived,
    structureArchiving,
    errorMessages,
    structureArchivedModalVisible,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    archiveStructure: (payload) => dispatch(archiveStructureAction(payload)),
    openStructureArchivedModal: () => dispatch(openStructureArchivedModalAction()),
    closeStructureArchivedModal: () => dispatch(closeStructureArchivedModalAction()),
    closeStructureArchiveModal: () => dispatch(closeStructureArchiveModalAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StructureArchive)
