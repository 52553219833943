import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function NumberIncrementor(props) {
  const { value, onChange, min, disabled } = props;
  const step = 1;
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const canDecrement = currentValue - step >= min;
  const handleDecrement = () => {
    const newValue = currentValue - step;

    if (newValue >= min) {
      onChange(newValue);
      setCurrentValue(currentValue);
    }
  };

  const handleIncrement = () => {
    const newValue = currentValue + step;

    onChange(newValue);
    setCurrentValue(newValue);
  };

  return (
    <div className="number-incrementors">
      <button className="btn btn-link" onClick={handleDecrement} disabled={disabled || !canDecrement}>
        -
      </button>
      <span>{currentValue}</span>
      <button className="btn btn-link" onClick={handleIncrement} disabled={disabled}>
        +
      </button>
    </div>
  );
}

NumberIncrementor.defaultProps = {
  value: 0,
  min: 0,
  onChange: () => {},
  disabled: false,
};

NumberIncrementor.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
