import React from 'react'

export default function ContractorReportFormSuccess({ handleClick }) {
  return (
    <div className="dome-d-flex dome-flex-column dome-gap12">
      <div className="dome-d-flex dome-flex-column dome-gap6">
        <div className="dome-h4 dome-color-green-gradient">success!</div>
        <div className="dome-p2 dome-color-med-grey">
          We’ll verify if we’re able to create a contractor report, and then send you an invoice letting you know your
          next steps.
        </div>
      </div>
      <div className="dome-d-flex dome-justify-end">
        <button type="button" className="btn-base btn-grey-stroke" onClick={handleClick}>
          OK
        </button>
      </div>
    </div>
  )
}
