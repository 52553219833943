import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { archiveStructureApi } from "./apis";

const initialState = {
  structureArchiveModalVisible: false,
  building: null,
  structure: null,
  structureArchived: false,
  structureArchiving: false,
  errorMessages: [],
  structureArchivedModalVisible: false,
};

export const archiveStructure = createAsyncThunk("buildingStructuresArchiveStructure/archiveStructure", async (data, thunkAPI) => {
  try {
    const response = await archiveStructureApi(data.building_id, data.id, data);
    const { building_structure } = response.data;
    return { building_structure };
  } catch (err) {
    if (err?.response?.data) {
      return thunkAPI.rejectWithValue(err.response.data);
    } else {
      throw err;
    }
  }
});

const structureArchiveSlice = createSlice({
  name: "buildingStructuresArchiveStructure",
  initialState,
  reducers: {
    openStructureArchiveModal: (state, action) => {
      if (action.payload) {
        const { building, building_structure } = action.payload;

        state.building = building;
        state.structure = building_structure;
      }

      state.structureArchiveModalVisible = true;
    },
    closeStructureArchiveModal: (state, action) => {
      state.structureArchiveModalVisible = false;
      state.errorMessages = [];
    },
    openStructureArchivedModal: (state, action) => {
      state.structureArchivedModalVisible = true;
    },
    closeStructureArchivedModal: (state, action) => {
      state.structureArchivedModalVisible = false;
      state.structureArchived = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(archiveStructure.pending, (state, action) => {
      state.structureArchiving = true;
    });
    builder.addCase(archiveStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload;

      state.structure = building_structure;
      state.structureArchiving = false;
      state.structureArchived = true;
    });
    builder.addCase(archiveStructure.rejected, (state, action) => {
      if (action.payload?.error_messages) {
        const { error_messages } = action.payload;
        state.errorMessages = error_messages;
      } else {
        state.errorMessages = ["Some errors happened, please try again!"];
      }
      state.structureArchiving = false;
    });
  },
});

export const selectStructureArchiveState = (state) => state.buildingStructuresArchiveStructure;

export const {
  setInitialState,
  openStructureArchiveModal,
  closeStructureArchiveModal,
  openStructureArchivedModal,
  closeStructureArchivedModal,
} = structureArchiveSlice.actions;

export const buildingStructuresArchiveStructureReducer = structureArchiveSlice.reducer;
