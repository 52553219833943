import { configureStore } from '@reduxjs/toolkit'
import { listenerMiddleware } from 'components/global/listener'
import insuranceQuoteInterstitialReducer from '../get_quote/slice/slice'
import instantInterstitialReducer from '../instant_interstitial/slice/slice'
import copyOverridesReducer from '../get_quote/slice/overridesSlice'

export const store = configureStore({
  reducer: {
    insuranceQuoteInterstitial: insuranceQuoteInterstitialReducer,
    instantInterstitial: instantInterstitialReducer,
    copyOverrides: copyOverridesReducer,
  },
  middleware: (createListenerMiddleware) => createListenerMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === 'development',
})
