import React, { createContext, useState, useEffect } from "react"
import { useEventListeners } from '@deathbyjer/react-event-manager'

const LeaseContext = createContext()

const LeaseContextProvider = ({ userRole, hasBeenShared, buildingShortAddress, electronicSigningConsentedAt: eSignatureSignedAt, remainingRequiredSignatureIdsByRole, signaturesLeft, allRequiredSignaturesSigned, documentsFinished, permissions, userRoles, notifiedReadyToShare, children }) => {
  const [electronicSigningConsentedAt, setElectronicSigningConsentedAt] = useState(eSignatureSignedAt)

  const defaultRemainingRequiredSignatureIds = userRoles && remainingRequiredSignatureIdsByRole ? userRoles.map(role => remainingRequiredSignatureIdsByRole[role]).flat() : []

  const [remainingRequiredSignatureIds, setRemainingRequiredSignatureIds] = useState(defaultRemainingRequiredSignatureIds)
  const [remainingSignaturesLeft, setRemainingSignaturesLeft] = useState(Object.values(signaturesLeft ?? {}).reduce((sum, value) => sum + value, 0))

  // check if all required signatures are signed
  useEventListeners({
    'signatureAdded': ({signature_id, required}) => { required ? removeRemainingRequiredSignature(signature_id) : null },
    'signatureDeleted': ({ signature_id, required }) => { required ? addRemainingRequiredSignature(signature_id) : null }
  })

  const removeRemainingRequiredSignature = signature_id => {
    const filtered = remainingRequiredSignatureIds.filter(remainingSignatureId => remainingSignatureId !== signature_id)
    setRemainingRequiredSignatureIds(filtered)
    setRemainingSignaturesLeft(prevCount => prevCount - 1)
  }

  const addRemainingRequiredSignature = signature_id => {
    setRemainingRequiredSignatureIds([...remainingRequiredSignatureIds, signature_id])
    setRemainingSignaturesLeft(prevCount => prevCount + 1)
  }

  const value = {
    userRole,
    hasBeenShared,
    documentsFinished,
    electronicSigningConsentedAt,
    setElectronicSigningConsentedAt,
    remainingRequiredSignatureIds,
    setRemainingRequiredSignatureIds,
    allRequiredSignaturesSigned,
    remainingSignaturesLeft,
    permissions,
    userRoles,
    buildingShortAddress,
    notifiedReadyToShare
  }

  return (
    <LeaseContext.Provider value={value}>
      {children}
    </LeaseContext.Provider>
  )
}

export { LeaseContext, LeaseContextProvider }
