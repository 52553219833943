import React, { useCallback } from 'react'
import { useEventManager } from '@deathbyjer/react-event-manager'
import useSelectedKeys from '../../hooks/useSelectedKeys'

const Item = ({ component, activeTool }) => {
  const selectedKeys = useSelectedKeys()
  const events = useEventManager()

  const handleClick = useCallback(() => {
    events.applyEventListeners('selectComponent', component.keyArray)
    events.applyEventListeners(`scrollToField`,component.page)
  }, [component.keyArray, component.page, events])

  return (
    <div onClick={handleClick} className={`item field ${selectedKeys.includes(component.key) ? 'active' : ''}`}>
      <div className='icon'>
        <i className='fa-light fa-credit-card-blank'></i>
      </div>
      <div className="field-name">
        <p>{component.label}</p>
      </div>
    </div>
  )
}

export default Item
