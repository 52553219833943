import React, { useLayoutEffect, useRef, useState } from 'react'

export default function Input({ value, onChange, onSubmit }) {
  // Hold information of current cursor to keep cursor between renders
  const [cursor, setCursor] = useState(null)

  // Check if input has been touched before
  const [touched, setTouched] = useState(false)

  const ref = useRef(null)

  const handleChange = (e) => {
    if (!touched) setTouched(true)
    setCursor(e.target.selectionStart)
    onChange(e.currentTarget.value)
  }

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault()
      onSubmit()
    }
  }

  useLayoutEffect(() => {
    const input = ref.current

    if (input) {
      if (touched) input.focus()
      input.setSelectionRange(cursor, cursor)
    }
  }, [cursor, value])

  return (
    <input
      ref={ref}
      type="text"
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className="form-control"
    />
  )
}
