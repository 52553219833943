import React from 'react'

import Edit from './index'
import Row from './row'
import UploadNew from './upload_new'

import CollapsiblePanel from 'components/utility/collapsible_panel'

class EditButton extends React.Component {
    constructor(props) {
      super(props)

      this.state = {}
      this.state.deleting = {}
      this.state.documents = this.props.documents
    }

    clickEdit(id) {
      if (this.edit_component)
        this.edit_component.open(id)
    }

    clickDelete(id) {
      const deleting = Object.assign({}, this.state.deleting)

      deleting[id] ? (delete deleting[id]) : (deleting[id] = true)

      this.setState({deleting: deleting})
    }

    checkDeleting() {
      if (this.is_deleting)
        return true

      this.setState({is_deleting: (this.is_deleting = true)})
      return false
    }

    releaseDeleting() {
      this.setState({is_deleting: null})
      delete this.is_deleting
    }

    delete() {
      if (Object.keys(this.state.deleting).length == 0)
        return

      if (this.checkDeleting())
        return

      const url = "/building_store_documents/destroy_multiple"
      const data = {
        building_id: this.props.building_id,
        document: this.state.deleting
      }

      $.ajax({
        url,
        type: "delete",
        data,
        dataType: 'json',
        complete: () => this.releaseDeleting(),
        success: () => this.successfullyDeleted()
      })
    }

    successfullyDeleted() {
      const swal_options = {
        title: "Removal Completed",
        text: `You successfully deleted ${Object.keys(this.state.deleting).length} documents`,
        type: "success"
      }
      swal(swal_options)

      const documents = this.state.documents.concat([])
      let doc_id
      for (let i = documents.length - 1; i >= 0; i--)
        if (this.state.deleting[documents[i].id])
          documents.splice(i, 1)

      this.setState({documents, deleting: {}})
    }

    reload() {
      $.ajax({
        url: `/building_store_documents/valid_building_store_documents`,
        data: {building_id: this.props.building_id},
        dataType: 'json',
        method: 'get',
        success: (data) => {
          let documents = data.valid_building_store_documents
          this.setState({documents})
        }
      })
    }

    openFileManagerModal() {
      const events = {
        closed: () => this.reload()
      }

      window.documents_manager_component.open(events)
    }

    documentsByClass() {
      const documents = {}

      let doc
      let file_class

      for (const propDoc in this.state.documents)
        documents[this.state.documents[propDoc].file_class] = []

      for (const doc of this.state.documents)
        documents[documents[doc.file_class] ? doc.file_class : "other"].push(doc)

      for (const file_class in documents)
        if (documents[file_class].length == 0)
          delete documents[file_class]

      return documents
    }


    renderPanel(file_class, documents) {
      const title = (file_class.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")) || "Other"

      const rows = documents.map(doc => {
        return <Row key={doc.id}
                      document={doc}
                      can_delete={this.props.can_delete}
                      is_deleting={this.props.is_deleting}
                      deleting={this.state.deleting[doc.id]}
                      clickEdit={() => this.clickEdit(doc.id)}
                      clickDelete={() => this.clickDelete(doc.id)} />
      })

      return <CollapsiblePanel key={file_class} title={title}>
        <table className="table table-responsive edit-accordion">
          <tbody>
            {rows}
          </tbody>
        </table>
      </CollapsiblePanel>
    }

    renderHeaderButtons() {
      if (!this.props.can_delete)
        return null

      const deleting_disabled = Object.keys(this.state.deleting).length == 0 || this.state.is_deleting
      const deletion_classes = ["dome-btn", "dome-btn-base", "dome-btn-grey-stroke", "pull-right"]
      if (deleting_disabled)
        deletion_classes.push("disabled")

      return <div>
        <button className={deletion_classes.join(" ")} disabled={deleting_disabled} onClick={e => this.delete()}>
          <i className="fa fa-trash"></i> delete selected
        </button>
        <button className="dome-btn dome-btn-base dome-btn-dark-green" data-toggle="modal" onClick={() => this.openFileManagerModal()}>File Manager</button>
      </div>
    }

    renderAllPanels() {
      const docs = this.documentsByClass()

      return <div className="panel-group" id="accordionDocuments" role="tablist" aria-multiselectable="true">
        {Object.keys(docs).map(file_class => this.renderPanel(file_class, docs[file_class])) }
      </div>
    }

    addedNewDocument(data) {
      const documents = this.state.documents.concat([])
      documents.push(data)
      this.setState({documents})
    }

    updateDocument(document) {
      const documents = this.state.documents.concat([])
      for (let i in documents)
        if (documents[i].id == document.id) {
          documents.splice(i, 1, document)
          break
        }

      this.setState({documents})
    }

    render() {
      const document_types = Object.keys(this.props.file_classes).map(key => [key, this.props.file_classes[key]])

      const onMount = component => this.edit_component = component
      const onUnmount = () => delete this.edit_component
      const onUpdate = document => this.updateDocument(document)

      return <div className="edit-store-documents">
        <UploadNew building_id={this.props.building_id} onSuccess={doc => this.addedNewDocument(doc)} />
        {this.renderHeaderButtons()}
        {this.renderAllPanels()}
        <Edit onMount={onMount} onUnmount={onUnmount} onUpdate={onUpdate} file_settings={this.props.file_settings} document_types={document_types} />
      </div>
    }
  }

  export default EditButton
