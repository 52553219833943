import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import BeaconSlider from './slider'
import { Portal } from 'components/utility/portal'

const withBeaconModal = (WrappedComponent) => {
  return function (props) {
    const { isShow, onClose } = props

    const [show, setShow] = useState(isShow)
    const overlayRef = useRef(null)

    useEffect(() => {
      setShow(isShow)
    }, [isShow, setShow])

    const closeModal = () => {
      setShow(false)
      onClose()
    }

    const hideOverlay = (e) => {
      if (e.target === overlayRef.current) closeModal()
    }

    return (
      <Portal>
        <div className={clsx('new-beacon-modal', show && 'show')}>
          <div
            ref={overlayRef}
            onClick={hideOverlay}
            className="dome-full-width dome-full-height dome-d-flex dome-align-center dome-justify-center"
          >
            <WrappedComponent {...props} show={show} closeModal={closeModal} />
          </div>
        </div>
      </Portal>
    )
  }
}

export default withBeaconModal
