import React, { useEffect } from 'react'
import { useWatchableRef } from 'lib/hooks'
import { Portal } from 'components/utility/portal'

export default function ConsumerDisclosureStatementModal({ id, open, onClose }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <Portal>
      <div ref={modalRef.ref} id={id} className="modal fade consumer-disclosure-statement-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content new-brp-modal">
            <div className="modal-header">
              <div className="dome-h2 dome-color-dark-green">consumer disclosure statement</div>
            </div>
            <div className="modal-body">
              <div className="dome-p2">
                To provide an accurate quote, we will obtain information about you and any household member from
                consumer reporting agencies and other third parties where permitted by law. In connection with this
                application for insurance, we will review your credit report or use a credit-based insurance score on
                the information contained in your credit report (in applicable states), driving and claim histories and
                other consumer reports. We will use this information to underwrite and/or rate your policy.
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="dome-btn dome-btn-link dome-btn-link-cancel pull-left dome-color-med-grey"
                data-dismiss="modal"
                onClick={onClose}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
