import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useModal } from 'lib/hooks'
import { swrFetcher } from 'lib/axios'
import { debounce } from 'lodash-es'
import useSWR from 'swr'
import UploadArea from 'components/utility/upload_area'

const generateRequestURL = (baseUrl, options) => {
  const urlParams = new URLSearchParams(options)

  return `${baseUrl}?${urlParams.toString()}`
}

export default function MyDocumentsModal({ open, onClose, buildingId, fetchUrl, onAttachDocument, onAttachFiles }) {
  const { modalRef } = useModal({ open, onClose })

  const [myDocuments, setMyDocuments] = useState({ items: [], forms: [], notice: null })

  const [keyword, setKeyword] = useState('')
  const debounceSetKeyword = useMemo(() => debounce(setKeyword, 500), [setKeyword])
  const updateSearchKeyword = useCallback((e) => debounceSetKeyword(e.currentTarget.value), [debounceSetKeyword])

  const queryKey = useMemo(() => {
    const options = { building_id: buildingId }
    if (keyword.trim().length > 0) options['search_by_label'] = keyword.trim()

    return open ? generateRequestURL(fetchUrl, options) : null
  }, [open, buildingId, keyword])

  const { data, mutate } = useSWR(queryKey, swrFetcher, { keepPreviousData: true })

  useEffect(() => {
    if (data) {
      setMyDocuments((prev) => ({ ...prev, items: data.items, forms: data.forms }))
    }
  }, [data])

  const attachDocumentClicked = (docId) => onAttachDocument('document', docId)
  const attachFormClicked = (formId) => onAttachDocument('form', formId)
  const handleAttachFile = (files) => {
    onAttachFiles(files)
  }

  return (
    <div ref={modalRef.ref} id="my-documents-container" className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Add Document to Requirement</h4>
          </div>
          <div className="modal-body">
            {myDocuments.notice && (
              <div className={`alert alert-${myDocuments.notice.type} break-word`}>{myDocuments.notice.text}</div>
            )}
            <UploadArea
              inputChanged={(files) => onAttachFiles(files)}
              exclude_icons={false}
              text="Drop files here to upload, or click to browse."
              valid_types={[
                'image/gif',
                'image/jpeg',
                'image/jpeg',
                'image/png',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
            />
            <h6>Search by name</h6>
            <input className="form-control" id="label_search_bar" onChange={updateSearchKeyword} />
            {myDocuments.items.map((doc) => {
              return (
                <div className="document" key={doc.id}>
                  <b>Document - </b>
                  {doc.label}
                  <br />
                  <b> from: </b>
                  Library
                  <button
                    type="button"
                    className="btn btn-default btn-xs pull-right"
                    onClick={() => attachDocumentClicked(doc)}
                  >
                    <i className="fa fa-paperclip "></i>
                  </button>
                </div>
              )
            })}
            {myDocuments.forms.map((form, index) => {
              return (
                <div className="document" key={index}>
                  <b>Form - </b>
                  {form.name}
                  <br />
                  <b>Description: </b>
                  {form.description}
                  <button
                    type="button"
                    className="btn btn-default btn-xs pull-right"
                    onClick={() => attachFormClicked(form)}
                  >
                    <i className="fa fa-paperclip "></i>
                  </button>
                </div>
              )
            })}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
