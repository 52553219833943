import React from 'react';
import BuildingResidentTableRow from './BuildingResidentTableRow';

export default function BuildingResidentTable({ residents }) {
  if (residents.length === 0) return;

  return (
    <>
      <table className='dome-table dome-table-striped dome-table-tight dome-full-width'>
        <thead>
          <tr>
            <th width={'20%'}>first name</th>
            <th width={'20%'}>last name</th>
            <th width={'30%'}>email address</th>
            <th width={'25%'}>phone number</th>
            <th width={'5%'}></th>
          </tr>
        </thead>
        <tbody>
          {residents.map((resident, index) => (
            <BuildingResidentTableRow key={index} resident={resident}/>
          ))}
        </tbody>
      </table>
    </>
  )
}
