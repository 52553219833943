import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import StructureEdit from 'components/buildings/edit/StructureEdit'
import { openStructureEditModal } from 'components/buildings/edit/StructureEdit/store/slice'
import StructureArchive from 'components/buildings/edit/StructureArchive'
import { openStructureArchiveModal } from 'components/buildings/edit/StructureArchive/store/slice'

export default function ({ building, buildingStructure, onArchived, archiveVisible }) {
  const dispatch = useDispatch()
  const [openMenu, setOpenMenu] = useState(false)
  const menuDropdownRef = useRef(null)

  const onAction = (event) => {
    const target = event.target
    if (target.tagName !== 'LI') return
    setOpenMenu(false)
    const key = target.getAttribute('data-name')
    switch (key) {
      case 'edit':
        dispatch(openStructureEditModal({ building, building_structure: buildingStructure }))
        break
      case 'archive':
        dispatch(openStructureArchiveModal({ building, building_structure: buildingStructure }))
        break
      default:
        break
    }
  }

  const handleMenuToggle = () => setOpenMenu((prev) => !prev)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuDropdownRef.current && !menuDropdownRef.current.contains(event.target)) setOpenMenu(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (buildingStructure?.default) {
    return null
  }

  return (
    <>
      <div ref={menuDropdownRef} className="dome-relative">
        <button onClick={handleMenuToggle} type="button" className="dome-react-dropdown-trigger dome-cursor-pointer">
          manage address <i className="fa-solid fa-chevron-down"></i>
        </button>
        {openMenu && (
          <ul onClick={onAction} className="manage-address-menu">
            <li data-name="edit" className="manage-address-menu-item">
              edit
            </li>
            {archiveVisible && (
              <li data-name="archive" className="manage-address-menu-item">
                archive
              </li>
            )}
          </ul>
        )}
      </div>
      <StructureEdit />
      <StructureArchive onArchived={onArchived} />
    </>
  )
}
