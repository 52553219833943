import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

const Navbar = props => {
  const availableTabs = ["Payments"]

  function setCurrentTab(tabName) {
    props.setAppState({ activeTab: tabName })
  }

  function renderNavItemList() {
    let list = []

    for (let i=0; i < availableTabs.length; i++) {
      const tabName = availableTabs[i]
      list.push(
        <div key={tabName}
          onClick={() => setCurrentTab(tabName)}
          className={`nav-item ${props.activeTab == tabName ? "active" : ""}`}>
          { tabName }
        </div>
      )
    }

    return list
  }

  return <div className="navbar row">
    { renderNavItemList() }
  </div>
}

Navbar.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setAppState: PropTypes.func.isRequired
}

export default Navbar
