import React, { useState } from 'react'

const SignerFormFields = (props) => {
  return (
    <div className='form-items'>
      <div className='form-group'>
        <label className='email-label' htmlFor='email'>
          Email<span className='required'>&nbsp;*</span>
        </label>
        {props.errors && (
          <span className='error'>{props.errors?.signer?.email?.message}</span>
        )}
        <input
          id='email'
          className={`form-control ${props.errors?.signer?.email ? "error-field" : ""
            }`}
          type='text'
          {...props.register("signer[email]", {
            pattern: {
              value: /^.+@.+\..+$/,
              message: "Enter a valid email address.",
            },
            required: "Email required",
          })}
        />
      </div>
      <button
        type='submit'
        className={`btn green pull-right ${props.isValid ? "all-valid" : ""
          }`}
      >
        Add Signer
      </button>
    </div>
  )
}

export default SignerFormFields
