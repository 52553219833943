import React, { useCallback, useEffect, useId, useMemo, useState } from 'react'
import UploadArea from 'components/utility/upload_area'
import clsx from 'clsx'
import axios from 'axios'

const InputField = ({ label, value, onChange, ...props }) => {
  const inputId = useId()
  return (
    <div className="dome-d-flex dome-gap3 dome-flex-column">
      <label htmlFor={inputId} className="dome-p3 dome-text-w400 dome-color-med-grey">
        {label}
      </label>
      <input id={inputId} value={value} onChange={onChange} {...props} />
    </div>
  )
}

const SelectField = ({ label, name, options, onChange }) => {
  return (
    <div className="dome-d-flex dome-gap3 dome-flex-column">
      <label className="dome-p3 dome-text-w400 dome-color-med-grey">{label}</label>
      <select onChange={onChange}>
        {options.map(({ id, label }) => (
          <option key={id} value={id}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

const FileDisplayArea = ({ file, onRemoveFile }) => {
  if (!file) return null
  const originalFileName = file?.name

  const formatFileSize = useMemo(() => {
    var size = file.size
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(size) / Math.log(k))

    return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
  }, [file])

  return (
    <div className="form-builder-uploaded-file dome-d-flex dome-justify-between dome-bg-fill">
      <div className="dome-d-flex dome-gap12 dome-align-center">
        <i className="fa-thin fa-magnifying-glass"></i>
        <span className="dome-p3 dome-color-darkest-grey">{originalFileName}</span>
        <span className="dome-p3 dome-color-darkest-grey">{formatFileSize}</span>
      </div>
      <span className="dome-color-red-alert dome-d-flex dome-align-center dome-cursor-pointer">
        <i onClick={onRemoveFile} className="fa-light fa-times delete-file-icon"></i>
      </span>
    </div>
  )
}

const CreatingFormBuilder = ({ organizations, hideOverlay }) => {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formName, setFormName] = useState('')
  const [organizationId, setOrganizationId] = useState(0)

  const organizationElements = useMemo(() => organizations.map(([label, id]) => ({ id, label })), [organizations])

  // Set the default value for organization
  useEffect(() => {
    if (organizationElements.length > 0) setOrganizationId(organizationElements[0].id)
  }, [organizationElements])

  const addFile = (newFile) => setFile(newFile[0])

  const removeFile = () => setFile(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const form = { name: formName, organization_id: organizationId, pdf_file: file.file }
      const formData = new FormData()
      Object.entries(form).map(([key, value]) => {
        formData.append(`form[${key}]`, value)
      })
      const response = await axios.post('/forms/v3/', formData);
      await pollStatus(response.data.form_id);
    } catch (error) {
      console.error('Submission error:', err);
      setLoading(false);
    }
  }

  const pollStatus = async (formId) => {
    try {
      const { data } = await axios.get(`/forms/v3/upload_status/${formId}`);
      handleStatus(data.status, formId, async () => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        await pollStatus(formId);
      });
    } catch (err) {
      console.error('Polling error:', err);
      setLoading(false);
    }
  };

  const handleStatus = useCallback((status, formId, continuePoll) => {
    switch (status.state) {
      case 'failure':
        throw new Error(status.error)
      case 'complete':
        setLoading(false)
        window.location.replace(`/forms/v3/${formId}/form_builder`)
        break
      default:
        continuePoll()
        break
    }
  }, [])

  const disabledButton = !file || !formName || !organizationId

  return (
    <form onSubmit={handleSubmit} className="dome-d-flex dome-gap24 dome-flex-column">
      <div className="dome-h2 dome-color-dark-green dome-text-w500">create new form</div>
      <InputField label="form name*" value={formName} onChange={(e) => setFormName(e.target.value)} />
      <SelectField
        label="organization name*"
        value={organizationId}
        options={organizationElements}
        onChange={(e) => setOrganizationId(e.target.value)}
      />
      <div className="dome-d-flex dome-gap6 dome-flex-column">
        <div className="dome-p3 dome-text-w400">upload form*</div>
        {!file ? (
          <UploadArea inputChanged={addFile} exclude_icons={true}>
            <div className="dome-d-flex dome-gap12 dome-align-center">
              <div className="file-icons">
                <i className="fa-thin fa-cloud-arrow-up"></i>
              </div>
              <div className="dome-p3 dome-text-w400">
                <div>drop files here to upload,</div>
                <div>or click to browse.</div>
              </div>
            </div>
          </UploadArea>
        ) : (
          <FileDisplayArea file={file} onRemoveFile={removeFile} />
        )}
      </div>
      <div className="dome-d-flex dome-justify-between">
        <button
          type="button"
          onClick={hideOverlay}
          className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
        >
          back
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={clsx('btn-base', disabledButton ? 'btn-disabled' : 'btn-dark-green')}
        >
          {loading ? <i className="fa-duotone fa-spinner fa-spin-pulse"></i> : 'build form'}
        </button>
      </div>
    </form>
  )
}

export default CreatingFormBuilder
