import React from 'react'

import MarkNotificationButton from './mark_notification_button'
import Notification from './notification'

class BuildingRow extends React.Component {
  render () {
    const notifications = this.props.building.notifications.map(notification => <Notification notification={notification} key={notification.notification.id} />)

    return (
      <div className='notification-row notification-building-container'>
        <div>
          <h3 className="notification-title dome-h3">
            {this.props.building.formatted_address}
          </h3>
        </div>
        <div className='notification-button-building-container'>
            <MarkNotificationButton
              handleAllReadClick={ this.props.handleAllReadClick }
              notifications={ this.props.building.notifications }
              fetchSpecificDate={this.fetchSpecificDate}
            />
        </div>
        <div>
          {notifications}
        </div>
      </div>
    )
  }
}

export default BuildingRow
