import React, {useMemo, useCallback, useState, useContext, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { Modal } from 'react-bootstrap'
import FormFromTemplate, { API as TemplateAPI } from 'components/form_from_template'
import { metadataByPath, pathMapToHash, valueByPath } from 'lib/utilities/form'

import { closeEditPerson, store } from './state'
import { checkForRequiredValues } from 'components/form_from_template'

const DataContext = React.createContext({})

function useApiPathMap(pathMap) {
  const { updates, setUpdates, errors } = useContext(DataContext)

  const values = useMemo(() => ({
    ...pathMap,
    ...updates
  }), [updates, pathMap])

  const hashValues = useMemo(() => pathMapToHash(values), [values])

  const getValue = useCallback(path => values[path], [values])
  const getValueHash = useCallback(path => valueByPath(hashValues, path), [hashValues])
  const getError = useCallback(path => errors[path])

  const updateValues = useCallback(hash => {
    setUpdates({
      ...updates,
      ...hash
    })
  }, [updates, setUpdates])

  return useMemo(() => ({
    getValue,
    getValueHash,
    getError,
    updateValues
  }), [getValue, getValueHash, updateValues])
}

function useTemplateOverrides({metadata, pathMap}) {
  const allValues = useSelector(({roles}) => roles.values) || {}
  const api = useApiPathMap(pathMap)
  
  return useMemo(() => ({
    ...TemplateAPI,
    getField(path) { return metadataByPath(metadata, path)  },
    ...api,
    globalPathmap: allValues
  }), [metadata, allValues, api])
}

function findErrors({template, values}) {
  const errors = {} 
  for (let path of checkForRequiredValues({ values, template }))
    errors[path] = "required"

  return errors
}

function Edit({ template, overrides, onSave, values, editing = false }) {
  const [ show, setShow ] = useState(true)
  const { updates, setErrors, clear } = useContext(DataContext)
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    clear()
    dispatch(closeEditPerson())
  }, [dispatch, clear])
  
  const close = useCallback(() => setShow(false), [setShow])
  const mergedValues = useMemo(() => ({ 
    ...values,
    ...updates
  }), [values, updates])

  const handleSave = useCallback(() => {
    const errors = findErrors({template, values: mergedValues})
    setErrors(errors)

    if (!isEmpty(errors))
      return 

    onSave(updates)
    close()
  }, [onSave, updates, mergedValues, close, setErrors, template])

  return <Modal show={show} centered={true} onExited={onClose} className="package-assign-roles-edit-person-modal">
    <Modal.Body>
      <FormFromTemplate overrides={overrides} template={template} />
    </Modal.Body>
    <Modal.Footer>
      <div className="buttons">
        <button className="btn" onClick={close}>Cancel</button>
        <button className="btn btn-success" onClick={handleSave}>{ editing ? "Update" : "Create"}</button>
      </div>
    </Modal.Footer>
  </Modal>
}

function useSavePerson({person_id, party_id, deal_party_id}) {
  const dispatch = useDispatch()
  return useCallback(updates => {
    const action = person_id ? "editDataPerson" : "addDataPerson"
    const payload = {
      party_id,
      deal_party_id,
      person_id,
      data: updates
    }

    dispatch(store.actions[action](payload))
  }, [dispatch, person_id, deal_party_id])
}

function EditPerson({party_id, person_id, role_id, deal_party_id }) {
  const party = useSelector(({roles}) => roles.deal_party_metadata[party_id])
  const metadata = useSelector(({roles}) => roles.metadata[party.data.path]?.fields || {})
  const pathMap = useSelector(({roles}) => roles.data_people[person_id]) || {}

  const fields = useSelector(({roles}) => roles.deal_party_metadata[party_id]?.fields) || {}
  const overrides = useTemplateOverrides({metadata, pathMap})

  const template = useMemo(() => ({ fields }), [ fields ])

  const save = useSavePerson({person_id, party_id, deal_party_id})

  return <Edit values={pathMap} overrides={overrides} template={template} onSave={save}  editing={!!person_id} />
}

const ROLE_METADATA = {
  first_name: {
    type: "string",
    label: "First Name",
    order: 1,
    size: 5,
    form_field: {
      type: "text"
    }
  },
  last_name: {
    type: "string",
    label: "Last Name",
    order: 2,
    size: 5,
    form_field: {
      type: "text"
    }
  },
  email: {
    type: "string",
    label: "Email",
    order: 3,
    size: 10,
    form_field: {
      type: "email"
    }
  }
}

const ROLE_TEMPLATE = {
  fields: [
    { id: "first_name"},
    { id: "last_name" }, 
    { id: "email" }
  ]
}

function EditUser({role_id, email}) {
  const dispatch = useDispatch()
  const pathMap = useSelector(({roles}) => roles.user_people[email]) || {}
  const overrides = useTemplateOverrides({metadata: ROLE_METADATA, pathMap})

  const handleSave = useCallback(data => {
    const action = email ? "editUser" : "addUser"
    dispatch(store.actions[action]({ email, data, role_id }))
  }, [email, role_id]) 

  return <Edit values={pathMap} overrides={overrides} template={ROLE_TEMPLATE} onSave={handleSave} editing={!!email} />
}

function Wrapper(props) {
  const isParty = props.party_id || props.deal_party_id || props.person_id

  useEffect(() => {

  }, [props.person_id, props.email])

  return isParty ? <EditPerson {...props} /> : <EditUser {...props} /> 
}

export default function() {
  const [updates, setUpdates] = useState({})
  const [errors, setErrors] = useState({})
  const props = useSelector(({roles}) => roles.edit_person_modal)

  const clear = useCallback(() => setUpdates({}), [setUpdates])

  const value = useMemo(() => ({
    updates, setUpdates, errors, setErrors, clear
  }), [updates, setUpdates, errors, setErrors, clear])

  return <DataContext.Provider value={value}>
    { props ? <Wrapper {...props} /> : null }
  </DataContext.Provider>
}