import React from 'react'

const ContractorReportFormField = ({ label, name, validation, register, errorMessage, watch }) => {
  return (
    <div>
      <label className="dome-p2 dome-text-w400 dome-color-med-grey">{label}</label>
      <input {...register(`${name}`, validation)} />
      {errorMessage && <div className="dome-p3 dome-color-red-alert">{errorMessage}</div>}
    </div>
  )
}

export default ContractorReportFormField
