import React, { useCallback, useRef } from 'react'
import { compact } from 'lodash'

import { fancyJoin } from 'lib/utilities'
import { useDropdown } from 'components/utility/hooks'

export const serialize = value => [value.years || 0, value.months || 0, value.days || 0].join(":")
export const deserialize = value => {
  const [years, months, days] = String(value || "").split(":")

  return { years: parseInt(years || 0), months: parseInt(months || 0), days: parseInt(days || 0) }
}

function formatValue(serializedValue, field = {}) {
  const value = deserialize(serializedValue)
  const parts = []
  if (!field.skipYears)
    parts.push(`${value.years || 0} yr`)

  if (!field.skipMonths)
    parts.push(`${value.months || 0} mo`)
  
  if (!field.skipDays)
    parts.push(`${value.days || 0} days`)

  return fancyJoin(parts)
}

function Duration(props) {
  const { className, field } = props
  const displayRef = useRef()
  const { ref: dropdownRef, toggle, close, show } = useDropdown({
    excludedReferences: [ displayRef ]
  })

  const classes = compact([
    className    
  ]).join(" ")

  const display = formatValue(props.value)

  return <div className={classes}>
    <div className="display" ref={displayRef} onClick={toggle}>
      <span>{display}</span>
      <i className="fa-regular fa-angle-down"></i>
    </div>

    { show ? 
      <DurationDropdown {...props} ref={dropdownRef} onClose={close} /> : 
      null }
  </div>
}

function Select({type, max, value, onChange}) {
  const options = []
  for (let i = 0; i <= max; i++)
    options.push(<option value={i} key={i}>{ i }</option>)

  return <select onChange={e => onChange(type, e.target.value)} value={value}>
    { options }
  </select>
}

const DurationDropdown = React.forwardRef((props, container) => {
  const { id, field, onChange, onClose } = props
  const fields = []
  const value = deserialize(props.value)

  const update = useCallback((type, val) => {
    const obj = {...value, [type]: val}
    onChange({
      [id]: serialize(obj)
    })
  }, [ id, value, onChange ])

  if (!field.skipYears)
    fields.push(<div key="years">
      <label>Years</label>
      <Select type="years" max={field.maxYears || 25} value={value.years || 0} onChange={update} />
    </div>)

  if (!field.skipMonths)
    fields.push(<div key="months">
      <label>Months</label>
      <Select type="months" max={11} value={value.months || 0} onChange={update} />
    </div>)
  

  if (!field.skipMonths)
    fields.push(<div key="days">
      <label>Days</label>
      <Select type="days" max={30} value={value.days || 0} onChange={update} />
    </div>)

  return <div className="duration-dropdown" ref={container}>
    { fields }
  </div>
})

export default Duration