import React, { useCallback, useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import {
  requestIssuePolicy,
  selectInsuranceQuoteInterstitialState,
  setLegalDisclaimerModalVisibility,
  setSignatureFormData,
} from 'components/insurance/get_quote/slice/slice'
import LegalDisclaimerModal from '../../modals/LegalDisclaimerModal'
import SignatureImage from './SignatureImage'
import ExpandableText from '../../ExpandableText'
import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import ConfirmBackToPreviousStepModal from './ConfirmBackToPreviousStepModal'
import PdfViewer from './PdfViewer'
import { InterstitialContext } from 'components/utility/domecile_interstitial/wrapper'
import { useCopyOverridesFor } from 'components/insurance/get_quote/slice/overridesSlice'

const ErrorsFromServer = React.memo(({ error }) => {
  return <div className="p3 dome-color-red-alert">{error}</div>
})

export default function FinalizeQuoteForm({ onBack }) {
  const [signatureModalVisibility, setSignatureModalVisibility] = useState(false)
  const [backToPreviousModalVisibility, setBackToPreviousModalVisibility] = useState(false)
  const [termsButtonVisible, setTermsButtonVisible] = useState(false)

  const {
    quote,
    building,
    legalDisclaimerModalVisibility,
    signatureFormData,
    formData,
    errorDescription,
    currentUser,
    package_requirement_title,
    insuranceType,
    startingQuote: { writingCompany }
  } = useSelector(selectInsuranceQuoteInterstitialState)
  const { show: interstitialVisible } = useContext(InterstitialContext)
  const { overrides } = useCopyOverridesFor(insuranceType, writingCompany)

  const dispatch = useDispatch()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      automatic_opted_in: !package_requirement_title || signatureFormData?.automatic_opted_in === false ? false : true,
    },
  })

  const watchOptIn = watch('opt_in', false)
  const watchAutomaticOptedIn = watch('automatic_opted_in')

  const submitButtonEnabled = watchOptIn && signatureFormData?.signed_at

  useEffect(() => {
    if (watchOptIn) {
      dispatch(setSignatureFormData({ ...signatureFormData, user_id: currentUser?.id, signed_at: new Date() }))

      window.dataLayerPush({
        event: 'insurance-interstitial.finalize-quote.agree-terms-and-conditions-checkbox',
        url: window.location.href,
        buildingId: building.id,
      })
    } else {
      dispatch(setSignatureFormData({ ...signatureFormData, signed_at: null }))
    }
  }, [watchOptIn])

  const onLegalDisclaimerModalClose = useCallback(() => {
    dispatch(setLegalDisclaimerModalVisibility(false))
  }, [])

  const onFormSubmit = (data) => {
    if (data.opt_in) {
      dispatch(
        requestIssuePolicy({
          quote_id: quote.id,
          data: { ...signatureFormData, quote_attributes: { automatic_opted_in: data.automatic_opted_in } },
        })
      )

      window.dataLayerPush({
        event: 'insurance-interstitial.finalize-quote.request-issue-policy',
        url: window.location.href,
        buildingId: building.id,
      })
    }
  }

  const onErrors = (errors) => {}

  const handleBackToPreviousModalClose = () => {
    setBackToPreviousModalVisibility(false)
  }

  const handleBackToPreviousModalSubmit = () => {
    onBack(formData)
  }

  const onRenderPdfError = () => {
    setTermsButtonVisible(true)
  }

  return (
    <>
      <div className="dome-p2">To continue, please read and accept the following terms.</div>
      {termsButtonVisible ? (
        <div>
          <a className="dome-link dome-is-button dome-link-aqua-stroke" href={quote.consentDocumentUrl} target="_blank">
            view terms
          </a>
        </div>
      ) : (
        <PdfViewer url={quote.consentDocumentUrl} onError={onRenderPdfError} />
      )}
      <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <div className="finalize-quote-form-wrapper">
          <div className="finalize-quote-form-section">
            <div className="finalize-quote-form-check">
              <input
                type="checkbox"
                {...register('opt_in')}
                id="finalize-quote_opt_in"
                className="finalize-quote-form-check-input"
              ></input>
              <label htmlFor="finalize-quote_opt_in" className="dome-p2" role="button">
                I have read and agree to the Electronic Transactions terms and conditions.
              </label>
            </div>
            {package_requirement_title && (
              <>
                <div className="finalize-quote-form-check">
                  <input
                    type="checkbox"
                    {...register('automatic_opted_in')}
                    id="finalize-quote_automatic_opt_in"
                    className="finalize-quote-form-check-input"
                  ></input>
                  <label htmlFor="finalize-quote_automatic_opt_in" className="dome-p2" role="button">
                    Upon completion of this process, attach my certificate to this application requirement:{' '}
                    {package_requirement_title}
                  </label>
                </div>
                <div style={{ display: watchAutomaticOptedIn ? 'none' : 'block', color: 'red' }}>
                  Please note you will have to upload proof of insurance to your application manually.
                </div>
              </>
            )}
            <div className="finalize-quote-action-section">
              <button
                type="button"
                className="dome-btn dome-btn-link dome-btn-link-back"
                onClick={() => setBackToPreviousModalVisibility(true)}
              >
                edit
              </button>
              <button
                type="submit"
                className={clsx([
                  'dome-btn',
                  'dome-btn-base',
                  submitButtonEnabled ? 'dome-btn-aqua-stroke' : 'dome-btn-disabled',
                ])}
                disabled={!submitButtonEnabled}
              >
                request to issue policy
              </button>
            </div>
            <div className="viaffinity-disclosure-mobile">
              {overrides?.show_expanded_disclaimer_modal.toggle && <div className="dome-p3" style={{ marginBottom: '24px' }}>
                <ExpandableText onClickExpandButton={() => dispatch(setLegalDisclaimerModalVisibility(true))} />
              </div>}
              <ViaffinityDisclosureContent legalDisclaimer={overrides?.legal_disclaimer.html} />
            </div>
          </div>
        </div>
      </form>
      {errorDescription ? <ErrorsFromServer error={errorDescription} /> : null}
      <LegalDisclaimerModal
        open={interstitialVisible && legalDisclaimerModalVisibility}
        onClose={onLegalDisclaimerModalClose}
      />
      <ConfirmBackToPreviousStepModal
        open={backToPreviousModalVisibility}
        onClose={handleBackToPreviousModalClose}
        onSubmit={handleBackToPreviousModalSubmit}
      />
    </>
  )
}
