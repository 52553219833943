import React from 'react'
import PropTypes from 'prop-types'

import Preview from 'components/packages/preview'
import TemplateRow from './template_row'

  class VisibleTemplates extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        template: null,
        previewOpen: false
      }

      this.handleClickPreviewBtn = this.handleClickPreviewBtn.bind(this)
      this.unmountPreviewModal = this.unmountPreviewModal.bind(this)
    }

    handleClickPreviewBtn(template) {
      this.setState({ template: template, previewOpen: true })
    }

    skipDsfData() {
      return {
        skipDigitalSubmissionFee: this.props.skipDigitalSubmissionFee,
        skipRefinanceSubmissionFee: this.props.skipRefinanceSubmissionFee,
        skipTransferSubmissionFee: this.props.skipTransferSubmissionFee
      }
    }

    renderTemplates() {
      let list = []

      for (let i = 0; i < this.props.buildingTemplates.length; i++) {
        let template = this.props.buildingTemplates[i]

        list.push(<TemplateRow
          key={template.id}
          userSignedIn={this.props.userSignedIn}
          buildingTemplate={template}
          userIsManagingAgentOfBuildingOrg={this.props.userIsManagingAgentOfBuildingOrg}
          handleClickPreviewBtn={this.handleClickPreviewBtn}
          userRoleRestrictedFromApplying={this.props.userRoleRestrictedFromApplying} />)
      }

      return list
    }

    renderPreviewModal() {
      const { template: { package_template, active_state, building_id }} = this.state
      const { userSignedIn, userIsManagingAgentOfBuildingOrg, userRoleRestrictedFromApplying } = this.props
      const package_fees = this.props.buildingTemplateFees[package_template.package_type] || []
      const package_type = package_template.package_type

      return <Preview
        packageTemplate={package_template}
        isRefinance={this.props.templateTypes.refinance.includes(package_type)}
        isTransfer={this.props.templateTypes.transfer.includes(package_type)}
        isRental={this.props.templateTypes.rental.includes(package_type)}
        hashedId={package_template.hashed_id}
        activeState={active_state}
        packageType={package_type}
        fees={package_fees}
        orgHandlesBPFee={this.props.orgHandlesBPFee}
        packageMachineId={package_template.package_machine_id}
        buildingId={building_id}
        userSignedIn={userSignedIn}
        userIsManagingAgentOfBuildingOrg={userIsManagingAgentOfBuildingOrg}
        userRoleRestrictedFromApplying={userRoleRestrictedFromApplying}
        skipDsfData={this.skipDsfData()}
        brokerPackageInitiationFee={ this.props.brokerPackageInitiationFee }
        hideProcessingFees={this.props.hideProcessingFees}
        unmount={this.unmountPreviewModal} />
    }

    unmountPreviewModal() {
      this.setState({ previewOpen: false })
    }

    renderContent() {
      return <div>
        {this.renderTemplates()}
      </div>
    }

    renderLoading() {
      return <div className="loading-dots"></div>
    }

    render() {
      return <div>
        { this.state.previewOpen ? this.renderPreviewModal() : null }
        {this.renderContent()}
      </div>
    }
  }

  VisibleTemplates.propTypes = {
    buildingTemplates: PropTypes.array.isRequired,
    buildingTemplateFees: PropTypes.object.isRequired,
    userSignedIn: PropTypes.bool.isRequired,
    userIsManagingAgentOfBuildingOrg: PropTypes.bool.isRequired,
    userRoleRestrictedFromApplying: PropTypes.bool,
    skipDigitalSubmissionFee: PropTypes.bool,
    skipRefinanceSubmissionFee: PropTypes.bool,
    skipTransferSubmissionFee: PropTypes.bool
  }

  export default VisibleTemplates
