import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'

export default function Field({ type, ...restProps }) {
  switch (type) {
    case ('text', 'date'):
      return <Input {...restProps} />
  }
}

Field.propTypes = {
  type: PropTypes.string.isRequired,
}
