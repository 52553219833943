import React, { Component } from "react"
import UploadArea from 'components/utility/upload_area'

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}
export default class EnableGenericPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {}
    }
  }

  changeValue(is_generic_preview_on) {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/enable_generic_preview`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({ is_generic_preview_on: is_generic_preview_on ? 1 : 0 }),
      success: () => this.props.updateIsGenericPreviewEnabled(is_generic_preview_on)
    })
  }

  renderChoices() {
    return <select defaultValue={this.props.isGenericPreviewEnabled ? "1" : "0"} onChange={e => this.changeValue(parseInt(e.target.value) == 1)}>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  }

  uploadFiles(file) {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/upload_generic_preview`
    const data = new FormData()

    data.append("file", file[0].file)
    data.append($("meta[name=csrf-param]").attr("content"), $("meta[name=csrf-token]").attr("content"))

    const success = (file) => this.props.updateFile(file)

    $.ajax({ url, type: "post", data, contentType: false, processData: false, success })

  }

  deleteUploadFiles() {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/delete_generic_preview/`

    const success = () => this.props.updateFile(null)

    $.ajax({ url, type: "delete", success})
  }

  renderFileName(){
    const buttonIcon = { fontSize: '16px', display: 'inline-block', marginLeft: '15px', border: 'none', background: 'transparent', textDecoration: 'none' }
    const url = this.props.file.url

    if (!this.props.isGenericPreviewEnabled)
      return ""

    return (
      <div className="list">
        <div>
          <div className="file-name">
            {this.props.file.name}
          </div>
          <div>
            <a href={url} target="_blank" rel="noopener noreferrer" ><button className="fas fa-search" style={buttonIcon} ></button></a>
            <button className="fa fa-trash" style={buttonIcon} onClick={() => this.deleteUploadFiles()}></button>
          </div>
          <div>
          </div>
        </div>
      </div>
    )
  }

  renderFile() {
    const fileUploaded = !this.props.file ? "" : this.renderFileName()
      return (
        <div>
          {fileUploaded}
      </div>
    )
  }

  renderUploadArea(){
    const uploadAreaText = "Choose File"

    if (this.props.isGenericPreviewEnabled)
      return (
        <div>
          <div className="file-upload">
            <button className="upload-generic-preview-btn">
              <UploadArea className="upload-generic-preview" inputChanged={files => this.uploadFiles(files)} exclude_icons={true} key="file" text={uploadAreaText} />
            </button>
          </div>
        </div>
      )
  }

  render() {
    return (
      <div>

      <div className="row questionnaire-admin-row">
          <div className="col-sm-4"><div>Generic Questionnaire Preview</div><div className="small subtext">(Files will be converted to PDF on upload)</div></div>
          <div className="col-sm-1">
            {this.renderChoices()}
          </div>
          <div className="col-sm-2">
            {this.renderUploadArea()}
          </div>
          <div className="questionnaire-generic-preview">
            {this.renderFile()}
          </div>
        </div>
      </div>
    )
  }
};
