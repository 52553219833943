import React, { useState, useEffect } from 'react'
import DeleteModal from './DeleteModal.jsx'
import { groupBy, mapValues, has } from 'lodash'

const PARTNERSHIP_TYPES = [
  { id: "property_management", label: "Property Management" },
  { id: "self_managed_property", label: "Self-Managed Property" },
  { id: "brokerages", label: "Brokerages" },
  { id: "affiliates", label: "Affiliates" },
  { id: "credit_organization", label: "Credit Organization" },
  { id: "test_organization", label: "Test Organization" },
  { id: "other", label: "Other" },
]

const marginLeft2 = {
  marginLeft: '2%',
}

const marginBot5 = {
  marginBottom: '5%',
}

const width100 = {
  width: "100%",
}

function useFiles() {
  const initialState = {}
  const [files, setFiles] = useState(initialState)

  function setFile(name, file) {
    const newFiles = { ...files, [name]: file }
    setFiles(newFiles)
  }

  function removeFile(name) {
    setFile(name, false)
  }

  return [mapValues(files, f => f || null), setFile, removeFile]
}

export default function Branding(props) {
  //states for initial gathering of slugs using ajax request
  const [slugs, setSlugs] = useState({});
  const [slugOrder, setSlugOrder] = useState([]);
  const [slug, setSlug] = useState({});
  const [files, setFile, removeFile] = useFiles({});

  const organization_id = props.org_id

  useEffect(() => {
    $.ajax({
      url: "branding/get_org_identifiers",
      data: { organization_id: organization_id },
      method: "GET",
      dataType: "JSON",
    })
      .done(({ slugs }) => {
        const newSlugs = mapValues(groupBy(slugs, slug => slug.id), arr => arr[0])
        setSlugs(newSlugs)

        const slugForBranding = slugs.map(slug => slug.use_for_branding).indexOf(true)
        const indexSlugForBranding = slugForBranding !== -1 ? slugForBranding : 0

        setSlugOrder(slugs.map(slug => slug.id))
        setSlug({ ...newSlugs[slugs[indexSlugForBranding].id] })
      })
  }, [])

  function changeSlug(e) {
    var slug_id = e.target.value
    setSlug({ ...slugs[slug_id] })
  }

  function updateSlug(event) {
    //event.preventDefault();
    const formData = new FormData();

    for (let attr of ["org_identifier_id", "visible_on_partner_page", "partnership_type", "partner_url"])
      formData.append(attr, slug[attr])

    for (let attr of ["favicon", "property_page_logo", "partner_page_logo", "logo", "subdomain_property_search_logo"])
      if (has(files, attr))
        formData.append(files[attr] ? attr : `remove_${attr}`, files[attr] || true)

    $.ajax({
      url: `/super/organizations/${props.org_id}/edit/branding/update_org_identifiers/${slug.id}`,
      method: "PATCH",
      dataType: "JSON",
      contentType: false,
      processData: false,
      data: formData,
    })
      .done((res) => {
        console.log('updated')
        setSlug(res)
        setSlugs({ ...slugs, [slug.id]: res })
      })
  }

  function updateAttribute(attr, value) {
    const newSlug = {
      ...slug, [attr]: value
    }
    setSlug(newSlug)
  }

  function slugOption(id) {
    const s = slugs[id]
    const label = (s.use_for_branding ? "Currently Used For Branding: " : "") + s.slug

    return { id, label }
  }

  return (
    <form onSubmit={updateSlug} id="branding-form" encType='multipart/form-data'>
      <div className="col-xs-12 col-md-12">
        <div className="page-header" style={{ margin: "-5px 0 35px", borderBottom: "inherit" }}>
          <h4 className="pull-left" style={marginBot5}> Edit Organization: {props.org_name}</h4>
          <div className="clearfix"></div>
          <h4 className="pull-left" style={marginBot5}>
            Select A Slug Below to Modify Logos:
            <br />
            <Select onChange={(e) => changeSlug(e)} value={slug.id} options={slugOrder.map(slugOption)} />
          </h4>
          <FileInput
            title="Favicon (.ico files only)"
            logo={slug.favicon?.url}
            alt="Subdomain Favicon Logo"
            fileType='favicon'
            onChange={setFile}
            onRemove={removeFile}
            updateSlug={updateSlug}
          />

          <FileInput
            title="Property Page Logo"
            logo={slug.property_page_logo?.url}
            alt="Property Page Logo"
            fileType='property_page_logo'
            onChange={setFile}
            onRemove={removeFile}
            updateSlug={updateSlug}
          />

          <h4 className="pull-left" style={marginBot5}>Partner</h4>
          <FileInput
            title="Partner Page Logo"
            logo={slug.partner_page_logo?.url}
            alt="Partner Page Logo"
            fileType='partner_page_logo'
            onChange={setFile}
            onRemove={removeFile}
            updateSlug={updateSlug}
          />

          <div className="row">
            <div className="col-xs-5" style={marginLeft2}>
              <label>Partners Page Logo - Website URL Link: </label>
            </div>
            <div className="col-xs-5">
              <input type="text" style={width100} value={slug.partner_url || ""} onChange={(e) => updateAttribute("partner_url", e.target.value)} />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-5" style={marginLeft2}>
              <label>Partners Page Logo - Type: </label>
            </div>
            <div className="col-xs-5">

              <Select onChange={(e) => updateAttribute("partnership_type", e.target.value)} value={slug.partnership_type || ""} options={PARTNERSHIP_TYPES} type={slug.partnership_type} />
              <br />
              <label>Visible on Partners Page? </label>
              <input type="checkbox" checked={slug.visible_on_partner_page || false} value={1} onChange={(e) => updateAttribute("visible_on_partner_page", e.target.checked)} />
              <br />
            </div>
          </div>
          <h4 className="pull-left" style={marginBot5}>Subdomain</h4>
          <div className="clearfix"></div>

          <FileInput
            title="Subdomain Login Logo"
            logo={slug.logo?.url}
            fileType='logo'
            alt="Subdomain Login Logo"
            onChange={setFile}
            onRemove={removeFile}
            updateSlug={updateSlug}
          />
          <br />
          <FileInput
            title="Subdomain Property Search Logo"
            logo={slug.subdomain_property_search_logo?.url}
            alt="Subdomain Property Search Logo"
            fileType='subdomain_property_search_logo'
            onChange={setFile}
            onRemove={removeFile}
            updateSlug={updateSlug}
          />
        </div>
      </div>
      <button type="submit" className='btn btn-success pull-right'>Save</button>
    </form>
  );
}

function FileInput(props) {
  const [isRemoved, setIsRemoved] = useState(false);

  const { logo } = props
  const onChange = (e) => props.onChange(props.fileType, e.target.files[0])
  const onRemove = () => props.onRemove(props.fileType)
  const hasImage = logo !== undefined && logo !== null
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (isRemoved) props.updateSlug()
  }, [isRemoved])

  function sweetAlertConfirmDelete() {
    swal({
      title: `Delete this ${props.title == "Favicon (.ico files only)" ? "Favicon" : props.title}?`,
      text: "This cannot be undone after saving.",
      type: "warning",
      showCancelButton: true,
      closeOnConfirm: true,
    }, function (isConfirm) {
      if (isConfirm) {
        onRemove()
        setIsRemoved(true)
      }
    })
  }

  return <>
    <div className="clearfix"></div>
    <div className="row">
      <div className="col-xs-5" style={marginLeft2}>
        <label>{props.title}</label>
      </div>
      <div className="col-xs-5">
        <input onChange={onChange} type="file" />
        <br />
        {hasImage ? <img style={{ maxWidth: "145px" }} src={logo} alt={props.alt} /> : null}
        {hasImage ? <button type="button" value={props.fileType} onClick={(e) => sweetAlertConfirmDelete(e)} style={{ fontSize: "20px", backgroundColor: "inherit", border: "inherit" }} className="fa fa-remove text-danger pull-right"></button> : ""}
        {showModal && <DeleteModal fileType={props.fileType} title={props.title} showModal={showModal}
          setShowModal={setShowModal} onRemove={onRemove} />}
      </div>
    </div>
    <hr />
    <br />
  </>
}

function Select({ value, options, onChange, type }) {
  const optionElements = options.map(({ id, label }) => <option key={id} value={id}>{label}</option>)

  return (
      <select className="form-control" value={value} onChange={onChange}>{optionElements}</select>
  )
}
