import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { blankDealPartyForSection, update, remove, add } from './reducer/deal_party'
import Field from 'components/utility/field'

const REQUIRED_FIELD_WARNING_TEXT = 'Field is required'

const DealParty = props => {
  const dispatch = useDispatch()
  const [areFieldsVisible, setAreFieldsVisible] = useState(false)
  // const [data, setData] = useState(props.data || {})
  const [section, setSection] = useState(props.section || {})
  const data = props.data || {}

  useEffect(() => {
    if (!data.id) {
      dispatch(add({section: section, dealParty: data}))
    }
  }, []);

  const sortedFields = () => {
    const { fields } = props

    return fields.sort((f1, f2) => f1.order > f2.order ? 1 : f2.order > f1.order ? -1 : 0)
  }

  const handleUpdate = (fieldName, value) => {
    dispatch(update({ section: props.section, dealParty: { ...data, [fieldName]: value }}))
  }

  // const handleChangeDate = (fieldName, value) => {
  //   let newDate = ''
  //   const format = [2, 2, 4]

  //   value = value.replace(/\D/g, '')

  //   if (value.length > format[0]) {
  //     for (const i in format) {
  //       if (value.length > format[i]) {
  //         newDate += value.substr(0, format[i]) + '/';
  //         value = value.substr(format[i]);
  //       }
  //     }
  //     newDate += value
  //   } else {
  //     newDate = value
  //   }

  //   dispatch(update(props.section, { ...data, [fieldName]: newDate }))
  // }

  const toggleFieldVibility = () => {
    setAreFieldsVisible(!areFieldsVisible)
  }

  const isAnyFieldFilled = () => {
    const fieldKeys = props.fields.map(field => field['id'])

    return fieldKeys.map(key => data[key]).some(val => !!val)
  }

  const fullName = () => {
    const nameMetadata = props.fields.find(field => field['id'] == 'full_name')

    if (!nameMetadata) return 'Name'

    return data['full_name'] || 'Name'
  }

  const renderLabel = () => {
    return <div className='col-xs-3'>
      <label className='control-label'>{props.label}</label>
    </div>
  }

  const renderFieldLabelText = field => {
    let labelText = field['required'] ? '*' : ''
        labelText += field['label']

    return labelText
  }

  const renderField = field => {
    const { type, placeholder } = field,
          name = `package_data[${section}][][${field['id']}]`,
          defaultValue = data[field['id']],
          required = field['required'] ? isAnyFieldFilled() : false

    return <Field type={type}
                  placeholder={placeholder}
                  name={name}
                  defaultValue={defaultValue}
                  required={required}
                  value={defaultValue}
                  handleUpdate={value => handleUpdate(field['id'], value)} />

    // switch(field['type']) {
    //   case 'string':
    //     fieldElement = <input type='text'
    //                           className='form-control'
    //                           placeholder={field['placeholder']}
    //                           name={`package_data[${section}][][${field['name']}]`}
    //                           onChange={event => handleChangeText(field['name'], event.target.value)}
    //                           defaultValue={data[field['name']]}
    //                           required={field['required'] ? enforceRequiredFields : false} />
    //     break
    //   case 'date':
    //     fieldElement = <input type='text'
    //                           className='form-control'
    //                           placeholder={field['placeholder']}
    //                           name={`package_data[${section}][][${field['name']}]`}
    //                           onChange={event => handleChangeDate(field['name'], event.target.value)}
    //                           value={data[field['name']]}
    //                           maxLength={10}
    //                           required={field['required'] ? enforceRequiredFields : false} />
    //     break;
    // }

    // return fieldElement
  }

  const fieldsList = () => {
    const list = sortedFields().map(field => {
      return <div className='col-xs-6' key={`field_${section}_${field['id']}`}>
        <label htmlFor={`field_${section}_${field['id']}`}>{renderFieldLabelText(field)}</label>
        {renderField(field)}
      </div>
    })

    list.push(<input key='id' type="hidden" name={`package_data[${section}][][id]`} value={data['id']} />)
    list.push(<input key='order' type="hidden" name={`package_data[${section}][][order]`} value={data['order']} />)

    return list
  }

  const renderFields = () => {
    return <div className='col-xs-8'>
      <div className='expandable-container'>
        <div className='data-preview' onClick={() => toggleFieldVibility()}>
          {fullName()}
        </div>
        <div className='fields-container' style={{display: areFieldsVisible ? 'inline-block' : 'none'}}>
          {fieldsList()}
        </div>
      </div>
    </div>
  }

  const renderCloneButton = () => {
    if (!props.cloneable)
      return null

    return <div className='col-xs-1'>
      <i className='fa fa-plus-square-o fa-2x'
         style={{ cursor: 'pointer' }}
        //  onClick={() => props.cloneSection(props.section)} />
        onClick={() => dispatch(blankDealPartyForSection({section: props.section}))} />
    </div>
  }

  const renderRemoveCloneButton = () => {
    if (!props.isCloned)
      return null

    return <div className='col-xs-1'>
      <i className='fa fa-minus-square-o fa-2x'
         style={{ cursor: 'pointer' }}
        onClick={() => dispatch(remove({section: props.section, dealParty: data}))} />
    </div>
  }

  return <div className='row deal-party'>
    {renderLabel()}
    {renderFields()}
    {props.isCloned ? renderRemoveCloneButton() : renderCloneButton()}
  </div>
}

// DealParty.defaultProps = {
//   data: {}
// }

// DealParty.propTypes = {
//   section: PropTypes.string.isRequired,
//   fields: PropTypes.array.isRequired,
//   label: PropTypes.string.isRequired,
//   cloneable: PropTypes.bool,
//   data: PropTypes.object,
//   cloneSection: PropTypes.func.isRequired
// }

export default DealParty