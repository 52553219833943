import React from 'react'
import PropTypes from 'prop-types'

class ReasonAdditionalDetails extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const textAreaValue = e.target.value

    this.props.setAppState({ additionalDetails: textAreaValue })
  }

  renderTextarea() {
    return (
      <textarea name="refund-details"
        id="refund-details-textarea"
        rows="3"
        placeholder="Please explain further to proceed with refund."
        value={this.props.additionalDetails}
        onChange={(e) => this.handleChange(e)}
      ></textarea>
    )
  }

  render() {
    return (
      <div id="refund-details-container">
        <div>Additional Details:</div>
        {this.renderTextarea()}
      </div>
    )
  }
}

ReasonAdditionalDetails.propTypes = {
  additionalDetails: PropTypes.string,
  setAppState: PropTypes.func.isRequired
}

export default ReasonAdditionalDetails
