import React, { useState, useEffect, useRef, useMemo } from 'react'
import { createSlice, configureStore, combineReducers } from '@reduxjs/toolkit'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { pick, mapValues, padStart } from 'lodash'

import { pathMapToHash } from 'lib/utilities/form'

import Form, { reducer as formReducers, setForm, setFormWidth  } from './form'
import { setPages, reducer as pageReducers } from './page'

import signatureReducers, { setSignatures, middleware as signatureMiddleware } from './signature/store'
import { middleware as fieldPositionMiddleware } from './field_position/store'
import { reducer as fieldReducer, setFields} from './field'
import { reducer as fieldPositionReducer, setFieldPositions } from './field_position/store'
import { reducer as signingAreaReducer, load as setSigningArea } from './signature/signing_area'
import { reducer as sidebarReducer } from './sidebar'
import { reducer as dealpartiesReducer, loadParties } from './deal_parties'

import {
  load as loadFontSizes,
  reducer as fontSizeReducer,
  middleware as fontSizeMiddleware } from './utilities/find_font_size'

import { getToolReducers, useToolDataDispatcher  } from './tools'


const initialState = {
  loaded: false,
  instance_id: null,
  uniqueness: 0,
  user_id: null,
  user: {},
  user_roles: [],
  user_permissions: [],
  package_id: null,
  values: {},
  valueHash: {},
  updates: {},
  conflicts: {},
  unlinked_values: {}
}

const Store = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoaded(state, { payload: loaded}) {
      state.loaded = loaded
    },

    setUser(state, { payload: user_id}) {
      state.user_id = user_id
    },

    initialInfo(state, { payload: { id, user, user_roles, user_permissions}}) {
      state.instance_id = id
      state.uniqueness = padStart(String(Math.floor(Math.random() * 1000000)), 6, '0')
      state.user = user
      state.user_roles = user_roles
      state.user_permissions = user_permissions
    },

    setPackage(state, { payload: package_id }) {
      state.package_id = package_id
    },

    load(state, { payload: {values}}) {
      state.values = values
      state.valueHash = pathMapToHash(values)
    },

    reload(state, { payload: {values} }) {
      state.values = { ... values, ... mapValues(state.updates, value => value[1]) }
      state.valueHash = pathMapToHash(state.values)
      // Find Conflicts
      const conflicts = {}

      for (let path in state.updates)
        if (state.updates[path][0] != values[path])
          conflicts[path] = true

      state.conflicts = conflicts

      // Update the "original" values to map to what they are now.
      state.updates = { ... mapValues(state.updates, (value, key) => [state.values[key], value[1]])}
    },

    clearUpdates(state) {
      state.values = { ...state.values, ...mapValues(state.updates, value => value[0])}
      state.valueHash = {}
      state.updates = {}
      state.conflicts = {}
    },

    updateValues(state, { payload: hash }) {
      const { updates, values } = state

      const original = mapValues(hash, (_, key) => updates[key] ? updates[key][0] : values[0] )
      state.updates = { ...updates, ...mapValues(hash, (value, key) => [original[key], value]) }
      state.values = { ...values, ...hash }
      state.valueHash = pathMapToHash(state.values)
    }
  }
})

export const { updateValue, clearUpdates } = Store.actions

export const reducers = {
  global: Store.reducer,
  font_sizes: fontSizeReducer,
  form: formReducers,
  form_pages: pageReducers,
  form_signatures: signatureReducers,
  form_signing_area: signingAreaReducer,
  form_fields: fieldReducer,
  form_field_positions: fieldPositionReducer,
  sidebar: sidebarReducer,
  deal_parties: dealpartiesReducer,
  ... getToolReducers()
}

const rootReducer = combineReducers(reducers)

const innerDimensions = (node) => {
  var computedStyle = getComputedStyle(node)

  let width = node.clientWidth // width with padding
  let height = node.clientHeight // height with padding

  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  return { height, width }
}

function useInitialData(data) {
  const [ initialData, setInitialData ] = useState(data)

  const clearInitialData = () => setInitialData(null)
  return [initialData, clearInitialData]
}

export function usePermissionsChecks(permissions) {
  const user_permissions = useSelector(({global: state}) => state.user_permissions)

  if (!Array.isArray(permissions))
    permissions = [permissions]
  
  return useMemo(() => {
    const lookups = permissions.map(permission => [permission, user_permissions.includes(permission)])

    return Object.fromEntries(lookups)
  }, [ ...permissions, user_permissions ] )
}

export function FormLoader(props) {
  const { form_id, user_id, package_id } = props
  const dispatch = useDispatch()
  const ref = useRef()
  const loaded = useSelector(({global: state}) => state.loaded)
  const [initialData, clearInitialData] = useInitialData(props.initialData)

  const dispatchData = useToolDataDispatcher()

  const success = ({form}) => {
    if (initialData) {
      dispatch({type: 'RESET', initialData})
      dispatch(Store.actions.reload(form))
    } else
      dispatch(Store.actions.load(form))

    dispatch(Store.actions.initialInfo(form))
    dispatch(setForm(form))
    dispatch(setFormWidth(form.page_width))
    dispatch(setPages([form.pages, form.page_files]))
    dispatch(setSignatures(form))
    dispatch(setSigningArea(form))
    dispatch(setFields(form))
    dispatch(setFieldPositions(form.field_positions))
    dispatch(loadParties(form))

    dispatchData(form, {merge: initialData ? true : false})
    dispatch(loadFontSizes(form))

    clearInitialData()
    dispatch(Store.actions.setLoaded(form.id))
  }

  useEffect(() => {
    if (!form_id)
      return

    const width = ref.current ? innerDimensions(ref.current).width - 350 : null
    const url = `${props.url || `/forms/v3/instance/${form_id}/data`}?width=${width}`

    $.ajax({
      url, success
    })
  }, [form_id])

  useEffect(() => {
    dispatch(Store.actions.setUser(user_id))
    dispatch(Store.actions.setPackage(package_id))
  }), [user_id, package_id]

  return <div className="form-loader-components" style={{ width: '100%' }} ref={ref}>
    {form_id && loaded == form_id ? <Form {...props} /> : <div className="loader"></div>}
  </div>
}

const prependMiddleware = (current, another) => current.prepend(another)
export default class extends React.Component {
  constructor(props) {
    super(props)

    this.store = configureStore({
      reducer: (state, action) => {
        if (action.type == 'RESET') {
          state = { ...state, ...action.initialData }
        }

        return rootReducer(state, action)
      },

      middleware: (getDefaultMiddleware) => {
        return [fontSizeMiddleware, signatureMiddleware, fieldPositionMiddleware ].flat().reduce(prependMiddleware, getDefaultMiddleware())
      },
    })
  }

  componentWillUnmount() {
    if (typeof this.props.dataOnClose != 'function')
      return

    this.props.dataOnClose()
  }

  render() {
    return <Provider store={this.store}>
      <FormLoader {...this.props} />
    </Provider>
  }
}
