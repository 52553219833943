import React from 'react'
import viaffinityLogo from '/app/assets/images/insurance/logos/viaffinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import lemonadeLogo from '/app/assets/images/insurance/logos/lemonade.png'
import { useCopyOverridesFor } from '../slice/overridesSlice'

export default function ViaffinityDisclosureContent({ legalDisclaimer }) {
  return (
    <>
      <div className="viaffinity-disclosure-header">
        <div className="viaffinity-disclosure-header__item">© 2024 Viaffinity</div>
        <div className="viaffinity-disclosure-header__item">
          <img src={viaffinityLogo} style={{ width: '56px' }} />
        </div>
      </div>
      <div className="viaffinity-disclosure-body">
        <div dangerouslySetInnerHTML={{__html: legalDisclaimer}} />
      </div>
      <div className="viaffinity-disclosure-footer">
        <div>
          <em className="dome-color-med-grey">insurance provided by</em>
        </div>
        <div className="dome-d-flex dome-align-center dome-gap6">
          <img src={chubbLogo} style={{ height: '6px', objectFit: 'contain' }} />
          <img src={lemonadeLogo} style={{ height: '10px', objectFit: 'contain' }} />
        </div>
      </div>
    </>
  )
}
