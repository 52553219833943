import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { castArray } from 'lodash'

import PersonModal from '../edit_person'
import { AllPeople, GroupPeople, OtherPeople } from './group'
import { openEditPerson } from '../state'
import { usePermissions } from '..'

function Dropdown({groups, options = {}, children}) {
  const { everyoneIsSelectable } = usePermissions()

  return <div className='dropdown'>
    { castArray(groups).map((group_id, index) => <GroupPeople key={group_id} group_id={group_id} defaultOpen={index == 0} {...options} />)}
    { everyoneIsSelectable ? <OtherPeople group_ids={groups} {...options} /> : null }
    { children }
  </div>
}

export function AllPeopleDropdown({onSelect, children, role_id}) {
  return <div className="dropdown">
    <AllPeople defaultOpen={true} onSelect={onSelect} role_id={role_id} />
    {children}
  </div>
}

export function AddPerson({party_id, deal_party_id}) {
  const party = useSelector(({roles}) => roles.deal_party_metadata[party_id])
  const dispatch = useDispatch()

  const handleClick = e => {
    e.preventDefault()
    dispatch(openEditPerson({party_id, deal_party_id}))
  }

  return <div className="add" onClick={handleClick}>
    <i className="fa-light fa-plus" />
    <span>Add a {party.label}</span>
  </div>
}

export function AddUser({role_id, deal_party_id}) {
  const role = useSelector(({roles}) => roles.roles[role_id])
  const [ showModal, setShowModal ] = useState(false)

  const onClose = useCallback(() => setShowModal(false), [setShowModal])

  return <div className="add">
    <a href="#" onClick={() => setShowModal(true)}>Add a {role?.label || "User"}</a>
    { showModal ? <PersonModal role_id={role_id} deal_party_id={deal_party_id}  onClose={onClose} /> : null }
  </div>
}

export function DealPartyDropdown({deal_party_id, selectedEmail, onSelect}) {
  const party_id = useSelector(({roles}) => roles.deal_parties[deal_party_id].party)
  const groups = useSelector(({roles}) => roles.party_groups[party_id])

  const options = { 
    deal_party_id,
    selectedEmail,
    onSelect
  }

  return <Dropdown groups={groups} options={options}>
    <AddPerson party_id={party_id} deal_party_id={deal_party_id} />
  </Dropdown>
}

export function PartyDropdown({party_id, onSelect, selectedEmail}) {
  const groups = useSelector(({roles}) => roles.party_groups[party_id])
  const options = { 
    party_id,
    selectedEmail,
    onSelect
  }

  return <Dropdown groups={groups} options={options}>
    <AddPerson party_id={party_id} />
  </Dropdown>
}

export function RoleDropdown({role_id, onSelect,selectedEmail}) {
  const groups = useSelector(({roles}) => roles.role_groups[role_id])
  const options = { 
    role_id,
    selectedEmail,
    onSelect
  }

  return <Dropdown groups={groups} options={options}>
    <AddUser role_id={role_id} />
  </Dropdown>
}

export function FullDropdown({ onSelect }) {
  const groups = useSelector(({roles}) => roles.group_order)
  const options = { allow_all: true, onSelect }

  return <Dropdown groups={groups} options={options} />
}

export default function(props) {
  const { party_id, role_id, deal_party_id } = props

  if (party_id)
    return <PartyDropdown party_id={party_id} {...props}  />

  if (deal_party_id)
    return <DealPartyDropdown deal_party_id={deal_party_id} {...props}  />

  if (role_id)
    return <RoleDropdown role_id={role_id} {...props} />

  return null
}