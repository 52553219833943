import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAdminResidentPaymentsState, setInitialState, updateEnableRpLeaseId } from "./store/slice";
import EnableRpLeaseId from "./components/EnableRpLeaseId";

export function ResidentPaymentsAdmin(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialState(props));
  }, [dispatch]);

  const { organization } = useSelector(selectAdminResidentPaymentsState);

  const onChangeEnableLeaseId = useCallback(
    (value) => {
      const organizationId = organization?.id;

      dispatch(updateEnableRpLeaseId({ organizationId, value }));
    },
    [dispatch, organization?.id]
  );

  return (
    <div>
      <h2>Resident Payments Administration</h2>
      <EnableRpLeaseId enabled={organization?.enable_rp_lease_id || false} changeValue={onChangeEnableLeaseId} />
    </div>
  );
}
