import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

function ConfirmPaymentRequestModal(props) {
  const { open, onClose, invoice, total, onSubmit } = props;
  const [note, setNote] = useState("");
  const modalRef = useWatchableRef(null);

  const onNoteChange = (e) => {
    setNote(e.currentTarget.value);
  };

  const handleSubmit = (_e) => {
    onSubmit(note.trim());
  };

  useEffect(() => {
    if (!modalRef.current) return;

    const handleClose = (_e) => onClose();
    const localRefValue = modalRef.current

    $(localRefValue).on("hidden.bs.modal", handleClose);

    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal confirm-payment-request-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <h3 className="modal-title confirm-payment-request-modal__title">Confirm Payment Request</h3>
          </div>
          <div className="modal-body">
            <div className="confirm-payment-request__text-inform">
              You are requesting payment for goods + services at
            </div>
            <div className="confirm-payment-request__text-building">
              {` ${invoice.building_address}, Unit ${invoice.unit}:`}
            </div>
            <div className="confirm-payment-request__text-amount">{asMoney(total / 100)}</div>
            <div className="confirm-payment-request__text-paid-by">to be paid by:</div>
            <div className="confirm-payment-request__text-payor-name">
              {invoice.first_name} {invoice.last_name}
            </div>
            <div className="confirm-payment-request__text-payor-email">{invoice.email}</div>
            <textarea placeholder="Include a message (Optional)" value={note} onChange={onNoteChange} />
          </div>
          <div className="modal-footer">
            <button className="btn btn-custom-cancel" data-dismiss="modal" onClick={onClose} style={{ float: "left" }}>
              Back
            </button>
            <button type="submit" className="btn btn-custom-submit" onClick={handleSubmit}>
              Send Invoice
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmPaymentRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    building_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number,
    user_id: PropTypes.number,
    requested_by_id: PropTypes.number.isRequired,
    building: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
    unit: PropTypes.string.isRequired,
    note: PropTypes.string,
  }),
};

export default ConfirmPaymentRequestModal;
