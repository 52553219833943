import React from 'react'
import SectionDivider from '../SectionDivider'
import Input from '../../Input'
import useTransformFieldProperties from '../../hooks/useTransformFieldProperties'


const Transform = ({ id, page, position, sectionName = 'transform', actionName, onAction }) => {
  const generateProperties = useTransformFieldProperties(id, page, position)

  return (
    <SectionDivider sectionName={sectionName} actionName={actionName} onAction={onAction}>
      <div className="split-input">
        <Input classes="dimension" prepend="x" label="position" {...generateProperties('x')} />
        <Input classes="dimension" prepend="y" {...generateProperties('y')} />
      </div>
      <div className="split-input">
        <Input classes="dimension" prepend="w" label="size" {...generateProperties('width')} />
        <Input classes="dimension" prepend="h" {...generateProperties('height')} />
      </div>
    </SectionDivider>
  )
}

export default Transform
