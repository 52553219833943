import React, {useState, useEffect, useRef} from 'react'

const SelectTypeModal = (props) => {
  const [type, setType] = useState(null)
  const [savedType, setSavedType] = useState(null)
 	const ref = useRef()

  const cleanup = () => props.onClose(savedType)
  const hide = () =>  $(ref.current).modal("hide")
  const save = () => type ? setSavedType(type) : null

  useEffect(() => {
  	const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [savedType])

  useEffect(() => {
    savedType ? hide() : null
	}, [savedType])


  return (
    <div ref={ref} className='modal'>
      <div className='specialist-modal-box'>
        <div className="header">
          <i className='icon'></i>
          <h2>concierge services</h2>
        </div>
        <p className='subtitle'>
          We offer two types of services to best suit your needs. Choose a type to get started:
        </p>
        <div className='divider'></div>
        <div className='choose-a-type'>
          <div
            className={`choice ${(type == 'review' ? 'selected' : '')}${(type == 'full' ? 'unselected' : '')}`}
            onClick={() => setType("review")}
          >
            <p className='title'>review only</p>
            <p className='inner-content'>
              Our Board Package Concierges will examine an existing board
              package and advise on any errors, oversights, or improvements that
              can be made.
            </p>
          </div>
          <div
            className={`choice ${(type == 'full' ? 'selected' : '')}${(type == 'review' ? 'unselected' : '')}`}
            onClick={() => setType("full")}
          >
            <p className='title'>full service</p>
            <p className='inner-content'>
              Our Concierge will handle the application process after initiation&mdash;working with deal parties and management to ensure error-free digital submission.
            </p>
          </div>
        </div>
        <p className='emphasized'>
          *Custom applications require full service assistance.
        </p>
        <div className='buttons'>
          <button className='btn-link cancel-link' onClick={hide}>cancel</button>
          <button className={`btn-base btn-${!type? 'disabled' : 'green-stroke'}`} disabled={!type} onClick={save}>
            next
          </button>
        </div>
      </div>
    </div>
  )
}

export default SelectTypeModal
