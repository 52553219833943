import { isPlainObject, startsWith, endsWith } from 'lodash'
import { analyzeValue } from './utilities'

import { toAbsolutePath } from '../form'

function analyzeHash(hash, request) {
  return Object.entries(hash).every(([key, value]) => analyzeHashItem(key, value, request))
}

function analyzeHashItem(key, value, request) {
  // AND
  switch(key) {
    case "_and":
      return value.every(item => check(item, request))
    case "_or":
      return value.some(item => check(item, request))
    case "_not":
      return !check(value, request)
  }

  if (key[0] == "^")
    return !analyzeSingle(key.slice(1), value, request)

  return analyzeSingle(key, value, request)
}

const isBlank = val => !val || String(val).match(/^\s*$/)
const exists = val => !isBlank(val)

function analyzeSingle(key, condition, request) {
  const key_value = analyzeValue(key, request)

  if (isPlainObject(condition))
    return analyzeSingleHashItem(key_value, Object.entries(condition)[0], request)
  else if (Array.isArray(condition))
    return condition.some(item =>  key_value == analyzeValue(item, request))
  else if (condition == "*")
    return exists(key_value)
  else
    return key_value == analyzeValue(condition, request)
}

function analyzeSingleHashItem(key, [subkey, value], request) {
  switch(subkey) {
    case 'lt':
      return key < analyzeValue(value, request)
    case 'lte':
      return key <= analyzeValue(value, request)
    case 'gt':
      return key > analyzeValue(value, request)
    case 'gte':
      return key >= analyzeValue(value, request)
    case 'between':
      return key >= analyzeValue(value[0], request) && key <= analyzeValue(value[1], request)
  }
}

function check(conditional, request) {
  if (isPlainObject(conditional))
    return analyzeHash(conditional, request)

  return analyzeValue(conditional, request)
}

export default function(conditional, pathMap, relativeTo = null) {
  return check(conditional, { pathMap, relativeTo })
}