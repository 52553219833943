import React, { useState, useEffect } from 'react'
import AllStepsModal from './all_steps_modal'
import clsx from 'clsx'

const Button = ({ onClickStart, text, img, btnClasses }) => {
  return (
    <button className={clsx('request-a-quote', btnClasses)} onClick={onClickStart}>
      {img && <img src={img} />} {text}
    </button>
  )
}

const AllStepsModalButton = (props) => {
  const { text, button_image, btn_classes, type, ...modalProps } = props
  const [step, setStep] = useState(0)

  const onClickStart = () => {
    type ? setStep(2) : setStep(1)
  }

  const handleCloseModal = () => setStep(0)

  return (
    <>
      <Button onClickStart={onClickStart} text={text} img={button_image} btnClasses={btn_classes} />
      <AllStepsModal step={step} onClose={handleCloseModal} type={type} {...modalProps} />
    </>
  )
}

export default AllStepsModalButton
