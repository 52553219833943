import React, { useState } from 'react'

const useLoader = () => {
  const [loading, setLoading] = useState(false)

  const withLoading = async (callback) => {
    setLoading(true)
    try {
      return await callback()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {loading, withLoading}
}

export default useLoader
