import React from 'react'
import PropTypes from 'prop-types'

import ApplyButton from './apply_button'

const TemplateRow = (props) => {
  const {
    userSignedIn,
    buildingTemplate,
    userRoleRestrictedFromApplying,
    buildingTemplate: { active_state, building_id,
      package_template: { hashed_id, package_type, label, package_machine_id }
    }
  } = props

  return <div className="row" style={{ padding: "0 0 6px 0" }}>
    <div className="package-template-row">
      <span>{label}</span>
      <span className='pull-right'>
        <button
          className='dome-btn btn-base btn-link preview-link-btn'
          onClick={() => props.handleClickPreviewBtn(buildingTemplate)}
        >
          <i className="fa-light fa-magnifying-glass"></i>
          preview
        </button>
        { active_state == "apply_online"
          ? <ApplyButton
              hashedId={hashed_id}
              packageType={package_type}
              packageMachineId={package_machine_id}
              buildingId={building_id}
              userRoleRestrictedFromApplying={userRoleRestrictedFromApplying}
              userSignedIn={userSignedIn} />
          : null}
      </span>
    </div>
  </div>
}

TemplateRow.propTypes = {
  buildingTemplate: PropTypes.object.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
  userRoleRestrictedFromApplying: PropTypes.bool
}

export default TemplateRow
