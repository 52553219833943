import React, { useRef, useEffect, useState } from 'react'
window.pdfjsLib = window['pdfjs-dist/build/pdf'] = require('pdfjs-dist/build/pdf')
window.pdfjsWorker = window['pdfjs-dist/build/pdf.worker'] = require('pdfjs-dist/build/pdf.worker')

const PdfViewer = ({ url, onError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const canvasContainerRef = useRef(null)

  useEffect(() => {
    // TODO: Improve rendering performance
    const renderPDF = async () => {
      setIsLoading(true)
      try {
        const loadingTask = pdfjsLib.getDocument(url)
        const pdf = await loadingTask.promise
        for (let num = 1; num <= pdf.numPages; num++) {
          const page = await pdf.getPage(num)
          renderPage(page)
        }
      } catch (error) {
        onError()
      }

      setIsLoading(false)
    }

    const renderPage = async (page) => {
      const pixelRatio = window.devicePixelRatio || 1
      const scale = 1
      const viewport = page.getViewport({ scale: scale })
      const canvasWrapper = document.createElement('div')
      canvasWrapper.className = 'canvas-wrapper'
      const canvas = document.createElement('canvas')
      canvas.height = viewport.height * pixelRatio
      canvas.width = viewport.width * pixelRatio
      canvas.style.width = viewport.width + 'px'
      canvas.style.height = viewport.height + 'px'
      const ctx = canvas.getContext('2d')
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      }
      canvasWrapper.appendChild(canvas)
      canvasContainerRef.current.appendChild(canvasWrapper)

      await page.render(renderContext)
    }

    renderPDF()
  }, [url])

  const renderLoading = () => {
    if (!isLoading) return null

    return (
      <div className="pdf-loading">
        <div className="spinner-border"></div>
      </div>
    )
  }

  return (
    <div className="pdf-viewer-wrapper">
      {renderLoading()}
      <div className="insurance-pdf-viewer" ref={canvasContainerRef} />
    </div>
  )
}

export default PdfViewer
