import React from 'react'

export default () => (
  <div>
    <h1>How to use this tool:</h1>
    <ol>
      <li>Click “Selecting field for autocalculation?”</li>
      <li>Click on a field in your form that is supposed to be the Sum or Difference of other fields. The field should highlight yellow.</li>
      <li>While this field is highlighted, click “Operator” and choose whether this field is a Sum or Difference total.</li>
      <li>Next click “Selecting terms?”</li>
      <li>Click on the fields that are going to make up your total (i.e. terms). The fields should highlight green.</li>
      <li>Click “Save” once you’re done assigning terms.</li>
      <li>Test that everything adds or subtracts correctly by adding numbers into your terms.</li>
    </ol>

    <p>*Note that you can also set a field to calculate the Sum or Difference of other Sum or Difference fields.</p>
    <p>**Also note that all fields are USD ($) based. All numbers entered in will automatically convert to include a $.</p>
  </div>
)
