import React from "react"

class BuildingModal extends React.Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
    this.printBuildingIds = this.printBuildingIds.bind(this)
  }

  componentDidMount() {
    const modal = $(this.modalRef.current)
    modal.on("hidden.bs.modal", () => this.close())
    modal.modal("show")
  }

  close() {
    if (this.props.onClose) this.props.onClose()
  }

  printBuildingIds() {
    const { buildingIds } = this.props
    let breaks = []
    if (buildingIds.length > 0) {
      buildingIds.sort()
      buildingIds.forEach((id, i) => {
        breaks.push(<div key={i}>{id}</div>)
      })
      return (
        <div>
          <i>
            {" "}
            *Duplicate IDs mean that this form is in MORE THAN one application
            of that building{" "}
          </i>
          <br />
          <br />
          <u> Building ID(s): </u>
          {breaks}
        </div>
      )
    } else return <i> There are no buildings using this form. </i>
  }

  render() {
    const { form } = this.props
    return (
      <div className='modal fade' tabIndex='-1' role='dialog' ref={this.modalRef}>
        <div className='modal-dialog modal-md' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
              <h5 className='modal-title'>Buildings using: {form.name}</h5>
            </div>
            <div className='modal-body'>{this.printBuildingIds()}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BuildingModal