import React from 'react'
import SignatureCanvas from 'react-signature-canvas'

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.canvas = React.createRef()
    this.container = React.createRef()
    
    this.trackSize = () => {
      if (!this.canvas.current)
        return
        
      const canvas = this.canvas.current.getCanvas()
      canvas.height = canvas.offsetHeight || this.container.current.offsetHeight
      canvas.width = canvas.offsetWidth || this.container.current.offsetWidth
    }
  }

  componentDidMount() {
    this.trackSize()
    setTimeout(this.trackSize, 500)
    window.addEventListener("resize", this.trackSize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.trackSize)
  }

  getSignature() {
    return this.canvas.current.getTrimmedCanvas().toDataURL("image/png")
  }

  clearSignature() {
    this.canvas.current.clear()
  }

  render() {
    return <div className="draw-signature" ref={this.container}>
      <div className="line" />
      <SignatureCanvas ref={this.canvas} canvasProps={{width: 100, height: 100}} />
    </div>
  }
}