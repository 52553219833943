import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mapValues } from 'lodash'
import { load as loadPages, setFormName, setPageWidth } from '../features/pages/pageSlice'
import { load as loadComponents } from '../features/components/componentSlice'
import { updateTemplates } from '../features/data/dataSlice'
import DocumentArea from '../features/components/DocumentArea'
import DocumentAreaWrapper from '../common/DocumentAreaWrapper'
import axios from 'axios'
import { uniqueComponentKey } from '../common/utility'

const SIGNATURE_LABEL = {
  signature: 'signature',
  name: 'full name',
  time: 'date signed',
}

const POSITIONS = ['x', 'y', 'width', 'height', 'page']

const isPercent = (x) => String(x).slice(-1) == '%'
const removePercentage = (x) => parseFloat(isPercent(x) ? String(x).slice(0, -1) : x)
const removePercentagesFromPosition = (pos) =>
  Object.fromEntries(POSITIONS.map((attr) => [attr, removePercentage(pos[attr])]))

function componentFromFieldPosition({ id, position, label, placeholder }) {
  return {
    id,
    position: removePercentagesFromPosition(position),
    type: 'data',
    label: label || 'Field',
    placeholder: placeholder || 'Placeholder',
  }
}

function componentFromSignature({ id, positions }) {
  return Object.entries(positions).map(([subtype, position]) => ({
    id,
    subtype,
    position: removePercentagesFromPosition(position),
    type: 'signature',
    label: SIGNATURE_LABEL[subtype],
    placeholder: SIGNATURE_LABEL[subtype],
  }))
}

function updateComponents({ dispatch, field_positions = [], signatures = [] }) {
  field_positions = Object.fromEntries(field_positions.map((position) => [position.id, position]))
  const components = []
    .concat(Object.values(field_positions).map(componentFromFieldPosition))
    .concat(Object.values(signatures).map(componentFromSignature).flat())

  const dataTemplates = mapValues(field_positions, (field_position) => {
    field_position.position = uniqueComponentKey('data', field_position.id)

    return field_position
  })
  const signatureTemplates = mapValues(signatures, (signature) => {
    Object.keys(signature.positions).forEach((key) => {
      signature.positions[key] = uniqueComponentKey('signature', signature.id, key)
    })

    return signature
  })

  dispatch(loadComponents(components))
  dispatch(updateTemplates({ type: 'data', templates: dataTemplates }))
  dispatch(updateTemplates({ type: 'signature', templates: signatureTemplates }))
}

const Loader = () => {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  const allPages = useSelector(({ pages }) => pages.all)

  const fetchAndLoadData = useCallback(async () => {
    try {
      const response = await axios.get('form_builder/data', { params: { width: 1400 } })
      const { form } = response.data
      const pages = { pages: form.pages, page_files: form.page_files }

      dispatch(setPageWidth(form.page_width))
      dispatch(loadPages(pages))
      dispatch(setFormName(form.name))
      updateComponents({
        dispatch,
        field_positions: form?.field_positions,
        signatures: form?.signatures,
      })

      setLoaded(true)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetchAndLoadData()
  }, [fetchAndLoadData])

  return (
    loaded && (
      <DocumentAreaWrapper>
        {allPages.map((page) => (
          <DocumentArea id={`page-${page.id}`} page={page.id} key={page.id} />
        ))}
      </DocumentAreaWrapper>
    )
  )
}

export default Loader
