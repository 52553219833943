import React, { createContext, useContext, useState } from "react"
import { compact } from "lodash"

const FAQContext = createContext()

const FAQProvider = ({ children, onToggleQuestion }) => {
  const [activeQuestion, setActiveQuestion] = useState(null)

  const toggleQuestion = (id, question) => {
    const isOpening = activeQuestion == id
    setActiveQuestion(isOpening ? "" : id)
    onToggleQuestion(question, !isOpening)
  }

  return (
    <FAQContext.Provider value={{ activeQuestion, toggleQuestion }}>
      {children}
    </FAQContext.Provider>
  )
}

function QuestionResponse({ question, id }) {
  const { activeQuestion, toggleQuestion } = useContext(FAQContext)
  const questionClasses = compact([
    "faq-question",
    activeQuestion == id ? "active" : null,
  ])

  const responseClasses = compact([
    "faq-response",
    activeQuestion == id ? "active" : null,
  ])

  return (
    <div className='faq-questions-accordion'>
      <div
        className={questionClasses.join(" ")}
        onClick={() => toggleQuestion(id, question)}
      >
        {question.question}
      </div>
      <div className={responseClasses.join(" ")}>{question.response}</div>
    </div>
  )
}

function Section({ title, questions, group }) {
  return (
    <div className='faq-title-questions-container'>
      {title && <div className='faq-section-title'>{title}</div>}
      <div className='accordion-container'>
        {questions.map((question, index) => (
          <QuestionResponse
            key={`${group}-${index}`}
            id={`${group}-${index}`}
            question={question}
          />
        ))}
      </div>
    </div>
  )
}

function Sections({ questions, showSectionTitles }) {
  return (
    <>
      {Object.entries(questions).map(([groupKey, groupValue]) => (
        <Section
          key={groupKey}
          group={groupKey || 'questions'}
          title={showSectionTitles ? (groupValue?.title || 'questions') : null}
          questions={groupValue?.questions}
        />
      ))}
    </>
  )
}

function FAQ({ questions, showSectionTitles, onToggleQuestion }) {
  return (
    <FAQProvider onToggleQuestion={onToggleQuestion}>
      <div className='question-response-wrapper'>
        <Sections questions={questions} showSectionTitles={showSectionTitles} />
      </div>
    </FAQProvider>
  )
}

FAQ.defaultProps = {
  showSectionTitles: true,
  onToggleQuestion: () => null
}

export default FAQ
