import React from "react";
import PropTypes from "prop-types";

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $(
    "meta[name=csrf-token]"
  ).attr("content");
  return data;
}

function updateQuestionnaireProcessorRequiredLogin(
  url,
  value,
  successCallback
) {
  return $.ajax({
    url: url,
    type: "post",
    data: addMeta({
      is_questionnaire_processor_required_login: value ? 1 : 0,
    }),
    success: () => {
      successCallback(value);
    },
  });
}

export default function EnableRequiredLoginProcessor(props) {
  const {
    organization,
    isQuestionnaireProcessorRequiredLogin,
    setIsQuestionnaireProcessorRequiredLogin,
  } = props;

  function handleChangeValue(value) {
    const url = `/super/organizations/${organization.id}/questionnaires/enable_required_login_processor`;

    updateQuestionnaireProcessorRequiredLogin(
      url,
      value,
      setIsQuestionnaireProcessorRequiredLogin
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-4">Enable Required Login Processor?</div>
        <div className="col-sm-4">
          <select
            defaultValue={isQuestionnaireProcessorRequiredLogin ? "1" : "0"}
            onChange={(e) => handleChangeValue(parseInt(e.target.value) == 1)}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>
    </>
  );
}

EnableRequiredLoginProcessor.propTypes = {
  organization: PropTypes.object.isRequired,
  isQuestionnaireProcessorRequiredLogin: PropTypes.bool.isRequired,
  setIsQuestionnaireProcessorRequiredLogin: PropTypes.func.isRequired,
};
