import React from 'react'
import $ from 'jquery'

import { addCSRF } from 'lib/document_utilities'

const isBlank = val => !val || String(val).match(/^\s*$/)

function apiRevokeState(id, { state, reason }) {
  const url = `/packages/${id}/revoke`
  const data = addCSRF({ state, reason })

  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      type: 'DELETE',
      dataType: "json",
      data,
      success: data => resolve(data),
      error: xhr => reject(xhr)
    })
  })
}

function popMissingInformation({ packageHashedId, tag }) {
  swal({
    title: 'Missing Information',
    text: "It looks like the DOB and SSN information is missing for at least one applicant. This information will be required in order to run a credit check after " + tag + " submission.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: "#F68A41",
    confirmButtonText: "Go to Deal Parties",
    cancelButtonText: 'OK',
    closeOnConfirm: true
  }, isConfirm => {
    if (isConfirm)
      window.location.href = "/packages/" + packageHashedId
  })
}

function confirmationMessage({ tag, processorTag }, success) {
  const title = `Submit to ${processorTag} Processor`
  const text = [
    `Please confirm that you would like to send this ${tag} to the ${processorTag} Processor(s).`,
    "Please ensure that all personally identifiable information (including social security numbers) has been redacted."
  ].join("\n\n")

  swal({
    title,
    text,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonColor: "#F68A41",
    cancelButtonText: 'Cancel',
    // cancelButtonColor: "#C1C1C1",
    closeOnConfirm: false,
    closeOnCancel: true
  }, confirm => confirm ? success() : null)
}

function popSuccessfulSubmit({ tag, processorTag }) {
  swal(
    'Success!',
    'You have successfully submitted this ' + tag + ' to the ' + processorTag + ' Processor(s) for review.',
    "success")
}

function popWithdraw({ processorTag }, callback) {
  const swalText = "Please explain in a few words why you are withdrawing access. The reason you insert below will be communicated to the " + processorTag + " Processor(s)."

  swal({
    type: "warning",
    title: "Withdraw Access?",
    text: swalText + "<textarea id='withdraw-reason' placeholder='Enter reason for withdrawing access' style='display: block;width: 100%;margin:10px 0;min-height: 60px; padding: 5px 10px;'></textarea>",
    html: true,
    showCancelButton: true,
    confirmButtonText: "Withdraw Access",
    confirmButtonColor: "#F68A41",
    closeOnConfirm: false,
    closeOnCancel: true
  }, onConfirm => {
    const reason = document.getElementById('withdraw-reason').value
    callback(onConfirm, reason)
  })
}

function popWithdrawDisclaimer({ processorTag }, callback) {
  swal({
    title: "<i class='fa-thin fa-triangle-exclamation withdraw-icon'></i><p class='withdraw-text'> Would you rather update the application?</p>",
    text: "<p id='withdraw-body-text'>Our representatives can help you do so without impacting the rest of your submission. <br/><br/> " +
      "For assistance, message the " + processorTag + " Processor(s) " + "through the Requirements section or <a href='mailto:info@domecile.com' style='color: #F68A41'>info@domecile.com</a></p>",
    html: true,
    showCancelButton: true,
    confirmButtonText: "No, Withdraw Application",
    confirmButtonColor: "#B30000",
    closeOnCancel: true,
    closeOnConfirm: false,
  }, onConfirm => {

    //since the html: true parameter renders html into the dom, had to be sure to remove it
    //when accepted, in order for next sweet alert instance to not conflict with pre-existing html from this swal
    const this_swal = document.getElementsByClassName("sweet-alert")
    const this_swal_overlay = document.getElementsByClassName("sweet-overlay")

    while (this_swal.length && this_swal_overlay.length > 0) {
      this_swal[0].parentNode.removeChild(this_swal[0]);
      this_swal_overlay[0].parentNode.removeChild(this_swal_overlay[0]);
    }

    callback(onConfirm)
  })
}

function popWithdrawn({ tag, processorTag }) {
  swal(tag + ' Withdrawn',
    `You have successfully withdrawn this ${tag} from the ${processorTag} Processor(s).`,
    "success")
}

function popWithdrawError({ tag, processorTag }) {
  swal(tag + ' Withdraw Error',
    `${tag} cannot be withdrawn from the ${processorTag} Processor(s) because fees have been collected`,
    "error")
}

function popCannotSubmit() {
  swal({
    title: "Cannot Submit",
    text: "Please ensure that all requirements have been completed and all fees have been authorized.",
    type: "warning",
    showCancelButton: false,
    confirmButtonColor: "#C1C1C1",
    confirmButtonText: "Close",
    closeOnConfirm: true
  })
}

function renderWithdrawTooltip() {
  return <>
    <span className="tooltip-text">
      Don't withdraw the application just to make changes.<br /><br />
      Our representatives can help you make updates without impacting the rest of your submission.<br />
      <a href="mailto:info@domecile.com" style={{ color: "#F68A41" }}>info@domecile.com</a>
    </span>
  </>
}

class ApplyStateButton extends React.Component {
  static defaultProps = {
    tooltip: false,
    tooltipText: '',
    tooltipDirection: 'top',
    stateApplied: false,
    disableButton: false,
    buttonIcon: '',
    buttonClass: 'btn-warning',
    buttonText: 'Submit',
    buttonTextTop: '',
    buttonTextBottom: '',
    revokeText: 'Revoke',
    additionalClass: 'btn-block'
  }

  constructor(props) {
    super(props)

    this.state = {
      unrevokable: false,
      revoked: false,
      packageHashedId: props.package_hashed_id,
      buttonState: props.state,
      stateApplied: props.state_applied,
      buttonClass: props.button_class,
      buttonText: props.button_text,
      buttonTextTop: props.button_text_top,
      buttonTextBottom: props.button_text_bottom,
      buttonIcon: props.button_icon,
      revokeText: props.revoke_text,
      additionalClass: props.additional_class,
      unrevokable: props.unrevokable,
      revoked: props.revoked,
      incomplete: props.incomplete,
      tag: $("#reset-fees-btn").attr('data-tag'),
      processorTag: props.processor_tag || $("#reset-fees-btn").attr('data-tag'),
      withdraw: props?.withdraw,
    }
  }

  componentDidMount() {
    if (this.props.ref_name)
      window[this.props.ref_name] = this
  }

  updateState(buttonState) {
    const state = { buttonState }
    if (buttonState == 'incomplete')
      state.incomplete = false
    if (this.state.unrevokable)
      state.revoked = true
    this.setState(state)
  }

  possibleRedirect({ redirect }) {
    if (!redirect)
      return

    window.location.href = `/packages/${this.state.packageHashedId}/voting`
  }

  hideResetBtn({ state }) {
    if (state == "managing_agent")
      $("#reset-fees-btn").hide()
  }

  hideWaiverBtn({ state }) {
    if (state == 'managing_agent')
      $('.waiver-btn').hide()
  }

  showResetBtn({ state }) {
    if (state == "managing_agent")
      $('#reset-fees-btn').show()
  }

  showWaiverBtn({ state }) {
    if (state == "managing_agent")
      $('.waiver-btn').show()
  }

  hideStatusColumn() {
    $('.processor-status-th').hide()
    $('.processor-status-td').hide()
  }

  showStatusColumn(data) {
    $('.processor-status-th').show()
    $('.processor-status-td').show()
  }

  applyState(e) {
    const hashed_id = this.state.packageHashedId

    const applyState = success => {
      $.ajax({
        url: `/packages/${hashed_id}/apply`,
        type: 'POST',
        dataType: "json",
        data: addCSRF({ state: this.state.stateApplied }),
        success
      })
    }

    const processDue = () => {
      $.ajax({
        url: `/packages/${hashed_id}/capture_fees`,
        type: 'POST',
        dataType: 'json',
        data: addCSRF({ hashed_id })
      })
    }

    const confirmationSucceded = data => {
      this.updateState(true)
      this.possibleRedirect(data)
      this.hideResetBtn(data)
      this.hideWaiverBtn(data)
    }

    if (this.props.incomplete_buyers_data)
      return popMissingInformation(this.state)

    if (!this.props.confirmation_message)
      return applyState(confirmationSucceded)

    confirmationMessage(this.state, () => {
      applyState(data => {
        this.updateState('incomplete')
        confirmationSucceded(data)
        popSuccessfulSubmit(this.state)

        if (data.onlyProcessingDue)
          processDue()
      })
    })
  }

  revokeState(e) {
    const package_hashed_id = this.props.package_hashed_id
    const stateApplied = this.state.stateApplied

    const success = data => {
      popWithdrawn(this.state)
      this.updateState(false)
      this.showResetBtn(data)
      this.showWaiverBtn(data)
    }

    const error = () => {
      popWithdrawError(this.state)
      $('#submit-management-btn').remove()
    }

    popWithdrawDisclaimer(this.state, (isConfirm) => {
      if (!isConfirm)
        return

      popWithdraw(this.state, (isConfirm, reason) => {
        if (reason == "") {
          $(".sa-error-container").html("<div class='icon'>!</div><p>This field cannot be blank.</p>").addClass('show')
          return
        }

        if (!isConfirm)
          return

        apiRevokeState(package_hashed_id, { state: stateApplied, reason }).then(success).catch(error)

      })
    })
  }

  renderDisabled() {
    const tooltipProps = {
      'data-toggle': "tooltip",
      'data-placement': this.props.tooltipDirection,
      title: this.props.tooltipText
    }

    const buttonClasses = 'btn btn-default disabled ' + this.state.additionalClass
    const onClick = () => this.props.tooltip ? popCannotSubmit() : null

    const buttonText = [<span key="text">{this.state.buttonText}</span>]
    if (this.state.buttonIcon != '')
      buttonText.unshift(<i key="icon" style={{ marginRight: "5px" }} className={this.state.buttonIcon} />)

    return <div className="btn-group btn-block" {... (this.props.tooltip ? tooltipProps : {})}>
      <button type="button" className={buttonClasses} onClick={onClick}>
        {buttonText}
      </button>
    </div>
  }

  renderApply() {
    const buttonClasses = ['btn', this.state.buttonClass, this.state.additionalClass]
    const onClick = () => this.applyState(this.state.stateApplied)

    this.hideStatusColumn()
    return <div className="btn-group btn-block">
      <button className={buttonClasses.join(" ")} type="button" onClick={onClick}>
        {isBlank(this.state.buttonTextTop) ? this.renderButtonText() : this.renderTwoPartButton()}
      </button>
    </div>
  }

  renderButtonText() {
    const icon = isBlank(this.state.buttonIcon) ? null : <i style={{ marginRight: "5px" }} className={this.state.buttonIcon} />

    return <div className="btn-text">
      {icon}
      <span>{this.state.buttonText}</span>
    </div>
  }


  renderTwoPartButton() {
    const icon = isBlank(this.state.buttonIcon) ? null : <i style={{ marginRight: "5px" }} className={this.state.buttonIcon} />

    return <div className="btn-text">
      {icon}
      <div className="btn-text" style={{ fontSize: 26 }}>
        {this.state.buttonTextTop}
      </div>
      <div className="btn-text">{this.state.buttonTextBottom}</div>
    </div>
  }


  renderRevoke() {
    this.showStatusColumn()

    const tooltipProps = {
      'data-toggle': "tooltip",
      'data-placement': this.props.tooltipDirection,
      title: this.props.tooltipText
    }

    return <div className="btn-block " style={{ display: 'inline' }}>
      <div className={this.props.withdraw ? "withdraw-tooltip" : null}>
        <button {... (this.props.tooltip ? tooltipProps : {})} className={`btn btn-danger ${this.state.additionalClass}`} type="button" onClick={() => this.revokeState(this.state.stateApplied)}>
          {this.state.revokeText}
        </button>
        {this.props?.withdraw ? renderWithdrawTooltip() : null}
      </div>
      <button className="btn btn-success pull-right pp-submission-btn" type="button" disabled={true} style={{ marginRight: '5px', display: 'none' }}>
        <i style={{ marginRight: '5px' }} className="fa fa-envelope" />
        <span>Submit to {this.state.processorTag}  Processor(s)</span>
      </button>
    </div>
  }

  renderUnrevokable() {
    const icon = isBlank(this.state.buttonIcon) ? null : <i className={this.state.buttonIcon} style={{ marginRight: "5px" }} />
    const buttonClasses = `btn btn-default disabled ${this.state.additionalClass}`
    return <div className="btn-group btn-block">
      <button className={buttonClasses} type="button">
        {icon}
        <span>{this.state.revokeText}</span>
      </button>
    </div>
  }

  render() {
    // this first button render is only for when the package is submitted + fees captured
    if (this.props.buttonDisabled && this.state.buttonState && !this.state.incomplete)
      return this.renderDisabled()
    else if (this.state.revoked && this.state.unrevokable)
      return this.renderUnrevokable()
    else if (this.state.buttonState && !this.state.incomplete)
      return this.renderRevoke()
    else
      return this.renderApply()
  }
}

export default ApplyStateButton
