import React from 'react'
import { connect } from 'react-redux'
import Store from '../Store'

export const modalErrorKeys = {
  package_processor_permissions_cannot_be_removed: "package_processor_permissions_cannot_be_removed",
  email_already_belongs_to_a_property_contact_or_a_board_member: "email_already_belongs_to_a_property_contact_or_a_board_member",
  package_processor_cannot_be_broker: "package_processor_cannot_be_broker",
};

const modalErrors = {
  package_processor_permissions_cannot_be_removed: "Package Processor Permissions Cannot Be Removed",
  email_already_belongs_to_a_property_contact_or_a_board_member: "A Property Contact or Board Member already exist with this email",
  package_processor_cannot_be_broker: "A Broker cannot be a Package Processor",
};

class Error extends React.Component {
  constructor(props) {
    super(props)
  }

  headerContent() {
    let headerElements = [
      <i key={1} className='fa-light fa-triangle-exclamation' style={{ fontSize: 80, margin: '10px 0 10px', color: '#B31F00' }} />
    ]
    switch(this.props.modalErrorDetails.name) {
      case modalErrorKeys.package_processor_permissions_cannot_be_removed:
        headerElements.push(<h3 key={2} className="modal-title">{modalErrors.package_processor_permissions_cannot_be_removed}</h3>)
        break
      case modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member:
        headerElements.push(<h3 key={2} className="modal-title">{modalErrors.email_already_belongs_to_a_property_contact_or_a_board_member}</h3>)
        break
      case modalErrorKeys.package_processor_cannot_be_broker:
        headerElements.push(<h3 key={2} className="modal-title">{modalErrors.package_processor_cannot_be_broker}</h3>)
        break
      default:
        break;
    }

    return headerElements
  }

  renderHeader() {
    return <div className='modal-header' style={{borderBottom: 'none'}}>
      {this.headerContent()}
    </div>
  }

  renderBody() {
    switch(this.props.modalErrorDetails.name) {
      case modalErrorKeys.package_processor_permissions_cannot_be_removed:
        return this.renderBodyForPackageProcessorPermissionsCannotBeRemoved()
      case modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member:
        return this.renderBodyForEmailAlreadyInUse()
      case modalErrorKeys.package_processor_cannot_be_broker:
        return this.renderBodyForBrokerAddedAsPackageProcessor()
      default:
        break
    }
  }

  renderBodyForPackageProcessorPermissionsCannotBeRemoved() {
    return <div className='modal-body text-center'>
      <div>There must be another <b>Package Processor</b> assigned <u>before</u> you can remove this user's permissions.</div>
      <div><b>There must be a replacement assigned for the following application types:</b></div><br />
      {this.props.modalErrorDetails.package_types.map(package_type => <div key={package_type} style={{color: '#C53C3B'}}>{package_type}</div>)}
    </div>
  }

  renderBodyForEmailAlreadyInUse() {
    return <div className='modal-body text-center'>
      <div style={{color: '#C53C3B'}}>{this.props.modalErrorDetails.email}</div>
    </div>
  }

  renderBodyForBrokerAddedAsPackageProcessor() {
    <div className='modal-body text-center'>
      <div style={{color: '#C53C3B'}}>{this.props.modalErrorDetails.email}</div>
    </div>
  }

  renderFooterForPackageProcessorPermissionsCannotBeRemoved() {
    return <div className='modal-footer' style={{ textAlign: 'center' }}>
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal">I Understand</button>
    </div>
  }

  renderFooterForEmailAlreadyInUse() {
    return <div className='modal-footer' style={{ textAlign: 'center' }}>
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal">Okay</button>
    </div>
  }

  renderFooterForBrokerAddedAsPackageProcessor() {
    return <div className='modal-footer' style={{ textAlign: 'center' }}>
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal">Okay</button>
    </div>
  }

  renderFooter() {
    switch(this.props.modalErrorDetails.name) {
      case modalErrorKeys.package_processor_permissions_cannot_be_removed:
        return this.renderFooterForPackageProcessorPermissionsCannotBeRemoved()
      case modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member:
        return this.renderFooterForEmailAlreadyInUse()
      case modalErrorKeys.package_processor_cannot_be_broker:
        return this.renderFooterForBrokerAddedAsPackageProcessor()
      default:
        break
    }
  }

  render() {
    return <div className="modal-content modal-error">
      {this.renderHeader()}
      {this.renderBody()}
      {this.renderFooter()}
    </div>

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    modalErrorDetails: state.modal.modalErrorDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(Store.closeModal()),
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsiblePartyRoles: (userEmail, roles) => dispatch(Store.updateBuildingResponsiblePartyRoles(userEmail, roles))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Error)
