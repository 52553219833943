import axios from 'lib/axios'

export const createQuoteApi = async (data) => {
  return await axios.post('/insurance/quotes', data)
}

export const createQuoteRequestApi = async (quote_id, data) => {
  return await axios.post(`/insurance/${quote_id}/quote_requests`, data)
}

export const getQuoteRequestStatusApi = async (requestId) => {
  return await axios.get(`/insurance/quote_requests/${requestId}/status`)
}

export const getSignatureApi = async () => {
  return await axios.get(`/user_signatures`)
}

export const createPolicyRequestApi = async (quote_id, data) => {
  return await axios.post(`/insurance/quotes/${quote_id}/request_issue_policy`, data)
}

export const getQuoteStatusApi = async (quoteId) => {
  return await axios.get(`/insurance/quotes/${quoteId}/status`)
}


export const getBuildingRequirementsApi = async (buildingId) => {
  return await axios.get(`/insurance/buildings/${buildingId}/requirements`)
}
