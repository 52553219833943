import React, { Component } from "react"
import { compact } from 'lodash'

const uniqueIdForPrice = price => [price.is_org ? "org" : "building", price.id].join(":")
class PresetPrice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dynamicValue: "",
      focused: false
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.props.selected != prevProp.selected) {
      this.setState({dynamicValue: ""})
      if (this.refs.input)
        this.refs.input.value = ""
    }
  }

  click() {
    this.props.onSelect()
    if (this.refs.input)
      this.refs.input.focus()
  }

  updateAmount(price) {
    this.props.onUpdatePrice(price)
  }

  updateDynamicValue(value) {
    this.setState({dynamicValue: value})
  }


  render() {
    const price = this.props.price
    const name = price.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

    const classes = ["flex-item"]
    if (this.props.selected)
      classes.push("active")

    return <div className={classes.join(" ")} onClick={() => this.click()}>
      <h4 className="questionnaire-pricing-name">{name}</h4>
      { price.dynamic_price ? this.renderDynamicPrice() : this.renderPrice() }
    </div>
  }

  renderDynamicPrice() {
    const onChange = event => this.updateDynamicValue(event.target.value)
    const onBlur = event =>  this.updateAmount(event.target.value)

    return (
      <div className="dynamic-price" >
        <input defaultValue={this.state.dynamicValue} type="number" min="0" ref="input" onChange={onChange} onBlur={onBlur}/>
        <i>$</i>
      </div>
    )
  }

  renderPrice() {
    const { price } = this.props.price
    const isFree = price == 0

    const classes = compact([
      'questionnaire-pricing-price',
      isFree ? "is-free" : null
    ]).join(" ")

    const text = isFree ? "Free" : `$${price}`

    return <h4 className={classes}>{text}</h4>
  }
};


export default class SetPresetPrices extends Component {
  constructor(props) {
    super(props)

    this.state = { }
  }

  selectPossiblePrice(possible_price) {
    this.setState({ possible_price })
    this.props.onChangePossiblePrice(possible_price)
  }

  updatePriceAmount(price) {
    this.props.onChangePriceAmount(price)
  }

  setFocusedId(id) {
    this.setState({focusedId: id})
  }


  render() {
    const prices = this.props.possible_prices.map(possible_price =>
      <PresetPrice  key={uniqueIdForPrice(possible_price)} price={possible_price}
                    selected={this.state.possible_price == possible_price}
                    onSelect={() => this.selectPossiblePrice(possible_price)}
                    onUpdatePrice={amount => this.updatePriceAmount({...possible_price, price: amount})} 
                    />

    )

    return(<div className="flex-container">{prices}</div>)
  }
};
