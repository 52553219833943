import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelRemoveService,
  removeService,
  deleteService,
  removeCategory,
  cancelRemoveCategory,
  deleteCategory,
  selectBuildingEditResidentPayments,
} from "./store/slice";

export function useRemoveServiceModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    newServicesIds,
    beRemovedService: { id },
  } = useSelector(selectBuildingEditResidentPayments);

  useEffect(() => {
    if (id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [id]);

  const onCancel = useCallback(() => {
    dispatch(cancelRemoveService());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (newServicesIds.includes(id)) {
      dispatch(removeService());
    } else {
      dispatch(deleteService({ serviceId: id }));
    }
  }, [dispatch, newServicesIds, id]);

  return useMemo(
    () => ({
      removeServiceModalVisible: open,
      onServiceModalCancel: onCancel,
      onServiceModalRemove: onSubmit,
    }),
    [open, onCancel, onSubmit]
  );
}

export function useRemoveCategoryModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    newCategoriesIds,
    beRemovedCategory: { id },
  } = useSelector(selectBuildingEditResidentPayments);

  useEffect(() => {
    if (id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [id]);

  const onCancel = useCallback(() => {
    dispatch(cancelRemoveCategory());
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (newCategoriesIds.includes(id)) {
      dispatch(removeCategory());
    } else {
      dispatch(deleteCategory({ categoryId: id }));
    }
  }, [dispatch, newCategoriesIds, id]);

  return useMemo(
    () => ({
      removeCategoryModalVisible: open,
      onCategoryModalCancel: onCancel,
      onCategoryModalRemove: onSubmit,
    }),
    [open, onCancel, onSubmit]
  );
}
