import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { save, selectBuildingEditResidentPayments, selectIsFormValid, setInitialState } from "./store/slice";
import BuildingEditEnableResidentPayments from "./components/BuildingEditEnableResidentPayments";
import BuildingEditServices from "./components/BuildingEditServices";

export function BuildingEditResidentPayments(props) {
  const dispatch = useDispatch();

  const isFormValid = useSelector(selectIsFormValid);

  useEffect(() => {
    dispatch(setInitialState(props));
  }, [dispatch]);

  const { building, formTouched } = useSelector(selectBuildingEditResidentPayments);

  const handleSave = () => dispatch(save());

  if (!building) return null;

  return (
    <div className="container building-edit-resident-payments-container">
      {formTouched && (
        <div id="alert-needs-save" className="alert alert-warning container row">
          <div className="col-xs-12">
            <p className="pull-left">Looks like you've made some changes...</p>
            <button className="btn btn-default pull-right" onClick={handleSave} disabled={!isFormValid}>
              Save
            </button>
          </div>
        </div>
      )}
      <div>
        <h3 style={{ display: "inline" }}>{building.address}</h3>
        <span className="pull-right">
          <button
            className="btn btn-success save-building-update-ie"
            onClick={handleSave}
            disabled={!formTouched || !isFormValid}
          >
            Save
          </button>
          <a className="page-back btn btn-warning" href={`/buildings/${building.id}/edit`}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            Back to Building
          </a>
        </span>
      </div>
      <h4 className="building-edit-resident-payments-container-header">Resident Payments</h4>
      <BuildingEditEnableResidentPayments />
      <div className="building-edit-property-goods-services">Property Goods + Services</div>
      <BuildingEditServices />
    </div>
  );
}

BuildingEditResidentPayments.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    sub_merchant_dropdown_with_default: PropTypes.arrayOf(PropTypes.array).isRequired,
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
      position: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
      category_id: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      gl_code: PropTypes.string,
      charge_code: PropTypes.string,
    })
  ),
};
