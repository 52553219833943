import React from 'react'
import Rollover from '../buttons/rollover'

import { DropzoneComponent } from 'react-dropzone-component'

class NewDocuments extends React.Component {
  handleSuccessResponse(buildingData){
    this.props.onDropzoneSuccessResponse(buildingData)
  }

  render(){
    const self = this
    const componentConfig = {
        showFiletypeIcon: true,
        postUrl: `/buildings/${this.props.buildingId}/dropzone_upload`,
        maxFilesize: 2500,
        // Prevents Dropzone from uploading dropped files immediately
        autoProcessQueue: true,
        dictDefaultMessage: 'Drop Files Here to Upload or Click for File Manager',
        thumbnailWidth: "50px",
        thumbnailHeight: "50px",
        previewsContainer: '#dz-custom-modal .modal-body'
    };

    const djsConfig = {
      headers: {
        "X-CSRF-Token" : this.props.authenticityToken
      },
      addRemoveLinks: false,
      acceptedFiles: ".gif, .jpg, .jpeg, .png, .doc, .docx, .pdf"
    };

    const eventHandlers = {
      success: function(file, data){
        this.removeFile(file);
        var error = data.error
        if (error) {
          pdfReadingError(error)
        } else {
          self.handleSuccessResponse(data)
        }
      },
      dragover: function(){
        $('#modal-dropzone .dz-message').addClass('dz-hovered')
      },
      dragleave: function(){
        $('#modal-dropzone .dz-message').removeClass('dz-hovered')
      }
    }

    return (
      <div className='new-documents row'>
        <div className='col-xs-12'>
          <p>
            Use this section to allocate display information to your documents within the Building About page. Document info can be edited by clicking on the text or selection from the drop down. <b>If files are not defined they will revert to default. </b>
          <Rollover
            content="<strong>Default Settings:</strong><br>
              Display Name: 'File name'<br>
              Type: 'other'<br>
              Expiration: 'No expiration'<br>
              Price: ($0)"
              placement={'left'}
              html={true}
              className={'doc-tooltip'}
            />
          </p>
        </div>
        <div className='col-xs-1'></div>
        <div className="col-xs-10" id="modal-dropzone">
          <DropzoneComponent config={componentConfig}
                             eventHandlers={eventHandlers}
                             djsConfig={djsConfig}
           />
        </div>
        <div className='col-xs-1'></div>
      </div>
    )
  }
}

export default NewDocuments
