import React from 'react'
import $ from 'jquery'


import { TYPES as QUESTIONNAIRE_TYPES, transformWithFile } from './utilities'

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

const org_attribute = (item, attribute) => {
  return item.org_questionnaire_price ? item.org_questionnaire_price[attribute] : null
}

const transformPrice = price => {
  let requested_with_file = price.requested_with_file || org_attribute(item, "requested_with_file")
  return transformWithFile(price, requested_with_file)
}

const itemKey = item => `${item.is_org ? "org" : "building"}-${item.id}`

const isPriceCustom = item => {
  if (item.dynamic_price)
    return true
  if (item.price)
    return false

  return org_attribute(item, "dynamic_price")
}

const displayItemPrice = item => {
  if (isPriceCustom(item))
    return "Custom"

  return asMoney(item.price || org_attribute(item, "price") || 0)
}

const displayItemType = item => QUESTIONNAIRE_TYPES[item.requested_with_file || org_attribute(item, "requested_with_file")]

const orgDynamicPrice = item => !item.id || item.is_org ? item.dynamic_price : org_attribute(item, "dynamic_price")
const namePlaceholder = item => (!item.id || item.is_org ? item.name : org_attribute(item, "name") || "Questionnaire Name")
const nameValue = item => item.dirty.name || (item.is_building ? item.name : "")
const pricePlaceholder = item => (!item.id || item.is_org ? item.price : org_attribute(item, "price") || "Questionnaire Price")
const priceValue = item => item.dirty.price || (item.is_building ? item.price : "")
const withFileValue = item => typeof(item.dirty.with_file) == "undefined" ? item.with_file : item.dirty.with_file
const withoutFileValue = item => typeof(item.dirty.without_file) == "undefined" ? item.without_file : item.dirty.without_file
const isCustomValue = item => typeof(item.dirty.dynamic_price) == "undefined" ? isPriceCustom(item) : item.dirty.dynamic_price

class Pricing extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.items = props.items.map(item => transformPrice(item))
    this.state.new_item = {}
  }

  openItem(item) {
    this.__updateItem(item, new_item => new_item.dirty = {})
  }

  updateItem(item, attribute, value) {
    this.__updateItem(item, new_item => new_item.dirty[attribute] = value)
  }

  cancelItem(item) {
    this.__updateItem(item, new_item => delete new_item.dirty)
  }

  __updateItem(item, method) {
    const items = this.state.items.concat([]),
          index = items.indexOf(item),
          new_item = Object.assign({}, item)

    method(new_item)

    if (index == -1)
      return this.setState({new_item: new_item})

    items.splice(index, 1, new_item)
    this.setState({items})
  }

  checkName(item) {
    if (item.dirty.name)
      return !item.dirty.name.match(/^\s+$/)

    return true
  }

  checkPrice(item) {
    const not_dirty = typeof(item.dirty.price) == "undefined"
    const has_local_custom = typeof(item.dirty.dynamic_price) != "undefined"

    const local_custom = has_local_custom ? item.dirty.dynamic_price : false
    const is_custom = has_local_custom ? local_custom : isPriceCustom(item)
    const org_custom = orgDynamicPrice(item)

    // Nothing has changed
    if (not_dirty && !has_local_custom)
      return true

    // There is a price
    if (item.dirty.price)
      return item.dirty.price.match(/^[0-9]+(.[0-9]{2})?$/)

    // We set it to custom
    if (local_custom)
      return true

    // The org has a price
    if (!org_custom)
      return true

    return false
  }

  checkType(item) {
    const with_file = typeof(item.dirty.with_file) != "undefined" ? item.dirty.with_file : item.with_file
    const without_file = typeof(item.dirty.without_file) != "undefined" ? item.dirty.without_file : item.without_file

    return with_file || without_file
  }

  checkUpdates(item) {
    const dirty = item.dirty,
          is_building = !item.is_org

    if (!dirty)
      return false

    if (!this.checkName(item))
      return false
    if (!this.checkPrice(item))
      return false
    if (!this.checkType(item))
      return false

    return true
  }

  prepareData(item) {
    const data = Object.assign({}, item.dirty)

    if (!item.is_org || typeof(data.dynamic_price) != "undefined")
      data.dynamic_price = data.dynamic_price ? 1 : 0

    if (data.dynamic_price)
      delete data.price


    if (item.is_org)
      for (let attr in data)
        if (!data[attr] || data[attr] == item[attr])
          delete data[attr]

    const with_file = typeof(data.with_file) != "undefined" ? data.with_file : item.with_file
    const without_file = typeof(data.without_file) != "undefined" ? data.without_file : item.without_file

    if (typeof(data.with_file) != "undefined" || typeof(data.without_file) != "undefined") {
      data.requested_with_file = with_file ? (without_file ? "both" : "with_file") : "without_file"
      delete data.with_file
      delete data.without_file
    }

    return data
  }

  updateUrl(item) {
    const base = `/buildings/${this.props.building_id}/questionnaire/type`
    return (!item.id || item.is_org) ? base : `${base}/${item.id}`
  }

  saveUpdates(item) {
    if (!this.checkUpdates(item))
      return

    const data = this.prepareData(item)
    if (Object.values(data).length == 0)
      return

    if (item.is_org)
      data.org_questionnaire_price_id = item.id

    const shouldCreateItem = !item.id || item.is_org

    $.ajax({
      url: this.updateUrl(item),
      type: shouldCreateItem ? "post" : "put",
      data: addMeta({questionnaire_price: data}),
      success: res => this.successfulSave(res, item, shouldCreateItem)
    })
  }

  successfulSave(res, old_item, isNewItem) {
    const item = transformPrice(res)

    // only adds for new questionnaire type prices
    if (!old_item.org_questionnaire_price && isNewItem) {
      return this.setState({items: this.state.items.concat([item]), new_item: {}})
    }

    this.__updateItem(old_item, new_item => {
      Object.getOwnPropertyNames(new_item).forEach(prop => delete new_item[prop])
      Object.assign(new_item, item)
    })
  }

  restore(item) {
    const swalOptions = {
      type: "info",
      title: "Restore Type",
      text: "Restore this type for this building",
      showCancelButton: true
    }

    const ajaxOptions = {
      url: this.updateUrl(item) + "/restore",
      type: "put",
      data: addMeta({}),
      success: res => this.__updateItem(item, new_item => delete new_item.disabled)
    }

    swal(swalOptions, confirm => confirm ? $.ajax(ajaxOptions) : null)
  }

  remove(item) {
    const disabling = item.is_org || item.org_questionnaire_price
    const swalOptions = {
      type: "warning",
      title: disabling ? "Disable Type" : "Remove Type",
      text: `Please confirm you wish to remove the following type: ${item.name}`,
      showCancelButton: true
    }

    const data = {}
    if (item.is_org)
      data.org_questionnaire_price_id = item.id

    const ajaxOptions = {
      url: this.updateUrl(item),
      type: "delete",
      data: addMeta({questionnaire_price: data}),
      success: new_item => this.successfulDelete(item, new_item)
    }

    swal(swalOptions, confirm => confirm ? $.ajax(ajaxOptions) : null)
  }

  successfulDelete(item, new_item) {
    const disabling = item.is_org || item.org_questionnaire_price,
          items = this.state.items.concat([]),
          index = items.indexOf(item)

    if (new_item && new_item.id)
      items.splice(index, 1, transformPrice(new_item))
    else if (disabling)
      items.splice(index, 1, Object.assign({}, item, {disabled: true}))
    else
      items.splice(index, 1)

    this.setState({items: items})
  }

  renderListItem(item) {
    const buttons = [],
          classes = ["inactive"]
    if (item.disabled) {
      buttons.push(<i className="fa fa-undo" key="restore" onClick={() => this.restore(item)} />)
      classes.push("disabled")
    } else {
      buttons.push(<i className="fa fa-pencil" key="edit" onClick={() => this.openItem(item)} />)
      buttons.push(<i className="fa fa-trash" key="remove" onClick={() => this.remove(item)} />)
    }

    return <div key={"view-" + itemKey(item)} className={classes.join(" ")}>
      <div className="name">{item.name || org_attribute(item, name)}</div>
      <div className="price">{displayItemPrice(item)}</div>
      <div className="with-file">{displayItemType(item)}</div>
      <div className="buttons">{buttons}</div>
    </div>
  }

  renderActiveListItem(item) {
    const is_price_custom = isCustomValue(item)
    const custom_button_classes = ["custom-button"].concat(is_price_custom ? ["active"] : [])

    let price_entry
    if(is_price_custom)
      price_entry = <div className="input">Custom Amount</div>
    else
      price_entry =<input type="text" placeholder={pricePlaceholder(item)} defaultValue={priceValue(item)} onChange={e => this.updateItem(item, "price", e.target.value)} />

    return <div key={"edit-" + itemKey(item)} className="active-item" ref={itemKey(item)}>
      <div className="name">
        <input type="text" defaultValue={nameValue(item)} placeholder={namePlaceholder(item)} onChange={e => this.updateItem(item, "name", e.target.value)} />
      </div>
      <div className="price">
        <div>
          {price_entry}
          <button className={custom_button_classes.join(" ")} onClick={e => this.updateItem(item, "dynamic_price", !is_price_custom)}>
            <input type="checkbox" checked={is_price_custom} />
            <span>Is Custom</span>
          </button>
        </div>
      </div>

      <div>
        <div className="type">
          <label>
            <input type="checkbox" defaultChecked={withFileValue(item)} onChange={e => this.updateItem(item, "with_file", e.target.checked)} />
            <span>With File</span>
          </label>
          <label>
            <input type="checkbox" defaultChecked={withoutFileValue(item)} onChange={e => this.updateItem(item, "without_file", e.target.checked)} />
            <span>Without File</span>
          </label>
        </div>
        <div className="buttons">
          <button className="cancel" onClick={e => this.cancelItem(item)}>Cancel</button>
          <button className="update" onClick={e => this.saveUpdates(item)}>Update</button>
        </div>
      </div>
    </div>
  }

  renderList() {
    const list = []
    for (var item of this.state.items) {
      list.push(this.renderListItem(item))
      if (item.dirty)
        list.push(this.renderActiveListItem(item))
    }

    return <div className="list">{list}</div>
  }

  renderNewItem() {
    return <div className="new-item">
      {this.state.new_item.dirty ? this.renderActiveListItem(this.state.new_item) : <button className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={() => this.openItem(this.state.new_item)}>add questionnaire type</button>}
    </div>
  }

  render() {
    return <div className="building-questionnaire-prices">
      {this.renderList()}
      {this.renderNewItem()}
    </div>
  }
}

export default Pricing
