import React, { useState, useEffect, useCallback } from 'react'
import { compact } from 'lodash'
import BuildingSearch from 'components/utility/building_search'
import axios from "lib/axios";

function BuildingInput({ name = null, multiple = false, defaultValue = [], onSelect = null }) {
  const [buildings, setBuildings] = useState(defaultValue)

  const handleSelect = useCallback(building => {
    if (!multiple && buildings.length > 0)
      return

    if (buildings.some(b => b.id == building.id))
      return

    if (!building.id)
      return

    setBuildings(buildings.concat([building]))
  }, [buildings, setBuildings, multiple])

  const handleRemove = useCallback(id => {
    setBuildings(buildings.filter(building => building.id != id))
  }, [buildings, setBuildings])

  useEffect(() => {
    if (onSelect)
      onSelect(buildings)
  }, [buildings, onSelect])

  const container_classes = compact([
    "building-input-component",
    multiple ? "building-multiple-input-component" : null
  ]).join(" ")

  return <div className={container_classes}>
    {buildings.map(building => <BuildingLabel key={building.id} {...building} onRemove={handleRemove} />)}
    {multiple || buildings.length == 0 ? <BuildingSearch onSelect={handleSelect} /> : null}
    {name ? buildings.map(building => <input type="hidden" name={name} value={building.id} />) : null}
  </div>
}

function BuildingLabel({ id, title, onRemove }) {
  return <div className="super-build-search-label">
    <span>{title}</span>
    <i className="fas fa-times" onClick={() => onRemove(id)} />
  </div>
}


export default BuildingInput
