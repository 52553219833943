import React from 'react'
// ConciergeAd is present in all specified package types and excluded from ContractorReportAd package types.
const PACKAGE_TYPES_FOR_CONTRACTOR_REPORT_AD = [
  'decorative_alteration',
  'minor_alteration',
  'major_alteration',
  'alteration',
]

const AD_CONFIGS = {
  contractorReport: {
    className: 'contractor-report-ad',
    iconClass: 'fa-light fa-screwdriver-wrench',
    link: '/about/contractorreport',
    title: 'contractor report',
    text: 'Vet your contractor.',
  },
  concierge: {
    className: 'concierge-ad',
    iconClass: 'fa-light fa-hand-wave',
    link: '/concierge',
    title: 'concierge',
    text: 'White-glove service.',
  },
}

const AdComponent = ({ className, iconClass, link, title, text }) => {
  return (
    <div className={`${className} dome-d-flex dome-align-center dome-gap9 dome-light-shadow`}>
      <div className="ad-icon-wrapper display-only-desktop">
        <i className={`ad-icon ${iconClass} dome-color-green-gradient`}></i>
      </div>
      <div className="dome-d-flex dome-flex-column dome-align-start">
        <div className="dome-d-flex dome-justify-between">
          <a
            href={link}
            target="_blank"
            className="dome-text-w500 dome-link dome-link-aqua dome-link-chevron-right dome-color-green-gradient"
          >
            {title}
          </a>
        </div>
        <span className="dome-text-w300 dome-p3">{text}</span>
      </div>
    </div>
  )
}

export default function AdvertisementButton({ packageType }) {
  const isContractorReport = PACKAGE_TYPES_FOR_CONTRACTOR_REPORT_AD.includes(packageType)
  const adProps = isContractorReport ? AD_CONFIGS.contractorReport : AD_CONFIGS.concierge

  return (
    <div className="advertisement-button-wrapper dome-d-flex dome-align-center dome-flex-wrap dome-gap12">
      <AdComponent {...adProps} />
    </div>
  )
}
