import React, { Component } from "react"

const QUESTIONNAIRE_STATUS = {
  "intake": "Intake",
  "price_set": "Price Set",
  "price set": "Price Set",
  "recorrected_price_set": "Corrected Price Set",
  "recorrected price set": "Corrected Price Set",
  "paid": "Paid",
  "email sent": "Email Sent",
  "canceled": "Canceled",
  "archived": "Archived"
}

export default class DashboardRequestRow extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.questionnaire_request = props.questionnaire_request
  }

  renderDate(date_string){
    const dateAndTime = date_string.split("T"),
    date = dateAndTime[0].split("-"),
    time = dateAndTime[1].split(":")

    return <div>{this.formatTime(time)}, {this.formatDate(date)}</div>
  }

  formatDate(date){
    const year = date[0].slice(-2),
    month = date[1],
    day = date[2]

    return <span>{month}/{day}/{year}</span>
  }

  formatTime(time){
    const suffix = time[0] >= 12 ? "PM" : "AM",
    hour = time[0] % 12 == 0 ? 12 : time[0] % 12,
    minutes = time[1]

    return <span>{hour}:{minutes} {suffix}</span>
  }

  renderAttachmentColumn(){
    if (this.state.questionnaire_request.questionnaire_form.url){
      return <a href={this.state.questionnaire_request.questionnaire_form.url} target="_blank">
        <i className="questionnaires-file-icon fa-thin fa-folder-magnifying-glass"></i>
      </a>
    }

    return ""
  }

  getRequestDomain(request){
    if (this.props.org_domain_hash[request.hashed_id])
      return this.props.org_domain_hash[request.hashed_id]

    return "www"
  }

  renderStatus(){
    switch (this.state.questionnaire_request.status){
      case "intake":
        return "intake"
      case "price set":
        return "price Set"
      case "price_set":
      case "price set":
        return "price set"
      case "recorrected price set":
        return "recorrected price set"
      case "recorrected_price_set":
        return "recorrected price set"
      case "paid":
        return "paid"
      case "email sent":
        return "email sent"
      case "canceled":
        return "canceled"
      case "archived":
        return "archived"
    }
  }

  renderSetPriceButton(){
    const org_domain = this.getRequestDomain(this.state.questionnaire_request)
    const setPriceUrl = "https://"+org_domain+".boardpackager.com/questionnaire_requests/"+this.state.questionnaire_request.hashed_id+"/responded_to_questionnaire/"+this.state.questionnaire_request.set_price_token
    const classes = ["dome-link", "dome-is-button"]
    const disabled = this.state.questionnaire_request.is_cancelled || this.state.questionnaire_request.is_archived
    if (disabled)
      classes.push("disabled")
    if (this.state.questionnaire_request.price) {
      classes.push('dome-link-green-stroke dome-link-chevron-right')
    } else {
      classes.push('dome-link-dark-green')
    }

    return <a href={setPriceUrl} className={classes.join(" ")} disabled={disabled} target="_blank">
      {this.renderButtonText()}
    </a>
  }

  renderButtonText(){
    if (this.state.questionnaire_request.price){
      return "open"
    }
    return "start"
  }


  render() {
    const disabled = this.state.questionnaire_request.is_cancelled || this.state.questionnaire_request.is_archived
    if (disabled)
      return null

    const columns = []
    columns.push(<td className="buildings" data-title='property'> {this.state.questionnaire_request.property_address} </td>)
    columns.push(<td data-title='name'>{this.state.questionnaire_request.buyer_name}</td>)

    if (this.state.questionnaire_request.company_name && this.state.questionnaire_request.company_name != "undefined")
      columns.push(<td data-title='requesting party'>{this.state.questionnaire_request.company_name}</td>)
    else
      columns.push(<td></td>)

    columns.push(<td data-title='created'>{this.renderDate(this.state.questionnaire_request.created_at)}</td>)
    columns.push(<td data-title='status'>{this.renderStatus()}</td>)
    columns.push(<td data-title='attachment'>{this.renderAttachmentColumn()}</td>)
    columns.push(<td className="td-button-column">{this.renderSetPriceButton()}</td>)

    return <tr key={this.state.questionnaire_request.id} className="data-row">{columns}</tr>
  }
};
