import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import NewRequirementField from './NewRequirementField'
import RequirementFields from './RequirementFields'

export default function Requirements({ recognition_agreement_enabled, onRemoveRequirementRemotely, onRemoveDocument }) {
  const { getValues, setValue, control } = useFormContext()
  const {
    fields: requirement_fields,
    append: append_requirement,
    remove: remove_requirement,
    move: move_requirement,
  } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'package_template.package_requirements',
  })

  const onAddRequirement = (title) => {
    append_requirement({ title: title })
  }

  const onRemoveRequirement = async (index) => {
    const confirmed = confirm('Are you sure you want to remove this requirement?')

    if (confirmed) {
      const requirement = requirement_fields[index]
      const requirementUnSaved = !!!requirement.id

      if (requirementUnSaved) {
        remove_requirement(index)
      } else {
        try {
          await onRemoveRequirementRemotely(requirement.id)
          remove_requirement(index)
        } catch (e) {}
      }
    }
  }

  const onDragEnd = ({ source, destination, type }) => {
    if (!destination) return

    if (type === 'requirements') {
      move_requirement(source.index, destination.index)
    } else {
      // MARK: We are assuming that a package template document cannot be dragged into a different requirement!
      const requirement_index = type.replace('requirement_documents_', '')
      const key = `package_template.package_requirements.${requirement_index}.package_template_documents`

      const packageTemplateDocuments = getValues(key)
      const [removed] = packageTemplateDocuments.splice(source.index, 1)
      packageTemplateDocuments.splice(destination.index, 0, removed)

      setValue(key, packageTemplateDocuments, { shouldDirty: true })
    }
  }

  return (
    <>
      <NewRequirementField onAddRequirement={onAddRequirement} />
      <RequirementFields
        fields={requirement_fields}
        remove={onRemoveRequirement}
        recognition_agreement_enabled={recognition_agreement_enabled}
        onDragEnd={onDragEnd}
        onRemoveDocument={onRemoveDocument}
      />
    </>
  )
}
