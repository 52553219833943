import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { compact } from 'lodash'
import { compactObject } from 'lib/utilities'
import { useFindFontSize } from 'components/signatures/utilities'
import { useFindFontSizeProps } from '../utilities/find_font_size'

import { calculateChecksum } from '../../signatures/utilities'

export default function({id, thumbnail, frozen}) {
  const container = useRef()
  const signature = useSelector(state => state.form_signatures.signatures[id])
  const signed = useSelector(state => state.form_signatures.signed_signatures[id])
  
  const fontSizeProps = useFindFontSizeProps({ 
    key: `signature|${id}|name`,
    ratioType: thumbnail ? "thumbnailRatio" : null, 
    frozen: frozen || thumbnail}) 

  const { originalSize, fontSize, isWorking } = useFindFontSize(
    container, 
    { 
      defaultSize: signed?.nameFontSize || 100, 
      defaultChecksum: calculateChecksum([signed?.user_name]),
      ...fontSizeProps 
    }, 
    [ signed?.user_name ]
  )

  const classes = compact([
    "signature-name"
  ]).join(" ")

  const style = compactObject({
    fontSize
  })

  if (isWorking) {
    style.opacity = 0
    style.transition = "opacity 0s"
  }

  return <div className={classes} style={style} ref={container}>
    { signed?.user_name }
  </div>
}