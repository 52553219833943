import React, { useEffect, useState } from 'react'
import { compact } from 'lodash-es'

const DomeSliderToggle = (props) => {
  const { offText, onText, inputName, miniSwitch, onChange, containerClass, initialIsToggleOn } = props
  const [isToggleOn, setIsToggleOn] = useState(initialIsToggleOn)

  useEffect(() => {
    setIsToggleOn(initialIsToggleOn)
  }, [initialIsToggleOn])

  const toggle = () => {
    const toggleTo = !isToggleOn
    setIsToggleOn(!isToggleOn)
    const callback = onChange
    const directCallback = props[toggleTo ? 'onToggleOn' : 'onToggleOff']

    callback ? callback(toggleTo) : null
    directCallback ? directCallback() : null
  }

  const container = compact(['dome-slider-toggle', containerClass]).join(' ')
  const toggleSwitch = compact(['dome-toggle-switch', miniSwitch ? 'small-switch' : null]).join(' ')

  return (
    <div className={container}>
      <input
        type="checkbox"
        name={inputName}
        className="slider-toggle-checkbox"
        checked={isToggleOn}
        onChange={() => {}}
      />
      <div className={toggleSwitch} onClick={toggle}>
        <div className="slider" />
        <div className="switch-off-label">{offText}</div>
        <div className="switch-on-label">{onText}</div>
      </div>
    </div>
  )
}

DomeSliderToggle.defaultProps = {
  initialIsToggleOn: false
}

export default DomeSliderToggle
