import React, { createContext, useMemo, useReducer, useEffect } from 'react'
import { useState } from 'react'
import { feeReducer } from '../reducers/feeReducer'
import { sortFees } from '../reducers/feeReducer'
import { v4 as uuidv4 } from "uuid"
import { dispatchEvent } from '../../../../../javascript/lib/utilities'

export const FeeContext = createContext()

const calculateSubtotal = (fees) => {
  const initialValue = 0

  return parseFloat(
    fees
      .filter(fee => fee.type === "fee" && !fee.waived)
      .reduce((acc, current) => acc + current.amount, initialValue)
  )
}

export const init = initialFeeData => initialFeeData

const FeeContextProvider = (props) => {
  const initialFeeData = sortFees(props.fees).map((fee, idx) => ({...fee, order: idx, uuid: uuidv4()}))
  const subMerchants = props.sub_merchants
  const [readyForCollection, setReadyForCollection] = useState(props.fees_ready_for_collection)
  const [fees, dispatch] = useReducer(feeReducer, initialFeeData)
  const [currentView, setCurrentView] = useState(null)

  const [feesSet, setFeesSet] = useState(props.fees_set)

  const subTotal = useMemo(() => calculateSubtotal(fees), [fees])

  useEffect(() => {
    dispatchEvent('lease-subtotal-changed', { subTotal: subTotal })
  }, [subTotal])

  return (
    <FeeContext.Provider
      value={{
        fees,
        subMerchants,
        currentView,
        setCurrentView,
        dispatch,
        subTotal,
        initialFeeData,
        feesSet,
        setFeesSet,
        packageId: props.package_id,
        remainingBalance: props.remainingBalance,
        readyForCollection
      }}
    >
      {props.children}
    </FeeContext.Provider>
  )
}

export default FeeContextProvider
