import React, { useState } from 'react'
import Nav from './Nav'
import ContentRenderer from '../SidebarContentRenderer'

const Sidebar = ({ navLabels, sidebarClasses, navClasses }) => {
  const [componentToRender, setComponentToRender] = useState(navLabels[0])

  const handleNavLabelClick = (item) => {
    setComponentToRender(item)
  }

  return (
    <div className={`sidebar ${sidebarClasses}`}>
      <Nav navLabels={navLabels} onLabelClick={handleNavLabelClick} classes={navClasses} />
      <ContentRenderer componentName={componentToRender} />
    </div>
  )
}

export default Sidebar
