export const BIG_NUMBER = 999999999999

export const formattedMoney = (value, opts = { stripZeros: true }) => {
  if (!value || value === 0) return '-'

  let options = { style: 'currency', currency: 'USD' }
  if (opts.stripZeros === true) {
    options = { ...options, trailingZeroDisplay: 'stripIfInteger' }
  }
  const numberFormat = Intl.NumberFormat('en-US', options)

  return numberFormat.format(value)
}

export const formattedNumber = (value) => {
  return !value ? '0' : Intl.NumberFormat('en-US').format(value)
}
