import React from 'react'
import CollaboratorKey from './key'

// will end up being unattached collaborator
function Row(props) {
  return (
    <div className='row' style={{paddingTop: '10px'}}>
      <div className='col-xs-1' style={{padding: '0px', marginLeft: '0px !important'}}>
        <CollaboratorKey {...props} />
      </div>
      <div className='col-xs-11' style={{paddingLeft: '10px', margin: '0px'}}>
        {props.collaborator.userMetadata.section}: {props.collaborator.userMetadata.full_name}
      </div>
    </div>
  )
}

export default Row
