import React from 'react'
import { uniqBy } from 'lodash'

import CommentThread from './thread'

const is_empty_list = list => !list || list.length == 0

class CommentBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.filter = this.props.filter.length > 2 ? "all" : this.props.filter[0]
    this.state.select_user = false
    this.state.selected_user_id = 0
    this.state.selected_user_thumbnail = this.state.selected_user_role_label = this.state.selected_user_name = ''
    this.state.selected_users = this.props.automaticallyShareAllCommentsWith || []
  }

  postComment() {
    var text_input = this.refs.commentText;
    var post_text = text_input.value;
    var requirement = this.props.reqId;
    var package_id = this.props.packageId;

    if (post_text.trim() == '')
      return

    const data = {
      package_id,
      requirement,
      body: post_text,
      sent_to: this.state.selected_users
    }

    const success = ({comments}) => {
      for (let comment of comments)
        this.props.onNewComment(comment)
      this.refs.commentText.value = ''
    }

    $.ajax({
      url: '/comments',
      dataType: 'json',
      type: 'POST',
      data: { comment: data },
      success
    })
  }

  setFilter() {
    this.setState({filter: this.refs.roleFilter.value})
  }

  resetName(user_id) {
    const users = this.state.selected_users
    const selected_users = users.filter(el => el.id !== user_id)
    this.setState({selected_users})
  }

  selectAll() {
    this.setState({ selected_users: this.props.share_users })
  }

  selectCurrentUser(user) {
    const selected_users = this.updateSelectedUsers(user)
    this.setState({
      selected_user_name: user['name'],
      selected_user_id: user['id'],
      selected_user_thumbnail: user['thumbnail'],
      selected_user_role_label: user['role_label'],
      select_user: false,
      selected_users
    })
  }

  updateSelectedUsers(user){
    var users = this.state.selected_users.concat([user])
    return uniqBy(users, 'id')
  }

  renderUser(user) {
    const img_src = is_empty_list(user['thumbnail']) ? null : <img src={user['thumbnail']} alt=""/>
    const onClick = () => this.selectCurrentUser(user)

    return <li className="selectable-user text-left" onClick={onClick} key={user['id']}>
      {user['role_label']}{img_src}{user['name']}
    </li>

  }

  renderSelectedUser(user, i) {
    const img_src = is_empty_list(user['thumbnail']) ? null : <img src={user['thumbnail']} alt=""/>
    const onClick = () => this.resetName(user['id'])

    return <span key={i} className='dm-title btn btn-warning'>
      {img_src}{user['name']}
      <i onClick={ onClick } className='fa fa-times-circle-o' />
    </span>
  }

  selectUserList() {
    if (this.props.automaticallyShareAllCommentsWith)
      return null

    if (is_empty_list(this.props.share_users))
      return null

    const img_src = is_empty_list(this.state.selected_user_thumbnail) ? null : <img src={this.state.selected_user_thumbnail} alt=""/>

    if (this.state.select_user) {
      const user_list = this.props.share_users.map(user => this.renderUser(user))

      if (this.state.selected_users && this.state.selected_users.length > 0) {
        return (
          <div>
            <div className="direct-message-box text-right">
              <span className="pull-left" style={{marginLeft: 10+'px'}}>Direct Message:
                {this.state.selected_users.map((user, i) => this.renderSelectedUser(user, i))}
              </span>
              <a onClick={e => this.toggleSelectUser(e)} className="btn">
                <i className="fa fa-plus" />
                <i className="fa fa-user" />
              </a>
            </div>
            <div className="direct-message-box text-right">
              <span className="dm-title pull-left">Select User:</span>
              <a onClick={e => this.toggleSelectUser(e)} className="btn">
                <i className="fa fa-times"></i>
              </a>
            </div>
            <ul className="user-list-box">{user_list}</ul>
          </div>
        )
      } else {
        return (
          <div>
            <div className="direct-message-box text-right">
              <span className="dm-title pull-left">Select User:</span>
              <a  onClick={e => this.toggleSelectUser(e)} className="btn">
                <i className="fa fa-times"></i>
              </a>
            </div>
            <ul className="user-list-box text-left">
              <li id="select-all-btn" onClick={() => this.selectAll()}>Select all</li>
            </ul>
            <ul className="user-list-box">{user_list}</ul>
          </div>
        )
      }
    } else if (this.state.selected_users && this.state.selected_users.length > 0) {
      return (
        <div className="direct-message-box text-right">
          <span className="pull-left" style={{marginLeft: 10+'px'}}>Direct Message:
            {this.state.selected_users.map((user, i) => this.renderSelectedUser(user, i)) }
          </span>
          <a onClick={() => this.toggleSelectUser()} className="btn">
            <i className="fa fa-plus" />
            <i className="fa fa-user"/>
          </a>
        </div>
      );
    } else {
      return (
        <div className="direct-message-box text-right">
          <a onClick={e => this.toggleSelectUser(e)} className="btn">
            <i className="fa fa-plus" />
            <i className="fa fa-user" />
          </a>
        </div>
      );
    }
  }

  toggleSelectUser() {
    this.setState({select_user: !this.state.select_user})
  }

  renderTextAreaClass() {
    if (this.state.selected_users.length === 0) {
      return('disabled');
    } else {
      return('');
    }
  }

  renderPlaceholderText(){
    if (this.props.share_users && this.props.share_users.length === 0 && this.props.packageUser.role == "broker" )
      return('Send comments to other Deal Parties once you’ve shared the package.')
    else if (this.props.share_users.length > 0 && this.state.selected_users.length === 0) {
      return('');
    } else {
      return('Type your message here...');
    }
  }

  renderCommentThread() {
    return <CommentThread
      selectCurrentUser={this.props.automaticallyShareAllCommentsWith ? () => {} : user => this.selectCurrentUser(user)}
      filter={this.state.filter}
      packageUser={this.props.packageUser}
      comments={this.props.comments}
      commenterId={this.props.commenterId}
      noDirectedMessages={this.props.automaticallyShareAllCommentsWith ? true : false}
      inModal={this.props.inModal}>
    </CommentThread>
  }

  renderPostCommentArea() {
    return <div>
      <div className="col-xs-12 no-padding">
        {this.selectUserList()}
        <textarea onChange={this.checkName} ref="commentText" rows="3" name="body" disabled={this.renderTextAreaClass()} placeholder={this.renderPlaceholderText()} className="form-control comment-box-skip"></textarea>
      </div>
      <div className="col-xs-4 col-sm-3 pull-right no-padding">
        <button onClick={() => this.postComment()} disabled={this.renderTextAreaClass()} className="btn btn-block btn-warning">Post</button>
      </div>
    </div>
  }

  renderModalWrap(modal_body, modal_footer) {
    return <div className="modal fade" id="comment-modal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            {modal_body}
          </div>
          <div className="modal-footer">
            {modal_footer}
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    if (!this.props.showThread)
      return <div />

    if (this.props.inModal)
      return this.renderModalWrap(this.renderCommentThread(), this.renderPostCommentArea())

    return <div>
      {this.renderCommentThread()}
      {this.renderPostCommentArea()}
    </div>
  }
}

export default CommentBox