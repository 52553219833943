import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBuildingEditBuildingStructure, setInitialState } from './store/slice'
import { setInitialState as setInitialImportModalState } from './components/ImportModal/store/slice'
import BuildingUnits from './components/BuildingUnits'
import ManageAddress from './components/ManageAddress'

export function BuildingEditBuildingStructure(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setInitialState(props))
    dispatch(setInitialImportModalState(props))
  }, [dispatch])

  const { building, building_structure } = useSelector(selectBuildingEditBuildingStructure)

  const onArchived = useCallback(() => {
    window.location.href = `/buildings/${building?.id}/building_structures`
  }, [building?.id])

  if (!building) {
    return null
  }

  return (
    <div className="container building-edit-building-structure-container">
      <div className="building-info__header">
        <h2 className="dome-h2" style={{ display: 'inline' }}>
          {building.title || building.address}
        </h2>
        <span className="pull-right">
          <a
            className="dome-link dome-link-grey dome-link-chevron-left"
            href={`/buildings/${building.id}/building_structures`}
          >
            back to addresses
          </a>
        </span>
      </div>
      <div className="building-structure__info">
        <div>
          <div className="dome-h3">{building_structure.default_additional_address.address}</div>
          <div className="dome-p2-lt">{building_structure.name || null}</div>
        </div>
        <div>
          <ManageAddress building={building} buildingStructure={building_structure} onArchived={onArchived} archiveVisible={props.archive_visible} />
        </div>
      </div>
      <div className="building-units__info dome-full-width">
        <div className="info-block">
          <div className="dome-color-dark-green dome-text-w500">units</div>
          <div className="info-row">
            <div className="detail-block">
              <div className="dome-p2-lt">building</div>
              <div className="dome-h1">{building_structure.number_of_units}</div>
            </div>
            <div className="splitter"></div>
            <div className="detail-block">
              <div className="dome-p2-lt">system</div>
              <div className="dome-h1">{building_structure.active_unit_count}</div>
            </div>
          </div>
        </div>
        <div className="info-block">
          <div className="dome-color-dark-green dome-text-w500">owners</div>
          <div className="detail-block">
            <div className="dome-p2-lt">total</div>
            <div className="dome-h1">{building_structure.owner_count}</div>
          </div>
        </div>
        <div className="info-block">
          <div className="dome-color-dark-green dome-text-w500">renters</div>
          <div className="detail-block">
            <div>total</div>
            <div className="dome-h1">{building_structure.renter_count}</div>
          </div>
        </div>
      </div>
      <BuildingUnits
        initialData={props.building_units.data}
        initialPaginationData={props.building_units.pagination_data}
        initialUrlParams={props.building_units.url_params}
      />
    </div>
  )
}
