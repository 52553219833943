import React from 'react'
import PropTypes from 'prop-types'

export default function TableHead({ columns, handleItemsSorting, sortField, sortOrder, actionsShown = true }) {
  const onSorting = (accessor) => {
    let newSortOrder

    if (accessor === sortField) {
      newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    } else {
      newSortOrder = 'asc'
    }

    handleItemsSorting(accessor, newSortOrder)
  }

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          const thClasses = []
          if (Array.isArray(column?.classes?.th)) {
            thClasses.push(...column.classes.th)
          }
          const thClassName = thClasses.length > 0 ? thClasses.join(' ') : undefined

          const linkClasses = ['dome-user-select-none']
          if (Array.isArray(column?.classes?.a)) {
            thClasses.push(...column.classes.a)
          }
          let sortIcon = null

          if (sortField === column.accessor) {
            linkClasses.push('active')

            if (sortOrder === 'asc') {
              sortIcon = <i className="fa-solid fa-caret-up"></i>
            } else {
              sortIcon = <i className="fa-solid fa-caret-down"></i>
            }
          }

          const isSortable = column?.sortable !== false
          if (isSortable) {
            linkClasses.push('dome-cursor-pointer')
          }

          return (
            <th className={thClassName} key={column.accessor}>
              <a
                className={linkClasses.join(' ')}
                onClick={() => (isSortable ? onSorting(column.accessor) : undefined)}
              >
                {column.label}
                {sortIcon}
              </a>
            </th>
          )
        })}
        {actionsShown && <th className="col-xs-2"></th>}
      </tr>
    </thead>
  )
}

TableHead.propTypes = {
  columns: PropTypes.array.isRequired,
}
