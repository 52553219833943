import React from 'react'

export default (props) => {
  const style = {color: 'red', fontWeight: 'bold'}
  return (
    <div>
      <hr style={props.hrStyle} />
      <span className='collaborator-hover' style={style} onClick={props.handleRemoveClick}
        >Remove Share
      </span>
      <hr style={props.hrStyle}></hr>
    </div>
  )
}
