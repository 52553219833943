import React, { useState } from 'react'
import clsx from 'clsx'
import InstantQuoteLookupOverlay from './overlay'

export default function InstantQuoteLookupOverlayButton(props) {
  const { buttonText, buttonClasses, ...modalProps } = props

  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <button className={clsx(buttonClasses)} onClick={handleOpenModal}>
        {buttonText}
      </button>
      <InstantQuoteLookupOverlay {...modalProps} open={openModal} onClose={handleCloseModal} />
    </>
  )
}
