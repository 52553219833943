import React, { useCallback, useEffect, useState } from 'react'
import { useWatchableRef } from 'lib/hooks'
import axios from 'lib/axios'

const getAuthenticityParam = () => {
  const param_name = document.head.querySelector('meta[name="csrf-param"]')?.content
  const param_value = document.head.querySelector('meta[name="csrf-token"]')?.content
  return { param_name, param_value }
}

const PackageRequirement = ({ requirement, isLast, package_hashed_id }) => {
  const { param_name, param_value } = getAuthenticityParam()

  return (
    <>
      <h4>{requirement.title}</h4>
      {requirement.description?.length > 0 && <small>{requirement.description}</small>}
      <ul>
        {requirement.package_template_documents.map((doc) => {
          return doc.resource_type !== 'Form' ? (
            <li key={doc.id}>
              <form
                action={`/download/${doc.resource_id}/free?package_id=${package_hashed_id}&req=${requirement.id}`}
                acceptCharset="UTF-8"
                method="post"
              >
                <input type="hidden" name={param_name} value={param_value} autoComplete="off" />
                <button type="submit" className="anchor-styled-download-button">
                  {doc.resource_name || ''}
                </button>
              </form>
            </li>
          ) : (
            <li key={doc.id}>
              <a
                onClick={() =>
                  window.open(
                    `/forms/${doc.resource_id}/public`,
                    'winname',
                    'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=800'
                  )
                }
                href="#"
              >
                {doc.resource_name} (Form)
              </a>
            </li>
          )
        })}
      </ul>
      {!isLast && <hr />}
    </>
  )
}

export default function PackagePreviewModal({ open, onClose, package_hashed_id, onAddToLibrary }) {
  const modalRef = useWatchableRef(null)

  const [state, setState] = useState({})
  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(`/package_templates/${package_hashed_id}/preview_data`)
      setState((state) => ({ ...state, package_template: data?.package_template }))
    } catch (e) {
      console.log(e)
    }
  }, [package_hashed_id])
  const { package_template } = state

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const handleClose = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', handleClose)
    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
      fetchData()
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  const renderPackageRequirements = () => {
    if (!package_template) return null

    return package_template.package_requirements.map((requirement, index) => (
      <PackageRequirement
        key={requirement.id}
        requirement={requirement}
        isLast={index === package_template.package_requirements.length - 1}
        package_hashed_id={package_hashed_id}
      />
    ))
  }

  const handleAddToLibrary = () => {
    onAddToLibrary(package_hashed_id)
  }

  return (
    <div
      ref={modalRef.ref}
      id="package-preview-container"
      className="modal package-templates-modal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={onClose}>
                ×
              </button>
              <h3 className="modal-title">{package_template?.label}</h3>
            </div>
            <div className="modal-body">
              <div className="col-xs-12">{renderPackageRequirements()}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success download-preview-btn" onClick={handleAddToLibrary}>
                <i
                  className="fa fa-cloud-download"
                  style={{ fontSize: '16px', paddingTop: '5px' }}
                  aria-hidden="true"
                ></i>
                Add to Library
              </button>
              <button type="button" className="btn btn-danger" onClick={onClose} style={{ float: 'left' }}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
