import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const tabs = {
  property: 'property',
  policy: 'policy',
  'about-you': 'about you',
}

const tabKeys = Object.keys(tabs)

const QuoteTabItem = ({ tab, status }) => {
  const tabLabel = tabs[tab]

  return (
    <div className="quote-tab-item">
      <div className="dome-p3">{tabLabel}</div>
      <div className={clsx('quote-tab-item-bar', status)}></div>
    </div>
  )
}

export default function QuoteTabsProgress({ activeTab }) {
  const activeIndex = tabKeys.findIndex((tab) => tab === activeTab)

  const getStatus = (index) => {
    if (activeIndex === index) return 'active'
    return activeIndex > index ? 'previously-active' : 'inactive'
  }

  return (
    <div className="quote-tabs-progress">
      {tabKeys.map((tab, index) => (
        <QuoteTabItem key={tab} tab={tab} status={getStatus(index)} />
      ))}
    </div>
  )
}

QuoteTabsProgress.propTypes = {
  activeTab: PropTypes.string.isRequired,
}
