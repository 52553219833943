import React from 'react'
import PropTypes from 'prop-types'

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rotating: false,
      visibility: 'hidden',
      previousBuilding: null
    }
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleClickApplyOnline = this.handleClickApplyOnline.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props

    if (type == "featured") {
      if (!this.state.previousBuilding && this.building()) {
        var containerSelector = "#" + this.containerId(),
            $current = $(containerSelector + " .current"),
            $incoming = $(containerSelector + " .incoming")

        this.addBuildingDataToCard($current, this.props.buildingCardStatus.current)
        this.addBuildingDataToCard($incoming, this.props.buildingCardStatus.incoming)
      }

      if (this.state.previousBuilding != this.building()) this.setState({ previousBuilding: this.building() })

      if (this.building(prevProps) && this.building() != this.building(prevProps))
        this.rotateCards()
    }

    if (type == "selectedBuilding") {
      if (this.building() != this.state.previousBuilding) {
        this.setState({ previousBuilding: this.building() })
        this.swapSelectedBuildingCards()
      }
    }
  }

  building(props=this.props) {
    return props.buildingCardStatus.current
  }

  containerId() {
    return "card-" + this.props.type
  }

  handleMouseEnter() {
    this.props.stopBuildingRotationInterval(this.props.type)
  }

  handleMouseLeave() {
    this.props.setBuildingRotationInterval(this.props.type)
  }

  handleClickApplyOnline() {
    window.location.href = `${this.props.buildingsPath}/${this.building().text_url}`
  }

  swapSelectedBuildingCards() {
    const containerSelector = "#" + this.containerId()
    let $hiddenCard = $(containerSelector + " .card:not(.slide-left):first"),
        $visibleCard = $(containerSelector + " .card.slide-left")

    if (this.building() && this.building() != this.state.previousBuilding) {
      this.addBuildingDataToCard($hiddenCard, this.building())
      $hiddenCard.addClass("slide-left")
      $visibleCard.removeClass("slide-left")
    } else {
      $visibleCard.removeClass("slide-left")
    }

    $visibleCard.removeClass("slide-left")
  }

  buildingSelectCardStatus() {
    let containerSelector = "#" + this.containerId()

    if ($(containerSelector + " .incoming").length > 0) return "incoming"

    if ($(containerSelector + ".current").length > 0) return "current"
  }

  rotateCards() {
    let containerSelector = "#" + this.containerId(),
        $incoming = $(containerSelector + " .incoming"),
        $current = $(containerSelector + " .current"),
        $outgoing = $(containerSelector + " .outgoing")

    $incoming.removeClass("incoming").addClass("current")
    $current.removeClass("current").addClass("outgoing")
    $outgoing.removeClass("outgoing").addClass("incoming")

    this.addBuildingDataToCard($outgoing, this.props.buildingCardStatus.incoming)
  }

  emptyCardContent($card) {
    $card.find(".card-header-address").empty()
    $card.find(".card-city-state").empty()
    $card.find(".card-thumbnail").css({ "background-image": "" })
  }

  addBuildingDataToCard($card, building) {
    $card.find(".card-header-address").text(building.address)
    $card.find(".card-city-state").text(`${building.city}, ${building.state}`)
    $card.find(".card-thumbnail").css({ "background-image": `url(${building.thumbnail_url})` })
  }

  renderSelectedBuildingContent() {
    return (
      <div>
        <div className={`card incoming`} key={this.props.type+"1"}>
          <div className="card-content">
            <div className="card-header-address"></div>
            <div className="card-city-state text-small"></div>
            <div className="card-thumbnail"></div>
            <div className="card-apply"><button className="btn card-apply-btn" onClick={this.handleClickApplyOnline}>APPLY ONLINE</button></div>
          </div>
        </div>
        <div className={`card incoming`} key={this.props.type+"2"}>
          <div className="card-content">
            <div className="card-header-address"></div>
            <div className="card-city-state text-small"></div>
            <div className="card-thumbnail"></div>
            <div className="card-apply"><button className="btn card-apply-btn" onClick={this.handleClickApplyOnline}>APPLY ONLINE</button></div>
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    let cardsList = []

    for (const key in this.props.buildingCardStatus) {
      if (key == "buildingRotationIntervalIds") continue

      cardsList.push(
        <div className={`card ${key}`} key={`${this.props.type}-${key}`}>
          <div className={`card-content`}>
            <div className="card-header-address"></div>
            <div className="card-city-state text-small"></div>
            <div className="card-thumbnail"></div>
            <div className="card-apply"><button className="btn card-apply-btn" onClick={this.handleClickApplyOnline}>APPLY ONLINE</button></div>
          </div>
        </div>
      )
    }

    return cardsList
  }

  render() {
    return (
      <div id={this.containerId()}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave} >
        {this.props.type == "featured" ? this.renderContent() : this.renderSelectedBuildingContent() }
      </div>
    )
  }
}

Card.propTypes = {
  buildingCardStatus:           PropTypes.object.isRequired,
  stopBuildingRotationInterval: PropTypes.func.isRequired,
  setBuildingRotationInterval:  PropTypes.func.isRequired,
  setAppState:                  PropTypes.func.isRequired,
  buildingsPath:                PropTypes.string.isRequired,
  isMobile:                     PropTypes.bool.isRequired,
  type:                         PropTypes.string.isRequired
}

export default Card
