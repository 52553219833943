import React from 'react'
import { pick } from 'lodash'

import OptionBox from './option_box'

const CREATOR_TITLE = "Package Creator visibility permissions cannot be updated."
const INCLUDED_PROPS = ['updateMessage', 'collaborator',
      'initialPolicy', 'updatedPolicy',
      'toggleReadOnly', 'toggleAttachAllRequirementPolicies', 'toggleSelectedRequirement',
      'packageRequirements', 'selectAllRequirements', 'deSelectAllRequirements',
      'resetForManualSelectClick', 'noRequirements', 'tag',
      'createPackageUser', 'destroyPackageUser', 'updatePackageUser', 'isAlteration'
    ]


function Key(props) {
  const {attached, isPackageCreator, idx: collab_index } = props.collaborator
  const isVisiblePopover = props.visiblePopoverIdx === collab_index
  const classes = ['key-popup fa fa-user-plus']
  const togglePopover = () => isPackageCreator ? null : props.updateVisiblePopover(collab_index)

  if(attached)
    classes.push('bopa-orange')

  return <div>
    <div style={{textAlign: 'center'}}>
      <i onClick={ togglePopover } className={classes.join(" ")} data-toggle="tooltip" data-placement="left" data-original-title={isPackageCreator ? CREATOR_TITLE : null} />
    </div>
    { isVisiblePopover ? <OptionBox { ...pick(props, INCLUDED_PROPS)} togglePopover={togglePopover} /> : null }
  </div>
}

export default Key
