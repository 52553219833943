import React from 'react'
import Collapsible from 'react-collapsible'
import Field from 'components/utility/field'
import { isPlainObject } from 'lodash'

const isFilled = val => val && !String(val).match(/^\s*$/)

function anyFieldsFilled(data) {
  const values = Object.values(data)
  const isData = val => !isPlainObject(val) && isFilled(val)

  if (values.some(isData))
    return true

  return values.filter(isPlainObject).some(anyFieldsFilled)
}

function isReadyToSubmit({fields, data, data_key}) {
  if (!anyFieldsFilled(data))
    return true

  // Submit if all required fields are filled
  return !fields.some(field => field.required && !isFilled(data[`${data_key}.${field.id}`]))
}

function collapsableTitle({data, data_key}) {
  const first = data[`${data_key}.name.first`]
  const last = data[`${data_key}.name.last`]
  const firm = data[`${data_key}.firm`]

  if (isFilled(first))
    return `${first} ${last}`
  else if (isFilled(firm))
    return firm
  
  return "Name"
}

function CollapsableFieldsArea({data, data_key, disabled, children}) {
  const styling = { 
    borderBottom: '1px solid rgb(221, 221, 221)', 
    padding: '10px 15px' 
  }
  const trigger = collapsableTitle({ data, data_key })

  return <Collapsible trigger={trigger}
                      triggerTagName='div'
                      triggerDisabled={disabled}
                      triggerStyle={styling}
                      contentOuterClassName='uncollapsed-fields-container'>
      {children}
  </Collapsible>
}

export default function({data, role, role_index, fields}) {
  const data_key = `${role}[${role_index}]`
  const sortedFields = fields.sort((d1, d2) => d1.order < d2.order ? -1 : 1)

  const renderFields = (fields) => {
    return fields.map(field => {
      const name = `${data_key}.${field.id}`
      const required = field.required && anyFieldsFilled(data)
      // const requiredStyling = { border: '1px solid red' }

      const props = {
        key: field.id,
        ... field.form_field,
        name,
        required,
        value: props.values[name],
        handleUpdate: value => props.onUpdate({path: name, value})
      }

      return <Field { ...props } />
    })
  }

  const ready = isReadyToSubmit({fields, data, data_key})
  return <CollapsableFieldsArea data={props.data} data_key={data_key} disabled={!ready}>
    {renderFields(sortedFields)}
  </CollapsableFieldsArea>
}
