import React from 'react'
import PropTypes from 'prop-types'

export default class VotesCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.vote_date) return;
    let newOption = this.props.checked ? "" : this.props.option;
    this.props.handleUpdateSelectedOption(newOption);
  }

  render() {
    const { option, checked, vote_date } = this.props;
    return (
      <div className="vote-checkbox-container">
        <input id={"option-" + option} type="checkbox" name={option} value="true" checked={checked} onChange={(e) => this.handleClick()} />
        <label htmlFor={"option-"+option}>{option}</label>
        <span style={{paddingLeft: '15px'}}>{checked ? vote_date : ''}</span>
      </div>
    )
  }
}

VotesCheckbox.propTypes = {
  option: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleUpdateSelectedOption: PropTypes.func,
  vote_date: PropTypes.string
}
