import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { createSlice, configureStore } from '@reduxjs/toolkit'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { mapValues, compact, uniq, isFinite, isEmpty, omitBy } from 'lodash'
import { ajax } from 'jquery'

import { addCSRF, sortObjectKeys } from 'lib/utilities'
import { splitPathSegment,
         firstPathSegment,
         pathMapToHash,
         generateRoute } from 'lib/utilities/form'

import { findErrors } from '../../form_field/validations'

import { reverseKeysAndValues } from './utilities'
import AssignRoles from './assign_roles'
import ReviewData from './review_data'
import { useEventManager } from '@deathbyjer/react-event-manager'

import { reducer as rolesReducer, load as loadToRoles, useExportUpdates } from './assign_roles/state'

function roleIndexes(values, role_id) {
  const indexes = []
  for (let path of Object.keys(values)) {
    const {key, index} = splitPathSegment(firstPathSegment(path))
    if (key == role_id)
      indexes.push(index)
  }

  return uniq(indexes)
}

const initialData = {
  current_user_roles: [],
  current_user_permissions: {
    read: [], write: []
  },

  form_id: null,
  deal_parties: {}, // This is the list of deal parties
  deal_party_groups: {},
  deal_party_metadata: {},
  party_paths: {},
  data_deal_parties: {},
  basic_info_template: [],
  metadata: {},

  updates: {},
  conflicts: {},

  values: {},
  valuesHash: {},
  errors: {},
  errorsHash: {},

  removedValues: [],

  party_order: [],
  group_order: [],
  person_for_deal_party: {}, // For each deal party id, a person'
  deal_party_for_person: {}, // Reverse mapping of the above, as it is 1-to-1
  people_by_party: {}, // These are the actual users mapped by role id
  people_by_group: {},
  deal_party_next_index: {}
}

const nullIfEmpty = item => isEmpty(item) ? null : item

const DataStore = createSlice({
  name: "data",
  initialState: initialData,
  reducers: {
    load(state, { payload: {  current_user_roles, current_user_permissions, form_id, deal_parties, deal_party_groups, data_deal_parties, party_paths, deal_party_metadata, deal_party_mapping, values, basic_info_template, metadata }}) {
      state.form_id = form_id
      state.deal_parties = deal_parties
      state.deal_party_groups = deal_party_groups
      state.deal_party_metadata = deal_party_metadata
      state.person_for_deal_party = deal_party_mapping
      state.data_deal_parties = data_deal_parties
      state.party_paths = party_paths
      state.deal_party_for_person = reverseKeysAndValues(deal_party_mapping)
      state.basic_info_template = basic_info_template
      state.metadata = metadata
      state.originalValues = values
      state.values = values
      state.valuesHash = pathMapToHash(values)
      state.current_user_roles = current_user_roles
      state.current_user_permissions = current_user_permissions

      // Find Errors
      const errors = {}
      const steps = []
      for (let path in values)
        steps.push(generateRoute(path))

      for (let path of uniq(steps.flat()))
        findErrors(path, metadata, state.valuesHash, errors, {fullRoute: false})

      state.errors = mapValues(errors, nullIfEmpty)
      state.errorsHash = pathMapToHash(state.errors)

      // Find Conflicts
      const conflicts = {}

      for (let path in state.updates)
        if (state.updates[path][0] != values[path])
          conflicts[path] = true

      state.conflicts = conflicts

      state.removedValues = []

      state.party_order = sortObjectKeys(deal_party_metadata)
      state.group_order = sortObjectKeys(deal_party_groups).concat([undefined])

      const people_by_party = mapValues(state.deal_party_metadata, () => [])
      for (let party_id in people_by_party) {
        for (let index of roleIndexes(values, party_id)) {
          // Skip Deal Parties that do not have any associated information
          if (state.valuesHash[party_id] && state.valuesHash[party_id][index])
            people_by_party[party_id].push(`${party_id}[${index}]`)
        }
      }

      const people_by_group = mapValues(state.deal_party_groups, () => [])
      

      state.people_by_party = people_by_party
    },

    assignRole(state, { payload: {person_id, deal_party_id}}) {
      const isPerson = assigned_person => assigned_person == person_id
      const isDealParty = assigned_deal_party => assigned_deal_party == deal_party_id

      state.person_for_deal_party = { ... omitBy(state.person_for_deal_party, isPerson), [deal_party_id]: person_id }
      state.deal_party_for_person = { ... omitBy(state.deal_party_for_person, isDealParty), [person_id]: deal_party_id }

      state.data_deal_parties[person_id] = data.deal_parties[deal_party_id].party_id
    },

    clearRoles(state) {
      state.person_for_deal_party = {}
      state.deal_party_for_person = {}
      // state.people_by_party = {}
    },

    addPerson(state, { payload: role_id}) {
      const data_id = state.deal_party_metadata[role_id].data.path

      const index = (isFinite(state.deal_party_next_index[data_id]) ? state.deal_party_next_index[data_id] : 0) - 1
      const person_id = `${data_id}[${index}]`

      state.data_deal_parties[person_id] = role_id
      state.people_by_party = { ...state.people_by_party, [role_id]: state.people_by_party[role_id].concat([person_id])}
      state.deal_party_next_index = { ...state.deal_party_next_index, [data_id]: index}
    },

    removePerson(state, { payload: {person_id, role_id} }) {
      const deal_party_id = state.deal_party_for_person[person_id]

      if (deal_party_id) {
        delete state.deal_party_for_person[person_id]
        delete state.person_for_deal_party[deal_party_id]
      }

      delete state.data_deal_parties[person_id]
      state.people_by_party[role_id] = state.people_by_party[role_id].filter(id => id != person_id)
      state.removedValues.push(person_id)

      for (let key in state.values) {
        if (!key.startsWith(person_id))
          continue

        delete state.values[key]
        delete state.updates[key]
      }

      state.valuesHash = pathMapToHash(state.values)
    },

    updateValues(state, { payload: hash }) {
      const updates = {}

      for (let path in hash) {
        updates[path] = [
          (state.updates[path] ? state.updates[path][0] : state.values[path]),  // Original Value
          hash[path] // New Value
        ]
      }

      state.updates = { ... state.updates, ... updates }
      state.values = { ...state.values, ...mapValues(state.updates, v => v[1]) }
      state.valuesHash = pathMapToHash(state.values)
    },

    clearUpdates(state) {
      state.updates = {}
    },

    checkErrors(state, { payload: { path, required } }) {
      const errors = { ...state.errors }
      findErrors(path, state.metadata, state.valuesHash, errors, { required })

      state.errors = mapValues(errors, nullIfEmpty)
      state.errorsHash = pathMapToHash(state.errors)
    },

    clearPathErrors(state, { payload: path }) {
      const errors = { ... state.errors }

      for (let segment of generateRoute(path, { metadata: false }))
        delete errors[segment]

      state.errors = errors
      state.errorsHash = pathMapToHash(errors)
    },

    clearErrors(state) {
      state.errors = {}
      state.errorsHash = {}
    }
  }
})

export function usePerson(person_id) {
  // TODO: Update this to use metadata.
  const { first, last, email } = useSelector(({data}) => ({
    first: data.values[`${person_id}.name.first`],
    last: data.values[`${person_id}.name.last`],
    email: data.values[`${person_id}.email`]
  }))

  const name = compact([first, last])
  return {
    exists: name.length > 0,
    name: name.length == 0 ? null : name.join(" "),
    email
  }
}

const { load: loadIntoStore } = DataStore.actions
export const { assignRole, clearRoles, updateValues, addPerson, removePerson, checkErrors, clearPathErrors, clearErrors, clearUpdates } = DataStore.actions

const initialBase = {
  tab: "assign-roles"
}

const BaseStore = createSlice({
  name: "base",
  initialState: initialBase,
  reducers: {
    setTab(state, { payload: tab }) {
      state.tab = tab
    }
  }
})

const { setTab } = BaseStore.actions

export const reducers = {
  data: DataStore.reducer,
  base: BaseStore.reducer,
  roles: rolesReducer,
//  assign_roles: assignReduer,
}

const store = configureStore({
  reducer: { ...reducers }
})

export function useSave() {
  const form_id = useSelector(({data}) => data.form_id)
  const updates = useSelector(({data}) => data.updates)
  const removals = useSelector(({data}) => data.removedValues)

  const fromRoles = useExportUpdates()
  const mergedUpdates = {
    ...updates,
    ...fromRoles.people
  }

  const mergedRemovals = [
    ...removals,
    ...fromRoles.removals
  ]

  //const deal_party_mapping = useSelector(({data}) => data.person_for_deal_party)
  //const new_deal_partys = useSelector(({data}) => data.deal_party_next_index )

  const url = `/forms/v3/instance/${form_id}/data`
  const type = 'put'
  const data = addCSRF({
    form: {
      updates: JSON.stringify(mapValues(mergedUpdates, v => v[1])),
      removals: JSON.stringify(mergedRemovals),
      deal_party_people: JSON.stringify(fromRoles.deal_party_people),
      new_parties: JSON.stringify(fromRoles.new_parties),
      user_updates: JSON.stringify(fromRoles.users),
      user_roles: JSON.stringify(fromRoles.user_roles)
      //deal_party_mapping: JSON.stringify(deal_party_mapping),
      //new_deal_partys
    }
  })

  // return () => new Promise((res, rej) => {
  //   console.log("To Save", data)
  //   res()
  // })


  return () => new Promise((res, rej) => {
    const success = data => res(data)
    const error = xhr => rej()
    ajax({
      url, type, data, dataType: 'json',
      success, error
    })
  })
}

export function useDealPartyAssignment(deal_party_id) {
  const dispatch = useDispatch()
  return useCallback(person_id => {

    dispatch(assignRole({deal_party_id, person_id}))
  }, deal_party_id)
}

export function useClearAssignments() {
  const dispatch = useDispatch()
  dispatch(clearRoles())
}

function Header(props) {
  const events = useEventManager()
  const dispatch = useDispatch()
  const persistToServer = useSave()

  function onSave() {
    persistToServer().then(res => {
      dispatch(clearUpdates())
      events.applyEventListeners('closeRoleAssignment')
    })
  }

  return <div className="header-area">
    <div>
      <div>
        <div>
          <h2>{props.main_title || "Document Setup" }</h2>
        </div>
        <ol>
          <HeaderTab type="assign-roles">Assign Roles</HeaderTab>
          <HeaderTab type='review-data'>Review Deal Data</HeaderTab>
        </ol>
      </div>
      <div>
        <button onClick={onSave}>Assign + Continue</button>
      </div>
    </div>
  </div>
}

function HeaderTab({type, children}) {
  const isSelected = useSelector(state => state.base.tab == type)
  const dispatch = useDispatch()
  const onClick = useCallback(() => dispatch(setTab(type)), [type, dispatch])

  const classes = compact([isSelected ? 'active' : null])

  return <li className={classes.join(" ")} onClick={onClick}>
    {children}
  </li>
}

function Main(props) {
  const tab =  useSelector(state => state.base.tab)

  return <div className="role-assignment-component">
    <Header />
    <div className="main-area">
      { tab == 'assign-roles' ? <AssignRoles /> : null }
      { tab == 'review-data' ? <ReviewData /> : null }
    </div>
  </div>
}


function Loader(props) {
  const { form_id } = props
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()

  const getUrl = props.url || `/forms/v3/instance/${form_id}/role_assignment`
  const editUrl = props.editUrl || `/forms/v3/instance/${form_id}/data`

  const success = useCallback(data => {
    dispatch(loadIntoStore({ ...data, form_id }))
    dispatch(loadToRoles(data))
    setLoaded(true)
  }, [form_id, dispatch, setLoaded])

  useEffect(() => {
    const url = props.url || `/forms/v3/instance/${form_id}/role_assignment`

    $.ajax({
      url, success
    })
  }, [form_id])

  return <>
    { loaded ? <Main /> : <div className="loader"></div>}
  </>
}


export default function(props) {
  return <Provider store={store}>
    <Loader {...props} />
  </Provider>
}
