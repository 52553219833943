import axios from 'axios'

const getCsrfToken = () => {
  return document.head.querySelector('meta[name="csrf-token"]')?.content
}

const httpMethodsNeedCsrfToken = ['post', 'put', 'patch', 'delete']

const defaultInstance = axios.create()

// Add a request interceptor
defaultInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    NProgress.start()

    // Set X-CSRF-TOKEN in headers only when it was not set and the request's method needs the csrf token.
    if (httpMethodsNeedCsrfToken.includes(config.method?.toLowerCase())) {
      config.headers['X-CSRF-TOKEN'] ||= getCsrfToken()
    }

    return config
  },
  function (error) {
    // Do something with request error
    NProgress.done()
    // console.error(error);
    return Promise.reject(error)
  }
)

// Add a response interceptor
defaultInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    NProgress.done()
    return response
  },
  function (error) {
    // Do something with response error
    NProgress.done()
    // console.error(error);
    return Promise.reject(error)
  }
)

export default defaultInstance
