import React from 'react'
import ReactSlider from 'react-slider'
import { compact, pick, omitBy } from 'lodash'

const isEmpty = val => !val || String(val).match(/^\s*$/)

export default function(props) {
  const handleChange = val => props.onChange({[props.id]: val})

  const tagProps = omitBy({ 
    ... pick(props.field, ['min', 'max', 'marks']),
    ... pick(props, ['disabled', 'onBlur']),
    value: props.value || "",
    onChange: handleChange,
    disabled: props.field.system_only || props.disabled,
  }, isEmpty)
  
  const classes = compact([
    "slider-tag",
    props.className
  ])

  return <div className={classes.join(" ")}>
    <ReactSlider { ...tagProps } />
  </div>
}