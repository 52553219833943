import React, { forwardRef, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

const Input = forwardRef(({ value, setValue, inputClasses, tag }, ref) => {
  const inputProps = {
    value: value,
    onChange: (e) => setValue(e.currentTarget.value),
    className: clsx(inputClasses),
    style: { width: '83%' },
  }

  switch (tag) {
    case 'textarea':
      return <textarea {...inputProps} ref={ref} />
    default:
      return <input {...inputProps} maxLength={255} ref={ref} />
  }
})

const ActionButtons = ({ onSubmit, onCancel, buttonClassname }) => {
  return (
    <>
      <button className={buttonClassname} onClick={onSubmit}>
        <i className="fa fa-check" aria-hidden="true"></i>
      </button>
      <button className={buttonClassname} onClick={onCancel}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </button>
    </>
  )
}

const EditableField = ({ defaultValue, onSubmit, children, tag, wrapperClasses, inputClasses, btnClasses }) => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const inputRef = useRef(null)
  const buttonClassname = clsx('btn', 'btn-plain', btnClasses)

  const handleSubmit = () => {
    onSubmit(value.trim())
    setEditing(false)
  }

  const handleCancel = () => {
    setValue(defaultValue)
    setEditing(false)
  }

  const onStartingEdit = () => {
    setEditing(true)
  }

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length)
    }
  }, [editing])

  const wrapperClassname = clsx('editable', wrapperClasses)

  const editableChildren = React.cloneElement(children, {
    onClick: onStartingEdit,
  })

  return editing ? (
    <div className={wrapperClassname}>
      <Input tag={tag} inputClasses={inputClasses} value={value} setValue={setValue} ref={inputRef} />
      <ActionButtons onSubmit={handleSubmit} onCancel={handleCancel} buttonClassname={buttonClassname} />
    </div>
  ) : (
    <div className={wrapperClassname}>{editableChildren}</div>
  )
}

export default EditableField
