import React from 'react'
import PropTypes from 'prop-types'

import FeeRow from './fee_row'

class ModalFeesContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  renderModalFeeRowList() {
    const { fees, setAppState, setFeeRefundAmount } = this.props

    return fees.map((fee, i) => <FeeRow key={fee.id} fee={fee} setAppState={setAppState} selectedFeeIdx={i} setFeeRefundAmount={setFeeRefundAmount} />)
  }

  render() {
    return (
      <div className="fees-list-container">
        {this.renderModalFeeRowList()}
        <hr />
        <span style={{ fontWeight: "bold" }}>Total Refund Amount</span>
        <span className="pull-right">
          <span className="bopa-orange">$</span>{this.props.totalRefundAmount}</span>
      </div>
    )
  }
}

ModalFeesContainer.propTypes = {
  fees: PropTypes.array.isRequired,
  setAppState: PropTypes.func.isRequired,
  setFeeRefundAmount: PropTypes.func.isRequired,
  totalRefundAmount: PropTypes.string.isRequired
}

export default ModalFeesContainer