import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cartPurchaseInvoiceApi } from "./apis";

export const PAYMENT_STATUSES = {
  init: "init",
  processing: "processing",
  completed: "completed",
  failed: "failed",
};

const initialState = {
  invoice: null,
  cards: [],
  paymentStatus: PAYMENT_STATUSES.init,
  statementDescriptor: "",
};

export const cartPurchaseInvoice = createAsyncThunk(
  "residentPaymentsInvoicePayment/cartPurchaseInvoice",
  async ({ request_token, payment_method_id, payment_token }) => {
    const { data } = await cartPurchaseInvoiceApi({ request_token, payment_method_id, payment_token });
    return data;
  }
);

const invoicePaymentSlice = createSlice({
  name: "residentPaymentsInvoicePayment",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.invoice = action.payload.invoice;
      state.cards = action.payload.cards;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cartPurchaseInvoice.pending, (state, action) => {
      state.paymentStatus = PAYMENT_STATUSES.processing;
    });
    builder.addCase(cartPurchaseInvoice.fulfilled, (state, action) => {
      const { invoice, statement_descriptor } = action.payload;
      state.invoice = invoice;

      if (invoice.paid_at) {
        state.paymentStatus = PAYMENT_STATUSES.completed;
        state.statementDescriptor = statement_descriptor;
      } else {
        state.paymentStatus = PAYMENT_STATUSES.failed;
      }
    });
    builder.addCase(cartPurchaseInvoice.rejected, (state, action) => {
      state.paymentStatus = PAYMENT_STATUSES.failed;
    });
  },
});

export const selectInvoicePaymentState = (state) => state.residentPaymentsInvoicePayment;

export const { setInitialState } = invoicePaymentSlice.actions;

export const residentPaymentsInvoicePaymentReducer = invoicePaymentSlice.reducer;
