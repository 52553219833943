import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const RolesAndPermissions = props => {
  const register = props.register
  const { formData } = useSelector(state => state.form)
  const [checkedParticipant, setCheckedParticipant] = useState(false)
  const [checkedCount, setCheckedCount] = useState(Object.keys(formData.roles || {}).filter(role => formData.roles[role]).length)

  const NextButton = () => {
    const commonAttributes = { disabled: checkedCount == 0, type: "submit", className: 'btn btn-success pull-right' }
    const buttonText = checkedParticipant ? 'Next: Doc Visibility' : 'Share'

    return <button {...commonAttributes}>{buttonText}</button>
  }

  const handleChecked = checked => {
    setCheckedCount(checked ? checkedCount+1 : checkedCount-1)
  }

  const handleChangeParticipant = checked => {
    setCheckedParticipant(checked)
    handleChecked(checked)
  }

  return <>
    <div>Select role(s) needed for this application:</div>
    {/* Participant */}
    <div>
      <input {...register("roles.participant")} onChange={e => handleChangeParticipant(e.target.checked)} type="checkbox" style={{ marginRight: 20, verticalAlign: 'top' }} />
      <div style={{ display: 'inline-block' }}>
        <div style={{ fontSize: 16 }}>Participant</div>
        <div>May sign + edit only requirements granted access to.</div>
      </div>
    </div>

    {/* Viewer */}
    <div>
      <input {...register("roles.viewer")} onChange={e => handleChecked(e.target.checked)} type="checkbox" style={{ marginRight: 20, verticalAlign: 'top' }} />
      <div style={{ display: 'inline-block' }}>
        <div style={{ fontSize: 16 }}>Viewer</div>
        <div>May view compiled application + comment.</div>
      </div>
    </div>
    <br />
    <button className='btn btn-default' type='button' onClick={props.close}>Cancel</button>
    <NextButton />
  </>
}

export default RolesAndPermissions
