import React from "react"

class PreviewModal extends React.Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
  }

  componentDidMount() {
    const modal = $(this.modalRef.current)
    modal.on("hidden.bs.modal", () => this.close())
    modal.modal("show")
  }

  close() {
    if (this.props.onClose) this.props.onClose()
  }

  render() {
    const { form } = this.props
    return (
      <div className='modal fade' tabIndex='-1' role='dialog' ref={this.modalRef}>
        <div className='modal-dialog modal-xl' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
              <h5 className='modal-title'>{form.name}</h5>
            </div>
            <div className='modal-body'>
              <iframe
                src={`/forms/${form.id}/html_preview?preview=true`}
                style={{ zoom: 0.6 }}
                frameBorder='0'
                height='700em'
                width='100%'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PreviewModal