import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

import { useScrollingTop } from '../utilities'
import { useToolSidebars } from '../tools'

import { Sidebar as DealParties } from '../deal_parties'

const initialState = {
  action: null
}

const Store = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setAction(state, { payload: action }) {
      state.action = action
    }
  }
})

export const reducer = Store.reducer
export const { setAction } = Store.actions

function Actions(props) {
  const action = useSelector(state => state.sidebar.action)
  const dispatch = useDispatch()
  const clickAction = action => () => dispatch(setAction(action))

  return <div className="sidebar-actions">
    <div onClick={clickAction('deal-parties')}>
      <i className={['fa-user-gear'].concat([action == 'deal-parties' ? 'fa-solid' : 'fa-light']).join(' ')} />
    </div>
    <div onClick={clickAction('tools')}>
      <i className={['fa-brush'].concat([action == 'tools' ? 'fa-solid' : 'fa-light']).join(' ')} />
    </div>
  </div>
}

function Toolbars(props) {
  const selected = useSelector(state => state.form.selected_components)
  const toolSidebars = useToolSidebars({selected})
  const action = useSelector(state => state.sidebar.action)

  switch(action) {
    case 'tools':
      return <div className="tool-sidebars">
        { toolSidebars }
      </div>  
    case 'deal-parties':
      return <DealParties />
  }
}

export default function({parentRef}) {
  const top = useScrollingTop(parentRef)

  const style = {
  //  top: `${top}px`
  }

  return <div className="form-sidebar-component sidebar-area" style={style}>
    <div>
      <Toolbars />
    </div>
    <Actions />
  </div>
}