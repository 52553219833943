import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'
import axios from 'lib/axios'
import BeaconResources from './resources'
import useBeaconDraggable from './useBeaconDraggable'
import qs from 'qs'

export const BeaconSliderContext = createContext()

export default function BeaconSlider({
  children,
  show,
  closeModal,
  numberOfSteps,
  packageHashedId,
  packageUserId,
  disableCheckBox,
  onDisableCheckboxSucceed,
}) {
  const [currentStep, setCurrentStep] = useState(0)
  const next = () => setCurrentStep(currentStep + 1)
  const prev = () => setCurrentStep(currentStep - 1)
  const beaconSliderRef = useRef(null)
  const iconDragRef = useRef(null)
  useBeaconDraggable({ beaconSliderRef, iconDragRef })

  useEffect(() => {
    if (!show) {
      setCurrentStep(0)
    }
  }, [show, setCurrentStep])

  const context = useMemo(
    () => ({
      currentStep,
      next,
      prev,
      closeModal,
      numberOfSteps,
      disableCheckBox,
      onDisableCheckboxSucceed,
      packageUserId,
      packageHashedId,
    }),
    [currentStep, numberOfSteps, disableCheckBox, packageHashedId, packageUserId]
  )

  return (
    <BeaconSliderContext.Provider value={context}>
      <div ref={beaconSliderRef} className="beacon-slider-body">
        <Header ref={iconDragRef} />
        {currentStep < numberOfSteps ? (
          <div className="beacon-slider-content">
            <div className="dome-d-flex dome-flex-column dome-gap24">
              {children(currentStep)}
              <Footer />
            </div>
          </div>
        ) : (
          <BeaconResources />
        )}
      </div>
    </BeaconSliderContext.Provider>
  )
}

const Header = forwardRef((props, ref) => {
  const { closeModal } = useContext(BeaconSliderContext)
  return (
    <div className="dome-d-flex dome-justify-between">
      <i ref={ref} className="beacon-slider-header-icon-drag fa-thin fa-grip-dots-vertical dome-color-med-grey"></i>
      <i onClick={closeModal} className="beacon-slider-header-icon-close fa-light fa-xmark dome-color-med-grey"></i>
    </div>
  )
})

const Footer = () => {
  const { next, prev, currentStep, packageHashedId, packageUserId, disableCheckBox, onDisableCheckboxSucceed } =
    useContext(BeaconSliderContext)
  const [isShowAgain, setIsShowAgain] = useState(!disableCheckBox)
  const beaconCheckBoxId = useId()

  const checkBeaconCheckbox = useCallback(
    (disableBeaconValue) => {
      const path = '/packages/' + packageHashedId + '/manage_beacon'
      const data = {
        package_user_id: packageUserId,
        disable_beacon_value: disableBeaconValue,
      }
      const config = { headers: { 'Accept': 'application/json' } }
      axios
        .post(path, data, config)
        .then((res) => {
          onDisableCheckboxSucceed(disableBeaconValue)
        })
        .catch((er) => console.log(er))
    },
    [packageHashedId, packageUserId]
  )

  const handleOnChange = (e) => {
    const disableBeaconValue = e.currentTarget.checked
    setIsShowAgain(!disableBeaconValue)
    checkBeaconCheckbox(disableBeaconValue)
  }

  return (
    <div className="beacon-slider-footer dome-d-flex dome-justify-between">
      <div className="dome-d-flex dome-align-center dome-gap6">
        <input
          id={beaconCheckBoxId}
          type="checkbox"
          style={{ margin: 0 }}
          checked={!isShowAgain}
          onChange={handleOnChange}
        />
        <label htmlFor={beaconCheckBoxId} role="button" className="dome-margin-auto dome-p3 dome-color-dark-grey">
          please do not show this again
        </label>
      </div>
      <div className="beacon-slider-footer-navigation dome-d-flex dome-gap18">
        {currentStep !== 0 && (
          <button
            type="button"
            onClick={prev}
            className="beacon-slider-footer-navigation-back dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
          >
            back
          </button>
        )}
        <button onClick={next} type="button" className="btn-base btn-dark-green">
          <span>next</span>
          <span>
            <i className="fa-regular fa-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  )
}
