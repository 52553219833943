import React, { useMemo } from 'react'
import QuoteEstimateMonthlyContent from './QuoteEstimateMonthlyContent'
import clsx from 'clsx'

export default function FinalizeQuoteSidebarHeadline({
  type,
  monthlyAmount,
  writingCompany,
  mobileExpanded,
  onToggleExpand,
}) {
  const mobileExpandedDescription = useMemo(() => {
    return mobileExpanded ? 'collapse quote' : 'expand quote'
  }, [mobileExpanded])

  return (
    <>
      <div className="finalize-quote-risk-type-and-quote-monthly">
        <div className="finalize-quote-status">
          <div className="finalize-quote-status__title" onClick={onToggleExpand}>
            {mobileExpandedDescription}
          </div>
        </div>
        <div className="finalize-quote-quote-monthly">
          <QuoteEstimateMonthlyContent monthlyAmount={monthlyAmount} writingCompany={writingCompany} />
        </div>
      </div>
      <div className="finalize-quote-headline-icon">
        <i
          className={clsx('dome-cursor-pointer fa-light', mobileExpanded ? 'fa-chevron-up' : 'fa-chevron-down')}
          onClick={onToggleExpand}
        />
      </div>
    </>
  )
}
