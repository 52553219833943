import React from "react";

export default function BuildingResidentTable({ residents}) {
  if (residents.length === 0) return;

  return (
    <>
      <table className="dome-table dome-table-striped dome-table-tight dome-full-width">
        <thead>
          <tr>
            <th style={{ width: "25%" }}>first name</th>
            <th style={{ width: "25%" }}>last name</th>
            <th style={{ width: "30%" }}>email address</th>
            <th style={{ width: "20%" }} colSpan={1}>
              phone number
            </th>
          </tr>
        </thead>
        <tbody>
          {residents.map((resident) => (
            <tr key={resident.id}>
              <td>{resident.first_name}</td>
              <td>{resident.last_name}</td>
              <td>{resident.email}</td>
              <td>{resident.phone_number}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
