import React from 'react'
import BuildingSelect from '../utility/building_select'

import { chunk, padEnd } from 'lodash'

const onlyNumbers = str => String(str).replaceAll(/[^0-9.]/g, '')

function addCommas(number) {
  const asArray = number.split('')
  const chunkedArray = chunk(asArray.reverse(), 3).reverse().map(num => num.reverse().join(""))
  return chunkedArray.join(',')
}

function prettyNumber(string) {
  const digits_only = onlyNumbers(string)
  const [dollars, cents] = digits_only.split(".")
  const croppedCents = padEnd(String(cents || "").substring(0, 2), 2, '0')
  return [addCommas(dollars), croppedCents].join(".")
}
class ConciergeModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.errors = {}

    this.required = [ "unit",
                      "name", "email", "confirm_email", "phone",
                      "role", "package_type"]
    this.all_fields = this.required.concat(["building", "custom_building", "financing", "corp_leasing",
                                            "parties_in_us", "brokers_involved", "has_file", "agent_type"])
  }

  componentDidMount() {
    $(this.refs.modal).modal("show")
    $(this.refs.modal).on('hidden.bs.modal', () => this.props.onClose(null))
  }

  componentWillUnmount() {
    $(this.refs.modal).off("hidden.bs.modal", () => this.props.onClose(null))
  }

  close() {
    $(this.refs.modal).modal("hide")
    this.props.onClose(null)
  }

  hasPackageType() {
    return this.state.package_type && !this.state.package_type.match(/^\s*$/)
  }

  isBrokerOrAgentInvolved () {
    return parseInt(this.state.brokers_involved);
  }

  isSale() {
    return ["coop_sale", "condo_sale"].includes(this.state.package_type)
  }

  isLease() {
    return ["coop_sublease", "condo_lease"].includes(this.state.package_type)
  }

  isRental() {
    return this.state.package_type == "rental"
  }

  isRefinance() {
    return this.state.package_type == "refinance"
  }

  isAlteration() {
    return this.state.package_type == 'alteration'
  }

  hasAgentType() {
    return !this.isRefinance() && !this.isAlteration()
  }

  all_filled() {
    const is_filled = item => this.state[item] && !String(this.state[item]).match(/^\s*$/)
    if (!this.required.every(field => is_filled(field)))
      return false

    if (!is_filled("building") && !this.state.custom_building)
      return false

    if (this.isSale() && !is_filled("purchase_price"))
      return false

    if (this.isSale() && !is_filled("financing"))
      return false

    if ((this.isRental() || this.isLease()) && !is_filled("corp_leasing"))
      return false

    if ((this.isRental() || this.isLease()) && !is_filled("lease_amount"))
      return false

    if ((this.isSale() || this.isRental() || this.isLease()) && !is_filled("parties_in_us"))
      return false

    if (this.isAlteration())
      return true

    if (!this.isRefinance() && !is_filled("brokers_involved"))
      return false

    if (this.isBrokerOrAgentInvolved() && !is_filled("agent_type"))
      return false

    return true
  }

  gatherErrors() {
    const errors = {}

    const is_blank = item => !this.state[item] || String(this.state[item]).match(/^\s*$/)

    for (let field of this.required)
      if (is_blank(field))
        errors[field] = "This field is required"

    if (!this.state.building && !this.state.custom_building)
      errors.building = "This field must be fully completed"

    if (!errors.email && !this.state.email.match(/^.+@.+\..+$/))
      errors.email = "This is not a valid email address"

    if (!errors.email && !errors.confirm_email && this.state.email != this.state.confirm_email)
      errors.confirm_email = "Emails must match"

    if (this.isSale() && is_blank("financing"))
      errors.financing = "This field is required"

    if (this.isSale() && is_blank("purchase_price"))
      errors.purchase_price = "This field is required"

    if ((this.isRental() || this.isLease()) && is_blank("corp_leasing"))
      errors.corp_leasing = "This field is required"

    if ((this.isRental() || this.isLease()) && is_blank("lease_amount"))
      errors.lease_amount = "This field is required"

    if ((this.isSale() || this.isRental() || this.isLease()) && is_blank("parties_in_us"))
      errors.parties_in_us = "This field is required"

    if (!this.isRefinance() && !this.isAlteration() && is_blank("brokers_involved"))
      errors.brokers_involved = "This field is required"

    if (this.state.has_file) {
      if (!this.refs.file || this.refs.file.files.length == 0)
        errors.file = "If uploading a file, you must select a file"
      else if (this.refs.file.files.length > 1)
        errors.file = "You may only select one file to send."
    }

    if (this.isBrokerOrAgentInvolved() && is_blank("agent_type"))
      errors.agent_type = "This field is required"

    this.setState({errors: errors})
    return Object.keys(errors).length > 0
  }

  isSubmitting() {
    if (this.state.submitting)
      return true

    this.setState({submitting: true})
    return false
  }

  checkSubmitting() {
    return this.state.submitting
  }

  finishedSubmitting() {
    this.setState({submitting: false})
  }

  submit() {
    if (this.isSubmitting())
      return

    if (this.gatherErrors())
      return this.finishedSubmitting()

    const form_data = new FormData()
    for (let attr of this.required)
      form_data.append(`request[${attr}]`, this.state[attr])

    if (this.state.custom_building) {
      for (let attr in this.state.custom_building)
        form_data.append(`request[custom_building][${attr}]`, this.state.custom_building[attr])
    }
    else
      form_data.append("request[building]", this.state.building.id)

    if (this.isSale()) {
      form_data.append("request[financing]", this.state.financing)
      form_data.append("request[purchase_price]", this.state.purchase_price)
    }

    if (this.isRental() || this.isLease()) {
      form_data.append("request[corp_leasing]", this.state.corp_leasing)
      form_data.append("request[lease_amount]", this.state.lease_amount)
    }

    if (this.isSale() || this.isRental() || this.isLease())
      form_data.append("request[parties_in_us]", this.state.parties_in_us)

    if (!this.isRefinance() && !this.isAlteration())
      form_data.append("request[brokers_involved]", this.state.brokers_involved)

    if (this.state.has_file)
      form_data.append("request[file]", this.refs.file.files[0], this.refs.file.files[0].name)

    form_data.append("request[service_type]", this.props.type)

    if (this.isBrokerOrAgentInvolved())
      form_data.append("request[agent_type]", this.state.agent_type)

    const ajax_options = {
      url: "/concierge",
      type: "POST",
      data: form_data,
      dataType: 'json',
      success: () => this.setState({success: true}),
      error: xhr => {
        try {
          const errors = JSON.parse(xhr.responseText).error
          if (typeof errors == "object")
            this.setState({errors: errors})
          else
            this.setState({server_error: errors})
        }
        catch(e) {
          this.setState({server_error: "Something went wrong with the request. Please try again later or contact BoardPackager by emailing info@boardpackager.com"})
        }
      },
      complete: () => this.finishedSubmitting(),
      contentType: false,
      processData: false
    }

    $.ajax(ajax_options);
  }

  setValue(e, id, options = {}) {
    const state = {}
    const errors = Object.assign({}, this.state.errors)
    delete errors[id]

    state[id] = e.target.value ? String(e.target.value).trim() : null
    if (options.isNumber)
      state[id] = onlyNumbers(state[id])

    state.errors = errors

    this.setState(state)
  }

  setChecked(e, id) {
    const state = {}
    const errors = Object.assign({}, this.state.errors)
    delete errors[id]

    state[id] = e.target.checked
    state.errors = errors

    this.setState(state)
  }

  setBuilding(evt) {
    const state = {}
    const errors = Object.assign({}, this.state.errors)
    delete errors.building

    state.building = evt.building
    state.custom_building = evt.custom_building
    state.errors = errors

    this.setState(state)
  }

  setRadioChecked(e, id) {
    if (!e.target.checked)
      return

    this.setValue(e, id)
  }

  renderLine(id, input, required, label) {
    const children = []
    const labelChildren = []

    labelChildren.push(label)
    if (required)
      labelChildren.push(<span className="required-star" key="required">*</span>)
    children.push(input)

    const fields = []
    const classes = ["form-line"]
    if (label)
      classes.push(label.split(' ').join('-'))

    fields.push(<div className="field input-field" key="input">{children}</div>)

    if (this.state.errors[id]) {
      classes.push("has-error")
      fields.push(<div key="error" className="error">{this.state.errors[id]}</div>)
    }

    return (
      <div className='field-container' key={id}>
        <label id={id} htmlFor={id}>
          {labelChildren}
        </label>
        <div className={classes.join(" ")} key={id}>
          {fields}
        </div>
      </div>
    )
  }

  renderText(id, label, required) {
    const input = <input key="value" type="text" defaultValue={this.state[id]}
                        required={required} onChange={e => this.setValue(e, id)} />
    return this.renderLine(id, input, required, label)
  }

  renderMoney(id, label, required) {
    const onBlur = e => e.target.value = prettyNumber(e.target.value)
    const onFocus = e => e.target.value = onlyNumbers(e.target.value)

    const onChange = e => this.setValue(e, id, { isNumber: true })

    const input = <div key="value" className="money-type">
      <i className="fa-light fa-dollar" />
      <input type="text" defaultValue={this.state[id]}
          onChange={onChange} onBlur={onBlur} onFocus={onFocus}  />
    </div>

    return this.renderLine(id, input, required, label)
  }

  renderSelect(id, label, values, required) {
    const options = values.map(item => <option key={item.id} value={item.id}>{item.name || item.label}</option>)
    options.unshift(<option className="placeholder" value="" key="__placeholder"></option>)

    const classes = []
    if (!this.state[id] || this.state[id] == "")
      classes.push("placeholder")

    const input = <select className={classes.join(" ")} key={id} onChange={e => this.setValue(e, id)} defaultValue={this.state[id]}>{options}</select>
    return this.renderLine(id, input, required, label)
  }

  renderBoolean(id, label, required) {
    const options = []
    options.push(<label key="yes">
      <input type="radio" name={id} defaultChecked={this.state[id] == "1"} value="1" onChange={e => this.setRadioChecked(e, id)} />
      <span>yes</span>
    </label>)

    options.push(<label key="no">
      <input type="radio" name={id} defaultChecked={this.state[id] == "0"} value="0" onChange={e => this.setRadioChecked(e, id)} />
      <span>no</span>
    </label>)

    const fields = []
    fields.push(<div className="boolean-options" key="options">{options}</div>)
    return this.renderLine(id, <div key={id} className="boolean">{fields}</div>, required, label)
  }

  renderCheck(id, label) {
    return <label className="field" key={id}>
      <input type="checkbox" defaultChecked={this.state[id]} onChange={e => this.setChecked(e, id)} />
      <span>{label}</span>
    </label>
  }

  renderFileUpload(id) {
    const handleDelete = (id) => {
      if (id && this.refs[id]) {
        this.refs[id].value = ''
        this.setState({fileSelected: false})
      }
    }
    const input = <div className='file-upload-container'>
      <input type="file" ref={id} key={id} onChange={() => this.setState({fileSelected: true})}/>
      {this.state.fileSelected && <button className='btn-link delete-link' onClick={() => handleDelete(id)}></button>}
    </div>
    return this.renderLine(id, input, false)
  }

  renderBuilding() {
    const custom_element = <div>To learn more about concierges for custom packages, please contact us at <a style={{color: '#0091A3'}} href="mailto:info@domecile.com">info@domecile.com</a>.</div>
    const props = {
      key: 'building',
      show_magnifying_glass: false,
      show_empty_message: true,
      allow_custom: true,
      ref: 'building_select',
      params: {include_addresses: 1},
      placeholder: 'find a property...',
      sorryMessage: <><div>Sorry, but we couldn't find any matching properties.<br/>Please try your search again.</div></>,
      custom_element,
      onChange: (e) => this.setBuilding(e)
    }
    const input = <BuildingSelect {...props} />
    return this.renderLine("building", input, true, "property")
  }

  renderUnit() {
    const buildingSelected = this.state.building
    const input = <input key="value" className={buildingSelected ? 'building-is-selected' : null} defaultValue={this.state['unit']} type="text" required={true} onChange={e => this.setValue(e, 'unit')} />

    return buildingSelected ? this.renderLine('unit', input, true, 'unit') : null
  }

  renderInlineRow(fields) {
    return (
      <div className='form-inline-row'>
        {fields}
      </div>
    )
  }

  formElements() {
    const elements = []

    elements.push(this.renderInlineRow([this.renderBuilding(), this.renderUnit()]))
    elements.push(<h4 className='contact-info' key="contact-info">contact information</h4>)
    elements.push(this.renderText("name", "name", true))
    elements.push(this.renderText("email", "email", true))
    elements.push(this.renderText("confirm_email", "confirm email", true))
    elements.push(this.renderText("phone", "phone number", true))
    elements.push(
      this.renderInlineRow([
        this.renderSelect("role", "role", this.props.roles, true),
        this.renderSelect("package_type", "package type", this.props.package_types, true)
      ])
    )

    if (this.isSale()) {
      elements.push(this.renderMoney("purchase_price", "purchase amount", true))
      elements.push(this.renderBoolean("financing", "Financing", true))
    }


    if (this.isLease() || this.isRental()) {
      elements.push(this.renderMoney("lease_amount", "lease amount", true))
      elements.push(this.renderBoolean("corp_leasing", "Is a corporation leasing this apartment?", true))
    }

    if (this.isSale() || this.isLease() || this.isRental())
      elements.push(this.renderBoolean("parties_in_us", "Do all deal parties live in the US?", true))

    if (this.hasPackageType() && this.hasAgentType())
      elements.push(this.renderBoolean("brokers_involved", "Are there any real estate broker(s)/agent(s) involved in this transaction?", true))

    if (this.hasPackageType() && this.hasAgentType() && this.isBrokerOrAgentInvolved())
      elements.push(this.renderSelect("agent_type", "agent type", this.props.agent_types, true))

    elements.push(this.renderCheck("has_file", "include documentation"))

    if (this.state.has_file)
      elements.push(this.renderFileUpload("file"))

    return elements
  }

  renderSuccess() {
    if (!this.state.success)
      return

    return <div className="success">
      <div className="header">
        <i className='fa-thin fa-circle-check'></i>
        <h2>concierge success!</h2>
      </div>

      <div className="body">
        <p className="description">Your request for a price quote has been received.</p>
        <p className="description">You will first receive a confirmation email, then a follow-up communication detailing next steps.</p>
      </div>

      <div className="buttons">
        <button type="button" className="btn-base btn-aqua-stroke" onClick={() => this.close()}>OK</button>
      </div>
    </div>
  }

  renderForm() {
    if (this.state.success)
      return

    const submission_classes = ["btn-base", "btn-go-green"]
    if (!this.all_filled() || this.checkSubmitting())
      submission_classes.push("btn-disabled")

    if (this.checkSubmitting())
      submission_classes.push("submitting")

    return <div>
      <div className='header'>
        <i className='icon'></i>
        <h2>concierge services</h2>
      </div>
      <p className="description" style={{fontWeight: 300}}>
        Please provide the following info to request quote.
      </p>

      <div className="form-area">{this.formElements()}</div>

      <div className="buttons">
        <button type="button" className="btn-link cancel-link" onClick={() => this.close()}>cancel</button>
        <button type="button" className={submission_classes.join(" ")} onClick={() => this.submit()}>submit</button>
      </div>
    </div>
  }

  render() {
    return <div id="specialist-request-modal" className="modal" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            {this.renderForm()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    </div>
  }
}

export default ConciergeModal
