import React from 'react'
import PropTypes from 'prop-types'

import ReasonAdditionalDetails from './reason_additional_details'
import ReasonSelector from './reason_selector'

class ReasonContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  renderAdditionalDetails() {
    if (this.props.reason != "Other") return null

    return (
      <ReasonAdditionalDetails
        setAppState={this.props.setAppState}
        additionalDetails={this.props.additionalDetails} />
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div id="refund-reason-select-container" className="col-xs-12 col-sm-6">
            <ReasonSelector reason={this.props.reason} setAppState={this.props.setAppState} />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            {this.renderAdditionalDetails()}
          </div>
        </div>
      </div>
    )
  }
}

ReasonContainer.propTypes = {
  setAppState: PropTypes.func.isRequired,
  additionalDetails: PropTypes.string,
  reason: PropTypes.string
}

export default ReasonContainer