import axios from 'axios'

export function submit(package_id, data) {
  const url = `/packages/${package_id}/add_additional_contact`
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }


  return axios.post(url, { additional_contact: data }, config)
}

export function updateVisibleRequirements(package_id, data) {
  const url = `/packages/${package_id}/update_additional_contact`
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return axios.put(url, { additional_contact: data }, config)
}

export function removeContact(package_id, userEmail) {
  const url = `/packages/${package_id}/remove_additional_contact`
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: { additional_contact: { email: userEmail } }
  }

  return axios.delete(url, config)
}
