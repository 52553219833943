import React from "react"
import Main from "./app/Main"
import { Provider } from "react-redux"
import store from './app/store'

const App = (props) => {

  return (
    <Provider store={store}>
      <Main />
    </Provider>
  )
}

export default App
