import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
import { useEventListeners, useEventManager } from '@deathbyjer/react-event-manager'
import { LeaseContext } from '../../../../plugins/leases_plugin/javascripts/fees/contexts/LeaseContext'
import { FeeContext } from '../../../../plugins/leases_plugin/javascripts/fees/contexts/FeeContext'
import { ModalContext } from '../../../../plugins/leases_plugin/javascripts/ModalContext'
import { dispatchEvent } from '../../../lib/utilities'

const DealPartyDocumentCompleteModalBody = ({handleClose, handleNextDocument}) => {
  return (
    <>
      <h3>Continue Signing</h3>
      <div>
        <p><span>All items requiring your signature on this document have been completed.</span></p>
      </div>

      <div className='button-container'>
        <button className="cancel" onClick={handleClose}>Stay on this Doc</button>
        <button className="green" onClick={handleNextDocument}>Next Document</button>
      </div>
    </>
  )
}

const FeesRemaining = () => {
  return (
    <>
      <div>
        <p><span>All the signatures you're required to submit have been collected.</span></p>
      </div>
      <div className='next-steps'>
        <p>
          <span className='orange'>Next Steps</span>
          <span>: Authorize any outstanding fees + submit the Agreement to Management.</span>
        </p>
      </div>
    </>
  )
}

const NoFeesRemaining = () => {
  return (
    <>
      <div>
        <p><span>Your required signatures across all documents have been collected.</span></p>
      </div>
      <div>
        <p>
          <span className='orange'>Next Steps</span>
          <span>: You will be notified when all requirements are complete + the Agreement is ready to submit to Management.</span>
        </p>
      </div>
    </>
  )
}

const LandlordDocumentComplete = ({handleClose}) => {
  return (
    <>
      <h3>Congratulations!</h3>
      <div>
        <p><span>Your required signatures across all documents have been collected.</span></p>
      </div>

      <div className="button-container center">
        <button className="cancel" onClick={handleClose}>Close</button>
      </div>
    </>
  )
}

const AllComplete = ({handleClose}) => {
  const { remainingBalance, packageId } = useContext(FeeContext)
  const feesRemaining = remainingBalance > 0

  const goToFeesTab = () => {
    window.location.href = `/package_hook/${packageId}/fees`
  }

  return (
    <>
      <h3>Congratulations!</h3>
      {feesRemaining ? <FeesRemaining /> : <NoFeesRemaining />}
      <div>
        <p><span className='footnote'><em>Please note, another Tenant may submit the Agreement on your behalf.</em></span></p>
      </div>

      <div className={`button-container${!feesRemaining ? ' center' : ''}`}>
        <button className="cancel" onClick={handleClose}>Close</button>
        {feesRemaining && <button className="white" onClick={goToFeesTab}>Go to Fees</button>}
      </div>
    </>
  )
}

const SignaturesComplete = ({handleClose}) => {
  const { userRoles } = useContext(LeaseContext)

  return (
    userRoles.includes('landlord') ? <LandlordDocumentComplete handleClose={handleClose} /> : <AllComplete handleClose={handleClose} />
  )
}

const Modals = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const [show, setShow] = useState(true)
  const { remainingSignaturesLeft } = useContext(LeaseContext)
  const noSignaturesLeft = remainingSignaturesLeft == 0

  const handleClose = () => {
    dispatchEvent("signatures-complete-modal-closed", {})
    setShow(false)
  }
  const handleCloseStayOnDoc = () => {setShow(false); setCurrentModal(null)}
  const events = useEventManager()

  const handleNextDocument = () => {
    events.applyEventListeners('nextDocumentRequested')
    setShow(false)
    setCurrentModal(null)
  }

  return (
    <Modal show={show} centered={true} onHide={()=> setCurrentModal(null)} size='md' dialogClassName='modal-45rem' className="signature-modal">
      <Modal.Body className="signature-modal-body">
      <div className={`${noSignaturesLeft ? 'signatures-lock' : 'continue-signing'}`}></div>
        {noSignaturesLeft ? <SignaturesComplete handleClose={handleClose} /> : <DealPartyDocumentCompleteModalBody handleClose={handleCloseStayOnDoc} handleNextDocument={handleNextDocument} />}
      </Modal.Body>
    </Modal>
  )

}

function SigningModals() {
  const [justSigned, setJustSigned] = useState(false)
  const allSigned = useSelector(({ form_signatures: state }) => state.signatures_to_sign?.allSigned)
  const { addToModalQueue } = useContext(ModalContext)


  useEventListeners({
    'signatureAdded': () => setJustSigned(true)
  })

  useEffect(() => {
    if (!justSigned)
      return

    allSigned ? addToModalQueue(<Modals />) : setJustSigned(false) // If just signed changed, but we are not all signed, then we need to reset.
  }, [allSigned, justSigned])

  return null
}


export default function () {
  return <>
    <SigningModals />
  </>
}
