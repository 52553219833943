import React from 'react'

import { errorPopup, confirmRemoval } from './utilities'

class SingleError extends React.Component {
  render() {
    return <div className="payment-single-error">
      <div className="title">{this.props.item.title}</div>
      <div className="price">{asMoney(this.props.item.price)}</div>
    </div>
  }
}

class ErrorGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  erroredAt() {
    const datetime = new Date(this.props.item.errored_at * 1000)
    const date = [datetime.getMonth() + 1, datetime.getDay(), datetime.getFullYear()]

    return date.join("/")
  }

  clickViewAll(e) {
    e.preventDefault()
    this.setState({open: !this.state.open})
  }

  confirmRemoval() {
    confirmRemoval(() => this.remove())
  }

  remove() {
    const success = res => {
      swal.close()
      this.props.onComplete()
    }

    const error = xhr => {
      const error = JSON.parse(xhr.responseText).error
      errorPopup(error)
    }

    $.ajax({
      url: `/user_payment_method/${this.props.id}/payment_error_group`,
      type: 'delete',
      data: hashToPostQueryString({group_id: this.props.group.id}),
      success, error
    })
  }

  render() {
    return <div className="payment-error-group">
      <div>
        <div className="title">{this.props.group.title}</div>
        <div className="price">{asMoney(this.props.group.total_price)}</div>
        <div className="error">{this.props.group.error}</div>
        <div className="number-of-errors">
          {this.props.group.payment_errors.length}
        </div>
        <div className="buttons">
          <button className="btn btn-view-all btn-sm" onClick={e => this.clickViewAll(e)}>
            {this.state.open ? "Hide Items" : "View Items"}
          </button>
          <button className="btn btn-danger btn-sm" onClick={() => this.confirmRemoval()}>Cancel</button>
        </div>
      </div>
      {this.renderExpansion()}
    </div>
  }

  renderExpansion() {
    const style = { height: this.state.open ? "auto" : 0, opacity: this.state.open ? 1 : 0 }
    if (!this.state.open)
      style.pointerEvents = "none"

    return <div className="expansion" style={style}>
      <div className="items-list-container">
        <label>Items within this Payment</label>
        <div className="list">
          { this.props.group.payment_errors.map(err => <SingleError key={err.id} item={err} />) }
        </div>
      </div>
    </div>
  }
}

export default ErrorGroup