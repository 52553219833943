// todo: dry up handler functions
import React from 'react'
import PropTypes from 'prop-types'

// This isn't actually a <table> element. It's just a <div> using bootstrap classes for simpler designing.
class FeesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fetchingData: false,
      firstLoad: true,
      edittableFees: [],

      availableAssignees: {},
      routingAccounts: {},
      availableAssignees: {}
    }

    this.handleAddNewFeeRowClick = this.handleAddNewFeeRowClick.bind(this)
    this.handleFeeAttributeChange = this.handleFeeAttributeChange.bind(this)
    this.handleDeleteRowBtnClick = this.handleDeleteRowBtnClick.bind(this)
  }

  componentDidMount() {
    // todo: turn getters to promise/async functions and set loading to false at the end
    this.getTableData()

    if (!this.state.fetchingData && this.state.loading)
      this.setLoading(false)
  }

  componentDidUpdate(_, prevState) {
    if (prevState.firstLoad && !this.state.firstLoad) {
      this.componentDidFirstLoad()
    }
  }

  componentDidFirstLoad() {
    switch(this.props.modalPurpose) {
      case "add":
        this.addDefaultNewFeeRow()
        break
      default:
        return null
    }
  }

  hasRequiredData() {
    if (!this.state.availableFeeNamesByDueAt) return false
    if (!this.state.availableAssignees) return false
    if (!this.state.routingAccounts) return false
    return true
  }

  isColumnEditable(fee, column) {
    if (!this.isFeeEditable(fee)) return false

    switch(column) {
      case "dueUpon":
      case "glCode":
      case "chargeCode":
      case "name":
      case "payee":
      case "routingAccount":
        return [undefined, "Assigned"].includes(fee.status)
      case "amount":
        return true
      default:
        return false
    }
  }

  isFeeEditable(fee) {
    switch(this.props.modalPurpose) {
      case "add":
        return !this.props.confirming // newly added fees are editable except during confirmation step
      case "edit":
        return fee.status != "Collected" // only assigned fees can be edited
      default:
        return false
    }
  }

  isFeeDeletable(fee) {
    switch(this.props.modalPurpose) {
      case "add":
        return !this.props.confirming // newly added fees are deletable except during confirmation step
      case "edit":
        return fee.status != "Collected" // only uncaptured fees can be deleted
      default:
        return false
    }
  }

  isFeeAmountValid(fee) {
    switch(this.props.modalPurpose) {
      case "add": {
        return Number(fee.amount) >= 1 && Number(fee.amount) < 100000
      }
      case "edit": {
        const amount = Number(fee.updatedAttributes ? (fee.updatedAttributes.amount || fee.amount) : fee.amount)
        if (amount < 1) return false
        if (amount > fee.amount) return false
        return true
      }
      default:
        return true
    }
  }

  getTableData() {
    if (this.hasRequiredData())
      return

    this.setState({fetchingData: true})

    const url = "/packages/" + this.props.packageHashedId + "/new_additional_fees_table_data"

    const stateSuccess = () => {
      if (!this.hasRequiredData())
        return

      this.setState({fetchingData: false})
      this.setLoading(false)
    }

    const success = ({available_names_by_due_at, assignable_package_users, routing_accounts}) => {
      this.setState({
        availableFeeNamesByDueAt: available_names_by_due_at,
        availableAssignees: assignable_package_users,
        routingAccounts: routing_accounts
      }, stateSuccess)
    }

    $.ajax({
      url,
      method: "GET", dataType: "JSON",
      success
    })
  }

  setLoading(bool) {
    let newState = { loading: bool }
    if (this.state.firstLoad) newState["firstLoad"] = false

    this.setState(newState)
  }

  defaultNewFeeAttributes() {
    return {
      name: "",
      package_user_id: "",
      due_at: "",
      sub_merchant_id: "",
      amount: 0
    }
  }

  addDefaultNewFeeRow() {
    let newFeesToAdd = this.props.feesToAdd
    newFeesToAdd.push(this.defaultNewFeeAttributes())

    this.props.setModalState({ feesToAdd: newFeesToAdd })
  }

  addExistingFeeRows() {

  }

  updateModalStateWithElementValue($element, attributeName) {
    const rowData = $element.parents(".fee-row").data()

    switch(rowData["rowType"]) {
      case "add": {
        const rowIndex = rowData["rowIndex"]
        let newFeeState = this.props.feesToAdd
        let value = $element.val()

        newFeeState[rowIndex][attributeName] = value

        this.props.setModalState({feesToAdd: newFeeState})
        break
      }
      case "edit": {
        const feeId = rowData["feeId"]
        let newFeeState = this.props.feesToEdit
        let newAttributeVal = $element.val()

        // clears updatedAttribute attribute if value is the same as the original, else set updatedAttribute
        newFeeState[feeId][attributeName] == newAttributeVal
        ? delete newFeeState[feeId].updatedAttributes[attributeName]
        : newFeeState[feeId].updatedAttributes[attributeName] = newAttributeVal

        this.props.setModalState({feesToEdit: newFeeState})
        break
      }
      default:
        return null
    }
  }

  handleAddNewFeeRowClick() {
    this.addDefaultNewFeeRow()
  }

  handleFeeAttributeChange(e, attributeName) {
    this.updateModalStateWithElementValue($(e.target), attributeName)
  }

  handleDeleteRowBtnClick(e) {
    const rowData = $(e.target).parents(".fee-row").data()

    switch(rowData["rowType"]) { // delete row element from feesToAdd array
      case "add": {
        let feesToAdd = [...this.props.feesToAdd]
        feesToAdd.splice(rowData["rowIndex"], 1)
        this.props.setModalState({ feesToAdd: feesToAdd })
        break
      }
      case "edit": { // toggles markedForDeletion attribute on fees
        const feeId = rowData["feeId"]
        let newFeeState = this.props.feesToEdit
        newFeeState[feeId].markedForDeletion = !newFeeState[feeId].markedForDeletion
        this.props.setModalState({feesToEdit: newFeeState})
        break
      }
      default:
        return null
    }
  }

  tableHeaders() {
    let headers = ["Fee Name", "Payor", "Due Upon", "GL Code", "Charge Code", "Routing Account", "Amount"]
    switch(this.props.modalPurpose) {
      case "add":
        if (!this.props.confirming)
          headers.push(<button className="btn-circle parent-flex-1" onClick={() => this.handleAddNewFeeRowClick()}>+</button>)
        return headers
      case "edit":
        headers.push("Fee Status")
        headers.push(<div className="parent-flex-1"></div>)
        return headers
      case "collect":
        return ["Fee Name", "Payor", "GL Code", "Charge Code", "Amount", "Fee Status"]
    }
  }

  renderTableHeader() {
    let headerElementsList = []

    this.tableHeaders().forEach(function(headerContent, i) {
      let classNames = "flex-column table-header"
      if (typeof headerContent == "object" && headerContent.props.className.includes("parent-flex-1"))
        classNames += " flex-1"
      headerElementsList.push(<div key={i} className={classNames}>{headerContent}</div>)
    })

    return (
      <div className="fee-header flex-container">
        {headerElementsList}
      </div>
    )
  }

  renderNameElement(fee) {
    const value = fee.updatedAttributes && fee.updatedAttributes.name != undefined ? fee.updatedAttributes.name : fee.name

    if (this.isColumnEditable(fee, "name"))
      return <textarea type="text"
        name="name"
        className="form-control"
        value={value || ""}
        onChange={(e) => this.handleFeeAttributeChange(e, "name")} />

    return <div>{value}</div>
  }

  renderPayeeElement(fee) {
    const value = fee.updatedAttributes ? (fee.updatedAttributes.package_user_id || fee.package_user_id) : fee.package_user_id
    const payeeName = this.state.availableAssignees[value]?.full_name || fee.payee_name

    if (this.isColumnEditable(fee, "payee"))
      return <select name="payee"
        className="form-control"
        value={value}
        onChange={(e) => this.handleFeeAttributeChange(e, "package_user_id")}>{this.payeeOptionsList()}</select>

    return <div>{payeeName}</div>
  }

  renderGLCodeElement(fee) {
    const value = fee.updatedAttributes ? (fee.updatedAttributes.gl_code || fee.gl_code) : fee.gl_code

    if (this.isColumnEditable(fee, "glCode"))
      return <textarea type="text"
        className="form-control"
        name="glCode"
        value={value || ""}
        onChange={(e) => this.handleFeeAttributeChange(e, "gl_code")} />

    return <div>{value}</div>
  }

  renderChargeCodeElement(fee) {
    const value = fee.updatedAttributes ? (fee.updatedAttributes.charge_code || fee.charge_code) : fee.charge_code

    if (this.isColumnEditable(fee, "chargeCode"))
      return <textarea type="text"
        className="form-control"
        name="chargeCode"
        value={value || ""}
        onChange={(e) => this.handleFeeAttributeChange(e, "charge_code")} />

    return <div>{value}</div>
  }

  renderDueUponElement(fee) {
    const value = fee.updatedAttributes ? (fee.updatedAttributes.due_at || fee.due_at) : fee.due_at

    if (this.isColumnEditable(fee, "dueUpon"))
      return <select name="dueUpon"
        className="form-control"
        value={value}
        onChange={(e) => this.handleFeeAttributeChange(e, "due_at")}>{this.dueUponList()}</select>

    return <div>{value}</div>
  }

  renderRoutingAccountElement(fee) {
    const value = fee.updatedAttributes ? (fee.updatedAttributes.sub_merchant_id || fee.sub_merchant_id) : fee.sub_merchant_id

    if (this.isColumnEditable(fee, "routingAccount"))
      return <select name="routingAccount"
        className="form-control"
        value={value}
        onChange={(e) => this.handleFeeAttributeChange(e, "sub_merchant_id")}>{this.routingAccountList()}</select>

    return <div>{this.state.routingAccounts[value]}</div>
  }

  renderAmountElement(fee) {
    const value = fee.updatedAttributes && fee.updatedAttributes.amount != undefined ? fee.updatedAttributes.amount : fee.amount

    if (this.isColumnEditable(fee, "amount"))
      return <input name="amount"
        className={`form-control ${this.isFeeAmountValid(fee) ? "" : "invalid"}`}
        type="number"
        min="1.00"
        max={fee.status == "Authorized" ? fee.amount : 99999.99}
        step="0.01"
        value={value}
        onChange={(e) => this.handleFeeAttributeChange(e, "amount")} />

    return <div>{asMoney(value)}</div>
  }

  renderDeleteButton(fee) {
    if (!this.isFeeDeletable(fee)) return null

    return <button className="delete-fee-row-btn btn-circle"
      onClick={(e) => this.handleDeleteRowBtnClick(e)}>&times;</button>
  }

  renderColumn(columnKey, columnElement, additionalClassNames=null) {
    let classNames = "flex-column"
    if (additionalClassNames) classNames += ` ${additionalClassNames}`

    return <div key={columnKey} className={classNames}>{columnElement}</div>
  }

  renderNewFeeRows() {
    let feeRowsList = []

    this.props.feesToAdd.forEach(function(fee, i) {
      const rowKey = `row-${i}`
      let feeColumns = [
        this.renderColumn(`${rowKey}-name`, this.renderNameElement(fee)),
        this.renderColumn(`${rowKey}-payee`, this.renderPayeeElement(fee)),
        this.renderColumn(`${rowKey}-dueUpon`, this.renderDueUponElement(fee)),
        this.renderColumn(`${rowKey}-glCode`, this.renderGLCodeElement(fee)),
        this.renderColumn(`${rowKey}-chargeCode`, this.renderChargeCodeElement(fee)),
        this.renderColumn(`${rowKey}-routingAccount`, this.renderRoutingAccountElement(fee)),
        this.renderColumn(`${rowKey}-amount`, this.renderAmountElement(fee)),
      ]

      if (this.isFeeDeletable(fee))
        feeColumns.push(this.renderColumn(`${rowKey}-delete`, this.renderDeleteButton(fee), "flex-1"))

      feeRowsList.push(<div key={rowKey} className="fee-row flex-container" data-row-type="add" data-row-index={i}>{feeColumns}</div>)
    }.bind(this))

    return feeRowsList
  }

  renderEditableFeeRows() {
    let feeRowsList = []

    for (let id in this.props.feesToEdit) {
      const fee = this.props.feesToEdit[id]
      let feeColumns = [
        this.renderColumn(`${id}-name`, this.renderNameElement(fee)),
        this.renderColumn(`${id}-payee`, this.renderPayeeElement(fee)),
        this.renderColumn(`${id}-dueUpon`, this.renderDueUponElement(fee)),
        this.renderColumn(`${id}-glCode`, this.renderGLCodeElement(fee)),
        this.renderColumn(`${id}-chargeCode`, this.renderChargeCodeElement(fee)),
        this.renderColumn(`${id}-routingAccount`, this.renderRoutingAccountElement(fee)),
        this.renderColumn(`${id}-amount`, this.renderAmountElement(fee)),
        this.renderColumn(`${id}-status`, <div className="fee-column-status">{fee.markedForDeletion ? "Marked to Delete" : fee.status}</div>),
        this.renderColumn(`${id}-delete`, this.renderDeleteButton(fee), "flex-1")
      ]

      let rowClasses = "fee-row flex-container"
      fee.markedForDeletion ? rowClasses += " marked-for-deletion" : null

      feeRowsList.push(<div key={id} className={rowClasses} data-row-type="edit" data-fee-id={id}>{feeColumns}</div>)
    }

    return feeRowsList
  }

  renderCollectableFeeRows() {
    let feeRowsList = []

    this.props.collectableFees.forEach(function(fee, _) {
      let feeColumns = [
        this.renderColumn(`${fee.id}-name`, this.renderNameElement(fee)),
        this.renderColumn(`${fee.id}-payee`, this.renderPayeeElement(fee)),
        this.renderColumn(`${fee.id}-glCode`, this.renderGLCodeElement(fee)),
        this.renderColumn(`${fee.id}-chargeCode`, this.renderChargeCodeElement(fee)),
        this.renderColumn(`${fee.id}-amount`, this.renderAmountElement(fee)),
        this.renderColumn(`${fee.id}-status`, <div className="fee-column-status">{fee.status}</div>),
      ]

      feeRowsList.push(<div key={fee.id} className="fee-row flex-container">{feeColumns}</div>)
    }.bind(this))

    return feeRowsList
  }

  feeNameOptionsList(prependKey="") {
    prependKey += "-fee-name"
    let list = []

    list.push(
      <option key={`${prependKey}-option-default`}
        disabled
        value="">Select Fee Type</option>)

    for (let dueAt in this.state.availableFeeNamesByDueAt) {
      const names = this.state.availableFeeNamesByDueAt[dueAt]

      names.forEach(function(name, i) {
        list.push(
          <option key={`${prependKey}-option-${dueAt}-${i}`}
            data-due-at={dueAt}
            value={name}>{name}</option>)
      })
    }

    list.push(
      <option key={`${prependKey}-custom-option`}
        data-due-at="custom"
        value="custom">&gt;Enter Your Own</option>)

    return list
  }

  payeeOptionsList(prependKey) {
    prependKey += "-payee"
    let list = []

    list.push(<option key={`${prependKey}-option-default`} disabled value="">{this.props.isAlteration ? "Select Resident Team" : "Select Deal Party"}</option>)

    for (let id in this.state.availableAssignees) {
      const payee = this.state.availableAssignees[id]
      list.push(
        <option key={`${prependKey}-option-${id}`} value={payee.package_user_id}>{payee.full_name}</option>)
    }

    return list
  }

  dueUponList(prependKey) {
    const defaultDueAtNames = ["Submission", "Move Out/Move In", "Upon Approval", "Pre-Closing", "Closing"]
    let list = []
    prependKey += "-dueUpon"

    list.push(<option key={`${prependKey}-option-default`} disabled value="" disabled>Select Time Due</option>)

    defaultDueAtNames.forEach(function(dueAtName, i) {
      list.push(<option key={`${prependKey}-option-${i}`} value={dueAtName}>{dueAtName}</option>)
    })

    return list
  }

  routingAccountList(prependKey) {
    let list = []
    prependKey += "-routingAccount"

    list.push(
      <option key={`${prependKey}-option-disabled`}
        disabled
        value="">Select Account</option>)

    for (let id in this.state.routingAccounts) {
      const accountLabel = this.state.routingAccounts[id]
      list.push(
        <option key={`${prependKey}-option-${id}`}
          value={id}>{accountLabel}</option>)}

    return list
  }

  renderRows() {
    switch(this.props.modalPurpose) {
    case 'add':
      return this.renderNewFeeRows()
    case 'edit':
      return this.renderEditableFeeRows()
    case 'collect':
      return this.renderCollectableFeeRows()
    }

    return null
  }

  renderTableBody() {
    if (this.state.loading)
      return this.renderLoading()

    return <div>{this.renderRows()}</div>
  }

  renderLoading() {
    // todo
  }

  render() {
    return (
      <div className="modal-table">
        {this.renderTableHeader()}
        {this.renderTableBody()}
      </div>
    )
  }
}

FeesTable.propTypes = {
  packageHashedId: PropTypes.string.isRequired,
  modalPurpose: PropTypes.string.isRequired,
  confirming: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  feesOnTable: PropTypes.array,
  feesToAdd: PropTypes.array,
  feesToEdit: PropTypes.object,
  collectableFees: PropTypes.array
}

export default FeesTable
