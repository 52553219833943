import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useWatchableRef } from 'lib/hooks'

export default function NewStructureModal(props) {
  const { title, submitText, open, onClose, onSubmit, structure, submitting, errorMessages, building } = props
  const modalRef = useWatchableRef(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      building_address: '',
      city: building.city,
      state: building.state,
      zip: building.zip,
      building_name: '',
      number_of_units: null,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (!structure) return

    reset({
      building_address: structure.default_additional_address.address,
      city: building.city,
      state: building.state,
      zip: building.zip,
      building_name: structure.name,
      number_of_units: structure.number_of_units,
    })
  }, [structure])

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current

    const close = (_e) => {
      onClose()
      reset()
    }

    $(localRefValue).on('hidden.bs.modal', close)

    return () => $(localRefValue).off('hidden.bs.modal', close)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  const onFormSubmit = (data, _e) => {
    onSubmit(data)
  }

  const onError = (errors, e) => console.log(errors, e)

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div
      ref={modalRef.ref}
      className="modal dome-modal dome-modal-centered new-structure-modal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit(onFormSubmit, onError)}>
            <div className="modal-header">
              <h3 className="modal-title">{title}</h3>
            </div>
            <div className="modal-body">
              {errorMessages.map((errorMessage, index) => (
                <p key={index} style={{ color: 'red' }}>
                  {errorMessage}
                </p>
              ))}
              <div>
                <div>
                  <label htmlFor="npm-street" className="form-label">
                    <span>street</span>
                    <span style={{ color: 'rgb(246, 138, 65)' }}>*</span>
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    id="npm-street"
                    {...register('building_address', { required: true })}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                <div style={{ flexGrow: '1' }}>
                  <div>
                    <label htmlFor="npm-city" className="form-label">
                      <span>city</span>
                      <span style={{ color: 'rgb(246, 138, 65)' }}>*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="npm-city"
                      {...register('city', { disabled: true })}
                    />
                  </div>
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div>
                    <label htmlFor="npm-state" className="form-label">
                      <span>state</span>
                      <span style={{ color: 'rgb(246, 138, 65)' }}>*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="npm-state"
                      {...register('state', { disabled: true })}
                    />
                  </div>
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div>
                    <label htmlFor="npm-zip" className="form-label">
                      <span>zip</span>
                      <span style={{ color: 'rgb(246, 138, 65)' }}>*</span>
                    </label>
                  </div>
                  <div>
                    <input type="text" className="form-control" id="npm-zip" {...register('zip', { disabled: true })} />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                <div style={{ flexGrow: '1' }}>
                  <div>
                    <label htmlFor="npm-name" className="form-label">
                      <span>building name</span>
                      <span style={{ color: 'rgb(246, 138, 65)' }}>*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="npm-name"
                      {...register('building_name', { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', columnGap: '20px' }}>
                <div style={{ flexGrow: '1' }}>
                  <div>
                    <label htmlFor="npm-number-of-units" className="form-label">
                      <span>building units</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="npm-number-of-units"
                      {...register('number_of_units')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel"
                data-dismiss="modal"
                onClick={onClose}
                style={{ float: 'left' }}
                type="button"
              >
                cancel
              </button>
              <button
                type="submit"
                className={`dome-btn dome-btn-base dome-btn-go-green ${
                  (!isValid || submitting) && 'dome-btn-disabled'
                }`}
                disabled={!isValid || submitting}
              >
                {submitText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

NewStructureModal.defaultProps = {
  title: 'new address',
  submitText: 'add',
}

NewStructureModal.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.object,
}
