import React from 'react'
import { pickBy } from 'lodash'

const isEmpty = val => !val || String(val).match(/^\s*$/)
const presence = val => isEmpty(val) ? null : val

export default function ({ field: { placeholder, system_only }, id, onChange, value, onBlur, className, disabled, inputClassName }) {
  const handleChange = e => onChange({[id]: e.target.value})

  const props = {
    value: value || "",
    disabled,
    placeholder,
    type: "text",
    onChange: handleChange,
    onBlur,
    className: inputClassName
  }

  return <div className={className}>
    { system_only ? value || "" : <input {...props} /> }
  </div>
}
