import React from 'react'

class AutoCalculationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  static defaultProps = {
    fieldData: {
      operator: 'sum',
      terms: []
    }
  }

  componentDidMount() {
    // Set form onclick handlers to set selected states
    var form_container = document.getElementById('form-container');
    var inputFields = form_container.getElementsByTagName('input');
    for (let i = 0; i < inputFields.length; i++) {
      inputFields[i].addEventListener('click', function(e) {
        const name = e.currentTarget.name;
        if(this.props.selectingTerms) {
          let selectedTerms = this.props.fieldData.terms
          let termsIndex = selectedTerms.indexOf(name);
          let newField = this.props.fieldData;
          termsIndex > -1 ? selectedTerms.splice(termsIndex, 1) : selectedTerms.push(name)
          newField.terms = selectedTerms;
          this.props.handleUpdateField(newField);
        } else {
          if (!this.props.selectingFieldElement) return;
          this.props.handleUpdateSelectedFieldName(name)
        }
      }.bind(this))
    }
  }

  render () {
    const { formHtml, formCss, selectingFieldElement } = this.props;
    const fullHTML = formCss + formHtml

    console.log(fullHTML)
    return (
      <div className='col-md-6' id='form-container' dangerouslySetInnerHTML={{__html: fullHTML}}>
      </div>
    );
  }
}

export default AutoCalculationForm
