import React from 'react'
import { pickBy } from 'lodash'

const isEmpty = val => !val || String(val).match(/^\s*$/)
const presence = val => isEmpty(val) ? null : val

export default function({field: {system_only}, value, className}) {

  return <div className={className}>
    <div className="static-value">{value || ""}</div>
  </div>
}