import { renderTemplate, pathMapToHash } from "lib/utilities/form"
import { updateSimilarPerson } from './people'

function wasUserFullyRemoved(state, email) {
  if (!state.user_roles[email])
    return true 

  if (state.user_roles[email].length > 0)
    return false

  if (state.user_data_people[email])
    return false

  return true
}

export function updateSimilarPeople(state, email, { first_name, last_name }) {
  if (state.editable_users[email]) {
    state.user_people[email] = state.editable_users[email] = { ... state.user_people[email], first_name, last_name }
    state.user_names[email] = userName(state, email)
    state.user_hash[email] = pathMapToHash(state.user_people[email])
  }

  for (let person_id of state.user_possible_people[email] || []) 
    updateSimilarPerson(state, person_id, { first_name, last_name})
}

function userName(state, email) {
  const user = state.user_people[email]

  return renderTemplate(['first_name', 'last_name'], { pathMap: user })
}

export function clearUsers(state) {
  state.user_people = {}
  state.user_updates = {}
  state.user_names = {}
  state.user_hash = {}
  state.user_roles = {}
}

export function initializeUsers(state, { editable_users, locked_users, editable_user_roles, locked_user_roles }) {
  clearUsers(state)
  
  state.editable_users = editable_users
  state.locked_users = locked_users

  state.editable_user_roles = editable_user_roles
  state.locked_user_toles = locked_user_roles
  
  state.user_people = { ...locked_users, ...editable_users }
  for (let email in state.user_people) {
    state.user_names[email] = userName(state, email)
    state.user_hash[email] = pathMapToHash(state.user_people[email])
    state.user_roles[email] = [ ...(editable_user_roles[email] || []), ...(locked_user_roles[email] || [])]
  }
}

export function makeUserEditable(state, { email }) {
  if (!state.locked_users[email])
    return false

  return addUser(state, { data: state.locked_users[email] })
}

export function addUser(state, {role_id, data = {}}) {
  const { email } = data

  state.user_people[email] = data
  state.editable_users[email] = data
  state.user_updates[email] = data
  state.user_names[email] = userName(state, email)
  state.user_hash[email] = pathMapToHash(data)
  updateSimilarPeople(state, email, data)
  addUserRole(state, { email, role_id })
  return true
}

export function editUser(state, { email, data }) {
  state.user_updates[email] = { 
    ...state.user_updates[email],
    ...data,
    email
  }

  state.editable_users[email] = state.user_people[email] = {
    ...state.user_people[email],
    ...state.user_updates[email]
  }

  state.user_names[email] = userName(state, email)
  state.user_hash[email] = pathMapToHash(state.user_people[email])
  
  updateSimilarPeople(state, email, data)
}

export function removeUser(state, { email }) {
  removeUserIfEmpty(state, email)
}

export function addUserRole(state, { email, role_id }) {
  state.user_roles[email] ||= []
  state.editable_user_roles[email] ||= []

  if (role_id) {
    state.user_roles[email].push(role_id)
    state.editable_user_roles[email].push(role_id)
  }
}

export function removeUserRole(state, { email, role_id}) {
  if (role_id) {
    state.editable_user_roles[email] = state.editable_user_roles[email].filter(id => id != role_id)
    state.user_roles[email] = [ ... state.editable_user_roles[email], ...(state.locked_user_roles[email] || []) ]
  }
}

export function removeUserIfEmpty(state, email) {
  if (!wasUserFullyRemoved(state, email))
    return

  delete state.user_roles[email]
  delete state.user_data_people[email]
  delete state.user_updates[email]
  delete state.user_people[email]
  delete state.editable_users[email]
  delete state.user_hash[email]

  state.to_remove.push({ email })
}