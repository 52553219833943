import { useEventListeners } from '@deathbyjer/react-event-manager'
import React from 'react'
import { useRef } from 'react'

const DocumentAreaWrapper = ({ children }) => {
  const documentAreaRef = useRef(null)
  useEventListeners({
    scrollToPage: (pageId) => {
      const curPage = document.getElementById(`page-${pageId}`)
      documentAreaRef.current.scrollTo({
        top: curPage.offsetTop,
        behavior: 'smooth',
      })
    },
    scrollToField: (pageId) => {
      const curPage = document.getElementById(`page-${pageId}`)
      const curElSelected = curPage.querySelector('.draw-area-selected')
      documentAreaRef.current.scrollTo({
        top: curPage.offsetTop + curElSelected?.offsetTop - 100,
        behavior: 'smooth',
      })
    },
  })

  return (
    <div ref={documentAreaRef} className="document-area-wrapper scrollable">
      <div className='document-area-wrapper-body'>
        {children}  
      </div>
    </div>
  )
}

export default DocumentAreaWrapper
