import React, { Component } from "react"
export default class ResetModal extends Component {
  constructor(props) {
    super(props)

    this.modal = null

    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    this.open()
  }

  componentWillUnmount() {
    this.close()
  }

  close() {
    $(this.modal).modal('hide')
  }

  open() {
    $(this.modal).modal('show')
  }

  renderResetPriceModal(){
    return (
      <div className="questionnaire-modal-container">
        <p>The price has been reset.
        The customer will be notified of the price change, after a new price has been selected.</p>
      
      <div className="questionnaire-modal-center">
        <button className="questionnaire-pricing-btn-submit" onClick={() => this.props.closeResetModal()} >OK</button>
      </div>
    </div>
    )
  }

  render(){
    return (
      <div className="modal" ref={this.setModalRef}>
        <div className="modal-dialog modal-md questionnaire-modal-dialog">
          <div className="modal-body">
            <h3 className="text-center bopa-text questionnaire-form-header">Price Reset</h3>
                {this.renderResetPriceModal()}
          </div>
        </div>
      </div>
    )
  }
};
