import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAsDropdown } from '../utility/react_helpers'
const TabContext = createContext(null)
const PageContext = createContext(null)

function getClassNames(activeTab, id) {
  return `partner-tab ${activeTab === id ? 'active' : ''}`
}

const TabsDesktop = ({ isDesktop }) => {
  if (!isDesktop) return

  const { activeTab, setActiveTab, tabSections } = useContext(TabContext)

  const tabList = tabSections.map(section => (
    <div
      key={section.id}
      className={getClassNames(activeTab, section.id)}
    >
      <span onClick={() => setActiveTab(section.id)}>{section.name}</span>
    </div>
  ))

  tabList.push(<div key="appended-line" className='partner-tab' style={{flexGrow: 1}}></div>)

  return tabList
}

const TabsMobile = ({ isDesktop }) => {
  if (isDesktop) return

  const { ref, opened, setOpened } = useAsDropdown({
    startOpened: false,
    frozen: false
  })
  const { activeTab, setActiveTab, tabSections } = useContext(TabContext)
  const activeTabObj = tabSections.find((tab) => tab.id === activeTab)
  const filteredTabSections = tabSections.filter((tab) => tab.id !== activeTabObj.id)
  const classes = ['active-tab-mbl', 'dome-h4', 'dome-text-w500', 'dome-d-flex', 'dome-align-center', 'dome-gap6']

  useEffect(() => setOpened(false), [activeTab])

  const toggleDropdown = useCallback(() => setOpened(!opened), [opened])

  return (
    <>
      <div
        ref={ref}
        className={classes.join(' ')}
        onClick={toggleDropdown}
      >
        {activeTabObj?.name} <i className='fal fa-angle-down' />
      </div>
      <div className={`partner-tabs-dropdown ${opened ? 'open' : ''}`}>
        {filteredTabSections.map(section => (
          <div
            key={section.id}
            className={getClassNames(activeTab, section.id)}
            onClick={() => setActiveTab(section.id)}
          >
            <span>{section.name}</span>
          </div>
        ))}
      </div>
    </>
  )
}

const Tabs = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 990)

  useEffect(() => {
    const isDesktop = () => setIsDesktop(window.innerWidth >= 990)

    window.addEventListener('resize', isDesktop)

    return () => window.removeEventListener('resize', isDesktop)
  }, [])

  return (
    <div className="tab-container dome-d-flex dome-relative">
      <TabsDesktop isDesktop={isDesktop} />
      <TabsMobile isDesktop={isDesktop} />
    </div>
  )
}

const Tile = ({ children }) => {
  return (
    <div className='logo-tile dome-relative'>
      {children}
    </div>
  )
}

const Logo = ({ partner }) => {
  return (
    <img className='partner-logo dome-full-width dome-absolute' src={partner.partner_page_logo.url} alt={partner.asset_slug} />
  )
}

const TilesList = () => {
  const { partners } = useContext(PageContext)
  const { activeTab } = useContext(TabContext)
  const renderedPartners = partners[activeTab] || []

  const tiles = renderedPartners.map(partner => {
    return (
      <Tile key={partner.id}>
        <a href={partner.partner_url}>
          <Logo partner={partner} />
        </a>
      </Tile>
    )
  })


  return <div className='tiles-list dome-d-flex dome-flex-wrap dome-align-center dome-justify-start'>{tiles}</div>
}

const Partners = ({children}) => {
  return (
    <div className="partners">
      <div className="centered-container dome-d-flex dome-flex-column">
        {children}
      </div>
    </div>
  )
}

function App({ partners, tabSections }) {
  const [activeTab, setActiveTab] = useState('property_management')

  return (
    <PageContext.Provider value={{partners}}>
      <TabContext.Provider value={{activeTab, setActiveTab, tabSections}}>
        <Partners>
          <Tabs />
          <TilesList />
        </Partners>
      </TabContext.Provider>
    </PageContext.Provider>
  )
}

export default App
