import React, { useState, useEffect } from 'react'

export default function DeleteModal(props) {
  const [currentFileType, setCurrentFileType] = useState('')
  const [currentFileTitle, setCurrentFileTitle] = useState('')

  useEffect(() => {
    displayModal()
  }, [])

  function displayModal() {
    setCurrentFileType(props.fileType)
    setCurrentFileTitle(props.title == "Favicon (.ico files only)" ?  "Favicon" : props.title)

    currentFileType !== undefined && props.showModal ? $("#delete-modal").modal("show") : ""
  }

  return (
    <div className="modal fade" data-backdrop="static" id="delete-modal" tabIndex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" onClick={() => props.setShowModal(false)} className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            <h4 className="modal-title" id="image-gallery-title">Confirm</h4>
          </div>
          <div className="modal-body text-center">
            <p>Are you sure you want to delete this {currentFileTitle} image?</p>
            <button className="btn btn-success" onClick={props.onRemove}>Yes</button>
            <button className="btn btn-secondary" data-dismiss="modal" onClick={() => props.setShowModal(false)}>No</button>
          </div>
        </div>
      </div>
    </div>
  )
}
