import React, { useEffect } from 'react'

import { useWatchableRef } from 'lib/hooks'
import { Portal } from 'components/utility/portal'

export default function ServerErrorModal({ open, onClose }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <Portal>
      <div ref={modalRef.ref} className="modal fade insurance-server-error-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content new-brp-modal">
            <div className="modal-header">
              <i className="fa-thin fa-triangle-exclamation dome-color-dark-green" style={{ fontSize: '30px' }} />
              <div className="dome-h2 dome-color-dark-green">oops!</div>
            </div>
            <div className="modal-body">
              <div className="dome-p2">
                The online quote is not available at this time, but our team can help get you covered.
                <div>
                  Please contact{' '}
                  <a className="dome-link dome-link-aqua" href="mailto:insurance-support@domecile.com">
                    insurance-support@domecile.com
                  </a>
                  .
                </div>
              </div>
              <button type="submit" className="dome-btn dome-btn-base dome-btn-aqua-stroke" onClick={onClose}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
