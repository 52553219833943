import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import InvoicePaymentModal from "./InvoicePaymentModal";
import PaymentCompletedModal from "./PaymentCompletedModal";
import { PAYMENT_STATUSES, cartPurchaseInvoice, selectInvoicePaymentState, setInitialState } from "./store/slice";

export function InvoicePayment(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialState(props));
  }, [dispatch]);

  const [invoicePaymentModalVisible, setInvoicePaymentModalVisible] = useState(false);
  const closePaymentModal = () => setInvoicePaymentModalVisible(false);
  const openPaymentModal = () => setInvoicePaymentModalVisible(true);

  const [paymentCompletedModalVisible, setPaymentCompletedModalVisible] = useState(false);
  const openPaymentCompletedModal = () => setPaymentCompletedModalVisible(true);
  const closePaymentCompletedModal = () => {
    setPaymentCompletedModalVisible(false);
    window.location.reload();
  };

  const { invoice, cards, paymentStatus, statementDescriptor } = useSelector(selectInvoicePaymentState);

  useEffect(() => {
    switch (paymentStatus) {
      case PAYMENT_STATUSES.init:
        break;
      case PAYMENT_STATUSES.processing:
        break;
      case PAYMENT_STATUSES.completed:
        closePaymentModal();
        openPaymentCompletedModal();
        break;
      case PAYMENT_STATUSES.failed:
        if (invoicePaymentModalVisible) window.alert("Failed to pay, retry or inform administrator");
      default:
    }
  }, [paymentStatus]);

  if (!invoice) return null;

  const submitPayment = ({ paymentMethodId, paymentToken }) => {
    dispatch(
      cartPurchaseInvoice({
        request_token: invoice.request_token,
        payment_method_id: paymentMethodId,
        payment_token: paymentToken,
      })
    );
  };

  return (
    <>
      <div className="cart-page__buttons">
        <button
          className="btn btn-custom-submit"
          disabled={paymentStatus === PAYMENT_STATUSES.completed}
          onClick={openPaymentModal}
        >
          Complete Payment
        </button>
      </div>
      <InvoicePaymentModal
        cards={cards}
        open={invoicePaymentModalVisible}
        onClose={closePaymentModal}
        onSubmit={submitPayment}
        total={asMoney(invoice.total / 100)}
        paymentStatus={paymentStatus}
      />
      <PaymentCompletedModal
        open={paymentCompletedModalVisible}
        onClose={closePaymentCompletedModal}
        statementDescriptor={statementDescriptor}
      />
    </>
  );
}

InvoicePayment.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    request_token: PropTypes.string.isRequired,
    building_id: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      last_4: PropTypes.string.isRequired,
      expires: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    })
  ),
};
