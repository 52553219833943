import React from 'react'

class Panel extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
    this.state.open = !!this.props.open
  }

  renderHeading() {
    const indicator_classes = ["indicator", "glyphicon", `glyphicon-menu-${this.state.open ?'down' : 'right'}`]
    const indicator_styles = { paddingRight: "4px" }

    return <div className="panel-heading">
      <h4 className="panel-title" role="button" onClick={e => this.setState({open: !this.state.open})}>
        <i className={indicator_classes.join(" ")} style={indicator_styles} />
        {this.props.title}
      </h4>
    </div>
  }

  renderBody() {
    const classes = ["panel-collapse", "collapse", "react-collapsible-body"]
    if (this.state.open)
      classes.push("in")

    return <div className={classes.join(" ")} role="tabpanel">
      {this.props.children}
    </div>
  }

  render() {
    const panel_classes = ["panel", `panel-${this.props.panel_variant || "default"}`]

    return <div className={panel_classes.join(" ")}>
      {this.renderHeading()}
      {this.renderBody()}
    </div>
  }
}

export default Panel