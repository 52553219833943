import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'components/utility/date_picker'

const RANGES = [
  "is in the last",
  "is equal to",
  "is between",
  "is after",
  "is on or after",
  "is before",
  "is before or on",
  "is empty"
]
const TIME_PERIODS = ["days", "weeks", "months"]
const TYPES = ["Deposit", "Capture"]

export default class DateFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      range: "is in the last",
      timePeriod: "days"
    }

    this.handleChangeRange = this.handleChangeRange.bind(this)
  }

  componentDidMount() {
    this.setInitialStateFromProps()
  }

  setInitialStateFromProps() {
    const { date } = this.props
    const initialState = this.state

    if ($.isEmptyObject(date))
      return

    switch(date["range"]) {
      case "isInTheLast":
        initialState["range"] = "is in the last"
        break;
      case "isEqualTo":
        initialState["range"] = "is equal to"
        break;
      case "isBetween":
        initialState["range"] = "is between"
        break;
      case "isAfter":
        initialState["range"] = "is after"
        break;
      case "isOnOrAfter":
        initialState["range"] = "is on or after"
        break;
      case "isBefore":
        initialState["range"] = "is before"
        break;
      case "isBeforeOrOn":
        initialState["range"] = "is before or on"
        break;
      case "isEmpty":
        initialState["range"] = "is empty"
        break;
    }

    if (date["timePeriod"]) initialState["timePeriod"] = date["timePeriod"]
    if (date["dateType"]) initialState["type"] = date["dateType"]

    this.setState(initialState)
  }

  handleChangeRange(range) {
    if (!RANGES.includes(range))
      return renderErrorSwal("Range is invalid")

    this.setState({ range: range })
  }

  handleChangeTimePeriod(timePeriod) {
    if (!TIME_PERIODS.includes(timePeriod))
      return renderErrorSwal("Selected time period is invalid")

    this.setState({ timePeriod: timePeriod })
  }

  needToRenderTimePeriodSelect() {
    return this.state.range == "is in the last"
  }

  shouldShowArrowIcon() {
    if (this.props.hideIcon) return false
    if (this.state.range == "is empty") return false

    return true
  }

  renderLabelForType(type) {
    let labelText = ""

    if (type == "Deposit") labelText = "Deposited"
    if (type == "Capture") labelText = "Collected"

    return <label htmlFor={`radio-${type}`}>{labelText}</label>
  }

  renderTypeRadio() {
    return <div className="type-container">
      {TYPES.map((type, index) =>
        <span key={type} className="type-option input-label-container" id={`date-radio-${type}`}>
          <input type="radio"
            required
            id={`radio-${type}`}
            key={`radio-${type}`}
            className="radio-option"
            name={`filters[date][dateType]`}
            value={type}
            defaultChecked={this.props.date["dateType"] == type || (!this.props.date["dateType"] && index == 0)} />
          {this.renderLabelForType(type)}
        </span>
      )}
    </div>
  }

  renderTimePeriodSelect() {
    return <select name={`filters[date][timePeriod]`} key={`filters[date][timePeriod]`}
      onChange={e => this.handleChangeTimePeriod(e.target.value)}
      value={this.state.timePeriod}>
      {TIME_PERIODS.map(period => <option key={period} value={period}>{period}</option>)}
    </select>
  }

  renderDaysInput() {
    return <input type="number"
      min="1"
      name={`filters[date][timePeriodValue]`}
      key={`filters[date][timePeriodValue]`}
      defaultValue={this.props.date["timePeriodValue"]}
      style={{"width": 50}}
      required />
  }

  renderRangeSelect() {
    const rangesList = RANGES.map(range => <option key={range} value={range}>{range}</option>)

    return <select className="range-select"
      onChange={e => this.handleChangeRange(e.target.value)}
      key='range-select'
      value={this.state.range}>
        {rangesList}
    </select>
  }

  renderFilter() {
    const inputs = []

    switch(this.state.range) {
      case "is between":
        inputs.push(this.renderDateInput("start", this.props.date["start"]))
        inputs.push(this.renderDateInput("end", this.props.date["end"]))
        break
      case "is equal to":
        inputs.push(this.renderDateInput("isEqualTo", this.props.date["isEqualTo"]))
        break
      case "is after":
      case "is on or after":
        inputs.push(this.renderDateInput("start", this.props.date["start"]))
        break
      case "is before":
        inputs.push(this.renderDateInput("end", this.props.date["end"]))
        break
      case "is before or on":
        inputs.push(this.renderDateInput("end", this.props.date["end"]))
        break
      case "is in the last":
        inputs.push(this.renderDaysInput())
        inputs.push(this.renderTimePeriodSelect())
        break
      case "is empty":
        break
      default:
        return null
    }

    return <div className="inputs-container">
      { this.shouldShowArrowIcon() ? <i className="fa fa-long-arrow-right"></i> : null }
      {inputs}
    </div>
  }

  renderDateInput(name, date) {
    date = date || ""

    return <DatePicker
      required={true}
      name={`filters[date][${name}]`}
      key={`filters[date][${name}]`}
      allowManualInput={false}
      date={date} />
  }

  renderDateTypeHiddenInput() {
    return <div>
      <input type="hidden" name="filters[date][range]" value={toCamelCase(this.state.range)} />
    </div>
  }

  render() {
    return <div className="date-filter">
      {this.renderTypeRadio()}
      {this.renderRangeSelect()}
      {this.renderFilter()}
      {this.renderDateTypeHiddenInput()}
    </div>
  }
}

DateFilter.propTypes = {
  date: PropTypes.object,
  hideIcon: PropTypes.bool
}

DateFilter.defaultProps = {
  date: {},
  hideIcon: false
}
