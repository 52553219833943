import React, { Component } from "react"
import ReactTooltip from 'react-tooltip'
import EditModal from '../Response/EditModal'
import UploadArea from 'components/utility/upload_area'

export default class FulfillQuestionnaireRequest extends Component {
  constructor(props) {
    super(props)

    const uploadedFile = {}
    for (let form of props.completed_forms || [])
      uploadedFile[form.id] = form

    this.state = {
      uploadedFile,
      showUploadModal: false,
      showEditModal: false,
      editModalName: ''
    }
  }

  isDisabled() {
    return this.props.questionnaire_request.needs_payment
  }

  hasUploadedFiles() {
    return Object.keys(this.state.uploadedFile).length > 0
  }


  disableHover(){
    const view = this.props.questionnaire_request.is_cancelled

    if (view)
      return (<span></span>)

    if (this.isDisabled()) {
      return (
        <span>
          <a data-tip='Field will become active once payment has been made.'><i style={{lineHeight: "0.10em"}} className="fas fa-question-circle fa-lg"></i></a>
            <ReactTooltip className='extraClass' effect='solid'/>
        </span>
      )
    }
  }

  updateAttribute(name, value) {
    const state = {}
    state[name] = value
    this.setState(state)
  }

  updateFileName(fileId, name) {
    const hash = this.props.questionnaire_request.hashed_id

    const url = `/questionnaire_requests/${hash}/update_questionnaire_request_completed_form_name/${fileId}`
    $.ajax({
      url: url,
      data: { name: name },
      method: 'patch',
      dataType: 'json',
      context: this,
      success: () => {
        const uploadedFile = {...this.state.uploadedFile}
        uploadedFile[fileId].name = name
        this.setState({uploadedFile})
      }
    })
  }

  updateUploadedFiles(update) {
    const uploadedFile = {...this.state.uploadedFile}

    update(uploadedFile)

    this.setState({uploadedFile})
    this.props.onCompletedFormsUpdate(Object.keys(uploadedFile).length)
  }

  addUploadedFiles(files) {
    this.updateUploadedFiles( uploadedFile => {
      for (let id in files)
        uploadedFile[id] = files[id]
    })
  }

  removeUploadedFile(id) {
    this.updateUploadedFiles( uploadedFile => delete uploadedFile[id] )
  }

  uploadFiles(files) {
    const hash = this.props.questionnaire_request.hashed_id
    const url = `/questionnaire_requests/${hash}/upload_questionnaire_pdf`
    const data = new FormData()

    let uploadedFile = this.state.uploadedFile

    for (let i = 0; i < files.length; i++) {
      data.append("files[]", files[i].file)
      data.append($("meta[name=csrf-param]").attr("content"), $("meta[name=csrf-token]").attr("content"))
    }

    const success = ({files}) => this.addUploadedFiles(files)

    $.ajax({ url, type: "post", data, contentType: false, processData: false, success })
  }

  deleteUploadFiles(id){
    const hash = this.props.questionnaire_request.hashed_id
    const url = `/questionnaire_requests/${hash}/delete_questionnaire_pdf/${id}`

    const success = () => this.removeUploadedFile(id)
    $.ajax({ url, type: "delete", success})
  }

  addedFiles(files) {
    {this.uploadFiles(files)}
  }

  toggleEditModal(){
    this.setState({showEditModal: !this.state.showEditModal})
  }

  setFileBeingEdited(file) {
    this.setState({fileBeingEdited: file})
  }

  editFileName(id, name){
    this.toggleEditModal()
    this.setFileBeingEdited(this.state.uploadedFile[id])
  }

  renderEditFileName(){
    if (this.state.showEditModal) {
      return <EditModal name={this.state.editModalName} closeEditModal={() => this.toggleEditModal()} file={this.state.fileBeingEdited} updateFileName={(fileId, name) => this.updateFileName(fileId, name)} />
    }
  }

  renderFileName(){
    if (Object.keys(this.state.uploadedFile).length === 0)
      return ""

    const name = Object.entries(this.state.uploadedFile).map(([id, file]) => this.renderUploadedFile(id, file))

    return(<div>{name}</div>)
  }

  renderUploadedFile(id, {name, upload_file}) {
    const buttonIcon = { fontSize: '16px', display: 'inline-block', marginLeft: '15px', border: 'none', background: 'transparent', textDecoration: 'none'}
    const url = upload_file.url
    const view = this.props.questionnaire_request.is_archived

    if (view) {
      return <div className="uploaded-file" key={id}>
      <div className="file-name" >
        {name}
      </div>
      <a href={url} target="_blank" rel="noopener noreferrer" ><button className="fas fa-search" style={buttonIcon} ></button></a>
    </div>
    }

    return <div className="uploaded-file" key={id}>
      <div className="file-name" >
        {name}
      </div>
      {/* <a href={url} download ><button className="fas fa-download" style={buttonIcon} ></button></a> */}
      <a href={url} target="_blank" rel="noopener noreferrer" ><button className="fas fa-search" style={buttonIcon} ></button></a>
      <button className="fa fa-pencil" style={buttonIcon}  onClick={() => this.editFileName(id, name)} ></button>
      <button className="fa fa-trash" style={buttonIcon} onClick={() => this.deleteUploadFiles(id)}></button>
    </div>
}

  renderNoFiles() {
    return <div className="well-sm">
      There are no documents attached to this questionnaire request.
    </div>
  }

  renderFileUpload() {
    const fileUploaded = this.hasUploadedFiles() ? this.renderFileName() : this.renderNoFiles()
    const view = this.props.questionnaire_request.is_archived
    const uploadAreaText = "Drag and drop completed questionnaire files into this box or click to select."

    if (view) { return (
      <div className="list" > <br/>
        <h4>Attached Document(s):</h4>
        <div>{fileUploaded}</div>
      </div>
      )
    }

    if (this.isDisabled())
      return <div className="text-center well" style={{marginTop: '20px'}}>
        Pricing must be set and the invoice must be paid before fulfillment can begin.
      </div>

    return (
      <div>
        <div className="file-upload" >
          <UploadArea inputChanged={files => this.addedFiles(files)} exclude_icons={true}  key={key} text={uploadAreaText}/>
        </div>
        <div className="list" > 
          <br/>
          <h4>Attached Document(s):</h4>
          <div>{fileUploaded}</div>
        </div>
      </div>
    )
  }

  render(){
    return(
      <div >
        <div className="questionnaire-options-container"  >
          <span className="building-header">{this.props.stepNumber || 3}. Fulfill</span> <span> {this.disableHover()} </span>
          <br/>
          <span>{this.renderFileUpload()}</span>
          {this.renderEditFileName()}
        </div>
      </div>
    )
  }
};

