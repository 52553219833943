import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from '@reduxjs/toolkit'
import clsx from 'clsx'

const Input = forwardRef(({ id, label, wrapperClasses, errorClasses, type, formError, ...restProps }, ref) => {
  const inputId = id || nanoid()
  const inputLabel = label || ''
  const inputType = type || 'text'
  const inputError = formError || null

  return (
    <div className={clsx(wrapperClasses)}>
      <label htmlFor={inputId}>{inputLabel}</label>
      <input ref={ref} type={inputType} {...restProps} id={inputId} />
      {inputError && <div className={clsx('dome-p3 dome-color-red-alert', errorClasses)}>{inputError?.message}</div>}
    </div>
  )
})

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  wrapperClasses: PropTypes.arrayOf(PropTypes.string),
  formError: PropTypes.object,
}

export default Input
