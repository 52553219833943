import React, { useState } from 'react'
import ResourceSectionGroup from '.'

export default function ResourceSectionOverlay(props) {
  const {
    package: { building, hashed_id: package_id },
    ...resourceSectionProps
  } = props
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const showToolTip = () => setIsShowTooltip(true)
  const closeToolTip = () => setIsShowTooltip(false)

  return (
    <div className="dome-relative">
      <div onMouseMove={showToolTip} className="dome-d-flex dome-align-center dome-gap6 dome-color-green-gradient">
        <i className="fa-regular fa-toolbox"></i>
        <span className="dome-p1 dome-text-w500">resources</span>
      </div>
      {isShowTooltip && (
        <ResourceSectionGroup
          onClose={closeToolTip}
          property={building}
          packageId={package_id}
          {...resourceSectionProps}
          className="resource-section-tooltip"
        />
      )}
    </div>
  )
}
