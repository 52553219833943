import React from 'react'

import buildingCheckDkgreen from '/app/assets/images/insurance/icons/building-check-dkgreen.svg'
import handleshakeDkgreen from '/app/assets/images/insurance/icons/handshake-dkgreen.svg'
import umbrella from '/app/assets/images/insurance/icons/umbrella.svg'
import userShieldDkgreen from '/app/assets/images/insurance/icons/user-shield-dkgreen.svg'
import wandMagicSparklesDkgreen from '/app/assets/images/insurance/icons/wand-magic-sparkles-dkgreen.svg'
import chubb from '/app/assets/images/insurance/logos/chubb.png'
import goGreenMark from '/app/assets/images/insurance/icons/go-green-mark.svg'

export default function PropertySidebar() {
  return (
    <>
      {' '}
      <div className="sidebar-desktop">
        <div className="what-we-know-sidebar-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="dome-h2 dome-color-dark-green">instance quote.</div>
          <div className="dome-h2 dome-color-dark-green">effortless coverage.</div>
          <div className="what-we-know-list">
            <div className="what-we-know-item">
              <div className="icon-wrapper">
                <img src={buildingCheckDkgreen} />
              </div>
              <div className="item-description">
                <div className="dome-p2 dome-text-w500">meet your property’s requirements</div>
                <div className="dome-p2-lt">We track accurate, up-to-date property info so you don't have to.</div>
              </div>
            </div>
            <div className="what-we-know-item">
              <div className="icon-wrapper">
                <img src={userShieldDkgreen} />
              </div>
              <div className="item-description">
                <div className="dome-p2 dome-text-w500">limited personal information required</div>
                <div className="dome-p2-lt">We need only your name + email after you see a quote.</div>
              </div>
            </div>
            <div className="what-we-know-item">
              <div className="icon-wrapper">
                <img src={handleshakeDkgreen} />
              </div>
              <div className="item-description">
                <div className="dome-p2 dome-text-w500">premium insurer</div>
                <div className="dome-p2-lt">
                  All quotes + policies are provided by
                  <span>
                    {' '}
                    <img src={chubb} style={{ width: '88.945px' }} />
                  </span>
                </div>
              </div>
            </div>
            <div className="what-we-know-item">
              <div className="icon-wrapper">
                <img src={umbrella} />
              </div>
              <div className="item-description">
                <div className="dome-p2 dome-text-w500">“all in” coverage</div>
                <div className="dome-p2-lt">The Masterpiece plan covers everything from the “walls in.”</div>
              </div>
            </div>
            <div className="what-we-know-item">
              <div className="icon-wrapper">
                <img src={wandMagicSparklesDkgreen} />
              </div>
              <div className="item-description">
                <div className="dome-p2 dome-text-w500">instant delivery</div>
                <div className="dome-p2-lt">We immediately attach the policy to your BoardPackager application.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-mobile">
        <div className="what-we-know-sidebar-mobile">
          <div className="what-we-know-headline dome-color-dark-green">
            <span>instance quote.</span>
            <span>effortless coverage.</span>
          </div>
          <div className="what-we-know-list">
            <div className="what-we-know-item">
              <img src={goGreenMark} />
              <span>instantly meet property requirements</span>
            </div>
            <div className="what-we-know-item">
              <img src={goGreenMark} />
              <span>no personal info needed for estimate</span>
            </div>
            <div className="what-we-know-item">
              <img src={goGreenMark} />
              <span>auto attach policy to application</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
