import axios from "lib/axios";

export const updateBuildingResidentPaymentsApi = async (data, buildingId) => {
  return await axios.patch(`/buildings/${buildingId}/resident_payments`, data);
};

export const removeServiceApi = async ({ serviceId }) => {
  return await axios.delete(`/resident_payments/services/${serviceId}`);
};

export const removeCategoryApi = async ({ categoryId }) => {
  return await axios.delete(`/resident_payments/categories/${categoryId}`);
};
