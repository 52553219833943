import React, { useCallback, useEffect, useRef } from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "lodash-es";
import {
  addService,
  updateCategory,
  updateService,
  openRemoveServiceModal,
  moveCategory,
  openRemoveCategoryModal,
  selectCategoryFormState,
  selectBuildingEditResidentPayments,
} from "../store/slice";
import BuildingServiceItem from "./BuildingServiceItem";

const CategoryMenu = ({
  isDragging,
  handleMoveCategory,
  handleRemoveCategory,
  canRemoveCategory,
  dragHandleProps,
  isFirst,
  isLast,
}) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (!canRemoveCategory && tooltipRef.current) {
      $(tooltipRef.current).tooltip();
    }
  }, [canRemoveCategory]);

  const classes = ["dropdown"];

  if (!isDragging) classes.push("dropdown-hover");

  return (
    <span className={classes.join(" ")}>
      <span {...dragHandleProps} data-toggle="dropdown">
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </span>
      <ul className="dropdown-menu" style={{ zIndex: 99999 }}>
        {!isFirst && <li onClick={() => handleMoveCategory("top")}>Move to top</li>}
        {!isLast && <li onClick={() => handleMoveCategory("bottom")}>Move to bottom</li>}
        {!isFirst && <li onClick={() => handleMoveCategory("oneUp")}>Move up one</li>}
        {!isLast && <li onClick={() => handleMoveCategory("oneDown")}>Move down one</li>}
        <li>
          <button onClick={handleRemoveCategory} disabled={!canRemoveCategory}>
            Delete category&nbsp;
            {!canRemoveCategory && (
              <i
                className="fa-light fa-circle-question"
                ref={tooltipRef}
                data-placement="top"
                data-original-title="All pending changes must be saved before removing this category."
              ></i>
            )}
          </button>
        </li>
      </ul>
    </span>
  );
};

export default function BuildingServiceCategory({
  category,
  index,
  sub_merchant_dropdown,
  services,
  dragProvided,
  isLast,
  isDragging,
}) {
  const dispatch = useDispatch();

  const form = useSelector((state) => selectCategoryFormState(state, category.id));
  const { formTouched } = useSelector(selectBuildingEditResidentPayments);

  // TODO: Handle in a better way
  const isNewCategory = isString(category.id);
  const canRemoveCategory = !formTouched || (isNewCategory && services.length === 0);

  const handleServiceChange = useCallback(
    (serviceId, key, value) => {
      dispatch(updateService({ serviceId, key, value }));
    },
    [dispatch]
  );

  const handleServiceRemove = useCallback(
    (serviceId) => {
      dispatch(openRemoveServiceModal({ id: serviceId, categoryId: category.id }));
    },
    [dispatch]
  );

  const handleChangeCategory = (key, value) => {
    dispatch(updateCategory({ categoryId: category.id, key, value }));
  };

  const handleAddService = () => {
    dispatch(addService({ categoryId: category.id }));
  };

  const handleMoveCategory = (position) => {
    dispatch(moveCategory({ currentIndex: index, position }));
  };

  const handleRemoveCategory = () => {
    dispatch(openRemoveCategoryModal({ id: category.id }));
  };

  const isFirst = index === 0;

  const renderServiceItem = (service, dragProvided) => {
    return (
      <BuildingServiceItem
        dragProvided={dragProvided}
        service={service}
        sub_merchant_dropdown={sub_merchant_dropdown}
        onServiceChange={handleServiceChange}
        onServiceRemove={handleServiceRemove}
      />
    );
  };

  return (
    <div className="building-edit-category" {...dragProvided.draggableProps} ref={dragProvided.innerRef}>
      <div className="building-edit-category-index">{index + 1}</div>
      <div className="building-edit-category-wrapper">
        <div className="building-edit-category-title-wrapper">
          <div>
            <input
              type="text"
              value={category.title}
              placeholder="Category Name"
              onChange={(e) => handleChangeCategory("title", e.currentTarget.value)}
            />
            {form?.errors?.title?.includes("presence") && <div style={{ color: "red" }}>Title is required</div>}
          </div>
          <div>
            <div>
              <CategoryMenu
                isDragging={isDragging}
                handleMoveCategory={handleMoveCategory}
                handleRemoveCategory={handleRemoveCategory}
                canRemoveCategory={canRemoveCategory}
                dragHandleProps={dragProvided.dragHandleProps}
                isFirst={isFirst}
                isLast={isLast}
              />
            </div>
          </div>
        </div>
        <Droppable key={category.id} droppableId={`category-${category.id}`}>
          {(provided, _snapshot) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className="building-edit-services">
              {services.map((service, index) => (
                <Draggable key={service.id} draggableId={`service-${service.id}`} index={index}>
                  {(provided, _snapshot) => renderServiceItem(service, provided)}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        <div className="building-edit-actions">
          <span className="new-item" onClick={handleAddService}>
            + New Item
          </span>
        </div>
      </div>
    </div>
  );
}
