import axios from 'lib/axios'

export const fetchAccountsApi = async (buildingId) => {
  return await axios.get(
    `/buildings/${buildingId}/property_accounts`
  )
}

export const submitAccountChangesApi = async (
  buildingId,
  accountValues
) => {
  return await axios.post(
    `/buildings/${buildingId}/transfer_accounts`,
    {
      accounts: accountValues,
    }
  )
}
