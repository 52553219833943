import clsx from 'clsx'
import React from 'react'

export default function ContractorReportFormSection({
  children,
  title,
  onNext,
  onBack,
  showBackButton,
  showNextButton,
  onSubmit,
}) {
  return (
    <div className="dome-d-flex dome-flex-column dome-gap12">
      <div className="dome-h4 dome-color-green-gradient">vet your contractor</div>
      <div className="dome-p2 dome-text-w500 dome-color-dark-grey">{title}</div>
      <div className="dome-d-flex dome-flex-column dome-gap12">{children}</div>
      <div className={clsx('dome-d-flex ', showBackButton ? 'dome-justify-between' : 'dome-justify-end')}>
        {showBackButton && (
          <button
            type="button"
            className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
            onClick={onBack}
          >
            back
          </button>
        )}
        {showNextButton && (
          <button type="button" className="btn-base btn-green-stroke" onClick={onNext}>
            next
          </button>
        )}
        {onSubmit && (
          <button type="submit" className="btn-base btn-green-stroke">
            submit request
          </button>
        )}
      </div>
    </div>
  )
}
