import React from 'react'

function Row(props) {
  const { title, id } = props.requirement
  const collaborator = props.collaborator
  const onChange = () => props.toggleSelectedRequirement(collaborator.idx, id)

  // Find the updated policy by the id, but we only care about the visibility
  const { visibility: checked } = collaborator.updatedPolicy.find(p => p.requirementId == id)

  return <li style={{paddingTop: '5px'}}>
    <input type='checkbox' className='collaborator-hover' onChange={onChange} checked={checked} />
    <span style={{fontWeight: 'bold'}}> { props.index + 1}. {title}</span>
  </li>
}

export default Row

/*
class PolicyRow extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelected = this.handleSelected.bind(this)
    this.getPolicyVisibility = this.getPolicyVisibility.bind(this)
  }

  handleSelected() {
    this.props.toggleSelectedRequirement(this.props.collaborator.idx, this.props.requirement.id)
  }

  getPolicyVisibility (requirement) {
    return this.props.collaborator.updatedPolicy.find((policy) => {
      return policy.requirementId === this.props.requirement.id
    })
  }

  render () {
    let {title, id} = this.props.requirement
    let isChecked = this.getPolicyVisibility().visibility
    return (
      <li style={{paddingTop: '5px'}}>
        <input
          className='collaborator-hover'
          type='checkbox'
          onChange={this.handleSelected}
          checked={isChecked}
          >
        </input>
        <span style={{fontWeight: 'bold'}}> {this.props.index + 1}. {title}</span>
      </li>
    )
  }
  */
