import axios from 'lib/axios'

export const attachDocumentApi = async ({ package_hashed_id, requirement_id, data }) => {
  return await axios.post(`/package_templates/${package_hashed_id}/requirements/${requirement_id}/documents`, data)
}

export const attachFormApi = async ({ package_hashed_id, requirement_id, data }) => {
  return await axios.post(`/package_templates/${package_hashed_id}/requirements/${requirement_id}/forms`, data)
}

export const saveFormApi = async ({ package_template_id, formData }) => {
  return await axios.put(`/package_templates/${package_template_id}`, formData, {
    headers: {
      'Accept': 'application/json',
    },
  })
}
