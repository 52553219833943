import React from 'react'

const Form = (props) => {
  return (
    <form className="login-form" name="login" autoComplete="new-password" action={props.action} method={props.method} onSubmit={props.handleSubmit(props.submitFn)} noValidate>
      {props.children}
    </form>
  )
}

export default Form