import { translateValue } from "./utilities"
import { isDate, isString, isPlainObject, isNil, mapValues, omit} from 'lodash'
import { format, intervalToDuration, parse } from 'date-fns'
import { asMoney, compactHash, asArray } from "lib/utilities"
import { ensureValueIsConstant } from "./utilities"
import { metadataByPath } from "../form"

import { deserialize as deserializeDuration } from 'components/form_field/types/duration'

const isEmpty = val => isNil(val) || val === false || String(val).match(/^\s*$/)
const isntEmpty = val => !isEmpty(val)

const ROUND_TO = {
  "quarter": 4.0,
  "half": 2.0,
  "whole": 1.0
}
const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

/*
 * [ path, path ] == { func: "CONCAT", values: [ path, path ], delimiter: " "}
 * { func: "UPCASE". path: path }
 * { func: FUNCTION_NAME, values: [ path, path] }
 * { func: "CONCAT", values: [path, path], delimiter: " "}
 * { func: "MAP", map: "CAPITALIZE", values: [ path, path ..]}
 * { func: "FILTER", filter: "EXISTS", values: [ path, path ]}
 * { func: "MONEY", value: "path" }
 * { func: "YEAR", value: STRING | DATE }
 * { func: "YEAR_2", value: STRING | DATE}
 * { func: "MONTH", value: STRING | DATE }
 * { func: "DAY", value: STRING | DATE}
 * { func: "NOW" }
 */

function renderTemplate(template, request) {
  if (Array.isArray(template))
    template = { func: "CONCAT", values: template}

  if (typeof template != "object")
    return translateValue(template, request)

  if (isPlainObject(template.variables)) {
    request.metadata = { 
      ... request.metadata, 
      ... mapValues( template.variables, varTemplate => renderTemplate(varTemplate, request.metadata) )
    }
  }
  
  const method = String(template.func).toLowerCase()
  
  template = omit(template, ["variables", "func"])
  return ITERATORS[method] ? renderIterator(method, template, request) : renderSingle(method, template, request)
}

function renderSingle(method, template, request) {
  const values = asArray(template.values).map(path => renderTemplate(path, request)).flat()
  const value = template.value ? renderTemplate(template.value, request) : null

  const compiled = { ...template, values, value, request }

  return FUNCTIONS._call(method, compiled, request)
}


function renderIterator(method, template, request) {
  let values = iteratableValues(template)

  const compiled = omit({ ...template, values }, ['value'])
  return FUNCTIONS._call(method, compiled, request) || []
}

function iteratableValues(template) {
  if (template['values']) return template['values']

  const value = template['value']
  if (Array.isArray(value)) return value
  if (!isString(value)) return []

  const length = parseInt(translateValue(`${value}[length]`, request)) || 0
  return new Array(length).fill(null).map((_,i) => `{value}[{i}]`)
}

const ITERATORS = {
  map: true,
  merge: true,
  filter: true
}

const FUNCTIONS = {
  _call(method, options, request) {
    if(!FUNCTIONS[method])
      return ""

    switch(FUNCTIONS[method].length) {
      case 0: 
        return FUNCTIONS[method]()
      case 1:
        return FUNCTIONS[method](options)
      case 2:
        return FUNCTIONS[method](options, request)
    }
  },

  filter(options, request) {
    const { values, filter } = options
    filter = FUNCTIONS[filter] ? filter : "isPresent"

    return values.filter(value => FUNCTIONS[filter]({ ...options, value }))
  },

  map(options, request) {
    const { values, map, allow_empty } = options
    func = FUNCTIONS[map] ? map : "self"

    const mapped = values.filter(isntEmpty).map(value => renderTemplate({func, value}, request))
    return allow_empty ? mapped : mapped.filter(isntEmpty)
  },

  merge(options, request) {
    let values = options['values']
    if (!value) return []

    values = values.map(value => renderTemplate(value, request))
    return values.reduce((a,b) => a.concat(b), [])
  },

  concat({values, delimiter = " "}) {
    return values.join(delimiter)
  },

  upcase({value}) {
    return String(value).toUpperCase()
  },

  downcase({value}) {
    return String(value).toUpperCase()
  },

  capitalize({value}) {
    value = String(value)
    return value[0].toUpperCase() + value.substr(1)
  },

  money({value, currency = "$"}) {
    value = parseInt(value)
    return asMoney(value, { currency })
  },

  now(_) {
    return new Date()
  },

  year({value}) {
    return formatDate("yyyy", value)
  },

  year_2({value}) {
    return formatDate("yy", value)
  },

  month({value}) {
    return formatDate("M", value)
  },

  month_name({value}) {
    return formatDate("MMMM", value)
  },

  month_short_name({value}) {
    return formatDate("MMM", value)
  },

  date({value}) {
    return formatDate("d", value)
  },

  date_with_suffix({value}) {
    return formatDate("do", value)
  },

  day_of_week({value}) {
    return formatDate("EEEE", value)
  },

  short_date_of_week({value}) {
    return formatDate("EEE", value)
  },

  letter_of_week({value}) {
    return formatDate("EEEEE", value)
  },

  duration_between({values, inclusive}) {
    const start = generateDate(values[0])
    const end = generateDate(values[1])
    if (!start || !end)
      return { years: 0, months: 0, days: 0 }

    return durationBetween({ start, end, inclusive })
  },

  duration_years({value}) {
    return generateDuration(value).years
  },

  duration_months({value}) {
    return generateDuration(value).months
  },

  duration_days({value}) {
    return generateDuration(value).days
  },

  date_plus_duration({values}) {
    const date = generateDate(values[0])
    const duration = generateDuration(values[1])

    return new Date(
      date.getFullYear() + duration.years,
      date.getMonth() + duration.months,
      date.getDate() + duration.days,
      0,0,0
    )
  },

  date_minus_duration({values}) {
    const date = generateDate(values[0])
    const duration = generateDuration(values[1])

    return new Date(
      date.getFullYear() - duration.years,
      date.getMonth() - duration.months,
      date.getDate() - duration.days,
      0,0,0
    )
  },

  date_difference_years({values, inclusive}) {
    const start = generateDate(values[0])
    const end = generateDate(values[1])
    if (!start || !end)
      return ""

    return durationBetween({ start, end, inclusive }).years
  },

  date_difference_months({values, inclusive}) {
    const start = generateDate(values[0])
    const end = generateDate(values[1])
    if (!start || !end)
      return ""

    return durationBetween({ start, end, inclusive }).months
  },

  date_difference_days({values, inclusive}) {
    const start = generateDate(values[0])
    const end = generateDate(values[1])
    if (!start || !end)
      return ""

    return durationBetween({ start, end, inclusive }).days
  },


  rounded_duration({round_by, round_to, round_op, value}) {
    round_by = round_by || "months"
    round_to = ROUND_TO[round_to] || ROUND_TO["quarter"]
    round_op = round_op || "avg"

    const duration = generateDuration(value)

    let rounding = duration.days * 1.0 / 31
    if (round_by == 'years')
      rounding = (duration.months + rounding) / 12.0

    rounding = rounding * round_to;
    switch(round_op) {
      case 'floor':
        rounding = Math.floor(rounding)
        break
      case 'ceil':
        rounding = Math.ceil(rounding)
        break
      case 'avg':
        rounding = Math.round(rounding)
        break
    }

    console.log(rounding, round_op, round_by, round_to)
    
    if (parseInt(rounding) == rounding)
      rounding = parseInt(rounding)

    rounding /= round_to
    duration.days = 0
    duration.months = 0

    if (round_by == "months") {
      duration.months = rounding % 12

      if (rounding == 1)
        duration.years += 1 
    }
    
    if (round_by == "years")
      duration.years = rounding

    return duration
  },


  template({values, template}) {
    if (values.every(value => isEmpty(value)))
      return ""

    return values.reduce((current, value, index) => {
      const val = isEmpty(value) ? "" : String(value)
      return current.replace(`{{${index}}}`, val)
    }, template)
  },

  lookup_in_options({path, value, request: { metadata }}) {
    if (!metadata)
      return ""

    const { options } = metadataByPath(metadata, path) || {}
    if (!options)
      return ""

    return isPlainObject(options) ? options[value] || "" : ""
  },

  self: ({value}) => value,

  // BOOLEANS

  isPresent: ({value}) => value && !String(value).match(/^\s*$/),
}


export default renderTemplate
// ok
function generateDate(value) {
  if (!value) return null
  if (isDate(value)) return value
  if (!isString(value) || isEmpty(value)) return null

  const [date, time] = value.split(" ")
  const [year, month, day] = date.split("-").map(i => parseInt(i) || 0)
  const [hour, minute, second] = (time || "").split(";").map(i => parseInt(i) || 0)

  return new Date(year || 1, (month || 1) - 1, day || 1, hour || 0, minute || 0, second || 0)
}

function durationBetween({ start, end, inclusive }) {
  end = new Date(end.valueOf())
  if (inclusive)
    end.setDate(end.getDate() + 1)

  return intervalToDuration({ start, end })
}

function generateDuration(value) {
  if(isString(value))
    value = deserializeDuration(value)
  if (!isPlainObject(value))
    value = {}

  return { years: value.years || 0, months: value.months || 0, days: value.days || 0 }
}

function formatDate(template, value) {
  const date = generateDate(value)
  if (!date)
    return ""

  return format(date, template)
}