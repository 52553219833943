import React, { useState, useEffect, useRef, useCallback } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

import { isNil } from 'lodash'
import { useEventManager } from '@deathbyjer/react-event-manager'


function firstLoad() {
  document.body.classList.add("interstitial-activated")
  return () => document.body.classList.remove('interstitial-activated')
}

// Rebuild
const Interstitial = React.forwardRef((props, ref) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const container = useRef()

  useEffect(firstLoad, [])
  useEffect(() => {
    if (!window?.document)
      return

    const root = window.document.documentElement;
    root.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
  }, [scrollbarWidth])

  const checkScrollbar = useCallback(() => {
    const current = container.current
    setScrollbarWidth(current?.offsetWidth || 0) + (current?.clientWidth || 0)
  }, [container.current])

  // Monitor window resizing
  useEffect(() => {
    const bound = checkScrollbar
    window.addEventListener('resize', bound)
    return () => window.removeEventListener('resize', bound)
  }, [checkScrollbar, container.current])

  // Monitor interstiail content resizing
  useEffect(() => {
    const element = container.current

    if (!element)
      return

    setScrollbarWidth(element?.offsetWidth || 0) + (element?.clientWidth || 0)
    const observer =  new ResizeObserver(checkScrollbar)
    observer.observe(element)
    return () => observer.unobserve(element)
  }, [container.current])

  return <>
    <div className="leases-documents-interstitial" ref={ref}>    
      <div ref={container} className={props.className}>
        {props.children}
      </div>
    </div>
  </>
})

class Event {
  constructor() {
    this.stopped = false
  }

  stop() {
    this.stopped = true
  }
}

export default function(props) {
  const events = useEventManager()
  const [show, setShow] = useState(isNil(props.show) ? true : props.show)
  
  const { backdrop } = props
  const container = useRef()

  const close = useCallback(e => {
    const event = new Event()
    events.applyEventListeners('closingInterstitial', event)
    if (event.stopped)
      return

    if (props.onClose && props.onClose() === false)
      return

    setShow(false)
  }, [setShow, props.onClose])

  const clickBackdrop = useCallback(() => {
    if (backdrop == 'static')
      return

    close()
  }, [close, backdrop])

  if (!show)
    return null

  return <>
    { backdrop === false ? null : <div className="leases-documents-interstitial-screen" onClick={clickBackdrop}></div> }
    <Interstitial {...props} ref={container} onClose={close} />
  </>
}