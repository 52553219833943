import React from 'react'
import { format, parseISO } from 'date-fns'

const formatDate = (time) => format(parseISO(time), "MM/dd/yyy' - 'hh:mm aa")
const prettyDate = (date) => date ? formatDate(date) : "N/A"

const FeeInfoAccordion = ({ fee }) => {
  return (
    <>
      <div className="slide-down accordion-row fee-row">
        <p className="fee-table-cell"><span>Due At:</span>  <br /> {fee.due_at || "N/A"} </p>
        <p className="fee-table-cell"><span>Created At:</span> <br /> {prettyDate(fee?.created_at)} </p>
        <p className="fee-table-cell"><span>Updated At:</span> <br /> {prettyDate(fee?.updated_at)} </p>
        <p className="fee-table-cell"><span>Processing Fee:</span> <br />{fee?.processing_fee ? "Yes" : "No"}</p>
        <p className="fee-table-cell"><span>Additional Fee:</span> <br /> {fee?.is_additional_fee || "N/A"} </p>
      </div>
      <div className="slide-down accordion-row fee-row">
        <p className="fee-table-cell"><span>Paid By:</span> <br /> {fee.user_email || "N/A"} </p>
        <p className="fee-table-cell"><span>Authorized At:</span> <br /> {prettyDate(fee?.authorized_date)} </p>
        <p className="fee-table-cell"><span>Stripe Charge Id</span> <br />{fee?.stripe_charge_id || "N/A"}</p>
        <p className="fee-table-cell"><span>Captured At:</span> <br /> {prettyDate(fee?.capture_date)} </p>
        <p className="fee-table-cell"><span>Stripe Transfer Id</span> <br />{fee?.stripe_transfer_id || "N/A"}</p>
        {/* Fill below as needed, added in for UI formatting */}
      </div>
    </>
  )
}

export default FeeInfoAccordion
