export { default as findAppropriateSize } from './dom/find_appropriate_size'

export function distanceFromTop(element) {
  if (!element)
    return 0

  if (element.style.position == 'fixed')
    return element.offsetTop

  return element.offsetTop + distanceFromTop(element.offsetParent)
}

export function distanceFromLeft(element) {
  if (!element)
    return 0

  if (element.style.position == 'fixed')
    return element.offsetLeft

  return element.offsetLeft + distanceFromLeft(element.offsetParent)
}

function findTopContainer(element, { default: defaultElement }) {
  do {
    if (element.style.position == "fixed")
      return element
  } while (element = element.offsetParent)

  return defaultElement || window.document
}

export function scrollTopTo(element, options) {
  const scrollable = findTopContainer(element, {default: window })
  scrollable.scrollTo({ behavior: 'smooth', ...options})
}

export function scrollTopAt(element) {
  const scrollable = findTopContainer(element, { default: window })
  return scrollable.scollTop || scrollable.pageYOffset
}

export function rectToStyle(rect) {
  return {
    left: rect.x,
    top: rect.y,
    width: rect.width,
    height: rect.height
  }
}