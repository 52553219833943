import React from 'react'
import PropTypes from 'prop-types'

import ShowModalButton from './show_modal_button'
import Modal from './modal'
import SuperAdminContainer from './super_admin'

class RefundApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFees: [],
      countOfSelectedFees: 0,
      openModalBtnVisible: false,
      totalRefundAmount: "0",
      reason: "",
      additionalDetails: ""
    }

    this.removeSelectedFeeIdByIndex = this.removeSelectedFeeIdByIndex.bind(this)
    this.setAppState = this.setAppState.bind(this)
    this.setFeeRefundAmount = this.setFeeRefundAmount.bind(this)
  }

  componentDidMount() {
    this.checkboxListener()
  }

  componentDidUpdate(_, prevState) {
    let newState = {}

    if (this.shouldModalBtnVisibilityChange(prevState.selectedFees))
      newState.openModalBtnVisible = this.determineModalBtnVisibilityFromPrevState(prevState.selectedFees)

    const refundAmount = this.calculateTotalRefundAmount()
    if (refundAmount != prevState.totalRefundAmount)
      newState.totalRefundAmount = refundAmount

    if (Object.keys(newState).length > 0) this.setState(newState)
  }

  anyFeesRequestedRefund() {
    let anyRequestedRefunds = false

    for (let feeId in this.props.feesGroupedById) {
      if (this.props.feesGroupedById[feeId]['refund_status'] == 'refund_requested') return anyRequestedRefunds = true
    }

    return anyRequestedRefunds
  }

  calculateTotalRefundAmount() {
    const selectedFees = this.state.selectedFees
    let totalRefundAmount = 0

    for (let i = 0; i < selectedFees.length; i++)
      totalRefundAmount += parseFloat(selectedFees[i].refundAmount)

    return parseFloat(totalRefundAmount).toFixed(2) || "0.00"
  }

  checkboxListener() {
    $("." + this.props.checkboxClass).change(function (e) {
      const { selectedFees } = this.state
      const feeId = e.target.dataset.feeId
      let selectedFee = this.props.feesGroupedById[feeId]
      const idxOfSelectedFee = selectedFees.map((f) => { return f.id }).indexOf(selectedFee.id)

      if (idxOfSelectedFee > -1)
        return this.removeSelectedFeeIdByIndex(idxOfSelectedFee)

      selectedFee.current_amount = this.removeCommasFromAmount(selectedFee.current_amount)
      selectedFee.amount = this.removeCommasFromAmount(selectedFee.amount)
      selectedFee.refundAmount = selectedFee.current_amount || selectedFee.amount
      const newSelectedFees = [...selectedFees, selectedFee]
      this.setState({ selectedFees: newSelectedFees, countOfSelectedFees: newSelectedFees.length })
    }.bind(this))
  }

  removeCommasFromAmount(amount) {
    if (typeof amount != "string") return amount

    return amount.replace(/,/g, '')
  }

  determineModalBtnVisibilityFromPrevState(prevSelectedFees) {
    const currentSelectedFees = this.state.selectedFees

    if (prevSelectedFees.length == 0 && currentSelectedFees.length > 0) return true
    if (prevSelectedFees.length > 0 && currentSelectedFees.length == 0) return false

    return this.state.openModalBtnVisible
  }

  shouldModalBtnVisibilityChange(prevSelectedFees) {
    const currentSelectedFees = this.state.selectedFees

    if (prevSelectedFees == currentSelectedFees) return false
    if (!prevSelectedFees && currentSelectedFees.length > 0) return true
    if (prevSelectedFees.length == 0 && currentSelectedFees.length > 0) return true
    if (prevSelectedFees.length > 0 && currentSelectedFees.length == 0) return true

    return false
  }

  removeSelectedFeeIdByIndex(idx) {
    const newSelectedFees = this.state.selectedFees.filter((_, i) => i !== idx)

    this.setState({ selectedFees: newSelectedFees, countOfSelectedFees: newSelectedFees.length })
  }

  renderShowModalButton() {
    return (
      <div className="text-align-right">
        <ShowModalButton setAppState={this.setAppState} />
      </div>
    )
  }

  renderSuperAdminRefundContainer() {
    if (!this.anyFeesRequestedRefund()) return null

    return <SuperAdminContainer
      rejectRefundRequestPath={this.props.rejectRefundRequestPath}
      approveRefundRequestPath={this.props.approveRefundRequestPath} />
  }

  renderModal() {
    return <Modal
      selectedFees={this.state.selectedFees}
      setAppState={this.setAppState}
      setFeeRefundAmount={this.setFeeRefundAmount}
      additionalDetails={this.state.additionalDetails}
      reason={this.state.reason}
      totalRefundAmount={this.state.totalRefundAmount}
      refundRequestPath={this.props.refundRequestPath}
    />
  }

  setFeeRefundAmount(feeIndex, amount) {
    let selectedFees = this.state.selectedFees
    selectedFees[feeIndex].refundAmount = amount

    this.setState({ selectedFees: selectedFees })
  }

  setAppState(newState) {
    this.setState(newState)
  }

  render() {
    const { openModalBtnVisible } = this.state
    return (
      <div id="refund-app">
        {openModalBtnVisible ? this.renderShowModalButton() : null}
        { this.props.superAdmin ? this.renderSuperAdminRefundContainer() : null }
        { this.renderModal() }
      </div>
    )
  }
}

RefundApp.propTypes = {
  checkboxClass: PropTypes.string.isRequired,
  feesGroupedById: PropTypes.object.isRequired,
  refundRequestPath: PropTypes.string.isRequired,
  rejectRefundRequestPath: PropTypes.string.isRequired,
  approveRefundRequestPath: PropTypes.string.isRequired,
  superAdmin: PropTypes.bool.isRequired
}

export default RefundApp