import React from 'react'
import NewStructure from 'components/buildings/edit/NewStructure';

export default function FormSearch({building}) {
  return (
    <>
      <div className='building-structure-form-search-container'>
        <div className='building-structure-form-search-wrapper'>
          <NewStructure building={building}/>
        </div>
      </div>
    </>
  )
}
