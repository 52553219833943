import React, { useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import useSWR from 'swr'

import BuildingUnitsHeader from './BuildingUnitsHeader'
import BuildingUnitsBody from './BuildingUnitsBody'
import { selectBuildingEditBuildingStructure, setActiveTab as setActiveTabAction } from '../store/slice'

import { swrFetcher } from 'lib/axios'
import useServerPagination from 'components/domeui/table/hooks/useServerPagination'
import useServerSortable from 'components/domeui/table/hooks/useServerSortable'

const PER_PAGE = 10

const generateRequestURL = (baseUrl, options) => {
  const urlParams = new URLSearchParams(options)

  return `${baseUrl}?${urlParams.toString()}`
}

const BuildingUnits = ({
  initialData,
  initialPaginationData,
  initialUrlParams,
  building,
  building_structure,
  activeTab,
  tabs,
  enabledTabs,
  setActiveTab,
}) => {
  const { randomVersion } = useSelector(selectBuildingEditBuildingStructure)
  let units, pagination_data
  const [page, setPage] = useState(
    initialPaginationData?.current_page ? parseInt(initialPaginationData?.current_page) : 1
  )
  const per_page = initialPaginationData?.per_page || PER_PAGE
  const {
    sortField,
    sortOrder,
    handleSorting: handleUnitsSorting,
  } = useServerSortable(initialUrlParams?.sort || null, initialUrlParams?.order || 'asc')

  const [searchQuery, setSearchQuery] = useState(initialUrlParams?.query)

  const queryOptions = {
    page: page,
    per_page: per_page,
  }

  if (sortField) {
    queryOptions['sort'] = sortField
    queryOptions['order'] = sortOrder
  }

  if (searchQuery?.length > 0) {
    queryOptions['query'] = searchQuery
  }

  const queryKey = generateRequestURL(
    `/api/buildings/${building.id}/building_structures/${building_structure.id}/building_units`,
    queryOptions
  )

  const { data, mutate } = useSWR(queryKey, swrFetcher, { keepPreviousData: true })
  if (data) {
    units = data.building_units.data
    pagination_data = data.building_units.pagination_data
  } else {
    units = initialData
    pagination_data = initialPaginationData
  }

  const { total_pages: totalPages } = pagination_data
  const { component: Pagination, goToPage } = useServerPagination(page, setPage, totalPages)

  const updateBrowserUrl = useCallback(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    urlParams.set('units_page', page)
    urlParams.set('units_per_page', per_page)

    if (sortField) {
      urlParams.set('units_order', sortOrder)
      urlParams.set('units_sort', sortField)
    }

    if (searchQuery?.length > 0) {
      urlParams.set('units_query', searchQuery)
    } else {
      urlParams.delete('units_query')
    }

    window.history.pushState({}, null, window.location.href.split('?')[0] + '?' + urlParams)
  }, [page, sortOrder, sortField, sortField, searchQuery])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (page > 1 || urlParams.has('units_page')) {
      updateBrowserUrl()
    }
  }, [page])

  useEffect(() => {
    updateBrowserUrl()
  }, [sortField, sortOrder])

  useEffect(() => {
    updateBrowserUrl()
  }, [searchQuery])

  const onSearch = (query) => {
    setSearchQuery(query)

    if (page > 1) {
      setPage(1)
    }
  }

  useEffect(() => {
    mutate()
  }, [randomVersion, mutate])

  // const resetDefaultSorting = useCallback(() => handleUnitsSorting('created_at', 'asc'), [handleUnitsSorting])

  // TODO: Handle history tab. The below code would work only for normal tables.
  // useEffect(() => {
  //   resetDefaultSorting()
  //   goToPage(1)
  // }, [activeTab, resetDefaultSorting, goToPage])

  return (
    <>
      <div className="building-edit-building-units-table-wrapper dome-bg-white">
        <BuildingUnitsHeader
          structureId={building_structure?.id}
          tabs={tabs}
          activeTab={activeTab}
          enabledTabs={enabledTabs}
          setActiveTab={setActiveTab}
          onSearch={onSearch}
          defaultSearchQuery={searchQuery}
          address={building_structure?.default_additional_address?.address}
        />
        <BuildingUnitsBody
          structureId={building_structure?.id}
          units={units}
          handleUnitsSorting={handleUnitsSorting}
          sortField={sortField}
          sortOrder={sortOrder}
          searchQuery={data?.building_units?.url_params?.query}
        />
        {totalPages > 1 && (
          <div className="pagination-container">
            <Pagination />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const structureState = state.buildingEditBuildingStructure

  return {
    building: structureState.building,
    building_structure: structureState.building_structure,
    tabs: structureState.tabs,
    enabledTabs: structureState.enabledTabs,
    activeTab: structureState.activeTab,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setActiveTab: (tab) => dispatch(setActiveTabAction(tab)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingUnits)
