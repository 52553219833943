import React, { useCallback, useMemo, useState } from 'react'
import Pagination from '../Pagination'

export default function usePagination(data, perPage) {
  const [currentPage, setCurrentPage] = useState(1)

  const start = (currentPage - 1) * perPage
  const end = Math.min(start + perPage, data.length)

  const totalPages = Math.floor(data.length / perPage) + (data.length % perPage === 0 ? 0 : 1)

  const isFirstPage = start === 0
  const isLastPage = end === data.length

  const canGoBack = !isFirstPage
  const canGoForward = !isLastPage

  const currentPageData = useMemo(() => data.slice(start, end), [data, start, end])

  const goPrevious = useCallback(
    () => setCurrentPage((prevCurrentPage) => (canGoBack ? prevCurrentPage - 1 : prevCurrentPage)),
    [setCurrentPage, canGoBack]
  )

  const goForward = useCallback(
    () => setCurrentPage((prevCurrentPage) => (canGoForward ? prevCurrentPage + 1 : prevCurrentPage)),
    [setCurrentPage, canGoForward]
  )
  const goToPage = useCallback((page) => setCurrentPage(page), [setCurrentPage])

  const component = useCallback(
    (props) => (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        goForward={goForward}
        goPrevious={goPrevious}
        goToPage={goToPage}
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        canGoBack={canGoBack}
        canGoForward={canGoForward}
        {...props}
      />
    ),
    [currentPage, goForward, goPrevious, goToPage, totalPages, isFirstPage, isLastPage, canGoBack, canGoForward]
  )

  return useMemo(
    () => ({
      component,
      currentPageData,
      currentPage,
      goForward,
      goPrevious,
      goToPage,
      totalPages,
      isFirstPage,
      isLastPage,
      canGoBack,
      canGoForward,
    }),
    [
      component,
      currentPageData,
      currentPage,
      goForward,
      goPrevious,
      goToPage,
      totalPages,
      isFirstPage,
      isLastPage,
      canGoBack,
      canGoForward,
    ]
  )
}
