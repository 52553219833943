import { useState, useEffect, useCallback, useMemo } from 'react'
import { nanoid } from '@reduxjs/toolkit'
// import { mapValues } from 'lodash-es'

import { updateValuesToElement } from './utils'
import List from './List'
import Input from './Input'
import Add from './Add'

const useInteractPropertyCodes = (initialValues, htmlOutputElementId) => {
  const [propertyCodes, setPropertyCodes] = useState(initialValues.map((value) => ({ id: nanoid(), value: value })))

  useEffect(() => {
    updateValuesToElement(
      propertyCodes.map((item) => item.value),
      htmlOutputElementId
    )
  }, [propertyCodes])

  const [currentPropertyCode, setCurrentPropertyCode] = useState('')

  const onListItemRemove = useCallback(
    (id) => {
      setPropertyCodes((prevPropertyCodes) => prevPropertyCodes.filter((item) => item.id !== id))
    },
    [setPropertyCodes]
  )
  const onInputChange = useCallback((value) => setCurrentPropertyCode(value), [setCurrentPropertyCode])
  const onSubmit = useCallback(() => {
    if (currentPropertyCode.trim()?.length === 0) return

    // Handle duplicate inputs if necessary
    // if (mapValues(propertyCodes).includes(currentPropertyCode.trim())) return

    setPropertyCodes((prevPropertyCodes) => [...prevPropertyCodes, { id: nanoid(), value: currentPropertyCode.trim() }])
    setCurrentPropertyCode('')
  }, [propertyCodes, setPropertyCodes, currentPropertyCode, setCurrentPropertyCode])

  return useMemo(
    () => ({
      propertyCodes,
      currentPropertyCode,
      hasValues: propertyCodes.length > 0,
      onListItemRemove,
      onInputChange,
      onSubmit,
    }),
    [propertyCodes, currentPropertyCode, onListItemRemove, onInputChange, onSubmit]
  )
}

export { List, Input, Add, useInteractPropertyCodes }
