import React, { useState, useEffect, useCallback, useRef } from 'react'
import { asArray } from 'lib/utilities'

export const useDropdown = ({defaultShow = false, excludedReferences = []} = {}) => {
  const [ show, setShow ] = useState(defaultShow)
  const ref = useRef()
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  const toggle = useCallback(() =>  setShow(!show), [setShow, show])

  useEffect(() => {
    if (!show || !ref.current)
      return
    
    const refs = [ref].concat(asArray(excludedReferences))
    const click = e => (refs.some(r => r.current?.contains(e.target))) ? null : close()

    window.addEventListener('click', click)
    return () => window.removeEventListener('click', click)
  }, [ref.current, show, close, excludedReferences] )

  return { ref, show, open, close, toggle }
}