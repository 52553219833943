import React from 'react'
import PropTypes from 'prop-types'
import MultipleAddressSelect from 'components/utility/multiple_address_select'
import Search from 'components/utility/search'

export default class BuildingFilters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      buildings: {},
      addresses: []
    }

    this.handleAddBuilding = this.handleAddBuilding.bind(this)
    this.handleAddAddress = this.handleAddAddress.bind(this)
    this.handleRemoveBuilding = this.handleRemoveBuilding.bind(this)
    this.removeAddress = this.removeAddress.bind(this)
  }

  componentDidMount() {
    if (this.props.addresses.length > 0)
      this.setState({ addresses: this.props.addresses })
  }

  handleRemoveBuilding(building) {
    if (typeof(this.props.handleRemoveBuilding) == "function")
      this.props.handleRemoveBuilding(building)

    let newState = this.state.buildings

    delete newState[building.id]

    this.setState({ buildings: newState })
  }

  removeAddress(address) {
    this.setState({ addresses: this.state.addresses.filter(stateAddress => stateAddress != address) })
  }

  handleAddBuilding(building) {
    if (typeof(this.props.handleAddBuilding) == "function")
      this.props.handleAddBuilding(building)

    this.setState({buildings: {...this.state.buildings, [building.id]: building}})
  }

  handleAddAddress(address) {
    const addresses = this.state.addresses

    if (addresses.indexOf(address) < 0) {
      addresses.push(address)
      this.setState({ addresses: addresses })
    }
  }

  renderBuildingSearch() {
    return <Search
      url="/api/v2/financials/search_transaction_addresses"
      onSelect={results => this.handleAddAddress(results.address)}
      hideResultsDropdownOnSelect={true}
      resultsDisplayKey="address"
      resultsKey="results"
      resultsIdentifierKey="address" />
  }

  renderAddressSelect() {
    return <div className="building-select">
      <MultipleAddressSelect
        name={"filters[addresses][]"}
        handleClickRemove={address => this.removeAddress(address)}
        selectedAddresses={this.state.addresses} />
    </div>
  }

  render() {
    return <div className="building-filter">
      {this.renderBuildingSearch()}
      {this.renderAddressSelect()}
    </div>
  }
}

BuildingFilters.propTypes = {
  addresses: PropTypes.array,
  setFiltersState: PropTypes.func.isRequired
}

BuildingFilters.defaultProps = {
  addresses: []
}
