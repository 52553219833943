import React from 'react'

export default function List({ items, onRemove }) {
  return (
    <ul>
      {items.map(({ id, value }) => (
        <li key={id} onClick={() => onRemove(id)}>
          <span>{value}</span>
          <i className="fa-light fa-xmark"></i>
        </li>
      ))}
    </ul>
  )
}
