import React from 'react'
import PropTypes from 'prop-types'
import ActiveFilters from './ActiveFilters'
import BuildingFilters from './BuildingFilter'
import AmountFilters from './AmountFilters'
import DateFilter from './DateFilter'
import DescriptionFilter from './DescriptionFilter'
import PurchaserFilter from './PurchaserFilter'
import TypeFilter from './TypeFilter'
import AccountFilter from './AccountFilter'

export default class Filters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAddressFilter: false,
      showAmountFilter: false,
      showDateFilter: false,
      showDescriptionFilter: false,
      showPurchaserFilter: false,
      showTypeFilter: false,
      showAccountFilter: false,
      addresses: [],
      date: {},
      purchaser: {},
      type: "",
      account: "",
      buildings: {}
    }

    this.clearFilters = this.clearFilters.bind(this)
    this.handleAddBuilding = this.handleAddBuilding.bind(this)
    this.handleRemoveBuilding = this.handleRemoveBuilding.bind(this)
    this.setFiltersState = this.setFiltersState.bind(this)
  }

  componentDidMount() {
    const initialState = this.state

    if (this.props.activeFilters["addresses"]) initialState["showAddressFilter"] = true
    if (this.props.activeFilters["amount"]) initialState["showAmountFilter"] = true
    if (this.props.activeFilters["date"]) initialState["showDateFilter"] = true
    if (this.props.activeFilters["purchaser"]) initialState["showPurchaserFilter"] = true
    if (this.props.activeFilters["itemType"]) initialState["showTypeFilter"] = true
    if (this.props.activeFilters["description"]) initialState["showDescriptionFilter"] = true
    if (this.props.activeFilters["account"]) initialState["showAccountFilter"] = true

    this.setState(initialState)
  }

  setFiltersState(newState) {
    this.setState(newState)
  }

  clearFilters() {
    window.location = "/api/v2/financials"
  }

  handleAddBuilding(building) {
    this.setState({buildings: {...this.state.buildings, [building.id]: building}})
  }

  handleRemoveBuilding(building) {
    let newState = this.state.buildings

    delete newState[building.id]

    this.setState({ buildings: newState })
  }

  handleToggleFilter(showFilterName) {
    this.setState({ [showFilterName]: !this.state[showFilterName] })
  }

  renderClearBtn() {
    return <button type="button" className="clear-btn" onClick={() => this.clearFilters()}>Clear</button>
  }

  renderSetButton() {
    return <button className="set-btn" type="submit">Set</button>
  }

  renderHeader() {
    return <div className="filter-header">
      {this.renderClearBtn()}
      <span>Filters</span>
      {this.renderSetButton()}
    </div>
  }

  renderActiveFilters() {
    if (this.props.activeFilters)
      return <ActiveFilters
        filters={this.props.activeFilters}
        setFiltersState={this.setFiltersState} />
  }

  renderFilterToggle(label, showFilterName) {
    return <div key={label} className="filter-toggle">
      <input className="filter-checkbox"
        type="checkbox"
        checked={!!this.state[showFilterName]}
        onChange={() => this.handleToggleFilter(showFilterName)} />
      <span className="filter-toggle-label">{label}</span>
    </div>
  }

  renderBuildingFilter() {
    return <div>
      { this.renderFilterToggle("Address", "showAddressFilter") }
      { this.state.showAddressFilter
        ? <BuildingFilters
            addresses={this.props.activeFilters["addresses"]}
            handleAddBuilding={building => this.handleAddBuilding(building)}
            handleRemoveBuilding={building => this.handleRemoveBuilding(building)}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  renderAmountFilter() {
    return <div>
      { this.renderFilterToggle("Amount", "showAmountFilter") }
      { this.state.showAmountFilter
        ? <AmountFilters
            amount={this.props.activeFilters["amount"]}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  renderDateFilter() {
    return <div>
      { this.renderFilterToggle("Date", "showDateFilter") }
      { this.state.showDateFilter
        ? <DateFilter
            date={this.props.activeFilters["date"]}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  renderDescriptionFilter() {
    return <div>
      { this.renderFilterToggle("Description", "showDescriptionFilter") }
      { this.state.showDescriptionFilter
        ? <DescriptionFilter
            description={this.props.activeFilters["description"]}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  renderPurchaserFilter() {
    return <div>
      { this.renderFilterToggle("Payor", "showPurchaserFilter") }
      { this.state.showPurchaserFilter
        ? <PurchaserFilter
            purchaser={this.props.activeFilters["purchaser"]}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  // refactor
  renderTypeFilter() {
    return <div>
      { this.renderFilterToggle("Type", "showTypeFilter") }
      { this.state.showTypeFilter
        ? <TypeFilter
            itemType={this.props.activeFilters["itemType"]}
            transactionTypes={this.props.transactionTypes}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  renderAccountFilter() {
    return <div>
      { this.renderFilterToggle("Account", "showAccountFilter") }
      { this.state.showAccountFilter
        ? <AccountFilter
            selectedAccounts={this.props.activeFilters["account"]}
            accounts={this.props.accounts}
            setFiltersState={this.setFiltersState} />
        : null
      }
    </div>
  }

  render() {
    const { } = this.state
    return <form className="filter-form" name="filter" style={{"display": this.props.visible ? "block" : "none"}}>
      {this.renderHeader()}
      <div className="filter-options">
        {this.renderBuildingFilter()}
        {this.renderAmountFilter()}
        {this.renderDescriptionFilter()}
        {this.renderDateFilter()}
        {this.renderPurchaserFilter()}
        {this.renderTypeFilter()}
        {this.renderAccountFilter()}
      </div>
    </form>
  }
}

Filters.propTypes = {
  setPaymentsState: PropTypes.func.isRequired,
  activeFilters: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  accounts: PropTypes.array,
  transactionTypes: PropTypes.array
}

Filters.defaultProps = {
  activeFilters: {}
}
