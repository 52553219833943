import React, { useState, useEffect } from 'react'
import BadgeIcon from './icons/BadgeIcon'
import EmailIcon from './icons/EmailIcon'
import GearIcon from './icons/GearIcon'
import LockIcon from './icons/LockIcon'
import PhoneIcon from './icons/PhoneIcon'
import UserGroupIcon from './icons/UserGroupIcon'
import UserIcon from './icons/UserIcon'
import VisibleIcon from './icons/VisibleIcon'

import PasswordRequirements from './PasswordRequirements'

const FormFields = (props) => {
  const [showExtraField, setshowExtraField] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const accountType = props.watch('user[account_type]')
  const firstName = props.watch('user[first_name]')
  const lastName = props.watch('user[last_name]')
  const password = props.watch('user[password]')
  const email = props.watch('user[email]')
  const phoneNumber = props.watch('user[phone_number]')
  const licenseID = props.watch('user[license_id]')
  const company = props.watch('user[company]')

  useEffect(() => {
    const value = accountType === "broker" || accountType === "attorney"
    setshowExtraField(value)
    if (!value) {
      props.unregister('user[license_id]')
      props.unregister('user[company]')
    }

  },[props.unregister, accountType])

  const updateVisiblePasswordState = (bool) => {
    setIsVisible(bool)
  }

  return (
    <>
      <div className='form-items'>
        <div className='name-inputs'>
          <div className='form-group'>
            <label htmlFor='first_name'>
              first name<span className='required'>*</span>
            </label>
            {props.errors && (
              <span className='error'>
                {props.errors?.user?.first_name?.message}
              </span>
            )}
            <div className='icon-field'>
              <UserIcon
                errors={props.errors?.user?.first_name}
                watchedField={firstName}
              />
              <input
                id='first_name'
                className={`form-control ${
                  props.errors?.user?.first_name ? "error-field" : ""
                }`}
                type='text'
                {...props.register("user[first_name]", {
                  required: "enter first name",
                  onBlur: e => {
                    props.setValue("user[first_name]", e.target.value.trim())
                  },
                })}
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='last_name'>
              last name<span className='required'>*</span>
            </label>
            {props.errors && (
              <span className='error'>
                {props.errors?.user?.last_name?.message}
              </span>
            )}
            <div className='icon-field'>
              <UserIcon
                errors={props.errors?.user?.last_name}
                watchedField={lastName}
              />
              <input
                id='last_name'
                className={`form-control ${
                  props.errors?.user?.last_name ? "error-field" : ""
                }`}
                type='text'
                {...props.register("user[last_name]", {
                  required: "enter last name",
                  onBlur: e => {
                    props.setValue("user[last_name]", e.target.value.trim())
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='email'>
            email<span className='required'>*</span>
          </label>
          {props.errors && (
            <span className='error'>{props.errors?.user?.email?.message}</span>
          )}
          <div className='icon-field'>
            <EmailIcon
              errors={props.errors?.user?.email}
              watchedField={email}
            />
            <input
              id='email'
              className={`form-control ${
                props.errors?.user?.email ? "error-field" : ""
              }`}
              type='text'
              {...props.register("user[email]", {
                pattern: {
                  value: /^.+@.+\..+$/,
                  message: "enter a valid email address",
                },
                required: "email required",
              })}
            />
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='password'>
            password<span className='required'>*</span>
          </label>
          {props.errors && (
            <span className='error'>
              {props.errors?.user?.password?.message}
            </span>
          )}
          <div className='icon-field'>
            <LockIcon
              errors={props.errors?.user?.password}
              watchedField={password}
            />
            <VisibleIcon
              updateVisiblePasswordState={updateVisiblePasswordState}
              isVisible={isVisible}
            />
            <input
              id='password'
              className={`form-control ${
                props.errors?.user?.password ? "error-field" : ""
              }`}
              type={isVisible ? "text" : "password"}
              {...props.register("user[password]", {
                required: "password required",
                pattern: {
                  value: /^.*(?=.{8})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9A-Za-z]).*$/,
                  message: "must meet complexity requirements",
                },
              })}
            />
          </div>
        </div>
        <PasswordRequirements watch={props.watch} />
        <div className='form-group'>
          <label htmlFor='phone_number'>
            phone number<span className='required'>*</span>
          </label>
          {props.errors && (
            <span className='error'>
              {props.errors?.user?.phone_number?.message}
            </span>
          )}
          <div className='icon-field'>
            <PhoneIcon
              errors={props.errors?.user?.phone_number}
              watchedField={phoneNumber}
            />
            <input
              id='phone_number'
              className={`form-control ${
                props.errors?.user?.phone_number ? "error-field" : ""
              }`}
              type='tel'
              {...props.register("user[phone_number]", {
                required: "enter phone number",
                onBlur: e => {
                  props.setValue("user[phone_number]", e.target.value.trim())
                },
              })}
            />
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='account'>
            account type<span className='required'>*</span>
          </label>
          {props.errors && (
            <span className='error'>
              {props.errors?.user?.account_type?.message}
            </span>
          )}
          <div className='icon-field'>
            <GearIcon
              errors={props.errors?.user?.account_type}
              watchedField={accountType}
            />
            <i className='fa-solid fa-angle-down'></i>
            <select
              id='account'
              className={`form-control ${
                props.errors?.user?.account_type ? "error-field" : ""
              }`}
              {...props.register("user[account_type]", {
                required: "must select account type",
              })}
            >
              <option value='' disabled hidden>
                choose an account type
              </option>
              <option value='applicant'>Applicant</option>
              <option value='attorney'>Attorney</option>
              <option value='boardmember'>Board Member</option>
              <option value='broker'>Broker</option>
              <option value='lender'>Lender</option>
              <option value='owner'>Owner/Landlord/Shareholder</option>
              <option value='propertymanager'>Property Manager</option>
              <option value='applicant'>Real Estate Developer</option>
              <option value='other'>Other</option>
            </select>
          </div>
        </div>
        {showExtraField && (
          <>
            <div className='form-group'>
              <label htmlFor='license_id'>
                license #<span className='required'>*</span>
              </label>
              {props.errors && (
                <span className='error'>
                  {props.errors?.user?.license_id?.message}
                </span>
              )}
              <div className='icon-field'>
                <BadgeIcon
                  errors={props.errors?.user?.license_id}
                  watchedField={licenseID}
                />
                <input
                  id='license_id'
                  className={`form-control ${
                    props.errors?.user?.license_id ? "error-field" : ""
                  }`}
                  type='text'
                  {...props.register("user[license_id]", {
                    required: "6-digit license # required",
                    minLength: {
                      value: 6,
                      message: "must be at least 6 characters",
                    },
                    onBlur: e => {
                      props.setValue("user[license_id]", e.target.value.trim())
                    },
                  })}
                />
              </div>
            </div>
            <div className='form-group'>
              <label htmlFor='company'>
                organization <span className='optional'>(optional)</span>
              </label>
              <div className='icon-field'>
                <UserGroupIcon watchedField={company} />
                <input
                  id='company'
                  className='form-control'
                  type='text'
                  {...props.register("user[company]", {})}
                />
              </div>
            </div>
          </>
        )}
        <div className='form-checkbox'>
          {props.errors && (
            <span className='error--no-float'>
              {props.errors?.user?.terms?.message}
            </span>
          )}
          <div className='checkbox-group'>
            <input
              type='checkbox'
              id='terms'
              {...props.register("user[terms]", {
                required:
                  "you must read + agree to our policies before proceeding",
              })}
            />
            <label htmlFor='terms'>
              By checking this box, you are confirming you have read and agree
              to our <a href='/about/legal'>Terms + Conditions</a> and{" "}
              <a href='/about/privacy_policy'>Privacy Policy.</a>
            </label>
          </div>
        </div>
        <footer>
          <div>
            <p>Already have an account? <a className='link-aqua link-chevron-right' href="/users/login">login</a></p>
            <button
              type='submit'
              className={`btn-base ${
                props.isValid ? "btn-dark-green" : "btn-disabled"
              }`}
            >
              register
            </button>
          </div>
        </footer>
      </div>
    </>
  )
}

export default FormFields
