import React, { useState, useContext, useCallback, forwardRef, useRef } from 'react'
import { difference, compact } from 'lodash-es'
import { clsx } from 'clsx'

import startIcon from '/app/assets/images/pages/home/pricing/start.svg'
import submitIcon from '/app/assets/images/pages/home/pricing/submit.svg'
import Search from './main_search'

const Context = React.createContext()

const PATH_KEYS = ['root', 'role', 'app_type']

const CAN_BACK_KEYS = difference(PATH_KEYS, ['root', 'role'])

const StartAppComponent = () => {
  return (
    <>
      <span className="display-only-desktop-inline">start an application</span>
      <div className="root-answer-mobile display-only-mobile-flex">
        <span className="root-action">start</span>
        <span>an application</span>
        <div>
          <img src={startIcon} />
        </div>
      </div>
    </>
  )
}

const SubmitToBuildingComponent = () => {
  return (
    <>
      <span className="display-only-desktop-inline">submit an application</span>
      <div className="root-answer-mobile display-only-mobile-flex">
        <span className="root-action">submit</span>
        <span>an application</span>
        <div>
          <img src={submitIcon} />
        </div>
      </div>
    </>
  )
}

const ROOT_QUESTION = {
  id: 'root',
  text: 'choose an option',
  answers: [
    {
      id: 'start_app',
      component: StartAppComponent,
    },
    {
      id: 'submit_to_building',
      component: SubmitToBuildingComponent,
    },
  ],
}

const RIGHT_PATH = [
  {
    id: 'role',
    text: 'choose your role',
    text_mobile: 'select your role*',
    condition: { root: ['start_app', 'submit_to_building'] },
    answers: [
      {
        id: 'broker',
        text: 'Broker',
        condition: { root: ['start_app', 'submit_to_building'] },
      },
      {
        id: 'attorney',
        text: 'Attorney',
        condition: { root: ['start_app'] },
      },
      {
        id: 'applicant',
        text: 'Applicant',
        condition: { root: ['start_app', 'submit_to_building'] },
      },
      {
        id: 'seller',
        text: 'Seller',
        condition: { root: ['start_app'] },
      },
      {
        id: 'resident',
        text: 'Resident',
        condition: { root: ['start_app', 'submit_to_building'] },
      },
    ],
    classes: {
      answers: ['one-third'],
    },
  },
  {
    id: 'app_type',
    text: 'what type of application?',
    text_mobile: 'select type of application*',
    condition: { root: ['start_app'], role: ['resident'] },
    answers: [
      {
        id: 'refinance-et-all',
        text: 'Refinance/Transfer/Lease Renewal/Alteration',
      },
      {
        id: 'sale-lease',
        text: 'Sale/Lease/Sublease',
      },
    ],
    classes: {
      answers: ['full-width'],
    },
  },
]

const PATH = [ROOT_QUESTION, ...RIGHT_PATH]

const serializeState = (state) => {
  return PATH_KEYS.map((key) => {
    if (!state[key]) return null

    return [key, [[state[key]].flat().join(',')].join('=')]
  })
    .filter((item) => item)
    .join('&')
}

const ONE_TIME_APP_FEE = {
  fee_title: 'initiation fee:',
  description: 'This one-time fee is payable by the person who initiates the application.',
}

const ANSWERS = {
  [serializeState({ root: 'start_app', role: 'broker' })]: {
    fee_number: 75,
    user_type: 'broker',
    ...ONE_TIME_APP_FEE,
  },
  [serializeState({ root: 'start_app', role: 'attorney' })]: {
    fee_number: 75,
    user_type: 'attorney',
    ...ONE_TIME_APP_FEE,
  },
  [serializeState({ root: 'start_app', role: 'applicant' })]: {
    fee_number: 120,
    user_type: 'applicant',
    ...ONE_TIME_APP_FEE,
  },
  [serializeState({ root: 'start_app', role: 'seller' })]: {
    fee_number: 120,
    user_type: 'seller',
    ...ONE_TIME_APP_FEE,
  },
  [serializeState({ root: 'start_app', role: 'resident', app_type: 'refinance-et-all' })]: {
    fee_title: 'initiation fee:',
    fee_number: 0,
    user_type: 'resident',
    description: 'The initiation fee for these application package types is free for residents.',
  },
  [serializeState({ root: 'start_app', role: 'resident', app_type: 'sale-lease' })]: {
    fee_number: 120,
    user_type: 'resident',
    ...ONE_TIME_APP_FEE,
  },
  [serializeState({ root: 'submit_to_building', role: 'broker' })]: {
    fee_title: 'submission fee:',
    fee_number: 0,
    user_type: 'broker',
    description: 'No cost to broker. A $65 Digital Submission Fee is assigned to applicants.',
  },
  [serializeState({ root: 'submit_to_building', role: 'applicant' })]: {
    fee_title: 'submission fee:',
    user_type: 'applicant',
    description: <span>Digital submission fee is determined by property/unit type. Please check the <a className='link-aqua' href='/buildings'>building page</a> for more information.</span>,
    placeholder: 'To begin an application, search for your property',
  },
  [serializeState({ root: 'submit_to_building', role: 'resident' })]: {
    fee_title: 'submission fee:',
    fee_number: 45,
    user_type: 'resident',
    description: 'Digital Submission Fee',
  },
}

const checkCondition = (state, [key, values]) => {
  return [values].flat().includes(state[key])
}

const checkConditions = (state, conditions, opts = {}) => {
  if (typeof conditions == 'undefined') return opts.default || false

  return Object.entries(conditions).every((condition) => checkCondition(state, condition))
}

const lookupAnswer = (question, answer) => {
  const { answers } = PATH.filter((q) => q.id == question)[0]
  return answers.filter((a) => a.id == answer)[0]
}

function Question({
  id,
  text,
  text_mobile,
  placeholder,
  answers,
  condition: conditions,
  state,
  onSelect,
  classes: customClasses,
  goBack,
  rootQuestion,
}) {
  const [showingAnswers, setShowingAnswers] = useState(false)
  const currentAnswer = lookupAnswer(id, state[id])?.text

  const classes = ['pricing-question']
  if (checkConditions(state, conditions, { default: true })) {
    classes.push('used')
  } else {
    classes.push('not-used')
  }

  if (customClasses && customClasses['question']) {
    classes.push(...customClasses['question'])
  }

  if (currentAnswer) {
    classes.push('is-answered')
  }

  const answersRef = useRef(null)
  const selectRef = useRef(null)

  const onSelectClick = () => {
    setShowingAnswers((showing) => {
      if (!showing) {
        answersRef.current?.focus()
      }

      return !showing
    })
  }

  const onBlur = (e) => {
    if (e?.relatedTarget !== selectRef.current) setShowingAnswers(false)
  }

  const handleSelect = (answer_id) => {
    onSelect(id, answer_id)
    onBlur()
  }

  return (
    <div className={classes.join(' ')}>
      <div className={clsx('question', rootQuestion && 'display-only-desktop-flex')}>
        {CAN_BACK_KEYS.includes(id) && (
          <span className="back-icon display-only-desktop" onClick={() => goBack(id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
              <path
                d="M19 6.75C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25L19 6.75ZM0.469669 5.46967C0.176777 5.76257 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989594 6.3033 0.696701C6.01041 0.403808 5.53553 0.403808 5.24264 0.696701L0.469669 5.46967ZM19 5.25L1 5.25L1 6.75L19 6.75L19 5.25Z"
                fill="#666666"
              />
            </svg>
          </span>
        )}
        <span className={clsx('question-text', (rootQuestion || text_mobile) && 'display-only-desktop-inline')}>
          {text}
        </span>
        {text_mobile && <span className="question-text display-only-mobile-inline">{text_mobile}</span>}
      </div>
      {!rootQuestion && (
        <QuestionSelect placeholder={placeholder} answer={currentAnswer} onClick={onSelectClick} ref={selectRef} />
      )}

      <Answers
        ref={answersRef}
        show={showingAnswers}
        id={id}
        answers={answers}
        state={state}
        onBlur={onBlur}
        onSelect={handleSelect}
        classes={customClasses || {}}
        rootQuestion={rootQuestion}
      />
    </div>
  )
}

const QuestionSelect = forwardRef(({ placeholder, answer, onClick }, ref) => {
  return (
    <div
      className={clsx('pricing-question-select', answer && 'answer-selected')}
      onClick={onClick}
      tabIndex="-1"
      ref={ref}
    >
      {answer ? <div className="selected">{answer}</div> : <div className="placeholder">{placeholder}</div>}
      <i className="fas fa-angle-down"></i>
    </div>
  )
})

const Answers = forwardRef(
  ({ id, answers, show, state, onSelect, onBlur, classes: customClasses, rootQuestion }, ref) => {
    const hoveringBase = answers.reduce((hash, answer) => ({ ...hash, ...{ [answer.id]: false } }), {})
    const [hovering, setHovering] = useState(hoveringBase)

    const updateHovering = (id, isHovering) => setHovering({ ...hovering, ...{ [id]: isHovering } })
    const isAnythingHovering = Object.values(hovering).some((answer) => answer)

    const isAnswered = lookupAnswer(id, state[id]) ? true : false

    const answersContainerClass = clsx('answers-wrapper', rootQuestion ? 'root' : 'non-root', show && 'showing')
    const answersClass = clsx(
      'answers',
      isAnswered && 'is-answered',
      isAnythingHovering && 'hovering',
      customClasses['answers'] || null
    )

    return (
      <div className={answersContainerClass} onBlur={onBlur} tabIndex="-1" ref={ref}>
        <div className={answersClass}>
          {answers.map((answer) => (
            <Answer
              parent={id}
              key={answer.id}
              state={state}
              onClick={onSelect}
              {...answer}
              onHoverChange={(isHovering) => updateHovering(answer.id, isHovering)}
              rootQuestion={rootQuestion}
            />
          ))}
        </div>
      </div>
    )
  }
)

function Answer({
  id,
  parent,
  text,
  component: Component,
  condition: conditions,
  state,
  onClick,
  onHoverChange,
  rootQuestion,
}) {
  const [hovering, setHovering] = useState(false)

  const updateHovering = (isHovering) => {
    setHovering(isHovering)
    onHoverChange(isHovering)
  }

  // Should we show this at all?
  if (!checkConditions(state, conditions, { default: true })) return null

  const classes = compact(['answer', hovering ? 'hovering' : null])
  // If something is selected for the parent question
  if (state[parent]) classes.push(state[parent] == id ? 'active' : 'inactive')

  return (
    <div className="answer-wrapper">
      <div
        className={classes.join(' ')}
        onClick={() => {
          onClick(id)
        }}
        onMouseEnter={() => updateHovering(true)}
        onMouseLeave={() => updateHovering(false)}
      >
        {typeof Component === 'function' ? <Component /> : <span>{text}</span>}
        {rootQuestion && (
          <div className="right-arrow-nav display-only-desktop">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="12" viewBox="0 0 28 12" fill="none">
              <path
                d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75V5.25ZM27.5303 6.53033C27.8232 6.23744 27.8232 5.76256 27.5303 5.46967L22.7574 0.696699C22.4645 0.403806 21.9896 0.403806 21.6967 0.696699C21.4038 0.989593 21.4038 1.46447 21.6967 1.75736L25.9393 6L21.6967 10.2426C21.4038 10.5355 21.4038 11.0104 21.6967 11.3033C21.9896 11.5962 22.4645 11.5962 22.7574 11.3033L27.5303 6.53033ZM1 6.75H27V5.25H1V6.75Z"
                fill="#4A4A4A"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

function RenderSearch(props, { placeholder }) {
  const image = useContext(Context)
  const classes = {
    iconClass: "fa-light fa-magnifying-glass search-icon",
    containerClass: "search-container",
    inputClass: "search-container-search",
    searchBarBorderClass: "searchbar-border-sm",
  }
  return (
    <Search
      show_magnifying_glass={true}
      show_empty_message={true}
      placeholderText={placeholder}
      classes={classes}
      customApplicationImage={image}
    />
  )
}

function Result({ state, goBack }) {
  const key = serializeState(state)
  // Which answer are we using?
  const answer = ANSWERS[key]
  const { fee_number, fee_title, user_type, description, additionally, placeholder } = answer || {}

  console.log(description)

  let lastStage = 'root'

  for (let id of [...PATH_KEYS].reverse()) {
    if (id in state) {
      lastStage = id
      break
    }
  }

  return (
    <div className={clsx('pricing-result', answer && 'showing')}>
      {fee_title ? (
        <div className="fee-title">
          {lastStage !== 'root' && (
            <span className="back-icon display-only-desktop" onClick={() => goBack(lastStage, 0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
                <path
                  d="M19 6.75C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25L19 6.75ZM0.469669 5.46967C0.176777 5.76257 0.176777 6.23744 0.469669 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989594 6.3033 0.696701C6.01041 0.403808 5.53553 0.403808 5.24264 0.696701L0.469669 5.46967ZM19 5.25L1 5.25L1 6.75L19 6.75L19 5.25Z"
                  fill="#666666"
                />
              </svg>
            </span>
          )}
          {`${user_type} ${fee_title}`}
        </div>
      ) : null}
      {typeof fee_number == 'undefined' ? null : (
        <div className="fee-content">
          <span className="fee-number">${fee_number}</span>
        </div>
      )}
      {description ? (
        <div className="description">
          {[description].flat().map((d, i) => (
            <div key={i}>{d}</div>
          ))}
        </div>
      ) : null}
      {placeholder ? <RenderSearch placeholder={placeholder} /> : null}
      {additionally ? <div className="additionally">{additionally}</div> : null}
    </div>
  )
}

export default function (props) {
  const [state, setState] = useState({})

  // Updating the state will wipe out all the answers to the questions below the one answered
  const updateState = useCallback(
    (key, value) =>
      setState((prevState) => {
        const newState = {}

        for (let id of PATH_KEYS) {
          if (key === id) {
            if (value !== null) newState[key] = value

            break
          }

          newState[id] = prevState[id]
        }

        return newState
      }),
    [setState]
  )

  const goBack = (key, backwardSteps = 1) => {
    const keyIndex = PATH_KEYS.findIndex((path_key) => path_key === key)

    if (keyIndex !== -1 && keyIndex > 0) {
      updateState(PATH_KEYS[keyIndex - backwardSteps], null)
    }
  }

  const anyQuestionsAnswered = Object.keys(state).length > 0

  return (
    <Context.Provider value={props.customApplicationImage}>
      <div className="pricing-questions">
        <div className="pricing-guide-column pricing-guide-left">
          <Question onSelect={updateState} state={state} {...ROOT_QUESTION} rootQuestion />
        </div>
        <div className={clsx('pricing-guide-column', 'pricing-guide-right', anyQuestionsAnswered && 'touched')}>
          {RIGHT_PATH.map((item) => (
            <Question key={item.id} onSelect={updateState} state={state} {...item} goBack={goBack} />
          ))}
          <Result state={state} goBack={goBack} />
          {/*<Result state={state} />*/}
        </div>
      </div>
    </Context.Provider>
  )
}
