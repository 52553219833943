import React from 'react'
import clsx from 'clsx'
import loadingIcon from '/app/assets/images/insurance/icons/loader.gif'

export default function Loading({ visible, message }) {
  return (
    <div className={clsx('insurance-interstitial-loading', visible && 'visible')}>
      <div className="container-wrapper">
        <img src={loadingIcon} width={252} height={252} />
        <div className="description">{message.length > 0 ? message : 'Loading...'}</div>
        <div className="description">Please do not refresh your screen.</div>
      </div>
    </div>
  )
}

Loading.defaultProps = {
  message: '',
}
