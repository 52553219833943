import React from 'react';
import List from './List';
import FormSearch from './FormSearch';

export default function Main({building, buildingStructures}) {
  return (
    <>
      <FormSearch building={building}/>
      <List buildingStructures={buildingStructures} buildingId={building?.id} />
    </>
  )
}
