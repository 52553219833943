import React from 'react'

class Rollover extends React.Component {
  static defaultProps = {
    className: 'tooltip',
    content: '',
    html: false,
    placement: 'right'
  }

  constructor(props) {
    super(props)

    this.tooltip_ref = React.createRef();
  }

  componentDidMount() {
    $(this.tooltip_ref.current).tooltip();
  }

  render(){
    let iconStyle = {
      border: '1px solid black',
      borderRadius: '50%',
      width: '15px',
      paddingLeft: '2px'
    }

    return (
      <span data-toggle="tooltip"
        ref={this.tooltip_ref}
        className={this.props.className}
        data-placement={this.props.placement}
        data-html={this.props.html ? 'true' : 'false'}
        data-original-title={this.props.content}
      >
      <i className='fa fa-question' style={iconStyle}></i>
      </span>
    )
  }
}

export default Rollover
