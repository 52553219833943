import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { compact, omit } from 'lodash'

import { addCSRF } from 'lib/utilities'
import SignatureView from 'components/signatures/image'
import { Signature as EditSignature, Initial as EditInitial } from 'components/signatures/edit'
// import { StatusContext } from '../../plugins/plugins/leases_plugin/javascripts/StatusContext'

async function fetchPost(url, data = {}, options = {}) {
  const headers = {
    ...(options.headers || {}),
    'Content-Type': 'application/json'
  }

  return await fetch(url, {
    ...options,
    headers,
    method: options.method || "POST",
    body: JSON.stringify(addCSRF(data))
  })
}

function ModalButtons({hide, ok}) {
  return <div className="buttons justify-space-between">
    <button className="btn btn-default" onClick={hide}>Close</button>
    <button className="btn btn-danger" onClick={ok}>Remove</button>
  </div>
}

function Buttons({type}) {
  const [ show, setShow ] = useState(false)
  const { removeSignature } = useContext(SignatureContext)

  const handleClick = useCallback(() => setShow(true), [setShow])

  const handleRemove = useCallback(() => {
    const url = '/user_signatures/'
    fetchPost(url, { signature: { type }}, { method: "DELETE"}).then(() => {
      removeSignature(type)
      setShow(false)
    })
  }, [removeSignature, setShow, type])

  const handleClose = useCallback(() => setShow(false))

  return <div className="buttons text-center">
    <button type="button" className="btn btn-danger btn-sm" onClick={handleClick}>Remove</button>
    <Modal show={show} onExited={handleClose} centered={true} size="sm">
      <Modal.Body>
        <div className="text-center">
          Are you sure you want to remove this signature?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ModalButtons hide={() => setShow(false)} ok={handleRemove} />
      </Modal.Footer>
    </Modal>
  </div>
}

function AddButton({type, label}) {
  const { updateSignature } = useContext(SignatureContext)
  // const { setSubmitted } = useContext(StatusContext)
  const [show, setShow] = useState(false)

  const EditType = type == "full" ? EditSignature : EditInitial

  const handleOpen = useCallback(() => setShow(true))
  const handleSign = useCallback(signature => {
    const url = "/user_signatures"
    fetchPost(url, { signature: { ...signature, type } })
      .then(res => updateSignature(type, signature)
      // .then(() => { debugger; setSubmitted(res['all_signatures_signed'] || false) })
      .then(() => setShow(false)))
  })

  const handleClose = useCallback(() => setShow(false))

  return <div className="buttons">
    <button type="button" className="btn btn-default" onClick={handleOpen}>Add New {label}</button>
    <Modal show={show} onExited={handleClose} centered={true} size={type == "initials" ? "sm" : "md"}>
      <Modal.Body>
        <EditType onSign={handleSign} closeModal={handleClose} />
      </Modal.Body>
    </Modal>
  </div>
}

function SignatureImage({signature, type}) {
  if (signature)
    return <SignatureView signature={signature} />

  return <div className="empty-signature">
    <i className="fa-light fa-question"></i>
  </div>
}

function Signature({className}) {
  const { signatures: { full: signature } } = useContext(SignatureContext)

  const classes = compact([
    'signature-full',
    className
  ]).join(" ")

  return <div className={classes}>
    <div className="title">Signature</div>
    <div className="signature"><SignatureImage signature={signature} type="full" /></div>
    { signature ? <Buttons type="full" /> : null }
    { signature ? null :  <AddButton label="Signature" type="full" />}
  </div>
}

function Initial({className}) {
  const { signatures: { initials: signature } } = useContext(SignatureContext)

  const classes = compact([
    'signature-initials',
    className
  ]).join(" ")

  return <div className={classes}>
  <div className="title">Initials</div>
  <div className="signature"><SignatureImage signature={signature} type="full" /></div>
  { signature ? <Buttons type="initials" /> : null }
  { signature ? null :  <AddButton label="Initials" type="initials" />}
  </div>
}

function DefaultSignature(props) {
  return <div className="row">
    <Signature className="col-xs-6"></Signature>
    <Initial className="col-xs-6"></Initial>
  </div>
}

const SignatureContext = React.createContext()

function SignatureProvider({children}) {
  const [ signatures, setSignatures ] = useState({})

  const value = useMemo(() => ({
    signatures,
    setSignatures,

    updateSignature: (type, signature) => new Promise((res, rej) => {
      setSignatures({...signatures, [type]: signature })
      res()
    }),

    removeSignature: type => {
      setSignatures(omit(signatures, [type]))
    }
   }), [signatures, setSignatures])


  return React.createElement(SignatureContext.Provider, { value }, children )
}

function Loader() {
  const [ loaded, setLoaded ] = useState(false)
  const { setSignatures } = useContext(SignatureContext)

  const url = '/user_signatures'

  const success = useCallback(async response => {
    const data = await response.json()
    setSignatures(data)
    setLoaded(true)
  }, [setLoaded, setSignatures])

  useEffect(() => {
    fetch(url).then(success)
  }, [])

  return loaded ? <DefaultSignature /> : <div className="loading"></div>
}

export default function() {
  return <SignatureProvider>

    <div className="user-settings-signatures-component">
      <Loader />
    </div>
  </SignatureProvider>
}
