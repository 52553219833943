import React from 'react'
import { castArray, compact } from 'lodash'

function Color({color, onClick, selected})  {
  const classes = compact([
    'color-item',
    selected ? 'selected-color' : null
  ])

  const style = {
    backgroundColor: color
  }

  return <div className={classes.join(" ")} style={style} onClick={onClick} />
}

export default function({field: {colors}, id, onChange, value, onBlur, className}) {
  const handleChange = color => onChange({[id]: color})
  const classes = compact([
    className
  ])

  return <div className={classes.join(" ")} onBlur={onBlur}>
    { castArray(colors).map(color => <Color key={color} color={color} onClick={() => handleChange(color)} selected={value == color} />)}
  </div>
}