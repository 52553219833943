import React, { useCallback, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectServiceFormState } from "../store/slice";
import { serviceValidationRules } from "../store/validates";
import NonBreakingTextArea from "./NonBreakingTextArea";
import CurrencyInput from "react-currency-input-field";
import Tooltip from "components/utility/Tooltip";

const NULL_FALLBACK = "null_fallback";

function BuildingServiceItem({
  service,
  sub_merchant_dropdown: building_sub_merchant_dropdown,
  onServiceChange,
  onServiceRemove,
  dragProvided,
}) {
  const handleChange = useCallback((key, value) => onServiceChange(service.id, key, value), [service.id]);
  const handleRemove = () => onServiceRemove(service.id);

  const form = useSelector((state) => selectServiceFormState(state, service.id));

  const isSubmerchantExist = useMemo(() => {
    return building_sub_merchant_dropdown.some(([_description, id]) => id === service.sub_merchant_id);
  }, [sub_merchant_dropdown, service.sub_merchant_id]);

  const sub_merchant_dropdown = useMemo(() => {
    if (service.sub_merchant_id === undefined) {
      return building_sub_merchant_dropdown.length === 0
        ? [["(No accounts)", null]]
        : [["", null], ...building_sub_merchant_dropdown];
    }

    if (isSubmerchantExist) {
      return building_sub_merchant_dropdown;
    }

    return [["(Account removed from Building)", service.sub_merchant_id], ...building_sub_merchant_dropdown];
  }, [isSubmerchantExist, service.sub_merchant_id]);

  /**
   * @param {string} input
   * */
  const handleAmountValueChange = (input) => {
    let value = input;

    if (input) {
      const centValue = parseInt(parseFloat(input) * 100);
      if (centValue === service.price) return;

      value = centValue;
    }

    handleChange("price", value);
  };

  /**
   * @param {Event} e
   * */
  const handleSubMerchantChange = (e) => {
    const value = e.currentTarget?.value;
    let subMerchantId;

    if (value === NULL_FALLBACK) {
      subMerchantId = null;
    } else {
      subMerchantId = value ? parseInt(value) : undefined;
    }

    handleChange("sub_merchant_id", subMerchantId);
  };

  return (
    <li
      className="building-edit-service-item"
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      style={dragProvided.draggableProps.style}
    >
      <div className="building-edit-service-item-title">
        <NonBreakingTextArea
          value={service.title}
          placeholder="Item Name"
          onChange={(e) => handleChange("title", e.currentTarget.value)}
        />
        {form?.errors?.title?.includes("presence") && <div style={{ color: "red" }}>Title is required</div>}
        {form?.errors?.title?.includes("max_length") && (
          <div style={{ color: "red" }}>
            Length exceeded: {service.title.length}/{serviceValidationRules.title.max_length} chars
          </div>
        )}
      </div>
      <div className="building-edit-service-item-account">
        <select
          value={service.sub_merchant_id == null ? NULL_FALLBACK : service.sub_merchant_id}
          onChange={handleSubMerchantChange}
        >
          {sub_merchant_dropdown.map(([description, id]) => (
            <option key={id} value={id === null ? NULL_FALLBACK : id}>
              {description}
            </option>
          ))}
        </select>
        {form?.errors?.sub_merchant_id?.includes("presence") && <div style={{ color: "red" }}>Account is required</div>}
      </div>
      <div className="building-edit-service-item-gl-code">
        <input
          type="text"
          value={service.gl_code}
          placeholder="GL Code"
          onChange={(e) => handleChange("gl_code", e.currentTarget.value)}
        />
      </div>
      <div className="building-edit-service-item-charge-code">
        <input
          type="text"
          value={service.charge_code}
          placeholder="Charge Code"
          onChange={(e) => handleChange("charge_code", e.currentTarget.value)}
        />
      </div>
      <div className="building-edit-service-item-amount">
        <CurrencyInput
          placeholder="Amount"
          defaultValue={service.price ? (service.price / 100).toFixed(2) : service.price}
          decimalsLimit={2}
          prefix="$"
          onValueChange={handleAmountValueChange}
        />
        {form?.errors?.price?.includes("presence") && <div style={{ color: "red" }}>Price is required</div>}
      </div>
      <div className="building-edit-service-item-variable-price text-right col-xs-6">
          <input
            type="checkbox"
            style={{ display: "inline-block" }}
            checked={service.has_variable_price || false}
            onChange={(e) => handleChange("has_variable_price", e.currentTarget.checked)}
          />
          <Tooltip
            placement="bottom"
            title="Has Variable Price?"
          >
            <i className="fa-light fa-circle-question"></i>
          </Tooltip>
      </div>
      <div>
        <div>
          <span {...dragProvided.dragHandleProps}>
            <i className="fa-solid fa-grip-vertical"></i>
          </span>
          <button onClick={handleRemove}>
            <i className="fa-light fa-xmark" style={{ color: "#B30000" }}></i>
          </button>
        </div>
      </div>
    </li>
  );
}

export default memo(BuildingServiceItem);
