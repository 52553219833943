import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import PropertyGoodsServices from "./PropertyGoodsServices";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInvoicePageState,
  setInitialState,
  addInvoiceService,
  updateInvoiceService,
  destroyInvoiceService,
  setActiveCategoryId,
} from "./store/slice";
import InvoiceSummary from "./InvoiceSummary";
import CategoriesFilter from "./CategoriesFilter";

export function InvoicePage(props) {
  const dispatch = useDispatch();

  const [readonly, setReadonly] = useState(false);

  useEffect(() => {
    dispatch(setInitialState(props));
  }, [dispatch]);

  const {
    categories,
    services,
    invoice,
    invoice_services,
    sub_merchants,
    category_services,
    activeCategoryId,
    addedServiceIds,
  } = useSelector(selectInvoicePageState);

  const onServiceQuantityChange = (serviceId, value) => {
    const invoiceService = invoice_services[serviceId];

    if (!invoiceService) {
      dispatch(addInvoiceService({ invoice_id: invoice.id, service_id: serviceId, quantity: value }));
    } else {
      if (value > 0) {
        dispatch(updateInvoiceService({ invoice_id: invoice.id, service_id: serviceId, quantity: value }));
      } else {
        dispatch(destroyInvoiceService({ invoice_id: invoice.id, service_id: serviceId }));
      }
    }
  };

  const onServicePriceChange = (serviceId, value) => {
    dispatch(updateInvoiceService({ invoice_id: invoice.id, service_id: serviceId, custom_price: value }));
  };

  const handleFilter = useCallback(
    (categoryId) => {
      dispatch(setActiveCategoryId(categoryId));
    },
    [dispatch]
  );

  const onInvoiceSent = useCallback(() => {
    setReadonly(true);
  }, []);

  if (!invoice) return null;

  return (
    <div className="container new-payment-page">
      <div className="row new-payment-page__nav-back">
        <a href="/resident_payments/invoices">&#60; Go Back</a>
      </div>
      <div className="row new-payment-page__body">
        <div className="col-md-7 new-payment-page__col goods-services">
          <div className="new-payment-page__col-container">
            <div className="goods-services__header">
              <span className="goods-services__header-text">Property Goods + Services</span>
            </div>
            <div className="goods-services__filter">
              <div className="goods-services__filter-title">Filter Categories</div>
              <div className="goods-services__filter-categories">
                <CategoriesFilter categories={categories} activeCategoryId={activeCategoryId} onFilter={handleFilter} />
              </div>
            </div>
            <div className="goods-services__categories">
              <PropertyGoodsServices
                activeCategoryId={activeCategoryId}
                categories={categories}
                services={services}
                invoice_services={invoice_services}
                sub_merchants={sub_merchants}
                invoice={invoice}
                onServiceQuantityChange={onServiceQuantityChange}
                onServicePriceChange={onServicePriceChange}
                category_services={category_services}
                readonly={readonly}
              />
            </div>
          </div>
        </div>
        <div className="col-md-5 new-payment-page__col invoice-summary">
          <div className="new-payment-page__col-container">
            <InvoiceSummary
              services={services}
              invoice_services={invoice_services}
              invoice={invoice}
              onServiceQuantityChange={onServiceQuantityChange}
              onServicePriceChange={onServicePriceChange}
              readonly={readonly}
              onInvoiceSent={onInvoiceSent}
              addedServiceIds={addedServiceIds}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

InvoicePage.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    building_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number,
    user_id: PropTypes.number,
    requested_by_id: PropTypes.number.isRequired,
    building: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
    unit: PropTypes.string.isRequired,
    note: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
    })
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      building_id: PropTypes.number.isRequired,
      organization_id: PropTypes.number,
      category_id: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      sub_merchant_info: PropTypes.string.isRequired,
    })
  ),
  invoice_services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      invoice_id: PropTypes.number.isRequired,
      service_id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
  sub_merchants: PropTypes.object,
};
