import React from 'react'
import PropTypes from 'prop-types'

import SearchSuggestionsContainer from './SearchSuggestionsContainer'
import Searchbar from './Searchbar'

class BuildingSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchSuggestionsHidden: false,
      preventSearchbarBlurEvent: false,
      customApplicationSuggestionCallback: null
    }
    this.setAppState = this.setAppState.bind(this)
  }

  componentDidMount() {
    if (this.props.clickHandlerNameForCustomAppSuggestion)
      this.buildClickHandlerForCustomAppSuggestion()
  }

  isLive() {
    return this.props.askLive ? this.state.liveOnly : true
  }

  renderSearchSuggestions() {
    if (this.state.searchText == "") return
    if (this.state.searchSuggestionsHidden) return

    const { getSuggestionsPath, buildingsPath, customApplicationImagePath, noCustomPackages } = this.props

    return <SearchSuggestionsContainer
      liveOnly={this.isLive()}
      searchText={this.state.searchText || ""}
      getSuggestionsPath={getSuggestionsPath}
      buildingsPath={buildingsPath}
      customApplicationImagePath={customApplicationImagePath}
      customApplicationSuggestionCallback={this.state.customApplicationSuggestionCallback}
      noCustomPackages={noCustomPackages}
      setAppState={this.setAppState}
    />
  }

  buildClickHandlerForCustomAppSuggestion() {
    this.setState({ customApplicationSuggestionCallback: window[this.props.clickHandlerNameForCustomAppSuggestion] })
  }

  setAppState(newState) {
    this.setState(newState)
  }

  renderHiddenFields() {
    const fields = []

    if (this.props.liveName)
      fields.push(<input type="hidden" name={this.props.liveName} value={this.isLive() ? "true" : "false"} key="live" />)

    return fields
  }

  render() {
    return (
      <div className="search-container">
        <Searchbar
          askLive={this.props.askLive}
          searchText={this.props.searchText}
          liveOnly={this.props.liveOnly}
          setAppState={this.setAppState}
          preventSearchbarBlurEvent={this.state.preventSearchbarBlurEvent}
          placeholderText={this.props.searchbarPlaceholderText}
          searchbarName={this.props.searchbarName}
          fromModal={this.props.fromModal || false} />
        {this.renderHiddenFields()}
        {this.renderSearchSuggestions()}
      </div>
    )
  }
}

BuildingSearch.propTypes = {
  getSuggestionsPath:         PropTypes.string.isRequired,
  buildingsPath:              PropTypes.string.isRequired,
  searchbarPlaceholderText:   PropTypes.string.isRequired,
  customApplicationImagePath: PropTypes.string.isRequired,
  fromModal:                  PropTypes.bool,
  clickHandlerNameForCustomAppSuggestion:  PropTypes.string,
  noCustomPackages:           PropTypes.bool,
  searchbarName:              PropTypes.string,
  searchText:                 PropTypes.string,
  liveOnly:                   PropTypes.bool
}

export default BuildingSearch