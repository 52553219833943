import React from 'react'
class Nothing extends React.Component {
  render() { return <div></div> }
}


let _stripe;
let _elements;

const stripe = () => {
if (!_stripe)
  _stripe = Stripe(Constants.StripeId)

return _stripe
}

const elements = () => {
if (!_elements)
  return _elements = stripe().elements();

return _elements
}

const is_blank = v => !v || v.match(/^\s*$/)
const label_from_card = card => `${card.type} ...${card.last_4} (Expires ${Constants.Months[parseInt(card.expires[0])].abbreviation} ${card.expires[1]})`

const ADDRESS_FIELDS = {
  address: "address_line1",
  suite: "address_line2",
  city: "address_city",
  state: "address_state",
  zip: "address_zip",
  country: "address_country"
}

const REQUIRED_FIELDS = ["name", "address", "city", "zip", "country"]

class StripeCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.card_error = "Please fill out all the credit card fields"
    this.state.errors = {}
    this.state.values = { country: "US" }

    if (this.cards().length == 0)
      this.state.payment_method = "new-card"
  }

  getCard() {
    if (this.card)
      return this.card

    this.card = elements().create('card', {hidePostalCode: true})
    this.card.on("change", event => this.checkCardErrors(event))
    return this.card
  }

  cards() {
    return this.props.cards || []
  }

  componentDidMount() {
    const card = this.cards()[0]
    this.setPaymentMethod(card ? card.id : "new-card")

    if (this.props.onlyRenderForm) {
      this.setPaymentMethod('new-card')
    }
  }

  componentDidUpdate() {
    if (this.refs.card) {
      if (!this.card_mounted)
        this.getCard().mount(this.refs.card)

      this.card_mounted = true
    } else
      this.card_mounted = false
  }

  componentWillUnmount() {
    if (this.refs.card && this.card_mounted) {
      this.card.destroy()
      delete this.card
    }
  }

  country() {
    return this.state.values.country
  }

  setValue(name, value) {
    const values = Object.assign({}, this.state.values)
    const errors = Object.assign({}, this.state.errors)
    values[name] = value

    delete errors[name]
    delete errors.payment_method

    this.setState({ values, errors })
  }

  setError(error, message) {
    const errors = Object.assign({}, this.state.errors)
    errors[error] = message
    this.setState({errors})
  }

  checkCardErrors(evt) {
    const errors = Object.assign({}, this.state.errors)

    let card_error = null
    if (evt.error)
      card_error = evt.error.message
    else if (!evt.complete)
      card_error = "Please fill out all the credit card fields."

    delete errors.card

    this.setState({errors, card_error})
  }

  checkErrors() {
    const errors = {}

    if (this.state.card_error)
      errors.card = this.state.card_error

    for (let field of REQUIRED_FIELDS)
      if (is_blank(this.state.values[field]))
        errors[field] = "This is a required field"

    this.setState({errors})
    return Object.keys(errors).length > 0
  }

  generateToken() {
    const details = { name: this.state.values.name }
    for (let key in ADDRESS_FIELDS)
      details[ADDRESS_FIELDS[key]] = this.state.values[key]

    return new Promise((resolve, reject) => {
      if (this.checkErrors())
        return reject()

      const errored = message => {
        this.setError("payment_method", message)
        reject(message)
      }

      const callback = ({token, error}) => token ? resolve(token.id) : errored(error.message)
      stripe().createToken(this.card, details).then(callback)
    })
  }

  //method above only returns token.id, this will return entire token object
  //(can then grab card details if needed)
  generateAllTokenDetails() {
    const details = { name: this.state.values.name }
    for (let key in ADDRESS_FIELDS)
      details[ADDRESS_FIELDS[key]] = this.state.values[key]

    return new Promise((resolve, reject) => {
      if (this.checkErrors())
        return reject()

      const errored = message => {
        this.setError("payment_method", message)
        reject(message)
      }

      const callback = ({token, error}) => token ? resolve(token) : errored(error.message)
      stripe().createToken(this.card, details).then(callback)
    })
  }

  onChangePaymentMethod(evt) {
    this.setPaymentMethod(is_blank(evt.target.value) ? null : evt.target.value)
  }

  setPaymentMethod(payment_method) {
    this.setState({payment_method})

    if (typeof this.props.onChangePaymentMethod == "function")
      this.props.onChangePaymentMethod(payment_method)
  }

  render() {
    return <div className="utility-stripe-card-component">
      {!this.props.onlyRenderForm && this.renderCards()}
      {this.renderNewCard()}
    </div>
  }

  renderCards() {
    const classes = ["form-group"]
    const select_options = (this.props.cards || []).map(card => {
      const date = [Constants.Months[parseInt(card.expires[0]) - 1].abbreviation, card.expires[1]].join(" ")
      const label = `${card.type} ... ${card.last_4} (Expires ${date})`
      return <option key={card.id} value={card.id}>{label}</option>
    })

    select_options.push(<option key="new-card" value="new-card">Add a New Card</option>)

    return <div className="form-line">
      <div className={classes.join(" ")}>
        <label className="form-label">payment method</label>
        <select className="form-control" onChange={e => this.onChangePaymentMethod(e)}>
          {select_options}
        </select>
      </div>
    </div>
  }

  renderNewCard() {
    if(this.state.payment_method != "new-card")
      return

    const classes = ["btn", "btn-success", "btn-lg", "btn-block"]

    const states_list = Object.keys(Constants.US_States)
      .filter(key => Constants.US_States[key].country == this.country())
      .map(key =>
        this.props.state_abbreviations
          ? [Constants.US_States[key].name, key]
          : [key, Constants.US_States[key].name]
      )
    const country_list = Constants.Countries.map(country => [country.code, country.name])
    const states = this.renderSelect("state", states_list)
    const countries = this.renderSelect("country", country_list, this.country())
    const show_state_select = Object.values(Constants.US_States).some(state => state.country == this.country())

    return <div className="new-card">
      <div className="form-line">
        {this.renderFormElement("name", "cardholder name")}
      </div>

      <div className="form-line">
        {this.renderCardElement()}
      </div>

      <div className="form-line">
      {this.renderFormElement("country", "country", {input: countries })}
      </div>

      <div className="form-line">
        {this.renderFormElement("address", "address", {classes: ["address"]})}
        {this.renderFormElement("suite", "floor / suite", {classes: ["suit"]})}
      </div>

      <div className="form-line">
        {this.renderFormElement("city", "city", {classes: ["city"]})}
        {this.renderFormElement("state", "state", {input: show_state_select ? states : null, classes: ["state"]})}
        {this.renderFormElement("zip", "postal / zip", {classes: ["zip"]})}
      </div>
    </div>
  }

  renderCardElement() {
    const classes = ["the-card", "form-control"]
    if (this.state.errors.card)
      classes.push("is-invalid")

    const error = this.state.errors.card ? <small className="invalid-feedback">{this.state.errors.card}</small> : null
    return  <div className="form-group">
      <label className="form-label">card information<span className="app-type-star">*</span></label>
      <div className={classes.join(" ")} ref="card"></div>
      {error}
    </div>
  }

  renderSelect(id, values, default_value, options = {}) {
    const classes = ["form-control"]
    if (this.state.errors[id])
      classes.push("is-invalid")

    const onChange = options.onChange || (e => this.setValue(id, e.target.value))
    const html_options = values.map(opt => <option key={opt[0]} value={opt[0]}>{opt[1]}</option>)

    return <select name={id} className={classes.join(" ")} onChange={onChange} defaultValue={default_value}>
      {html_options}
    </select>
  }

  renderInput(id, options = {}) {
    const classes = ["form-control"]
    if (this.state.errors[id])
      classes.push("is-invalid")

    const onChange = options.onChange || (e => this.setValue(id, e.target.value))
    return <input name={id} className={classes.join(" ")} onChange={onChange} />
  }

  renderFormElement(id, label, options = {}) {
    let classes = ["form-group"]
    if (options.classes)
      classes = classes.concat(options.classes)

    const error = this.state.errors[id] ? <small className="invalid-feedback">{this.state.errors[id]}</small> : null
    const description = options.description ? <small className="text-muted">{options.description}</small> : null

    return <div className={classes.join(" ")}>
      <label className="form-label">{label}{REQUIRED_FIELDS.some(field => field === id) ? <span className="app-type-star">*</span> : null}</label>
      { options.input || this.renderInput(id, options) }
      { error }
      { description }
    </div>
  }
}


export default StripeCard
