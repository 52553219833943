import React, { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'

import Group from './group'
import PersonModal from './edit_person'

const AssignPermissionsContext = React.createContext()

function PermissionProvider({children}) {
  const permissions = useSelector(({roles}) => roles.current_user_permissions)

  const value = useMemo(() => {
    return {
      everyoneIsSelectable: permissions.write.includes("party-overrides")
    }
  }, [permissions])

  return <AssignPermissionsContext.Provider value={value}>
    {children}
  </AssignPermissionsContext.Provider>
}


function Groups() {
  const groupOrder = useSelector(({roles}) => roles.group_order)
  return <div className="groups">
    <h3>Assigned</h3>
    <p className="description">Roles assigned to participants will autofill the documents.</p>
    { groupOrder.map(group_id => <Group key={group_id} group_id={group_id} /> )} 
  </div>
}

export function usePermissions() {
  return useContext(AssignPermissionsContext)
}

export default function() {
  return <PermissionProvider>
    <div className="package-assign-roles-component">
      <Groups />
      <PersonModal />
    </div>
  </PermissionProvider>
}