import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getResumeStateApi } from './apis'

export const fetchQuoteResume = createAsyncThunk(
  'instantInterstitial/fetchResumeState',
  async ({ quote_uuid }, thunkAPI) => {
    try {
      const response = await getResumeStateApi(quote_uuid)
      return response.data
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data)
      } else {
        throw error
      }
    }
  }
)

const initialState = {
  form: {},
  quoteResumable: false,
  quote_uuid: '',
  building: null,
  package_id: null,
  insuranceType: 'homeowners',
  error: {},
  quote: {},
  quote_request: {},
}

const instantInterstitialSlice = createSlice({
  name: 'instantInterstitial',
  initialState,
  reducers: {
    resetState: (_state, _action) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuoteResume.fulfilled, (state, action) => {
      // TODO: Check condition
      const { quote, quote_request } = action.payload
      state.form = quote.form
      state.building = action.payload.building
      state.package_id = quote.package_id
      state.insuranceType = quote.insurance_type
      state.quoteResumable = true
      state.quote_uuid = action.meta.arg.quote_uuid
      state.error = quote_request.error
      state.quote = quote
      state.quote_request = quote_request
    })
  },
})

export const selectInstantInterstitialState = (state) => state.instantInterstitial

export default instantInterstitialSlice.reducer
