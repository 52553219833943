import axios from 'axios'

/**
 * An axios instance does not have the csrf token attached.
 * This axios instance might be used when calling external apis, when the csrf token is not necessary.
 * */
const pureInstance = axios.create()

// Add a request interceptor
pureInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    NProgress.start()
    return config
  },
  function (error) {
    // Do something with request error
    NProgress.done()
    // console.error(error);
    return Promise.reject(error)
  }
)

// Add a response interceptor
pureInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    NProgress.done()
    return response
  },
  function (error) {
    // Do something with response error
    NProgress.done()
    // console.error(error);
    return Promise.reject(error)
  }
)

export default pureInstance;
