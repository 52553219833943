import React, { useCallback } from 'react'
import FAQ from 'components/utility/FAQ'

export default function InsuranceFAQ(props) {
  const onToggleQuestion = useCallback((question, open) => {
    if (open) {
      window.dataLayerPush({
        event: 'insurance_page_faq_open',
        question: question.question,
      })
    }
  }, [])

  return <FAQ {...props} onToggleQuestion={onToggleQuestion} />
}
