import React from 'react'

const DataListField = props => {
  const { options, placeholder, name, handleUpdate, required, value } = props

  const renderOptions = () => {
    const list = []

    for (let i = 0; i < options.length; i++) {
      list.push(
        <option key={i} value={options[i]}>{options[i]}</option>
      )
    }

    return list
  }

  return <>
    <input list={`data-list-for-${name}`}
           className='form-control'
           onChange={event => handleUpdate(event.target.value)}
           placeholder={placeholder || ''}
           required={required || false}
           defaultValue={value || ''}
           name={name} />
    <datalist id={`data-list-for-${name}`}>
      {renderOptions()}
    </datalist>
  </>
}

export default DataListField
