import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}


export default class EnableDisclaimer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      disclaimerText: this.props.disclaimer_text || "",
      finishedUpdating: false
    }
  }

  changeValue(isDisclaimerEnabled) {
    const url = `/super/organizations/${this.props.organization_id}/questionnaires/enable_disclaimer`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({ is_disclaimer_on: isDisclaimerEnabled ? 1 : 0 }),
      success: () => this.props.updateIsDisclaimerEnabled(isDisclaimerEnabled)
    })
  }

  renderChoices() {
    return <select defaultValue={this.props.isDisclaimerEnabled ? "1" : "0"}
      onChange={e => this.changeValue(parseInt(e.target.value) == 1)}>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  }

  saveDisclaimerText() {
    this.setState({ finishedUpdating: false })
    const url = `/super/organizations/${this.props.organization_id}/questionnaires/update_disclaimer_text`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({ disclaimer_text: this.state.disclaimerText }),
      success: () => this.setState({ finishedUpdating: true })
    })
  }

  renderSaveDisclaimerButton() {
    return <>
      <button onClick={() => this.saveDisclaimerText()}>Save Disclaimer</button>
      <p>{this.state.finishedUpdating ? "Done" : ""}</p>
    </>
  }

  renderDisclaimerTextBox() {
    return <>
      <textarea
        className="disclaimer-textbox"
        value={this.state.disclaimerText || null}
        onChange={(e => this.setState({ disclaimerText: e.target.value }))}
      >

      </textarea>

      {this.renderSaveDisclaimerButton()}<br />
    </>

  }

  render() {
    return (
      <>
        <div className="row ">
          <div className="col-sm-4">Enable Disclaimer Message?</div>
          <div className="col-sm-1">
            {this.renderChoices()}
          </div>
          <br /><br />
          <div className="col-sm-4">
            {this.props.isDisclaimerEnabled ? this.renderDisclaimerTextBox() : null}
          </div>
        </div>
      </>
    )
  }
};
