import React from 'react'
import PropTypes from 'prop-types'

import FetchButton from './fetch_button'
import RejectButton from './reject_button'
import RejectModal from './reject_modal'

class SuperAdminContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id="super-admin-refund-container">
        <div className="text-align-right">
          <span id='reject-refund-container'>
            <RejectButton />
          </span>
          <span id='fetch-refund-container'>
            <FetchButton approveRefundRequestPath={this.props.approveRefundRequestPath} />
          </span>
        </div>
        <RejectModal rejectRefundRequestPath={this.props.rejectRefundRequestPath}/>
      </div>
    )
  }
}

SuperAdminContainer.propTypes = {
  rejectRefundRequestPath: PropTypes.string.isRequired,
  approveRefundRequestPath: PropTypes.string.isRequired
}

export default SuperAdminContainer
