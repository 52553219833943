import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const prefix = 'dome-'

const defaultClasses = {
  wrapper: prefix + 'interstitial-wrapper',
  backdrop: prefix + 'interstitial-backdrop',
  content: prefix + 'interstitial-content',
  activated: prefix + 'interstitial-activated',
}

function DomecileInterstitial({
  show,
  hiding,
  onClose,
  staticBackdrop,
  wrapperClasses,
  backdropClasses,
  contentClasses,
  children,
}) {
  useEffect(() => {
    document.body.classList.toggle(defaultClasses['activated'], show)
  }, [show])

  const handleClose = () => {
    if (staticBackdrop) return

    if (typeof onClose === 'function') onClose()
  }

  return (
    <div
      className={clsx(defaultClasses['wrapper'], wrapperClasses, {
        opened: show,
        hiding: hiding,
        'static-backdrop': staticBackdrop,
      })}
    >
      <div className={clsx(defaultClasses['backdrop'], backdropClasses)} onClick={handleClose}></div>
      <div className={clsx(defaultClasses['content'], contentClasses)}>{children}</div>
    </div>
  )
}

DomecileInterstitial.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  staticBackdrop: PropTypes.bool,
  wrapperClasses: PropTypes.arrayOf(PropTypes.string),
  backdropClasses: PropTypes.arrayOf(PropTypes.string),
  contentClasses: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
}

DomecileInterstitial.defaultProps = {
  show: false,
  onClose: () => {},
  staticBackdrop: false,
  wrapperClasses: [],
  backdropClasses: [],
  contentClasses: [],
}

export default DomecileInterstitial
