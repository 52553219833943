import React, { Component } from "react";
import MakeRequest from "./MakeRequest";
import DisclaimerMessage from "./DisclaimerMessage";

function getDisclaimer({ organization_id, building_id }) {
  const url = `/questionnaire_requests/check_for_disclaimer/${organization_id}/${building_id}`;

  return new Promise((res, rej) => {
    const error = (xhr) => {
      try {
        const errorJson = JSON.parse(xhr.responseText);
        rej(errorJson.error);
      } catch (e) {
        rej("A server error has occurred");
      }
    };

    $.ajax({
      url,
      success: (data) => res(data),
      error,
    });
  });
}

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showDisclaimerMessage: false,
      submitted: false,
      building: this.props.building,
    };
  }

  componentDidMount() {
    this.modal = $(this.refs.modal);
    this.modal.on("hidden.bs.modal", () =>
      this.setState({ submitted: false, agreedToDisclaimer: false })
    );

    this.checkForDisclaimer();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.organization.id != prevProps.organization.id ||
      this.props.building?.id != prevProps.building?.id
    )
      this.checkForDisclaimer();
  }

  checkForDisclaimer() {
    const requestOptions = {
      organization_id: this.props.organization.id,
      building_id: this.props.building?.id,
    };

    const setDisclaimer = ({ disclaimer }) =>
      this.setState({ disclaimer, loading: false, agreedToDisclaimer: false });

    this.setState({ loading: true });
    getDisclaimer(requestOptions)
      .then(setDisclaimer)
      .catch(() => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    delete this.modal;
  }

  showLoading() {
    return this.state.loading;
  }

  showDisclaimer() {
    if (this.state.loading || this.state.submitted) return false;

    return this.state.disclaimer && !this.state.agreedToDisclaimer;
  }

  showForm() {
    if (this.state.loading || this.state.submitted) return false;

    return (
      this.state.agreedToDisclaimer ||
      !this.props.organization.enable_disclaimer
    );
  }

  showComplete() {
    if (this.state.loading) return false;

    return this.state.submitted;
  }

  renderLoading() {
    if (!this.showLoading()) return null;

    // Maybe a better loading screen?
    return "Loading...";
  }

  renderDisclaimer() {
    if (
      !this.showDisclaimer() ||
      !this.props.organization.enable_disclaimer ||
      this.state.agreedToDisclaimer
    )
      return null;

    return (
      <DisclaimerMessage
        onAgreed={() => this.setState({ agreedToDisclaimer: true })}
        disclaimer={this.state.disclaimer}
        showDisclaimerMessage={this.state.showDisclaimerMessage}
        onCancel={() => this.modal.modal("hide")}
      />
    );
  }

  renderForm() {
    if (!this.showForm()) return null;

    return (
      <MakeRequest
        building={this.props.building}
        organization={this.props.organization}
        onSubmit={() => this.setState({ submitted: true })}
        onCancel={() => this.modal.modal("hide")}
      />
    );
  }

  renderComplete() {
    if (!this.showComplete()) return null;

    return (
      <div className="questionnare-submitted">
        <p>
          Your request for a questionnaire has been sent to property management.
        </p>
        <p>
          You will receive a confirmation email, then a follow-up email
          indicating cost and payment procedure.
        </p>

        <div className="buttons">
          <button
            className="green-btn-white-text"
            onClick={() => this.modal.modal("hide")}
          >
            OK
          </button>
        </div>
      </div>
    );
  }

  returnHeader() {
    if (
      !this.props.organization.enable_disclaimer ||
      this.state.agreedToDisclaimer
    )
      return "Request a Questionnaire";
    else return null;
  }

  render() {
    return (
      <>
        <div
          className="modal fade questionnaire-modal"
          data-backdrop="static"
          id={this.props.id || "questionnaire-request-modal"}
          ref="modal"
        >
          <div className="modal-dialog modal-md questionnaire-modal-dialog">
            <div className="modal-body">
              <h3 className="text-center bopa-text questionnaire-form-header">
                {this.state.submitted ? (
                  <div>
                    <div>
                      <i className="fa-regular fa-circle-check"></i>
                    </div>
                    <span>Success!</span>
                  </div>
                ) : (
                  this.returnHeader()
                )}
              </h3>
              <div className="questionnaire-request-container">
                {this.renderLoading()}
                {this.renderDisclaimer()}
                {this.renderForm()}
                {this.renderComplete()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
