import { createSlice, configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { findIndex } from 'lodash'

const stepsByMode = {
  create: {
    steps: ['contact-details', 'roles-and-permissions', 'requirements', 'review', 'success'],
    submitStep: 'review'
  },
  edit_requirements: {
    steps: ['requirements', 'success'],
    submitStep: 'requirements'
  }
}


const formSlice = createSlice({
  name: 'form',
  initialState: {
    currentStepIndex: 0,
    steps: ['contact-details', 'roles-and-permissions', 'requirements', 'review', 'success'],
    submitStep: 'review',
    formData: {},
    mode: 'create'
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStepIndex = action.payload
    },
    jumpToStepName: (state, action) => {
      const stepIndex = state.steps.findIndex(step => step == action.payload)

      if (stepIndex > -1)
        state.currentStepIndex = stepIndex
    },
    setFormData: (state, action) => {
      state.formData = {...state.formData, ...action.payload}
    },
    setVisibleRequirements: (state, action) => {
      const requirementsPayload = Array.isArray(action.payload) ? action.payload : action.payload ? [action.payload] : []
      state.formData = { ...state.formData, requirement_visibility: requirementsPayload }
    },
    nextStep: (state, action) => {
      state.currentStepIndex = state.currentStepIndex + 1
    },
    prevStep: (state) => {
      state.currentStepIndex = state.currentStepIndex - 1
    },
    setMode: (state, action) => {
      if (state.mode !== action.payload)
        state.currentStepIndex = 0
      state.mode = action.payload
      state.steps = stepsByMode[action.payload].steps
      state.submitStep = stepsByMode[action.payload].submitStep
    },
    clear: (state) => {
      state.currentStepIndex = 0
      state.steps = ['contact-details', 'roles-and-permissions', 'requirements', 'review', 'success']
      state.formData = { }
      state.mode = 'create'
    }
  }
})

const tableSlice = createSlice({
  name: 'table',
  initialState: {
    users: []
  },
  reducers: {
    setUsers(state, action) {
      state.users = action.payload
    },
    addUser(state, action) {
      state.users = [ ...state.users, action.payload ]
    },
    updateUserVisibleRequirements(state, { payload: { email, requirement_visibility } }) {
      const userIndex = findIndex(state.users, user => user.email == email)
      const user = state.users[userIndex]
      state.users = Object.assign([], state.users, { [userIndex]: { ...user, requirement_visibility: requirement_visibility.map(id => parseInt(id)) }})
    },
    removeUser(state, { payload: { email } }) {
      state.users = state.users.filter(user => user.email != email)
    }
  }
})

const rootReducer = combineReducers({
  form: formSlice.reducer,
  table: tableSlice.reducer,
})

export const actions = {
  ...formSlice.actions,
  ...tableSlice.actions,
}


export default configureStore({
  reducer: rootReducer
})
