import React from "react";
import PropTypes from "prop-types";

export default function TableBody({ columns, items, renderActions }) {
  return (
    <tbody className="text-grey">
      {items.map((item) => {
        return (
          <tr className="row" key={item.id}>
            {columns.map((column) => (
              <td key={column.accessor}>
                {typeof column.transformFn === "function"
                  ? column.transformFn(item)
                  : item[column.accessor]}
              </td>
            ))}
            <td>
              <div className="actions">{renderActions(item)}</div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

TableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};
