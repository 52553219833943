import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPropertyTransfer,
  fetchAccounts as fetchAccountsAction,
  setAccountValues as setAccountValuesAction,
} from 'components/property_transfer/store/slice'
import TransferAccountsSection from './TransferAccountsSection'

const TransferAccountsTable = () => {
  const dispatch = useDispatch()
  const { buildingAccounts, buildingAccountsFetching, buildingId, accountValues } =
    useSelector(selectPropertyTransfer)

  useEffect(() => {
    if (buildingId) {
      dispatch(fetchAccountsAction({ buildingId }))
    }
  }, [dispatch, buildingId])

  const onValueSelect = useCallback(
    (key, value) =>
      dispatch(setAccountValuesAction({ source: key, destination: value })),
    [dispatch]
  )

  if (buildingAccountsFetching) return 'Loading...'
  if (!buildingAccounts?.olds || !buildingAccounts?.current) return null
  if (buildingAccounts.current.accounts.length === 0)
    return "The currents org doesn't have accounts setup."
  if (buildingAccounts.olds.length === 0)
    return 'All accounts were already transferred!'

  const { olds, current } = buildingAccounts

  return (
    <>
      {olds.map((old_org) => {
        return (
          <TransferAccountsSection
            key={old_org.id}
            source={old_org}
            destination={current}
            values={accountValues}
            onValueSelect={onValueSelect}
          />
        )
      })}
    </>
  )
}

export default TransferAccountsTable
