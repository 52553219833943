import React, {Component} from 'react'
import BuildingSearch from 'components/utility/building_search'

class MainSearch extends Component {
  constructor(props) {
    super(props)
  }

  redirectToUrl(e) {
    if (e.text_url)
      window.location.href = `/buildings/${e.text_url}`
  }

  render() {
    return(<BuildingSearch
      customApplicationImage={this.props.customApplicationImage}
      onSelect={e => this.redirectToUrl(e)}
      show_magnifying_glass={this.props.show_magnifying_glass}
      show_empty_message={this.props.show_empty_message}
      placeholderText={this.props.placeholderText}
      clickHandlerNameForCustomAppSuggestion={this.props.clickHandlerNameForCustomAppSuggestion || "showCustomApplicationModalWithSearchedText"}
      classes={this.props.classes}
      reversed={this.props.reversed}
      askLive={this.props.askLive}
      liveOnly={this.props.liveOnly}
      allow_custom={this.props.allowCustom !== false}
    />)

  }
}

export default MainSearch