import React, { useState, useEffect, useCallback } from 'react'
import LoginForm from './LoginForm'
import CheckEmailModal from './CheckEmailModal'
import FlashMessage from './FlashMessage'

const ComponentsWrapper = (props) => {
  const [isComponentOpen, setIsComponentOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  const { screenFilter: filter } = props

  useEffect(() => {
    if (!filter)
      return

    window[filter][isComponentOpen ? 'freezeHomeScreen' : 'unFreezeHomeScreen']()
  }, [isComponentOpen, filter])

  const closeComponent = useCallback(() => setIsComponentOpen(false), [setIsComponentOpen])

  return (
    <>
      <FlashMessage classes='display-only-desktop-flex' display='display-only-desktop' flashMessage={props.flashMessages} />

      <div className='login-popdown-hidden display-only-desktop'>
        <div className={`login-component ${isComponentOpen ? 'open-login' : ''}`}>
          <LoginForm flashMessages={props.flashMessages} closeOnClick={closeComponent} updateEmailState={setUserEmail} updateParentState={setIsModalOpen} modalState={isModalOpen} />
        </div>
      </div>
      {isModalOpen && <CheckEmailModal envelope={props.icons.envelope} email={userEmail} updateParentState={setIsModalOpen} />}
    </>
  )
}

export default ComponentsWrapper
