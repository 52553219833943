import React, { Component } from "react"
export default class SubmitModal extends Component {
  constructor(props) {
    super(props)

    this.modal = null

    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    this.open()
  }

  componentWillUnmount() {
    this.close()
  }

  close() {
    $(this.modal).modal('hide')
  }

  open() {
    $(this.modal).modal('show')
  }

  renderSubmitPriceModal(){
    return (  
      <div className="questionnaire-modal-container">
        <p>A request for a payment has been sent.
        You will be notified once payment has been processed.
        You may upload the requested documents then.</p>
        
        <div className="questionnaire-modal-center">
          <button className="questionnaire-pricing-btn-submit" onClick={() => this.props.closeSubmitModal()}>OK</button>
        </div>
      </div>
      )
  }

  render(){
    return (
      <div className="modal" ref={this.setModalRef}>
        <div className="modal-dialog modal-md questionnaire-modal-dialog">
          <div className="modal-body">
            <h3 className="text-center bopa-text questionnaire-form-header">Success!</h3>
                {this.renderSubmitPriceModal()}
          </div>
        </div>
      </div>
    )
  }
};
