import React, { Component } from "react"
import SetQuestionnaireRequestPrice from './Response/SetQuestionnaireRequestPrice'
import FulfillQuestionnaireRequest from './Response/FulfillQuestionnaireRequest'
import UploadConfirmationModal from './Response/UploadConfirmationModal'
import UploadModal from './Response/UploadModal'
import CancelConfirmationModal from "./Response/CancelConfirmationModal"

export default class Response extends Component {
  constructor(props) {
      super(props)

      this.state = {
          has_uploaded_files: this.props.completed_forms && this.props.completed_forms.length > 0,
          showUploadModal: false,
          showConfirmModal: false,
          showCancelledModal: false,
          isArchived: this.props.questionnaire_request.is_archived,
          questionnaire_request: this.props.questionnaire_request
      }

  }

  emailUploadedFiles(){
    const hash = this.state.questionnaire_request.hashed_id
    const url = `/questionnaire_requests/${hash}/send_completed_questionnaire_pdf`
    const type = "post"

    const data = {}
    data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")

    const success = () => {this.toggleUploadModal()}

    $.ajax({ url, type, data, success })

    if (this.props.auto_archive) {
      this.archiveQuestionnaireRequest()
    }

  }

  toggleUploadModal(){
      this.setState({
        showUploadModal: !this.state.showUploadModal,
        showConfirmModal: false
      })
    }

  toggleConfirmModal(){
    this.setState({showConfirmModal: !this.state.showConfirmModal})
  }

  renderArchiveButton(){
    this.setState({isArchived: !this.state.isArchived})
  }


  render() {
      return (
        <div>
          <div className="questionnaire-request-response">
            <SetQuestionnaireRequestPrice
                  stepNumber={this.props.first_step}
                  questionnaire_request={this.state.questionnaire_request}
                  possible_prices={this.props.possible_prices}
                  questionnaire_price={this.state.questionnaire_price}
                  preset_price={this.props.preset_price}
                  auto_pricing={this.props.auto_pricing}
                  onUpdatedPrice={questionnaire_request => this.setState({questionnaire_request})}
                  />
          </div>
            <br />
          <div className="questionnaire-request-response">
          <FulfillQuestionnaireRequest
                  stepNumber={this.props.first_step + 1}
                  questionnaire_request={this.state.questionnaire_request}
                  completed_forms={this.props.completed_forms}
                  onCompletedFormsUpdate={num_forms => this.setState({has_uploaded_files: num_forms > 0})}
                  />
          </div>
            <br/>
              <div className="questionnaire-buttons-div">
                {this.renderReturnToDashboard()}
                {this.renderSubmitButton()}
                {this.renderUnArchiveButton()}
                {this.renderCancelButton()}
              </div>
                            
              {this.renderConfirmModal()}
              {this.renderUploadModal()}
              {this.renderCancelModal()}
        </div>
      )
  }

  renderConfirmModal() {
      if (this.state.showConfirmModal) {
        return <UploadConfirmationModal formName={this.props.completed_forms} confirmQuestionnaire={() => this.emailUploadedFiles()} closeConfirmModal={() => this.toggleConfirmModal()}/>
      }
    }

  renderUploadModal() {
    if (this.state.showUploadModal) {
      return <UploadModal location={this.props.user_signed_in ? "/questionnaire_requests/list" : "/" } closeUploadModal={() => this.toggleUploadModal()}/>
      }
  }

  renderCancelModal() {
    if (!this.state.showCancelledModal) 
      return

    return <CancelConfirmationModal id={this.state.questionnaire_request.hashed_id} onClose={() => this.setState({showCancelledModal: false})} onCancel={() => this.redirectToDashboard()} />
  }

  redirectToDashboard() {
    window.location.href = this.props.user_signed_in ? "/questionnaire_requests/list" : "/"
  }

  renderReturnToDashboard() {
    return (
      <div>
        <button className="btn btn-secondary btn-block"
          onClick={() => this.redirectToDashboard()}>
          Return to Dashboard
        </button>
      </div>
    )
  }

  renderCancelButton() {
    if (this.state.questionnaire_request.is_cancelled)
      return

    const handleClick = () => this.setState({showCancelledModal: true})
    
    return (
      <div>
        <button className="btn btn-danger btn-block" onClick={handleClick}>
          Cancel
        </button>
      </div>
    )
  }


  archiveQuestionnaireRequest(){
    const hash = this.state.questionnaire_request.hashed_id
    const token = this.state.questionnaire_request.purchaser_token
    const url = `/questionnaire/${hash}/archive_questionnaire/${token}`
    const type = "post"

    const data = {}
    data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")

    const success = () => window.location.reload(false);
    $.ajax({ url, type, data, success })
  }

  unarchiveQuestionnaireRequest(){
    const hash = this.state.questionnaire_request.hashed_id
    const token = this.state.questionnaire_request.purchaser_token
    const url = `/questionnaire/${hash}/unarchive_questionnaire/${token}`
    const type = "post"

    const data = {}
    data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")

    const success = () => window.location.reload(false);
    $.ajax({ url, type, data, success })
  }

  renderUnArchiveButton() {
    const isArchived = this.state.isArchived

    if (isArchived) {
      return (
        <button className="btn btn-cancel btn-new-success" onClick={() => this.unarchiveQuestionnaireRequest()}>
          Un-Archive Questionniare
        </button>
      )
    }
  }


  renderSubmitButton() {
    const views = this.state.questionnaire_request.is_cancelled || this.state.questionnaire_request.is_archived
    const classes = ["btn-success", "btn", "btn-new-success"]
    const archivable = this.state.questionnaire_request.archivable && this.props.user_signed_in
    const disabled = !this.state.has_uploaded_files


    if (views)
      return (<div></div>)

    if (this.props.auto_archive && this.props.user_signed_in) {
      return (
        <div>
          <button className={classes.join(" ")} disabled={!this.state.has_uploaded_files} onClick={() => this.emailUploadedFiles()}>
            Send & Archive Questionnaire
          </button>
        </div>

        )
    } else {
        return (
          <div>
            <button className={classes.join(" ")} disabled={disabled} onClick={() => this.emailUploadedFiles()}>
              Send Questionnaire
            </button>

            <button className={classes.join(" ")} disabled={!archivable} onClick={() => this.archiveQuestionnaireRequest()}>
              Archive Questionnaire
            </button>
          </div>
        )
    }
  }
};


