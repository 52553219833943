import React from 'react'

export default function ContractorReportFormInfo({ handleClick }) {
  return (
    <div className="dome-d-flex dome-flex-column dome-gap12">
      <div className="dome-h4 dome-color-green-gradient">vet your contractor</div>
      <div className="dome-p2 dome-color-med-grey">
        A professional + reliable report ensures that a contractor is vetted to your expectations.
      </div>
      <div className="dome-d-flex dome-justify-between dome-align-center">
        <div className="dome-d-flex dome-gap3 dome-align-baseline">
          <span className="dome-h4 dome-color-dark-green">$150</span>
          <span className="dome-p3 dome-color-med-grey dome-pl-3">per report</span>
        </div>
        <button type="button" className="btn-base btn-dark-green" onClick={handleClick}>
          get started
        </button>
      </div>
    </div>
  )
}
