import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

export default class QuestionnaireProcessingTime extends Component {
  constructor(props) {
    super(props)
  }

  updateProcessingTime() {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/questionnaire_processing_time_to_complete`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({time: this.props.processingTime})
    })
  }

  renderInput() {
    return <div className='input-group'>
      <input type="text"
             className="form-control"
             onChange={e => this.props.updateProcessingTime(e.target.value)}
             defaultValue={this.props.processingTime}
             placeholder="No Limit" />
      <span className="input-group-addon">days</span>
    </div>
  }

  renderSaveProcessingTime() {
    return <button type="button" onClick={() => this.updateProcessingTime()}>Update</button>
  }

  render() {
    return <div className="row questionnaire-admin-row">
      <div className="col-sm-4">Questionnaire Processing Time</div>
      <div className="col-sm-3">
        {this.renderInput()}
      </div>
      <div className="col-sm-4">
        {this.renderSaveProcessingTime()}
      </div>
    </div>
  }
};