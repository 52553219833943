import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import BuildingSearch from "components/utility/building_search";
import { useCallback } from "react";

function DisplayBuilding({ title, onFocus, disabled }) {
  return (
    <input
      className="form-control"
      type="text"
      defaultValue={title || ""}
      onFocus={onFocus}
      disabled={disabled}
      placeholder="find your property"
    />
  );
}

const SUGGESTIONS_URL = "/resident_payments/invoices/building_suggestions";
const PROPERTY_CLASSES = {
  inputClass: "form-control",
};

function useEditBuilding(onChange) {
  const [building, setBuilding] = useState(null);
  const [editing, setEditing] = useState(true);

  const assignBuilding = useCallback(
    (b) => {
      setBuilding(b);
      setEditing(false);
      if (typeof onChange === "function") onChange(b);
    },
    [setBuilding, setEditing, onChange]
  );

  return {
    building,
    editing,
    setEditing,
    assignBuilding,
  };
}

function PropertySearch({ disabled, onChange, defaultBuilding }) {
  const { building, editing, setEditing, assignBuilding } = useEditBuilding(onChange);
  const buildingTitle = building?.title || building?.address;

  useEffect(() => {
    if (!defaultBuilding) return;

    assignBuilding(defaultBuilding);
  }, []);

  const handleSelect = useCallback(
    (b) => {
      if (b.id) return assignBuilding(b);

      if (b.query?.length < 2) return assignBuilding(null);

      if (building) return setEditing(false);
    },
    [assignBuilding, building, setEditing]
  );

  return (
    <>
      {editing ? null : <DisplayBuilding disabled={disabled} title={buildingTitle} onFocus={() => setEditing(true)} />}
      {editing ? (
        <BuildingSearch
          url={SUGGESTIONS_URL}
          focusOnMount
          query={buildingTitle}
          classes={PROPERTY_CLASSES}
          show_empty_message={true}
          onSelect={handleSelect}
        />
      ) : null}
    </>
  );
}

function ControlledPropertySearch({ control, defaultBuilding, onBuildingSelected, rules = {} }) {
  const rendering = ({ field: { onChange } }) => {
    const handleChangeBuilding = (building) => {
      onChange(building?.id);
      onBuildingSelected(building);
    };

    return (
      <PropertySearch disabled={rules.disabled} onChange={handleChangeBuilding} defaultBuilding={defaultBuilding} />
    );
  };
  return <Controller control={control} name="building_id" rules={rules} render={rendering} />;
}

ControlledPropertySearch.propTypes = {
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  defaultBuilding: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
};

ControlledPropertySearch.defaultProps = {
  rules: {},
};

/**
 * Purpose: return a PropertySearch component which can be reseted.
 * Interal implementation: A version is a random number, then added to dependency of useCallback function.
 * Every time value of the version is changed, a whole new component is returned, replacing the previous component.
 *
 * */
export function useControllerPropertySearch() {
  const [version, setVersion] = useState(0);

  const reset = useCallback(() => setVersion(Math.random()), [setVersion]);
  const component = useCallback((props) => <ControlledPropertySearch {...props} />, [version]);

  return { component, reset };
}
