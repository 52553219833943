import React from 'react'
import { isPlainObject } from 'lodash'

function toOptions(hash) {
  if (isPlainObject(hash))
    return Object.entries(hash)
  
  if (Array.isArray(hash))
    return hash.map(item => [item, item])

  return []
}

export default function({field, options, id, onChange, value, onBlur, className, multiple }) {
  const handleChange = e => onChange({[id]: e.target.value})

  const props = {
    value: value || "", 
    onChange: handleChange,
    onBlur,
    multiple
  }

  
  const select_options = toOptions(field.options || options)
  select_options.unshift(["", field.placeholder])

  return <div className={className}>
    <select {...props}>
      { select_options.map(([value, label]) => <option value={value} key={value}>{label}</option>) }
    </select>
  </div>
}