import React from 'react'

class MultiSelect extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
		this.state.optionsOpen = false
		this.state.options = this.defaultValue()

		this.state.show_default = true

		this.handleClickOption = this.handleClickOption.bind(this)
		this.handleClickSelectBox = this.handleClickSelectBox.bind(this)

		this.handleClickWindow = this.handleClickWindow.bind(this)
		this.handleClickSelectArea = this.handleClickSelectArea.bind(this)
	}

	defaultValue() {
		return this.props.defaultValue ? this.ensureArray(this.props.defaultValue).filter(key => this.props.options[key]) : []
	}

	default() {
		const selected = this.defaultValue(),
					all_match = this.state.options.every(option => selected.includes(option)),
					same_amount = this.state.options.length == selected.length

		if (all_match && same_amount)
			return this.figureSelectBoxText()

		this.setState({options: selected}, () => this.figureSelectBoxText())
	}

	clear() {
		this.setState({options: []})
		this.figureSelectBoxText()
	}

	ensureArray(item) {
		return $.isArray(item) ? item : [item]
	}

	componentDidMount() {
		$(window).on("click", this.handleClickWindow)

		this.select_box_element = $(this.refs.select_box)
		this.display_element = $(this.refs.display)

		this.container_element = $(this.refs.container)
		this.container_element.data("react", this)

		// Give some time to get this on the screen
		setTimeout(() => this.figureSelectBoxText(), 50)
	}

	componentWillUnmount() {
		$(window).off("click", this.handleClickWindow)

		this.container_element.data("react", null)

		delete this.select_box_element
		delete this.display_element
		delete this.container_element
	}

	figureSelectBoxText(selected) {
		if (!selected)
			selected = this.selectedOptionKeys()

		const all_options = this.options()
		const items = selected.map(key => all_options[key]).sort()
		const temp_container = $("<SPAN></SPAN>").css({visibility: "hidden", position: "absolute", left: "0", top: "0"})

		this.iterateFigureSelectBoxText(items, selected.length, temp_container)
	}

	itemsToDisplayText(items, total_items) {
		return items.join(", ") + (items.length < total_items ? ` + ${total_items - items.length}` : "")
	}

	iterateFigureSelectBoxText(items, total_selected, element) {
		const text = this.itemsToDisplayText(items, total_selected)
		element.html(text)
		this.display_element.append(element)

		setTimeout(() => {
			// Ensure that even if React did something, we add the box back in.
			if (!this.display_element)
				return

			const good_width = element.width() <= this.display_element.width()
			const good_height = element.height() <= this.display_element.height()

			if (items.length > 1 && !(good_width && good_height))
				return this.iterateFigureSelectBoxText(items.slice(0, -1), total_selected, element)

			element.remove()
			this.setState({display_text: text})
		}, 5)
	}

	options() {
		return this.props.options || {}
	}

	isOptionsEmpty() {
		return this.options().length == 0
	}

	maxSelected() {
		const max = parseInt(this.props.max || 0)
		return max && max >= 0 ? max : 0
	}

	handleClickSelectBox() {
		this.setState({optionsOpen: !this.state.optionsOpen})
	}

	handleClickWindow(evt) {
		if (!this.refs.container.contains(evt.target) && this.refs.container != evt.target)
			this.setState({optionsOpen: false})
	}

	handleClickSelectArea(evt) {
		evt.reactMultiSelect = this
	}

	renderSelectBox() {
		const display_text = this.state.options.length == 0 ? this.props.placeholder || <span className="nothing">Nothing Selected</span> : this.state.display_text

		return <div className="select-box" onClick={this.handleClickSelectBox} ref="select_box">
			<div className="display" ref="display">{display_text}</div>
			<i className="fa fa-caret-down" />
		</div>
	}

	selectedOptionKeys() {
		return this.state.options
	}

	selectOption(key) {
		const max = this.maxSelected()
		if (max > 1 && max == this.state.options.length)
			return

		const options = (max == 1) ? [] : this.state.options.concat([])
		options.push(key)

		this.setState({options: options})

		return options
	}

	unselectOption(key) {
		const options = this.state.options.concat([]),
					index = options.indexOf(key)

		if(!this.canHaveZeroOptions() && options.length == 1)
			return

		options.splice(index, 1)

		this.setState({options: options})

		return options
	}

	isOptionKeySelected(key) {
		return this.state.options.some(option => String(option) == String(key))
	}

	canHaveZeroOptions() {
		return this.maxSelected() != 1 || this.props.multiple
	}

	handleClickOption(key) {
		const options = this.isOptionKeySelected(key) ? this.unselectOption(key) : this.selectOption(key)

		if (this.maxSelected() == 1 || this.props.closeOnSelect)
			this.setState({optionsOpen: false})

		if (!options)
			return

		if (options.length > 0)
			this.figureSelectBoxText(options)

		if (this.props.onChange)
			this.props.onChange(options)
	}

	renderOption(key, value) {
		const classes = ["option"], checkboxClasses = []
		if (this.isOptionKeySelected(key)) {
			classes.push("checked")
			checkboxClasses.push("fa", "fa-check")
		}

		return <div className={classes.join(" ")} key={key} onClick={() => this.handleClickOption(key)}>
			<i className={checkboxClasses.join(" ")} />
			<div>{value}</div>
		</div>
	}

	renderOptions() {
		if (!this.state.optionsOpen)
			return ""

		const options = this.options(),
					option_html = Object.keys(options).map(key => this.renderOption(key, options[key])),
					height = this.select_box_element.outerHeight() * 5,
					style = { maxHeight: height, width: this.select_box_element.outerWidth() }

		return <div className="options-container" style={style}>
			{this.isOptionsEmpty() ? "There are no options" : option_html}
		</div>
	}

	renderInputs() {
		if (!this.props.name)
			return ""

		return this.selectedOptionKeys().map(key => <input type="hidden" name={this.props.name} value={key} key={key} />)
	}

	render() {
		return <div className="multi-select react-multi-select" onClick={this.handleClickSelectArea} ref="container">
			{this.renderSelectBox()}
			{this.renderOptions()}
			{this.renderInputs()}
		</div>
	}
}

export default MultiSelect