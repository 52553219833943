import $ from 'jquery'
import React from 'react'
import PropTypes from 'prop-types'

import { cacheToNamespace } from 'lib/utilities'

const FORM_LIST_CACHE = "SuperAdminTools.TemplateFormUpdater.FormsList"
function getFormsList(url) {
  return new Promise((res, rej) => {
    $.ajax({
      url,
      dataType: "json",
      type: "get",
      success: data => res(data),
      error: xhr => rej(xhr)
    })
  })
}

class FormsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      formsList: {},
      formIdsOrder: [],
      selectedFormId: null
    };
  }

  componentDidMount() {
    this.__setFormsList()
  }

  async __setFormsList() {
    const callback = async () => await getFormsList(this.props.formsListPath)
    const result = await cacheToNamespace(FORM_LIST_CACHE, callback)

    this.setState({
      formsList: result.forms,
      formIdsOrder: result.form_ids_order,
      loading: false
    })
  }

  handleClick(id) {
    const form = this.state.formsList[id]
    const for_original = this.props.formFor == "original",
          for_replacement = this.props.formFor == "replacement"

    const state = {
      formListOpenForType: null,
      [for_original ? "originalForm" : for_replacement ? "replacementForm" : "selectedForm"]: form
    }

    this.props.setAppState(state)
  }

  renderFormsList() {
    if (this.state.loading)
      return <p className="loading-dots"></p>

    return this.state.formIdsOrder.map(id => {
      const {name, organization_name} = this.state.formsList[id]

      return <div key={id} className="row form-row" onClick={() => this.handleClick(id)} >
        <div className="col-xs-1">{id}</div>
        <div className="col-xs-7">{name}</div>
        <div className="col-xs-4">{organization_name}</div>
      </div>
    })

  }

  render() {
    return (
      <div className="forms-list-container">
        <div className="row">
          <div className="col-xs-1"><h4>ID</h4></div>
          <div className="col-xs-7"><h4>Name</h4></div>
          <div className="col-xs-4"><h4>Organization</h4></div>
        </div>
        <div className="forms-list">
          { this.renderFormsList() }
        </div>
      </div>
    )
  }
}

FormsList.propTypes = {
  formsListPath: PropTypes.string.isRequired,
  selectedFormId: PropTypes.number,
  formFor: PropTypes.string.isRequired
}

export default FormsList
