import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  active: '',
  previous: ''
}

const toolSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setActive(state, { payload: selected}) {
      state.previous = state.previous ? state.active : selected
      state.active = selected
    },

  }
})

export const { load, setActive } = toolSlice.actions
export default toolSlice.reducer
