import React, { useContext, useEffect, useState, useCallback, useMemo, Fragment, createContext } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import MiniSearch from 'minisearch'
import { difference, filter, first, partition } from 'lodash'

import { useWatchableRef } from 'lib/hooks'

import { viewContext } from '../../context/viewContext'
import { RecipientProvider, RecipientSyncer, useRecipients } from '../../context/recipientContext'

import { VIEWS } from './views'
import { useCreateThreadMutation, useFetchParticipantsQuery } from '../api/apiSlice'
import Header from '../../common/Header'
import { ChoiceChip } from '../../common/ChoiceChip'
import { useClasses } from 'lib/utilities/react'
import { is } from 'date-fns/locale'
import { yearsToQuarters } from 'date-fns'

const filterGroups = recipients => recipients.filter(recipient => !recipient.user)
const filterUsers = recipients => recipients.filter(recipient => recipient.user)

const SelectAll = ({unselected, addAllToSelected, isFetching}) => {
  const { selectRecipients, allRecipients, selectedRecipients } = useRecipients()

  const groups = useMemo(() => filterGroups(allRecipients), [allRecipients])
  const allSelected = useMemo(() => groups.every(group => selectedRecipients.includes(group)))
  const selectAll = useCallback(() => selectRecipients(groups.map(group => group.value)), [ selectRecipients, groups ])

  return (
    <>
      {(!isFetching && !allSelected) && <button onClick={selectAll} className='dome-btn dome-btn-link dome-btn-link-med-grey'>+ select all</button>}
    </>
  )
}


const selectedRemoveIcon = <i className="fal fa-multiply dome-color-red-alert dome-text-w500" />
function IndividualSelectedRecipient({recipient}) {
  const { usersByRole, deselectRecipients } = useRecipients()

  const handleClick = useCallback(() => deselectRecipients([recipient.value]), [deselectRecipients, recipient.value])

  return <ChoiceChip  classes='chip-selected' 
                      choiceChipData={recipient} 
                      userList={usersByRole[recipient.value]} 
                      onClick={handleClick} 
                      appendedIcon={selectedRemoveIcon}
                      alwaysShowIcon />
}

const SelectedRecipientsType = ({type, recipients, tooltip}) => {
  const [ hovering, setHovering ] = useState(false)
  const [ visibility, setVisibility ] = useState('none')

  const isHidden = visibility == 'none'
  const isFading = visibility == 'fading'
  const isShown = visibility == 'show'

  const mouseIn = useCallback(() => setHovering(true), [setHovering])
  const mouseOut = useCallback(() => hovering ? setHovering(false) : null, [hovering, setHovering])

  useEffect(() => {
    if (hovering) 
      return setVisibility('show')

    const timeout = setTimeout(() => setVisibility('fading'), 200)
    return () => clearTimeout(timeout)
  }, [hovering, setVisibility])

  useEffect(() => {
    if (!isFading) 
      return

    const timeout = setTimeout(() => { setVisibility('none') }, 400)
    return () => clearTimeout(timeout)
  }, [visibility, setVisibility])

  const tooltipClasses = useClasses([
    "tooltip-dropdown dome-bg-white dome-rounded-border dome-dark-grey-border dome-padding6 dome-color-darkest-grey",
    "dome-fadable",
    isShown ? "dome-opaque" : "dome-faded"
  ])

  return <div className="selected-recipients dome-d-flex dome-align-start dome-gap6">
    <div className="seleted-recipients-title dome-color-dark-green dome-text-w500 dome-relative" onMouseLeave={mouseOut}>
      <div className="dome-d-flex dome-align-center dome-gap6">
        <span>{type}</span>
        { tooltip ? <i className="fa-light fa-circle-info dome-cursor-pointer" onMouseEnter={mouseIn}></i> : null }
      </div>
      { tooltip && !isHidden && <div className={tooltipClasses}>{tooltip}</div>}
    </div>
    <div className="dome-d-flex dome-align-center dome-gap6 dome-flex-wrap">
      { recipients.map(recipient => <IndividualSelectedRecipient key={recipient.value} recipient={recipient} />)}
    </div>
  </div>
}

const GroupTooltip = ({text}) => (<div>
  <h4 className="dome-h4">what are groups?</h4>
  <p>{ text || "This message can be seen by all people with the selected groups' roles and access to this section" }</p>
</div>)

const UserTooltip = ({text}) => (<div>
  <h4 className="dome-h4">what is this?</h4>
  <p>{ text || "This message will only be sent to the individuals you selected. Additionally, they will only have access if they also have access to this section." }</p>
</div>)

const SelectedRecipients = () => {
  const { selectedRecipients } = useRecipients()

  const groups = useMemo(() => filterGroups(selectedRecipients), [ selectedRecipients ])
  const users = useMemo(() => filterUsers(selectedRecipients), [ selectedRecipients ])

  return (
    <div className="dome-d-flex dome-gap9 dome-relative dome-flex-column" style={{ maxWidth: 'calc(100% - 50px)' }}>
      { groups.length > 0 && <SelectedRecipientsType type="groups" recipients={groups} tooltip={<GroupTooltip />} /> }
      { groups.length > 0 && users.length > 0 && <hr className="dome-hr selected-recipients-divider" />}
      { users.length > 0 && <SelectedRecipientsType type="people" recipients={users} tooltip={<UserTooltip />} /> }
    </div>
  )
}

const UnselectedGroups = () => {
  const { unique_owner_id } = useContext(viewContext)
  const { isFetching } = useFetchParticipantsQuery({ unique_owner_id }, {refetchOnMountOrArgChange: true})
  const { allRecipients, selectedRecipients, selectRecipients, usersByRole } = useRecipients()

  const unselectedGroups = useMemo(() => difference(filterGroups(allRecipients), selectedRecipients), [allRecipients, selectedRecipients])
  if (unselectedGroups.length == 0)
    return null


  return (
    <div className="quick-add-container dome-d-flex dome-align-start dome-gap6">
      <div className="dome-color-green-gradient" style={{padding: "0 6px"}}>
        <i className="fal fa-sparkle" /> add groups
      </div>
      <div className="dome-d-flex dome-align-center dome-gap6 dome-flex-wrap" style={{maxWidth: '700px'}}>
        { !isFetching && (
          unselectedGroups.map((group) => {
            return <ChoiceChip
              key={group.value}
              choiceChipData={group}
              userList={usersByRole[group.value]}
              onClick={() => selectRecipients([group.value])}
              prependedIcon={
                <i className="far fa-plus" style={{fontSize: '10px'}} />
              }
            />
          })
        )}
      </div>
    </div>
  )
}

const search_options = {
  prefix: true,
  fuzzy: 0.2,
  boost: { path: 2 }
}

function useRecipientSearch() {
  const { allRecipients } = useRecipients()

  return useMemo(() => {
    const search = new MiniSearch({
      fields: ['label', 'role_label'],
      storeFields: ['label', 'role_label', 'value']
    })

    search.addAll(allRecipients)
    return search
  }, [allRecipients])
}

function AutosuggestItem({recipient}) {
  const { selectRecipients, deselectRecipients, selectedRecipients } = useRecipients()

  const isSelected = useMemo(() => selectedRecipients.includes(recipient), [selectedRecipients, recipient])
  const handleClick = useCallback(() => {
    isSelected ? deselectRecipients([recipient.value]) : selectRecipients([recipient.value])
  }, [isSelected, recipient, deselectRecipients, selectRecipients])

  return <div onClick={handleClick} className='dome-d-flex dome-align-start dome-gap9'>
    <input className="dome-checkbox " type="checkbox" checked={isSelected} value={1} onChange={() => {}} />
    <div>
      <div className='dome-p2-lt'>{recipient.label}</div>
      { recipient.role_label && <div className="secondary dome-p4 dome-color-med-grey">{recipient.role_label}</div> }
    </div>
  </div>
}

const isBlank = val => !val || val.match(/^\s*$/)

function AutosuggestItems({ label, icon, items }) {
  if (items.length == 0)
    return null

  return <div className="dome-d-flex dome-flex-column dome-gap12">
    <div className="dome-color-dark-green dome-d-flex dome-align-center dome-gap6">
      { icon }
      <span>{label}</span>
      <hr className="dome-hr dome-flex-1" />  
    </div>
    { items.map(recipient => <AutosuggestItem key={recipient.id} recipient={recipient} />) }
  </div>
}

function AutosuggestDropdown({query, onClose}) {
  const { allRecipients, recipientsHash, selectedRecipients, selectRecipients, deselectRecipients } = useRecipients()
  const search = useRecipientSearch()

  const results = useMemo(() => search.search(query, search_options).map(({id}) => recipientsHash[id]), [ search, query, recipientsHash ])
  const options = useMemo(() => isBlank(query) ? allRecipients : results, [query, allRecipients, results])

  const groups = useMemo(() => filterGroups(options), [ options ])
  const users = useMemo(() => filterUsers(options), [ options ])

  const allOptionsSelected = useMemo(() => difference(options, selectedRecipients).length == 0, [options, selectedRecipients])

  const clickAll = useCallback(() => {
    const values = options.map(recipient => recipient.value)
    allOptionsSelected ? deselectRecipients(values) : selectRecipients(values)
  }, [options, allOptionsSelected, selectRecipients, deselectRecipients])

  return <div className="dome-dropdown dome-bg-white dome-rounded-border dome-dark-shadow">
    <div className="dropdown-options dome-scrollable dome-gap3 dome-d-flex dome-flex-column dome-gap12 dome-bg-fill dome-padding6-12">
      <AutosuggestItems label="groups" items={groups} icon={<i className="fa-light fa-users"></i>} />
      <AutosuggestItems label="users" items={users} icon={<i className="fa-light fa-user"></i>} />
    </div>
    <div className="dome-d-flex dome-justify-between dome-align-center dome-bg-white dome-gap40 dome-padding6-12">
      <div onClick={clickAll} className="dome-d-flex dome-align-center dome-gap9 dome-checkbox">
        <input type="checkbox" className="dome-checkbox" checked={allOptionsSelected} value={1} onChange={() => {}} />
        <span>select all ({options.length})</span>
      </div>
      <div className="dome-d-flex dome-align-center dome-gap12">
        {selectedRecipients.length} selected
        <button className="dome-btn dome-btn-base dome-btn-aqua-stroke " onClick={onClose}>done</button>
      </div>
    </div>
  </div>
}

function AutosuggestUsers() {
  const { unique_owner_id } = useContext(viewContext)
  const { isFetching } = useFetchParticipantsQuery({ unique_owner_id }, {refetchOnMountOrArgChange: true})
  const [ query, setQuery ] = useState('')
  const [ open, setOpen ] = useState(false)

  const watchedRef = useWatchableRef()

  const handleFocus = useCallback(() => setOpen(true), [setOpen])
  const handleClose = useCallback(() => setOpen(false), [setOpen])

  useEffect(() => {
    if (!open || !watchedRef.current) return

    const clickEverywhere = (evt) => {
      if (!evt.target.isConnected) return
      if (watchedRef.current.contains(evt.target)) {
        return
      }

      setOpen(false)
    }

    document.addEventListener('click', clickEverywhere)
    return () => document.removeEventListener('click', clickEverywhere) 
  }, [watchedRef.version, open, setOpen])

  return (
    <div className="dome-relative dome-full-width dome-align-center" ref={watchedRef.ref}>
      <div className="dome-input-search" onFocus={handleFocus}>
        <input placeholder="search by name or role" type="text" className="dome-flex-1" value={query} onChange={e => setQuery(e.target.value)} />
        { isFetching ? <i className="fa-duotone fa-spinner fa-spin-pulse" /> : <i className="fa-solid fa-magnifying-glass"></i> }
      </div>
      { open && !isFetching && <AutosuggestDropdown query={query} onClose={handleClose} />}
    </div>
  )
}

const RecipientSelector = ({ page }) => {
  const { unique_owner_id } = useContext(viewContext);
  const { isFetching } = useFetchParticipantsQuery({ unique_owner_id }, {refetchOnMountOrArgChange: true})

  return (
    <div className='dome-d-flex dome-flex-column dome-gap9'>
      <div className='dome-d-flex dome-align-baseline dome-justify-start dome-gap12'>
        <span>to:</span>
        <AutosuggestUsers />
      </div>
      
      <SelectedRecipients />
    </div>
  )
}

const ThreadComposer = ({ setInputText, page }) => {
  const handleChange = (e) => {
    setInputText(e.target.value)
  }

  return (
    <div className='thread-composer-body dome-d-flex dome-flex-column dome-gap12'>
      <RecipientSelector page={page} />
      <textarea placeholder='type your message here...' name="messagebox" id="messagebox" onChange={handleChange}></textarea>
    </div>
  )
}

const ThreadActionFooter = ({ inputText, handleSend, isLoading, isSuccess }) => {
  const { setCurrentView } = useContext(viewContext)
  const { selectedRecipients } = useRecipients()

  const isDisabled = selectedRecipients.length == 0 || !inputText && inputText.length === 0

  const classes = ['dome-btn', 'dome-btn-base', 'dome-btn-base-fit-content']
  classes.push(isDisabled ? 'dome-btn-disabled' : 'dome-btn-go-green')

  const buttonIconClasses = []
  buttonIconClasses.push(isLoading ? 'fal fa-spinner fa-spin-pulse' : 'fal fa-paper-plane-top')

  const handleCancel = () => setCurrentView(VIEWS.LIST_VIEW)

  useEffect(() => {
    if (isSuccess) {
      setCurrentView(VIEWS.LIST_VIEW)
    }
  }, [isSuccess])

  return (
    <div className='thread-action-footer dome-d-flex dome-align-center dome-justify-between'>
      <button onClick={handleCancel} className="dome-btn dome-btn-link dome-btn-link-cancel">
        cancel
      </button>
      <button onClick={handleSend} className={classes.join(' ')}>
        <i className={buttonIconClasses}/>
      </button>
    </div>
  )
}

const NewThreadView = ({ page }) => {
  const { currentView, unique_owner_id, threadsPerPage } = useContext(viewContext)
  const [inputText, setInputText] = useState('')
  const [createThread, { isLoading, isSuccess }] = useCreateThreadMutation()
  const { selectedRecipients } = useRecipients()

  const handleSend = useCallback(() => {
    if (selectedRecipients.length == 0 || inputText.length == 0) return

    const newMessage = {
      message: inputText,
      unique_owner_id,
      participants: selectedRecipients.map(recipient => recipient.value),
      per_page: threadsPerPage,
      page: 1,
    }

    createThread(newMessage)
  }, [inputText, selectedRecipients])

  if (currentView !== VIEWS.NEW_MESSAGE_VIEW) return

  return (
    <div className='dome-d-flex dome-flex-column dome-gap12'>
      <Header headerTitle='new message' />
      <ThreadComposer setInputText={setInputText} page={page} />
      <ThreadActionFooter inputText={inputText} handleSend={handleSend} isLoading={isLoading} isSuccess={isSuccess} />
    </div>
  )
}

export default (props) => (
  <RecipientProvider>
    <RecipientSyncer />
    <NewThreadView {...props} />
  </RecipientProvider>
)
