import { useCallback, useEffect, useMemo, useState } from 'react'
import { get } from 'lodash-es'

export default function useSortableTable(data, defaultSortField) {
  const [tableData, setTableData] = useState(data)
  const [sortField, setSortField] = useState(defaultSortField)
  const [sortOrder, setSortOrder] = useState('asc')

  const handleSorting = useCallback((sortField, sortOrder) => {
    setSortField(sortField)
    setSortOrder(sortOrder)
  }, [])

  const clearSorting = useCallback(() => handleSorting(null, 'asc'), [handleSorting])

  useEffect(() => {
    let newData = data

    if (sortField) {
      newData = [...data].sort((a, b) => {
        if (get(a, sortField) === get(b, sortField) || (get(a, sortField) === null && get(b, sortField) === null))
          return 0
        if (get(a, sortField) === null) return 1
        if (get(b, sortField) === null) return -1

        return (
          get(a, sortField).toString().localeCompare(get(b, sortField).toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      })
    }

    setTableData(newData)
  }, [data, sortField, sortOrder])

  return useMemo(
    () => ({
      tableData,
      handleSorting,
      sortField,
      clearSorting,
      sortOrder,
    }),
    [tableData, handleSorting, sortField, clearSorting, sortOrder]
  )
}
