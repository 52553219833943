import React from "react"

class Paginate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: props.currentPage,
    }
  }
  pageUpdated(stateName, newValue) {
    const state = { ...this.state }
    state[stateName] = parseInt(newValue)
    this.setState(state, () => this.updateParent())
  }

  updateParent() {
    this.props.onClick(this.state.page)
  }

  renderNextButton() {
    const { totalPages, currentPage } = this.props
    if (totalPages <= 1 || typeof totalPages === "undefined") return null
    if (currentPage === totalPages)
      return (
        <li className='disabled'>
          <a>
            Next <span>&#8594;</span>
          </a>
        </li>
      )

    const nextPage = currentPage + 1

    return (
      <li>
        <a
          href='javascript:void(0)'
          onClick={e => this.pageUpdated("page", nextPage)}
        >
          Next <span>&#8594;</span>
        </a>
      </li>
    )
  }

  renderPreviousButton() {
    const { totalPages, currentPage } = this.props
    if (
      (currentPage === 1 && typeof totalPages === "undefined") ||
      totalPages <= 1
    )
      return null
    if (currentPage === 1)
      return (
        <li className='disabled'>
          <a>
            <span>&#8592;</span> Previous
          </a>
        </li>
      )

    const previousPage = currentPage - 1

    return (
      <li>
        <a
          href='javascript:void(0)'
          onClick={e => this.pageUpdated("page", previousPage)}
        >
          <span>&#8592;</span> Previous
        </a>
      </li>
    )
  }

  renderPageButtons(pageNumber) {
    const { totalPages } = this.props

    if (pageNumber < 0 || pageNumber > totalPages || totalPages <= 1)
      return null
    if (pageNumber === this.props.currentPage)
      return (
        <li key={pageNumber} className='active'>
          <span>{pageNumber}</span>
        </li>
      )

    return (
      <li key={pageNumber} className='page-item'>
        <a
          href='javascript:void(0)'
          onClick={e => this.pageUpdated("page", e.target.innerText)}
        >
          {pageNumber}
        </a>
      </li>
    )
  }

  render() {
    const pageButtons = []
    const { totalPages, currentPage } = this.props
    let page = 0

    for (; page < Math.min(3, currentPage - 3); page++) {
      pageButtons.push(this.renderPageButtons(page + 1))
    }
    if (page + 1 < currentPage - 3) {
      pageButtons.push(
        <li>
          <span key='break-1'>...</span>
        </li>
      )
    }
    for (
      page = Math.max(0, currentPage - 3);
      page <= Math.min(totalPages, currentPage + 3);
      page++
    ) {
      pageButtons.push(this.renderPageButtons(page + 1))
    }
    if (page < totalPages - 3) {
      pageButtons.push(
        <li>
          <span key='break-2'>...</span>
        </li>
      )
    }
    for (page = Math.max(page, totalPages - 3); page <= totalPages; page++) {
      pageButtons.push(this.renderPageButtons(page + 1))
    }

    return (
      <div className='row pagination-links'>
        <ul className='pagination'>
          {this.renderPreviousButton()}
          {pageButtons}
          {this.renderNextButton()}
        </ul>
      </div>
    )
  }
}

export default Paginate