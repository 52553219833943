import React from 'react'
import PropTypes from 'prop-types'

export default class ExistingForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formId: props.form.form_id,
      formTitle: props.form.form_title,
      updating: false
    }

  }

  toggleSignOnly() {
    const form = { ...this.props.form }
    this.setState({ updating: true })
    $.ajax({
      url: '/buildings/' + this.props.buildingId + '/update_lease_form',
      method: "POST",
      dataType: "JSON",
      context: this,
      data: {
        building_form_id: this.props.form.id,
        sign_only: !form.sign_only,
      },
      success: function (res) {
        if (res.refresh_all_lease_forms)
          this.props.refreshForms()

        form.sign_only = !form.sign_only
        this.props.updateForm(form)
        this.setState({ updating: false })
      }
    })
  }

  canRemoveForm() {
    return this.isACustomForm()
  }

  canEditForm() {
    if (this.props.form.form_id == null) return false

    return this.isACustomForm()
  }

  isACustomForm() {
    return !this.props.form.organization_id && !this.props.form.copied_from_lease_form_id
  }

  showExistingForm() {
    return (
      <div className="row" id={`edit-building-${this.props.selector}-view-${this.props.form.id}`} >
        <div className="col-md-1">
          {this.sortingArrows()}
        </div>
        <div className="col-md-2 text-left">
          {this.state.formId}
        </div>
        <div className="col-md-3 text-left">
          {this.state.formTitle}
        </div>
        <div className="col-md-2 text-right">
          {this.editFormButton()}
        </div>
        <div className="col-md-2">
          {this.renderSignOnlyButton()}
        </div>
        <div className="col-md-2">
          {this.renderIncludeOrExcludeButton()}
        </div>
      </div>
    )
  }

  editExistingForm() {
    if (this.props.included) {
      return (
        <div className={`row edit-building-${this.props.selector}`} data-id={this.props.form.id} id={`edit-building-${this.props.selector}-form-${this.props.form.id}`} style={{ display: "none" }}>
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <input
              name='form_id'
              type="number"
              min="1"
              className="form-control"
              value={this.state.formId}
              onChange={event => this.updateValue('form_id', event.target.value)}
            />
          </div>
          <div className="col-md-5">
            <input
              name='form_title'
              type="text"
              className="form-control"
              value={this.state.formTitle}
              onChange={event => this.updateValue('form_title', event.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button className='btn btn-success lease-form-button' onClick={() => this.updateBuildingForm()}>Update</button>
          </div>
          <div className="col-md-2">
            <button className='btn btn-default lease-form-button' onClick={() => this.cancelFormEditing()}>Cancel</button>
          </div>
        </div>
      )
    }
  }

  sortingArrows() {
    if (this.props.included) {
      return (
        <div className="up-down-arrows text-bopa text-center">
          <i className='fas fa-sort-alt'></i>
        </div>
      )
    }
  }

  renderIncludeOrExcludeButton() {
    const btnText = this.canRemoveForm() ? 'Remove' : this.props.included ? 'Exclude' : 'Include'

    return <button
      className='btn btn-default lease-form-button'
      onClick={() => this.manageForm()}>{btnText}
    </button>
  }

  renderSignOnlyButton() {
    const toggledOn = this.props.form.sign_only
    const styles = {}
    if (toggledOn) {
      styles['backgroundColor'] = '#898989'
      styles['color'] = 'white'
    }

    return <button
      className='btn btn-default lease-form-button'
      onClick={() => this.toggleSignOnly()}
      disabled={this.state.updating}
      style={styles}>
      Sign Only
    </button>
  }

  manageForm() {
    if (this.canRemoveForm()) {
      this.deleteBuildingForm()
    } else {
      this.manageOrgForm()
    }
  }

  deleteBuildingForm() {
    $.ajax({
      url: '/buildings/' + this.props.buildingId + '/delete_lease_form',
      method: "DELETE",
      dataType: "JSON",
      data: { form_id: this.props.form.id },
      context: this,
      success: function (res) {
        this.props.deleteForm(this.props.form.id)
      }
    })
  }

  manageOrgForm() {
    this.props.toggleIncluded(this.props.form.id)
  }

  editFormButton() {
    if (this.canEditForm()) {
      return (
        <button className='btn btn-default lease-form-button' onClick={() => this.editForm()}>Edit</button>
      )
    }
  }

  editForm() {
    $('#edit-building-' + this.props.selector + '-view-' + this.props.form.id).hide()
    $('#edit-building-' + this.props.selector + '-form-' + this.props.form.id).show()
  }

  cancelFormEditing() {
    $('#edit-building-' + this.props.selector + '-view-' + this.props.form.id).show()
    $('#edit-building-' + this.props.selector + '-form-' + this.props.form.id).hide()
    this.setState({ form_title: this.props.form.form_title, form_id: this.props.form.form_id })
  }

  updateFormIdAndTitle(formId, formTitle) {
    const form = { ...this.props.form }

    form.formId = formId
    form.formTitle = formTitle

    this.props.updateForm(form)
  }

  updateBuildingForm() {
    $.ajax({
      url: '/buildings/' + this.props.buildingId + '/update_lease_form',
      method: "POST",
      dataType: "JSON",
      context: this,
      data: {
        building_form_id: this.props.form.id,
        form_id: this.state.formId,
        form_title: this.state.formTitle,
        form_type: this.state.formType
      },
      success: function (res) {
        this.updateFormIdAndTitle(this.state.formId, this.state.formTitle)
        this.cancelFormEditing()
      }
    })
  }

  updateValue(inputType, value) {
    switch (inputType) {
      case 'form_title':
        this.setState({ formTitle: value })
        break;
      case 'form_id':
        this.setState({ formId: value })
        break
    }
  }

  render() {
    return (
      <div className="existing-building-lease-form" >
        {this.showExistingForm()}
        {this.editExistingForm()}
      </div>
    )
  }
}

ExistingForm.propTypes = {
  refreshForms: PropTypes.func.isRequired
}
