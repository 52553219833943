import React, {  useEffect } from 'react'
import { ajax } from 'jquery'
import { omitBy } from 'lodash'

import { createSlice, configureStore } from '@reduxjs/toolkit'
import { Provider, useDispatch, useSelector } from 'react-redux'

import { addCSRF, compactObject } from 'lib/utilities'
import { metadataByPath, valueByPath, pathMapToHash } from 'lib/utilities/form'

import FormFromTemplate, { API } from 'components/form_from_template'

const initialState = {
  building_id: null,
  metadata: {},
  basic_info: {},
  values: {},
  valuesHash: {},
  updates: {},

  limitedIds: []
}

function removeLocals(pathMap) {
  return omitBy(pathMap, (_, key) => key.match(/\.__/))
}

//

const EXAMPLE_LIMITED = [] /* ['general_information.address.zip', 
'general_information.address.state', 
'general_information.property_management.contact_info[0].first_name', 
'general_information.property_management.contact_info[0].last_name'
] */

const Store = createSlice({
  name: "base",
  initialState,
  reducers: {
    load(state, { payload: {building_id, metadata, basic_info_template, values, limitedIds}}) {
      state.building_id = building_id
      state.metadata = metadata || {}
      state.basic_info = basic_info_template || {}
      state.values = values || {}
      state.limitedIds = limitedIds || EXAMPLE_LIMITED
      for (let id of state.limitedIds)
        state.values[id] ||= null

      state.valuesHash = pathMapToHash(state.values)
    },

    updateValues(state, {payload: pathMap}) {
      state.updates = { ... state.updates, ... pathMap }
      state.values = compactObject({ ...state.values, ... state.updates })
      state.valuesHash = pathMapToHash(state.values)
    }
  }
})

const store = configureStore({
  reducer: Store.reducer
})

function useSave(building_id) {
  const updates = useSelector(state => removeLocals(state.updates))

  const url = `/buildings/${building_id}/questionnaire/data`
  const data = {
    updates: JSON.stringify(updates) 
  }

  return () => ajax({
    url,
    type: 'post',
    data: addCSRF({ building_questionnaire: data }),
    dataType: 'json'
  })
}

function Form({building_id, template, overrides, limitById}) {
  const save = useSave(building_id)

  return <div className="building-question-edit-component">
    <FormFromTemplate template={template} overrides={overrides} limitById={limitById} />

    <div className="buttons">
      <button className="btn" onClick={save}>Save</button>
    </div>
  </div>
}

function loadData(building_id) {
  const url = `/buildings/${building_id}/questionnaire`

  return new Promise((res, rej) => {
    const success = data => res(data)
    ajax({
      url, dataType: 'json',
      success
    })
  })
}

function Loader({building_id}) {
  const dispatch = useDispatch()

  useEffect(() => {
    loadData(building_id).then(data =>dispatch(Store.actions.load(data)))
  }, [building_id])

  const metadata = useSelector(state => state.metadata)
  const basic_info = useSelector(state => state.basic_info)
  const values = useSelector(state => state.values)
  const valuesHash = useSelector(state => state.valuesHash)
  const limitedIds = useSelector(state => state.limitedIds.concat([]))

  const getField = id => metadataByPath(metadata, id)
  const getValue = id => values[id]
  const getValueHash = id => valueByPath(valuesHash, id)
  const updateValues = hash => dispatch(Store.actions.updateValues(hash))

  const api = { 
    ...API, 
    getField,
    getValue, 
    getValueHash,
    updateValues
  }

  return basic_info.sections ? <Form building_id={building_id} template={basic_info} overrides={api} limitById={limitedIds} /> : null
}

export default function(props) {
  return <Provider store={store}>
    <Loader {...props} />
  </Provider>
}