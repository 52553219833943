import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

function InvoiceCancelledModal(props) {
  const { open, onClose, invoiceAddress } = props;
  const modalRef = useWatchableRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const handleClose = (_e) => onClose();
    const localRefValue = modalRef.current;

    $(localRefValue).on("hidden.bs.modal", handleClose);

    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal invoice-cancelled-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-check fa-3x"></i>
            <div>
              <h3 className="modal-title invoice-cancelled-modal__title">Invoice Cancelled</h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="confirm-payment-request__text-inform">
              <span>This invoice draft for </span>
              <span style={{ fontWeight: 400 }}>{invoiceAddress}</span>
              <span> was deleted.</span>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-custom-cancel" data-dismiss="modal" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

InvoiceCancelledModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoiceAddress: PropTypes.string.isRequired,
};

export default InvoiceCancelledModal;
