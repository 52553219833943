import React, { useEffect } from 'react'

import { useWatchableRef } from 'lib/hooks'
import { Portal } from 'components/utility/portal'

export default function ClientErrorModal({ open, onClose, onSubmit, errorMessage }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <Portal>
      <div ref={modalRef.ref} className="modal fade insurance-client-error-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content new-brp-modal">
            <div className="modal-header">
              <i className="fa-thin fa-triangle-exclamation dome-color-dark-green" style={{ fontSize: '30px' }} />
              <div className="dome-h2 dome-color-dark-green">oops!</div>
            </div>
            <div className="modal-body">
              <div className="dome-p2">{errorMessage}</div>
              <div className="dome-p2">Please try again.</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="dome-btn dome-btn-link dome-btn-link-cancel" onClick={onClose}>
                close
              </button>
              <button type="submit" className="dome-btn dome-btn-base dome-btn-dark-green" onClick={onSubmit}>
                retry
              </button>
              <div className="dome-p2">
                If the problem persists, please contact{' '}
                <a href="mailto:info@domecile.com">
                  <u>info@domecile.com</u>
                </a>
              </div>
              <button type="button" className="dome-btn dome-btn-link dome-btn-link-chevron-right dome-color-aqua">
                report bug
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

ClientErrorModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  errorMessage: '',
}
