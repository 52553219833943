import React, {useState, useEffect} from 'react'

const LockIcon = (props) => {
  const [toggleSuccess, setToggleSuccess] = useState(false)

  useEffect(() => {
    const re = /^.*(?=.{8})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^0-9A-Za-z]).*$/.test(props.watchedField)
    const value = props.watchedField.length > 0 && re
    setToggleSuccess(value)
  }, [props.watchedField])

  return <i style={{color: toggleSuccess ? '#56a256' : '#b7b7b7'}} className={`fa-light fa-lock ${props.errors ? 'error-icon' : ''}`}></i>
}

export default LockIcon
