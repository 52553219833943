import React from 'react'
import Beacon from '.'
import feesImage1 from '/app/assets/images/beacon/new/fees2 - 1.png'
import feesImage2 from '/app/assets/images/beacon/new/fees2 - 2.png'
import feesImage3 from '/app/assets/images/beacon/new/fees2 - 3.png'
import feesImage4 from '/app/assets/images/beacon/new/fees2 - 4.png'
import BeaconSlider from './slider'
import withBeaconModal from './modal'

const DATA = [
  {
    label: 'fees/submit',
    titles: [
      'Authorize fees assigned to you in this section. You can also see what fees are assigned to other deal participants.',
    ],
    image: feesImage1,
  },
  {
    label: 'pay fees',
    titles: ['Review the fees assigned to you, then select Authorize Fees  to enter your credit card info.'],
    image: feesImage2,
  },
  {
    label: 'add card',
    titles: [
      'Enter your credit card information.',
      'A hold will be placed on your card until the Processor collects the fees. Your card will be charge at the time of collection.',
    ],
    image: feesImage3,
  },
  {
    label: 'status',
    titles: [
      'Check the status tab to see a timestamp of when the application was submitted and when fees have been collected.',
    ],
    image: feesImage4,
  },
]

const FeesForDealPartiesBeacon = (props) => {
  return (
    <BeaconSlider numberOfSteps={DATA.length} {...props}>
      {(currentStep) => {
        const item = DATA[currentStep]
        return (
          <Beacon>
            <Beacon.Body>
              <Beacon.RadioGroup />
              <Beacon.ListGroup gap={0}>
                {currentStep === 0 && <Beacon.Label />}
                <Beacon.Title>{item.label}</Beacon.Title>
              </Beacon.ListGroup>
              <Beacon.ListGroup>
                {item.titles.map((title) => (
                  <Beacon.Text key={title}>{title}</Beacon.Text>
                ))}
                <Beacon.SubText>{item.description}</Beacon.SubText>
              </Beacon.ListGroup>
            </Beacon.Body>
            <Beacon.Image src={item.image} />
          </Beacon>
        )
      }}
    </BeaconSlider>
  )
}

const FeesForDealPartiesBeaconWithBeaconModal = withBeaconModal(FeesForDealPartiesBeacon)

export default FeesForDealPartiesBeaconWithBeaconModal
