import React from 'react'
import PropTypes from 'prop-types'

function object_to_params(obj) {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&')
}

export default class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchResults: [],
      resultsHidden: false
    }

    this.handleFocusInput = this.handleFocusInput.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  url(q) {
    const url = this.props.url,
          query = { query: q || this.state.query, count_results: true }

    query.only_live = this.props.include_disabled ? false : true

    if (typeof(this.props.params) == "object")
      Object.assign(query, this.props.params, query)

    return url + "?" + object_to_params(query)
  }

  handleSelect(results) {
    const { onSelect, hideResultsDropdownOnSelect } = this.props

    onSelect(results)

    if (hideResultsDropdownOnSelect)
      this.setState({ resultsHidden: true })
  }

  handleFocusInput() {
    if (this.props.hideResultsDropdownOnSelect && this.state.resultsHidden)
      this.setState({ resultsHidden: false })
  }

  clearData() {
    this.setState({ searchResults: [], query: null })
    if (typeof(this.props.onSelect) == "function")
      this.props.onSelect({searchResults: []})
  }

  rateLimitQueryUpdater(new_value) {
    this.setState({query: new_value})

    clearTimeout(this.rated_limited_lookup)
    this.rated_limited_lookup = setTimeout(() => this.findPossibleResults(), 500)
  }

  validQuery(query) {
    query = query || this.state.query
    return query && query.length >= 2
  }

  findPossibleResults(query) {
    if (!this.validQuery(query))
      return this.setState({searchResults: []})

    this.setState({searching: true})

    $.ajax({
      url: this.url(query),
      success: response => this.setState({searchResults: response[this.props.resultsKey], searching: null})
    })
  }

  renderResultPossibilities() {
    const { resultsIdentifierKey, resultsDisplayKey } = this.props
    const results = []

    if (this.state.resultsHidden)
      return

    for ( let result of this.state.searchResults )
      results.push(
        <div key={result[resultsIdentifierKey]}
          onClick={() => this.handleSelect(result)}>
            {result[resultsDisplayKey]}
        </div>)

    if (results.length == 0)
      return this.renderNoPossibilities()

    return <div className="result-possibilities">
      {results}
    </div>
  }

  renderNoPossibilities() {
    if (!this.props.show_empty_message)
      return null

    if (!this.validQuery())
      return null

    if (this.state.searching)
      return null


    return <div className="no-possibilities">
      <div>{this.props.noResultsText}</div>
    </div>
  }

  renderSearch() {
    const inside_container = []
    const container_classes = ["input-container"]

    if (this.props.showSearchbarIcon) {
      container_classes.push("left-items")
      inside_container.push(<i className={this.props.searchbarIconClass} key="searchbarIcon" />)
    }

    inside_container.push(<input key="input"
      type="text"
      defaultValue={this.state.query}
      placeholder={this.props.placeholderText}
      onChange={e => this.rateLimitQueryUpdater(e.target.value)}
      onFocus={e => this.handleFocusInput()} />)

    return <div className="results-select">
      <div className={container_classes.join(" ")}>{inside_container}</div>
      {this.renderResultPossibilities()}
    </div>
  }

  render() {
    return <div className="utility-search">
      {this.renderSearch()}
    </div>
  }
}

Search.propTypes = {
  placeholderText: PropTypes.string,
  url: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  showSearchbarIcon: PropTypes.bool,
  searchbarIconClass: PropTypes.string,
  resultsIdentifierKey: PropTypes.string,
  resultsDisplayKey: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  hideResultsDropdownOnSelect: PropTypes.bool,
  resultsKey: PropTypes.string.isRequired // property used to return search results from json response
}

Search.defaultProps = {
  placeholderText: "Enter address(es)",
  showSearchbarIcon: false,
  searchbarIconClass: "fa-light fa-magnifying-glass",
  resultsIdentifierKey: "id",
  noResultsText: "No matching results found.",
  hideResultsDropdownOnSelect: false
}

