import React from 'react'
import Header from '../common/Header'
import Sidebar from '../common/sidebars/Sidebar'
import Loader from './Loader'
import Toolbar from '../common/toolbar/Toolbar'

const Main = () => {
  return (
    <div className='form-builder'>
      <Header />
      <div className="main">
        <Sidebar navLabels={['Fields', 'Pages']} sidebarClasses='left-sidebar' navClasses='left-nav' />
        <div className="center-area">
          <Toolbar />
          <Loader />
        </div>
        <Sidebar navLabels={['Properties']} sidebarClasses='right-sidebar' navClasses='right-nav'/>
      </div>
    </div>
  )
}

export default Main
