import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {}
}

const newMessagesSlice = createSlice({
  name: "new_messages",
  initialState,
  reducers: {
    load(state, { payload }) {
      state.data = { ...state.data, ...payload }
    },

    updateOwner(state, { payload: { owner, count }}) {
      state.data[owner] = count
    }
  }
})

export const { load, updateOwner } = newMessagesSlice.actions

export default newMessagesSlice.reducer
