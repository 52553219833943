import React from 'react'

const SectionDivider = ({ sectionName, children, actionName, onAction }) => {
  return (
    <div className="section-divider">
      <div className="section-name dome-d-flex dome-justify-between dome-p1">
        <span>{sectionName}</span>
        {actionName && (
          <span className="action-name" onClick={onAction}>
            {actionName}
          </span>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default SectionDivider
