import React from 'react'

import { DropzoneComponent } from 'react-dropzone-component'

const standard_dropzone_configs = {
  showFiletypeIcon: true,
  maxFilesize: 2500,
  // Prevents Dropzone from uploading dropped files immediately
  autoProcessQueue: true,
  dictDefaultMessage: 'Drop Files Here to Upload or Click for File Manager',
  thumbnailWidth: "50px",
  thumbnailHeight: "50px",
  previewsContainer: '#dz-custom-modal .modal-body'
}

const dropzone_config = building_id => Object.assign({}, standard_dropzone_configs, { postUrl: `/buildings/${building_id}/dropzone_upload`})

const djsConfig = () => {
  const token = $("meta[name=csrf-token]").attr("content")

  return {
    headers: {
      "X-CSRF-Token" : token
    },
    addRemoveLinks: false,
    acceptedFiles: ".gif, .jpg, .jpeg, .png, .doc, .docx, .pdf"
  }
}

const PASSWORD_ERRORS = [
  "Inappropriate ioctl for device",
  "Invalid password ()",
  "xref stream not found when expected"
]

const PASSWORD_WARNING = {
  title: "Password Protected File",
  text: "This document failed to attach due to password protection. Please make sure to remove password protection and try uploading again. For more details, please see our <a class='support-link' target='_blank' href='https://boardpackager.freshdesk.com/support/solutions/articles/43000008471-password-protected-documents'>support article</a>."
}

const ENCRYPTION_ERRORS = [
  "File is encrypted"
]

const ENCRYPTION_WARNING = {
  title: "Encrypted/Read-Only File",
  text: "This document failed to attach due to file encryption. If possible, please try to remove file encryption and try uploading again. For more details, please see our <a class='support-link' target='_blank' href='https://boardpackager.freshdesk.com/support/solutions/articles/43000008471-password-protected-documents'>support article</a> or contact <a href='mailto:info@boardpackager.com'>info@boardpackager.com</a>.",
}

const READING_ERRORS = [
  "Broken catalog"
]

const READING_WARNING = {
  title: "File Error",
  text: "This document failed to attach due to file error. If possible, please try to re-save the file and try uploading again. For more details, please see our <a class='support-link' target='_blank' href='https://boardpackager.freshdesk.com/support/solutions/articles/43000008471-password-protected-documents'>support article</a> or contact <a href='mailto:info@boardpackager.com'>info@boardpackager.com</a>.",
}

const SWAL_WARNING_OPTS = {
  type: "warning",
  html: true
}

function swap_warning(opts) {
  swal(Object.assign({}, SWAL_WARNING_OPTS, opts))
}

class UploadNew extends React.Component {

  dropzoneHandlers() {
    const success = (file, data) => {
      this.dropzone.removeFile(file)

      if (PASSWORD_ERRORS.includes(data['error']))
        return swap_warning(PASSWORD_WARNING)

      if (ENCRYPTION_ERRORS.includes(data['error']))
        return swap_warning(ENCRYPTION_WARNING)

      if (READING_ERRORS.includes(data['error']))
        return swap_warning(READING_WARNING)

      if (typeof this.props.onSuccess == "function")
        this.props.onSuccess(data)
    }

    return {
      success,
      init: dropzone => this.dropzone = dropzone,
      dragover: () => $('#modal-dropzone .dz-message').addClass('dz-hovered'),
      dragleave: () => $('#modal-dropzone .dz-message').removeClass('dz-hovered'),
    }
  }

  render() {
    return <div className="upload-new-store-document">
      <div className="upload-area">
        <DropzoneComponent ref="dropzone"
                            config={dropzone_config(this.props.building_id)}
                            eventHandlers={this.dropzoneHandlers()}
                            djsConfig={djsConfig()}
          />
      </div>
    </div>
  }
}

export default UploadNew
