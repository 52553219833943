import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { addCSRF } from 'lib/utilities'
import LandlordFormFields from './landlord_form_fields'

function addLandlord(orgId, data) {
  const url = `/super/organizations/${orgId}/rentals_landlord`

  return new Promise((res, rej) => {
    $.ajax({
      url,
      data: addCSRF(data),
      method: 'POST',
      dataType: 'JSON'
    }).done(res).fail(rej)
  })
}

function removeLandlord(orgId, landlordId) {
  const url = `/super/organizations/${orgId}/rentals_landlord/${landlordId}`

  return new Promise((res, rej) => {
    $.ajax({
      url,
      data: addCSRF({}),
      method: 'DELETE',
      dataType: 'JSON'
    }).done(res).fail(rej)
  })
}

function LandlordView({ landlord, onRemove, onEdit }) {
  const { email } = landlord

  return (
    <div id='existing-landlord'>
      <div className='existing-landlord'>
        <div className="landlord-info">
          <div className="landlord-email">{email}</div>
        </div>
        <div className="landlord-operations">
          <div className='edit-landlord' onClick={() => onEdit()}>
            <i className='fa fa-pencil edit-landlord-btn' aria-hidden='true'></i>
          </div>
          <div className='remove-landlord' onClick={() => onRemove()} >
            <i className='fa fa-2x fa-times remove-landlord-btn' aria-hidden='true'></i>
          </div>
        </div>
      </div>
      <div className="pull-right">
        <span className='thick-text'>Note: </span><span className='thin-text'>this email is <span className='thick-text'>autofilled</span> within the lease</span>
      </div>
    </div>
  )
}

function LandlordForm({ organizationId, landlord, onSave, onCancel, canCancel}) {
  const { register, reset, setError, setValue, handleSubmit, formState: { errors, isValid } } = useForm({
    defaultValues: {
      landlord: { email: landlord?.email || '' }
    }
  })

  const onSubmit = useCallback(data => {
    addLandlord(organizationId, data)
      .then(res => onSave(res))
      .catch(res => setError('landlord[email]', { type: "manual", message: res.responseJSON.error}))
  }, [organizationId])

  return (
    <div id='landlord-form'>
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)} className='landlord-form'>
          <LandlordFormFields register={register} reset={reset} setValue={setValue} errors={errors} isValid={isValid} />
        </form>
        { canCancel && <button className="btn cancel pull-left" onClick={() => onCancel()}>Cancel</button> }
      </div>
    </div>
  )
}

const RentalsLandlord = (props) => {
  const organizationId = props.organization_id
  const [landlord, setLandlord] = useState(props.landlord)
  const [showForm, setShowForm] = useState(landlord == null)

  const handleCreateLandlord = useCallback(({ landlord }) => {
    setLandlord(landlord)
    setShowForm(false)
  }, [setLandlord, setShowForm])

  const handleRemoveLandlord = useCallback(() => {
    removeLandlord(organizationId, landlord.id)
      .then(setLandlord(null))
    setShowForm(true)
  }, [organizationId, landlord])

  const handleCancellation = useCallback(() => {
    setShowForm(false)
  }, [setShowForm])

  const handleEditLandlord = useCallback(() => {
    setShowForm(true)
  }, [setShowForm])

  return (
    <div className='landlord-section'>
      <h4 className='header-divider'>Organization Landlord</h4>
      <span className='subtitle'>Landlord Information</span>
      <br />
      <span className='thin-text'>By default, the following information will </span><span className='thick-text'>autofill</span><span className='thin-text'> across all lease agreements for this Organization's rental properties.</span>
      <br />
      <span className='thick-text'>Note: </span><span className='thin-text'>The landlord name + address listed on the lease will match the related property's name + address.</span>
      {!showForm && <LandlordView organizationId={organizationId} landlord={landlord} onEdit={handleEditLandlord} onRemove={handleRemoveLandlord} />}
      {showForm && <LandlordForm organizationId={organizationId} landlord={landlord} onSave={handleCreateLandlord} onCancel={handleCancellation} canCancel={landlord != null} />}
    </div>
  )
}

export default RentalsLandlord
