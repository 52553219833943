import React from 'react'
import { connect } from 'react-redux'
import Store from './Store'
import Modal from './Modal'
import Item from './Item'

class List extends React.Component {
  constructor(props) {
    super(props)

    this.tableContainer = null
    this.setTableContainer = element => { this.tableContainer = element }
  }

  componentDidMount() {
    this.applySortable()
  }

  updateSorting() {
    const { buildingId, buildingResponsibleParties } = this.props
    const sortedIds = $(this.tableContainer).find('.sortable-list-item').map((_, el) => parseInt(el.dataset.id)).get()

    $.ajax({
      url: `/buildings/${buildingId}/reorder_building_responsible_parties`,
      data: { sorted_ids: sortedIds },
      method: 'POST',
      dataType: 'json',
      context: this,
      success: () => {
        sortedIds.forEach((id, index) => {
          const buildingResponsibleParty = buildingResponsibleParties.find(contact => contact.id == id)
          buildingResponsibleParty.sort_order = index
          this.props.updateBuildingResponsibleParty(buildingResponsibleParty)
        })
      }
    })
  }

  sortededBuildingResponsibleParties() {
    return this.props.buildingResponsibleParties.sort((a, b) => a.sort_order - b.sort_order)
  }

  applySortable() {
    $(this.tableContainer).sortable({
      update: (event, ui) => this.updateSorting(),
      items: '.sortable-list-item',
      handle: '.fa-grip-dots-vertical'
    })
  }

  renderModal() {
    const { modalOpenForContext, package_types } = this.props

    if (modalOpenForContext)
      return <Modal package_types={package_types}
                    alteration_processor_package_types={this.props.alteration_processor_package_types} />
  }

  renderList() {
    const list = this.sortededBuildingResponsibleParties().map(buildingResponsibleParty =>
        <Item key={buildingResponsibleParty.id} buildingResponsibleParty={buildingResponsibleParty} />)

    const tableStyling = {
      fontSize: 12,
      display: 'table',
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 1em',
      borderSpacing: '0 1rem'
    }

    return <div style={tableStyling}>
      {this.renderHeader()}
      <div style={{ display: 'table-row-group' }} ref={this.setTableContainer}>
        {list}
      </div>
    </div>
  }

  renderHeader() {
    const columnStyling = { display: 'table-cell', verticalAlign: 'middle', minWidth: 15, padding: '0 5px 0 0' }

    return <div style={{ marginBottom: 10, display: 'table-header-group' }}>
      <div style={columnStyling}></div>
      <div style={{...columnStyling, minWidth: 20}}>VISIBILITY</div>
      <div style={columnStyling}>NAME/TITLE</div>
      <div style={columnStyling}>CONTACT INFO</div>
      <div style={columnStyling}>ROLE(S)</div>
      <div style={columnStyling}></div>
      <div style={columnStyling}></div>
    </div>
  }

  render() {
    return <div>
      {this.renderModal()}
      {this.renderList()}
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buildingId: state.main.buildingId,
    buildingResponsibleParties: state.main.buildingResponsibleParties,
    modalOpenForContext: state.modal.modalOpenForContext
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
