import React, { useEffect, useState } from 'react'
import ENotaryIntroModal from './ENotaryIntroModal'
import ENotaryModal from './ENotaryModal'
import ENotaryEndingModal from './ENotaryEndingModal'
import { Portal } from '../utility/portal'

export default function Steps_for_modals(props) {
  const { step: initialStep = 0, onClose = () => {} } = props
  const [step, setStep] = useState(initialStep)

  const [passedAddress, setPassedAddress] = useState('')
  const [passedUnit, setPassedUnit] = useState(0)

  useEffect(() => {
    setStep(initialStep)
  }, [initialStep])

  useEffect(() => {
    if (!step) onClose()
  }, [step])

  function ensureModalIsClosed() {
    setStep(0)
  }

  function stepToEndAndPass(address, unit) {
    setPassedAddress(address)
    setPassedUnit(unit)
    setStep(3)
  }

  return (
    <Portal>
      {step == 1 ? <ENotaryIntroModal step={step} setStep={setStep} onClose={ensureModalIsClosed} /> : null}
      {step == 2 ? (
        <ENotaryModal
          {...props}
          setStep={setStep}
          step={step}
          stepToEndAndPass={stepToEndAndPass}
          onClose={ensureModalIsClosed}
        />
      ) : step == 3 ? (
        <ENotaryEndingModal
          step={setStep}
          setStep={setStep}
          onClose={ensureModalIsClosed}
          passedAddress={passedAddress}
          passedUnit={passedUnit}
        />
      ) : null}
    </Portal>
  )
}
