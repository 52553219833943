import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { addCSRF } from 'lib/utilities'
import { compact } from 'lodash'
import SignerFormFields from './signer_form_fields'

function addSigner(orgId, data) {
  const url = `/super/organizations/${orgId}/rentals_signer`

  return new Promise((res, rej) => {
    $.ajax({
      url,
      data: addCSRF(data),
      method: 'POST',
      dataType: 'JSON'
    }).done(res).fail(rej)
  })
}

function removeSigner(orgId, signerId) {
  const url = `/super/organizations/${orgId}/rentals_signer/${signerId}`

  return new Promise((res, rej) => {
    $.ajax({
      url,
      data: addCSRF({}),
      method: 'DELETE',
      dataType: 'JSON'
    }).done(res).fail(rej)
  })
}

function Signer({signer, onRemove}) {
  const { id, first_name, last_name, email } = signer

  return <div className='existing-signer'>
    <div className="signer-info">
      <div className="signer-name">{first_name} {last_name}</div>
      <div className="signer-email">{email}</div>
    </div>
    <div className='remove-signer' onClick={() => onRemove(id)}>
      <i className='fa fa-2x fa-times remove-signer-btn' aria-hidden='true'></i>
    </div>
  </div>
}

function Signers({ organizationId, signers, onRemoved }) {
  const handleRemove = useCallback(signerId => {
    removeSigner(organizationId, signerId)
      .then(onRemoved)
  }, [ onRemoved ])

  return (
    <div id='existing-signers'>
      {signers.map(signer => <Signer key={signer.id} signer={signer} onRemove={handleRemove} />) }
    </div>
  )
}

function NewSignerForm ({organizationId, onSave, onCancel, canCancel }) {
  const { register, reset, setError, setValue, handleSubmit, formState: { errors, isValid } } = useForm({
    defaultValues: {
      signer: { email: '' }
    }
  })

  const onSubmit = useCallback(data => {
    addSigner(organizationId, data)
      .then(res => onSave(res))
      .catch(res => setError('signer[email]', { type: "manual", message: res.responseJSON.error}))
  }, [organizationId])

  return (
    <div id='new-signer-form'>
      <div className="form-container">
        <h5 className='form-header'>New Authorized Property Signer</h5>
        <div className="header-divider"></div>
        <form onSubmit={handleSubmit(onSubmit)} className="signer-form">
          <SignerFormFields register={register} reset={reset} setValue={setValue} errors={errors} isValid={isValid} />
        </form>
        {canCancel && <button className="btn cancel pull-left" onClick={() => onCancel()}>Cancel</button> }
      </div>
    </div>
  )
}

function NewSignerButton({show, onClick}) {
  const classes = compact([
    'pull-left',
    show ? 'inactive' : null
  ]).join(" ")

  return <div id='new-signer-btn' className={classes} onClick={onClick}>
    <i className='fa fa-plus' aria-hidden='true' /> New Authorized Signer
  </div>
}

const RentalSigners = (props) => {
  const organizationId = props.organization_id
  const [signers, setSigners] = useState(props.signers)
  const [showForm, setShowForm] = useState(signers.length == 0)

  const handleCreateSigner = useCallback(({ signers }) => {
    setSigners(signers)
    setShowForm(false)
  }, [setSigners, setShowForm])

  const handleRemovedSigner = useCallback(({signers}) => {
    setSigners(signers)

    if (signers.length == 0)
      setShowForm(true)
  }, [setSigners, setShowForm])

  const handleCancellation = useCallback(() => {
    setShowForm(false)
  }, [setShowForm])

  return (
    <div className='signers-section'>
      <h4>Authorized Signing Permissions</h4>
      <span className='thin-text'>The following users may sign any lease for this property on behalf of the landlord.</span>
      <br />
      <span className='thin-text'>At least one authorized signer must be assigned.</span>
      <Signers organizationId={organizationId} signers={signers} onRemoved={handleRemovedSigner} />
      {showForm && <NewSignerForm organizationId={organizationId} onSave={handleCreateSigner}
                                  onCancel={handleCancellation} canCancel={signers.length > 0} />}
      < NewSignerButton show={showForm} onClick={() => setShowForm(true)} />
    </div>
  )
}

export default RentalSigners
