import React, { useRef } from 'react'
import { pickBy } from 'lodash'

const isEmpty = val => !val || String(val).match(/^\s*$/)
const presence = val => isEmpty(val) ? null : val

export default function ({ field: { placeholder, system_only } = {}, id, onChange, value, defaultValue, onBlur, onFocus, className, disabled, style, inputClassName }) {
  const container = useRef()
  const handleChange = e => onChange({ [id]: e.target.value })
  
  const handleKeyDown = e => {
    if (e.key == "Enter" && container.current)
      container.current.blur()
  }

  let props = {
    ref: container,
    value: value || defaultValue || "",
    disabled,
    placeholder,
    type: "text",
    style,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onBlur,
    onFocus,
    className: inputClassName
  }

  return <div className={className}>
    { system_only ? value || "" : <input {...props} /> }
  </div>
}
