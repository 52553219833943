import React from 'react'
import { connect } from 'react-redux'
import Store from '../Store'
import MultiSelect from 'components/utility/multi_select'
import SliderToggle from 'components/utility/slider_toggle'
import { modalErrorKeys } from './Error'

const ROLES_TO_LABEL_HASH = {
  package_processor: 'Package Processor',
  property_executive: 'Property Executive',
  alterations: 'Alterations',
  resident_payments_processor: 'Resident Payments Processor',
}

const ROLES_WITH_ADDITIONAL_OPTIONS = ['package_processor']

class RolesAndPermissionsModalContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false,
      alterationsSliderOn: this.anyAlterationRolesSelected()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.alterationsSliderOn && !this.state.alterationsSliderOn) {
      this.props.toggleAlterationsRoleOn(null)
    }
  }

  canSubmit() {
    const { roles } = this.props

    if (roles.package_processor.selected && roles.property_executive.selected)
      return false

    if (!this.validQuestionnaireProcessorSelectedOptions() || !this.validPackageProcessorSelectedOptions())
      return false

    if (this.state.alterationsSliderOn && !this.anyAlterationRolesSelected())
      return false

    return true
  }

  submitIfAble() {
    if (!this.canSubmit())
      return false

    if (this.checkIfConfirmationIsNeededBeforeSubmit())
      return this.props.openModalForContext('Remove Role Confirmation')

    this.submit()
  }

  submit() {
    if (this.props.modalUserDetails.id) {
      this.updateResponsibleParty()
    } else {
      this.addResponsibleParty()
    }
  }

  checkIfConfirmationIsNeededBeforeSubmit() {
    const { originalRoles, roles, modalUserDetails: { id } } = this.props

    if (!id) // new building responsible party does not need confirmation
      return false

    if (originalRoles && originalRoles.package_processor.selected && !roles.package_processor.selected)
      return true

    return false
  }

  updateResponsibleParty() {
    const { modalUserDetails, roles } = this.props
    const data = {
      building_responsible_party: {
        role_label: modalUserDetails.title,
        is_visible: modalUserDetails.is_visible,
        is_phone_hidden: modalUserDetails.is_phone_hidden,
        package_processor_package_types: roles.package_processor.package_types,
        package_processor: roles.package_processor.selected,
        package_processor_for_all_package_types: roles.package_processor.selected && roles.package_processor.package_types.length == 0,
        property_executive: roles.property_executive.selected,
        board_minutes_processor: roles.minutes_processor.selected,
        questionnaire_processor_with_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withFiles,
        questionnaire_processor_without_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withoutFiles,
        alterations_processor: roles.alterations_processor.selected,
        alterations_property_architect: roles.alterations_property_architect.selected,
        alterations_viewer: roles.alterations_viewer.selected,
        alterations_processor_package_types: roles.alterations_processor.package_types,
        resident_payments_processor: roles.resident_payments_processor.selected,
        first_name: modalUserDetails.first_name,
        last_name: modalUserDetails.last_name,
        phone_number: modalUserDetails.phone_number,
        phone_ext: modalUserDetails.phone_ext
      }
    }

    this.setState({ submitting: true })

    $.ajax({
      url: `/buildings/${this.props.buildingId}/update_building_responsible_party/${modalUserDetails.id}`,
      method: 'PATCH',
      dataType: 'json',
      data: data,
      context: this,
      success: res => {
        this.props.updateBuildingResponsibleParty(res.building_responsible_party[0])
        this.props.updateBuildingResponsiblePartyRoles(modalUserDetails.email, res.roles)
        this.props.closeModal()
      }, error: err => {
        if (err.responseJSON.error) {
          if (err.responseJSON.error.user?.includes('cannot be broker')) {
            this.props.setErrorModalDetails({
              name: modalErrorKeys.package_processor_cannot_be_broker,
              email: modalUserDetails.email
            })
            this.props.openModalForContext('Error')
          } else {
            this.props.setErrorModalDetails({
              name: modalErrorKeys.package_processor_permissions_cannot_be_removed,
              package_types: err.responseJSON.error.package_types
            })
            this.props.openModalForContext('Error')
          }
        }
      }, always: () => this.setState({ submitting: false })
    })
  }

  addResponsibleParty() {
    const { modalUserDetails, roles, buildingResponsiblePartyType } = this.props
    const data = {
      building_responsible_party: {
        board_member: buildingResponsiblePartyType == 'BoardMembers',
        role_label: modalUserDetails.title,
        is_visible: modalUserDetails.is_visible,
        is_phone_hidden: modalUserDetails.is_phone_hidden,
        package_processor_package_types: roles.package_processor.package_types,
        package_processor: roles.package_processor.selected,
        package_processor_for_all_package_types: roles.package_processor.selected && roles.package_processor.package_types.length == 0,
        property_executive: roles.property_executive.selected,
        board_minutes_processor: roles.minutes_processor.selected,
        questionnaire_processor_with_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withFiles,
        questionnaire_processor_without_files: roles.questionnaire_processor.selected && roles.questionnaire_processor.withoutFiles,
        alterations_processor: roles.alterations_processor.selected,
        alterations_property_architect: roles.alterations_property_architect.selected,
        alterations_viewer: roles.alterations_viewer.selected,
        alterations_processor_package_types: roles.alterations_processor.package_types,
        resident_payments_processor: roles.resident_payments_processor.selected,
        first_name: modalUserDetails.first_name,
        last_name: modalUserDetails.last_name,
        email: modalUserDetails.email,
        phone_number: modalUserDetails.phone_number,
        phone_ext: modalUserDetails.phone_ext,
      }
    }

    this.setState({ submitting: true })

    $.ajax({
      url: `/buildings/${this.props.buildingId}/add_building_responsible_party`,
      method: 'POST',
      dataType: 'json',
      data: data,
      context: this,
      success: function(res) {
        this.props.addBuildingResponsibleParty(res.propertyContact[0])
        this.props.updateBuildingResponsiblePartyRoles(modalUserDetails.email, res.roles)
        this.props.closeModal()
      }, error: (err) => {
        if (err.responseJSON.error) {
          if (err.responseJSON.error.responsible_party_id == 'has already been taken') {
            this.props.setErrorModalDetails({
              name: modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member,
              email: modalUserDetails.email
            })
            this.props.openModalForContext('Error')
          } else if (err.responseJSON.error.user?.includes('cannot be broker')) {
            this.props.setErrorModalDetails({
              name: modalErrorKeys.package_processor_cannot_be_broker,
              email: modalUserDetails.email
            })
            this.props.openModalForContext('Error')
          }
        }
      }, always: () => this.setState({ submitting: false })
    })
  }


  validQuestionnaireProcessorSelectedOptions() {
    const { roles: { questionnaire_processor } } = this.props

    if (questionnaire_processor.selected == false)
      return true

    return questionnaire_processor.withFiles || questionnaire_processor.withoutFiles
  }

  validPackageProcessorSelectedOptions() {
    const { roles: { package_processor } } = this.props

    if (package_processor.selected == false) return true
    if (package_processor.select_custom_package_types == false) return true

    return package_processor.package_types.length > 0
  }

  anyAlterationRolesSelected() {
    return this.props.roles.alterations_processor.selected || this.props.roles.alterations_property_architect.selected || this.props.roles.alterations_viewer.selected
  }

  isSliderOn(roleName) {
    switch(roleName) {
      case 'alterations':
        return this.state.alterationsSliderOn
      default:
        return this.props.roles[roleName].selected
    }
  }

  handleToggleSlider(onOrOff, roleName) {
    switch(roleName) {
      case 'alterations':
        this.setState({ alterationsSliderOn: onOrOff == 'on' })
        this.props.setRolePackageType('alterations_processor', [])
        break
      default:
        onOrOff == 'on' ? this.props.toggleRoleOn(roleName) : this.props.toggleRoleOff(roleName)
        break
    }
  }

  rolesToLabelHash() {
    return this.props.rolesToLabelHash || ROLES_TO_LABEL_HASH
  }

  roleSlidersList() {
    const list = [],
          rolesToLabelHash = this.rolesToLabelHash()

    for (const role in rolesToLabelHash)
      list.push(this.renderRoleSlider(rolesToLabelHash[role], role))

    return list
  }

  renderRoleSlider(roleLabel, roleName) {
    const containerStyling = { border: '1px solid #4a4a4a', borderRadius: 10, marginBottom: 20 },
          roleNameContainerStyling = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2px 20px',
            backgroundColor: '#F9F9F9',
            borderBottom: '1px solid #4a4a4a'
          }

    roleNameContainerStyling['borderRadius'] = ROLES_WITH_ADDITIONAL_OPTIONS.includes(roleName) ? '10px 10px 0 0' : 10

    return <div key={roleName} style={containerStyling}>
      <div style={roleNameContainerStyling}>
        <span>{roleLabel}</span>
        {<SliderToggle inputName={roleName}
                       onByDefault={this.isSliderOn(roleName)}
                       onToggleOn={() => this.handleToggleSlider('on', roleName)}
                       onToggleOff={() => this.handleToggleSlider('off', roleName)}
                       toggleOnBackgroundColor="#008000" />}
      </div>
      {this.renderRoleAdditionalOptions(roleName)}
    </div>
  }

  renderRoleAdditionalOptions(role) {
    if ((this.props.roles[role] && !this.props.roles[role].selected) || (role == 'alterations' && !this.state.alterationsSliderOn))
      return null

    switch(role) {
      case 'package_processor':
        return <div>{this.renderPackageProcessorTypeOptions()}</div>
      case 'questionnaire_processor':
        return <div>{this.renderQuestionnaireProcessorOptions()}</div>
      case 'alterations':
        return <div>{this.renderAlterationsProcessorTypeOptions()}</div>
      default:
        return null
    }
  }

  renderPackageProcessorCustomTypesDropdown() {
    const { package_types, roles: { package_processor } } = this.props

    if (package_processor.select_custom_package_types)
      return <MultiSelect
        options={package_types}
        placeholder="Select Package Type(s)"
        defaultValue={package_processor.package_types}
        onChange={options => this.props.setRolePackageType('package_processor', options)}
        multiple={true} />
  }

  renderAlterationsProcessorCustomTypesDropdown() {
    const { alteration_processor_package_types, roles: { alterations_processor } } = this.props

    if (alterations_processor.selected)
      return <div style={{marginBottom: 20}}>
        <MultiSelect
          options={alteration_processor_package_types}
          placeholder="Select one or more alteration types this processor will review."
          defaultValue={alterations_processor.package_types}
          onChange={options => this.props.setRolePackageType('alterations_processor', options)}
          multiple={true} />
      </div>
  }

  renderPackageProcessorTypeOptions() {
    const styling = {
      padding: '5px 20px'
    }

    return <div style={styling}>
      <div style={{marginBottom: 5}}>Application Types <span style={{ color: '#F68A41' }}>*</span></div>
      <div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type="radio"
            name="package_processor_type_option"
            id="package_processor_all_types"
            style={{marginRight: 5, marginTop: 0}}
            checked={this.props.roles.package_processor.select_custom_package_types == false}
            onChange={() => this.props.toggleRoleCustomPackageTypes('package_processor', false)} />
          <label style={{marginBottom: 0}} htmlFor="package_processor_all_types">Add to All Application Types</label>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type="radio"
            name="package_processor_type_option"
            id="package_processor_custom_types"
            style={{marginRight: 5, marginTop: 0}}
            checked={this.props.roles.package_processor.select_custom_package_types == true}
            onChange={event => this.props.toggleRoleCustomPackageTypes('package_processor', event.target.checked)} />
          <label style={{marginBottom: 0}} htmlFor="package_processor_custom_types">Custom</label>
        </div>
        <div style={{paddingLeft: 18, width: '60%'}}>{this.renderPackageProcessorCustomTypesDropdown()}</div>
      </div>
    </div>
  }

  renderAlterationsProcessorTypeOptions() {
    const styling = {
      padding: '5px 20px'
    }

    return <div style={styling}>
      <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: 10}}>
        <span>
          <input
            type="radio"
            name="alterations_type_option"
            id="alterations_processor"
            style={{marginRight: 5, marginTop: 0}}
            defaultChecked={this.props.roles.alterations_processor.selected}
            onChange={event => event.target.checked ? this.props.toggleAlterationsRoleOn('alterations_processor') : null} />
          <label style={{marginBottom: 0}} htmlFor="package_processor_all_types">Processor</label>
        </span>
        <span>
          <input
            type="radio"
            name="alterations_type_option"
            id="alterations_property_architect"
            style={{marginRight: 5, marginTop: 0}}
            defaultChecked={this.props.roles.alterations_property_architect.selected}
            onChange={event => event.target.checked ? this.props.toggleAlterationsRoleOn('alterations_property_architect') : null} />
          <label style={{marginBottom: 0}} htmlFor="package_processor_custom_types">Property Architect</label>
        </span>
        <span>
          <input
            type="radio"
            name="alterations_type_option"
            id="alterations_viewer"
            style={{marginRight: 5, marginTop: 0}}
            defaultChecked={this.props.roles.alterations_viewer.selected}
            onChange={event => event.target.checked ? this.props.toggleAlterationsRoleOn('alterations_viewer') : null} />
          <label style={{marginBottom: 0}} htmlFor="package_processor_custom_types">Viewer</label>
        </span>
      </div>

      {this.renderAlterationsProcessorCustomTypesDropdown()}
    </div>
  }

  renderQuestionnaireProcessorOptions() {
    return <div>
      <div>
        <input type="checkbox"
                name="questionnaire_processor_with_files"
                id="questionnaire_processor_with_files"
                checked={this.props.roles.questionnaire_processor.withFiles}
                onChange={event => this.props.toggleQuestionnaireProcessorWithFiles(event.target.checked)} />
        <label htmlFor="questionnaire_processor_with_files">Process incoming requests with files attached</label>
        <input type="checkbox"
                name="questionnaire_processor_without_files"
                id="questionnaire_processor_without_files"
                checked={this.props.roles.questionnaire_processor.withoutFiles}
                onChange={event => this.props.toggleQuestionnaireProcessorWithoutFiles(event.target.checked)} />
        <label htmlFor="questionnaire_processor_without_files">Process incoming requests without files attached</label>
      </div>
    </div>
  }

  renderBody() {
    return <div className="modal-body">
      {this.roleSlidersList()}
    </div>
  }

  renderSubmitButton() {
    const { edittingExistingUser, roles } = this.props

    const anyRolesSelected = Object.values(roles).some(role => role.selected),
          buttonStyling = {
            backgroundColor: '#4A4A4A',
            color: 'white'
          }

    let btnAttributes = {key: 'modal-submit', type: 'button', className: 'btn', style: buttonStyling, onClick: () => this.submitIfAble()},
        btnText = ''

    if (!this.canSubmit() || this.state.submitting)
      btnAttributes['disabled'] = true

    if (edittingExistingUser) {
      btnText = 'Update Roles'
    } else if (anyRolesSelected) {
      btnText = 'Add & Assign Role'
    } else {
      btnText = 'Add With No Role'
    }

    return <button {...btnAttributes}>{btnText}</button>
  }

  renderError() {
    const { roles } = this.props,
          styling = {
            color: '#F74141',
            backgroundColor: '#FFF8F8',
            border: '1px solid #F74141',
            borderRadius: 5,
            width: '70%',
            textAlign: 'center',
            margin: '-10px auto 20px',
            padding: '5px 10px'
          }

    if (roles.package_processor.selected && roles.property_executive.selected)
      return <div style={styling}>
        A property contact cannot be both a Package Processor and a Property Executive. Please select only one of the roles.
      </div>
  }

  renderFooter() {
    return <div className="modal-footer">
      {this.renderError()}
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal" style={{ float: 'left' }}>Cancel</button>
      {this.renderSubmitButton()}
    </div>
  }

  render() {
    const { modalUserDetails: { first_name, last_name } } = this.props

    return <div className="modal-content roles-and-permissions-modal">
      <div className="modal-header">
        <h3 className="modal-title">Role &amp; Permissions</h3>
        <h5>{`${first_name} ${last_name}`}</h5>
      </div>
      <form ref={this.setFormRef}>
        {this.renderBody()}
        {this.renderFooter()}
      </form>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  const { roles, modalUserDetails } = state.modal
  return {
    roles: roles,
    modalUserDetails: modalUserDetails,
    buildingId: state.main.buildingId,
    edittingExistingUser: modalUserDetails.id != null,
    originalRoles: modalUserDetails.id ? state.main.buildingResponsiblePartyRolesGroupedByEmail[modalUserDetails.email] : null
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => dispatch(Store.closeModal()),
    toggleRoleOn: role => dispatch(Store.toggleRoleOn(role)),
    toggleRoleOff: role => dispatch(Store.toggleRoleOff(role)),
    // TOGGLE_ALTERATIONS_ROLE_ON
    toggleAlterationsRoleOn: role => dispatch(Store.toggleAlterationsRoleOn(role)),
    setRolePackageType: (role, package_types) => dispatch(Store.setRolePackageType(role, package_types)),
    toggleRoleCustomPackageTypes: (role, bool) => dispatch(Store.toggleRoleCustomPackageTypes(role, bool)),
    toggleQuestionnaireProcessorWithFiles: bool => dispatch(Store.toggleQuestionnaireProcessorWithFiles(bool)),
    toggleQuestionnaireProcessorWithoutFiles: bool => dispatch(Store.toggleQuestionnaireProcessorWithoutFiles(bool)),
    addBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.addBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsiblePartyRoles: (userEmail, roles) => dispatch(Store.updateBuildingResponsiblePartyRoles(userEmail, roles)),
    openModalForContext: context => dispatch(Store.openModalForContext(context)),
    setErrorModalDetails: details => dispatch(Store.setErrorModalDetails(details))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesAndPermissionsModalContent)
