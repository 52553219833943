import React, { useMemo } from 'react'
import InsuranceTooltip from '../../InsuranceTooltip'
import { formattedMoney } from 'components/insurance/get_quote/utils'

export default function AboutYouSidebarDetail({
  isPolicy,
  liabilityLimit,
  homeAndContentsDeductible,
  contentsAmount,
  additionsAndAlterationsAmount,
  sidebarDetails,
  insuranceType,
  overrides
}) {
  const tooltips = useMemo(() => {
    return {
      sidebar_loss_of_use_and_additional_living_expenses: {
        header: 'what does this cover?',
        content: `If the unit becomes uninhabitable due to a covered peril, this coverage helps pay for temporary living
        expenses, like hotel bills or comparable temporary rental property. Chubb includes "full coverage" which is
        a reasonable agreed upon amount for bills and comparable temporary housing.`,
      },
      sidebar_loss_assessment_coverage: {
        header: 'what does this cover?',
        content: `Even if damage to a common area in your building is covered by your building's policy, as a resident you may still need to contribute money to satisfy the policy deductible, which could be substantial. Chubb
        automatically includes $5,000 to cover an assessment from the deductible, and higher amounts may be
        purchased. And, if there is damage to the common area that would have been covered under your building's
        policy but the policy has insufficient coverage, Chubb automatically includes $50,000 to cover your share.`,
      },
    }
  }, [])

  return (
    <>
      <div>
        <div className="dome-h4 dome-color-dark-green">
          <div dangerouslySetInnerHTML={{__html: overrides?.whats_covered.html}} />
        </div>
        <div className="dome-p2">
          Liability Limit: <span className="dome-text-w300">{formattedMoney(liabilityLimit)}</span>
        </div>
        <div className="dome-p2">
          Base Deductible: <span className="dome-text-w300">{formattedMoney(homeAndContentsDeductible)}</span>
        </div>
        <div className="dome-p2">
          Contents Amount: <span className="dome-text-w300">{formattedMoney(contentsAmount)}</span>
        </div>
        {!isPolicy && insuranceType == 'homeowners' && <div className="dome-p2">
          Dwelling Amount: <span className="dome-text-w300">{formattedMoney(additionsAndAlterationsAmount)}</span>
        </div>}
        {overrides?.show_coverage_fields.toggle && <>
          <div className="dome-p2">
            Loss of use + additional living expenses
            <i
              className="fa-light fa-circle-question dome-color-dark-green"
              style={{ marginLeft: '6px' }}
              data-tip
              data-for="sidebar_loss_of_use_and_additional_living_expenses"
            ></i>
          </div>
          <InsuranceTooltip
            id="sidebar_loss_of_use_and_additional_living_expenses"
            header={tooltips.sidebar_loss_of_use_and_additional_living_expenses.header}
            content={tooltips.sidebar_loss_of_use_and_additional_living_expenses.content}
          />
          <div className="dome-p2">
            Loss assessment coverage
            <i
              className="fa-light fa-circle-question dome-color-dark-green"
              style={{ marginLeft: '6px' }}
              data-tip
              data-for="sidebar_loss_assessment_coverage"
            ></i>
          </div>
          <InsuranceTooltip
            id="sidebar_loss_assessment_coverage"
            header={tooltips.sidebar_loss_assessment_coverage.header}
            content={tooltips.sidebar_loss_assessment_coverage.content}
          />
        </>}
      </div>
      <div className="sage-line my-18"></div>
      <div className="quote-estimate-details">
        <div className="dome-h4 dome-color-dark-green">details</div>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> property
          </summary>
          <div>{sidebarDetails.title}</div>
          <div>{sidebarDetails.address}</div>
          <div>{`${sidebarDetails.city}, ${sidebarDetails.state} ${sidebarDetails.zip}`}</div>
          {sidebarDetails.buildingTypeDisplay && <div>{`Type: ${sidebarDetails.buildingTypeDisplay}`}</div>}
        </details>
        {!isPolicy && (
          <details>
            <summary>
              <i className="fa-light fa-chevron-down" /> unit
            </summary>
            <div>{sidebarDetails.buildingUnit}</div>
            <div>{`${sidebarDetails.squareFootage} sq.ft`}</div>
          </details>
        )}
      </div>
    </>
  )
}
