import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialState as initStateAction, selectPackageTemplatesForm } from './store/slice'
import Main from './components/Main'

export default function PackageTemplatesForm(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initStateAction(props))
  }, [dispatch])

  const { stateInitialized } = useSelector(selectPackageTemplatesForm)

  if (!stateInitialized) return null

  return (
    <div id="package-template-container">
      <Main />
    </div>
  )
}
