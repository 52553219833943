import React from 'react'
import PropTypes from 'prop-types'
import Payments from './Dashboard/Payments'
import Navbar from './Dashboard/NavBar'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: "Payments"
    }

    this.setAppState = this.setAppState.bind(this)
  }

  setAppState(newState) {
    this.setState(newState)
  }

  renderTab() {
    let tab = null
    switch(this.state.activeTab) {
      case "Payments":
        tab = <Payments
          transactionItems={this.props.transactionItems}
          activeFilters={this.props.activeFilters}
          accounts={this.props.accounts}
          exportColumns={this.props.exportColumns}
          transactionTypes={this.props.transactionTypes}
          exportButtonIconPath={this.props.exportButtonIconPath}
          filterButtonIconPath={this.props.filterButtonIconPath} />
    }

    return <div className="tab">{tab}</div>
  }

  render() {
    const { activeTab } = this.state
    return <div className="dashboard">
      <Navbar activeTab={activeTab} setAppState={this.setAppState} />
      {this.renderTab()}
    </div>
  }
}

Dashboard.propTypes = {
  transactionItems: PropTypes.array,
  activeFilters: PropTypes.object,
  transactionTypes: PropTypes.array,
  accounts: PropTypes.array,
  exportColumns: PropTypes.object,
  exportButtonIconPath: PropTypes.string,
  filterButtonIconPath: PropTypes.string
}

Dashboard.defaultProps = {
  activeFilters: {}
}

