import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import {  pick, compact, mapKeys } from 'lodash'
import { renderTemplate } from 'lib/utilities/form'

import { usePermissionsChecks } from '..'
import EditOnPage from './edit_on_page'

import Text from '../../form_field/types/text'

import { FieldsDropdown, useReverseMappedFieldIds, setUnlinkedValue, save } from '../field'
import { setClicked, focusFieldPosition, unfocusFieldPosition } from './store'

// for thumbnail size
import { useFindFontSize, calculateChecksum } from 'components/signatures/utilities'
import { useFindFontSizeProps } from '../utilities/find_font_size'


const DEFAULT_FONT_SIZE = 30
const DEFAULT_CHECKSUM = 'none'

function useLocalFindFontSize({container, field_position, frozen, value, thumbnail, editingUnlinked}) {
  const { id, font_size: defaultFontSize } = field_position
  const ratioType = thumbnail ? "thumbnailRatio" : null
  const key = `position|${id}`

  const fontSizeHash = useSelector(state => state.font_sizes.font_sizes[key])
  const { fontSize, checksum } = fontSizeHash || {}
  const fontSizeProps = useFindFontSizeProps({
    ratioType, frozen: frozen || editingUnlinked, key
  })

  return useFindFontSize(container, {
    ...fontSizeProps, fontSize,
    defaultSize: defaultFontSize || DEFAULT_FONT_SIZE,
    defaultChecksum: checksum || DEFAULT_CHECKSUM,
    checksumOverridesLock: !thumbnail && !editingUnlinked,
    onResize: ({size, checksum}) => fontSizeProps.onResize({size, checksum}),
  }, [value])
}

export function useRenderedPositionValue({template, fields}) {
  const mappedIds = useReverseMappedFieldIds(fields)
  const values = useSelector(({form_fields: state}) => pick(state.values, Object.keys(mappedIds)), shallowEqual)
  const pathMap = useMemo(() => mapKeys(values, (_, key) => mappedIds[key]), [mappedIds, values])

  return useMemo(() => renderTemplate(template, { pathMap }), [ template, pathMap ])
}

export function usePositionValue({id, template, fields}) {
  const unlinkedValue = useSelector(({form_fields: state}) => state.unlinkedValues[id])
  const renderedValue = useRenderedPositionValue({template, fields})

  return !!unlinkedValue ? unlinkedValue : renderedValue
}

function RenderEditUnlinked({ field_position }) {
  const { id } = field_position
  const dispatch = useDispatch()

  const value = useSelector(({form_fields: state}) => state.unlinkedValues[id])

  const handleChange = useCallback(hash => {
    dispatch(setUnlinkedValue({ id, value: hash[id] }))
  }, [ dispatch, id ])

  return <Text className="edit-unlinked" field={{}} id={id} key={id} value={value} onChange={handleChange} />
}

function InnerRenderValue({field_position, value, hiding, frozen}) {
  const container = useRef()

  const { fontSize, isWorking } = useLocalFindFontSize({
    container, field_position, frozen, value
  })

  const style = {}
  if (fontSize)
    style.fontSize = fontSize

  if (isWorking || hiding) {
    style.opacity = 0
    style.transition = "opacity 0s"
  }

  return <div style={style} ref={container} className="value">{value}</div>
}

const RenderValue = React.memo(InnerRenderValue)

function contains(container, element) {
  if (container.contains(element))
    return true

  let target = element
  while (target) {
    target = target.parentNode

    if (!target) return null
    if (target == container) return true
    if (target == document) break
  }

  return false
}

function FieldPosition({ frozen, field_position }) {
  const container = useRef()
  const permissions = usePermissionsChecks('form:edit')
  const isSigned = useSelector(({form_signatures: state}) => Object.keys(state.signed_signatures).length > 0)
  const isSigning = useSelector(({form_signatures: state}) => !!state.signing_as)
  const ratio = useSelector(({form: state}) => state.ratio)

  const { id, idx, template, position, fields, label } = field_position
  const dispatch = useDispatch()
  const clicked = useSelector(state => state.form_field_positions.clicked == idx)
  const unlinked = useSelector(({form_fields: state}) => state.unlinkedValues[id] != null)
  const updating = useSelector(({form_fields: state}) => state.updatingPositions[id])

  const value = usePositionValue({id, template, fields})
  const locked = frozen || !permissions['form:edit'] || isSigned || isSigning
  const isOpen = clicked && !locked
  const editingUnlinked = unlinked && isOpen

  // Click anywhere else to remove the item
  useEffect(() => {
    if (!clicked || !container.current)
      return

    const clickOff = e => {
      if (contains(container.current, e.target) !== false)
        return

      dispatch(setClicked(null))
      dispatch(unfocusFieldPosition(id))
    }

    window.addEventListener("click", clickOff)
    return () => window.removeEventListener("click", clickOff)
  }, [container.current, clicked, id])

  const [hovering, setHovering] = useState(false)

  const classes = compact([
    "position",
    hovering ? "hovering" : null,
    clicked ? "clicked" : null
  ]).join(" ")

  const style = {
    height: '100%',
    width: '100%',
    background: '#DBDBDB'
  }

  if (editingUnlinked)
    style.fontSize = (parseInt(field_position.font_size) * ratio) + "px"

  if (hovering || clicked)
    style.background = '#C6E5FF'

  if (locked)
    style.background = null

  const click = e => {
    if (locked)
      return

    dispatch(setClicked(idx))
    dispatch(focusFieldPosition(id))
  }

  const mouseEnter = _ => setHovering(true)
  const mouseLeave = _ => setHovering(false)

  return <div ref={container} className={classes} style={style} onClick={click} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
    { editingUnlinked ?
      <RenderEditUnlinked field_position={field_position} /> :
      <RenderValue field_position={field_position} hiding={updating} value={value} frozen={frozen} />  }
    { <FieldsDropdown show={isOpen} field_position={field_position} position={position} value={value} linkedDataLabel={label} /> }
  </div>
}

function ThumbnailPosition({field_position}) {
  const { id } = field_position
  const key = `position|${id}`

  const value = usePositionValue(field_position)

  const ratio = useSelector(({form: state}) => state.thumbnailRatio)
  const { fontSize: originalFontSize, checksum} = useSelector(({font_sizes: state}) => state.font_sizes[key] || {}, shallowEqual)
  const fontSize = originalFontSize * ratio

  const style = {
    fontSize: `${fontSize <= 5 ? Math.ceil(fontSize) : fontSize}px`
  }

  const currentChecksum = useMemo(() => calculateChecksum([value]), [ value ])

  if (!fontSize || checksum != currentChecksum)
    style.display = "none"

  return <div className="value thumbnail-value" style={style}>{value}</div>
}

export default React.memo((props) => {
  const { thumbnail, field_position } = props
  const { edit_on_page } = field_position

  if (edit_on_page)
    return <EditOnPage {...props} />

  return thumbnail ? <ThumbnailPosition {...props} /> : <FieldPosition {...props} />
})
