import axios from "lib/axios";

export const loadUnitApi = async (buildingUnitId) => {
  return await axios.get(`/api/building_units/${buildingUnitId}`);
};

export const createUnitApi = async (buildingStructureId, data) => {
  return await axios.post(`/api/building_structures/${buildingStructureId}/building_units`, data);
}

export const updateUnitApi = async (buildingUnitId, data) => {
  return await axios.patch(`/api/building_units/${buildingUnitId}`, data);
}

export const archiveUnitApi = async (buildingUnitId) => {
  return await axios.delete(`/api/building_units/${buildingUnitId}`);
}
