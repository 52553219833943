import React from 'react'
import PropTypes from 'prop-types'

import SearchSuggestion from './SearchSuggestion'
class SearchSuggestionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchResults: [],
      additionalResultsCount: null
    }

    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  componentDidMount() {
    this.getSuggestions()
  }

  componentDidUpdate(prevProps) {
    this.shouldUpdateSuggestions(prevProps)
  }

  shouldUpdateSuggestions(prevProps) {
    if (prevProps.searchText != this.props.searchText)
      return this.getSuggestions()

    if (typeof(prevProps.liveOnly) == "undefined" || typeof(this.props.liveOnly) == "undefined")
      return

    if (prevProps.liveOnly != this.props.liveOnly)
      return this.getSuggestions()
  }

  handleMouseOver() {
    this.props.setAppState({ preventSearchbarBlurEvent: true })
  }

  handleMouseLeave() {
    this.props.setAppState({ preventSearchbarBlurEvent: false })
  }

  showAnySuggestions() {
    return (this.props.searchText || "").length >= 2
  }

  getSuggestions() {
    if (!this.showAnySuggestions())
      return this.setState({searchResults: []})

    const ajaxData = {
      limit: 10,
      query: this.props.searchText,
      count_results: true,
      only_live: this.props.liveOnly
    }

    $.ajax({
      url: this.props.getSuggestionsPath,
      data: ajaxData,
      method: "GET",
      dataType: "JSON",
      context: this,
      success: function (res) {
        const additionalResultsCount = res.total_results_count - res.buildings.length
        this.setState({ searchResults: res.buildings, additionalResultsCount: additionalResultsCount })
      }
    })
  }

  renderSuggestions() {
    if (!this.showAnySuggestions())
      return null

    const shouldShowCustomApplicationSuggestion = !this.props.noCustomPackages && this.state.searchResults.length < 3
    let searchSuggestionList = []

    for (let i = 0; i < this.state.searchResults.length; i++) {
      const building = this.state.searchResults[i]
      searchSuggestionList.push(
        <SearchSuggestion
          building={building}
          key={i}
          buildingsPath={this.props.buildingsPath}
          hideAddress={building.address == building.title}
        />
      )
    }

    if (shouldShowCustomApplicationSuggestion) {
      searchSuggestionList.push(
        <SearchSuggestion
          key="custom-application-suggestion"
          customApplicationSuggestion={true}
          searchText={this.props.searchText}
          customApplicationImagePath={this.props.customApplicationImagePath}
          customApplicationSuggestionCallback={this.props.customApplicationSuggestionCallback} />
      )
    }

    if (searchSuggestionList.length == 0) {
      searchSuggestionList.push(
        <SearchSuggestion
          key="empty-suggestion"
          noResults={true}
          searchText={this.props.searchText} />
      )
    }

    return <ul className="search-suggestions">{searchSuggestionList}</ul>
  }

  render() {
    if (!this.showAnySuggestions())
      return <div></div>

    return (
      <div
        className="search-suggestions-container"
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}>
        {this.renderSuggestions()}
      </div>
    )
  }
}

SearchSuggestionsContainer.propTypes = {
  getSuggestionsPath: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  buildingsPath: PropTypes.string.isRequired,
  setAppState: PropTypes.func.isRequired,
  customApplicationImagePath: PropTypes.string.isRequired,
  customApplicationSuggestionCallback:  PropTypes.func,
  noCustomPackages: PropTypes.bool
}

export default SearchSuggestionsContainer
