import React, { useEffect, useState } from 'react'
import CheckmarkIcon from './icons/CheckmarkIcon'

const PasswordRequirements = (props) => {
  const [hasUpperCase, setHasUpperCase] = useState(false)
  const [hasLowerCase, setHasLowerCase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecial, setHasSpecial] = useState(false)
  const [hasMinCharacters, setHasMinCharacters] = useState(false)

  const password = props.watch('user[password]')

  const checkUpperCase = (str) => {
    const value = str.toLowerCase() !== str
    setHasUpperCase(value)
  }

  const checkLowerCase = (str) => {
    const value = str.toUpperCase() !== str
    setHasLowerCase(value)
  }

  const checkNumber = (str) => {
    const re = /\d/
    const value = re.test(str)

    setHasNumber(value)
  }

  const checkSpecial = (str) => {
    const re = /[^0-9A-Za-z. ]/
    const value = re.test(str)

    setHasSpecial(value)
  }

  const checkLength = (str) => {
    const value = str.length >= 8
    setHasMinCharacters(value)
  }

  useEffect(() => {
    checkUpperCase(password)
    checkLowerCase(password)
    checkNumber(password)
    checkLength(password)
    checkSpecial(password)
  }, [password])

  return (
    <div className='password-requirements-container'>
      <p>Your password must contain at least:</p>
      <ul className='password-requirements display-only-desktop-flex'>
        <li style={{color: hasMinCharacters && '#4a4a4a'}}>
          8 characters {hasMinCharacters && <CheckmarkIcon />}
        </li>
        <li style={{color: hasUpperCase && '#4a4a4a'}}>
          1 uppercase {hasUpperCase && <CheckmarkIcon />}
        </li>
        <li style={{color: hasLowerCase && '#4a4a4a'}}>
          1 lowercase {hasLowerCase && <CheckmarkIcon />}
        </li>
        <li style={{color: hasNumber && '#4a4a4a'}}>
          1 number {hasNumber && <CheckmarkIcon />}
        </li>
        <li style={{ color: hasSpecial && '#4a4a4a' }}>
          1 special {hasSpecial && <CheckmarkIcon />}
        </li>
      </ul>
      <ul className='password-requirements display-only-mobile-flex'>
        <li style={{color: hasMinCharacters && '#4a4a4a'}}>
          8 characters {hasMinCharacters && <CheckmarkIcon />}
        </li>
        <li style={{color: hasUpperCase && '#4a4a4a', marginInline: '4rem auto'}}>
          1 uppercase {hasUpperCase && <CheckmarkIcon />}
        </li>
      </ul>
      <ul className='password-requirements display-only-mobile-flex'>
        <li style={{color: hasLowerCase && '#4a4a4a'}}>
          1 lowercase {hasLowerCase && <CheckmarkIcon />}
        </li>
        <li style={{color: hasNumber && '#4a4a4a', marginInline: '4rem auto'}}>
          1 number {hasNumber && <CheckmarkIcon />}
        </li>
        <li style={{color: hasSpecial && '#4a4a4a', marginInline: '4rem auto'}}>
          1 special {hasSpecial && <CheckmarkIcon />}
        </li>
      </ul>
    </div>
  )
}

export default PasswordRequirements
