import React, { Component } from "react"
import SetPresetPrices from '../Response/SetPresetPrices'
import ResetModal from '../Response/ResetModal'
import SubmitModal from '../Response/SubmitModal'
import PendingPaymentRequest from '../Response/PendingPaymentRequest'
export default class SetQuestionnaireRequestPrice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      questionnaireRequest: props.questionnaire_request,
      isDisabled: !props.questionnaire_request.needs_payment,

      showResetModal: false,
      showPriceModal: false,
      submitted: !!props.questionnaire_request.responded_at,

      preset_price: props.preset_price,
      price: props.questionnaire_request.price,

      resetCounter: 0,
      respondedAt: props.questionnaire_request.responded_at,

      viewOnly: false
    }

  }

  componentDidMount() {
    const preset_price = this.props.possible_prices[0]
    const price = preset_price.price

    if (this.state.questionnaireRequest.price !== null) {
      this.setState({
        submitted: true
      })
    }

    if (this.props.possible_prices.length === 1) {
      this.setState({
        price,
        preset_price: preset_price
      })

    }
  }

  handleChangePossiblePrice(preset_price){
    const state = { preset_price, price: preset_price.price }
    this.setState(state)
  }

  handleReset(){
    this.setState({
      resetCounter: this.state.resetCounter + 1,
      preset_price: null,
      price: null,
      submitted: false,
      showResetModal: true
    })
  }

  ajaxSubmit() {
    if (this.state.price == null)
      return

    const hash = this.state.questionnaireRequest.hashed_id
    const url = `/questionnaire_requests/${hash}/set_questionnaire_price`

    const data = {
      questionnaire_request: {
        price: this.state.price,
        preset_price_type: this.state.preset_price.is_org ? "org" : "building",
        preset_price_id: this.state.preset_price.id
      }
    }

    const success = ({request}) => {
      this.setState({
        submitted: true,
        showPriceModal: request.needs_payment,
        respondedAt: request.responded_at
      })

      this.props.onUpdatedPrice(request)
    }


    $.ajax({ type: "POST", url, data, dataType: 'json', success });
  }

  renderPrices(){
    const view = this.props.questionnaire_request.is_cancelled

    if (view)
      return (
        <div>
          <h6 className="text-left" >Questionnaire was canceled, no price was set.</h6> 
        </div>
      )

    return (
      <div>
        <div className="description">Set the price point for this Questionnaire</div>
          <div className="questionnaire-price-toggle">
            <div className="questionnaire-price-options">
              <SetPresetPrices possible_prices={this.props.possible_prices}
              onChangePossiblePrice={this.handleChangePossiblePrice.bind(this)}
              onChangePriceAmount={this.handleChangePossiblePrice.bind(this)}/>
            </div>
            <div className="button-row text-right">
              <button type="submit" className="questionnaire-pricing-btn-submit" onClick={() => this.ajaxSubmit()}>Set Price</button>
            </div>
          </div>
      </div>
    )
  }

  renderResetModal() {
    if (this.state.showResetModal) 
      return <ResetModal closeResetModal={() => this.setState({showResetModal: false})}/>
  }

  renderPriceModal(){
    if (this.state.showPriceModal) 
      return <SubmitModal closeSubmitModal={() => this.setState({showPriceModal: false})}/>    
  }
  
  render() {
    const autoPricing = this.props.auto_pricing && this.props.possible_prices.length === 1

    const pendingProps = {
      autoPricing,
      price: this.state.price,
      respondedAt: this.state.respondedAt,
      request: this.state.questionnaireRequest,
      reset: () => this.handleReset()
    }

    return(
      <div>
        <div className="questionnaire-options-container">
          <span className="building-header">{this.props.stepNumber || 2}. Pricing</span>
          {this.state.submitted ? <PendingPaymentRequest {...pendingProps} /> : this.renderPrices()}
        </div>
        {this.renderResetModal()}
        {this.renderPriceModal()}
      </div>
    )
  }
};

