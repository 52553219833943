import React from 'react'
import PropTypes from 'prop-types'
import VotesCheckbox from './votes_checkbox'
import CastVotesHeader from './cast_votes_header'
import SubmitVoteButton from './submit_vote_button'
import VotesTable from './votes_table'

export default class VotesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vote_value: this.props.stage_data.vote_value,
      vote_date: this.props.stage_data.vote_date,
      address: this.props.stage_data.address,
      unit: this.props.stage_data.unit,
      completed: this.props.stage_data.vote_value !== "",
      selectedOption: ""
    }
    this.handleUpdateSelectedOption = this.handleUpdateSelectedOption.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleUpdateSelectedOption(option) {
    let newOption = option == this.state.selectedOption ? "" : option;
    this.setState({selectedOption: newOption})
  }

  handleSave() {
    const path = this.props.save_path;
    swal({
      title: "Are you sure?",
      text: "You will not be able to re-cast your vote after this.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Confirm Vote",
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
      }, function(isConfirm){
        if (isConfirm) {
          $.ajax({
            url: path,
            method: "post",
            dataType: 'json',
            data: {vote_value: this.state.selectedOption},
            context: this,
            success: (res) => {
              if (res.success) {
                this.setState({completed: true, vote_value: this.state.selectedOption, vote_date: res.vote_date});
                swal.close()
              } else {
                swal("Error", res.errors, 'error')
              }
            }
          })
        }
    }.bind(this));
  }

  render() {
    const { address, unit, selectedOption, completed, vote_value, vote_date } = this.state;
    const checkboxOptions = ["Yes", "No", "Abstain"]
    let checkboxOptionsList = []
    for (let i = 0; i < checkboxOptions.length; i++) {
      let checkboxItem
      if (completed) {
        checkboxItem = <VotesCheckbox key={"option-"+checkboxOptions[i]} option={checkboxOptions[i]} checked={vote_value == checkboxOptions[i]} vote_date={vote_date} />
      } else {
        checkboxItem = <VotesCheckbox key={"option-"+checkboxOptions[i]} option={checkboxOptions[i]} checked={checkboxOptions[i] == selectedOption} handleUpdateSelectedOption={this.handleUpdateSelectedOption} />
      }
      checkboxOptionsList.push(checkboxItem)
    }
    return (
      <div id="vote-container">
        <CastVotesHeader address={address} unit={unit} completed={completed} />
        {checkboxOptionsList}
        <SubmitVoteButton submittable={!completed && selectedOption !== ""} handleSave={this.handleSave} />
        {this.props.stage_data.board_members ? <VotesTable boardMembers={this.props.stage_data.board_members} currentUserEmail={this.props.current_user_email} voteValue={vote_value} /> : ""}
      </div>
    )
  }
}

VotesContainer.propTypes = {
  stage_data: PropTypes.object.isRequired,
  save_path: PropTypes.string.isRequired,
  current_user_email: PropTypes.string.isRequired
}
