import React, { useRef } from 'react'

const PhoneField = props => {
  const { placeholder, name, handleUpdate, value, required } = props

  const inputRef = useRef(null)

  const formattedPhone = phone => {
    if (!phone)
      return ''

    const phoneWithoutDashes = phone.replace(/-/g, ''),
          inputLength = phoneWithoutDashes.length
    let formatted = ''

    if (inputLength > 6) {
      formatted = phoneWithoutDashes.substr(0, 3) + '-' + phoneWithoutDashes.substr(3, 3) + '-' + phoneWithoutDashes.substr(6, 4)
    } else if (inputLength > 3) {
      formatted = phoneWithoutDashes.substr(0, 3) + '-' + phoneWithoutDashes.substr(3, 3)
    } else {
      formatted = phoneWithoutDashes
    }

    return formatted
  }

  const handleChange = value => {
    if (value !== formattedPhone(value)) {
      inputRef.current.value = formattedPhone(value)

      handleUpdate(formattedPhone(value))
    } else {
      handleUpdate(value)
    }
  }

  return (
    <input type='text'
          //  style={styling || ''}
           ref={inputRef}
           className='form-control'
           placeholder={placeholder || ''}
           name={name || ''}
           onChange={event => handleChange(event.target.value)}
           defaultValue={formattedPhone(value)}
           required={required || false} />
  )
}

export default PhoneField
