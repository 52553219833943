import React, { useEffect } from 'react'
import { useWatchableRef } from 'lib/hooks'
import { useClasses } from 'lib/utilities/react'


function Modal({open, header, footer, children, onClose, className}) {
  const modalRef = useWatchableRef()

  useEffect(() => {
    if (!modalRef.current) return;

    const handleClose = (_e) => onClose();
    const localRefValue = modalRef.current;

    $(localRefValue).on("hidden.bs.modal", handleClose);

    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);
  
  const classes = useClasses([
    'modal', 
    'fade', 
    className].flat()
  )

  return (<div ref={modalRef.ref} className={classes} tabIndex="-1" role="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        { header ? <div className="modal-header">{header}</div> : null }
        <div className="modal-body">
          { children }
        </div>
        { footer ? <div className="modal-footer">{footer}</div> : null }
      </div>
    </div>
  </div>)
}

export default Modal