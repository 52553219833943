import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ExistingForm from './existing_form'

export default class LeaseForms extends React.Component {
  constructor(props) {
    super(props)

    this.reorderForms = this.reorderForms.bind(this)
  }

  componentDidMount() {
    this.el = $('#existing-' + this.props.selector + 's-list')

    this.el.sortable({
      update: this.reorderForms,
      cancel: ".not-draggable",
      handle: '.up-down-arrows',
      start: (evt, ui) => { $(ui.item).addClass("is-dragging") },
      stop: (evt, ui) => { $(ui.item).removeClass("is-dragging") }
    }).disableSelection()
  }

  render() {
    return this.renderFormsArea()
  }

  normalizeSortOrder(forms) {
    const compareSortOrder = (form1, form2) => form1.sort_order - form2.sort_order

    let includedForms = forms.filter(form => form.included).sort((form1, form2) => compareSortOrder(form1, form2))
    let excludedForms = forms.filter(form => !form.included).sort((form1, form2) => compareSortOrder(form1, form2))

    for (let i = 0; i < includedForms.length; i++) includedForms[i].sort_order = i
    for (let i = 0; i < excludedForms.length; i++) excludedForms[i].sort_order = i

    return includedForms.concat(excludedForms)
  }

  reorderForms() {
    let reorderedElements = $('.edit-building-' + this.props.selector).toArray()
    let formIds = reorderedElements.map((el) => $(el).data('id'))

    this.props.sortFormsInOrder(formIds)
  }

  renderFormsArea() {
    if (!this.props.included && this.props.forms.length == 0) {
      if (this.props.defaults) {
        return (
          <div>All organization defaults are included for this building</div>
        )
      } else {
        return (
          <div>{this.props.title}s are managed by the building</div>
        )
      }
    }

    return (
      <div className="existing-building-lease-forms">
        <div className="forms-table-header">
          <div className="col-md-1"></div>
          <div className="col-md-2 text-left underscoded-title">Form ID</div>
          <div className="col-md-9 text-left underscoded-title">Title</div>
        </div>
        <div id={`existing-${this.props.selector}s-list`} >
          {this.renderFormsList()}
        </div>
      </div>
    )
  }

  deleteForm(formId) {
    this.props.deleteForm(formId)
  }

  updateForm(form) {
    const forms = this.props.all_forms
    const formIndex = forms.findIndex(f => f.id == form.id)
    forms[formIndex] = form

    this.props.updateForms(forms)
  }

  renderFormsList() {
    const formsList = this.props.forms.map(form => {
      return (
        <div key={form.id} className="existing-form">
          <ExistingForm
            form={form}
            included={this.props.included}
            selector={this.props.selector}
            buildingId={this.props.buildingId}
            deleteForm={(formId) => this.deleteForm(formId)}
            updateFormIdAndTitle={(id, formId, formTitle) => this.updateForm(id, formId, formTitle)}
            toggleIncluded={this.props.toggleIncluded}
            updateForm={updatedForm => this.updateForm(updatedForm)}
            refreshForms={() => this.props.refreshForms()}
          />
        </div>
      );
    })

    return formsList
  }
}

LeaseForms.propTypes = {
  refreshForms: PropTypes.func.isRequired
}
