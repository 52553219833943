import React, { useState, useEffect, useRef } from "react"

const CheckEmailModal = props => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(true)
  const hide = () => $(ref.current).modal("hide")
  const cleanup = () => setIsOpen(false)
  useEffect(() => {
    props.updateParentState(isOpen)
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  })

  useEffect(() => {
    if (isOpen) {
      props.updateParentState(isOpen)
    } else {
      props.updateParentState(isOpen)
      hide()
    }
  }, [isOpen])
  return (
    <div ref={ref} className='modal'>
      <div id="check-email-modal" className='modal-box'>
        <img src={`${props.envelope}`} alt="envelope with notification" />
        <h2>check your email</h2>
        <div>
          <p>
            If there's a Domecile account associated with this email
            address, a link to reset the password will be sent to <span className="user-email">{props.email}</span>.
            If you do not see the email, please check your junk folder.
          </p>
          <p>
            questions? <a href='https://www.boardpackager.com/about/contact_us' className="link-aqua link-chevron-right">contact us</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default CheckEmailModal
