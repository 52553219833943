import React from 'react'
import PropTypes from 'prop-types'

import { addComponent } from './index'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
class AddNewDealPartyForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      nameValue: null,
      emailValue: null,
      roleValue: null
    }

    this.form = null
    this.nameInput = null
    this.emailInput = null
    this.roleInput = null


    this.setFormRef = element => { this.form = element }
    this.setNameInputRef = element => { this.nameInput = element }
    this.setEmailInputRef = element => { this.emailInput = element }
    this.setRoleInputRef = element => { this.roleInput = element }
  }

  updateValue(inputType, value) {
    switch(inputType) {
      case 'name':
        this.setState({ nameValue: value })
        break;
      case 'email':
        this.setState({ emailValue: value })
        break;
      case 'role':
        this.setState({ roleValue: value })
        break;
    }
  }

  addDealParty(e) {
    const { nameValue, emailValue, roleValue } = this.state

    this.props.addRecipient(nameValue, emailValue, roleValue)

    this.clearInputs()
  }

  clearInputs() {
    $(this.nameInput).val(null)
    $(this.emailInput).val(null)
    $(this.roleInput).val(null)

    this.setState({ nameValue: null, emailValue: null, roleValue: null })
  }

  areAllInputsValid() {
    const { nameValue, emailValue, roleValue } = this.state

    return nameValue && emailValue && roleValue && validateEmail(emailValue)
  }

  renderNameInput() {
    return <div className='row'>
      <div className='col-xs-2'>
        <label htmlFor="deal-party-name">Name</label>
      </div>
      <div className='col-xs-10'>
        <span className='form-asterisk'>*</span>
        <input type="text"
          name="deal-party-name"
          className='form-control'
          ref={this.setNameInputRef}
          onChange={event => this.updateValue('name', event.target.value)}
          required />
      </div>
    </div>
  }

  renderEmailInput() {
    return <div className='row'>
      <div className='col-xs-2'>
        <label htmlFor="deal-party-email">Email</label>
      </div>
      <div className='col-xs-10'>
        <span className='form-asterisk'>*</span>
        <input type="text"
          name="deal-party-email"
          className='form-control'
          ref={this.setEmailInputRef}
          onChange={event => this.updateValue('email', event.target.value)}
          required />
      </div>
    </div>
  }

  roleOptionsList() {
    const optionsList = [
      <option disabled value='blank' key='blank'>Choose Role</option>
    ]

    this.props.roles.forEach(role => {
      optionsList.push(<option key={role} value={role}>{role}</option>)
    })

    return optionsList
  }

  renderRoleInput() {
    return <div className='row'>
      <div className='col-xs-2'><label htmlFor="deal-party-role">Role</label></div>
      <div className='col-xs-10'>
        <span className='form-asterisk'>*</span>
        <select name="deal-party-role"
          defaultValue='blank'
          ref={this.setRoleInputRef}
          className='form-control'
          onChange={event => this.updateValue('role', event.target.value)}
          required>
            {this.roleOptionsList()}
        </select>
      </div>
    </div>
  }

  renderAddButton() {
    return <div style={{textAlign: 'right'}}>
      <button type='button'
        disabled={!this.areAllInputsValid()}
        onClick={event => this.addDealParty(event)}
        className='btn btn-warning add-btn'>Add</button>
    </div>
  }

  render() {
    return <div className='add-new-deal-party-form' ref={this.setFormRef}>
      {this.renderNameInput()}
      {this.renderEmailInput()}
      {this.renderRoleInput()}
      {this.renderAddButton()}
    </div>
  }
}

AddNewDealPartyForm.propTypes = {
  addRecipient: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired
}

addComponent("AddNewDealPartyForm", AddNewDealPartyForm)

export default AddNewDealPartyForm
