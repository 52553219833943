import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import DropdownMenu from "./DropdownMenu";
import { buildingUnitEditPageState } from "../store/slice";

export default function BuildingResidentTableRow({ resident }) {
  const { currentOwner, currentRenter, isOwnerFormShown, isRenterFormShown } = useSelector(buildingUnitEditPageState);

  const isEditIconShown = useMemo(() => {
    if (!isOwnerFormShown && !isRenterFormShown) return true;

    if (isOwnerFormShown && resident.resident_role === 'owner' && currentOwner.f_id === resident.f_id) return false;
    
    if (isRenterFormShown && resident.resident_role === 'renter' && currentRenter.f_id === resident.f_id) return false;

    return true;
  }, [currentOwner, currentRenter]);

  return (
    <>
      <tr>
        <td>{resident.first_name}</td>
        <td>{resident.last_name}</td>
        <td>{resident.email}</td>
        <td>{resident.phone_number}</td>
        <td>
          <DropdownMenu item={resident} isEditIconShown={isEditIconShown}/>
        </td>
      </tr>
    </>
  );
}
