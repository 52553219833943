import React, { useMemo } from 'react'
import { sumBy } from 'lodash-es'

export default function List({ buildingId, buildingStructures }) {
  const metaData = useMemo(() => {
    const totalAddress = buildingStructures.length
    const totalUnits = sumBy(buildingStructures, (o) => o.number_of_units)
    const totalActiveUnits = sumBy(buildingStructures, (o) => o.active_unit_count)
    const ownerCount = sumBy(buildingStructures, (o) => o.owner_count)
    const renterCount = sumBy(buildingStructures, (o) => o.renter_count)
    return { totalAddress, totalUnits, totalActiveUnits, ownerCount, renterCount }
  }, [JSON.stringify(buildingStructures)])

  const renderDefaultStructureIcon = (buildingStructure) => {
    if (!buildingStructure.default) return null

    return (
      <a href="#" className="dome-link dome-link-aqua ml-6">
        <i className="fa-thin fa-location-dot"></i>
      </a>
    )
  }

  return (
    <>
      <div className="building-structures-table-wrapper">
        <table className="dome-table dome-table-striped dome-table-tight dome-full-width">
          <thead>
            <tr className="no-border">
              <th>address</th>
              <th>building name</th>
              <th className="dome-text-right">building units</th>
              <th className="dome-text-right">system units</th>
              <th className="dome-text-right">owners</th>
              <th className="dome-text-right">renters</th>
              <th></th>
            </tr>
            <tr className="annotations">
              <th colSpan={2}>{metaData.totalAddress}</th>
              <th className="dome-text-right">{metaData.totalUnits}</th>
              <th className="dome-text-right">{metaData.totalActiveUnits}</th>
              <th className="dome-text-right">{metaData.ownerCount}</th>
              <th className="dome-text-right">{metaData.renterCount}</th>
              <th className="dome-text-style-italic dome-text-right">
                <div>total</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {buildingStructures.map((bs) => (
              <tr key={bs.id}>
                <td>
                  {bs.default_additional_address.address}
                  {renderDefaultStructureIcon(bs)}
                </td>
                <td>{bs.name}</td>
                <td className="dome-text-right">{bs.number_of_units}</td>
                <td className="dome-text-right">{bs.active_unit_count}</td>
                <td className="dome-text-right">{bs.owner_count}</td>
                <td className="dome-text-right">{bs.renter_count}</td>
                <td>
                  <div className="actions">
                    <a
                      className="dome-link dome-link-chevron-right dome-is-button dome-link-green-stroke"
                      href={`/buildings/${buildingId}/building_structures/${bs.id}`}
                    >
                      open
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
