import React, { createRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Form from "./Form"
import { useEventListeners } from '@deathbyjer/react-event-manager'
import { actions } from './Store'

const Modal = (props) => {
  const modalRef = createRef()
  const dispatch = useDispatch()

  const { setFormData, setMode, clear } = actions

  useEffect(() => {
    const modal = $(modalRef.current)
    modal.modal("hide")

    modal.on('hidden.bs.modal', () => dispatch(clear()))
  }, [])

  const closeModal = () => $(modalRef.current).modal('hide')

  useEventListeners({
    [`additional_contacts:modal:create`]: ({formData}) => {
      dispatch(setFormData(formData))
      dispatch(setMode('create'))

      $(modalRef.current).modal('show')
    },
    [`additional_contacts:edit:requirements`]: ({ formData }) => {
      dispatch(setFormData(formData))
      dispatch(setMode('edit_requirements'))
      $(modalRef.current).modal('show')
    }
  })


  return (
    <div id='additional-contacts-modal' className='modal fade' tabIndex='-1' role='dialog' ref={modalRef}>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <Form requirementsAndDocuments={props.requirementsAndDocuments}
            contactTitles={props.contactTitles}
            close={closeModal}
            packageId={props.packageId} />
        </div>
      </div>
    </div>
  )
}

export default Modal
