import { useCallback, useEffect, useMemo, useState } from "react";

export default function useSortableTable(data, defaultSortField, defaultSortOrder = 'asc') {
  const [tableData, setTableData] = useState(data);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  const handleSorting = useCallback((sortField, sortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  }, []);

  const clearSorting = useCallback(() => handleSorting(null, sortOrder), [handleSorting]);

  useEffect(() => {
    let newData = data;

    if (sortField) {
      newData = [...data].sort((a, b) => {
        if (a[sortField] === b[sortField] || (a[sortField] === null && b[sortField] === null)) return 0;
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;

        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
    }

    setTableData(newData);
  }, [data, sortField, sortOrder]);

  return useMemo(
    () => ({
      tableData,
      handleSorting,
      sortField,
      clearSorting,
      sortOrder,
    }),
    [tableData, handleSorting, sortField, clearSorting, sortOrder]
  );
}
