import React from 'react'

function Remove(props) {
  
  const confirmClick = () => props.destroyPackageUser(props.collaborator)
  const cancelClick = () => {
    props.noRequirements ? props.cancelPolicyChange() : props.changeTab('DEFAULTTAB')
  }

  return (
    <div className='confirm-package-user-destroy-tab'>
      <p>Are you sure? This user will no longer be able to access this {props.tag}.</p>
      <button className='pull-left margin-left swal-neutral-button' onClick={cancelClick}>Cancel</button>
      <button className='pull-right margin-right swal-success-button' onClick={confirmClick}>Confirm</button>
    </div>
  )
}

export default Remove
