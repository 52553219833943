import React, { useContext, useEffect, useState } from 'react'
import { Provider, useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'

import InsuranceBuildingSearch from './InsuranceBuildingSearch'
import EstimatedQuoteResult from './EstimatedQuoteResult'
import GetQuoteButton from '../button'
import { checkQuoteServiceAvailibility, setCurrentUser } from '../slice/slice'
import { store } from 'components/insurance/store/store'
import XIcon from '/app/assets/images/insurance/icons/x-icon.svg'
import { InstantQuoteLookupOverlayContext } from './overlay'

const STEPS = { search: 'search', selected: 'selected', not_enabled: 'not_enabled' }

function getStepBasedOnBuilding(building) {
  if (!building) return STEPS.search
  if (building['insurance_quoting_eligible?'] === false) return STEPS.not_enabled
  return STEPS.selected
}

function NotEnabledBuilding({ building, canGoBack, onBack }) {
  const { overlayAttached, onClose } = useContext(InstantQuoteLookupOverlayContext)

  return (
    <>
      <div className="estimated-result dome-d-flex dome-justify-between dome-align-start">
        <div className="dome-h2 dome-color-darkest-grey">{building?.title}</div>
        {overlayAttached && (
          <button className="dome-btn dome-btn-link" onClick={onClose}>
            <img src={XIcon} />
          </button>
        )}
      </div>
      <div>
        <button
          className={clsx(
            'dome-btn dome-btn-link dome-btn-link-chevron-left dome-btn-link-aqua',
            !canGoBack && 'dome-btn-link-disabled'
          )}
          onClick={onBack}
          disabled={!canGoBack}
        >
          edit
        </button>
      </div>
      <hr />
      <div className="dome-p1 dome-color-med-grey">
        Looks like insurance is not available for this property yet. For more information,{' '}
        <a
          href="https://boardpackager.freshdesk.com/support/tickets/new"
          target="_blank"
          className="dome-link dome-link-aqua"
          style={{ fontSize: '16px' }}
        >
          contact us.
        </a>
      </div>
    </>
  )
}

function InstantQuoteLookup({ building: defaultBuilding, currentUser, packageId, insuranceType, hideOfflineServiceMessage }) {
  const [currentBuilding, setCurrentBuilding] = useState(defaultBuilding)
  const [step, setStep] = useState(() => getStepBasedOnBuilding(defaultBuilding))
  const { overlayAttached, visible } = useContext(InstantQuoteLookupOverlayContext)

  const dispatch = useDispatch()

  const onBuildingSelect = (building) => {
    if (!building?.id) return
    setCurrentBuilding(building)
    const newStep = getStepBasedOnBuilding(building)
    setStep(newStep)
  }

  const onBack = () => {
    setCurrentBuilding(null)
    setStep(STEPS.search)
  }

  useEffect(() => {
    dispatch(setCurrentUser(currentUser))
    dispatch(checkQuoteServiceAvailibility())
  }, [dispatch])

  useEffect(() => {
    const canGoBack = !defaultBuilding

    if (overlayAttached && canGoBack && !visible) {
      onBack()
    }
  }, [visible])

  return (
    <div className="instant-quote-lookup">
      <div className={clsx('instant-quote-lookup__step', step === STEPS.search && 'active')}>
        <InsuranceBuildingSearch onBuildingSelect={onBuildingSelect} />
      </div>
      <div className={clsx('instant-quote-lookup__step', step === STEPS.not_enabled && 'active')}>
        <NotEnabledBuilding building={currentBuilding} onBack={onBack} canGoBack={!defaultBuilding} />
      </div>
      <div className={clsx('instant-quote-lookup__step', step === STEPS.selected && 'active')}>
        <EstimatedQuoteResult
          building={currentBuilding}
          onBack={onBack}
          canGoBack={!defaultBuilding}
          packageId={packageId}
          insuranceType={insuranceType}
          active={step === STEPS.selected}
          hideOfflineServiceMessage={hideOfflineServiceMessage}
        />
      </div>
    </div>
  )
}

export default (props) => (
  <Provider store={store}>
    <InstantQuoteLookup {...props} />
  </Provider>
)
