import React, { useState, useEffect, useRef } from 'react'


export function useAsDropdown({ref: inRef, startOpened, frozen} = {}) {
  const ref = inRef || useRef()

  const [opened, setOpened] = useState(startOpened || false)

  useEffect(() => {
    if (!opened || !ref.current || frozen)
      return

    const clickElsewhere = evt => ref.current.contains(evt.target) ? null : setOpened(false) 
    window.addEventListener('click', clickElsewhere)
    return () => window.removeEventListener('click', clickElsewhere)
  })

  return { ref, opened, setOpened, frozen }
}