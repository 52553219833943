import React from "react"

import FormRow from './FormRow'
import FormSearch from './FormSearch'
import Paginate from './Paginate'

class FormTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: "*",
      forms: [],
      package_counts: {},
      total_forms: 0,
      page: 1,
      per_page: 10,
      organization_id: null,
      organizations: [],
      total_pages: 0,
      direction: "",
      sort_type: "",
      inputEmpty: true,
      sorted: false,
      search_filters: { organization_id: "" },
    }
    this.refreshForms = this.refreshForms.bind(this)
    this.handleDeleteForm = this.handleDeleteForm.bind(this)
    this.checkInputEmptyBeforeSearching =
      this.checkInputEmptyBeforeSearching.bind(this)
  }

  componentDidMount() {
    this.renderPrepopulatedForms()
    this.getOrganizations()
  }

  getOrganizations() {
    $.ajax({
      url: "/super/forms/get_organizations",
      method: "GET",
      dataType: "JSON",
      context: this,
      success: function (res) {
        this.setState({ organizations: res.organizations })
      },
    })
  }

  renderPrepopulatedForms() {
    $.ajax({
      url: "/super/forms/",
      method: "GET",
      dataType: "JSON",
      context: this,
      success: function (res) {
        this.setState({
          forms: res.forms,
          package_counts: res.package_counts,
          total_forms: res.total_forms,
          total_pages: this.state.inputEmpty ? 0 : res.total_pages,
          per_page: res.per_page,
        })
      },
    })
  }

  resetState() {
    return {
      search: "",
      direction: "",
      page: 1,
      sort_type: "",
      inputEmpty: true,
      sorted: false,
    }
  }

  checkInputEmptyBeforeSearching(text) {
    const state = this.resetState()
    if (text === "" && this.state.organization_id) {
      this.setState(state, () => this.refreshForms())
    } else if (text === "") {
      this.setState(state, () => this.renderPrepopulatedForms())
    } else {
      this.setState({ inputEmpty: false, search: text }, () =>
        this.refreshForms()
      )
    }
  }

  refreshForms() {
    let currentSearch = this.state.search

    $.ajax({
      url: "/super/forms/search_forms",
      data: {
        search: this.state.inputEmpty ? "*" : currentSearch,
        page: this.state.page,
        organization: this.state.organization_id,
        sort: this.state.sort_type,
        direction: this.state.direction ? "asc" : "desc",
      },
      method: "GET",
      dataType: "JSON",
      context: this,
      success: function (res) {
        this.setState({
          forms: res.forms,
          package_counts: res.package_counts,
          total_forms: res.total_forms,
          total_pages: res.total_pages,
          search: currentSearch,
        })
      },
    })
  }

  updatePageState(state) {
    this.setState({ page: state }, () => this.refreshForms())
  }

  clickSort(e, sortType) {
    e.preventDefault()
    const { sort_type, direction } = this.state
    const state = {
      page: 1,
      sort_type: sortType,
      sorted: true,
      direction: sort_type === sortType ? !direction : true,
    }

    this.setState(state, () => this.refreshForms())
  }

  handleDeleteForm(form) {
    let forms = this.state.forms.concat([])
    let index = forms.indexOf(form)
    if (index >= 0) forms.splice(index, 1)

    this.setState({
      forms: forms,
      total_forms: this.state.total_forms - 1,
      total_pages: Math.floor((this.state.total_forms - 1) / this.state.per_page) + 1,
    })

    if (this.state.forms && this.state.forms.length === 0) {
      $("#search").val("")
      this.setState({ inputEmpty: true })
    }
  }

  convertOrgNametoId(filters) {
    if (filters.organization_id === "All") return
    let organization = this.state.organizations.find(
      org => org.name === filters.organization_id
    )
    return organization.id
  }

  updateSearchFilters(filters) {
    const organizationId = this.convertOrgNametoId(filters)
    this.setState({ organization_id: organizationId, page: 1 }, () =>
      this.refreshForms()
    )
  }

  renderSVG(direction) {
    if (!this.state.sorted) return "sort-icon-forms-default"

    if (direction) return "sort-icon-forms-asc"
    if (!direction) return "sort-icon-forms-desc"
  }

  renderRows(forms_type, package_count_type) {
    if (Object.keys(forms_type || {}).length === 0) {
      return null
    }

    const rows = []
    const forms = forms_type
    forms.forEach((form, i) => {
      rows.push(
        <FormRow
          form={form}
          key={form.id}
          packageRequirements={package_count_type}
          handleDeleteForm={this.handleDeleteForm}
          searchForm={this.refreshForms}
          formDeletionAllowed={this.props.formDeletionAllowed}
        />
      )
    })
    return rows
  }

  render() {
    const { direction, forms, package_counts } = this.state
    return (
      <div className='container'>
        <div className='row'>
          <FormSearch
            formCount={this.state.total_forms}
            organizations={this.state.organizations}
            handleSearch={this.checkInputEmptyBeforeSearching}
            onChange={filters => this.updateSearchFilters(filters)}
          />
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th style={{ minWidth: "80px" }}>
                <img className={this.renderSVG(direction)} alt='' />
                <a
                  className='pull-left sortable-column-header'
                  href='javascript:void(0)'
                  onClick={e => {
                    this.clickSort(e, "id")
                  }}
                >
                  Form #
                </a>
              </th>
              <th>
                <img className={this.renderSVG(direction)} alt='' />
                <a
                  className='pull-left sortable-column-header'
                  href='#'
                  onClick={e => {
                    this.clickSort(e, "name")
                  }}
                >
                  Name
                </a>
              </th>
              <th className='sortable-column-header'>
                Packages Using This Form
              </th>
              <th className='sortable-column-header'>Date Created</th>
              <th className='sortable-column-header'>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderRows(forms, package_counts)}</tbody>
        </table>
        <Paginate
          totalPages={this.state.total_pages}
          currentPage={this.state.page}
          onClick={state => this.updatePageState(state)}
        />
      </div>
    )
  }
}

export default FormTable
