import React from 'react'

export default class MultipleAddressSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAddresses: []
    }
  }

  componentDidMount() {
    this.setState({selectedAddresses: this.props.selectedAddresses || {} })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedAddresses != prevProps.selectedAddresses)
      this.setState({ selectedAddresses: this.props.selectedAddresses || {} })

    if (this.state.selectedAddresses != prevState.selectedAddresses) {
      if (typeof(this.props.onChange) != "function")
        return
      this.props.onChange(this.state.selectedAddresses)
    }
  }

  clearData() {
    this.setState({ addresses: [], address: null, query: null })
    if (typeof(this.props.onChange) == "function")
      this.props.onChange({address: null})
  }

  renderDeleteIconForAddress(address) {
    if (typeof(this.props.handleClickRemove) != "function")
      return

    return <i className="fa fa-times delete-building" onClick={() => this.props.handleClickRemove(address)} />
  }

  renderselectedAddresses() {
    const addresses = this.state.selectedAddresses
    let addressList = []

    for(let i = 0; i < addresses.length; i++) {
      addressList.push(this.renderAddress(addresses[i]))
    }

    return addressList
  }

  renderAddress(address) {
    if (!address) return

    const input = this.props.name ? <input type="hidden" name={`${this.props.name}`} value={address} /> : ""

    return <div key={address} className="selected-building">
      <div className="address">
        <div className="title">{address}</div>
      </div>
      <div className="buttons">
        {this.renderDeleteIconForAddress(address)}
      </div>
      {input}
    </div>
  }

  render() {
    return <div className="building-select-area">
      {this.renderselectedAddresses()}
    </div>
  }
}

