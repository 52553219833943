import React from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentResident,
  setCurrentResidentAttemptToRemove,
  setIsBuildingUnitRemoveResidentConfirmationModalShown,
  setIsOwnerFormShown,
  setIsRenterFormShown,
  setOwnerFormMode,
  setRenterFormMode,
} from "../store/slice";

export default function DropdownMenu({ item, isEditIconShown }) {
  const dispatch = useDispatch();

  const onEditResident = (item) => {
    dispatch(setCurrentResident(item));

    if (item.resident_role === "owner") {
      dispatch(setOwnerFormMode("edit"));
      dispatch(setIsOwnerFormShown(true));
    }

    if (item.resident_role === "renter") {
      dispatch(setRenterFormMode("edit"));
      dispatch(setIsRenterFormShown(true));
    }
  };

  const onRemoveResident = (item) => {
    dispatch(setCurrentResidentAttemptToRemove(item));
    dispatch(setIsBuildingUnitRemoveResidentConfirmationModalShown(true));
  };

  return (
    <>
      <div className="dropdown">
        <a
          role="button"
          className="dome-link dropdown-toggle"
          id="buildingResidentActions"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
          style={{ width: "20%", display: isEditIconShown ? 'inline' : 'none' }}
        >
          <i className="fa-thin fa-pencil" />
        </a>
        <ul className="dropdown-menu" aria-labelledby="buildingResidentActions">
          <li>
            <a role="button" onClick={() => onEditResident(item)}>
              edit
            </a>
          </li>
          <li>
            <a role="button" onClick={() => onRemoveResident(item)}>
              <span className="dome-color-red-alert">remove</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
