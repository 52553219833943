import { useCallback } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { calNewRectDuplicateEl, reverseUniqueComponentKey, splitComponentKey, uniqueComponentKey } from 'components/form_builder/common/utility'
import { omit, findIndex } from 'lodash-es'
import { Tools } from 'components/form_builder/common/toolbar/Tools'
import { useEventManager } from '@deathbyjer/react-event-manager'

const initialState = {
  all: {},
  selected: [],
  newest: null,
  newestId: 0,
}

// Ids don't matter. They only need to be unique
let globalNewestId = 0

const TYPES = {
  data: {
    positionSubtypes: null
  },
  signature: {
    positionSubtypes: ['signature', 'name', 'time']
  }
}

function removeFromSignature(state, type, id, subtype) {
  if (subtype !== 'signature'){
    delete state.all[uniqueComponentKey(type, id, subtype)]
  }else{
    delete state.all[uniqueComponentKey(type, id, 'signature')]
    delete state.all[uniqueComponentKey(type, id, 'name')]
    delete state.all[uniqueComponentKey(type, id, 'time')]
  }
}

const componentSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    load(state, { payload: components }) {
      state.all = {}

      for (let component of components) {
        const newComponent = {
          id: component.id,
          rect: omit(component.position, ['page']),
          type: component.type,
          page: component.position.page,
          key: uniqueComponentKey(component.type, component.id, component.subtype),
          behaviorInputs: { label: component.label , placeholder : component.placeholder},
          ...(component.subtype && { subtype: component.subtype })
        }

        state.all[uniqueComponentKey(component.type, component.id, component.subtype)] = newComponent
      }

      state.selected = []
    },

    createComponent(state, { payload: { key, component}}) {
        state.newest = key
        state.all[key] = component
    },

    duplicateComponent(state, { payload: { key, component, duplicateComponent}}) {
        state.newest = key
        state.all[key] = duplicateComponent
    },

    updateComponentKeys(state, { payload: { deltaIds, type }}) {
      const subtypes = TYPES[type]?.positionSubtypes || [null]
      const keys = Object.entries(deltaIds).map(([oldId, newId]) => {
        return subtypes.map(subtype => ({
          id: newId,
          old: uniqueComponentKey(type, oldId, subtype),
          new: uniqueComponentKey(type, newId, subtype)
        }))
      }).flat()
//
      let selectedIndex
      for (let keyPair of keys) {
        if (state.all[keyPair.old]) {
          state.all[keyPair.new] = { ...state.all[keyPair.old], key: keyPair.new, id: keyPair.id }
          delete state.all[keyPair.old]
        }

        selectedIndex = findIndex(state.selected, key => key == keyPair.old)
        if (selectedIndex >= 0) state.selected[selectedIndex] = keyPair.new
      }

      state.newest = null
    },

    removeComponents(state, { payload: keys }) {
      for (let key of keys){
        const { type, id, subtype } = reverseUniqueComponentKey(key)
        switch(type) {
          case 'data':
            delete state.all[key]
          case 'signature':
            removeFromSignature(state, type, id, subtype)
          }
      }

      state.selected = state.selected.filter(key => !keys.includes(key))
    },

    updateDimension(state, { payload: { key, dimension, value }}) {
      state.all[key].rect = { ...state.all[key].rect, [dimension]: value }
    },

    updatePosition(state, { payload: { key, rect }}) {
      state.all[key].rect = rect
    },

    updateFieldText(state, { payload: { key, field, text }}) {
      state.all[key].behaviorInputs[field] = text
    },

    setSelected(state, { payload: selected}) {
      state.selected = selected.map(item => uniqueComponentKey(...item))
    },

  },
})

export function gatherSelectedComponents(state) {
  const { components } = state
  const { all, selected } = components

  return selected.map(key => all[key]).filter(item => item)
}

export function useComponentCreator() {
  const dispatch = useDispatch()

  return useCallback(({id, type, page, rect, options, subtype }) => {
    const _id = id ?? (globalNewestId += 1)
    const key = uniqueComponentKey(type, _id, subtype)
    const component = { ...options, type, id: _id, rect, page, subtype, key }

    dispatch(componentSlice.actions.createComponent({ key, component }))
    return component
  }, [dispatch])
}

export function useComponentDuplicate() {
  const dispatch = useDispatch()

  return useCallback((component) => {
    const _id = (globalNewestId += 1)
    const key = uniqueComponentKey(component.type, _id, component.subtype)
    const rect = calNewRectDuplicateEl({rect:component.rect, page: component.page})
    const duplicateComponent = { ...component, id: _id, key, rect }

    dispatch(componentSlice.actions.duplicateComponent({ key, component, duplicateComponent }))
    return duplicateComponent
  }, [dispatch])
}

export function useDependentFieldCreator() {
  const dispatch = useDispatch()
  const events = useEventManager()

  return useCallback(({ id, type, page, rect, subtype }) => {
    const tool = Tools[type]
    const { options } = tool.dependentFields[subtype].defaultAttributes
    const key = uniqueComponentKey(type, id, subtype)
    const component = {
      id,
      key,
      page,
      type,
      subtype,
      rect,
      ...options
    }
    dispatch(componentSlice.actions.createComponent({ key, component }))
    events.applyEventListeners('duplicateComponent', [[type, id, subtype]])
  }, [dispatch])
}

export const { load, createComponent, duplicateComponent, updatePosition, updateDimension, setSelected, updateFieldText, removeComponents, updateComponentKeys } = componentSlice.actions
export default componentSlice.reducer
