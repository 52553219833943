import React from 'react'
import Button from './Button'

const StandardLogin = (props) => {
  const handleForgotPassword = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.updateStep(3)
  }

  return (
    <div className='dome-d-flex dome-justify-between dome-align-start'>
      <Button className="forgot-password btn-link" type="button" onClick={handleForgotPassword}>
        forgot password?
      </Button>
      <Button
        type="submit"
        disabled={!props.hasInput}
        className={`btn-base ${props.hasInput ? 'btn-dark-green' : 'btn-disabled'}`}
        id="log-in-button"
      >
        {props.isLoading ? <i className="fa-duotone fa-spinner fa-spin-pulse"></i> : 'login'}
      </Button>
    </div>
  )
}

export default StandardLogin
