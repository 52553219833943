import React from 'react'
import LeaseFormTypes from './lease_form_types'

export default class BuildingLeaseForms extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      leaseTypes: props.leaseTypes,
      riders: props.riders
    }
  }

  refreshLeaseTypesAndRiders() {
    $.ajax({
      url: `/buildings/${this.props.buildingId}/leases_and_riders`,
      dataType: 'json',
      method: 'get',
      success: res => this.setState({ riders: res.riders, leaseTypes: res.lease_types })
    })
  }

  updateLeaseTypes(leaseTypes) {
    this.setState({ leaseTypes: leaseTypes })
  }

  updateRiders(riders) {
    this.setState({ riders: riders })
  }

  render() {
    return (
      <div className="row">
        <div className="building-lease-forms col-md-6" id="building-lease-types">
          <h4 className="lease-forms-header" style={{ marginBottom: "17px" }}>Lease Types</h4>
          <LeaseFormTypes
            forms={this.state.leaseTypes}
            updateForms={leaseTypes => this.updateLeaseTypes(leaseTypes)}
            buildingId={this.props.buildingId}
            title='Lease Type'
            selector='lease-type'
            defaults={this.props.defaults}
            refreshForms={() => this.refreshLeaseTypesAndRiders()}
          />
        </div>
        <div className="building-lease-forms col-md-6" id="building-riders">
          <h4 className="lease-forms-header" style={{ marginBottom: "17px" }}>Riders</h4>
          <LeaseFormTypes
            forms={this.state.riders}
            updateForms={riders => this.updateRiders(riders)}
            buildingId={this.props.buildingId}
            title='Rider'
            selector='rider'
            defaults={this.props.defaults}
            refreshForms={() => this.refreshLeaseTypesAndRiders()}
          />
        </div>
      </div>
    )
  }
}
