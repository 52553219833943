import React, { useState } from 'react'
import clsx from 'clsx'
import { Portal } from 'components/utility/portal'
import FormContractorReportModal from './modal'

export default function FormContractorReportModalButton({ buttonText, buttonClasses }) {
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <button className={clsx(buttonClasses)} onClick={handleOpenModal}>
        {buttonText}
      </button>
      <FormContractorReportModal open={openModal} onClose={handleCloseModal} />
    </>
  )
}
