import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { archiveUnitApi, loadUnitApi } from "../../apis/buildingUnit";
import { omit, some } from "lodash-es";

export const archiveUnit = createAsyncThunk(
  "buildingUnitEdit/archiveUnit",
  async (buildingUnitId, thunkAPI) => {
    try {
      const response = await archiveUnitApi(buildingUnitId);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

export const loadUnit = createAsyncThunk(
  "buildingUnitShow/loadUnit",
  async (data, thunkAPI) => {
    try {
      const response = await loadUnitApi(data.buildingUnitId);
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

const initialState = {
  buildingUnit: {},
  buildingResidents: [],
  isUnitArchived: false
};

const buildingUnitShowSlice = createSlice({
  name: "buildingUnitShow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUnit.fulfilled, (state, { payload }) => {
      state.buildingUnit = omit(payload?.building_unit, 'building_residents');
      state.buildingResidents = payload?.building_unit?.building_residents.map(building_resident => {
        let resident_role;
         if(some(building_resident.roles, ['name', 'owner'])) {
          resident_role = 'owner';
         } else if (some(building_resident.roles, ['name', 'renter'])) {
          resident_role = 'renter';
         }
         return {...building_resident, resident_role: resident_role };
      });
    },
    builder.addCase(archiveUnit.fulfilled, (state, { payload }) => {
      state.isUnitArchived = true;
    })
    );
  },
});

export const buildingUnitShowPageState = (state) => state.buildingUnitShowPage;

export const buildingUnitShowPageReducer = buildingUnitShowSlice.reducer;
