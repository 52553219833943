import React from 'react'

class OperatorDropdown extends React.Component {
  constructor(props) {
    super(props)
  }

  handleSelectChange(e) {
    let newField = { ... this.props.fieldData };
    let operator = e.target.value;
    newField.operator = operator;
    this.props.handleUpdateField(newField);
  }

  render() {
    const { fieldData, selectedFieldElementName } = this.props;
    return (
      <select className='form-control' disabled={!selectedFieldElementName} onChange={(e) => this.handleSelectChange(e)} value={fieldData.operator || ''} >
        <option value=''></option>
        <option value="sum">Sum</option>
        <option value="difference">Difference</option>
      </select>
    )
  }
}

export default OperatorDropdown