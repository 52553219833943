import React, { useState } from "react";
import RequestRefundModal from "./RequestRefundModal/RequestRefundModal.jsx";

export default function RequestRefund() {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <button
        className="request-refund-button btn-base btn-aqua-stroke"
        onClick={() => setIsModalShown(true)}
      >
        request refund
      </button>
      {isModalShown && (
        <RequestRefundModal onClose={() => setIsModalShown(false)} />
      )}
    </>
  );
}
