import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit'
import { pick, difference } from 'lodash'

import { addCSRF } from 'lib/utilities'

import { removeSignature } from '../signature/store'

const initialState = {
  first_load: true,
  locked: true,
  font_sizes: {},
  newly_resized: [],
  timeout: null
}

const WAIT_FOR_COLLECTIONS = 4000

const compactHash = hash => Object.fromEntries(Object.entries(hash).filter(([_,v]) => v))

const Store = createSlice({
  name: "font_sizes",
  initialState,
  reducers: {
    load(state, { payload: form }) {
      state.font_sizes = {}
      state.locked = true

      for (let signature of Object.values(form.signed_signatures)) {
        state.font_sizes[`signature|${signature.id}`] = { fontSize: signature.fontSize }
        state.font_sizes[`signature|${signature.id}|time`] = { fontSize: signature.timeFontSize }
        state.font_sizes[`signature|${signature.id}|name`] = { fontSize: signature.nameFontSize }
      }

      // TODO: Load field positions (position) into the state
      for (const [id, [fontSize, checksum]] of Object.entries(form.field_position_fonts)) {
        state.font_sizes[`position|${id}`] = { fontSize: fontSize, checksum: checksum }
      }

      state.font_sizes = compactHash(state.font_sizes)
      state.newly_resized = []
    },

    finishedFirstLoad(state) {
      state.first_load = false
    },

    onRemoveSignature(state, { payload: id}) {
      const check = `signature-${id}`

      for (let key in state.font_sizes)
        if (key.startsWith(check))
          delete state.font_sizes[key]
    },

    clearLock(state) {
      state.locked = false
    },

    setResized(state, { payload: [key, fontSize, checksum]}) {
      if (!key)
        return

      state.font_sizes[key] = {fontSize, checksum}
      state.newly_resized.push(key)
    },

    removeFromNewlyResized(state, { payload: newly_resized}) {
      state.newly_resized = difference(state.newly_resized, newly_resized)
    }
  }
})

const { finishedFirstLoad, clearLock, setResized, removeFromNewlyResized, onRemoveSignature } = Store.actions
export const { load } = Store.actions
export const { reducer } = Store

// todo: this is whats causing the saving to occur even when checksum matches!
export async function persistFontSizes(instance_id, font_sizes) {
  const url = `/forms/v3/instance/${instance_id}/font_sizes`

  const font_size_data = {}

  for (const [key, value] of Object.entries(font_sizes)) {
    font_size_data[key] = { fontSize: value.fontSize, checksum: value.checksum }
  }

  const data = addCSRF({
    form_instance: { font_sizes: font_size_data }
  })

  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

// Useful for when we are saving the form
export function usePersistFontSizes() {
  const dispatch = useDispatch()
  const fontSizes = useSelector(({font_sizes: state}) => state.font_sizes)
  const newlyResized = useSelect(({font_sizes: state}) => state.newly_resized)
  const instance_id = useSelect(({global}) => global.instance_id)

  const keys = [... newlyResized ]
  dispatch(removeFromNewlyResized(keys))
  return async () => await persistFontSizes(instance_id, pick(fontSizes, keys))
}

const standardMiddleware = createListenerMiddleware()
const persistMiddleware = createListenerMiddleware()

persistMiddleware.startListening({
  actionCreator: setResized,
  effect: async (action, { delay, dispatch, cancelActiveListeners, getState }) => {
    // Give a chance for all the iterators to finish
    await delay(WAIT_FOR_COLLECTIONS)
    cancelActiveListeners()

    const { font_sizes: state, global, form_signatures, field_positions } = getState()
    const keys = [... state.newly_resized ]

    // Nothing to do
    if (keys.length == 0)
      return

    // Automatic persistence only when signing ...
    // const isSigning = Object.keys(form_signatures.signed_signatures).length != 0
    // const isFillingFieldPositions = Object.keys(field_positions.)
    // ... or all the font sizes are new ones on a first load
    // todo: saves font-sizes during first load so that font-size doesnt have to be recalculated on refresh/next loads
    const emptyFirstLoad = keys.length == Object.keys(state.font_sizes).length && state.firstLoad

    // // if (!isSigning && !emptyFirstLoad)
    // if (!emptyFirstLoad)
    //   return

    dispatch(finishedFirstLoad())
    dispatch(removeFromNewlyResized(keys))

    await persistFontSizes(global.instance_id, pick(state.font_sizes, keys))
  }
})

standardMiddleware.startListening({
  actionCreator: load,
  effect: (action, { dispatch }) => {
    setTimeout(() => dispatch(clearLock()), 2000)
  }
})

// For positions, they are always on the page and thus don't need to be cleared.
// Signatures, however, are removed when there is no signature. Therefore, they need to be
// cleared. (They will be cleared anyway as the signed_signature is removed)
standardMiddleware.startListening({
  actionCreator: removeSignature,
  effect: ( { payload: id }, { dispatch }) => {
    dispatch(onRemoveSignature(id))
  }
})

export const middleware = [standardMiddleware.middleware, persistMiddleware.middleware]

export function useFindFontSizeProps({ key, ratioType, frozen } = {}) {
  const ratio = useSelector(({form}) => form[ratioType || "ratio"])
  const fontSize = useSelector(({font_sizes: state}) => key ? state.font_sizes[key]?.fontSize : null)

  const lock = useSelector(({font_sizes: state}) => state.locked )
  const loaded = useSelector(({global: state}) => state.loaded)
  const locked = fontSize && lock || !loaded || frozen

  const dispatch = useDispatch()
  const onResize = useCallback(({ size, checksum }) => {
    key ? dispatch(setResized([ key, size, checksum ])) : null
  }, [key, dispatch])

  return {
    ratio, fontSize, locked, onResize
  }
}
