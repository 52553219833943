import axios from 'lib/axios'
import { addCSRF } from 'lib/utilities'

export function getMetadata() {
  const url = "/buildings/metadata"

  return new Promise((res, rej) => {
    axios.get(url).then(response => res(response.data)).catch(() => rej())
  })
}

export function getUnitsForStructure(building_id, structure_id) {
  const url = `/buildings/${building_id}/building_structures/${structure_id}/units`

  return new Promise((res, rej) => {
    axios.get(url).then(response => res(response.data.units.map(unit => unit.unit_number))).catch(() => rej())
  })
}

export function getPathValues(paths, { building_id, structure_id, unit}) {
  const url = `/buildings/${building_id}/gather_from_path`
  const params = {
    paths,
    building_structure_id: structure_id, 
    building_unit_number: unit
  }

  return new Promise((res, rej) => {
    axios.post(url, addCSRF(params)).then(response => res(response.data.data)).catch(() => rej())
  })
}

export function storePathValues(values, { building_id, structure_id, unit}) {
  const url = `/buildings/${building_id}/data_from_paths`
  const params = {
    values,
    building_structure_id: structure_id, 
    building_unit_number: unit
  }

  return new Promise((res, rej) => {
    axios.post(url, addCSRF(params)).then(() => res()).catch(() => rej())
  })
}