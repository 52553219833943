import clsx from 'clsx'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDependentFieldCreator } from '../features/components/componentSlice'
import { calNewRectDuplicateEl } from '../common/utility'

export const DependentFieldModal = ({ signatureComponent }) => {
  const { id, type, page, rect } = signatureComponent
  const ref = useRef()
  const drawAreaEl = useMemo(() => document.querySelector('.draw-area-component'), [])
  const fullName = useSelector((state) => state.data.signature[id]?.positions?.name)
  const dateSigned = useSelector((state) => state.data.signature[id]?.positions?.time)

  const createDependentFieldComponent = useDependentFieldCreator()

  const createComponent = (subtype) =>
    createDependentFieldComponent({ id, type, page, rect: calNewRectDuplicateEl({ rect, page }), subtype })

  const atRightConner = useMemo(() => {
    if (!ref.current) return
    if (rect.x + rect.width + ((ref.current.clientWidth + 20) / drawAreaEl.clientWidth) * 100 > 100) return true
  }, [rect])

  const atTopConner = useMemo(() => {
    if (!ref.current) return
    if (rect.y + rect.height - (ref.current.clientHeight / drawAreaEl.clientHeight) * 100 < 0) return true
  }, [rect])

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        right: !atRightConner && '-245px',
        left: atRightConner && '-245px',
        bottom: !atTopConner && '-5px',
        top: atTopConner && '-5px',
        backgroundColor: 'white',
        border: '1px solid #F0F0F0',
        borderRadius: '6px',
        padding: '18px 12px',
        boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
        pointerEvents: 'initial',
      }}
    >
      <div className="dome-d-flex dome-gap12 dome-flex-column">
        <div className="dome-p2-lt">add any dependent field:</div>
        <div className="dome-d-flex dome-gap6 dome-p2">
          <button
            disabled={dateSigned}
            onClick={() => createComponent('time')}
            type="button"
            className={clsx('dome-btn dome-btn-base dome-btn-grey-stroke', dateSigned && 'dome-btn-disabled')}
          >
            date signed
          </button>
          <button
            disabled={fullName}
            onClick={() => createComponent('name')}
            type="button"
            className={clsx('dome-btn dome-btn-base dome-btn-grey-stroke', fullName && 'dome-btn-disabled')}
          >
            full name
          </button>
        </div>
      </div>
    </div>
  )
}
