import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty, uniq } from "lodash-es";
import { createUnitApi } from "../../apis/buildingUnit";

export const createUnit = createAsyncThunk(
  "buildingUnitNew/createUnit",
  async (data, thunkAPI) => {
    try {
      const response = await createUnitApi(
        data.buildingStructureId,
        data.payload
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

const initialState = {
  buildingUnit: { unit_number: null },
  buildingResidents: [],
  currentOwner: {},
  currentRenter: {},
  isOwnerFormShown: false,
  isRenterFormShown: false,
  ownerFormMode: null,
  renterFormMode: null,
  isSubmiting: false,
  isBuildingUnitCreatedModalShown: false,
  errorMessages: [],
  isBuildingUnitRemoveResidentConfirmationModalShown: false,
  currentResidentAttemptToRemove: {}
};

const buildingUnitEditSlice = createSlice({
  name: "buildingUnitNew",
  initialState,
  reducers: {
    resetState: (_state, { _payload }) => {
      return initialState;
    },
    addResident: (state, { payload }) => {
      state.buildingResidents = [...state.buildingResidents, payload];
    },
    setCurrentResident: (state, { payload }) => {
      const resident = state.buildingResidents.find(
        (buildingResident) => buildingResident.f_id === payload.f_id
      );
      if (resident.resident_role === "owner") {
        state.currentOwner = resident;
      } else if (resident.resident_role === "renter") {
        state.currentRenter = resident;
      }
    },
    resetCurrentResident: (state, { payload }) => {
      if (isEmpty(payload)) return;

      if (payload === "owner") state.currentOwner = {};

      if (payload === "renter") state.currentRenter = {};
    },
    setIsOwnerFormShown: (state, { payload }) => {
      state.isOwnerFormShown = payload;
    },
    setIsRenterFormShown: (state, { payload }) => {
      state.isRenterFormShown = payload;
    },
    setOwnerFormMode: (state, { payload }) => {
      state.ownerFormMode = payload;
    },
    setRenterFormMode: (state, { payload }) => {
      state.renterFormMode = payload;
    },
    updateResident: (state, { payload }) => {
      const residentIndex = state.buildingResidents.findIndex(
        (buildingResident) => buildingResident.f_id === payload.f_id
      );
      state.buildingResidents[residentIndex] = payload;
    },
    setIsBuildingUnitRemoveResidentConfirmationModalShown: (state, { payload }) => {
      state.isBuildingUnitRemoveResidentConfirmationModalShown = payload;
    },
    setCurrentResidentAttemptToRemove: (state, { payload }) => {
      state.currentResidentAttemptToRemove = payload;
    },
    removeResident: (state, { _payload }) => {
      state.buildingResidents = state.buildingResidents.filter(resident => (resident?.f_id && resident.f_id !== state.currentResidentAttemptToRemove.f_id) || (resident?.id && resident.id !== state.currentResidentAttemptToRemove.id));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createUnit.pending, (state, { payload }) => {
      state.isSubmiting = true;
    }),
      builder.addCase(createUnit.fulfilled, (state, { payload }) => {
        const { building_unit } = payload;
        state.buildingUnit = building_unit;
        state.isSubmiting = false;
        state.isBuildingUnitCreatedModalShown = true;
      }),
      builder.addCase(createUnit.rejected, (state, { payload }) => {
        if (payload?.error) {
          state.errorMessages = uniq(payload.error);
        } else {
          state.errorMessages = ["Some errors happened, please try again!"];
        }
        state.isSubmiting = false;
      });
  },
});

export const buildingUnitNewPageState = (state) => state.buildingUnitNewPage;

export const {
  resetState,
  addResident,
  setCurrentResident,
  setIsOwnerFormShown,
  setIsRenterFormShown,
  setOwnerFormMode,
  setRenterFormMode,
  resetCurrentResident,
  updateResident,
  setIsBuildingUnitRemoveResidentConfirmationModalShown,
  setCurrentResidentAttemptToRemove,
  removeResident
} = buildingUnitEditSlice.actions;

export const buildingUnitNewPageReducer = buildingUnitEditSlice.reducer;
