import React from 'react'
import PropTypes from 'prop-types'

import Fees from './fees'
import ReasonContainer from './reason_container'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  readyToSubmit() {
    const { reason, additionalDetails, selectedFees } = this.props

    if (!reason || reason == "") return false
    if (reason == "Other" && additionalDetails == "") return false
    if (selectedFees.some((fee) => parseInt(fee.refundAmount) == 0)) return false

    return true
  }

  renderModalHeader() {
    return (
      <div className="modal-header">
        <h4 className="modal-title text-center">Refund Fees</h4>
      </div>
    )
  }

  renderModalFooter() {
    return (
      <div className="modal-footer text-align-left">
        <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
        <button type="button"
          className="btn btn-success pull-right"
          disabled={!this.readyToSubmit()}
          onClick={(e) => this.handleClick(e)}>Confirm</button>
      </div>
    )
  }

  renderLoadingModalContent() {
    return (
      <div className="modal-content">
        {this.renderModalHeader()}
        <div className="modal-body">
          <p className="loading-dots"></p>
        </div>
      </div>
    )
  }

  renderModalContent() {
    return (
      <div className="modal-content">
        {this.renderModalHeader()}
        <div className="modal-body">
          <Fees
            fees={this.props.selectedFees}
            setAppState={this.props.setAppState}
            setFeeRefundAmount={this.props.setFeeRefundAmount}
            totalRefundAmount={this.props.totalRefundAmount} />
          <ReasonContainer
            additionalDetails={this.props.additionalDetails}
            reason={this.props.reason}
            setAppState={this.props.setAppState} />
        </div>
        {this.renderModalFooter()}
      </div>
    )
  }

  handleClick(e) {
    const {selectedFees, reason, additionalDetails, refundRequestPath} = this.props
    const feeData = selectedFees.map((fee) => { return { id: fee.id, refundAmount: fee.refundAmount } })

    this.setState({ loading: true })

    $.ajax({
      url: refundRequestPath,
      method: 'POST',
      dataType: 'JSON',
      data: { fees: feeData, reason: reason, additionalDetails: reason == "Other" ? additionalDetails : null },
      context: this,
      success: function (res) {
        if (res.error) {
          alert(res.error)
          this.setState({ loading: false })
        } else {
          window.location.reload()
        }
      }, error: function (err) {
        alert(err.responseJSON['error'])
        this.setState({ loading: false })
      }
    })
  }

  render() {
    return (
      <div id="refunds-modal" className="fade modal in" data-backdrop="static">
        <div className="modal-dialog modal-md">
          {this.state.loading ? this.renderLoadingModalContent() : this.renderModalContent()}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  selectedFees: PropTypes.array.isRequired,
  setAppState: PropTypes.func.isRequired,
  setFeeRefundAmount: PropTypes.func.isRequired,
  totalRefundAmount: PropTypes.string.isRequired,
  additionalDetails: PropTypes.string,
  reason: PropTypes.string,
  refundRequestPath: PropTypes.string.isRequired
}

export default Modal