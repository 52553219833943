import React, { useEffect, useState } from 'react'

import { ajax } from 'jquery'

const ApiContext = React.createContext()

function generateApi() {
  return {
    update(path_mapping) {

    },

    remove(paths) {

    }
  }
}

function loadBasicInfo(package_id) {
  const url = `/packages/v3/${package_id}/basic`

  return new Promise((resolve, reject) => {
    const success = data => resolve(data) 
    const error = () => reject()
    
    ajax({
      url, success, error
    })
  })
}

function Loader(prop) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loadBasicInfo(props.id).then(data => {
      setLoaded(true)
    })
  }, [props.id])

  if(!loaded)
    return null // Show loading area
}

export default React.memo(function(props) {
  const api = generateApi(props.form_id)

  return <ApiContext.Provider api={api}>
    <Loader {...props} />
  </ApiContext.Provider>
})