import React from 'react'
import PropTypes from 'prop-types'

class SelectFormRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: [],
      loadingTemplates: false,
      showTemplatesList: false
    }
  }

  componentDidMount() {
    this.getTemplatesUsingFormSection()
  }

  componentDidUpdate(prevProps, prevState) {
    const selectedForm = this.selectedForm()
    const prevSelectedForm = this.prevSelectedForm(prevProps)

    if (prevState.showTemplatesList) this.setState({ showTemplatesList: false })

    if (this.props.formRowType == "original" && prevState.templates != this.state.templates)
      this.props.setAppState({ originalFormTemplates: this.state.templates })

    if (selectedForm != prevSelectedForm) {
      if (!selectedForm) return this.setState({ templates: [], loadingTemplates: false })
      this.getTemplatesUsingFormSection()
    }

    if (prevState.templates != this.state.templates)
      this.renderTemplatesCount()
  }

  selectedForm() {
    if (!this.props.originalForm && !this.props.replacementForm) return null
    if (this.props.formRowType == "original" && this.props.originalForm) return this.props.originalForm
    if (this.props.formRowType == "replacement" && this.props.replacementForm) return this.props.replacementForm
  }

  prevSelectedForm(prevProps) {
    if (!prevProps.originalForm && !prevProps.replacementForm) return null
    if (prevProps.formRowType == "original" && prevProps.originalForm) return prevProps.originalForm
    if (prevProps.formRowType == "replacement" && prevProps.replacementForm) return prevProps.replacementForm
  }

  getTemplatesUsingFormSection() {
    const form = this.selectedForm()
    if (!form) return null

    this.setState({ loadingTemplates: true })
    $.ajax({
      url: this.props.templatesUsingFormPath,
      data: { form_id: form.id },
      dataType: 'json',
      method: 'get',
      context: this,
      success: function (res) {
        this.setState({ templates: res.templates, loadingTemplates: false })
      }
    })
  }

  renderLoading() {
    return <p className="loading-dots"></p>
  }

  renderTemplatesCount() {
    if (this.props.formRowType != "original") return
    if (this.state.loadingTemplates) return this.renderLoading()
    return (
      <div>
        <div className="row">
          <h3 onClick={() => this.toggleTemplatesList()}>{this.templatesSize()} Templates using this Form</h3>
        </div>
        <div className="row">
          {this.renderTemplatesList()}
        </div>
      </div>
    )
  }

  renderTemplatesList() {
    if (this.templatesSize == 0) return null
    let templatesList = []
    this.state.templates.forEach((template) => {
      templatesList.push(
        <p>{template.address} - {template.label}</p>
      )
    })

    return (<div className="templates-list-container">{templatesList}</div>)
  }

  toggleTemplatesList() {
    this.setState({ showTemplatesList: !this.state.showTemplatesList })
  }

  templatesSize() {
    return this.state.templates.length
  }

  renderRowForOriginalForm() {
    if (this.props.originalForm) return this.renderSelectedFormSectionRow()
    return <h3 onClick={() => this.handleClickSelectedForm()}>Click here to select form to remove from package templates</h3>
  }

  renderRowForReplacementForm() {
    if (this.props.replacementForm) return this.renderSelectedFormSectionRow()
    return <h3 onClick={() => this.handleClickSelectedForm()}>Click here to select form as replacement</h3>
  }

  renderRow() {
    return this.props.formRowType == "original" ? this.renderRowForOriginalForm() : this.renderRowForReplacementForm()
  }

  // returns "REMOVE/REPLACE or WITH" for the leftmost text
  rowLeftColumnText() {
    const updateType = this.props.updateType
    // When formRowType is "original", the text becomes "Remove" or "Replace" depending on the updateType
    return this.props.formRowType == "original" ? updateType.toUpperCase() : "WITH"
  }

  handleClickSelectedForm() {
    const {formListOpenForType, formRowType, setAppState} = this.props

    if (!formListOpenForType || formListOpenForType != formRowType)
      return setAppState({formListOpenForType: formRowType})
    return setAppState({formListOpenForType: null})
  }

  renderSelectedFormSectionRow() {
    const colOneText = this.rowLeftColumnText()
    return (
      <div className="row">
        <div className="col-xs-2"><h3>{colOneText}:</h3></div>
        <div className="col-xs-6">
          <h3 className="hover-highlight" onClick={() => this.handleClickSelectedForm()}>
            {this.props.formRowType == "original" ? this.props.originalForm.name : this.props.replacementForm.name}
          </h3>
        </div>
        <div className="col-xs-4">{this.renderTemplatesCount()}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={this.props.formListOpenForType == this.props.formRowType ? "row-selected" : ""}>
        {this.renderRow()}
      </div>
    )
  }
}

SelectFormRow.propTypes = {
  formRowType: PropTypes.string.isRequired, // "original" or "replacement"
  updateType: PropTypes.string.isRequired, // "replace" or "remove"
  setAppState: PropTypes.func.isRequired,
  templatesUsingFormPath: PropTypes.string.isRequired,
  originalForm: PropTypes.object,
  replacementForm: PropTypes.object,
  formListOpenForType: PropTypes.string // "original" or "replacement" or null
}

export default SelectFormRow
