import React, {forwardRef} from 'react'

const errorTextRed = {color: '#b30000'}
const errorBorderRed = {borderColor: '#b30000'}

const EmailField = forwardRef((props, inputRef) => {
  const { errors, currentStep, register, reset, resetPassword, onClick } = props;
  const hasError = errors?.user?.email;

  const handleClick = (e) => {
    e.stopPropagation()
    if (currentStep === 1 || !onClick) return;

    onClick(currentStep - 1)
    resetPassword?.()
  }

  const clearErrorsOnChange = (e) => {
    if (inputRef.current.value.length < 1) {
      reset()
    }
    if(currentStep === 2) handleClick(e)
  }

  const { ref, ...rest } = register("user[email]", {
    pattern: {
      value: /^.+@.+\..+$/,
      message: "Enter a valid email address.",
    },
    onChange: clearErrorsOnChange,
  })

  return (
    <div className='input-group'>
      <label htmlFor='email'>email</label>
      <div style={{position: 'relative'}}>
        <i
          className='fa-light fa-envelope input-icons'
          style={hasError ? errorTextRed : {}}
        ></i>
        <input
          {...rest}
          name={"user[email]"}
          ref={e => {
            ref(e)
            inputRef.current = e
          }}
          style={hasError ? errorBorderRed : {}}
          className='rounded-edges'
          type='email'
          id='email'
        />
        {currentStep === 2 && (
          <div tabIndex={0} className="overlay-for-disabled-input dome-full-width dome-full-height dome-cursor-pointer dome-d-flex dome-align-center" onClick={handleClick}>
            <span style={{ fontSize: '1.2rem' }}>{inputRef.current?.value}</span>
          </div>
        )}
      </div>
    </div>
  )
})

export default EmailField
