import React from 'react'
import PropTypes from 'prop-types'

class FeeRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refundAmount: parseFloat(this.props.fee.refundAmount.replace(/,/g, '')) || 0.00
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
  }

  handleInputChange(e) {
    const val = e.target.value || 0.00
    this.setState({ refundAmount: val })
  }

  maxRefundableAmount() {
    const fee = this.props.fee
    return parseFloat(fee.current_amount || fee.amount)
  }

  handleInputBlur() {
    let refundAmountInCurrency = parseFloat(this.state.refundAmount).toFixed(2)

    if (refundAmountInCurrency > this.maxRefundableAmount()) refundAmountInCurrency = 0

    this.setState({ refundAmount: refundAmountInCurrency })

    this.props.setFeeRefundAmount(this.props.selectedFeeIdx, refundAmountInCurrency)
  }

  render() {
    const { fee } = this.props
    const { refundAmount } = this.state

    return (
      <div className="modal-fee-row row">
        <div className="col-xs-8">{fee.name}</div>
        <div className="col-xs-4">
          <span className="bopa-orange">$</span>
          <input className="refund-amount-input"
            type="number"
            value={refundAmount || null}
            step=".01"
            max={fee.amount}
            name="refund-amount"
            onChange={(e) => this.handleInputChange(e)}
            onBlur={() => this.handleInputBlur()} />
        </div>
      </div>
    )
  }
}

FeeRow.propTypes = {
  fee: PropTypes.object.isRequired,
  selectedFeeIdx: PropTypes.number.isRequired,
  setAppState: PropTypes.func.isRequired,
  setFeeRefundAmount: PropTypes.func.isRequired,
  selectedFeeIdx: PropTypes.number.isRequired
}

export default FeeRow