import React, { useCallback } from 'react'
import { padStart } from 'lodash'
import { format } from 'date-fns'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

const DATE_FORMAT = "MMMM d, yyyy"

function Static({date}) {
  return <div className="static-value">
    { format(date, DATE_FORMAT) }
  </div>
}

export default function({id, onChange, value, onBlur, className, system_only}) {
  const [year, month, day] = String(String(value).split(" ")[0]).split("-").map(num => parseInt(num))

  const date = new Date()
  if (year) date.setFullYear(year)
  if (month) date.setMonth(month - 1)
  if (day) date.setDate(day)

  const handleChange = useCallback(date => {
    const stringDate = [
      date.getFullYear(),
      padStart(String(date.getMonth() + 1), 2, '0'),
      padStart(String(date.getDate()), 2, '0'),
    ].join('-') + " 00:00:00"

    onChange({ [id]: stringDate })

    if (onBlur)
      setTimeout(() => onBlur(), 200)
  }, [id, onChange])

  return <div className={className}>
    {system_only ? 
      <Static date={date} /> : 
      <>
        <i className="fa-light fa-calendar-days"></i>
        <DatePicker showYearDropdown={true} showMonthDropdown={true} scrollableYearDropdown={true} selected={date} onChange={handleChange} adjustDateOnChange={true} dateFormat={DATE_FORMAT} /> 
      </>
    }
  </div>
}
