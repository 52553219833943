import React from 'react'
import PropTypes from 'prop-types'

class Fees extends React.Component {

  is_third_party_submission(fee) {
    return fee.due_at == 'Submission (Third Party Payable)'
  }

  bp_fees() {
    const initiationFees = [
      { id: "agent-init", paid_by: "Agent / Attorney", name: "Single-Application Initiation Fee *", amount: this.props.brokerPackageInitiationFee, due_at: "Initiation" },
      { id: "applicant-init", paid_by: "Applicant", name: "Single-Application Initiation Fee *", amount: 120, due_at: "Initiation" },
    ]

    const bp_fees = this.props.free_initialization ? [] : initiationFees.concat([])

    const packageTypeIs45 = ['alteration', 'major_alteration', 'minor_alteration', 'decorative_alteration', 'sublet_renewal', 'lease_renewal', 'storage_sale_current_resident', 'parking_sale_current_resident', 'refinance', 'transfer' ].includes(this.props.packageType)

    if (this.props.isRental) {
      bp_fees.push({
        id: "rental",
        paid_by: "Tenant",
        name: "Digital Submission Fee",
        amount: 20,
        due_at: "Submission"
      })
    }

    else if (this.props.isRefinance && !this.props.skipDsfData['skipRefinanceSubmissionFee']) {
      bp_fees.push({
        id: "refinance",
        paid_by: "Shareholder",
        name: "Refinance",
        amount: 45,
        due_at: this.props.orgHandlesBPFee.refinance ? "Complimentary" : "Submission"
      })
    }

    else if (this.props.isTransfer && !this.props.skipDsfData['skipTransferSubmissionFee']) {
      bp_fees.push({
        id: "transfer",
        paid_by: "Any Transfers",
        name: "Transfer",
        amount: 45,
        due_at: this.props.orgHandlesBPFee.transfer ? "Complimentary" : "Submission"
      })
    }

    else if (!this.props.skipDsfData['skipDigitalSubmissionFee']) {
      bp_fees.push({
        id: "applicant",
        paid_by: "Applicant",
        name: "Digital Submission Fee",
        amount: packageTypeIs45 ? 45 : 65 ,
        due_at: this.props.orgHandlesBPFee.applicant ? "Complimentary" : "Submission"
      })
    }

    bp_fees.push({
      id: "admin-fee",
      paid_by: "Any Participant Paying Building Fees",
      name: "App Admin Fee",
      due_at: "Submission",
      amount: [
        <div key="5%">5% of Total</div>,
        <small key="desc">(excluding Digital Submission & Initiation Fees)</small>
      ]
    })

    return bp_fees
  }

  render() {
    return <div>
      <h4>building &amp; management fees</h4>
      {this.renderTable(this.props.fees) }

      {!this.props.hideProcessingFees && (
        <>
          <h4 className="has-bp-logo">processing fees</h4>
          {this.renderTable(this.bp_fees())}
        </>
      )}

      <div style={{padding: "10px"}}>
        <small>* - Only one Application Initiation Fee will be charged. The amount is based on the initiator's identity</small>
      </div>
    </div>
  }

  renderTable(fees) {
    return <table className="table table-striped building-show-fees margin-left">
      <thead>
        <tr>
          <th>Payor</th>
          <th>Building Fees</th>
          <th>Amount</th>
          <th>Due Upon</th>
          { this.props.show_recepient ? <th>Recipient</th> : null}
        </tr>
      </thead>
      <tbody>
        { fees.map(fee => this.renderFeeRow(fee)) }
      </tbody>
    </table>
  }

  renderFeeRow(fee) {
    return <tr key={fee.id}>
      <td className="text-left">{fee.paid_by}</td>
      <td>{ fee.name }</td>
      <td>{ this.renderFeeAmount(fee.amount)}</td>
      <td>{ this.is_third_party_submission(fee) ? "Submission" : fee.due_at}</td>
      { this.props.show_recepient ? fee.recipient_display : null }
    </tr>
  }

  renderFeeAmount(amount) {
    if (typeof amount == "object")
      return amount

    amount = String(amount)
    return amount.match(/[a-z]/i) ? amount : asMoney(amount)
  }
}

Fees.propTypes = {
  fees: PropTypes.array.isRequired,
  skipDsfData: PropTypes.object
}

export default Fees
