import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectNewStructureState,
  clearErrorMessages as clearErrorMessagesAction,
  createStructure as createStructureAction,
  resetState as resetStateAction,
} from './store/slice'
import NewStructureCreatedModal from './modals/NewStructureCreatedModal'
import NewStructureModal from './modals/NewStructureModal'

export function NewStructure(props) {
  const { building, onCreatedClose } = props
  const dispatch = useDispatch()
  const [newStructureModalVisible, setNewStructureModalVisible] = useState(false)
  const [newStructureCreatedModalVisible, setNewStructureCreatedModalVisible] = useState(false)

  const onRequestNewStructureModalOpen = useCallback(() => {
    setNewStructureModalVisible(true)
  }, [])

  const onRequestNewStructureModalClose = useCallback(() => {
    setNewStructureModalVisible(false)
    dispatch(clearErrorMessagesAction())
  }, [dispatch])

  const onStructureCreatedModalOpen = useCallback(() => {
    setNewStructureCreatedModalVisible(true)
  }, [])

  const onStructureCreatedModalClose = useCallback(() => {
    setNewStructureCreatedModalVisible(false)
    dispatch(resetStateAction())
    if (typeof onCreatedClose === 'function') onCreatedClose()
  }, [])

  const onSubmit = (data) => {
    const payload = { ...data, building_id: building.id }

    dispatch(createStructureAction(payload))
  }

  const { structureCreated, structure, structureCreating, errorMessages } = useSelector(selectNewStructureState)

  useEffect(() => {
    if (newStructureModalVisible && structureCreated && structure?.id) {
      onRequestNewStructureModalClose()
      onStructureCreatedModalOpen()
    }
  }, [
    structureCreated,
    structure?.id,
    onRequestNewStructureModalClose,
    onStructureCreatedModalOpen,
    newStructureModalVisible,
  ])

  return (
    <>
      <button
        className="dome-btn dome-btn-base dome-btn-dark-green"
        type="button"
        onClick={onRequestNewStructureModalOpen}
      >
        <i className="fa-light fa-plus"></i> new address
      </button>
      <NewStructureModal
        open={newStructureModalVisible}
        onClose={onRequestNewStructureModalClose}
        onSubmit={onSubmit}
        submitting={structureCreating}
        errorMessages={errorMessages}
        building={building}
      />
      <NewStructureCreatedModal
        open={newStructureCreatedModalVisible}
        onClose={onStructureCreatedModalClose}
        address={structure?.default_additional_address?.address || ''}
        onSubmit={() => {
          onStructureCreatedModalClose()
          onRequestNewStructureModalOpen()
        }}
      />
    </>
  )
}

NewStructure.propTypes = {
  building: PropTypes.object.isRequired,
  onCreatedClose: PropTypes.func,
}

NewStructure.defaultProps = {
  buttonProps: {},
}
