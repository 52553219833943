import React, { useEffect, useState } from 'react'
import { Provider, useDispatch } from 'react-redux'

import { store, setBuilding, setMetadata } from './state'
import { getMetadata } from './api'

import AssocitionSelection from './association_selection'
import Fields from './fields'

function DataIngest({singleBuilding = false}) {
  return <div className="building-data-ingester">
    <AssocitionSelection singleBuilding={singleBuilding} />
    <Fields />
  </div>
}


function Loader(props) {
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  const { singleBuilding } = props

  useEffect(() => {
    dispatch(setBuilding({ ...props, as_current: singleBuilding }))
  }, [dispatch, singleBuilding])

  useEffect(() => {
    getMetadata().then(metadata => {
      dispatch(setMetadata(metadata))
      setLoaded(true)
    })
  }, [dispatch, setLoaded])

  return loaded ? <DataIngest singleBuilding={!!props.building} /> : null
}

export default function(props) {
  return <Provider store={store}>
      <Loader {...props} />
  </Provider>
}