import React, { useState, useEffect, useRef } from 'react'
import { compact, isNil } from 'lodash'

import { useAsDropdown } from 'components/utility/react_helpers'

const isBlank = v => !v || String(v).match(/^\s*$/)

const FONT_FAMILIES = [
  'Dancing Script',
  'Great Vibes',
  'Mrs Saint Delafield', 
  'Sacramento',
  'Satisfy'
]

function TextInput({value, onChange, placeholder}) {
  if (isNil(placeholder))
    placeholder = "Type your Signature Here"

  return <div>
    <input type="text" value={value || ""} placeholder={placeholder || ""}  onChange={e => onChange(e.target.value)} />
  </div>
}

function SelectItem({font: fontFamily, selected, onChange, text}) {
  const style = { fontFamily }
  const classes = compact([
    fontFamily == selected ? 'selected' : null
  ]).join(" ")

  return <div className={classes} style={style} onClick={() => onChange(fontFamily)}>
    {text}
  </div>
}

function SelectFamily({text, fontFamily, onChange}) {
  const { ref: container, opened, setOpened} = useAsDropdown()

  const classes = compact([
    'select-family',
    opened ? 'opened' : null
  ]).join(' ')

  const style = { 
    fontFamily 
  }

  const props = {
    selected: fontFamily,
    text,
    onChange: value => {
      onChange(value)
      setOpened(false)
    }
  }

  if (!text)  
    return null

  return <>
    <div className="directions">Select the Font</div>
    <div className={classes} ref={container}>
      <div className="button" onClick={() => setOpened(!opened)}>
        <div style={style}>{text}</div>
        <i className="fa-sharp fa-solid fa-caret-down"></i>
      </div>
      { opened ? <div className="family-select">{ FONT_FAMILIES.map(font => <SelectItem key={font} font={font} {...props} />)}</div> : null }
    </div>
  </>
}

export default class extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      text: props.defaultValue,
      fontFamily: 'Satisfy'
    }
  }

  setText(value) {
    this.setState({text: isBlank(value) ? null : value})
  }

  getSignature() {
    return { ... this.state }
  }

  clearSignature() {
    this.canvas.current.clear()
  }

  render() {
    return <div className="type-signature-input">
      <div className="directions">{this.props.label || "Type Here"}</div>
      <TextInput value={this.state.text} placeholder={this.props.placeholder} onChange={text => this.setText(text)} />
      <SelectFamily {...this.state} placeholder={this.props.selectPlaceholder} onChange={fontFamily => this.setState({fontFamily})} />
    </div>
  }
}