import React from 'react'
import Beacon from '.'
import feesImage1 from '/app/assets/images/beacon/new/fees1.png'
import feesImage2 from '/app/assets/images/beacon/new/fees2.png'
import feesImage3 from '/app/assets/images/beacon/new/fees3.png'
import feesImage4 from '/app/assets/images/beacon/new/fees4.png'
import feesImage5 from '/app/assets/images/beacon/new/fees5.png'
import BeaconSlider from './slider'
import withBeaconModal from './modal'

const DATA = [
  {
    label: 'fees/submit',
    titles: [
      'Assign each fee to appropriate person to notify them.',
      'Submit completed application after fee authorization.',
    ],
    image: feesImage1,
  },
  {
    label: 'assign fees',
    titles: ['Designate who pays each fee using  “Select Assignee.” '],
    description:
      '*If a fee is conditional, enter amount based on prescribed variables. Then will “Select Assignee” appear.',
    image: feesImage2,
  },
  {
    label: 'authorize fees',
    titles: ['Authorize any fees assigned to you by selecting the “Authorize Fees” button and providing payment info.'],

    image: feesImage3,
  },
  {
    label: 'submit to processors',
    titles: [
      'Select Submit after application is generated + all fees are authorized to submit/send Application to Processor.',
    ],
    image: feesImage4,
  },
  {
    label: 'collected fees',
    titles: ['Check the status tab to see when fees get collected and other application milestones.'],
    image: feesImage5,
  },
]

const FeesSubmitBeacon = (props) => {
  return (
    <BeaconSlider numberOfSteps={DATA.length} {...props}>
      {(currentStep) => {
        const item = DATA[currentStep]
        return (
          <Beacon>
            <Beacon.Body>
              <Beacon.RadioGroup />
              <Beacon.ListGroup gap={0}>
                {currentStep === 0 && <Beacon.Label />}
                <Beacon.Title>{item.label}</Beacon.Title>
              </Beacon.ListGroup>
              <Beacon.ListGroup>
                {item.titles.map((title) => (
                  <Beacon.Text key={title}>{title}</Beacon.Text>
                ))}
                <Beacon.SubText>{item.description}</Beacon.SubText>
              </Beacon.ListGroup>
            </Beacon.Body>
            <Beacon.Image src={item.image} />
          </Beacon>
        )
      }}
    </BeaconSlider>
  )
}

const FeesSubmitBeaconWithBeaconModal = withBeaconModal(FeesSubmitBeacon)

export default FeesSubmitBeaconWithBeaconModal
