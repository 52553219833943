import React, { useLayoutEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { injectReducers, store } from 'components/stores/global/store'

const useInjectOnLayout = (callback) => {
  const [isInjected, setIsInjected] = useState(false)

  useLayoutEffect(() => {
    callback()
    setIsInjected(true)
  }, [])

  return isInjected
}

/**
 * A global parent to get wrapped around all other components to provide global contexts, routers, themes, etc.
 * */
export const GlobalWrapper = ({ children, reducers }) => {
  const isInjected = useInjectOnLayout(() => injectReducers(reducers))

  return isInjected ? <Provider store={store}>{children}</Provider> : null
}

/**
 * A HOC to wrap components inside the GlobalWrapper component.
 * */
export const withGlobal =
  (Component, { reducers }) =>
  (props) => {
    return (
      <GlobalWrapper reducers={reducers}>
        <Component {...props} />
      </GlobalWrapper>
    )
  }
