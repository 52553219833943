import React, { useEffect } from 'react'
// import PropTypes from "prop-types";
import { connect } from 'react-redux'

import {
  selectStructureEditState,
  updateStructure as updateStructureAction,
  openStructureUpdatedModal as openStructureUpdatedModalAction,
  closeStructureEditModal as closeStructureEditModalAction,
  closeStructureUpdatedModal as closeStructureUpdatedModalAction,
} from './store/slice'

import StructureUpdateModal from './modals/StructureUpdateModal'
import StructureUpdatedModal from './modals/StructureUpdatedModal'

function StructureEdit(props) {
  const {
    structure,
    structureEditModalVisible,
    building,
    structureUpdated,
    structureUpdating,
    errorMessages,
    structureUpdatedModalVisible,
    updateStructure,
    closeStructureEditModal,
    openStructureUpdatedModal,
    closeStructureUpdatedModal,
  } = props

  const onSubmit = (data) => {
    const payload = { ...data, building_id: building?.id, id: structure.id }

    updateStructure(payload)
  }

  useEffect(() => {
    if (structureUpdated && structureEditModalVisible) {
      closeStructureEditModal()
      openStructureUpdatedModal()
    }
  }, [structureUpdated, structureEditModalVisible, closeStructureEditModal, openStructureUpdatedModal])

  return (
    <>
      <StructureUpdateModal
        title="edit address"
        submitText="update"
        open={structureEditModalVisible}
        onClose={closeStructureEditModal}
        onSubmit={onSubmit}
        submitting={structureUpdating}
        errorMessages={errorMessages}
        structure={structure}
        building={building}
      />
      <StructureUpdatedModal
        open={structureUpdatedModalVisible}
        onClose={closeStructureUpdatedModal}
        address={structure?.default_additional_address?.address || ''}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    structure,
    structureEditModalVisible,
    building,
    structureUpdated,
    structureUpdating,
    errorMessages,
    structureUpdatedModalVisible,
  } = selectStructureEditState(state)

  return {
    structure,
    structureEditModalVisible,
    building,
    structureUpdated,
    structureUpdating,
    errorMessages,
    structureUpdatedModalVisible,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStructure: (payload) => dispatch(updateStructureAction(payload)),
    openStructureUpdatedModal: () => dispatch(openStructureUpdatedModalAction()),
    closeStructureUpdatedModal: () => dispatch(closeStructureUpdatedModalAction()),
    closeStructureEditModal: () => dispatch(closeStructureEditModalAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StructureEdit)
