import React from 'react'
import Row from './row'

class DocumentsTable extends React.Component {

  filteredMyDocuments(){
    return this.props.myDocuments.filter((doc) => {
      if (this.props.librarySearchType === 'all'){
        return (this.matchesLabelSearch(doc))
      } else {
        return (this.matchesLabelSearch(doc) && this.matchesSearchType(doc) )
      }
    })
  }

  truncateString(str){
    return str.substring(0,25)
  }

  matchesLabelSearch(doc){
    return doc.label.toLowerCase().indexOf(this.props.librarySearch.toLowerCase()) !== -1
  }

  matchesSearchType(doc){
    return doc.file_class === this.props.librarySearchType
  }

  renderDocuments(){
    return this.props.documents.map((document, i) => {
      return (
        <Row
          document={document}
          handleDocumentDelete={this.props.handleDocumentDelete}
          docTypes={this.props.docTypes}
          checkImagePath={this.props.checkImagePath}
          truncateString={this.truncateString}
          key={i}
          onDocumentInputChange={this.props.onDocumentInputChange}
          disabled={false}
        />
      )
    })
  }

  render(){
    let tableStyle = {
      display: 'table',
      borderCollapse: 'collapse',
      width: '100%',
      marginTop: '30px',
      paddingTop: '25px',
      tableLayout: 'fixed'
    }
    let tdStyle={
      display: 'table-cell',
      color: 'orange',
      textAlign: 'center',
      fontWeight: '1.2em',
      width: '18%',
      padding: '5px'
    }
    let tdStyleHalfWidth = { ...tdStyle,  width: '9%' }
    let iconCell={
      display: 'table-cell',
      width: '3%'
    }
    let trStyle={ display: 'table-row'}

    return (
      <div className='table-container row'>
        <div className='col-xs-12'>
          <div className="uploaded-docs-table-component" style={tableStyle}>
            <span style={trStyle}>
              <span className='text-bopa' style={tdStyle}><b>Display Name</b></span>
              <span className='text-bopa' style={tdStyle}><b>Type</b></span>
              <span className='text-bopa' style={tdStyle}><b>Expiration</b></span>
              <span className='text-bopa' style={tdStyle}><b>Price</b></span>
              <span className='text-bopa' style={tdStyleHalfWidth}><b>GL Code</b></span>
              <span className='text-bopa' style={tdStyleHalfWidth}><b>Charge Code</b></span>
              <span style={iconCell}></span>
            </span>
            {this.renderDocuments()}
          </div>
        </div>
      </div>
    )
  }
}


export default DocumentsTable
