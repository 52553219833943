import React from "react"

const STRIPE_CARD_STYLES = {
  base: {
    color: '##4a4a4a',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#C3C3C3'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardFilled: false,
      purchased: this.props.paid || false
    }

    this.handleNameInputBlur = this.handleNameInputBlur.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handlePurchaseClick = this.handlePurchaseClick.bind(this)
  }

  componentWillMount() {
    this.mountStripe()
  }

  componentDidMount() {
    this.state.purchased
    ? this.showPaymentSuccessModal()
    : (
        this.props.cancelled
        ? $("#cancel-success-modal").modal("show")
        : this.showOnLoadModal()
    )
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.purchased && this.state.purchased)
      this.showPaymentSuccessModal()
  }

  mountStripe() {
    const script = document.createElement("script")
    script.src = "https://js.stripe.com/v3/"
    script.async = true

    document.body.appendChild(script)

    script.addEventListener("load", () => this.stripeDidMount())
  }

  stripeDidMount() {
    const stripe = Stripe(this.props.publishableStripeKey)
    const elements = stripe.elements()
    var card = elements.create('card', {style: STRIPE_CARD_STYLES})

    card.mount('#card-element')

    this.listenCardChange(card)
    this.setState({stripe: stripe, card: card})
  }

  stripe() {
    return this.state.stripe
  }

  card() {
    return this.state.card
  }

  listenCardChange(card) {
    card.addEventListener('change', function(event) {
      if (this.state.cardFilled != event.complete)
        this.setState({cardFilled: event.complete})

      this.handleCardChangeValidation(event)
    }.bind(this));
  }

  // Handle real-time validation errors from the card Element.
  handleCardChangeValidation(cardEvent) {
    if (cardEvent.error)
      return this.renderStripeErrorMsg(cardEvent.error.message)

    this.renderStripeErrorMsg(null)
  }

  handleNameInputBlur(e) { this.setState({cardName: e.target.value}) }

  handleCancelClick() {
    if (!this.ableToCancel()) return false

    this.setLoading(true)

    $.ajax({
      url: "/questionnaire/"+this.props.hashedId+"/cancel/"+this.props.purchaserToken,
      dataType: "JSON",
      method: "POST",
      success: function(res) {
        if (res.success) {
          $("#cancel-modal").modal("hide")
          $("#cancel-success-modal").modal("show")
        } else {
          // TODO: handle cancel failure
        }
        this.setLoading(false)
      }
    })
  }

  handlePurchaseClick() {
    this.setLoading(true)
    this.stripe().createToken(this.card()).then(function(result) {
      if (result.error) {
        this.renderStripeErrorMsg(result.error.message)
        this.setLoading(false)
      } else {
        $.ajax({
          url: "/questionnaire/"+this.props.hashedId+"/purchase/"+this.props.purchaserToken,
          data: {cardToken: result.token, paymentIntentId: this.props.paymentIntentId},
          dataType: "JSON",
          method: "POST",
          context: this,
          success: function() {
            this.setState({purchased: true})
            this.setLoading(false)
          }, error: function(xhr) {
            alert(xhr.responseJSON.error)
            this.setLoading(false)
          }
        })
      }
    }.bind(this))
  }

  renderStripeErrorMsg(msg) {
    var errorElement = document.getElementById('card-errors')
    errorElement.textContent = msg
  }

  setLoading(boolean) {
    this.setState({loading: boolean})
  }

  shouldPurchaseBtnBeDisabled() {
    if (!this.state.cardFilled) return true
    if (!this.state.cardName) return true
    if (this.state.loading) return true

    return false
  }

  ableToCancel() {
    if (this.state.loading) return false

    return this.state.cancelText == "Cancel"
  }

  centsToStringifiedDollars(cents) {
    var dollars = cents / 100;
    dollars = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});

    return dollars
  }

  formPriceInDollars() { return this.centsToStringifiedDollars(this.props.priceInCents) }

  processingFeeInDollars() { return this.centsToStringifiedDollars(this.props.processingFeeInCents) }

  totalPriceInDollars() { return this.centsToStringifiedDollars(this.props.priceTotalInCents) }

  showOnLoadModal() {
    $("#onload-modal").modal("show")
  }

  showPaymentSuccessModal() {
    $("#payment-success-modal").modal("show")
  }

  renderPaymentTable() {
    return (
      <table className="checkout-table">
        <thead>
          <tr>
            <th>Document</th>
            <th>Building</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{this.props.formName}</td>
            <td>{this.props.propertyAddress}</td>
            <td className="text-grey-semi-bold">{this.formPriceInDollars()}</td>
          </tr>
          <tr>
            <td>App Admin Fee</td>
            <td></td>
            <td>{this.processingFeeInDollars()}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="text-grey-semi-bold">{this.totalPriceInDollars()}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderReceiptTable() {
    return (
      <table className="checkout-table" id="receipt-table">
        <thead>
          <tr>
            <th>Description</th>
            <th></th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{this.props.formName}</td>
            <td></td>
            <td>{this.formPriceInDollars()}</td>
          </tr>
          <tr className="tr-border-bottom">
            <td>App Admin Fee</td>
            <td></td>
            <td>{this.processingFeeInDollars()}</td>
          </tr>
          <tr className="text-grey-semi-bold">
            <td></td>
            <td>Total Price:</td>
            <td>{this.totalPriceInDollars()}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderOnLoadModal() {
    return (
      <div id="onload-modal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
                <br/>
              <p>Request for Questionnaire</p>
            </div>
            <div className="modal-body">
              <p>The price for this Questionnaire is</p>
              <p className="text-orange">{this.formPriceInDollars()}<span className="text-grey">*</span> </p>
              <p>Property management will complete the questionnaire after receiving payment. You will receive an email with a link to the questionnaire once it's complete.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn grey-btn-white-text payment-form-purchase-btn" data-dismiss="modal" aria-label="Close">OK</button>
              <p className="below-button-text"><small><span>*</span>Excluding app admin fees.</small></p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPaymentSuccessModal() {
    return (
      <div id="payment-success-modal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
            <i className="fa-light fa-circle-check fa-5x" style={{color:'green'}}></i>
              <p>Success!</p>
            </div>
            <div className="modal-body">
              <p>Thank you for your payment for this questionnaire.</p>
              <p>A digital receipt will be sent to you by email.</p>
              <br/>
              <p>Property management will then email you the completed form when it's ready.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn grey-btn-white-text payment-form-purchase-btn" data-dismiss="modal" aria-label="Close">OK</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCancelModal() {
    return (
      <div id="cancel-modal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content border-red">
            <div className="modal-header">
              <p className="text-red">Cancel Questionnaire</p>
            </div>
            <div className="modal-body">
              <p>Please confirm you would like to cancel your request for this questionnaire by typing 'Cancel' in the box below:</p>
              <br/>
              <input type="text"
                className="form-control cancel-input"
                value={this.state.cancelText}
                disabled={this.state.loading}
                onChange={(e) => {this.setState({cancelText: e.target.value})}}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-back" data-dismiss="modal" aria-label="Close" disabled={this.state.loading}>Back</button>
              <button type="button" className="btn btn-danger cancel-request-btn" disabled={!this.ableToCancel()} onClick={() => this.handleCancelClick()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCancelSuccessModal() {
    return (
      <div id="cancel-success-modal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content border-red">
            <div className="modal-header">
              <p className="text-red">Cancel Questionnaire</p>
            </div>
            <div className="modal-body">
              <p>Your request has been canceled.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn grey-btn-white-text payment-form-purchase-btn" onClick={() => window.location.href = "/"}>OK</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPaymentForm() {
    return (
      <div className="box-content">
        <div className="row">
          <div className="col-sm-2">
            <img src="https://bopa-production.s3.amazonaws.com/uploads/assets/questionnaire-29.png" height="75" alt="questionnaire image"/>
          </div>
          <div className="col-sm-10">
            {this.renderPaymentTable()}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-9">
            <p className="text-grey">Card Payment</p>
            <div className="card-info-container">
              <div>
                <span className="card-name-asterisk text-orange">*</span>
                <input className="card-field card-name" type="text" placeholder="Name" name="name" autoComplete="off" disabled={this.state.loading} onChange={(e) => this.handleNameInputBlur(e)} style={{color: '#4a4a4a'}} />
              </div>
              <div className="card-field stripe-card" id="card-element"></div>
              <div id="card-errors" role="alert"></div>
              <div className="row buttons-container">
                <div className="col-sm-12">
                  <button className="btn btn-danger" disabled={this.state.loading} onClick={() => $("#cancel-modal").modal("show")}>Cancel Questionnaire</button>
                  <button className="btn grey-btn-white-text payment-form-purchase-btn" disabled={this.shouldPurchaseBtnBeDisabled()} onClick={() => this.handlePurchaseClick()}>Purchase</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderReceipt() {
    return (
      <div className="receipt-content">
        <h4 className="text-grey-semi-bold">Thank you for your payment!</h4>
        <p>A digital receipt will also be sent to you by email.</p>
        <p><span className="text-grey-semi-bold">Please note:</span> A completed questionnaire will be returned to the email address provided once property management has completed the form(s).</p>
        <p><span className="text-grey-semi-bold">Property Address:</span> {this.props.propertyAddress}</p>
        <p><span className="text-grey-semi-bold">Unit:</span> {this.props.unitId} </p>
        <div className="box-content receipt-box-content">
          {this.renderReceiptTable()}
        </div>
        <br/>
        <button className="btn grey-btn-white-text" onClick={() => window.print()}>Print Receipt</button>
        <br/><br/>
        <p>Prior to disputing any charge with your card issuer, you agree to contact Domecile at <a href="mailto:refunds@domecile.com" className="text-grey">refunds@domecile.com</a> and provide your name, dispute, reason for dispute, and any supporting documentation.</p>
      </div>
    )
  }

  renderTitle() {
    const titleJsx = this.state.purchased
                    ? (<p><span className="text-grey">Digital</span> Receipt</p>)
                    : (<p className="text-grey-semi-bold"><span className="text-grey-semi-bold">Purchase</span> Questionnaire</p>)
    return (
      <div className="title">
        {titleJsx}
      </div>
    )
  }

  renderMainContent() {
    return this.state.purchased ? this.renderReceipt() : this.renderPaymentForm()
  }

  render() {
    return (
      <div id="questionnaire-checkout">
        {this.renderTitle()}
        {this.renderMainContent()}
        {this.renderOnLoadModal()}
        {this.renderPaymentSuccessModal()}
        {this.renderCancelModal()}
        {this.renderCancelSuccessModal()}
      </div>
    )
  }
}

export default CheckoutPage