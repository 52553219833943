import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { uniq } from 'lodash-es'
import { uniqueComponentKey } from '../../utility'
import Searchbar from './Searchbar'
import LabelsList from './LabelsList'

const useFilterLabels = (componentsList) => {
  const [queriedLabel, setQueriedLabel] = useState('')
  const labelKeys = useMemo(() => componentsList.map((c) => c.label?.toLowerCase()), [componentsList])
  const filteredLabelsList = useMemo(() => {
    if (!queriedLabel) return labelKeys

    const queryLower = queriedLabel.toLowerCase()
    return labelKeys.filter((key) => key?.includes(queryLower))
  }, [queriedLabel, labelKeys])

  return { filteredLabelsList, setQueriedLabel }
}

const Fields = () => {
  const allComponents = useSelector(({ components }) => components.all)
  const componentsList = useMemo(() => {
    const componentList = Object.values(allComponents).filter(
      ({ type, subtype }) => type === 'data' || (type === 'signature' && subtype === 'signature')
    )
    return componentList.map((component) => ({
      key: uniqueComponentKey(component.type, component.id, component.subtype),
      keyArray: [component.type, component.id, component.subtype],
      id: component.id,
      label: component?.behaviorInputs?.label,
      type: component.type,
      page: component.page,
      subtype: component.subtype,
    }))
  }, [allComponents])

  const { filteredLabelsList, setQueriedLabel } = useFilterLabels(componentsList)

  return (
    <div className="content">
      <Searchbar setQueriedLabel={setQueriedLabel} />
      <LabelsList filteredLabelsList={filteredLabelsList} componentsList={componentsList} />
    </div>
  )
}

export default Fields
