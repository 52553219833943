const DisabledDocumentTableRow = React.createClass({

  getInitialState(){
    return { hover: false }
  },

  onDelete(){
    this.props.handleDocumentDelete(this.props.document.id)
  },

  formatAsMoney(price){
    return "$" + price.formatMoney(2)
  },

  toggleHover(){
    this.setState({hover: !this.state.hover})
  },

  render(){
    let trStyle = { display: 'table-row'}
    let tdStyle={ display: 'table-cell', width: '18%', textAlign: 'center', padding: '5px'}
    let fileName={marginLeft: '20px'}
    let iconCell={ display: 'table-cell', width: '10%', paddingLeft: '5px'}
    let trashIcon={ fontSize: '25px', verticalAlign: 'middle'}
    let trStyleHover={ display: 'table-row', backgroundColor: '#EEEDEC', width: '100%'}
    let { label, file_class, days_until_expiration, id, price, base_filename } = this.props.document
    return (
      <div className='tr'
        style={this.state.hover ? trStyleHover : trStyle}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        >
        <span className='dont-break-out' style={[tdStyle, fileName]}>{this.props.truncateString(base_filename)}</span>
        <span className='dont-break-out' style={tdStyle}>{label}</span>
        <span style={tdStyle}>{file_class}</span>
        <span style={tdStyle}>{days_until_expiration}</span>
        <span style={tdStyle}>{this.formatAsMoney(price)}</span>
        <span style={iconCell}>
          <i className="fa fa-trash" style={trashIcon} onClick={this.onDelete.bind(this)}></i>
        </span>
      </div>
    )
  }
})
