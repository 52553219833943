import React, { useState, useRef, useCallback } from 'react'
import { useEventListeners } from '@deathbyjer/react-event-manager'

import { pick } from 'lodash'
import { compactHash } from 'lib/utilities'

//import Modal from './modal'
import Draw from './draw'
import Type from './type'

function Tab({children, active, onClick}) {
  const classes = ['tab']
  if (active)
    classes.push('active')

  return <div className={classes.join(" ")} onClick={onClick}>
    {children}
  </div>
}

function prepareSignature(tab, ref) {
  const signature = ref.getSignature()

  if (signature == null)
    return null

  switch(tab) {
  case 'draw':
    return { image: signature }
  case 'type':
    return pick(signature, ['fontFamily', 'text'])
  }
}

const EMPTY_FUNCTION = () => {}

export function Signature({onSign, closeModal, showDefault}) {
  const tabRef = useRef()
  const [tab, setTab] = useState("draw")
  const [asDefault, setAsDefault] = useState(true)

  const toggleDefault = useCallback(() => setAsDefault(!asDefault), [asDefault, setAsDefault])

  const handleSign = useCallback(() => {
    onSign(prepareSignature(tab, tabRef.current), { asDefault })
  }, [tab, tabRef.current, asDefault])

  const buttonProps = {
    showDefault,
    asDefault,
    toggleDefault,
    handleSign,
    closeModal
  }

  return <div className="edit-signature">
      <div className="tabs">
        <Tab active={tab == "draw"} onClick={() => setTab("draw")}>Draw</Tab>
        <Tab active={tab == "type"} onClick={() => setTab("type")}>Type</Tab>
      </div>

      <div className="tab-content">
        { tab == "draw" ? <Draw ref={tabRef} /> : null }
        { tab == "type" ? <Type ref={tabRef} /> : null }
      </div>

      <Buttons {...buttonProps} />
  </div>
}

export function Initial({onSign, closeModal, showDefault}) {
  const tabRef = useRef()
  const [asDefault, setAsDefault] = useState(true)

  const toggleDefault = useCallback(() => setAsDefault(!asDefault), [asDefault, setAsDefault])
  const handleSign = useCallback(() => {
    if (!tabRef.current)
      return

    onSign(tabRef.current.getSignature(), { initials: true, asDefault})
  }, [tabRef.current, onSign])

  const buttonProps = {
    showDefault,
    asDefault,
    toggleDefault,
    handleSign,
    closeModal
  }

  return <div className="edit-signature">
    <div className="tab-content">
      <div>
        <label>Initials</label>
      </div>

      <Type ref={tabRef} placeholder={false} label="Initial Here" />
      <Buttons {...buttonProps} />
    </div>
  </div>
}

function Buttons(props) {
  const { closeModal, handleSign, showDefault } = props

  return <div className="buttons">
    <button className="btn" onClick={closeModal}>Close</button>
    <div className="save">
      <button className="btn btn-primary" onClick={handleSign}>Sign</button>
      { showDefault ? <SaveAsDefault {...props} /> : null }
    </div>
  </div>
}

function SaveAsDefault({ toggleDefault, asDefault }) {
  return <div onClick={toggleDefault}>
    <span>Save as Default:</span>
    <input type="checkbox" checked={asDefault} onChange={EMPTY_FUNCTION} />
  </div>
}
