import { useEventManager } from '@deathbyjer/react-event-manager'
import React from 'react'
import { useSelector } from 'react-redux'

const Pages = () => {
  const pages = useSelector(({pages}) => pages.all)
  const events = useEventManager()

  const scrollToPage = (pageId) => {
    events.applyEventListeners(`scrollToPage`, pageId)
  }

  return (
    <div className="page-thumbnails scrollable">
      {pages.map((page) => (
        <a key={page.id} onClick={() => scrollToPage(page.id)} className='dome-cursor-pointer'>
          <div className="thumbnail-container">
            <img className="img-thumbnail" src={page.url} alt={`page-${page.id}`} />
          </div>
        </a>
      ))}
    </div>
  )
}

export default Pages
