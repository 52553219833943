import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { fetchAccountsApi, submitAccountChangesApi } from './apis'
import { startAppListening } from 'components/global/listener'

export const STEPS = {
  list: 'list',
  confirm: 'confirm',
  transferred: 'transferred',
}

const initialState = {
  transferAccountsModalOpen: false,
  organizationId: null,
  buildingId: null,
  buildingAccounts: {},
  buildingAccountsFetching: false,
  accountValues: {},
  step: STEPS.list,
  transferred: {
    accounts: [],
  },
}

export const fetchAccounts = createAsyncThunk('propertyTransfer/fetchAccounts', async (data) => {
  try {
    const response = await fetchAccountsApi(data.buildingId)
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else {
      throw error
    }
  }
})

export const submitAccountChanges = createAsyncThunk('propertyTransfer/submitAccountChanges', async (data) => {
  try {
    const response = await submitAccountChangesApi(data.buildingId, data.accountValues)
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      return thunkAPI.rejectWithValue(error.response.data)
    } else {
      throw error
    }
  }
})

const propertyTransferSlice = createSlice({
  name: 'propertyTransfer',
  initialState,
  reducers: {
    setInitialState: (state, action) => {},
    openModal: (state, action) => {
      state.transferAccountsModalOpen = true
      state.buildingId = action.payload.buildingId
      state.organizationId = action.payload.organizationId
    },
    closeModal: (state, action) => {
      return initialState
    },
    setAccountValues: (state, action) => {
      const { source, destination } = action.payload
      state.accountValues[source] = destination
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.pending, (state, action) => {
      state.buildingAccountsFetching = true
    })
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.buildingAccounts = action.payload
      state.buildingAccountsFetching = false
    })
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state.buildingAccountsFetching = false
    })
    builder.addCase(submitAccountChanges.fulfilled, (state, action) => {
      state.step = STEPS.transferred
      state.transferred = action.payload.transferred
    })
  },
})

export const selectPropertyTransfer = (state) => state.propertyTransfer

export const { setInitialState, openModal, closeModal, setAccountValues, setStep } = propertyTransferSlice.actions

export const propertyTransferReducer = propertyTransferSlice.reducer
