import React, { useState, useMemo, useContext, useCallback } from 'react'
import FeeModal from './FeeModal'
import FeeInfoAccordion from './FeeInfoAccordion'
import { isString, isPlainObject, isArray } from 'lodash-es'

import { submitFee } from './api'

const FEE_BUTTON_INFO = [
  { text: "Deauthorize", className: "btn btn-success fee-btn-disable", url: '/super/package_info/deauthorize_fee' },
  { text: "Reset", className: "btn btn-success fee-btn-disable", url: '/super/package_info/reset_fee' },
  { text: "Waive", className: "btn btn-warning fee-btn-disable", url: '/super/package_info/waive_fee' },
  { text: "Remove", className: "btn btn-danger fee-btn-disable", url: '/super/package_info/remove_fee' },
  { text: "Reset All", className: "btn btn-success fee-btn-disable", url: '/super/package_info/reset_fee', reset_all: true }
]

// Helper Methods
const getIds = list => list.map(item => item.id)
const addOnce = (array, item) => array.includes(item) ? null : [...array, item]
const removeFee = (array, selected) => array.filter(item => item != selected)
const isCheckboxDisabled = (fee) => fee?.status === "captured"
const allNonCapturedFees = (fees) => fees.filter(fee => fee?.status !== "captured")
const feeStatus = (status) => status == "not_authorized" ? "unassigned" : status

const optsTooltip = {
  "data-tooltip": "tooltip",
  "data-placement": "top",
  "title": "Cannot modify captured fees, please redirect to Payments Team."
}

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

const formatErrorMessage = (error) => {
  if (isString(error)) {
    return error;
  }

  if (isPlainObject(error)) {
    error = Object.values(error);
  }

  if (isArray(error)) {
    return error.join(" ");
  }

  return "Our server had an issue.";
};

export function errorForFeeAction(error) {
  const opts = {
    title: 'Error.',
    text: formatErrorMessage(error),
    type: "error",
    showCancelButton: true,
    closeOnConfirm: true,
  }

  return new Promise((res, rej) => swal(opts, ok => ok ? res() : rej()))
}

export const FeeTableContext = React.createContext({})
export const ModalContext = React.createContext({})

function ModalProvider({ children }) {
  const [currentModal, setCurrentModal] = useState(null)

  const value = useMemo(() => ({
    setCurrentModal
  }), [currentModal])

  return (
    <ModalContext.Provider value={value}>
      {children}
      {currentModal}
    </ModalContext.Provider>
  )
}

function FeeTableProvider({ hashed_id, fees: defaultFees, children }) {
  const [fees, setAllFees] = useState(defaultFees)
  const [selectedFees, setSelectedFees] = useState([])

  const setFees = useCallback(fees => {
    setAllFees(fees);
    setSelectedFees(selectedFees.filter(fee => getIds(fees).includes(fee.id)))
  }, [fees, setAllFees, setSelectedFees])

  const selectAllViableFees = useCallback(() => setSelectedFees(allNonCapturedFees(fees)), [fees, setSelectedFees])
  const removeAllFees = useCallback(() => setSelectedFees([]), [setSelectedFees])
  const isSelected = useCallback(fee => selectedFees.includes(fee), [selectedFees])

  // New helper to check if all viable fees are selected
  const areAllViableFeesSelected = useCallback(() => {
    const viableFees = allNonCapturedFees(fees)
    return viableFees.length > 0 && selectedFees.length === viableFees.length
  }, [fees, selectedFees])

  const toggleSelectedFee = useCallback((fee, enabled) => {
    setSelectedFees(enabled ? addOnce(selectedFees, fee) : removeFee(selectedFees, fee))
  }, [selectedFees])

  const submitSelectedFees = useCallback(({ url, selected_option }) => {
    const options = {
      url, hashed_id, selected_option,
      fees: selectedFees.map(fee => fee.id)
    }

    return new Promise((res, rej) => {
      submitFee(options).then(updatedFees => setFees(updatedFees)).then(res).catch(rej)
    })
  }, [hashed_id, selectedFees, setFees])

  const value = useMemo(() => ({
    fees,
    selectedFees,
    isSelected,
    selectAllViableFees,
    removeAllFees,
    submitSelectedFees,
    toggleSelectedFee,
    areAllViableFeesSelected
  }), [hashed_id, fees, selectedFees, areAllViableFeesSelected])

  return <FeeTableContext.Provider value={value}>
    {children}
  </FeeTableContext.Provider>
}

function PackageFeeRow({ fee }) {
  const { isSelected, toggleSelectedFee } = useContext(FeeTableContext)
  const [isAccordionOpen, setAccordionOpen] = useState(false)
  const showAccordion = () => setAccordionOpen(!isAccordionOpen)

  const checked = isSelected(fee)
  const disabled = isCheckboxDisabled(fee)
  const accordionIcon = isAccordionOpen ? "fa fa-minus" : "fa fa-plus"
  const tooltip = disabled ? optsTooltip : {}

  return (
    <div className="row-container">
      <div className="fee-table-row">
        <p className="fee-table-cell">
          <input {...tooltip}
            type="checkbox"
            className="fee-checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => toggleSelectedFee(fee, !checked)}
          />
        </p>
        <p className="fee-table-cell"> {fee?.name || "N/A"} </p>
        <p className="fee-table-cell"> {"$" + (fee?.amount || "N/A")} </p>
        <p className="fee-table-cell"> {feeStatus(fee?.status) || "N/A"} </p>
        <p className="fee-table-cell"> {fee?.user_email || "N/A"} </p>
        <p className="fee-table-cell"> <i onClick={showAccordion} className={accordionIcon + " more-info-icon"} /></p>
      </div>
      {isAccordionOpen && <FeeInfoAccordion fee={fee} />}
    </div>
  )
}

function FeeButton({ text, className, url, reset_all }) {
  const { selectAllViableFees, fees, selectedFees, areAllViableFeesSelected } = useContext(FeeTableContext)
  const { setCurrentModal } = useContext(ModalContext)
  const disabled = reset_all ? false : selectedFees.length <= 0

  const associatedFeesExist = useCallback(() => {
    const stripeChargeIdsInSelected = selectedFees
      .map(fee => fee.stripe_charge_id)
      .filter(id => id); // removes falsy values

    if (stripeChargeIdsInSelected.length === 0) {
      return false;
    }

    const unselectedFees = fees.filter(fee => !selectedFees.includes(fee));
    const hasStripeChargeInUnselected = unselectedFees.some(fee =>
      stripeChargeIdsInSelected.includes(fee.stripe_charge_id)
    );

    return hasStripeChargeInUnselected;
  }, [fees, selectedFees]);

  const handleClick = useCallback(() => {
    if (reset_all) {
      selectAllViableFees();
    }

    setCurrentModal(<FeeModal
      text={text}
      url={url}
      allFeesSelected={reset_all || areAllViableFeesSelected()}
      associatedFeesExist={associatedFeesExist()}
    />)
  }, [text, url, reset_all, areAllViableFeesSelected])

  const buttonProps = {
    type: "submit",
    disabled, className,
    onClick: handleClick
  }

  return (
    <button {...buttonProps}>
      {text}
    </button>
  )
}

function RenderTable() {
  const { fees } = useContext(FeeTableContext)

  return (
    <div className="fee-table-wrapper">
      <div className="fee-table">
        <div className="fee-table-row header-style">
          <p className="fee-table-cell">Selected</p>
          <p className="fee-table-cell">Fee Name</p>
          <p className="fee-table-cell">Fee Amount</p>
          <p className="fee-table-cell">Status</p>
          <p className="fee-table-cell">Assigned To</p>
          <p className="fee-table-cell">Expand</p>
        </div>
        {fees.map(fee => <PackageFeeRow key={fee.id} fee={fee} />)}
      </div>
    </div>
  )
}

const PackageFeesTable = ({ fees, hashed_id }) => {
  return (
    <FeeTableProvider fees={fees} hashed_id={hashed_id}>
      <ModalProvider>
        <RenderTable />
        <div className="fee-buttons">
          {FEE_BUTTON_INFO.map(info => <FeeButton key={info.text} {...info} />)}
        </div>
      </ModalProvider>
    </FeeTableProvider>
  )
}

export default PackageFeesTable
