import React, { useState, useEffect, useRef } from "react";
import notarizeLogo from "/app/assets/images/pages/home/enotary/notarize_logo.png"

export default function ENotaryEndingModal(props) {
  const ref = useRef()
  const cleanup = () => props.onClose()
  const hide = () => {
    $(ref.current).modal("hide");
    props.setStep(0)
  }

  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])
  return (
    <div ref={ref} className='modal e-notary-outro'>
      <div className='e-notary-modal-box text-center'>
        <i className="fa-thin fa-circle-check ending-checkmark"></i>
        <h2>notary request submitted</h2>
        <div style={{fontSize: '14px'}}>This payment will show on your statement as:</div>
        <div className="card-statement-box">E-Notary {props.passedAddress} Unit: {props.passedUnit}</div>
        <div className="next-steps-box">
          <span style={{ fontWeight: 500 }}>next steps: </span>
          You will receive an email with the verified appointment date + a link to set up an account via
          <span style={{ fontWeight: 400 }}> <img style={{width: "76px", marginBottom: "2px"}} src={notarizeLogo} /></span>
        </div>

        <button className='btn-base btn-aqua-stroke' onClick={hide}>OK</button>
        <div className="disclaimer-box">
          If you have any questions, please contact <a href="mailto:info@domecile.com">info@domecile.com</a>.
        </div>
      </div>
    </div>
  )
}
