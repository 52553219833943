import React, { useRef, useEffect } from 'react'

import { useSelector } from 'react-redux'

import DrawArea from 'components/utility/draw_area'
import { PageWrapper, usePageComponents } from '.'

export default function({id, onClick, children}) {
  const ref = useRef()
  const page = useSelector(state => state.form_pages.pages[id])
  const page_file = useSelector(state => state.form_pages.page_files[id])
  const locked = true
  const pageComponents = usePageComponents({page_id: id, locked: true, frozen: true, thumbnail: true})

  useEffect(() => {
    if (!ref.current || !onClick)
      return

    ref.current.addEventListener('click', onClick)
    return () => ref.current?.removeEventListener('click', onClick)
  }, [ref]);

  return <PageWrapper page={page} page_file={page_file} ref={ref}>
    <DrawArea locked={locked}>
      { pageComponents }
    </DrawArea>
    {children}
  </PageWrapper>
}