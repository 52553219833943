import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'

import { useWatchableRef } from 'lib/hooks'
import { Portal } from 'components/utility/portal'
import { store } from 'components/insurance/store/store'
import { selectInsuranceQuoteInterstitialState, setQuoteOfflineSubmittedVisibility } from '../../slice/slice'

function QuoteOfflineSubmittedModal({ open, onClose }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <Portal>
      <div
        ref={modalRef.ref}
        className="modal fade insurance-quote-offline-submitted-modal dome-modal-centered"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content new-brp-modal">
            <div className="modal-header">
              <i className="fa-thin fa-circle-check dome-color-dark-green" style={{ fontSize: '46px' }} />
              <div className="dome-h2 dome-color-dark-green">quote request submitted!</div>
            </div>
            <div className="modal-body">
              <div className="dome-p1 dome-text-center">
                You’ll receive a link to your quote via email as soon as this service is back online.
              </div>
              <div className="dome-p1">Thank you for your patience!</div>
            </div>
            <div className="modal-footer">
              <button className="dome-btn dome-btn-base dome-btn-aqua-stroke" data-dismiss="modal" onClick={onClose}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

function QuoteOfflineSubmittedModalWrapper() {
  const { quoteOfflineSubmittedVisibility } = useSelector(selectInsuranceQuoteInterstitialState)
  const dispatch = useDispatch()

  const onClose = function () {
    dispatch(setQuoteOfflineSubmittedVisibility(false))
  }

  return <QuoteOfflineSubmittedModal open={quoteOfflineSubmittedVisibility} onClose={onClose} />
}

export default (props) => (
  <Provider store={store}>
    <QuoteOfflineSubmittedModalWrapper {...props} />
  </Provider>
)
