import React from 'react'
import { createSlice } from '@reduxjs/toolkit'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { merge } from 'lodash'

import { useGenerateComponent } from '.'
import getToolbar, { prepareComponents, generateEmpty } from 'components/form_creator/tools/strikethrough'
import { addCSRF } from 'lib/utilities'
import axios from 'axios'

const isOnPage = (item, page_id) => item.position.page == page_id

// The highstrikethroughight state
const initialState = {
  lookups: {},
  by_page: {},
  next_id: -1
}

function addToPage(by_page, strikethrough) {
  const { position } = strikethrough
  by_page[position.page] ||= []
  by_page[position.page].push(strikethrough.id)
}

function removeFromPage(by_page, strikethrough) {
  if (!strikethrough)
    return

  const { position } = strikethrough
  const { page: page_id } = position

  let page = by_page[page_id] || []
  page = page.filter(id => id != strikethrough.id)

  if (page.length > 0)
    by_page[page_id] = page
  else
    delete by_page[page_id]
}

const Store = createSlice({
  name: "strikethrough",
  initialState,
  reducers: {
    setStrikethroughs(state, { payload: strikethroughs }) {
      const lookups = { ... (strikethroughs || {}) }

      const by_page = {}
      for (let id in strikethroughs)
        addToPage(by_page, strikethroughs[id])

      state.lookups = lookups
      state.by_page = by_page
    },

    mergeStrikethroughs(state, { payload: strikethroughs}) {
      const lookups = { ...state.lookups, ... (strikethroughs || {}) }

      const by_page = { ... state.by_page }
      for (let id in strikethroughs)
        addToPage(by_page, strikethroughs[id])

      state.lookups = lookups
      state.by_page = by_page
    },

    setStrikethrough(state, { payload: strikethrough }) {
      const lookups= { ... state.lookups }
      const by_page = { ... state.by_page }

      const id = strikethrough.id || state.next_id
      removeFromPage(by_page, lookups[id])

      lookups[id] = { ...strikethrough, id }
      addToPage(by_page, lookups[id])

      if (id == state.next_id)
        state.next_id -= 1

      state.lookups = lookups
      state.by_page = by_page
    },

    removeStrikethrough(state, { payload: id}) {
      const lookups= { ... state.lookups }
      const by_page = { ... state.by_page }

      removeFromPage(by_page, lookups[id])
      delete lookups[id]

      state.lookups = lookups
      state.by_page = by_page
    }
  }
})

const { setStrikethrough, removeStrikethrough } =  Store.actions
export const { setStrikethroughs, mergeStrikethroughs } = Store.actions
export const reducer = Store.reducer


export function useGenerator({keepDrawing} = {}) {
  const dispatch = useDispatch()

  return rect => {
    const strikethrough = merge(generateEmpty(), { position: rect })

    if (keepDrawing)
      strikethrough.newlyAdded = true

    dispatch(setStrikethrough(strikethrough))
  }
}

export function useComponents(tools, page_id) {
  const strikethroughs = useSelector(state => Object.values(state.strikethroughs.lookups).filter(h => isOnPage(h, page_id)), shallowEqual)
  const selectedComponents = [] //useSelector(state => state.general.selectedComponents)

  const generateComponent = useGenerateComponent(tools, selectedComponents)
  return prepareComponents('strikethrough', strikethroughs).map(generateComponent)
}

function useApi() {
  // Generate the API
  const dispatch = useDispatch()
  const strikethroughs = useSelector(state => state.strikethroughs.lookups)
  const instance_id = useSelector(({ global: state }) => state.instance_id)

  const update = (id, componentData) => {
    dispatch(setStrikethrough({ id, ...componentData }))
    saveStrikethrough({ instance_id, id, componentData })
  }

  const create = (componentData, id) => {
    update(id, merge(generateEmpty(), componentData))
  }

  const read = id => strikethroughs[id]

  const destroy = id => {
    dispatch(removeStrikethrough(id))
    destroyStrikethrough({ instance_id, id })
  }

  return { create, read, update, destroy }
}

export function useTool() {
  const api = useApi()
  return getToolbar({name: 'strikethrough', api })
}

function saveStrikethrough({ instance_id, id, componentData }) {
  const url = `/forms/v3/instance/${instance_id}/strikethrough`
  const { position, options } = componentData
  const data = addCSRF({ strikethrough: { instance_id, id, position, options } })

  return new Promise((res, rej) => {
    const success = ({ strikethrough }) => res([strikethrough])

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch (e) {
        rej("There was a problem accessing the server")
      }
    }

    axios.post(url, data).then(res => success(res)).catch(xhr => error(xhr))
  })
}

function destroyStrikethrough({ instance_id, id }) {
  const url = `/forms/v3/instance/${instance_id}/strikethrough`
  const data = addCSRF({ strikethrough: { instance_id, id } })

  return new Promise((res, rej) => {
    const success = ({ strikethrough }) => res([strikethrough])

    const error = xhr => {
      try {
        const err = JSON.parse(xhr.responseText)
        rej(err.error)
      } catch (e) {
        rej("There was a problem accessing the server")
      }
    }

    axios.delete(url, data).then(res => success(res)).catch(xhr => error(xhr))
  })
}
