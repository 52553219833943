import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useControllerPropertySearch } from "./useControllerPropertySearch";
import { useWatchableRef } from "lib/hooks";

export function InvoiceModal(props) {
  const { title, submitText, open, onClose, onSubmit, invoice, buildingDisabled, additionalAddressDisabled } = props;
  const modalRef = useWatchableRef(null);
  const { component: PropertySearch, reset: resetPropertySearch } = useControllerPropertySearch();
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [enableLeaseId, setEnableLeaseId] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid },
    setValue,
  } = useForm({
    defaultValues: {
      building_id: "",
      additional_address_id: 0,
      unit: "",
      first_name: "",
      last_name: "",
      email: "",
      lease_id: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (!invoice) return;

    reset({
      building_id: invoice.building_id,
      additional_address_id: invoice.additional_address_id || 0,
      unit: invoice.unit,
      first_name: invoice.first_name,
      last_name: invoice.last_name,
      email: invoice.email,
      lease_id: invoice.lease_id,
    });
  }, [invoice]);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;

    const close = (_e) => {
      onClose();
      reset();
      resetPropertySearch();
    };

    $(localRefValue).on("hidden.bs.modal", close);

    return () => $(localRefValue).off("hidden.bs.modal", close);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  const onFormSubmit = (data, _e) => {
    onSubmit(data);
  };

  const onError = (errors, e) => console.log(errors, e);

  const onBuildingSelected = useCallback(
    (building) => {
      if (selectedBuilding?.id !== building?.id) {
        // Reset the building address if the selected building is changed to a different building.
        setValue("additional_address_id", 0);
      }

      setSelectedBuilding(building);

      if (building?.enable_rp_lease_id) {
        setEnableLeaseId(true);
      } else {
        setEnableLeaseId(false);
        setValue("lease_id", "");
      }
    },
    [setValue, selectedBuilding?.id],
  );

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal new-invoice-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <form onSubmit={handleSubmit(onFormSubmit, onError)}>
            <div className="modal-header">
              <h3 className="modal-title new-invoice-modal__title">{title}</h3>
              <h4 className="new-invoice-modal__subtitle">Provide Resident details for invoice:</h4>
            </div>
            <div className="modal-body">
              <div>
                <div style={{ flexGrow: 1 }}>
                  <div>
                    <div>
                      <label htmlFor="npm-building-id" className="form-label">
                        <span>Property</span>
                        <span style={{ color: "rgb(246, 138, 65)" }}>*</span>
                      </label>
                    </div>
                    <div>
                      <PropertySearch
                        control={control}
                        rules={{
                          required: true,
                          disabled: buildingDisabled,
                        }}
                        defaultBuilding={invoice?.building}
                        onBuildingSelected={onBuildingSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedBuilding?.additional_addresses?.length > 0 && (
                <div>
                  <div>
                    <label htmlFor="npm-additional-address-id" className="form-label">
                      <span>Property Address</span>
                      <span style={{ color: "rgb(246, 138, 65)" }}>*</span>
                    </label>
                  </div>
                  <div>
                    <select
                      className="form-control"
                      disabled={!selectedBuilding?.address || additionalAddressDisabled}
                      id="npm-additional-address-id"
                      {...register("additional_address_id")}
                    >
                      <option value="0">{selectedBuilding?.address}</option>
                      {selectedBuilding?.additional_addresses.map((additional_address) => (
                        <option key={additional_address.id} value={additional_address.id}>{additional_address.address}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div>
                <div>
                  <label htmlFor="npm-unit" className="form-label">
                    <span>Unit</span>
                    <span style={{ color: "rgb(246, 138, 65)" }}>*</span>
                  </label>
                </div>
                <div>
                  <input type="text" className="form-control" id="npm-unit" {...register("unit", { required: true })} />
                </div>
              </div>
              {enableLeaseId && (
                <div>
                  <div>
                    <label htmlFor="npm-lease-id" className="form-label">
                      <span>Lease ID</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="npm-lease-id"
                      {...register("lease_id", { required: false })}
                    />
                  </div>
                </div>
              )}
              <div>
                <div>
                  <label htmlFor="npm-first-name" className="form-label">
                    <span>Resident Full Name</span>
                    <span style={{ color: "rgb(246, 138, 65)" }}>*</span>
                  </label>
                </div>
                <div className="new-invoice-modal__form-block">
                  <div style={{ flexGrow: 1, marginRight: 20 }}>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="npm-first-name"
                        placeholder="First"
                        {...register("first_name", { required: true })}
                      />
                    </div>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="npm-last-name"
                        placeholder="Last"
                        {...register("last_name", { required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="npm-email" className="form-label">
                    <span>Email</span>
                    <span style={{ color: "rgb(246, 138, 65)" }}>*</span>
                  </label>
                </div>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    id="npm-email"
                    {...register("email", {
                      required: true,
                      pattern: { value: /^.+@.+\..+$/ },
                    })}
                  />
                </div>
                <span className="new-invoice-modal__small-subtitle">
                  Resident will use this email for Domecile login.
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary cancel-btn"
                data-dismiss="modal"
                onClick={onClose}
                style={{ float: "left" }}
                type="button"
              >
                Cancel
              </button>
              <button type="submit" className="btn" disabled={!isValid}>
                {submitText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

InvoiceModal.defaultProps = {
  title: "Create Invoice",
  submitText: "Next",
};

InvoiceModal.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invoice: PropTypes.object,
  buildingDisabled: PropTypes.bool,
  additionalAddressDisabled: PropTypes.bool,
};
