import React from 'react'

function bolden(default_org) {
  return org => org.id == default_org ? <strong>{org.name}</strong> : org.name
}

function OrgNames({organizations: orgs, default_organization}) {
  const reducer = (a,b) => [a,",", b]
  const names = orgs.map(bolden(default_organization))
  const style = { display: "inline-block", marginRight: "10px" }

  return <p className="col-xs-12 text-center">
    <i style={style} className="fa fa-plus-square-o"></i>
    { orgs.length == 0 ? "Click to select Organizations" : names.reduce(reducer) }
  </p>
}

function SelectedOrganizations(props) {
  const ids = props.organizations.map(org => org.id)
  const key = attr => `organization[relationship][${props.relationship}][${attr}]`
  const default_org = props.default_organization
  const onClick = () => props.handleToggleDropdownActive()

  
  return <div className="row" onClick={onClick}>
    <OrgNames { ...props } />
    <input type="hidden" name={key('selected')} value={ids.join(',')} />
    <input type="hidden" name={key('default')} value={default_org || ""} />
  </div>
}

export default SelectedOrganizations