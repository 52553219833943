import React from 'react'

import StripeCard from 'components/utility/stripe_card'

const is_blank = val => !val || String(val).match(/^\s*$/)

class Checkout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.carts = this.props.carts
  }

  hasCheckout() {
    return this.props.checkout_items > 0
  }

  hasConfirmation() {
    return this.props.confirmation_items > 0
  }

  confirm() {
    const statements = []
    if (this.hasCheckout())
      statements.push(`Your card will be charged <span class="dome-text-w500">${asMoney(this.props.totals.checkout.total)}</span> right now.`)
    if (this.hasConfirmation())
      statements.push(`Your card may be charged up to <span class="dome-text-w500">${asMoney(this.props.totals.confirmation.total)}</span> upon confirmation of applicable documents.`)


    const lines = []
    lines.push("<p>Please confirm the following items:</p>")
    lines.push(`<ol>${statements.map(stmt => `<li>${stmt}</li>`).join('')}</ol>`)
    lines.push('<p class="contact">If you have any questions, please contact <a href="mailto:info@domecile.com">info@domecile.com</a>.</p>')

    var swal_options = {
      title: "purchase confirmation",
      html: true,
      text: `<div id="store-documents-checkout-confirmation">${lines.map(line => `${line}`).join("")}</div>`,
      showCancelButton: true,
      confirmButtonColor: "#56A256",
      confirmButtonText: "confirm & purchase",
      closeOnCancel: true,
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }

    swal(swal_options, confirm => confirm ? this.processCard() : null)
  }

  errored(errors) {
    if (!Array.isArray(errors))
      errors = [errors]

    const lines = []
    lines.push("Your purchase returned with the following errors:")

    if (errors.length == 1)
      lines.push(errors[0])
    else
      lines.push(`<ol>${errors.map(stmt => `<li>${stmt}</li>`).join("")}</ol>`)

    lines.push(`<p>For further assistance, please contact <a href="mailto:info@domecile.com">info@domecile.com</a>.</p>`)

    const swal_options = {
      title: "purchase error",
      html: true,
      text: `<div id="store-documents-checkout-error">${lines.map(line => `<p>${line}</p>`).join("<br />")}</div>`,
      showCancelButton: false,
      confirmButtonColor: "#005C4F",
      confirmButtonText: "OK",
    }

    swal(swal_options)
  }

  processCard() {
    if (!this.state.payment_method)
      return swal.close()

    if (this.state.payment_method != "new-card")
      return this.processOrder("payment-method", this.state.payment_method)

    const success = token => this.processOrder("token", token)
    const error = message => {console.log(message); swal.close()}
    this.refs.stripe_card.generateToken().then(success).catch(error)
  }

  processOrder(payment_method, payment_method_value) {
    const data = {}
    data[payment_method == 'payment-method' ? 'payment_method_id' : 'payment_token'] = payment_method_value

    const success = res => {
      reload_to_confirmation(res.purchase_group_id) || request_confirmation_popup()
    }

    const reload_to_confirmation = purchase_group_id => {
      if (is_blank(purchase_group_id))
        return false

      window.location = `/shopping_cart/success?purchase_group=${purchase_group_id}`
      return true
    }

    const request_confirmation_popup = () => {
      const text = []
      text.push("The documents have been submitted for approval. You will be notified by email upon approval.")
      text.push("<span class='redirect-msg'>You will be redirected to your home page in 5 seconds.</span>")

      swal({
        title: "<i class='fa-thin fa-circle-check'></i><br>request submitted!",
        html: true,
        text: text.join("<br /><br />"),
        closeOnConfirm: false,
        showConfirmButton: false
      })

      // setTimeout(() => window.location = "/", 5000)
    }

    const error = res => {
      const json = JSON.parse(res.responseText)
      this.errored(json.errors || json.error)
    }

    $.ajax({
      url: "/shopping_cart/checkout",
      type: "post",
      data: hashToPostQueryString(data),
      success,
      error
    })
  }

  render() {

    return <div className="store-document-checkout-component">
      {this.renderAmounts()}
      {this.renderStripeCard()}
      {this.renderButton()}
    </div>
  }

  renderButton() {
    const classes = ["dome-btn", "dome-btn-base", "dome-btn-dark-green"]
      return (
        <div className='button-container'>
          <button className={classes.join(" ")} onClick={() => this.confirm()}>
            <span>continue</span>
          </button>
        </div>
      )
  }

  renderStripeCard() {
    const changePaymentMethod = payment_method => this.setState({payment_method: payment_method})
    return <StripeCard ref="stripe_card" cards={this.props.cards} onChangePaymentMethod={changePaymentMethod} />
  }

  renderAmounts() {
    const show_checkout = this.hasCheckout()

    const checkout = <div>
      <span>Your card will be charged </span>
      <span className="price">{asMoney(this.props.totals.checkout.total)}</span>
      <span> immediately for all items available for direct purchase. Items requiring prior authorization will be charged upon confirmation.</span>
    </div>

    return <div className="checkout-amounts">
      { show_checkout ? checkout : null}
    </div>
  }
}

export default Checkout
