import React from 'react'

class Toast extends React.Component {
  componentDidMount() {
    this.toast = $(this.refs.toast)

    setTimeout(() => this.toast.css({opacity: 1}), 100)
    setTimeout(() => this.transitionOff(), 3500)
  }

  transitionOff() {
    this.toast.css({opacity: 0})
    setTimeout(() => this.props.onClose(), 750)
  }

  componentWillUnmount() {
    delete this.toast
  }

  render() {
    return <div className="toast" ref="toast">
      <div className="toast-header">
        <div>
          <strong>{this.renderTitle()}</strong>
        </div>
        <div>
          <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="toast-body">
        {this.renderBody()}
      </div>
    </div>
  }

  renderTitle() {
    switch(this.props.type) {
    case "cart":
      return "Document Added to Cart"
    case "library":
      return "Document Added to Library"
    case "request":
      return "Awaiting confirmation"
    }
  }

  renderBody() {
    switch(this.props.type) {
    case "cart":
      return `${this.props.document.label} was added to your cart.`
    case "library":
      return `${this.props.document.label} was added to your library.`
    case "request":
      return `${this.props.document.label} will be added to your library upon confirmation.`
    }

  }
}

export default Toast