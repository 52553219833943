import React from 'react'

import { uniq, omit } from 'lodash'

import { RectWrapper, eventForId } from 'components/utility/draw_area'
import { useReadApi, createToolbar, toolGenerator, useDraggable } from './base'


import { useEventListeners } from '@deathbyjer/react-event-manager'

const isSignatureIdArray = id => Array.isArray(id) && id[0] == 'signature'

export const generateEmpty = () => ({
  positions: {
    signature: {}
  },
  signature: {}
})

function onRectUpdated({signature, type, rect, api}) {
  const positions = { ... signature.positions }
  positions[type] = { ... positions[type], ... rect }
  api.update(signature.id, {...signature, positions })
}

function SignatureComponent({id, setId, api, signatureSelected}) {
  const fullId = ["signature", "signature", id]
  setId ? setId(fullId) : null

  const signature = useReadApi(api, id)

  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({rect}) => onRectUpdated({type: 'signature', api, signature, rect})
  })

  const classes = ["signature-tool-signature-component"]
  if (signatureSelected)
    classes.push("signatured-is-selected")

  return <div className={classes.join(" ")}>

  </div>
}

function TimeComponent({id, api, setId, draggable}) {
  const fullId = ["signature", "time", id]
  setId ? setId(fullId) : null

  const signature = useReadApi(api, id)

  const classes = ['signature-tool-time-component']
  const dragProps = useDraggable({id: fullId, draggable, classes})

  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({rect}) => onRectUpdated({type: 'time', api, signature, rect})
  })

  const props = {
    className:  classes.join(" "),
    ...dragProps
  }

  return <RectWrapper {...props}>
    Signed At
  </RectWrapper>
}

function NameComponent({id, api, setId, draggable}) {
  const fullId = ["signature", "name", id]
  setId ? setId(fullId) : null

  const signature = useReadApi(api, id)

  const classes = ['signature-tool-time-component']
  const dragProps = useDraggable({id: fullId, draggable, classes})

  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({rect}) => onRectUpdated({type: 'name', api, signature, rect})
  })

  const props = {
    className:  classes.join(" "),
    ...dragProps
  }

  return <div {...props}>
    Signed By    
  </div>
}

function SingleToolbarDraggable({id, api}) {
  const signature = useReadApi(api, id)
  const { time: hasTime, name: hasName } = signature.positions
  
  const props = {
    id, api, draggable: true
  }

  return <div className="draggable-items">
    { hasTime ? null : <TimeComponent {...props} /> }
    { hasName ? null : <NameComponent {...props} /> }
  </div>
}

function SelectTimeFormat({api, id}) {
  
  const signature = useReadApi(api, id)
  const now = new Date()

  const formats = [
    '',
    'Month DD, YYYY - HH:MM:SS AM'
  ]

  return <div className="signature-time-formatting">
    <label>Select Date/Time Format</label>
  </div>
}

const SingleToolbarComponent = ({api, componentIds}) => {
  const id = componentIds[0][2]

  const signature = useReadApi(api, id)
  return <div className="signature-tool-single-toolbar-component">
    <SingleToolbarDraggable id={id} api={api} />
    <div>
      Time Format:

    </div>
  </div>
}

const MultipleToolbarComponent = ({api, componentIds}) => {
  return <div className="signature-tool-single-toolbar-component">
    MULTIPLE SIDEBAR!
  </div>
}

export function separateComponents(namespace, signatures) {
  const signature_types = ['signature', 'time', 'name']
  const components = []
  for (let signature of signatures) {
    for (let signature_type of signature_types) {
      if (signature.positions[signature_type]) {
        const component = {
          type: `${namespace}/${signature_type}`, 
          id: signature.id, 
          props: {
            id: signature.id, 
            rect: { ... signature.positions[signature_type] }
          }
        }

        switch(signature_type) {
        case 'signature':
          if (signature.newlyAdded)
            component.props.drawOnCreate = true
          break
        }

        components.push(component)
      }
    }
  }

  return components
}

function idForSelectedComponents(componentIds) {
  if (!componentIds.every(isSignatureIdArray))
    return null

  const signatureIds = componentIds.map(id => id[2])
  return uniq(signatureIds).length == 1 ? signatureIds[0] : null
}

export const signatureIsOnPage = (signature, page_id) => signature.positions?.signature?.page == page_id


export default toolGenerator({
  components: { 
    signature: SignatureComponent,
    time: TimeComponent,
    name: NameComponent
  },

  toolbars: {
    single: createToolbar(SingleToolbarComponent, {
      showForSelected(componentIds) { 
        // Don't show if it's not
        if (!componentIds.every(isSignatureIdArray))
          return false

        // All signatures for the same signature
        const signatureIds = componentIds.map(id => id[2])
        if (uniq(signatureIds).length != 1)
          return false

        return true
      }
    }),
    multiple: createToolbar(MultipleToolbarComponent, {
      showForSelected(componentIds) {
        // Don't show if it's not
        if (!componentIds.every(isSignatureIdArray))
          return false

        // All signatures for the same signature
        const signatureIds = componentIds.map(id => id[2])
        if (uniq(signatureIds).length < 2)
          return false

        return true
      }
    })
  }
}, {
  onItemDropped(api, {componentId, point}) {
    try {
      const [namespace, type, id] = componentId.split(",")
      
      if (!id)
        return

      if (namespace != 'signature')
        return

      const rect = { width: 10, height: 3, ... point }
      const signature = api.read(id)
      if (signature.positions[type])
        return
      
      const positions = { ...signature.positions, [type]: rect }
      api.update(id, { ...signature, positions })
    } catch(e) {
      console.error(e)
    }
  },

  componentPropsFor({allSelected, props}) {
    const localProps = { ... props }
    const componentId = idForSelectedComponents(allSelected)

    if (props.id && props.id == componentId)
      localProps.signatureSelected = true

    return localProps
  }
})