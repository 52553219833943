import React from 'react'
import PropTypes from 'prop-types'

const SubmitVoteButton = (props) => (
  <div>
    <button className={`btn ${props.submittable ? "btn-submittable" : "btn-disabled"}`} disabled={!props.submittable} onClick={props.handleSave}>Submit Vote</button>
  </div>
)

SubmitVoteButton.propTypes = {
  submittable: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired
}

export default SubmitVoteButton
