import React, { useState, useEffect } from 'react'
import { pick } from 'lodash'
import ButtonAndModal from './ButtonAndModal'

export default function DealStatusTable(props) {

  const [dealStatuses, setDealStatuses] = useState(props.deal_statuses)
  const [dealStatusesForType, setDealStatusesForType] = useState([])
  const [currentStatusType, setCurrentStatusType] = useState('package')
  const [nextSortOrderIndex, setNextSortOrderIndex] = useState(0)
  const [countForCurrentDSType, setCountForCurrentDSType] = useState(props.deal_status_counts)

  const DEAL_STATUS_TYPES = [
    { id: "package", label: "Packages" },
    { id: "rental", label: "Rentals" },
    { id: "rental_lease", label: "Rental Leases" },
    { id: "alteration", label: "Alterations" },
  ]

  const DEAL_STATUS_ROLES = [
    { id: null, label: "--Select Role--" },
    { id: "intake", label: "Intake" },
    { id: "package_incomplete", label: "Package Incomplete" },
    { id: "package_complete", label: "Package Complete" },
    { id: "approved", label: "Approved" },
    { id: "denied", label: "Denied" },
    { id: "archived", label: "Archived" },
    { id: "unarchived", label: "Unarchived" },
  ]

  //used to alter what deal statuses are being displayed based on type selected
  //also returns package count dependent on deal status type selected + controller logic
  const addPackageCounts = () => {
    const dsCounts = Object.entries(props.deal_status_counts)
    const getCounts = (ds) => {
      for (const [key, value] of dsCounts) {
        if (currentStatusType == key) return value[ds.status_identifier] || 0
      }
    }

    const dealStatusesWithPackageCounts = dealStatuses
      .filter(ds => ds?.status_type == currentStatusType)
      .map(ds => ({ ...ds, package_count: getCounts(ds) }))

    setDealStatusesForType(dealStatusesWithPackageCounts)
  }

  //used to alter what deal statuses are being displayed based on type selected
  useEffect(() => {
    addPackageCounts()
  }, [currentStatusType, dealStatuses])

  //used to instantiate next sort_order number (when creating a new deal status)
  useEffect(() => {
    let slicedDealStatus = dealStatusesForType.slice(-1)
    slicedDealStatus.map(ds => {
      setNextSortOrderIndex(ds.sort_order + 1)
    })
  }, [dealStatusesForType])

  function updateDealStatus(event) {
    event.preventDefault();
    var data = returnRoles();

    const formData = new FormData()
    formData.append('status_roles', data)

    $.ajax({
      url: `/super/organizations/${props.org_id}/update_deal_status_roles`,
      data: formData,
      type: 'POST',
      dataType: 'json',
      processData: false,
      contentType: false,
    })
      .done((res) => {
        window.location.reload();
      })
  }

  function returnRoles() {
    const status_array = dealStatusesForType.map(ds => pick(ds, ['id', 'status_role']))

    return JSON.stringify(status_array)
  }

  const editDealStatus = (org_id, deal_status_id) => {
    window.location = `/super/organizations/${org_id}/deal_status/${deal_status_id}`
  }

  function deleteDealStatus(org_id, deal_status_id) {
    swal({
      type: "warning",
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this deal status?",
      showCancelButton: true,
      closeOnConfirm: true,
      showLoaderOnConfirm: true
    }, confirm => confirm ?
      $.ajax({
        type: 'DELETE',
        dataType: 'json',
        url: `/super/organizations/${org_id}/delete_deal_status/${deal_status_id}`,
        data: { id: deal_status_id },
        success: function (res) { setDealStatuses(res.deal_statuses) },
        error: function () { window.location = `/super/organizations/${org_id}/deal_status/${deal_status_id}/delete_conflict` }
      })
      : null)
  }

  function selectStatusRole(e, current_ds) {
    let roleSelected = e
    const newDealStatuesForType = dealStatusesForType.map(ds => {
      if (current_ds.id == ds.id) return { ...current_ds, status_role: roleSelected }

      return ds;
    })
    setDealStatusesForType(newDealStatuesForType)
  }

  $('#sortable-section').sortable({
    placeholder: "deal-status-sortable-placeholder",
    snapTolerance: 1,
    update: function () {
      var idHash = {}
      var sortOrder = 0
      $('#sortable-section').children('.ds-handle').map(function () {
        var id = $(this).data('id')
        idHash[id] = sortOrder;
        sortOrder += 1
      });
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: `/super/organizations/${props.org_id}/deal_status`,
        data: { deal_statuses_order: idHash },
        success: function () { location.reload() }
      })
    }
  })

  return (
    <form onSubmit={updateDealStatus}>
      <div className="main-content">
        <div className="row">
          <h4 style={{ display: "inline-block" }}>Deal Statuses</h4>
          <ButtonAndModal currentStatusType={currentStatusType} setDealStatuses={setDealStatuses} org_id={props.org_id} nextSortOrderIndex={nextSortOrderIndex} />
        </div>
        <div className="col-xs-12">
          <div className="col-xs-5" style={{ paddingRight: "0" }}>
            <h5 style={{ textAlign: "right", marginBottom: "20px" }}>Deal Status Type:</h5>
          </div>
          <div className="col-xs-3">
            <Select value={currentStatusType} options={DEAL_STATUS_TYPES} onChange={(e) => setCurrentStatusType(e.target.value)} type={'status_type'} />
          </div>
          <table className="clean-head" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Sort Order</th>
                <th>Status Name</th>
                <th>Packages Using Status</th>
                <th>Status Role</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody id="sortable-section" style={{ backgroundColor: 'white' }}>
              {dealStatusesForType.map((ds, index) => {

                return (
                  <>
                    <tr key={index} className="ds-handle" data-id={ds.id}>
                      <td style={{ paddingLeft: "0px" }}>{ds.sort_order}</td>
                      <td> {ds.status} </td>
                      <td> {ds.package_count || 0} </td>
                      <td className='status-role-td' data-role={ds.status_role}>
                        <Select value={ds.status_role || ''} options={DEAL_STATUS_ROLES} onChange={(e) => selectStatusRole(e.target.value, ds)} />
                      </td>
                      <td>
                        <i className="fa fa-trash-o" onClick={() => deleteDealStatus(props.org_id, ds.id)}></i>
                      </td>
                      <td>
                        <i className="fa fa-edit" onClick={() => editDealStatus(props.org_id, ds.id)}></i>
                      </td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
          <button className="btn btn-success pull-right" type='submit'>Save</button>
        </div>
      </div>
    </form>
  )
}

function Select({ value, options, onChange, type }) {
  const optionElements = options.map(({ id, label }) => <option key={id} value={id}>{label}</option>)
  const marginForStatusType = type == 'status_type' ? "auto" : ''
  const widthForStatusType = type == 'status_type' ? '' : "inherit"

  return (
    <select className="form-control" style={{ width: widthForStatusType, margin: marginForStatusType }} value={value} onChange={onChange}>{optionElements}</select>
  )
}
