import React from 'react'
import PropTypes from 'prop-types'

export default class TypeFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    this.setState({ type: this.props.transactionTypes[0] })
  }



  renderTypeSelect() {
    return <select name="filters[itemType]" defaultValue={this.props.itemType}>
      {this.props.transactionTypes.map(type =>
        <option key={type} value={type}>{typeToHumanFriendlyString(type)}</option>)}
    </select>
  }

  render() {
    return <div className="type-filter">
      {this.renderTypeSelect()}
    </div>
  }
}

TypeFilter.propTypes = {
  typesWithCount: PropTypes.array,
  itemType: PropTypes.string,
  transactionTypes: PropTypes.array.isRequired
}

TypeFilter.defaultProps = {
  typesWithCount: [{}],
  itemType: ""
}
