import React, { Component } from 'react'
import { connect } from 'react-redux'
import List from './List'
import Store from './Store'

export class Main extends Component {
  constructor(props) {
    super(props)
  }

  isPropertyContactType() {
    return this.props.buildingResponsiblePartyType === 'PropertyContacts'
  }

  isBoardMemberType() {
    return this.props.buildingResponsiblePartyType === 'BoardMembers'
  }

  renderNewBuildingResponsiblePartyButton() {
    let buttonText
    if(this.isPropertyContactType()) {
      buttonText = 'property contact'
    } else if (this.isBoardMemberType()) {
      buttonText = 'board member'
    }

    return <button className='dome-btn dome-btn-base dome-btn-dark-green'
      onClick={() => this.props.openModalForContext('New Building Responsible Party')}
      style={{ padding: '4px 13px', boxShadow: '3px 3px 5px #dbdbdb' }}>
      <i className='fa-regular fa-plus' style={{marginRight: '3px'}}></i>
      {buttonText}
    </button>
  }

  renderHeader() {
    let headerText
    if(this.isPropertyContactType()) {
      headerText = 'property contacts'
    } else if (this.isBoardMemberType()) {
      headerText = 'board members'
    }

    return <>
      <h4 className='dome-h2 dome-text-w500'>{headerText}</h4>
      {this.renderNewBuildingResponsiblePartyButton()}
    </>
  }

  render() {
    return (
      <>
        <div className='edit-container-header' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {this.renderHeader()}
        </div>
        <List
          package_types={this.props.package_types}
          alteration_processor_package_types={this.props.alteration_processor_package_types}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buildingResponsiblePartyType: state.main.buildingResponsiblePartyType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModalForContext: context => dispatch(Store.openModalForContext(context))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
