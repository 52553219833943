import React from 'react'

import MultiSelect from 'components/utility/multi_select'

class SupportingDocumentsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.errors = {}

    this.state.firstScreen = true

    this.state.attachedDocuments = Object.assign({}, this.props.documents)
    this.state.packageDocuments = {}
    this.state.packageRequirements = []

    this.loadDocuments()
    this.loadDocumentTypes()

    this.loadPackage()
  }

  loadPackage() {
    const filter = {
      visible_package_requirements: {id: true, title: true, package_documents: ["id", "label", "combined_pdf"]}
    }

    $.ajax({
      url: `/packages/${this.props.package_id}/json`,
      data: {q: filter},
      success: res => {
        const requirements = []
        const documents = {}
        for (let requirement of res.visible_package_requirements) {
          requirements.push({
            req_id: requirement.id,
            req_title: requirement.title, 
            doc_ids: requirement.package_documents.map(doc => doc.id)
          })

          documents[requirement.id] = requirement.package_documents.map(doc => ({
            doc_title: doc.label,
            doc_id: doc.id,
            url: doc.combined_pdf.url
          }))
        }

        this.setState({packageRequirements: requirements, packageDocuments: documents})
      }
    })
  }

  loadDocumentTypes() {
    const options = {}
    for (let report_type of this.props.reports) {
      for (let document_type of this.props.reportTypes[report_type].credit_document_types) {
        if (options[document_type.id])
          continue

        options[document_type.id] = this.props.documentTypes[document_type.id]
      }
    }

    this.state.documentTypeOptions = options
  }

  orderedApplicableDocumentTypes() {
    return Object.keys(this.props.documentTypes).filter(document_type => {
      return this.props.reports.some(report_type => {
        return this.props.reportTypes[report_type].credit_document_types.some(doc_type => {
          return String(doc_type.id) == String(document_type)
        })
      })
    })
  }

  loadDocuments() {
    this.state.attachedDocuments = {}
    for (let {doc_id, type_id} of this.props.documents) {
      this.state.attachedDocuments[doc_id] = this.state.attachedDocuments[doc_id] || []
      this.state.attachedDocuments[doc_id].push(type_id)
    }
  }

  componentDidMount() {
    $(this.refs.modal).modal("show")
    $(this.refs.modal).on("hidden.bs.modal", () => {
      if (this.props.onClose)
        this.props.onClose()
    })
  }

  close() {
    $(this.refs.modal).modal("hide")
  }

  packageDocumentsHash() {
    const hash = {}
    for (let id in this.state.packageDocuments)
      for (let doc of (this.state.packageDocuments[id] || []))
        hash[doc.doc_id] = doc

    return hash    
  }

  render() {
    return <div className="supporting-documents">
      <div id="supporting-documents-modal" className="modal fade" tabIndex="-1" role="dialog" ref="modal">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body">
                
              {this.renderModalHeader()}
              <div id="supporting-documents-modal-body" className="col-xs-12">
                {this.renderModalBody()}
                {this.renderModalFooter()}
              </div>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  renderModalHeader() {
    const title = "Supporting Documentation" + (this.state.firstScreen ? "" : " Summary")

    return <div className="header-area">
      <h3>{title}</h3>
      <h4>The reports you're requesting require supporting documentation</h4>
    </div>
/*    return null
    if (this.state.firstScreen) {
      return <div className="row modal-title orange-text">
        <div className="col-xs-6">
          <h4>Supporting Documentation</h4>
        </div>
        <div className="col-xs-6">
          <h4 className="pull-right"> {this.props.party.name} </h4>
        </div>
      </div>
    } else {
      return <div className="row modal-title orange-text">
        <div className="col-xs-6">
          <h4></h4>
        </div>
        <div className="col-xs-6">
          <h4 className="pull-right"> {this.props.party.name} </h4>
        </div>
      </div>
    } */
  }

  renderModalBody() {
    const onClick = () => {
      this.selectNoDocument()
    }

    return <div className="row" onClick={onClick}>

      {this.renderBodyHeader()}
      {this.renderRequiredDocumentTypes()}
      {this.renderInstructions()}
      {this.renderSelectDocuments()}
      {this.renderSummary()}
    </div>
  }

  renderBodyHeader() {
    return <div>
      <h3>{this.props.party.full_name}</h3>
    </div>
  }

  renderInstructions() {
    if (!this.state.firstScreen)
      return null

    return <h5 className="dark-text text-center">
        Select the documents below which satisfy the needed document types.
      </h5>
  }

  renderSelectDocuments() {
    if (!this.state.firstScreen)
      return null

    const classes = ["documents-and-preview-container"]
    if (this.state.loading_canvas)
      classes.push("is-loading")

    return <div className={classes.join(" ")}>
      {this.renderFullDocumentPreview()}
      <div>
        <div className="preview-container">
          {this.state.loading_canvas ? <div className="spinner-grow"></div> : null}
          {this.renderFirstPagePreview()}
        </div>
        <div className="documents-container">
          <h4 className="orange-text ">Requirements</h4>
          {this.renderPackageRequirements()}
        </div>
      </div>
    </div>
  }

  renderFirstPagePreview() {
    const onClick = () => this.setState({full_preview_url: this.state.currentDocumentUrl})

    const placeholder = <div className="placeholder">
      <span>Document Preview</span>
    </div>

    return <div className="first-page-preview" onClick={onClick}>
      {this.state.currentDocument ? null : placeholder}
      <canvas ref="preview_canvas" id="pdf-js-viewer" className="supporting-docs-iframe" title="webviewer" frameBorder="0"></canvas>
    </div>
  }

  renderFullDocumentPreview() {
    if (!this.state.full_preview_url)
      return null

    return <div className="full-document-preview">
      <object data={this.state.full_preview_url} type="application/pdf">
        <a href={this.state.full_preview_url}>Download the PDF</a>
      </object>
    </div>
  }

  attachedDocumentsByType() {
    const types = {}
    for (let document_id in this.state.attachedDocuments) {
      for (let type_id of this.state.attachedDocuments[document_id]) 
        types[type_id] = (types[type_id] || []).concat([document_id])
    }

    return types
  }

  renderSummary() {
    if (this.state.firstScreen)
      return null

    const ERROR_DIV = <div className="warning"><i className="fa fa-exclamation-triangle" /></div>
    const document_types = this.orderedApplicableDocumentTypes()
    const documentsByType = this.attachedDocumentsByType()

    const missing_types = document_types.filter(type_id => {
      const documents = documentsByType[type_id] || []
      return documents.length == 0
    }).length

    const packageDocuments = this.packageDocumentsHash()
    const documentTypes = document_types.map(document_type => {
      const documents = documentsByType[document_type] || []
      const document_divs = documents.map(id => <div key={id}>{packageDocuments[id].doc_title}</div>)

      const classes = []
      if (documents.length == 0)
        classes.push("error")

      return <tr key={document_type} className={classes.join(" ")}>
        <td>{this.props.documentTypes[document_type]}</td>
        <td>
          {documents.length == 0 ? ERROR_DIV : document_divs}
        </td>
      </tr>
    })

    const error_area = <div className="errors">
      <i className="fa fa-exclamation-triangle" />
      <h4>{missing_types} document type{missing_types > 1 ? "s are" : " is"} missing.</h4>
    </div>

    return <div className="summary-container">
      {missing_types > 0 ? error_area : null}
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <td>Document Type</td>
              <td>Document Name</td>
            </tr>
          </thead>
          <tbody>{documentTypes}</tbody>
        </table>
      </div>
    </div>
  }

  renderPreviewFooter() {
    const clickBack = () => {
      const afterSetState = () => this.renderDocumentPreview(this.state.currentDocumentUrl, {})
      this.setState({full_preview_url: null}, afterSetState)
    }

    return <div className="preview-footer">
      <button type="button" className="btn btn-default btn-lg" onClick={clickBack}>Go Back</button>
    </div>
  }

  renderModalFooter() {
    if (this.state.full_preview_url)
      return this.renderPreviewFooter()

    return <div className="text-center navigation">
      <div>
        {this.prevButton()}
      </div>
      <div>
        {this.nextButton()}
      </div>
    </div>
  }

  renderRequiredDocumentTypes() {
    if (!this.state.firstScreen)
      return

    const types = (this.orderedApplicableDocumentTypes() || []).map(id => {
      return <li className="orange-text" key={id}><h5>{this.props.documentTypes[id]}</h5></li>
    })

    const list = <div className="required-doc-types">
      <div>Required Document Types:</div>
      <ul className="no-bullets required-doc-types-list dark-text">
        {types}
      </ul>
    </div>

    return list
  }

  // rewrite this using react
  renderDocumentPreview(url, { pageNumber, scale }) {
    const pdfjsLib = window['pdfjs-dist/build/pdf']
    if (!pageNumber)
      pageNumber = 1

    // pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js'

    this.setState({loading_canvas: true})

    const loadingTask = pdfjsLib.getDocument(url)
    loadingTask.promise.then(pdf => {

      // Fetch the requested page
      pdf.getPage(pageNumber).then(page => {
        const width = page.view[2]
        const height = page.view[3]

        scale = Math.min(306.0 / width, 396.0 / height)

        const canvas = this.refs.preview_canvas
        const viewport = page.getViewport({ scale })

        // Prepare canvas using PDF page dimensions
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };

        const renderTask = page.render(renderContext)
        renderTask.promise.then(() => this.setState({loading_canvas: false}))
      });
    });
  }

  cleanCanvas() {
    const canvas = this.refs.preview_canvas
    if (!canvas)
      return

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  // renderSelect() {
  //   const hash = this.props.reportTypes
  //   const options = Object.keys(hash).map(key => <option key={key} value={key}>{hash[key]}</option>)
  //   options.unshift(<option className="placeholder" value="" key="__placeholder">-- Select Document Type -- </option>)

  //   const input = <select className="find-me">{options}</select>
  //   return this.renderLine(input)
  // }

  selectDocument(doc) {
    if (this.state.currentDocument == doc.doc_id)
      return

    this.renderDocumentPreview(doc.url, {})
    this.setState({ currentDocument: doc.doc_id, currentDocumentUrl: doc.url })
  }

  selectNoDocument() {
    this.cleanCanvas()
    this.setState({currentDocument: null})
  }

  renderPackageRequirement(req) {
    return <div key={req.req_id}>
      <div className="package-requirement" data-toggle="collapse" data-target={"#package-req-"+req.req_id}>
        {req.req_title}
      </div> 

      {this.renderRequirementDocuments(req.req_id)} 
    </div>
  }

  renderPackageRequirements() {
    const reqs = this.state.packageRequirements.map(req => this.renderPackageRequirement(req))
    const list = <div className="package-requirements">{reqs}</div>
    return list
  }

  renderRequirementDocumentSelected(doc) {
    if (this.state.currentDocument == doc.doc_id) 
      return null

    const document_types = (this.state.attachedDocuments[doc.doc_id] || []).map(id => this.props.documentTypes[id])
    if (document_types.length == 0)
      return null

    const onClickRemove = e => {
      e.stopPropagation()

      const attachedDocuments = Object.assign({}, this.state.attachedDocuments)
      delete attachedDocuments[doc.doc_id]
      this.setState({attachedDocuments})
    }

    return <div className="document-type-selected">
      <div className="list">{document_types.join(", ")}</div>
      <div className="remove" onClick={onClickRemove}><i className="fa fa-times" /></div>
    </div>
  }

  renderRequirementDocumentSelectType(doc) {
    if (this.state.currentDocument != doc.doc_id) 
      return null

    const onChange = documents => {
      const attachedDocuments = Object.assign({}, this.state.attachedDocuments)
      attachedDocuments[doc.doc_id] = documents
      this.setState({attachedDocuments})
    }

    return <div className="document-type-select">
      <MultiSelect options={this.state.documentTypeOptions} placeholder="-- Select a Document Type --" defaultValue={this.state.attachedDocuments[doc.doc_id]} onChange={onChange} multiple={true} closeOnSelect={true} />
    </div>
  }

  renderRequirementDocument(doc) {
    const classes = ["package-document"]
    if (this.state.currentDocument == doc.doc_id)
      classes.push("selecting")

    const onClick = e => {
      e.stopPropagation()
      this.selectDocument(doc)
    }

    return <div className={classes.join(" ")} key={doc.doc_id} onClick={onClick}>
      <div className="document-name">
        {doc.doc_title}
      </div>
      {this.renderRequirementDocumentSelectType(doc)}
      {this.renderRequirementDocumentSelected(doc)}
    </div>
  }

  renderRequirementDocuments(id) {
    const docs = (this.state.packageDocuments[id] || []).map(doc => this.renderRequirementDocument(doc))
    const list = <div className="package-documents" id={"package-req-"+id} className="no-bullets collapse in">{docs}</div>

    return list
  }

  nextButton() {
    const buttonTitle = this.state.firstScreen ? "Next" : "Continue"
    return <button type="button" className="btn btn-bper pull-right" onClick={() => this.nextButtonAction()}>
      {buttonTitle}
    </button>
  }

  prevButton() {
    const buttonTitle = this.state.firstScreen ? "Cancel" : "< Go Back"
    return <button type="button" className="btn pull-left" onClick={() => this.prevButtonAction() }>
      {buttonTitle}
    </button>
  }

  prevButtonAction() {
    this.state.firstScreen ? this.close() : this.prevScreen()
  }

  nextButtonAction() {
    this.state.firstScreen ? this.nextScreen() : this.finishDocumentAttaching()
  }

  nextScreen() {
    this.setState({ firstScreen: false })
  }

  prevScreen() {
    this.setState({ firstScreen: true })
  }

  finishDocumentAttaching() {
    const documents = []
    for (let doc_id in this.state.attachedDocuments)
      for (let type_id of this.state.attachedDocuments[doc_id])
        documents.push({type_id, doc_id})
    
    if (this.props.onChanged)
      this.props.onChanged(documents)

    this.close()
  }
}

export default SupportingDocumentsModal