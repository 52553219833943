import React from 'react'

export default function AddResidentButton({ role, isShown, onClick }) {
  return (
    <>
      <a
        className="dome-link dome-link-aqua pull-right"
        style={{
          cursor: "pointer",
          display: isShown ? "inline" : "none",
        }}
        onClick={onClick}
      >
        <i className="fa-light fa-plus"></i>
        <span style={{ marginLeft: "4px" }}>{`add ${role}`}</span>
      </a>
    </>
  )
}
