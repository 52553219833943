import React from 'react'
import PropTypes from 'prop-types'
import Enable from './Enable'
import QuestionnaireResponseTime from './QuestionnaireResponseTime'
import QuestionnaireProcessingTime from './QuestionnaireProcessingTime'
import EnableUploadRequired from './EnableUploadRequired'
import EnableAutoArchive from './EnableAutoArchive'
import EnableGenericPreview from './EnableGenericPreview'
import EnableAutoPricing from './EnableAutoPricing'
import EnableDisclaimer from './EnableDisclaimer'
import Emails from './Emails'
import Pricing from './Pricing'
import EnableRequiredLoginProcessor from './EnableRequiredLoginProcessor'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enabled: props.organization.enable_questionnaires,
      responseTime: props.organization.questionnaire_response_time,
      processingTime: props.processingTime,
      uploadRequired: props.organization.enable_questionnaire_upload_required,
      isAutoArchiveEnabled: props.organization.enable_auto_archive,
      isAutoPricingEnabled: props.organization.enable_auto_pricing,
      isGenericPreviewEnabled: props.organization.enable_generic_preview,
      isDisclaimerEnabled: props.organization.enable_disclaimer,
      genericPreviewFile: props.genericPreviewFile,
      isQuestionnaireProcessorRequiredLogin: props.organization.is_questionnaire_processor_required_login
    }
  }

  renderEnableSelect() {
    return <Enable organization_id={this.props.organization.id} enabled={this.state.enabled} />
  }

  renderAdministrationArea() {
    let containerClass = 'questionnaire-administration'

    if (!this.state.enabled)
      containerClass += ' hidden'

    return <div className={containerClass}>
      {this.renderResponseTime()}
      {this.renderProcessingTime()}
      <br />
      {this.renderEnableUploadRequired()}
      <br />
      {this.renderEnableAutoArchive()}
      <br />
      {this.renderEnableAutoPricing()}
      <br />
      {this.renderEnableGenericPreview()}
      <br />
      {this.renderEnableDisclaimer()}
      <br />
      {this.renderEnableRequiredLoginProcessor()}
      {this.renderAdminEmails()}
      {this.renderPricing()}
    </div>
  }

  renderResponseTime() {
    return <QuestionnaireResponseTime organization={this.props.organization}
      responseTime={this.state.responseTime}
      updateResponseTime={responseTime => this.setState({ responseTime: responseTime })} />
  }

  renderProcessingTime() {
    return <QuestionnaireProcessingTime organization={this.props.organization}
      processingTime={this.state.processingTime}
      updateProcessingTime={processingTime => this.setState({ processingTime: processingTime })} />
  }

  renderEnableUploadRequired() {
    return <EnableUploadRequired organization={this.props.organization}
      uploadRequired={this.state.uploadRequired}
      updateUploadRequired={isRequired => this.setState({ uploadRequired: isRequired })} />
  }

  renderEnableAutoArchive() {
    return <EnableAutoArchive organization={this.props.organization}
      isAutoArchiveEnabled={this.state.isAutoArchiveEnabled}
      updateIsAutoArchiveEnabled={isEnabled => this.setState({ isAutoArchiveEnabled: isEnabled })} />
  }

  renderEnableGenericPreview() {
    return <div className={`questionnaire-administration-generic-preview ${this.state.uploadRequired ? 'hidden' : ''}`}>
      <EnableGenericPreview organization={this.props.organization}
        isGenericPreviewEnabled={this.state.isGenericPreviewEnabled}
        updateIsGenericPreviewEnabled={isEnabled => this.setState({ isGenericPreviewEnabled: isEnabled })}
        file={this.state.genericPreviewFile}
        updateFile={file => this.setState({ genericPreviewFile: file })} />
    </div>
  }

  renderEnableDisclaimer() {
    return <EnableDisclaimer organization_id={this.props.organization.id} disclaimer_text={this.props.disclaimer_text}
                             isDisclaimerEnabled={this.state.isDisclaimerEnabled}
                             updateIsDisclaimerEnabled={isEnabled => this.setState({ isDisclaimerEnabled: isEnabled }) } />
  }

  renderEnableAutoPricing() {
    return <EnableAutoPricing organization={this.props.organization}
                              isAutoPricingEnabled={this.state.isAutoPricingEnabled}
                              updateIsAutoPricingEnabled={isEnabled => this.setState({ isAutoPricingEnabled: isEnabled })} />
  }

  renderEnableRequiredLoginProcessor() {
    return <EnableRequiredLoginProcessor organization={this.props.organization}
                                         isQuestionnaireProcessorRequiredLogin={this.state.isQuestionnaireProcessorRequiredLogin}
                                         setIsQuestionnaireProcessorRequiredLogin={isEnabled => this.setState({ isQuestionnaireProcessorRequiredLogin: isEnabled })} />
  }

  renderAdminEmails() {
    return <div>
      <h4>Admin Emails</h4>
      <Emails organization={this.props.organization}
        emails={this.props.adminEmails} />
    </div>
  }

  renderPricing() {
    return <div>
      <h4>Prices</h4>
      <Pricing organization={this.props.organization}
        prices={this.props.prices} />
    </div>
  }

  render() {
    return <div>
      <div className="page-header" style={{margin: "-5px 0 20px"}}>
        <h4>Edit Organization: {this.props.organization.name}</h4>
      </div>
      <h2>Questionnaire Administration</h2>
      {this.renderEnableSelect()}
      {this.renderAdministrationArea()}
    </div>
  }
}

Index.propTypes = {
  organization: PropTypes.object.isRequired,
  processingTime: PropTypes.string
}

export default Index
