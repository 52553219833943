import React  from 'react'

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  componentDidMount() {
    const modal = $(this.ref.current)

    $(modal).on("hidden.bs.modal", this.props.onClose)
    $(modal).on('shown.bs.modal', () => {
      modal.before($('.modal-backdrop'))
    })
    $(modal).modal("show")
  }

  close() {
    $(this.ref.current).modal("hide")
  }

  render() {
    return <div ref={this.ref} className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {this.props.children}
        </div>
      </div>
    </div>
  }
}


export default React.memo(Modal)