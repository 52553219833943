import React from 'react'
import PropTypes from 'prop-types'
import Search from 'components/utility/search'

export default class DescriptionFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderInput() {
    return <input type="text" name="filters[description]" defaultValue={this.props.description} required />
  }

  renderSearch() {
    return <Search
      url="/financials/search_transaction_descriptions"
      resultsDisplayKey="name"
      resultsKey="results" />
  }

  render() {
    return <div className="description-filter">
      <div className="inputs-container">
        <i className="fa fa-long-arrow-right"></i>
        {this.renderInput()}
      </div>
    </div>
  }
}

DescriptionFilter.propTypes = {
  description: PropTypes.string
}

DescriptionFilter.defaultProps = {
  description: ""
}

