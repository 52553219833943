import React from 'react'
import PropTypes from 'prop-types'
import NewForm from './new_form'
import LeaseForms from './lease_forms'

export default class LeaseFormTypes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      updating: false,
      refreshingForms: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.forms != this.props.forms && this.state.refreshingForms && prevState.refreshingForms == false)
      return this.setState({ forms: this.props.forms, refreshingForms: false })

    if (prevProps.forms != this.props.forms && this.didSortOrderOrIncludedChange(prevProps.forms))
      this.postUpdateForms()
  }

  didSortOrderOrIncludedChange(prevForms) {
    let changesFound = false

    for (let i = 0; i < prevForms.length; i++) {
      const prevForm = prevForms[i],
        currentForm = this.props.forms.find(form => form.id == prevForm.id)

      if (!currentForm)
        continue

      if (prevForm.sort_order != currentForm.sort_order || prevForm.included != currentForm.included) {
        changesFound = true
        break;
      }
    }

    return changesFound
  }

  includedForms() {
    return this.props.forms.filter(form => form.included)
  }

  excludedForms() {
    return this.props.forms.filter(form => !form.included)
  }

  postUpdateForms() {
    $.ajax({
      url: `/buildings/${this.props.buildingId}/update_organization_lease_forms_included_and_sort_order`,
      method: 'post',
      datatype: 'json',
      contentType: "application/json",
      data: JSON.stringify({ lease_forms: this.props.forms }),
      context: this,
      success: function (res) {
        if (res.refresh_all_lease_forms) {
          this.setState({ refreshingForms: true })
          this.props.refreshForms()
        }
      }
    })

  }

  render() {
    return (
      <div>
        <div className="included-lease-types">
          <div className="form-type-title">
            Included {this.props.title}s
          </div>
          <LeaseForms
            title={this.props.title}
            included={true}
            forms={this.includedForms()}
            all_forms={this.props.forms}
            selector={this.props.selector}
            buildingId={this.props.buildingId}
            deleteForm={formId => this.deleteForm(formId)}
            updateForms={newForms => this.props.updateForms(newForms)}
            sortFormsInOrder={orderedFormIds => this.sortFormsInOrder(orderedFormIds)}
            toggleIncluded={formId => this.toggleIncluded(formId)}
            refreshForms={() => this.props.refreshForms()}
          />
          <NewForm
            title={this.props.title}
            selector={this.props.selector}
            buildingId={this.props.buildingId}
            createForm={(form) => this.createForm(form)}
            refreshForms={() => this.props.refreshForms()}
          />
        </div>

        <div className="included-lease-types">
          <div className="form-type-title">
            Excluded Organization {this.props.title} Defaults
          </div>
          <LeaseForms
            title={this.props.title}
            included={false}
            forms={this.excludedForms()}
            all_forms={this.props.forms}
            buildingId={this.props.buildingId}
            defaults={this.props.defaults}
            deleteForm={formId => this.deleteForm(formId)}
            updateForms={newForms => this.props.updateForms(newForms)}
            sortFormsInOrder={orderedFormIds => this.sortFormsInOrder(orderedFormIds)}
            toggleIncluded={formId => this.toggleIncluded(formId)}
            refreshForms={() => this.props.refreshForms()}
          />
        </div>
      </div>
    )
  }

  createForm(form) {
    form.included = true
    let newFormState = this.props.forms

    newFormState.push(form)

    this.props.updateForms(newFormState)
  }

  deleteForm(formId) {
    let newFormsState = this.props.forms

    newFormsState = newFormsState.filter(form => form.id != formId)

    this.props.updateForms(newFormsState)
  }

  sortFormsInOrder(orderedFormIds) {
    let newFormsState = jQuery.extend(true, [], this.props.forms);

    for (let i = 0; i < newFormsState.length; i++) {
      const form = newFormsState[i]
      if (!orderedFormIds.includes(form.id)) continue

      form.sort_order = orderedFormIds.indexOf(form.id)
    }

    this.props.updateForms(newFormsState)
  }

  // does not save state. returns forms array with normalized sort_order properties
  normalizeSortOrder(forms) {
    const compareSortOrder = (form1, form2) => form1.sort_order - form2.sort_order

    let includedForms = forms.filter(form => form.included).sort((form1, form2) => compareSortOrder(form1, form2))
    let excludedForms = forms.filter(form => !form.included).sort((form1, form2) => compareSortOrder(form1, form2))

    for (let i = 0; i < includedForms.length; i++) includedForms[i].sort_order = i
    for (let i = 0; i < excludedForms.length; i++) excludedForms[i].sort_order = i

    return includedForms.concat(excludedForms)
  }

  toggleIncluded(formId) {
    let newFormsState = jQuery.extend(true, [], this.props.forms);

    for (let i = 0; i < newFormsState.length; i++) {
      if (newFormsState[i].id == formId) {
        let form = newFormsState[i]
        form.included = !newFormsState[i].included
        form.sort_order = 9999
        newFormsState[i] = form
        break
      }
    }
    newFormsState = this.normalizeSortOrder(newFormsState)

    this.props.updateForms(newFormsState)
  }
}

LeaseFormTypes.propTypes = {
  refreshForms: PropTypes.func.isRequired,
  updateForms: PropTypes.func.isRequired
}
