import React from 'react'

import DateRow from './date_row'

class DateRows extends React.Component {
  render () {
    return <div>
      {this.props.dateRows.map(row => this.renderDateRow(row))}
    </div>
  }

  renderDateRow(row) {
    return <DateRow
      key={row.dateKey}
      month={row.month}
      year={row.year}
      day={row.day}
      fetchSpecificDate={this.props.fetchSpecificDate}
      dateKey={row.dateKey}
      buildings={row.buildings}
      fetchAllNotifications={this.props.fetchAllNotifications}
      fetchNotifications={this.props.fetchNotifications}
    />
  }
}

export default DateRows
