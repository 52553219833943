import React from 'react'

const TextField = props => {
  const { placeholder, name, handleUpdate, value, required } = props

  return (
    <input type='text'
           className='form-control'
           placeholder={placeholder || ''}
           name={name || ''}
           onChange={event => handleUpdate(event.target.value)}
           defaultValue={value || ''}
           required={required || false} />
  )
}

export default TextField
