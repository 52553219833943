import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { updateTemplate, useDataTemplate } from 'components/form_builder/features/data/dataSlice'
import useMultiControlledInput from './useMultiControlledInput'

const useMultiplePaths = (id, component) => {
  const template = useDataTemplate(...id)
  const dispatch = useDispatch()
  const [values, setValues, handleChange] = useMultiControlledInput()

  useEffect(() => {
    if (template?.fields?.length > 0) {
      setValues(template?.fields?.reduce((pre, cur) => ({ ...pre, [cur]: cur }), {}))
    } else {
      setValues({ [uuidv4()]: '' })
    }
  }, [id])

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') e.target.blur()
  }, [])

  const onBlur = useCallback((e) => storeValue(Object.values(values)), [values, storeValue])

  const addNewPath = useCallback(() => setValues((prev) => ({ ...prev, [uuidv4()]: '' })), [])

  const removePath = useCallback(
    (name) => {
      const newValue = { ...values }
      delete newValue[name]
      setValues(newValue)
      storeValue(Object.values(newValue))
    },
    [values, storeValue]
  )

  const storeValue = useCallback(
    (fields) => {
      dispatch(updateTemplate({ id: component.id, type: component.type, template: { ...template, fields } }))
    },
    [template]
  )

  return { values, addNewPath, removePath, onChange: handleChange, onKeyDown, onBlur }
}
export default useMultiplePaths
