import React, { useCallback } from 'react'
import { padStart } from 'lodash'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

export default function({id, className, onChange, onBlur, value = ""}) {
  const [hour, minutes, seconds] = value.split(":").map(parseInt)

  const date = new Date()
  if (hour) date.setHours(hour)
  if (minutes) date.setMinutes(minutes)
  if (seconds) date.setSeconds(seconds)

  const handleChange = useCallback(date => {
    const time = [ date.getHours(), date.getMinutes, date.getSeconds() ]
    onChange({[id]: time.map(t => padStart(t, 2, '0').join(":"))})
    setTimeout(() => onBlur(), 200)
  }, [id, onChange])

  return <div className={className}>
    <DatePicker selected={date} onChange={handleChange} timeIntervals={15} dateFormat="h:mm aa" showTimeSelect showTimeSelectOnly />
  </div>
}