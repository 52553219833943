import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Pagination from '../domeui/table/Pagination';
import $ from 'jquery';

const SurveySentModal = ({ sendSurveyEmailPath }) => {
  const [data, setData] = useState({ closing_status: '', recipients: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [surveySent, setSurveySent] = useState(false);

  const sendSurveyTextRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await fetch(sendSurveyEmailPath, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const jsonData = await response.json();

      if (jsonData?.success) {
        setData(jsonData.success);
        setSurveySent(true);
      } else if (jsonData?.error) {
        setError(jsonData.error);
        setIsOpen(false);
      } else {
        setError("Data is not in the expected format");
        setIsOpen(false);
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleOpenModalEvent = () => {
      setIsOpen(true);
      fetchData();
    };
    window.addEventListener('openSurveySentModal', handleOpenModalEvent);

    return () => {
      window.removeEventListener('openSurveySentModal', handleOpenModalEvent);
    };
  }, []);

  useEffect(() => {
    if (surveySent) {
      $('.send-survey-button').html('Survey Sent <i class="fas fa-check"></i>');
      $('.send-survey-button').addClass('survey-sent');
    } else {
      $('.send-survey-button').html('Survey Sent');
      $('.send-survey-button').removeClass('survey-sent');
    }
  }, [surveySent]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(data.recipients.length / itemsPerPage);

  return (
    <Modal show={isOpen} onHide={handleClose} centered size="lg" className="survey-modal">
      <Modal.Header>
        <Modal.Title>
          <span className="icon-circle">
            <i className="fas fa-check"></i>
          </span>
          survey sent
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You have {data.closing_status} this package. Survey sent to all deal parties:</p>

        <table className="table custom-table">
          <thead>
            <tr>
              <th>deal party</th>
              <th>role</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            {data.recipients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => (
              <tr key={index}>
                <td>{item.full_name}</td>
                <td style={{ textAlign: 'left' }}>{item.role}</td>
                <td style={{ textAlign: 'left' }}>{item.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-wrapper">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            goForward={() => handlePageChange(currentPage + 1)}
            goPrevious={() => handlePageChange(currentPage - 1)}
            goToPage={handlePageChange}
            isFirstPage={currentPage === 1}
            isLastPage={currentPage === totalPages}
            canGoBack={currentPage > 1}
            canGoForward={currentPage < totalPages}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-custom">
        <button type="button" className="btn btn-default right-aligned-button" onClick={handleClose}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SurveySentModal;
