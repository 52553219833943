import React from 'react'

const FlashMessage = (props) => {
  const doesFlashExist = () => props.flashMessage && props.flashMessage.length > 0

  return (
    doesFlashExist() ? (
    <div id='notification' className={props.display}>
      <div className={`flash-message-${props.flashMessage[0][0]} ${props.classes}`}>
        <div className='fm-wrap'><span>{` ${props.flashMessage[0][1]}`}</span></div>
      </div>
    </div>
    ) : null
  ) 
}

export default FlashMessage