import React from 'react'
import $ from 'jquery'

class Notification extends React.Component {

	getFaIcon() {
		return('notification-icon fa ' + this.props.notification.fa_icon)
	}

	handleClick(e) {
		// if the notification has not been read, send a request to do so now
		if (!this.props.notification.notification.has_been_read) {
			$.ajax({
				url: 'notifications/read',
				type: 'POST',
				dataType: "json",
				data: {checked_ids: [this.props.notification.notification.id]}
			},this.setState({
				flagAsRead: true
			}))
		}

		window.location.href = this.props.notification.activity_update_url
	}

	render() {

		return (
      <div className='notification-row'>
        <div className='text-centered notification-message-body'>
					<div className='au-header unread dome-d-flex'>
							<div><i onClick={e => this.handleClick(e)} className='fa-light fa-arrow-up-right dome-color-aqua'></i></div>
							<div className='dome-d-flex dome-flex-column dome-align-start dome-p1'>
								<span>{this.props.notification.message}</span>
								<div className='notification-timestamp dome-color-med-grey'>{this.props.notification.created_at}</div>
							</div>
					</div>
        </div>
      </div>
    )
	}
}

export default Notification
