import React, { useState } from 'react'
import { isEmpty, isFinite } from 'lodash-es'
import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import AboutYouSidebarDetail from './AboutYouSidebarDetail'
import AboutYouSidebarHeadline from './AboutYouSidebarHeadline'
import clsx from 'clsx'
import { isAfter } from 'date-fns'
import { useCopyOverridesFor } from 'components/insurance/get_quote/slice/overridesSlice'


const getQuoteInfo = (quote, startingQuote, formData) => {
  const existingQuote = (isEmpty(quote) || !quote.premium) && startingQuote ? startingQuote : quote

  if (existingQuote.lastModifiedDate && isAfter(existingQuote.lastModifiedDate, formData.modifiedAt)) {
    return quote
  }

  return {
    ...(existingQuote || {}),
    liabilityLimit: formData?.personal_liability || startingQuote?.liabilityLimit,
    homeAndContentsDeductible: formData?.deductible_amount || startingQuote?.homeAndContentsDeductible,
    contentsAmount: formData?.content_coverage || startingQuote?.contentsAmount,
    additionsAndAlterationsAmount: formData?.additions_alterations_amount || startingQuote?.additionsAndAlterationsAmount
  }
}

export default function AboutYouSidebar({ quote, isPolicy, insuranceType, sidebarDetails, startingQuote, formData = {} }) {
  const {
    premium,
    writingCompany,
    liabilityLimit,
    homeAndContentsDeductible,
    contentsAmount,
    additionsAndAlterationsAmount,
  } = getQuoteInfo(quote, startingQuote, formData)
  const { overrides, error, loading } = useCopyOverridesFor(insuranceType, writingCompany)
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const [mobileExpanded, setMobileExpanded] = useState(true)
  const onToggleExpand = () => setMobileExpanded((mobileExpanded) => !mobileExpanded)

  return (
    <>
      <div className="sidebar-desktop">
        <div className="about-you-sidebar-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="sidebar-headline">
            <AboutYouSidebarHeadline
              monthlyAmount={monthlyAmount}
              writingCompany={writingCompany}
              type={insuranceType}
            />
          </div>
          <div className="sage-line my-18"></div>
          <AboutYouSidebarDetail
            isPolicy={isPolicy}
            liabilityLimit={liabilityLimit}
            homeAndContentsDeductible={homeAndContentsDeductible}
            contentsAmount={contentsAmount}
            additionsAndAlterationsAmount={additionsAndAlterationsAmount}
            sidebarDetails={sidebarDetails}
            overrides={overrides}
            insuranceType={insuranceType}
          />
        </div>

        <div className="viaffinity-disclosure-desktop dome-bg-white dome-dark-shadow dome-rounded-border">
          <div className="dome-p3" style={{ marginBottom: '24px' }}>
            * Estimate based on property’s requirements, insurer’s recommendations, or expected values and is not final.
          </div>
          <ViaffinityDisclosureContent legalDisclaimer={overrides?.legal_disclaimer.html} />
        </div>
      </div>

      <div className="sidebar-mobile">
        <div className="about-you-sidebar-mobile">
          <div className="sidebar-headline">
            <AboutYouSidebarHeadline
              monthlyAmount={monthlyAmount}
              writingCompany={writingCompany}
              type={insuranceType}
              mobileExpanded={mobileExpanded}
              onToggleExpand={onToggleExpand}
            />
          </div>
          <div className={clsx('sidebar-content', mobileExpanded ? '' : 'collapsed')}>
            <div className="dome-bg-white dome-dark-shadow dome-rounded-border">
              <AboutYouSidebarDetail
                isPolicy={isPolicy}
                liabilityLimit={liabilityLimit}
                homeAndContentsDeductible={homeAndContentsDeductible}
                contentsAmount={contentsAmount}
                additionsAndAlterationsAmount={additionsAndAlterationsAmount}
                sidebarDetails={sidebarDetails}
                overrides={overrides}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AboutYouSidebar.defaultProps = {
  formData: {},
}
