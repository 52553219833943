import React from 'react'
import { connect } from 'react-redux'
import Store from '../Store'
import { modalErrorKeys } from './Error';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
class UserBasicDetailsModalContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showOtherTitleField: props.modalUserDetails && props.modalUserDetails.title && !Object.keys(props.buildingResponsiblePartyTitles).includes(props.modalUserDetails.title)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // clears custom title input field when switching to it
    if (!prevState.showOtherTitleField && this.state.showOtherTitleField) {
      this.props.updateModaluserDetails('title', null)
    }
  }

  handleInputChange(stateName, value, args={}) {
    if (stateName == 'title') {
      if (!args['fromCustomInput']) {
        if (value == 'other' ) {
          return this.setState({ showOtherTitleField: true })
        } else if (this.state.showOtherTitleField) {
          this.setState({ showOtherTitleField: false })
        }
      }
    }

    this.props.updateModaluserDetails(stateName, value)
  }

  handleClickSubmit() {
    const { buildingResponsiblePartyType, modalOpenForContext } = this.props

    switch(buildingResponsiblePartyType) {
      case 'PropertyContacts':
        return this.props.openModalForContext('Roles & Permissions')
      case 'BoardMembers':
        if (modalOpenForContext == 'New Building Responsible Party')
          return this.addBuildingResponsibleParty()
        if (modalOpenForContext == 'Edit Building Responsible Party')
          return this.updateBuildingResponsibleParty()
    }
  }

  addBuildingResponsibleParty() {
    const { modalUserDetails, buildingResponsiblePartyType } = this.props
    const data = {
      building_responsible_party: {
        board_member: buildingResponsiblePartyType == 'BoardMembers',
        role_label: modalUserDetails.title,
        is_visible: modalUserDetails.is_visible,
        is_phone_hidden: modalUserDetails.is_phone_hidden,
        first_name: modalUserDetails.first_name,
        last_name: modalUserDetails.last_name,
        email: modalUserDetails.email,
        phone_number: modalUserDetails.phone_number,
        phone_ext: modalUserDetails.phone_ext
      }
    }

    $.ajax({
      url: `/buildings/${this.props.buildingId}/add_building_responsible_party`,
      method: 'POST',
      dataType: 'json',
      data: data,
      context: this,
      success: function(res) {
        this.props.addBuildingResponsibleParty(res.propertyContact[0])
        this.props.closeModal()
      }, error: (err) => {
        if (err.responseJSON.error && err.responseJSON.error.responsible_party_id == 'has already been taken') {
          this.props.setErrorModalDetails({
            name: modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member,
            email: modalUserDetails.email
          })
          this.props.openModalForContext('Error')
        }
      }
    })
  }

  updateBuildingResponsibleParty() {
    const { modalUserDetails } = this.props
    const data = {
      building_responsible_party: {
        role_label: modalUserDetails.title,
        is_visible: modalUserDetails.is_visible,
        is_phone_hidden: modalUserDetails.is_phone_hidden,
        first_name: modalUserDetails.first_name,
        last_name: modalUserDetails.last_name,
        phone_number: modalUserDetails.phone_number,
        phone_ext: modalUserDetails.phone_ext
      }
    }

    $.ajax({
      url: `/buildings/${this.props.buildingId}/update_building_responsible_party/${modalUserDetails.id}`,
      method: 'PATCH',
      dataType: 'json',
      data: data,
      context: this,
      success: res => {
        this.props.updateBuildingResponsibleParty(res.building_responsible_party[0])
        this.props.closeModal()
      }, error: (err) => {
        if (err.responseJSON.error && err.responseJSON.error.responsible_party_id == 'has already been taken') {
          this.props.setErrorModalDetails({
            name: modalErrorKeys.email_already_belongs_to_a_property_contact_or_a_board_member,
            email: modalUserDetails.email
          })
          this.props.openModalForContext('Error')
        }
      }
    })
  }

  areRequiredFieldsFilled() {
    const { modalUserDetails: { first_name, last_name, email, title } } = this.props

    return first_name && last_name && email && validateEmail(email) && title
  }

  // refer to the list of supported states in
  // app/assets/javascripts/components/Buildings/Edit/PropertyContacts/Modal.es6.jsx
  renderField(fieldName, fieldId, labelText, stateName, placeholder='', inputType='text', required=true, defaultValue='') {
    const requiredAsterisk = required ? <span style={{color: '#F68A41', paddingLeft: 5}}>*</span> : null

    return <div>
      <div>
        <label htmlFor={fieldId} className="form-label">{labelText}{requiredAsterisk}</label>
      </div>
      <div>
        <input type={inputType}
          name={fieldName}
          className="form-control"
          id={fieldId}
          placeholder={placeholder}
          required={required}
          defaultValue={this.props.modalUserDetails[stateName]}
          onChange={event => this.handleInputChange(stateName, event.target.value)} />
      </div>
    </div>
  }

  renderCustomTitleField() {
    if (!this.state.showOtherTitleField)
      return

    return <input type="text"
                  name='title'
                  className='form-control'
                  placeholder='Custom title name'
                  value={this.props.modalUserDetails.title || ''}
                  onChange={event => this.handleInputChange('title', event.target.value, {fromCustomInput: true})} />
  }

  titleDropdownOptions() {
    const titleDropdownOptions = []

    titleDropdownOptions.push(<option key='disabled' value='disabled' disabled>Select a Title</option>)

    for (const title in this.props.buildingResponsiblePartyTitles)
      titleDropdownOptions.push(
        <option key={title}
          value={title}
          className="form-control">
          {this.props.buildingResponsiblePartyTitles[title]}
        </option>)

    titleDropdownOptions.push(<option key='other' value='other'>Other</option>)

    return titleDropdownOptions
  }

  renderTitleDropdownField() {
    const { buildingResponsiblePartyTitles, modalUserDetails: { title } } = this.props
    const requiredAsterisk = <span style={{color: '#F68A41', paddingLeft: 5}}>*</span>

    const defaultValue = () => {
      if (!title)
        return 'disabled'

      return Object.keys(buildingResponsiblePartyTitles).includes(title) ? title : 'other'
    }

    return <div>
      <div>
        <label htmlFor='npc-title' className="form-label">Title{requiredAsterisk}</label>
      </div>
      <div>
        <select name={this.state.showOtherTitleField ? null : 'title'}
                id='npc-title'
                className='form-control'
                defaultValue={defaultValue()}
                onChange={event => this.handleInputChange('title', event.target.value)}>
          {this.titleDropdownOptions()}
        </select>
        {this.renderCustomTitleField()}
      </div>
    </div>
  }

  renderVisilityCheckbox() {
    if (this.props.buildingResponsiblePartyType == 'BoardMembers')
      return null

    return <div style={{display: 'flex', alignItems: 'center'}}>
      <input type='checkbox'
        id='npc-hidden'
        name='hidden'
        style={{ display: 'inline-block', margin: '0 10px 0 0' }}
        checked={this.props.modalUserDetails.is_visible}
        onChange={event => this.handleInputChange('is_visible', event.target.checked)} />
      <span>Visible on Building Page</span>
    </div>
  }

  renderPhoneVisilityCheckbox() {
    if (this.props.buildingResponsiblePartyType == 'BoardMembers' || this.props.newUser)
      return null

    return <div style={{display: 'flex', alignItems: 'center'}}>
      <input type='checkbox'
        id='pcphone-hidden'
        name='pcphone-hidden'
        style={{ display: 'inline-block', margin: '0 10px 0 0' }}
        checked={this.props.modalUserDetails.is_phone_hidden}
        onChange={event => this.handleInputChange('is_phone_hidden', event.target.checked)} />
      <span>Hide Phone Number on Building Page</span>
    </div>
  }

  renderError() {
    const { modalUserDetails } = this.props,
          styling = {
            color: '#F74141',
            backgroundColor: '#FFF8F8',
            border: '1px solid #F74141',
            borderRadius: 5,
            width: '70%',
            textAlign: 'center',
            margin: '-10px auto 20px',
            padding: '5px 10px'
          }

    if (modalUserDetails['email'] && !validateEmail(modalUserDetails['email']))
      return <div style={styling}>
        Please enter a complete email address.
      </div>
  }

  renderHeader() {
    const { buildingResponsiblePartyType, modalOpenForContext } = this.props

    const newOrEdit = modalOpenForContext == 'New Building Responsible Party' ? 'New' : 'Edit'
    let title
    switch(buildingResponsiblePartyType) {
      case 'PropertyContacts':
        title = `${newOrEdit} Property Contact`
        break
      case 'BoardMembers':
        title = `${newOrEdit} Board Member`
        break
    }

    return <div className="modal-header">
      <h3 className="modal-title">{title}</h3>
    </div>
  }

  renderBody() {
    const containerStyling = { display: 'flex', justifyContent: 'space-around' }

    return <div className="modal-body">
      <div style={containerStyling}>
        <div style={{ flexGrow: 1, marginRight: 20 }}>
          {this.renderField('first_name', 'npc-first-name', 'First Name', 'first_name', 'First')}
        </div>
        <div style={{ flexGrow: 1 }}>
          {this.renderField('last_name', 'npc-last-name', 'Last Name', 'last_name', 'Last')}
        </div>
      </div>
      {this.renderField('email', 'npc-email', 'Email', 'email', 'first.last@gmail.com', 'email', 'email')}
      <div style={containerStyling}>
        <div style={{ flexGrow: 3, marginRight: 20}}>
          {this.renderField('phone', 'npc-phone', 'Phone Number', 'phone_number', '123-456-7890', 'tel', false)}
        </div>
        <div style={{ flexGrow: 1}}>
          {this.renderField('phone-ext', 'npc-phone-ext', 'EXT', 'phone_ext', '123', 'text', false)}
        </div>
      </div>
      <div>
        {this.renderPhoneVisilityCheckbox()}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {this.renderTitleDropdownField()}
        </div>
      </div>
      <div>
        {this.renderVisilityCheckbox()}
      </div>
    </div>
  }

  renderFooter() {
    const buttonStyling = {
      backgroundColor: '#56A256',
      color: 'white'
    }
    const { modalOpenForContext } = this.props
    const addOrUpdate = modalOpenForContext == 'New Building Responsible Party' ? 'Add' : 'Update'

    return <div className="modal-footer">
      {this.renderError()}
      <button key="modal-dismiss" className="btn btn-secondary cancel-btn" data-dismiss="modal" style={{ float: 'left' }}>Cancel</button>
      <button key="modal-submit"
              type='button'
              className="btn"
              style={buttonStyling}
              disabled={!this.areRequiredFieldsFilled()}
              onClick={() => this.handleClickSubmit()}>{addOrUpdate}</button>
    </div>
  }

  // todo: dynamic text depending on if for new user or editting existing user
  render() {
    return <div className="modal-content new-brp-modal">
      {this.renderHeader()}
      {this.renderBody()}
      {this.renderFooter()}
    </div>

  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buildingId: state.main.buildingId,
    buildingResponsiblePartyType: state.main.buildingResponsiblePartyType,
    modalOpenForContext: state.modal.modalOpenForContext,
    buildingResponsiblePartyTitles: state.main.buildingResponsiblePartyTitles,
    modalUserDetails: state.modal.modalUserDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalForContext: context => dispatch(Store.openModalForContext(context)),
    closeModal: () => dispatch(Store.closeModal()),
    updateModaluserDetails: (propertyName, value) => dispatch(Store.updateModaluserDetails(propertyName, value)),
    addBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.addBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty)),
    setErrorModalDetails: details => dispatch(Store.setErrorModalDetails(details))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBasicDetailsModalContent)
