import React from 'react'
import { useSelector } from 'react-redux'


const EXAMPLE_META = {
  first_name: {
    type: "string",
    label: "First Name"
  }
}



export default function({id}) {
  const dealParty = useSelector(state => state.lookups[{id}])
  const metadata = useSelector(state => state.meta[dealParty.role])

  const fields = Object.keys(metadata).map(field => {
    return { field, ... metadata[field], key: field }
  })

  return <div className="edit-deal-party">
    { fields.map(field => <Field {...field} /> )}
  </div>
}