import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'

// Mock API call to fetch overrides from the server
const fetchOverridesFromServer = async (type, underwriter) => {
  try {
    const response = await axios.get('/insurance/buildings/overrides', {
      params: {
        underwriter,
        type
      }
    })

    return response.data
  } catch (error) {
    throw error
  }

}

const indexFor = ({type, underwriter}) => `${type}::${underwriter}`

// Thunk to load overrides if they are not in the cache
export const fetchCopyOverrides = createAsyncThunk('copyOverrides/fetch', async ({ type, underwriter }, { getState }) => {
  try {
    const { overrides } = getState().copyOverrides

    if (overrides[indexFor(type, underwriter)]) {
      return { overrides: overrides[indexFor({type, underwriter})] } // Return cached data immediately
    }

    const data = await fetchOverridesFromServer(type, underwriter)
    return data // Fetch from the server if not cached
  } catch (error) {
    console.error('There was an error fetching the overrides:', error)
  }
})

const copyOverridesSlice = createSlice({
  name: 'copyOverrides',
  initialState: {
    overrides: {}, // Stores overrides per type
    loading: {}, // Tracks loading state per type
    error: {}, // Tracks errors per type
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCopyOverrides.pending, (state, action) => {
        state.loading[indexFor(action.meta.arg)] = true
        state.error[indexFor(action.meta.arg)] = null
      })
      .addCase(fetchCopyOverrides.fulfilled, (state, action) => {
        state.overrides[indexFor(action.meta.arg)] = action.payload.overrides
        state.loading[indexFor(action.meta.arg)] = false
      })
      .addCase(fetchCopyOverrides.rejected, (state, action) => {
        state.loading[indexFor(action.meta.arg)] = false
        state.error[indexFor(action.meta.arg)] = action.error.message
      })
  },
})

export const useCopyOverridesFor = (type, underwriter) => {
  const dispatch = useDispatch()

  // Select the necessary state from Redux
  const overrides = useSelector((state) => state.copyOverrides.overrides[indexFor({type, underwriter})])
  const loading = useSelector((state) => state.copyOverrides.loading[indexFor({type, underwriter})])
  const error = useSelector((state) => state.copyOverrides.error[indexFor({type, underwriter})])

  useEffect(() => {
    if (!type || !underwriter) return

    if (!overrides) {
      dispatch(fetchCopyOverrides({ type, underwriter }))
    }
  }, [type, underwriter, overrides, dispatch])

  return { overrides, loading, error }
}

export default copyOverridesSlice.reducer
