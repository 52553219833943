import React, {useState, useEffect} from 'react'

const UserGroupIcon = (props) => {
    const [toggleSuccess, setToggleSuccess] = useState(false)

  useEffect(() => {
    const value = props?.watchedField?.length > 0
    setToggleSuccess(value)
  }, [props.watchedField])
  return <i style={{color: toggleSuccess ? '#56a256' : '#b7b7b7'}} className='fa-light fa-user-group'></i>
}

export default UserGroupIcon