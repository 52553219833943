import React, { useRef} from 'react'
import { pick } from 'lodash'

import { compactObject } from 'lib/utilities'
import { useFindFontSize } from './utilities'

function useDefaultImageSizing(ref, props) {
  const { signature, locked} = props

  const container = useRef()
  const out = useFindFontSize(container, { 
    oneLine: true,
    ...pick(props, ['onResize', 'defaultSize', 'fontSize', 'minFont', 'ratio']),
    locked: signature.image || locked,
  }, [ signature.text, signature.fontFamily ] )

  return { container, ...out }
}

const InternalImage = React.forwardRef((props, ref) => {
  const { signature } = props
  const classes = [
    'signature-image', 
    'signature-image-component',
    signature.image ? 'as-image' : 'as-text'
  ].join(" ")

  const { container, fontSize, isWorking } = useDefaultImageSizing(ref, props)
  
  const style = compactObject({ 
    fontSize,
    backgroundImage: signature.image ? `url(${signature.image})` : null,
    fontFamily: signature.fontFamily
  })

  if (isWorking)
    style.opacity = 0

  return <div className={classes} style={style} ref={container}>
    { signature.image ? null : signature.text }
  </div>
})

export default InternalImage
//export default React.memo(InternalImage)