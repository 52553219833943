import React from 'react'
import PropTypes from 'prop-types'

function createObjFromURI() {
  let uri = decodeURI(location.search.substr(1));
  let chunks = uri.split('&'),
      params = Object();

  for (let i=0; i < chunks.length ; i++) {
    const chunk = chunks[i].split('=');
    chunk[1]=chunk[1].replace(/\+/g, '%20');

    if(chunk[0].search("\\[\\]") !== -1) {
      chunk[0]=chunk[0].replace(/\[\]$/,'');
      chunk[1]=unescape(chunk[1]);
      typeof params[chunk[0]] === 'undefined'
        ? params[chunk[0]] = [chunk[1]]
        : params[chunk[0]].push(chunk[1]);
    } else {
      params[chunk[0]] = unescape(chunk[1]);
    }
  }

  return params;
}

export default class ActiveFilters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.handleClickDelete = this.handleClickDelete.bind(this)
  }

  handleClickDelete(paramKey, paramValue, nestedParamKeys=[]) {
    let params = createObjFromURI()

    const paramType = nestedParamKeys.length > 0
      ? typeof(params[`${paramKey}[${nestedParamKeys[0]}]`])
      : typeof(params[paramKey])

    switch(paramType) {
      case "object":
        if(nestedParamKeys.length > 0) {
          // maybe not needed
        } else {
          const index = params[paramKey].indexOf(paramValue)
          params[paramKey].splice(index, 1);
        }
        break;
      case "string":
        if(nestedParamKeys.length > 0) {
          for(const key of nestedParamKeys) {
            const nestedKey = `${paramKey}[${key}]`
            delete params[nestedKey]
          }
        } else {
          delete params[paramKey]
        }
        break;
    }

    window.location = location.origin + "/api/v2/financials?" + $.param(params)
  }

  filterTextForFilterKey(key) {
    switch(key) {
      case "amount":
        return this.filterTextForAmounts()
      case "purchaser":
        return this.props.filters["purchaser"]["fieldValue"]
      case "date":
        return this.filterTextForDates()
    }
  }

  filterTextForAmounts() {
    const amount = this.props.filters["amount"]
    if (amount["equalsTo"]) return asMoney(amount["equalsTo"])
    if (amount["min"] && amount["max"]) return `${asMoney(amount["min"])}-${asMoney(amount["max"])}`
    if (amount["greaterThan"]) return `> ${asMoney(amount["greaterThan"])}`
    if (amount["lessThan"]) return `< ${asMoney(amount["lessThan"])}`
  }

  filterTextForDates() {
    const date = this.props.filters["date"]
    const dateType = date["dateType"],
          timePeriodValue = date["timePeriodValue"],
          timePeriod = date["timePeriod"]

    switch(date["range"]) {
      case "isEqualTo":
        return `${dateType} date is on ${date["isEqualTo"]}`
      case "isInTheLast":
        return `${dateType} date is in the last ${timePeriodValue} ${timePeriod}`
      case "isBetween":
        return `${dateType} date is between ${date["start"]}—${date["end"]}`
      case "isAfter":
        return `${dateType} date is after ${date["start"]}`
      case "isOnOrAfter":
        return `${dateType} date is on or after ${date["start"]}`
      case "isBefore":
        return `${dateType} date is before ${date["end"]}`
      case "isBeforeOrOn":
        return `${dateType} date is before or on ${date["end"]}`
      case "isEmpty":
        return `${dateType} date is empty`
    }
  }

  filterTextForAccountId(id) {
    id = parseInt(id)

    const accountIndex = this.props.accounts.findIndex(account => account["id"] == id)

    return accountIndex == null ? null : this.props.accounts[accountIndex]["account_description"]
  }

  renderDeleteIcon(paramKey, paramValue, nestedParamKeys=[]) {
    if (this.props.canDelete)
      return <i className="fa fa-times delete-icon" onClick={() => this.handleClickDelete(paramKey, paramValue, nestedParamKeys)} />
  }

  renderFilters() {
    const { filters } = this.props
    const filtersList = []

    for(const key in filters) {
      if(typeof(filters[key]) == "object") {
        switch(key) {
          case "amount":
          case "purchaser":
          case "date":
            filtersList.push(
              <div key={key} className="active-filter-item">
                <span className="active-filter-text">{this.filterTextForFilterKey(key)}</span>
                {this.renderDeleteIcon(`filters[${key}]`, filters[key], Object.keys(filters[key]))}
              </div>
            )
            break
          case "type":
            filtersList.push(
              <div key={key} className="active-filter-item">
                <span className="active-filter-text">{typeToHumanFriendlyString(filters[key])}</span>
                {this.renderDeleteIcon(`filters[${key}]`, filters[key])}
              </div>
            )
            break;
          case "account":
            filters[key].forEach((val, i) =>
              filtersList.push(
                <div key={`${key}-${i}`} className="active-filter-item">
                  <span className="active-filter-text">{this.filterTextForAccountId(val)}</span>
                  {this.renderDeleteIcon(`filters[${key}]`, val)}
                </div>
              )
            )
            break;
          default: {
            filters[key].forEach((val, i) =>
              filtersList.push(
                <div key={`${key}-${i}`} className="active-filter-item">
                  <span className="active-filter-text">{val}</span>
                  {this.renderDeleteIcon(`filters[${key}]`, val)}
                </div>
              )
            )
          }
        }
      } else {
        filtersList.push(
          <div key={key} className="active-filter-item">
            <span className="active-filter-text">
              {key == "itemType" ? typeToHumanFriendlyString(filters[key]) : filters[key]}
            </span>
            {this.renderDeleteIcon(`filters[${key}]`, filters[key])}
          </div>
        )
      }
    }

    return <div>{filtersList}</div>
  }

  renderHiddenInputs() {
    if (!this.props.renderHiddenInputs)
      return null

    const { filters } = this.props,
      inputList = []

    for(const filterKey in filters) {
      switch(typeof filters[filterKey]) {
        case "object":
          const filter = filters[filterKey]
          for(const objectKey in filter) {
            const name = Array.isArray(filter) ? `filters[${filterKey}][]` : `filters[${filterKey}][${objectKey}]`
            inputList.push(<input key={filter[objectKey]}
              name={name}
              type="hidden"
              value={filter[objectKey]} />)
          }
          break;
        default:
          inputList.push(<input key={filters[filterKey]}
            name={`filters[${filterKey}]`}
            type="hidden"
            value={filters[filterKey]} />)
          break;
      }
    }

    return <div key="active-filters-hidden-inputs-container">{inputList}</div>
  }

  render() {
    return <div className="active-filters">
      {this.renderFilters()}
      {this.renderHiddenInputs()}
    </div>
  }
}

ActiveFilters.propTypes = {
  filters: PropTypes.object,
  canDelete: PropTypes.bool,
  renderHiddenInputs: PropTypes.bool,
  accounts: PropTypes.array
}

ActiveFilters.defaultProps = {
  filters: {},
  canDelete: true,
  renderHiddenInputs: false
}
