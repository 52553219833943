import React, { useState } from 'react'
import clsx from 'clsx'
import Steps_for_modals from './steps_for_modals'

const Button = ({ id, className, image, text, onClick }) => {
  return (
    <button id={id} className={className} onClick={onClick}>
      {image && <img src={image} style={{ width: '14px', marginBottom: '3px' }} />} {text}
    </button>
  )
}

const Link = ({ className, onClick, text, iconClass }) => {
  return (
    <button className={clsx('get-started-button btn-link primary-link', className)} onClick={onClick}>
      {text} <i className={iconClass}></i>
    </button>
  )
}

export default function Steps_for_modals_button(props) {
  const [step, setStep] = useState(0)
  const [passedAddress, setPassedAddress] = useState('')
  const [passedUnit, setPassedUnit] = useState(0)

  const handleClick = () => {
    if (step !== 0) return
    setStep(1)
  }

  const handleCloseModal = () => {
    setStep(0)
  }

  return (
    <>
      {props.button ? (
        <Button
          id="enotary-request-btn"
          className="request-notary mobile-sizes btn-base btn-dark-green"
          text={props.text}
          image={props.image}
          onClick={() => setStep(1)}
        />
      ) : props.tab ? (
        <button className="e-notary-tab" onClick={handleClick}>
          {props.text}
        </button>
      ) : (
        <Link className={props.btnClasses} text={props.text} iconClass={props.icon_class} onClick={() => setStep(1)} />
      )}
      <Steps_for_modals step={step} onClose={handleCloseModal} {...props} />
    </>
  )
}
