import React from 'react'
import Beacon from '.'
import requirements1Image from '/app/assets/images/beacon/new/requirements1.png'
import requirements2Image from '/app/assets/images/beacon/new/requirements2.png'
import requirements3Image from '/app/assets/images/beacon/new/requirements3.png'
import requirements4Image from '/app/assets/images/beacon/new/requirements4.png'
import BeaconSlider from './slider'
import withBeaconModal from './modal'

const DATA = [
  {
    label: 'requirements',
    titles: ['Every requirement, listed in order here, is prescribed by Property Management and must be fulfilled.'],
    image: requirements1Image,
    category: [':: fill out forms', ':: sign docs', ':: invite others', ':: upload docs'],
  },
  {
    label: 'filling out forms',
    titles: [
      <span>
        <span className="dome-text-w500 dome-color-dark-green">a.</span> Select form to fill in, sign + edit.
      </span>,
      <span>
        <span className="dome-text-w500 dome-color-dark-green">b.</span> Upload files
      </span>,
      <span>
        <span className="dome-text-w500 dome-color-dark-green">c.</span> Select Reviewing when you’ve completed a
        Requirement to mark as “Finished” + stop editing.
      </span>,
    ],
    description: '*If further edits are needed after marking as “Finished,” switch toggle back to “Reviewing.”',
    image: requirements2Image,
  },
  {
    label: 'sharing',
    titles: [
      <span>
        Select <i className="dome-color-med-grey fa-duotone fa-solid fa-user-plus"></i> icon next to a name to choose
        Requirements to share.{' '}
      </span>,
      'Invitee will be emailed when they have access.',
    ],
    description: '*To invite someone, they must be listed with name + email in Deal Parties. ',
    image: requirements3Image,
  },
  {
    label: 'generate application',
    titles: [
      'You’ll be prompted to Generate the Application after all Requirements are marked “Finished”.',
      'Generating an application creates one digital document, with Table of Contents, dividers + all forms/files/documents within Requirements.',
      'To see the completed application, select the View + Print button.',
    ],
    image: requirements4Image,
    imageNote: '*Application must be generated before Property Management submission. ',
  },
]

const RequirementsBeacon = (props) => {
  return (
    <BeaconSlider numberOfSteps={DATA.length} {...props}>
      {(currentStep) => {
        const item = DATA[currentStep]
        return (
          <Beacon>
            <Beacon.Body style={{ width: '240px' }}>
              <Beacon.RadioGroup />
              <Beacon.ListGroup gap={0}>
                {currentStep === 0 && <Beacon.Label />}
                <Beacon.Title>{item.label}</Beacon.Title>
              </Beacon.ListGroup>
              <Beacon.ListGroup>
                {item.titles.map((title, index) => (
                  <Beacon.Text key={index}>{title}</Beacon.Text>
                ))}
                <Beacon.SubText>{item.description}</Beacon.SubText>
              </Beacon.ListGroup>
              <Beacon.Category listCategory={item.category} />
            </Beacon.Body>
            <Beacon.Image src={item.image} note={item.imageNote} />
          </Beacon>
        )
      }}
    </BeaconSlider>
  )
}

const RequirementsBeaconWithBeaconModal = withBeaconModal(RequirementsBeacon)

export default RequirementsBeaconWithBeaconModal
