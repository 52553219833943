import React, { useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash-es'
import { store } from '../store/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { fetchQuoteResume, selectInstantInterstitialState } from './slice/slice'
import { useDomecileInterstitial } from 'components/utility/domecile_interstitial/hooks'
import { InterstitialContext, InterstitialsWrapper } from 'components/utility/domecile_interstitial/wrapper'
import {
  resetStateWithProperty as resetStateWithPropertyAction,
  setFormData as setFormDataAction,
  setPackageId as setPackageIdAction,
  setInsuranceType as setInsuranceTypeAction,
  setStep as setStepAction,
  setQuote as setQuoteAction,
  INSURANCE_QUOTE_STEPS,
  setFormErrors as setFormErrorsAction,
  setErrorDescription as setErrorDescriptionAction,
  selectInsuranceQuoteInterstitialState,
} from '../get_quote/slice/slice'
import QuoteInterstitial from '../get_quote/quote_interstitial'

function InstantInterstitial({}) {
  const dispatch = useDispatch()

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const quote_uuid = urlParams.get('quote_uuid')
    if (quote_uuid) {
      dispatch(fetchQuoteResume({ quote_uuid }))
    }
  }, [])

  const { quoteResumable, quote_uuid, form, building, package_id, insuranceType, error, quote, quote_request } =
    useSelector(selectInstantInterstitialState)
  const { insuranceConstants } = useSelector(selectInsuranceQuoteInterstitialState)
  const { show, open, close, hiding } = useDomecileInterstitial()
  const constant_key = 'InsuranceConstants'

  const handleClose = () => {
    close()
    resetStateWithPropertyAction(null)
  }

  const populateInformation = useCallback(() => {
    dispatch(resetStateWithPropertyAction(building))
    const {
      response: { errorDetails, detail, status, premium, writingCompany, quoteNumber, legalDisclaimer },
      request: {
        homeAndContentsDeductible,
        liabilityLimit,
        policyEffectiveDate,
        policyExpirationDate,
        contentsAmount,
        additionsAndAlterationsAmount,
      },
    } = quote_request
    const {
      status: quoteStatus,
      insurance_type: insuranceType,
      building_id: buildingId,
      consent_document_url: consentDocumentUrl,
    } = quote
    dispatch(
      setQuoteAction({
        id: quote_uuid,
        premium,
        writingCompany,
        liabilityLimit,
        homeAndContentsDeductible,
        quoteNumber,
        legalDisclaimer,
        policyEffectiveDate,
        policyExpirationDate,
        contentsAmount,
        additionsAndAlterationsAmount,
        quoteStatus,
        insuranceType,
        buildingId,
        consentDocumentUrl,
      })
    )
    dispatch(
      setFormDataAction({
        data: {
          ...form,
          prior_carrier_name: insuranceConstants.PRIOR_CARRIERS.findIndex((i) => i === form.prior_carrier_name),
        },
        step: 'policy',
      })
    )
    dispatch(setPackageIdAction(package_id))
    dispatch(setInsuranceTypeAction(insuranceType))
    if (isEmpty(error)) {
      dispatch(setStepAction(INSURANCE_QUOTE_STEPS.finalize_page))
      return
    }

    if (error.error_type === 'viaffinity_error') {
      dispatch(setFormErrorsAction(error.error_details))
    } else if (error.error_type === 'chubb_error') {
      dispatch(setErrorDescriptionAction(error.error_details.desc))
    }
    dispatch(setStepAction(INSURANCE_QUOTE_STEPS.policy_page))
  }, [dispatch, building, package_id, insuranceType, error, quote, quote_request])

  useEffect(() => {
    if (quoteResumable) {
      open()
      populateInformation()
    }
  }, [quoteResumable])

  return (
    <InterstitialContext.Provider value={{ show, hiding }}>
      <QuoteInterstitial show={show} hiding={hiding} onClose={handleClose} constant_key={constant_key} />
    </InterstitialContext.Provider>
  )
}

export default (props) => (
  <Provider store={store}>
    <InterstitialsWrapper>
      <InstantInterstitial {...props} />
    </InterstitialsWrapper>
  </Provider>
)
