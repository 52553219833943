import React, { useId, useState } from 'react'
import SectionDivider from '../SectionDivider'
import Input from '../../Input'
import useMultiplePaths from '../../hooks/usePathsFieldProperties'

const Paths = ({ id, sectionName = 'paths', component }) => {
  const { values, addNewPath, removePath, ...props } = useMultiplePaths(id, component)

  return (
    <SectionDivider sectionName={sectionName}>
      <div style={{ background: 'white' }}>
        {Object.entries(values).map(([key, value]) => (
          <Input
            key={key}
            name={key}
            value={value}
            placeholder="enter valid path"
            {...props}
            append={
              <i
                onClick={() => {
                  removePath(key)
                }}
                className="fa-regular fa-xmark dome-color-red-alert dome-cursor-pointer"
              ></i>
            }
          />
        ))}
      </div>
      <span onClick={addNewPath} className="dome-p2 dome-color-aqua dome-cursor-pointer">
        + add another
      </span>
    </SectionDivider>
  )
}

export default Paths
