import React from 'react'
// all commented out code was present in the original coffee file. but it is not being used. keep it for a while

export default class PropertyOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: this.props.value,
      property_selected: Boolean(this.props.value),
      search_list: [],
      has_address_in_cookie: this.shouldRenderNewFormFromCookie()
    }
    this.searchTextInput = React.createRef()
    window.customPackageCookieUsed = false
  }

  componentDidMount() {
    window.customPackageCookieUsed = true
  }

  shouldRenderNewFormFromCookie() {
    if (!window.customPackageCookieUsed)
      return !!getCookie("custom_package_address")
    return false
  }

  // editProperty() {
  //   $("#custom_package_sb").addClass('hidden')
  //   $('#building-types-input').hide()
  //   $('#can-submit').data('submit', false)
  //   this.setState({
  //     property_selected: false,
  //     new_property: false,
  //     search_list: [],
  //     property: {}
  //   })

  //   this.setDefaultPackageTypes()
  //   $('#package_type').attr('disabled', 'disabled')
  //   $('#address-select').html('');

  //   this.render()
  // }

  setDefaultPackageTypes() {
    $('#package_type').html('')
    for (const packageTypeArr of this.props.default_package_types) {
      $('#package_type').append('<option value="' + packageTypeArr[0] + '">' + packageTypeArr[1] + '</option>')
    }
    $('#template_id').val('')
  }

  addressFromCookie() {
    if (window.customPackageCookieUsed || !getCookie("custom_package_address"))
      return ""
    return decodeURIComponent(getCookie("custom_package_address").replace(/\+/g, ' '))
  }

  // elasticSearchSuggest(e) {
  //   if (this.searchTextInput && this.searchTextInput.value.length > 0) {
  //     data = { query: e.target.value, include: 'id', buildings: 'all', type: document.getElementById('building_type').value }
  //     type = $(e.target).attr("data-building-type")
  //     if ( type && type != "")
  //       data.type = type

  //     new_building = '<li class="lead new-property address_search"><strong>Property Not Found, Click here to create a custom package</strong></li>'
  //     $.ajax({
  //       url: "/buildings/search_suggestions",
  //       type: 'GET',
  //       dataType: "json",
  //       contentType: 'application/json',
  //       data: data,
  //       success: function (data) {
  //         if (data.buildings.length > 0) {
  //           this.updateToSearchList(data.buildings)
  //         } else {
  //           this.updateToSearchList([])
  //         }
  //       }.bind(this)
  //     })
  //   } else {
  //     this.updateToSearchList([])
  //   }
  // }

  // updateToSearchList(list) {
  //   this.setState({
  //     search_list: list
  //   })
  // }

  // updatePackageTypes(id) {
  //   $.ajax({
  //     url: "/buildings/" + id + "/package_types",
  //     type: 'GET',
  //     dataType: "json",
  //     contentType: 'application/json',
  //     success: function (data) {
  //       if (data.buildings.length > 0) {
  //         $('#package_type').html('')
  //         data.buildings.forEach(function (b, i) {
  //           $('#package_type').append('<option data-template-id=' + b["building_package_template_id"] + ' data-package-type=' + b['package_type'] + ' value=' + b["id"] + '>' + b['title'] + '</option>')

  //           if (i == 0)
  //             $('#template_id').val(b["building_package_template_id"]).data('package-type', $('#package_type :selected').data('package-type'))

  //           $('#package_type').on('change', function () {
  //             if ($('#package_type :selected').data('template-id')) {
  //               $('#template_id').val($('#package_type :selected').data('template-id')).data('package-type', $('#package_type :selected').data('package-type'))
  //             } else {
  //               $('#template_id').val('')
  //             }
  //             return
  //           })
  //         })
  //       } else {
  //         this.setDefaultPackageTypes()
  //       }
  //     }.bind(this)
  //   })
  //   $('#package_type').removeAttr('disabled')
  // }

  // checkOrgHandlesFees(id) {
  //   $.ajax({
  //     url: "/buildings/" + id + "/org_handles_fees",
  //     type: 'GET',
  //     dataType: "json",
  //     contentType: 'application/json',
  //     success: function (data) {
  //       $('#org-handles-fees').data('fees', data)
  //     }.bind(this)
  //   })
  // }

  // checkBuildingAdditionalAddresses(id) {
  //   $.ajax({
  //     url: "/buildings/" + id + "/additional_addresses",
  //     type: 'GET',
  //     dataType: "json",
  //     contentType: 'application/json',
  //     success: function (data) {
  //       if (data.buildings.length > 0) {
  //         $('#address-select').html('');
  //       }
  //       $('#address-select').append('<option value="">Select an Address</option>')
  //       data.buildings.forEach(function (b, i) {
  //         $('#address-select').append('<option value=' + b["id"] + '>' + b['address'] + '</option>')
  //       })
  //     }.bind(this)
  //   })
  // }

  // updateBuildingId(id) {
  //   $('#building-id').data('id', id)
  // }

  // selectProperty(property) {
  //   $('#can-submit').data('submit', true)
  //   this.setState({
  //     property: property,
  //     new_property: false,
  //     search_list: []
  //   })
  //   this.updatePackageTypes(property.id)
  //   this.updateBuildingId(property.id)
  //   this.checkBuildingAdditionalAddresses(property.id)
  // }

  newPropertyTrue() {
    $('#can-submit').data('submit', true)
    this.setDefaultPackageTypes()
    this.setState({
      new_property: true,
      search_list: []
    })
    $('#package_type').removeAttr('disabled')
  }

  // searchSuggestList() {
  //   if (this.state.search_list.length > 0 || (this.searchTextInput && this.searchTextInput.value.length > 0)) {
  //     return (
  //       <ul id="address_list" className="address_list">
  //         <li className="addresses">
  //           <ul className="search-result-list">
  //             {renderProperties()}
  //           </ul>
  //         </li>
  //         {renderAddProperty()}
  //       </ul>
  //     )
  //   }
  // }

  // renderProperties() {
  //   let list = []
  //   for (const property of this.state.search_list) {
  //     list.push(
  //       <li key={property.id} className="lead property address_search" onClick={this.selectProperty.bind(this, props)}>
  //         <strong className="strong">
  //           {property.title}
  //         </strong>
  //         <br />
  //         <span>
  //           {property.address}
  //         </span>
  //         <br />
  //         <span>
  //           {property.city + ', ' + property.state + ' ' + property.zip}
  //         </span>
  //       </li>
  //     )
  //   }

  //   return list
  // }

  // renderAddProperty() {
  //   if (this.searchTextInput && this.searchTextInput.value.length > 0) {
  //     return (
  //       <li className="add-prop-list-item">
  //         <ul className="add-prop-ul">
  //           <li key='0' className="lead property address_search not-found" onClick={this.newPropertyTrue}>
  //             <strong className="strong">
  //               Property Not Found
  //             </strong>
  //             <br />
  //             <span>
  //               Click here to create a custom package
  //             </span>
  //           </li>
  //         </ul>
  //       </li>
  //     )
  //   }
  // }

  // renderSearch() {
  //   return (
  //     <div className="col-sm-7 col-xs-12">
  //       <input type="text" ref={this.searchTextInput} name="package_property_search" className="form-control" onChange={this.elasticSearchSuggest} />
  //       <input type="hidden" name="package_property_id" id="create_package_property_id" />
  //       { this.searchSuggestList() }
  //     </div>
  //   )
  // }

  // renderSelected() {
  //   return (
  //     <div className="opt">
  //       <input type="hidden" name='package[building_id]' value={this.state.property['id']} />
  //       <div className="col-sm-7 col-xs-12">
  //         <input type="text" className="form-control" readOnly="true" value={this.state.property['address']} />
  //       </div>
  //       <div className="col-xs-1">
  //         <button className="btn btn-danger pull-right" onClick={this.editProperty}>
  //           <span>Clear</span>
  //         </button>
  //       </div>
  //     </div>
  //   )
  // }

  renderPropertyForm() {
    if (this.shouldRenderNewFormFromCookie())
      this.newPropertyTrue()
    return this.renderNew()
  }

  renderNew() {
    this.setDefaultPackageTypes()
    $('#building-types-input').show()
    $("#custom_package_sb").removeClass('hidden')
    $("#building-id").data('id', '')

    return (
      <div className="add-building-form col-xs-8">
        <div className="form-horizontal col-xs-11">
          <div className="form-group">
            <label htmlFor="" className="control-label">
              Property Name
            </label>
            <input type="text" className="form-control" name='package[b_name]' required={true}/>
          </div>
          <div className="form-group">
            <label htmlFor="" className="control-label">
              Property Address
            </label>
            <input type="text" className="form-control" name='package[b_address]' required={true} defaultValue={this.addressFromCookie() || ""}/>
          </div>
          <div className="form-group">
            <label htmlFor="" className="control-label">
              City
            </label>
            <input type="text" className="form-control" name='package[b_city]' required={true} />
          </div>
          <div className="form-group">
            <label htmlFor="" className="control-label">
              State
            </label>
            <input type="text" className="form-control" name='package[b_state]' required={true} />
          </div>
          <div className="form-group">
            <label htmlFor="" className="control-label">
              Zip Code
            </label>
            <input type="text" className="form-control" name='package[b_zip]' required={true} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="form-horizontal">
        <div className="option">
          <div className="form-group">
            <label className="control-label col-xs-12 col-sm-4">
              Property Address
            </label>
            {this.renderPropertyForm()}
          </div>
        </div>
      </div>
    )
  }
}

PropertyOption.defaultProps = {
  property_id: 0,
  property_selected: false,
  search_list: [],
  new_property: false
}
