import { useContext, useMemo } from "react"
import { viewContext } from "../context/viewContext"

export const useBuildParams = (page, threadId) => {
  const { threadsPerPage, unique_owner_id } = useContext(viewContext)

  const params = useMemo(() => {
    const result = {
      unique_owner_ids: [unique_owner_id],
      per_page: threadsPerPage,
    }

    if (!threadId) {
      result.page = page;
    } else {
      result.thread_id = threadId;
    }

    return result
  }, [page, threadId, threadsPerPage, unique_owner_id])

  return params
};
