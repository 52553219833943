import React from 'react'
import $ from 'jquery'

class MarkNotificationButton extends React.Component {

  hasBeenRead(){
    if(this.props.notifications[0] === undefined){
      return false;
    }else{
      for (var i = 0; i < this.props.notifications.length; i++) {
        if (this.props.notifications[i].notification.has_been_read === false){
          return false
        }
        i++;
      }
      return true;
    }
  }

  getCheckedIds(){
    if(this.props.notifications != undefined){
      var array = [];
      this.props.notifications.map(function(notification){
        array.push(notification.notification.id)
      });
      return array
    }else{
      return this.props.checked_ids;
    }
  }

  toggleRead(e){
    $.ajax({
      url: 'notifications/read',
      type: 'POST',
      dataType: "json",
      data: {checked_ids: this.getCheckedIds()},
      success: function(data){
        this.props.handleAllReadClick()
      }.bind(this)
    })
  }

  render() {
    return(
      <div className= 'btn-group btn-block'>
        <button
        className= {'dome-btn dome-btn-base dome-btn-dark-green'}
        type='button'
        onClick={e => this.toggleRead(e)}>
          mark as read
        </button>
      </div>
      )
  }
}

export default MarkNotificationButton
