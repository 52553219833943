import React from 'react'

import Category from './category'
import Document from './document'
import Toast from './toast'

function incrementCart() {
  const cart_icon = $(".notification-container > .cart-icon")
  const cart_count = cart_icon.next(".cart-notification-bubble")
  const current_count = cart_icon.hasClass("active") ? parseInt(cart_count.html()) : 0
  if (current_count == 0) {
    cart_icon.addClass("active")
    cart_count.addClass("active")
  }

  cart_count.html(current_count + 1)
}


class Display extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.document_details = Object.assign({}, this.props.document_user_details || {})
    this.state.toasts = []
  }

  unsorted_categories() {
    this.documentsByCategory(null)
    return Object.keys(this.documents_by_category)
  }

  documentsByCategory(cat) {
    if (this.documents_by_category)
      return this.documents_by_category[cat]

    const by_cat = {}
    for (let doc of this.props.documents) {
      if (!by_cat[doc.file_class])
        by_cat[doc.file_class] = []

      by_cat[doc.file_class].push(doc)
    }

    return (this.documents_by_category = by_cat)[cat]
  }

  extendDocument(doc) {
    return Object.assign({}, doc, this.state.document_details[doc.id] || {})
  }

  visibleCategories() {
    const categories = StoreDocument.TYPES_DISPLAY_ORDER.filter(key => this.documentsByCategory(key))

    return categories.concat(this.unsorted_categories().filter(cat => !categories.includes(cat)).sort())
  }

  addToCart(doc) {
    const document_details = Object.assign({}, this.state.document_details)
    document_details[doc.id] = Object.assign({}, document_details[doc.id], { in_cart: true })
    this.setState({document_details})

    this.addToast({doc, type: "cart"})
    incrementCart()
  }

  addToLibrary(doc) {
    const document_details = Object.assign({}, this.state.document_details)
    document_details[doc.id] = Object.assign({}, document_details[doc.id], { in_library: true })
    this.setState({document_details})

    this.addToast({doc, type: "library"})
  }

  addToRequests(doc) {
    const document_details = Object.assign({}, this.state.document_details)
    document_details[doc.id] = Object.assign({}, document_details[doc.id], { awaiting_confirmation: true })
    this.setState({document_details})

    this.addToast({doc, type: "request"})
  }

  awaitConfirmation(doc) {
    const document_details = Object.assign({}, this.state.document_details)
    document_details[doc.id] = Object.assign({}, document_details[doc.id], { awaiting_confirmation: true })
    this.setState({document_details})
  }

  addToast(toast) {
    const toasts = this.state.toasts.concat([])
    toasts.push(toast)

    while(toasts.length > 5)
      toasts.shift()

    this.setState({toasts})
  }

  removeToast(doc) {
    const toasts = this.state.toasts.concat([])
    for (let index = 0; index < toasts.length; index++){
      if (toasts[index].doc == doc){
        toasts.splice(index, 1)
        break
      }
    }

    this.setState({toasts})
  }

  render() {
    return <div className="panel-group store-document-building-page-component">
      {this.visibleCategories().map(cat => this.renderCategory(cat))}
      {this.renderToasts()}
    </div>
  }

  renderToasts() {
    if (this.state.toasts.length == 0)
      return null

    return <div className="toasts">
      {this.state.toasts.map(({type, doc}) => <Toast key={doc.id} type={type} document={doc} onClose={() => this.removeToast(doc)}  /> )}
    </div>
  }

  renderCategory(cat) {
    const docs = this.documentsByCategory(cat).sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase()> a.label.toLowerCase()) ? -1 : 0)).map(doc =>  {
      return <Document key={doc.id}
                      site_key={this.props.site_key}
                      document={this.extendDocument(doc)}
                      current_user={this.props.user_id}
                      building_id={this.props.building_id}
                      addToCart={() => this.addToCart(doc)}
                      addToLibrary={() => this.addToLibrary(doc)}
                      addToRequests={() => this.addToRequests(doc)}
                      awaitConfirmation={() => this.awaitConfirmation(doc)} />
    })

    return <Category key={cat} category={cat}>
      {docs}
    </Category>
  }
}

export default Display
