import React from 'react'

class Row extends React.Component {

  id() {
    return `store-document-edit-row-${this.props.document.id}`
  }

  componentDidMount() {
    $("#" + this.id()).find("span.doc-label").tooltip()
  }

  render() {
    const download_path = `/download/${this.props.document.id}/owner`

    return <tr id={this.id()}>
      <td>
        {this.renderLabel()}
      </td>
      <td className="button">
        {this.renderDocumentCost(this.props.document)}
      </td>
      <td className="button">
        <a href="#" className="edit-store-document" onClick={e => this.clickEdit(e)} dangerouslySetInnerHTML={{__html: store_document_buttons.edit_html}}></a>
      </td>
      <td className="button">
        <a href={download_path} className="download-store-document" target="_blank"><img src={store_document_buttons.download_image_path} /></a>
      </td>
      {this.renderDelete(this.props.document)}
    </tr>
  }

  renderLabel() {
    const tip = []
    tip.push(`<h4 key="_title_">Document Info:</h4>`)

    const details = []
    details.push(["Display Name", this.props.document.label])
    details.push(["Type", this.props.document.display_type])
    details.push(["Expiration", this.props.document.display_expiration])
    details.push(["Price", asMoney(this.props.document.price || 0)])

    for (let detail of details)
      tip.push(`<dl key="${detail[0]}"><dt>${detail[0]}</dt><dd>${detail[1]}</dd></dl>`)

    const tip_html = `<div className="building-doc-tip">${tip.join("")}</div>`

    return <span data-html={true} data-class="doc-label-tooltip" title={tip_html} data-place="right" data-effect="solid" data-toggle="tooltip" className="doc-label">
      {this.props.document.label}
    </span>
  }

  renderDocumentCost() {
    if (!this.props.document.cost_of_document)
      return ""

    try {
      return asMoney(this.props.document.cost_of_document)
    } catch(e) { }
  }

  renderDelete(doc) {
    if (!this.canDelete(doc))
      return null

    const disabled = this.props.is_deleting

    return <td className="button">
      <input type="checkbox" readOnly={true} disabled={disabled} checked={this.props.deleting} onChange={e => {}} onClick={e => this.props.clickDelete()} />
    </td>
  }

  canDelete() {
    return this.props.can_delete
  }

  clickEdit(e) {
    e.preventDefault()
    this.props.clickEdit()
  }

}

export default Row