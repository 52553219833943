import React from 'react'
import PropTypes from 'prop-types'

function errorsFromXhr(xhr) {
  try { return JSON.parse(xhr.responseText) }
  catch(e) {}

  return {error: xhr.responeText}
}

class ApplyButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.paywallData && this.state.paywallData)
      this.apply()
  }

  handleClick() {
    if (!this.props.userSignedIn) return this.loginAlert()

    if (this.props.userRoleRestrictedFromApplying)
      return this.restrictedRoleAlert()

    if (this.state.paywallData)
      return this.apply()

    this.setLoading(true)

    this.getPaywallData()
      .then(data => this.setState({ paywallData: data }))
      .catch(err => alert(err))
      .finally(() => this.setLoading(false))
  }

  getPaywallData() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/paywalls/get_paywall',
        data: { hashedId: this.props.hashedId },
        success: data => resolve(data),
        error: xhr => reject(errorsFromXhr(xhr))
      })
    })
  }

  apply() {
    const { paywallData: {
      building_has_additional_addresses,
      can_skip_initiation_fee,
      show_package_apply_disclaimer_modal,
      allowed_to_apply
    } } = this.state
    const { hashedId, packageMachineId, packageType, buildingId } = this.props

    if (this.props.unmountOtherModal) this.props.unmountOtherModal()

    if (!allowed_to_apply)
      return userNotAllowedToApplyAlert()

    if (building_has_additional_addresses)
      return closePreviewOpenAddressSelect(hashedId, packageMachineId, packageType, can_skip_initiation_fee)

    if (can_skip_initiation_fee) {
      if (show_package_apply_disclaimer_modal) {
        return showDisclaimerModal(null, function() {
          return closePreviewOpenPackageLoadingModal(hashedId, buildingId)
        }, null, false)
      } else {
        return closePreviewOpenPackageLoadingModal(hashedId, buildingId)
      }
    }

    closePreviewOpenNextModal(hashedId, packageType)
  }

  loginAlert() {
    if (this.props.unmountOtherModal) this.props.unmountOtherModal()

    const text = `<div class='building-require-login-modal-header'">
      <i class="fa-thin fa-arrow-right-to-bracket" style="color: #005C4F; font-size: 45px"></i>
      <p class="title">login to continue</p>
    </div>
    <div class='building-require-login-modal-body'>
      <p class="require-login-content">Only logged in users may apply online.</p>
    </div>
    `

    swal({
      title: '',
      text: text,
      html: true,
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: "log in / sign up",
      confirmButtonColor: '#005C4F',
      closeOnConfirm: false,
      customClass: 'building-require-login-modal',
    }, function(isConfirm){
      if (!isConfirm) return
      if (inIframe()) {
        var win = window.open(loginPath, "_blank");
        win.focus();
      } else {
        window.location = loginPath
      }
    })
  }

  restrictedRoleAlert() {
    const text =
    `<div class="dome-d-flex dome-flex-column dome-gap12">
      <i class="fa-thin fa-circle-exclamation" style="color: #005C4F; font-size: 45px"></i>
      <div class="dome-color-dark-green dome-h2">unable to apply</div>
      <div class="dome-color-darkest-grey">Please note your account type is unable to initiate this application.</div>
      <div class="dome-color-darkest-grey">If you have any questions, please contact <a class='dome-link dome-link-aqua' target='_blank' href='mailto:info@domecile.com'>info@domecile.com</a> for assistance.</div>
    </div>`
    swal({
      title: "",
      html: true,
      text: text,
      showCancelButton: false,
      confirmButtonText: "OK",
      customClass: "application-apply-modal",
    })
  }

  setLoading(bool) {
    this.setState({ loading: bool })
  }

  renderLoading() {
    return <button
      disabled
      className="btn btn-success">Loading...</button>
  }

  renderContent() {
    const { buttonText, userRoleRestrictedFromApplying } = this.props

    if (this.state.loading) return this.renderLoading()

    const classes = ["btn-base", "btn-green-stroke"]
    if (userRoleRestrictedFromApplying) classes.push("disabled")

    return (
      <button
        id="package-apply-online"
        className={classes.join(' ')}
        onClick={() => this.handleClick()}
      >
        {buttonText || 'apply online'}
      </button>
    )
  }

  render() {
    return <span style={{ marginLeft: '12px' }}>
      { this.renderContent() }
    </span>
  }
}

ApplyButton.propTypes = {
  buttonText: PropTypes.string,
  hashedId: PropTypes.string.isRequired,
  packageMachineId: PropTypes.number.isRequired,
  packageType: PropTypes.string.isRequired,
  buildingId: PropTypes.number.isRequired,
  unmountOtherModal: PropTypes.func,
  userSignedIn: PropTypes.bool.isRequired,
  userRoleRestrictedFromApplying: PropTypes.bool
}

export default ApplyButton
