import React from "react";
import PropTypes from "prop-types";

import { compact } from 'lodash';

const notEmpty = val => val && !String(val).match(/^\s*$/)

function object_to_params(obj) {
  return Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
}

class BuildingSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBuildingResults: [],
      buildingList: null,
      searching: false,
      query: this.props.query || "",
      liveOnly: props.liveOnly || true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.boundClearData = this.clearData.bind(this);

    this.inputRef = React.createRef();
    this.inputContainerRef = React.createRef();
  }

  componentDidMount() {
    $(document.body).on("click", this.boundClearData);
    if (notEmpty(this.props.query))
      this.getBuildings()

    if (this.props.focusOnMount && this.inputRef.current)
      this.inputRef.current.focus()
  }

  componentWillUnmount() {
    $(document.body).off("click", this.boundClearData);
  }

  url(q) {
    const url = this.props.url,
      query = { query: q || this.state.query || "", count_results: true };

    if (this.props.organization)
      query.organization_id = this.props.organization;

    query.only_live = this.state.liveOnly; //this.props.include_disabled ? false : true
    query.include_addresses = this.props.include_addresses;

    if (this.props.params) Object.assign(query, this.props.params, query);

    return url + "?" + object_to_params(query);
  }

  clearData() {
    if ($(this.inputRef.current).val() != "") $(this.inputRef.current).val("");

    if (typeof this.props.onSelect == "function")
      this.props.onSelect({
        building: null,
        address: null,
        custom_building: null,
        query: this.state.query
      });

    this.setState({
      searchBuildingResults: [],
      addresses: [],
      building: null,
      address: null,
      query: "",
    });
  }

  rateLimitQueryUpdater(new_value) {
    this.setState({ query: new_value || "" });

    clearTimeout(this.rated_limited_lookup);
    this.rated_limited_lookup = setTimeout(
      () => this.findPossibleBuildings(),
      250
    );
  }

  validQuery(query) {
    query = query || this.state.query;
    return query && query.length >= 2;
  }

  findPossibleBuildings(query) {
    if (!this.validQuery(query))
      return this.setState({
        searchBuildingResults: [],
        addresses: [],
        buildingList: null,
      });

    this.setState({ searching: true }, this.getBuildings);
  }

  handleClick() {
    $(".wrapper").css("filter", "none");
    $(".wrapper").removeClass("pointer-events-off");
    $("#nav-drawer").removeClass("open-drawer");
    $("body").css("overflow", "auto");
    $(".hamburger-menu-open").removeClass("opened");
    const customApplicationSuggestion =
      window[this.props.clickHandlerNameForCustomAppSuggestion];

    if (this.props.allow_custom) customApplicationSuggestion(this.state.query);
  }

  getBuildings() {
    $.ajax({
      url: this.url(this.state.query),
      success: (response) => {
        this.setState(
          {
            searchBuildingResults: response.buildings,
            addresses: [],
            searching: null,
          },
          this.prepareBuildingsForRender
        );
      },
    });
  }

  prepareBuildingsForRender() {
    const buildings = [];
    for (let building of this.state.searchBuildingResults)
      buildings.push(
        <div
          className="property-address-section"
          key={building.id}
          onClick={() => { typeof(this.props.onSelect) == "function" ? this.props.onSelect(building) : null}}
        >
          <img className="property-thumbnail" src={building.thumbnail} alt="" />
          <div>
            <span className="property-title">{building.title}</span>
            <span className="address-subtitle">{building.address}</span>
          </div>
        </div>
      );

    this.setState({ buildingList: buildings }, this.applyStylesToSearchBar);
  }

  renderBuildings() {
    if (
      this.state.buildingList &&
      this.state.buildingList.length == 0 &&
      this.state.searching == null
    ) {
      return this.renderNoPossibilities();
    }

    const classes = compact([
      'building-possibilities',
      this.props.classes?.buildingPossibilities
    ]).join(" ");

    return (
      <>
        <div className={classes}>
          {this.state.buildingList}
          {this.renderStartCustomApplicationOption()}
        </div>
      </>
    );
  }

  renderStartCustomApplicationOption() {
    if (!this.props.allow_custom) return null;

    return (
      <div
        className='property-address-section custom-property'
        key='custom-property'
        onClick={() => this.handleClick()}
      >
        <div className='custom-property-content'>
          {this.props.customApplicationImage ? <img className='property-thumbnail' src={this.props.customApplicationImage} alt='' /> : null}
          <div className='inner-text'>
            <div>Don't see the address you're looking for?</div>
            <div className='pseudo-link'>
              Click here to create a custom application &gt;
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNoPossibilities() {
    if (!this.validQuery()) return null;

    if (!this.props.show_empty_message) return null;

    if (this.state.searching) return null;

    let start_custom = null;
    if (this.props.allow_custom) {
      start_custom = (
        <>
          <div className="inner-text">
            <div>Don't see the address you're looking for?</div>
            <div className="pseudo-link">Click to build your own application &gt;</div>
          </div>
        </>
      );
    }

    const customEmptyMessage = this.props.customEmptyMessage || null

    const no_results_found = <div className="inner-text">no results found</div>;

    const classes = compact([
      'no-possibilities',
      this.props.classes?.customNoBuildingPossibilities
    ]).join(" ")

    return (
      <div className={classes} onClick={() => this.handleClick()}>
        <div className="no-possibilities-content">
          {this.props.customApplicationImage ? <img className='property-thumbnail' src={this.props.customApplicationImage} alt='' /> : null}
          {start_custom || customEmptyMessage || no_results_found}
        </div>
      </div>
    );
  }

  handleCheckLive(checked) {
    this.setState({ liveOnly: checked }, this.getBuildings);
  }

  renderLiveOnlyCheckbox() {
    if (!this.props.askLive) return null;

    const classes = ["ask-live"];
    if (this.state.live) classes.push("checked");

    return (
      <label
        className={classes.join(" ")}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        <input
          type="checkbox"
          className="live-checkbox"
          defaultChecked={this.props.liveOnly}
          onChange={(event) => this.handleCheckLive(event.target.checked)}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
        <span>live only</span>
      </label>
    );
  }

  renderBuildingSearch() {
    if (this.state.building || this.state.custom) return;

    const inside_container = [];
    const container_classes = compact([this.props.classes?.containerClass]);

    if (this.props.show_magnifying_glass) {
      inside_container.push(
        <i className={this.props.classes?.iconClass} key="mag-glass" />
      );
    }

    inside_container.push(
      <input
        ref={this.inputRef}
        className={this.props.classes?.inputClass}
        key="input"
        type="search"
        size="31"
        defaultValue={this.state.query}
        placeholder={this.props.placeholderText}
        onChange={(e) => this.rateLimitQueryUpdater(e.target.value)}
      />
    );

    const classes = ["building-select"];
    if (this.props.reversed) classes.push("building-select-reversed");

    return (
      <div className={classes.join(" ")}>
        <div
          className={container_classes.join(" ")}
          ref={this.inputContainerRef}
        >
          {inside_container}
          {this.renderLiveOnlyCheckbox()}
        </div>
        {this.renderBuildings()}
      </div>
    );
  }

  applyStylesToSearchBar() {
    const input = $(this.inputRef.current);
    const searchDetails = $(this.inputContainerRef.current).next("div");

    if (input.val().length > 1) {
      if (this.props.classes?.searchBarBorderClass)
        input.addClass(this.props.classes.searchBarBorderClass);

      input.addClass("is-open");
      searchDetails.show();
    }
  }

  removeStylesFromSearchBar() {
    const input = $(this.inputRef.current);
    const searchDetails = $(this.inputContainerRef.current).next("div");
    if (this.props.classes?.searchBarBorderClass)
      input.removeClass(this.props.classes.searchBarBorderClass);

    input.removeClass("is-open");
    searchDetails.hide();
  }

  restartSearchFromCustom() {
    this.findPossibleBuildings(this.state.custom.address);
    this.setState({ query: this.state.custom.address || "", custom: null });
  }

  componentDidUpdate() {
    if (this.state.buildingList == null || this.state.query.length < 2)
      this.removeStylesFromSearchBar();
  }

  render() {
    return (
      <div
        className="building-select-area utility-building-search-component"
        onClick={(e) => e.stopPropagation()}
      >
        {this.renderBuildingSearch()}
      </div>
    );
  }
}

BuildingSearch.propTypes = {
  placeholderText: PropTypes.string,
  url: PropTypes.string,
  organization: PropTypes.number,
  onSelect: PropTypes.func,
  params: PropTypes.object,
  classes: PropTypes.object,
};

BuildingSearch.defaultProps = {
  placeholderText: "find your property",
  url: "/buildings/search_suggestions",
  include_addresses: 0,
};

export default BuildingSearch;
