import React from 'react'
import Modal from './Modal';
import Table from './Table'
import { Provider } from "react-redux";
import Store from './Store';
import { useEventManager } from '@deathbyjer/react-event-manager'

const InviteAdditionalContactsTag = () => {
  const events = useEventManager()

  const handleClick = () => {
    events.applyEventListeners('additional_contacts:modal:create', { formData: {} })
  }

  return <a className='bopa-orange' onClick={handleClick}>+ Invite Additional Contact</a>
}

const App = (props) => {
  return (
    <Provider store={Store}>
      <Table {...props} />
      <InviteAdditionalContactsTag />
      <Modal {...props} />
    </Provider>
  )
}

export default App
