import { createSlice, nanoid } from "@reduxjs/toolkit"
import { set } from 'lodash'

export const dealPartySlice = createSlice({
  name: 'dealParty',
  initialState: {
    data: {},
    deletedPaths: []
  },
  reducers: {
    setData: (state, action) => {
      // todo
      state.data = action.payload.data
    },
    setDealParties: (state, action) => {
      // todo: edit
      // state.dealParties = action.payload.dealParties
    },
    blankDealPartyForSection: (state, action) => {
      const section = action.payload.section,
            emptyDealParty = { ...state.dealParties[section][0] },
            maxOrder = Math.max.apply(Math, state.dealParties[section].map(dp => dp.order)) || 0

      Object.keys(emptyDealParty).forEach(key => {
        switch(key) {
          case 'id':
            emptyDealParty[key] = `${section}_${nanoid()}`
            break
          case 'order':
            emptyDealParty[key] = maxOrder + 10
            break
          default:
            emptyDealParty[key] = ''
            break
        }
      })

      state.dealParties[action.payload].push(emptyDealParty)
    },
    add: (state, action) => {
      state.data[action.payload.path] = action.payload.value
      if (state.deletedPaths.includes(action.payload.path)) {
        state.deletedPaths = state.deletedPaths.filter(path => path !== action.payload.path)
      }
    },
    clone: (state, action) => {
      // todo: refactor
      const { role, sectionMetadataFields } = action.payload
      const roleDataKeys = Object.keys(state.data).filter(key => key.startsWith(`${role}[`))
      const roleData = roleDataKeys.map(key => { return {[key]: state.data[key]} })
      const roleIndex = str => parseInt(str.split('.')[0].split('[')[1].split(']')[0])
      const instanceCount = roleData.length == 0 ? 1 : Math.max(...roleData.map(d => roleIndex(Object.keys(d)[0])))
      const basePath = `${role}[${instanceCount+1}]`

      sectionMetadataFields.map(field => field.id ).forEach(field => {
        const path = `${basePath}.${field}`
        console.log(path)
        state.data[path] = ''
      })
    },
    update: (state, action) => {
      const { path, value } = action.payload

      state.data[path] = value

      if (state.deletedPaths.includes(path)) {
        state.deletedPaths = state.deletedPaths.filter(deletedPath => deletedPath !== path)
      }
    },
    remove: (state, action) => {
      const { role, role_index } = action.payload

      Object.keys(state.data).filter(key => key.startsWith(`${role}[${role_index}]`)).forEach(key => {
       state.deletedPaths.push(key)
       delete state.data[key]
      })
    }
  }
})

export const { setData, setDealParties, add, clone, update, remove, blankDealPartyForSection } = dealPartySlice.actions
export default dealPartySlice.reducer
