import React from "react"

const Form = props => {
  return (
    <form
      className='registration-form'
      name='registration'
      action={props.action}
      method={props.method}
      onSubmit={props.handleSubmit(props.submitFn)}
      noValidate
    >
      {props.children}
    </form>
  )
}

export default Form
