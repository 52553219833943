import React from 'react'

const DateField = props => {
  const { placeholder, name, handleUpdate, required, value } = props

  return (
    <input type="date"
           className='form-control'
           placeholder={placeholder || ''}
           name={name || ''}
           onChange={event => handleUpdate(event.target.value)}
           defaultValue={value || ''}
           maxLength={10}
           required={required || false} />
  )
}

export default DateField
