import React from 'react'

import ErrorGroup from './error_group'
import SelectMethod from './select_method'


class PaymentErrors extends React.Component {
  constructor(props) {
    super(props)

    this.state = { groups: {} }
    for (let group_class in props.groups) {
      this.state.groups[group_class] = {}
      for (let group_id in props.groups[group_class])
        this.state.groups[group_class][group_id] = props.groups[group_class][group_id]
    }
  }

  clear() {
    this.setState({groups: {}})
  }

  groups() {
    const groups = []
    for (let group_class in this.state.groups)
      for (let group_id in this.state.groups[group_class])
        groups.push({group_class, group_id, group: this.state.groups[group_class][group_id]})

    return groups
  }

  removeGroup({group_class, group_id}) {
    const groups = {...this.state.groups}
    const class_group = { ... groups[group_class] }
    delete class_group[group_id]
    groups[group_class] = class_group
    this.setState({groups})
  }

  totalMonies() {
    return this.groups().map(({group}) => group.total_price).reduce((total, price) => total + price, 0)
  }

  render() {
    const groups = this.groups()

    return <div>
      {this.renderHasErrors(groups)}
      {this.renderNoErrors(groups)}
    </div>
  }

  renderNoErrors(groups) {
    if (groups.length > 0)
      return null

    return <h3 className="text-center">There are no errors associated with this payment method</h3>
  }

  renderHasErrors(groups) {
    if (groups.length == 0)
      return null

    const group_key = props => [props.group_class, props.group_key].join("|")

    return <div className="card-has-errors">
      <div className="title">
        <h3>There have been errors processing your credit card.</h3>
        <p>
          Please either ensure the issues are resolved with the current payment method or assigning a new payment method to be used. 
        </p><p>
          You may also cancel individual items you no longer with to be assigned to any payment method. Once cancelled, the items will need to be repurchased and/or authorized. Please feel free to contact the property management company or <a href="mailto:info@boardpackager.com">BoardPackager Support</a> if you have any questions.
        </p>
      </div>
      <div className="user-payment-method-payment-errors-component">
        <div className="payment-error-group header">
          <div>
            <div className="title">Payment Type</div>
            <div className="price">Price</div>
            <div className="error">Payment Error</div>
            <div className="number-of-errors"># of Items</div>
            <div className="buttons"></div>
          </div>
        </div>
        <div className="payment-error-group-list">
          {groups.map(props => <ErrorGroup {...props} key={group_key(props)} id={this.props.id} onRemove={() => this.removeGroup(props)} />)}
        </div>

        <SelectMethod id={this.props.id} cards={this.props.cards} total={this.totalMonies()} onComplete={() => this.clear()} />
      </div>
    </div>
  }
}


export default PaymentErrors