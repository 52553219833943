import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { sortBy } from 'lodash-es'
import { useEventManager } from '@deathbyjer/react-event-manager'
import useSelectedKeys from '../../hooks/useSelectedKeys'
import { useSelector } from 'react-redux'

const DependentFieldTypes = ['name', 'time']

const DependentFieldExpanded = ({ signatureId }) => {
  const allComponents = useSelector(({ components }) => components.all)

  const dependentFieldComponent = useMemo(() => {
    return Object.values(allComponents)
      .filter((component) => component.id === signatureId && DependentFieldTypes.includes(component.subtype))
      .sort((a, b) => a.subtype.localeCompare(b.subtype))
  }, [allComponents, signatureId])

  if (dependentFieldComponent.length === 0) return null

  return (
    <div className="dome-d-flex dome-flex-column dome-gap12">
      {dependentFieldComponent.map((component) => {
        return (
          <div key={component.subtype} className="item field-expand">
            <div className="icon">
              <i className="fa-light fa-credit-card-blank"></i>
            </div>
            <div className="field-name">
              <p>{component?.behaviorInputs?.label}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const ExpandableSection = ({ component }) => {
  const events = useEventManager()
  const selectedKeys = useSelectedKeys()

  const shouldExpandSelected = useMemo(() => {
    return selectedKeys.some((selectedKey) => selectedKey.includes(`${component.type}.${component.id}`))
  }, [component.type, component.id, selectedKeys])

  const handleClick = useCallback(() => {
    events.applyEventListeners('selectComponent', component.keyArray)
    events.applyEventListeners(`scrollToField`, component.page)
  }, [component.keyArray, component.page, events])

  return (
    <div
      onClick={handleClick}
      className={clsx('expandable-section dome-d-flex dome-flex-column dome-gap12', shouldExpandSelected && 'active')}
    >
      <div className="dome-d-flex dome-align-center dome-gap12">
        <div className="icon">
          <i className="fa-light fa-lg fa-signature"></i>
        </div>
        <div className="field-name">
          <p>{`${component?.type} ${component?.id}`}</p>
        </div>
      </div>
      <DependentFieldExpanded signatureId={component.id} />
    </div>
  )
}

export default ExpandableSection
