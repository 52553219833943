import React from 'react'
import Behaviors from './Behaviors'
import Transform from './Transform'
import { useGatheredToolbars } from "../../toolbar/Tools"

const Properties = () => {
  const toolbars = useGatheredToolbars()

  return (
    <div className='content'>
      {toolbars || <p className='instructional'>Select a field label to get started.</p>}
    </div>
  )
}

export default Properties
