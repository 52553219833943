import React, { useEffect, useRef } from "react";
import introStamp from "/app/assets/images/pages/home/enotary/stamp-intro.svg"

export default function ENotaryIntroModal(props) {
  const ref = useRef()
  const cleanup = () => props.onClose()
  const hide = () =>  {
    $(ref.current).modal("hide");
    props.setStep(2)
  }

  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])

  return (
    <div ref={ref} className='modal e-notary-modal-screens'>
      <div className='e-notary-modal-box mobile-width'>
        <img src={introStamp} className="intro-img" />
        <h2>need notarization?</h2>
        <p className='subtitle'>
          Notarize digitally in three steps:
        </p>
        <ol className="enotary-three-steps">
          <li>
            <p className="enotary-three-steps__step-title">set a time</p>
            <p>Schedule a time that works best for you.</p>
          </li>
          <li>
            <p className="enotary-three-steps__step-title">verify identity</p>
            <p>Complete a simple + secure screening to confirm your identity.</p>
          </li>
          <li>
            <p className="enotary-three-steps__step-title">e-notarize!</p>
            <p>Our in-house notaries validate your identity, witness your signature, + notarize your document(s) in minutes!</p>
          </li>
        </ol>
        <div className="e-notary-modal-buttons">
          <a className="link-aqua link-chevron-right" href="/about/enotary" target="_blank">learn more</a>
          <button className='btn-base btn-dark-green' onClick={hide}>get started</button>
        </div>
      </div>
    </div>
  )
}
