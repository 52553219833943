import React from "react"

class FormSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organization_id: "",
      query: "",
    }
    this.timer = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      this.debounceInput()
    }
  }

  getOrganizationOptions() {
    const organizations = this.props.organizations
    let options = []
    organizations.forEach(org => {
      options.push(<option key={org.id}>{org.name}</option>)
    })
    options.unshift(<option key={0}>All</option>)

    return options
  }

  renderFilter() {
    return (
      <div className='col-xs-12 col-md-6'>
        <form action=''>
          <label style={{ display: "inline" }} htmlFor='organization_filter'>
            Organization Filter{" "}
          </label>
          <select
            className='form-control'
            style={{ display: "inline", width: "50%" }}
            name='organization_filter'
            id='organization_filter'
            onChange={e =>
              this.filterUpdated("organization_id", e.target.value)
            }
          >
            {this.getOrganizationOptions()}
          </select>
        </form>
      </div>
    )
  }

  filterUpdated(filter_name, new_value) {
    const state = { ...this.state }
    state[filter_name] = new_value
    this.setState(state, () => this.updateParent())
  }

  updateParent() {
    const filters = {
      organization_id: this.state.organization_id,
    }
    this.props.onChange(filters)
  }

  updateStateOnChange(e) {
    this.setState({ query: e.target.value })
  }

  debounceInput() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.props.handleSearch(this.state.query)
    }, 375)
  }

  renderSearchBar() {
    return (
      <div className='col-xs-12 col-md-6'>
        <form
          className='form-horizontal'
          action='/super/forms'
          method='GET'
          acceptCharset='UTF-8'
          onKeyDown={e => (e.key === "Enter" ? e.preventDefault() : true)}
        >
          <input type='hidden' name='utf8' value='✓' />
          <div className='input-group'>
            <input
              type='search'
              name='search'
              className='form-control'
              placeholder='Search Form Names or Slugs'
              id='search'
              onChange={e => this.updateStateOnChange(e)}
            />
            <div className='input-group-btn'>
              <button
                className='btn btn-default'
                style={{ pointerEvents: "none" }}
              >
                <span
                  className='glyphicon glyphicon-search'
                  aria-hidden='true'
                ></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render() {
    return (
      <div className='page-header'>
        <div className='col-xs-12 col-md-2'>
          <h3 className='pull-left' style={{ color: "#898989", margin: "0px" }}>
            Forms
          </h3>
        </div>
        <div className='col-xs-12 col-md-1'>
          <span className='pull-right'>Results: {this.props.formCount}</span>
        </div>
        <div className='col-xs-12 col-md-8'>
          {this.renderFilter()}
          {this.renderSearchBar()}
        </div>
        <div className='clearfix'></div>
      </div>
    )
  }
}

export default FormSearch
