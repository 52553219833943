import React, { useState } from 'react'
import CreatingFormBuilder from './CreatingForm'
import { Modal } from 'react-bootstrap'

export default function FormBuilderOverlay({ buttonText, organizations }) {
  const [show, setShow] = useState(false)
  const hideOverlay = () => setShow(false)

  return (
    <>
      <a style={{ display: 'block' }} className="dome-cursor-pointer" onClick={() => setShow(true)}>
        {buttonText}
      </a>
      <Modal show={show} centered={true} onHide={hideOverlay} className="form-builder-modal">
        <CreatingFormBuilder organizations={organizations} hideOverlay={hideOverlay} />
      </Modal>
    </>
  )
}
