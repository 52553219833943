import { addCSRF } from "lib/utilities"

export function submitFee({ url, hashed_id, fees, selected_option }) {

  const opts = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(addCSRF({ fees, hashed_id, selected_option }))
  }

  return new Promise((res, rej) => {
    fetch(url, opts)
      .then(response => response.text())
      .then(data => JSON.parse(data).error ? rej(JSON.parse(data).error) : res(JSON.parse(data).updated_fees))
      .catch(rej)
  })

}