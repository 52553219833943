import React from 'react'

import TextField from './field_types/text_field'
import SSNField from './field_types/ssn_field'
import PhoneField from './field_types/phone_field'
import DateField from './field_types/date_field'
import SelectField from './field_types/select_field'
import DataListField from './field_types/data_list_field'

function RequiredSpan() {
  const style = {color: '#b94a48'}
  return <span style={style}>
    Field is required
  </span>
}

function Label({label, required, value}) {
  const show_required = required && !value

  return <label>
    {label}
    { show_required ? <RequiredSpan required={required} value={value} /> : null }
  </label>
}

function renderFieldType(props) {
  switch(props.type) {
    case 'text':
      return <TextField {...props} />
    case 'ssn':
      return <SSNField {...props} />
    case 'phone_number':
      return <PhoneField {...props} />
    case 'date':
      return <DateField {...props} />
    case 'select':
      return <SelectField {...props} />
    case 'datalist':
      return <DataListField {...props} />
  }

  return null
}

const Field = props => {
  const { label, required, value } = props
  return <div className='col-xs-6'>
    <Label label={label} required={required} value={value} />
    {renderFieldType(props)}
  </div>
}

export default Field
