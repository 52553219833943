import React, { useEffect } from 'react';
import { useWatchableRef } from 'lib/hooks';

function BuildingUnitRemoveResidentConfirmationModal({ open, onClose, onSubmit, resident }) {
  const residentRole = resident?.resident_role;
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const localRefValue = modalRef.current
    const handleClose = (_e) => onClose()

    $(localRefValue).on('hidden.bs.modal', handleClose)
    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal building-unit-remove-resident-confirmation-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-body">
            <i className="fa-thin fa-circle-exclamation fa-3x dome-color-red-alert"></i>
            <div>
              <span className="dome-text-w500 dome-h2 dome-color-dark-grey">are you sure?</span>
            </div>
            <div>
              <p className='dome-p1'>{`This action will remove the ${residentRole} from this unit.`}</p>
            </div>
          </div>
          <div className="modal-footer" style={{ width: '100%' }}>
            <div className='modal-footer-actions-wrapper'>
              <button className='dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel' data-dismiss="modal" onClick={onClose}>cancel</button>
              <button className="dome-btn dome-btn-base dome-btn-red-stroke" onClick={onSubmit}>{`remove ${residentRole}`}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildingUnitRemoveResidentConfirmationModal
