import React, { useEffect } from 'react'
import { Provider, useDispatch } from 'react-redux'
import store from './src/app/store'

import { load as loadNewMessages } from './src/features/new_messages'

const TheLoader = ({data}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadNewMessages(data))
  }, [data])
}

const Main = ({ data }) => {
  return (
    <Provider store={store}>
      <TheLoader data={data} />
    </Provider>
  )
}

export default Main
