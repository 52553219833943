import React, { createContext } from "react";

export const userContext = createContext(null)

export const UserContextProvider = ({current_user, children}) => {
  return (
    <userContext.Provider value={{currentUser: current_user}}>
      {children}
    </userContext.Provider>
  )
}
