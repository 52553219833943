import React from 'react'
import { padStart, compact } from 'lodash'

const onlyNumbers = value => String(value).replaceAll(/[^0-9]/g, '').replace(/^0+/, '')

function makeMoney(value) {
  value = onlyNumbers(value)
  value = padStart(value, 3, '0')

  const dollars = []
  for (let i = value.length - 2; i >= 0; i -= 3)
    dollars.push(value.substring( Math.max(i-3, 0), i))

  return compact(dollars).reverse().join(",") + "." + value.substring(value.length - 2)
}

export default function({field: {placeholder}, id, onChange, value, onBlur, className}) {
  const handleChange = e => onChange({[id]: onlyNumbers(e.target.value)})

  const props = {
    value: makeMoney(value || "0.00"), 
    placeholder,
    type: "text",
    onChange: handleChange,
    onBlur
  }

  return <div className={className}>
    <input {...props} />
  </div>
}