import React, { useRef } from 'react'
import axios from 'lib/axios'

function notifyRemoval(site_banner_id) {
  axios.delete(`/site_banners/${site_banner_id}/for_user`)
}

function removeBanner(location, ref) {
  ref.current.closest(".site-banner").remove()

  switch(location) {
    case 'top':
      document.body.classList.remove("has_top_banner")
      break
  }
}

function BannerCloseButton({ site_banner_id, location }) {
  const ref = useRef()

  const handleClick = () => {
    if (!ref.current) return

    notifyRemoval(site_banner_id)
    removeBanner(location, ref)
  }

  return <i className="close-banner fa fa-times" ref={ref} onClick={handleClick}></i>
}

export default BannerCloseButton
