import React, { useCallback, useState } from 'react'

const Searchbar = ({ setQueriedLabel }) => {
  const [query, setQuery] = useState('')

  const handleChange = useCallback((e) => {
    setQuery(e.target.value)
    setQueriedLabel(e.target.value)
  }, [])

  return (
    <div className='searchbar search-fields'>
      <div><i className='fa-light fa-sm fa-magnifying-glass'></i></div>
      <input type='text' onChange={handleChange} value={query} placeholder='search fields...' />
    </div>
  )
}

export default Searchbar
