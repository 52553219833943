import { residentPaymentsInvoiceEditReducer } from 'components/ResidentPayments/InvoiceEdit/store/slice'
import { residentPaymentsNewInvoiceReducer } from 'components/ResidentPayments/NewInvoice/store/slice'
import { residentPaymentsInvoicePageReducer } from 'components/ResidentPayments/InvoicePage/store/slice'
import { residentPaymentsInvoicePaymentReducer } from 'components/ResidentPayments/InvoicePayment/store/slice'
import { buildingEditResidentPaymentsReducer } from 'components/BuildingEditResidentPayments/store/slice'
import { adminResidentPaymentsReducer } from 'components/ResidentPayments/Admin/store/slice'

export const residentPaymentsReducers = {
  adminResidentPayments: adminResidentPaymentsReducer,
  residentPaymentsInvoiceEdit: residentPaymentsInvoiceEditReducer,
  residentPaymentsNewInvoice: residentPaymentsNewInvoiceReducer,
  residentPaymentsInvoicePage: residentPaymentsInvoicePageReducer,
  residentPaymentsInvoicePayment: residentPaymentsInvoicePaymentReducer,
  buildingEditResidentPayments: buildingEditResidentPaymentsReducer,
}
