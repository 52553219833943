import React, { createRef } from 'react'


const Extensions = {
  gif: "image/gif",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
  csv: "text/csv",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
}

function getExt(filename) {
  return filename.split(".").pop()
}

function getFileType(file) {
  if (!file)
    return
  
  if (file.file_type)
    file.type = file.file_type

  if (file.type && !file.type.match(/^\s*$/) && file.type != "application/octet-stream")
    return file.type

  if (!file.name)
    return

  const from_extension = Extensions[getExt(file.name)]
  if (from_extension)
    return from_extension

  if (typeof(file) == "object" && (parseFloat(file.size || 0) || 0) > 0)
    return "unsupported"
}

class UploadArea extends React.Component {
  constructor(props) {
    super(props)
    this.uploadAreaRef = createRef()
  }

  componentDidMount() {
    $(this.uploadAreaRef.current).on("drop", e => this.dropStuff(e))
    this.createFileInput()
    $(window).on({dragover: this.windowDragging, drop: this.windowDragging})
  }

  componentWillUnmount() {
    delete $(this.uploadAreaRef.current)
    $(window).off({dragover: this.windowDragging, drop: this.windowDragging})
  }

  windowDragging(evt) {
    evt.preventDefault()
  }

  createFileInput() {
    if (this.file_input)
      this.file_input.remove()

    this.file_input = $("<INPUT />").attr("type", "file").attr("multiple", "multiple").on("change", e => this.inputChanged(e))
    $(this.uploadAreaRef.current).append(this.file_input)
  }

  prepareFiles(file_list) {
    const files = []
    let file

    for (let i in file_list) {
      file = this.prepareUploadFile(file_list[i])

      if (file.type)
        files.push(file)
    }

    return files
  }

  dropStuff(evt) {
    const transfer = evt.originalEvent.dataTransfer,
          files = this.prepareFiles(transfer.files)

    if (files.length == 0)
      return

    if (this.props.inputChanged)
      this.props.inputChanged(files)
  }

  clickUpload() {
    this.file_input[0].click()
  }

  inputChanged() {
    const files = this.prepareFiles(this.file_input[0].files)

    if (files.length == 0)
      return

    if (this.props.inputChanged)
      this.props.inputChanged(files)

    this.createFileInput()
  }

  extraFunctionality() {
    if (this.props.extraFunctionality)
      return this.props.extraFunctionality

    return {}
  }

  prepareUploadFile(file) {
    const file_type = getFileType(file)
    const valid_types = valid_types && (Array.isArray(this.props.valid_types) ? this.props.valid_types : [this.props.valid_types])
    
    const out = {
      file: file,
      name: file.name || file.filename,
      size: file.size || file.file_size,
      type: file_type
    }

    out.needs_uploading = true
    if (valid_types && !valid_types.includes(file_type))
      out.needs_uploading = false

    return out
  }

  renderInside() {
    const icon = this.props.exclude_icons ? "" : <span className="file-icons"><i className="fa fa-file-o" /><i className="fa fa-plus" /></span>,
          text = this.props.text || "Drop files Here"

    if (this.props.children)
      return this.props.children

    return <>
      {icon}
      <span>{text}</span>
    </>
  }

  render() {
    return  <div ref={this.uploadAreaRef} className="upload-area-component" onClick={() => this.clickUpload()}  {...this.extraFunctionality()}>
      { this.renderInside() }
    </div>
  }
}

export default UploadArea
