import React, { useState, useEffect } from 'react'

export default function ButtonAndModal(props) {
  const [dealStatusName, setDealStatusName] = useState('')

  $("#new-deal-status-btn").on('click', function () {
    $("#new-deal-status-modal").show();
  })

  $("#close-modal").on('click', function () {
    $("#new-deal-status-modal").hide();
  })

  function createDealStatus() {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `/super/organizations/${props.org_id}/new_deal_status`,
      data: { status: dealStatusName, status_type: props.currentStatusType, sort_order: props.nextSortOrderIndex },
      //success: function (res) { console.log(res) }
    })
      .done((res) => {
        props.setDealStatuses(res.deal_statuses)
        $("#close-modal").on('click', function () {
          $("#new-deal-status-modal").hide();
        })
      })
  }

  return (
    <>
      <button type='button' id="new-deal-status-btn" className="btn btn-success pull-right">New Deal Status</button>

      <div id="new-deal-status-modal" className="modal fade" style={{ opacity: 1, overflow: 'visible' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Deal Status</h5>
            </div>
            <div className="modal-body" style={{ paddingTop: "10px" }}>
              <span>Status Name: </span>
              <input style={{ width: "100%" }} type="text" value={dealStatusName || ""} onChange={(e) => setDealStatusName(e.target.value)} />
            </div>
            <div className="modal-footer">
              <button onClick={() => createDealStatus()} type="button" id="close-modal" className="btn btn-success">Create Deal Status</button>
              <button type="button" id="close-modal" className="btn btn-primary">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
