import React from 'react'
import PropTypes from 'prop-types'

import FeesTable from './fees_table'

const modalSelector = ".additional-fee-modal"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feesOnTable: [],
      feesToAdd: [],
      feesToEdit: {},
      confirming: false,
      loading: false
    }

    this.setModalState = this.setModalState.bind(this)
    this.handleCancelBtnClick = this.handleCancelBtnClick.bind(this)
    this.handleSubmitBtnClick = this.handleSubmitBtnClick.bind(this)
  }

  componentDidMount() {
    this.initFeesToEdit()
    this.listenModalClose()
    $(modalSelector).modal("show")
  }

  componentWillUnmount() {
    $(modalSelector).modal("hide")
  }

  listenModalClose() {
    $(modalSelector).on("hidden.bs.modal", function (e) {
      this.props.setAppState({ modalActive: false })
    }.bind(this));
  }

  setModalState(newState) {
    this.setState(newState)
  }

  setLoading(loadingBool) {
    this.setState({loading: loadingBool})
  }

  handleCancelBtnClick() {
    switch(this.props.modalPurpose) {
      case "add":
        if (this.state.confirming)
          return this.setState({confirming: false})
        break
      case "edit":
        break
      case "collect":
        break
    }
  }

  handleSubmitBtnClick() {
    switch(this.props.modalPurpose) {
      case "add":
        if (!this.state.confirming)
          return this.setState({confirming: true})
        this.submitAssignFees()
        break
      case "edit":
        this.submitUpdateEdits()
        break
      case "collect":
        this.submitCollectFees()
    }
  }

  isEditedFeeAmountChanged(fee) {
    if ($.isEmptyObject(fee.updatedAttributes)) return false
    if (fee.updatedAttributes.amount == undefined) return false

    return Number(fee.amount) != Number(fee.updatedAttributes.amount)
  }

  anyFeesEdited() {
    return Object.values(this.state.feesToEdit).some((fee) => fee.markedForDeletion || (!$.isEmptyObject(fee.updatedAttributes)))
  }

  allUpdatedAttributesNotBlank() {
    let fees = Object.values(this.state.feesToEdit).filter(fee => !$.isEmptyObject(fee.updatedAttributes))

    return fees.every(fee => Object.values(fee.updatedAttributes).every(attribute => attribute != null && attribute != ""))
  }

  feesMarkedForDeletion() {
    return Object.values(this.state.feesToEdit).filter(fee => fee.markedForDeletion)
  }

  feesWithUpdatedAttributes() {
    let feesInHash = {}
    const fees = Object.values(this.state.feesToEdit).filter(fee => !$.isEmptyObject(fee.updatedAttributes))

    fees.forEach(fee => feesInHash[fee.id] = fee.updatedAttributes)

    return feesInHash
  }

  areFeeAmountsValid() {
    switch(this.props.modalPurpose) {
      case "add": {
        return this.state.feesToAdd.every(fee => Number(fee.amount) >= 1 && Number(fee.amount) < 100000)
      }
      case "edit": {
        if (!this.isEditedAmountForAuthorizedFeesValid())
          return false

        let editedFees = Object.values(this.state.feesToEdit)

        // amounts aren't updated
        if (editedFees.every(fee => !$.isEmptyObject(fee.updatedAttributes) || fee.updatedAttributes.amount == undefined))
          return true

        editedFees = editedFees.filter(fee => !$.isEmptyObject(fee.updatedAttributes) && fee.updatedAttributes.amount != undefined )

        // validates all updated fee amount is greator or equal to 1
        return editedFees.every(fee => this.isEditedFeeAmountChanged(fee) && Number(fee.updatedAttributes.amount) >= 1)
      }
      default:
        return true
    }
  }

  // validates all authorized updated amount is less or equal to original amount
  isEditedAmountForAuthorizedFeesValid() {
    let fees = Object.values(this.state.feesToEdit)
    fees = fees.filter(fee => !$.isEmptyObject(fee.updatedAttributes) && fee.updatedAttributes.amount && fee.status == "Authorized" )

    if (fees.length == 0) return true

    return fees.every(fee => Number(fee.amount) >= Number(fee.updatedAttributes.amount))
  }

  initFeesToEdit() {
    let feesToEdit = {}
    this.props.existingFees.forEach(fee => (feesToEdit[fee.id] = {...fee, updatedAttributes: {}, markedForDeletion: false}))

    this.setState({feesToEdit: feesToEdit})
  }

  collectableFees() {
    return this.props.existingFees.filter(fee => fee.status == "Authorized")
  }

  submitAssignFees() {
    this.setLoading(true)
    $.ajax({
      url: `/packages/${this.props.packageHashedId}/assign_additional_fees`,
      method: "POST",
      dataType: "JSON",
      data: {fees: JSON.stringify(this.state.feesToAdd)},
      context: this,
      success: response => {
        this.props.setAppState({modalActive: false, sendModalSuccess: true})
        this.setLoading(false)
      }, error: xhr => {
        alert(xhr.responseJSON.error.base.join(" "))
        this.setLoading(false)
      }
    })
  }

  submitUpdateEdits() {
    this.setLoading(true)
    let data = {
      feeIdsMarkedForDeletion: this.feesMarkedForDeletion().map(fee => fee.id),
      feesWithUpdatedAttributes: this.feesWithUpdatedAttributes()
    }

    $.ajax({
      url: `/packages/${this.props.packageHashedId}/update_additional_fees`,
      method: "PATCH",
      dataType: "JSON",
      data: data,
      context: this,
      success: response => {
        this.props.setAppState({modalActive: false, sendModalSuccess: true})
        this.setLoading(false)
      }, error: xhr => {
        // todo
      }
    })
  }

  submitCollectFees() {
    this.setLoading(true)
    $.ajax({
      url: `/packages/${this.props.packageHashedId}/capture_additional_fees`,
      method: "POST",
      dataType: "JSON",
      context: this,
      success: response => {
        this.props.setAppState({modalActive: false, sendModalSuccess: true})
        this.setLoading(false)
      }, error: xhr => {
        this.props.setAppState({
          modalActive: false,
          sendModalError: true,
          modalErrors: xhr.responseJSON.error.stripe_errors,
          collectDeclineCodes: xhr.responseJSON.error.declineCode,
          uncapturableFees: xhr.responseJSON.error.uncapturableFees,
          declineCodePerFeeId: xhr.responseJSON.error.declineCodePerFeeId
        })
        this.setLoading(false)
      }
    })
  }

  renderHeaderText() {
    switch(this.props.modalPurpose) {
      case "add":
        return this.state.confirming ? "Assign Fees?" : "Additional Fees"
      case "edit":
        return "Edit Fees"
      case "collect":
        return <div>
          <div className="sa-icon sa-info"></div>
          <h2 className="fees-collection-alert-title">Collectable Fee(s)</h2>
        </div>
    }
  }

  renderBodyText() {
    switch(this.props.modalPurpose) {
      case "add":
        if (this.state.confirming)
          return <p>Please review for accuracy. Select “Go Back” to make changes or if everything is correct, select Confirm to proceed.</p>
        return <div>
          <p>To add fees to this package select Fee Name, Payee, when fees are due, and then enter the Amount. Select the green plus button to add more fees. Select the red x button to remove any fees.</p>
        </div>
      case "edit":
        return <div className="text-center">Use this area to edit any assigned or authorized fees. Fees with a status of “Assigned” are fully editable and re-assignable. Fees with a status of “Authorized” can only be decreased in amount or deleted.</div>
      default:
        return null
    }
  }

  modalFooterClasses() {
    let classes = "modal-footer"
    if (this.props.modalPurpose == "collect") classes += " modal-footer-collect"

    return classes
  }

  renderFooterText() {
    switch(this.props.modalPurpose) {
      case "collect":
        return <div className="fees-collection-warning">
          The above fees have all been authorized and are able to be collected. If an assigned fee is not appearing in this list, it has not been authorized yet and cannot be collected. Please ensure that the amounts specified are correct before proceeding to collect fees.
        </div>
      default:
        return null
    }
  }

  renderBody() {
    return <div>
      {this.renderBodyText()}
      {<FeesTable
          modalPurpose={this.props.modalPurpose}
          packageHashedId={this.props.packageHashedId}
          isAlteration={this.props.isAlteration}
          confirming={this.state.confirming}
          setModalState={this.setModalState}
          feesToAdd={this.state.feesToAdd}
          feesToEdit={this.state.feesToEdit}
          feesOnTable={this.state.feesOnTable}
          collectableFees={this.props.modalPurpose == "collect" ? this.collectableFees() : null} />}
    </div>
  }

  submitBtnText() {
    switch(this.props.modalPurpose) {
      case "add":
        return this.state.confirming ? "Confirm" : "Assign Fees"
      case "edit":
        return "Save"
      case "collect":
        return "Proceed & Collect Fee(s)"
    }
  }

  submitBtnClasses() {
    return this.props.modalPurpose == "collect" ? "btn-collect collect-proceed" : "btn btn-success"
  }

  cancelBtnText() {
    switch(this.props.modalPurpose) {
      case "add":
        return this.state.confirming ? "Go Back" : "Cancel"
      case "edit":
        return "Cancel"
      case "collect":
        return "Cancel"
    }
  }

  cancelBtnClasses() {
    return this.props.modalPurpose == "collect" ? "btn-collect collect-cancel" : "btn btn-secondary"
  }

  shouldCancelBtnCloseModalOnClick() {
    switch(this.props.modalPurpose) {
      case "add":
        return this.state.confirming ? false : true
      case "edit":
        return true
      case "collect":
        return true
    }
  }

  shouldSubmitBtnBeDisabled() {
    if (this.state.loading) return true

    switch(this.props.modalPurpose) {
      case "add":
        if (this.state.feesToAdd.length == 0) return true

        if (!this.areFeeAmountsValid()) return true

        return !this.state.feesToAdd.every(fee =>
          fee.amount >= 1 && ["due_at", "name", "package_user_id", "sub_merchant_id"].every(feeAttribute => fee[feeAttribute] != "")
        )
      case "edit":
        if (!this.anyFeesEdited()) return true

        if (!this.areFeeAmountsValid()) return true

        return !this.allUpdatedAttributesNotBlank()
      case "collect":
        return this.collectableFees().length == 0
    }
  }

  renderCancelBtn() {
    return <button type="button"
      className={this.cancelBtnClasses()}
      onClick={() => this.handleCancelBtnClick()}
      data-dismiss={this.shouldCancelBtnCloseModalOnClick() ? "modal" : ""}>
      {this.cancelBtnText()}
    </button>
  }

  renderSubmitBtn() {
    return <button type="button"
      className={this.submitBtnClasses()}
      disabled={this.shouldSubmitBtnBeDisabled()}
      onClick={() => this.handleSubmitBtnClick()}>{this.submitBtnText()}</button>
  }

  render() {
    return (
      <div className="modal additional-fee-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.renderHeaderText()}</h5>
            </div>
            <div className="modal-body">
              {this.renderBody()}
            </div>
            <div className={this.modalFooterClasses()}>
              {this.renderFooterText()}
              {this.renderCancelBtn()}
              {this.renderSubmitBtn()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  packageHashedId: PropTypes.string.isRequired,
  setAppState: PropTypes.func.isRequired,
  existingFees: PropTypes.array.isRequired,
  modalPurpose: PropTypes.string.isRequired
}

export default Modal
