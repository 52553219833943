import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

function PaymentCompletedModal(props) {
  const { open, onClose, statementDescriptor } = props;
  const modalRef = useWatchableRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const close = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", close);

    return () => $(localRefValue).off("hidden.bs.modal", close);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal payment-completed-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-check fa-3x"></i>
            <div>
              <h3 className="modal-title -modal__title">Payment Complete</h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="payment-completed__text-inform">This payment will show on your account statement as:</div>
            <div className="payment-completed__text-receipt">{statementDescriptor}</div>
            <div className="payment-completed__text-detail">
              <div className="text-bold">Deposits</div>
              <div>Contact refunds@domecile.com or Management to initiate deposit return.</div>
              <br />
              <div className="text-bold">Refunds</div>
              <div>Contact refunds@domecile.com before filing a dispute with your credit issuer.</div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-custom-cancel" data-dismiss="modal" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentCompletedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statementDescriptor: PropTypes.string.isRequired,
};

export default PaymentCompletedModal;
