import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash-es'

export const useGetStartingQuote = (building, insuranceType) => {
  const startingQuotes = useMemo(() => {
    if (isEmpty(building?.insurance_building_settings)) return {}

    const settings = {}

    building.insurance_building_settings.forEach((setting) => {
      if (setting['starting_quote_relevant?']) {
        settings[setting.unit_type] = setting.serialized_starting_quote
      }
    })

    return settings
  }, [building?.insurance_building_settings])

  const startingQuote = useMemo(() => {
    return startingQuotes[insuranceType]
  }, [startingQuotes, insuranceType])

  return startingQuote
}

export const useBindInjectedElements = (data, containerRef, handleClick) => {
  useEffect(() => {
    if (!data || !containerRef) return

    Object.values(data)?.forEach(({ events }) => {
      events?.forEach(({ data_event, action, target }) => {
        const button = containerRef.current?.querySelector(`[data-react-event=${data_event}]`)
        if (!button) return

        const clickListener = (event) => {
          handleClick(event, action, target)
        }

        button.addEventListener('click', clickListener)

        return () => {
          button.removeEventListener('click', clickListener)
        }
      })
    })
  }, [data, containerRef.version, handleClick])

}
