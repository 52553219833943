import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { attachDocumentApi, attachFormApi, saveFormApi } from '../apis'
import { startAppListening } from 'components/global/listener'
import { produce } from 'immer'

const initialState = {
  stateInitialized: false,
  building: {},
  package_template: {},
  package_type_label: '',
  package_types: [],
  package_template_states: [],
  package_template_active_state: null,
  is_super_admin: false,
  has_rofr_waiver: false,
  can_upload_template_yaml: false,
  myDocumentsModalState: {
    visible: false,
    requirement: {
      id: null,
    },
    buildingId: null,
  },
}

export const attachDocument = createAsyncThunk(
  'packageTemplatesForm/attachDocument',
  async ({ package_hashed_id, requirement_id, data: formData }) => {
    const { data } = await attachDocumentApi({ package_hashed_id, requirement_id, data: formData })

    return data
  }
)

export const attachForm = createAsyncThunk(
  'packageTemplatesForm/attachForm',
  async ({ package_hashed_id, requirement_id, data: formData }) => {
    const { data } = await attachFormApi({ package_hashed_id, requirement_id, data: formData })

    return data
  }
)

const packageTemplatesFormSlice = createSlice({
  name: 'packageTemplatesForm',
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      Object.assign(state, { ...action.payload, stateInitialized: true })
    },
    togglePreview: (state, action) => {
      state.previewModalVisible = action.payload
    },
    openMyDocumentsModal: (state, action) => {
      Object.assign(state.myDocumentsModalState, {
        visible: true,
        requirement: action.payload.requirement,
        buildingId: action.payload.buildingId,
      })
    },
    closeMyDocumentsModal: (state, action) => {
      Object.assign(state.myDocumentsModalState, initialState.myDocumentsModalState)
    },
  },
  extraReducers: (builder) => {},
})

export const saveAll = createAsyncThunk('packageTemplatesForm/saveAll', async ({ package_template_id, formData }) => {
  const parsedFormData = produce(formData, (draft) => {
    draft.package_template.package_requirements.forEach((requirement, requirement_index) => {
      requirement.sort_order = requirement_index + 1
      requirement.package_template_documents.forEach((doc, doc_index) => {
        doc.sort_order = doc_index + 1
      })
    })
  })

  await saveFormApi({ package_template_id, formData: parsedFormData })
})

export const selectPackageTemplatesForm = (state) => state.packageTemplatesForm

export const selectMyDocumentsModalState = createSelector(
  selectPackageTemplatesForm,
  (state) => state.myDocumentsModalState
)

export const { setInitialState, togglePreview, openMyDocumentsModal, closeMyDocumentsModal } =
  packageTemplatesFormSlice.actions

export const packageTemplatesFormReducer = packageTemplatesFormSlice.reducer

startAppListening({
  actionCreator: saveAll.fulfilled,
  effect: (action, listenerApi) => {
    window.location.reload()
  },
})
