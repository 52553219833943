import React from 'react'

import { useForm } from 'react-hook-form'
import Form from './Form'
import FormFields from './FormFields'

const RegisterForm = () => {
  const { register, unregister, setError, setValue, handleSubmit, watch, formState: { errors, isValid }} = useForm({
    defaultValues: {
      user:{
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone_number: '',
        account_type: '',
      }

    },
    shouldUnregister: true,
    mode: 'onTouched',
    reValidateMode: 'onChange'
  })

  const submitRegistration = (data) => {
    $.ajax({
      url: '/registration_create_with_json',
      data: {...data},
      context: this,
      method: 'POST',
      dataType: 'JSON'
    })
    .done(res => {
      window.location.assign(res.url)
    })
    .fail((res) => {
      Object.keys(res.responseJSON.errors).forEach(key => {
        setError(`user[${key}]`, {
          type: "manual",
          message: res.responseJSON.errors[key],
        });
      });
    });
  }

  return (
    <div className='registration centered-container'>
      <header>create a free account</header>
      <div className="byr-notice display-only-desktop">
        <p className='byr-title'>before you register &mdash;</p>
        <p>
          Have you received an email invitation to our system?
          <span> If so, please register through the unique link provided in that email.</span>
        </p>
      </div>
      <Form handleSubmit={handleSubmit} submitFn={submitRegistration}>
        <FormFields register={register} unregister={unregister} setValue={setValue} watch={watch} errors={errors} isValid={isValid} />
      </Form>
    </div>
  )
}

export default RegisterForm
