import React from 'react'

import { compact } from 'lodash'
import { sortObjectKeys } from 'lib/utilities'

function Static({classes, value}) {
  return <div className={classes.join(" ")}>
    { value || "" }
  </div>
}

function Measurement({field, id, onChange, onBlur, disabled, value}) {
  const { measurement_placeholder: placeholder,
          measurement_key = "measurement" } = field.extras

  const handleChange = e => onChange({[`${id}.${measurement_key}`]: e.target.value})

  const props = {
    type: "text",
    disabled,
    placeholder,
    value: value[measurement_key] || "",
    onBlur,
    onChange: handleChange
  }

  return <div className="measurement">
    <input {...props} />
  </div>
}

function Units({field, id, onChange, onBlur, disabled, value}) {
  const { units_placeholder: placeholder,
          units_key = "measurement",
          units = {} } = field.extras

  const handleChange = e => onChange({[`${id}.${units_key}`]: e.target.value})

  const props = {
    disabled,
    value: value[units_key] || "",
    onBlur,
    onChange: handleChange
  }

  const options = sortObjectKeys(units).map(key => [key, units[key]])

  if (placeholder)
    options.unshift(['', placeholder])

  return <div className="units">
    <select className="units" { ...props }>
      {options.map(([key, label]) => <option key={key} value={key}>{label}</option>)}
    </select>
  </div>
}

export default function({field, id, onChange, value = {}, onBlur, className, disabled}) {
  const props = {
    field,
    id, value,
    disabled,
    onChange,
    onBlur
  }

  const classes = compact([
    className
  ])

  if (field.system_only)
    return <Static className={classes} value={value} />

  return <div className={classes.join(" ")}>
    <Measurement {...props} />
    <Units {...props} />
  </div>
}