import React from 'react'

function Save(props) {
  const classes = ["btn", "btn-sm", "btn-warning", "pull-right", "collaborator-hover"]

  const onClick = () => {
    switch(props.selectedTab) {
      case 'POLICYTAB':
      case 'MESSAGETAB':
      case 'REMOVETAB':
        return props.changeTab('DEFAULTTAB')
      case 'DEFAULTTAB':
        return props.updatePackageUser(props.collaborator)
    }
  }

  return  <button onClick={onClick} className={classes.join(" ")}>
    Save
  </button>
}

function Send(props) {
  const classes = "btn btn-sm btn-success pull-right collaborator-hover"
  const onClick = () => props.changeTab('CONFIRMTAB')

  return <button className={classes} onClick={onClick}>
    Send
  </button>
}

function Toolbar(props) {
  const attached = props.collaborator.attached
  const isDefault = props.selectedTab == "DEFAULTTAB"

  const cancelButton = <button onClick={props.cancelPolicyChange} className="btn btn-sm btn-danger pull-left collaborator-hover">Cancel</button>

  return <div className="btn-toolbar">
      { cancelButton }
      { attached ? null : <Send {...props} /> }
      { attached || !isDefault ? <Save {...props} /> : null}
    </div>
}

export default Toolbar
