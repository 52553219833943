import React from 'react'

class Row extends React.Component {
  static defaultProps = {
    disabled: true
  }

  onDelete(){
    this.props.handleDocumentDelete(this.props.document.id)
  }

  onInputChange(event) {
    let newValue = event.target.value
    let changedState = event.target.name
    this.props.onDocumentInputChange(newValue,this.props.document.id, changedState)
  }

  render(){

    let { label, file_class, days_until_expiration, id, price, current_gl_code, current_charge_code } = this.props.document

    const docTypeOptions = Object.keys(this.props.docTypes).map(key => {
      return ( <option key={key} value={key}>{this.props.docTypes[key]}</option> )
    })

    let trStyle={ display: 'table-row'}
    let tdStyle={ display: 'table-cell', width: '18%', padding: '5px'}
    let tdStyleHalfWidth = { ...tdStyle, width: '9%' }
    let iconCell={ display: 'table-cell', width: '10%', paddingLeft: '5px'}
    let trashIcon={ fontSize: '20px', cursor: 'pointer'}

    return (
      <form style={trStyle}>
        <span className='dont-break-out' style={tdStyle}>
          <input type='text'
            value={label}
            onChange={this.onInputChange.bind(this)}
            placeholder="display name"
            name='label'
            className='form-control'
            disabled={this.props.disabled}
            />
        </span>
        <span style={tdStyle}>
          <select value={file_class}
            name='file_class'
            onChange={this.onInputChange.bind(this)}
            disabled={this.props.disabled}
            className='form-control'>
            {docTypeOptions}
          </select>
        </span>
        <span style={tdStyle}>
          <select value={days_until_expiration}
            name='days_until_expiration'
            onChange={this.onInputChange.bind(this)}
            disabled={this.props.disabled}
            className='form-control'
            >
            <option value='0'>No Expiration</option>
            <option value='14'>14</option>
            <option value='30'>30</option>
            <option value='45'>45</option>
            <option value='90'>90</option>
          </select>
        </span>
        <span style={tdStyle}>
          <input type='number'
            value={price}
            name='price'
            onChange={this.onInputChange.bind(this)}
            disabled={this.props.disabled}
            className='form-control'
            />
        </span>
        <span style={tdStyleHalfWidth}>
          <input type="text"
            value={current_gl_code || ''}
            name='current_gl_code'
            onChange={this.onInputChange.bind(this)}
            disabled={this.props.disabled}
            className='form-control'
          />
        </span>
        <span style={tdStyleHalfWidth}>
          <input type="text"
            value={current_charge_code || ''}
            name='current_charge_code'
            onChange={this.onInputChange.bind(this)}
            disabled={this.props.disabled}
            className='form-control'
          />
        </span>
        <span style={iconCell}>
          <i className="fa fa-trash" style={trashIcon} onClick={this.onDelete.bind(this)}></i>
        </span>
      </form>
    )
  }
}

export default Row
