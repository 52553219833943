import { trim } from "lodash-es";

function removeItem(draftArray, itemBeRemoved) {
  const index = draftArray.findIndex((item) => item === itemBeRemoved);

  if (index !== -1) {
    draftArray.splice(index, 1);
  }
}

export const categoryValidationRules = {
  title: { presence: true },
};

export const serviceValidationRules = {
  title: { presence: true, max_length: 150 },
  sub_merchant_id: { presence: true, nullable: true },
  price: { presence: true },
};

/**
 * @param formState: Immer writable state.form
 * @param rules: categoryValidationRules || serviceValidationRules
 * */
export const validateItem = (formState, rules, { id, type, key, value }) => {
  if (!rules.hasOwnProperty(key)) {
    return;
  }

  const rule = rules[key];
  const formId = `${type}-${id}`;

  formState[formId] ||= { touched: true, errors: {} };
  formState[formId].errors[key] ||= [];

  if (rule["presence"] === true) {
    if (rule["nullable"] === true && value === null) {
      removeItem(formState[formId].errors[key], "presence");
    } else if (trim(value) === "") {
      !formState[formId].errors[key].includes("presence") && formState[formId].errors[key].push("presence");
    } else {
      removeItem(formState[formId].errors[key], "presence");
    }
  }

  if (rule["max_length"]) {
    if (trim(value).length > rule["max_length"]) {
      !formState[formId].errors[key].includes("max_length") && formState[formId].errors[key].push("max_length");
    } else {
      removeItem(formState[formId].errors[key], "max_length");
    }
  }
};

export const checkSubMerchantId = (state, rules, { id, type, key, value }) => {
  if (!value) return;

  const formState = state.form;
  const rule = rules[key];
  const formId = `${type}-${id}`;

  formState[formId] ||= { touched: true, errors: {} };
  formState[formId].errors[key] ||= [];

  if (rule["presence"] === true) {
    const subMerchantDropdown = state.building?.sub_merchant_dropdown_with_default || [];
    const isSubmerchantExist = subMerchantDropdown.some(([_description, id]) => id === value);

    if (!isSubmerchantExist) {
      !formState[formId].errors[key].includes("presence") && formState[formId].errors[key].push("presence");
    } else {
      removeItem(formState[formId].errors[key], "presence");
    }
  }
};

export const validateAllItems = (state) => {
  const categoryAttributesToValidate = Object.keys(categoryValidationRules);

  state.categories.forEach((category) => {
    for (const key of categoryAttributesToValidate) {
      validateItem(state.form, categoryValidationRules, {
        type: "category",
        id: category.id,
        key,
        value: category[key],
      });
    }
  });

  const serviceAttributesToValidate = Object.keys(serviceValidationRules);

  Object.values(state.services).forEach((service) => {
    for (const key of serviceAttributesToValidate) {
      validateItem(state.form, serviceValidationRules, {
        type: "service",
        id: service.id,
        key,
        value: service[key],
      });

      if (key === "sub_merchant_id") {
        checkSubMerchantId(state, serviceValidationRules, {
          type: "service",
          id: service.id,
          key,
          value: service[key],
        });
      }
    }
  });
};
