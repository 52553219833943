import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

function RemoveServiceModal(props) {
  const { open, onClose, onSubmit } = props;
  const modalRef = useWatchableRef(null);

  const handleSubmit = (_e) => {
    onSubmit();
  };

  useEffect(() => {
    if (!modalRef.current) return;

    const handleClose = (_e) => onClose();
    const localRefValue = modalRef.current;

    $(localRefValue).on("hidden.bs.modal", handleClose);

    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    $(modalRef.current).modal(open ? "show" : "hide");
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal remove-service-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-exclamation fa-3x"></i>
            <div>
              <h3 className="modal-title remove-service-modal__title">Are you sure?</h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="confirm-payment-request__text-inform">
              This service will be permanently deleted.
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-custom-cancel" data-dismiss="modal" onClick={onClose} style={{ float: "left" }}>
              Back
            </button>
            <button type="submit" className="btn btn-custom-submit" onClick={handleSubmit}>
              Remove Service
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

RemoveServiceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RemoveServiceModal;
