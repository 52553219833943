import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoiceModal from "../InvoiceModal";
import { createInvoice, selectNewInvoiceState } from "./store/slice";

export function NewInvoice(props) {
  const dispatch = useDispatch();
  const [newInvoiceModalVisible, setNewInvoiceModalVisible] = useState(false);

  const onRequestNewInvoiceModalOpen = () => {
    setNewInvoiceModalVisible(true);
  };

  const onRequestNewInvoiceModalClose = useCallback(() => {
    setNewInvoiceModalVisible(false);
  }, []);

  const onSubmit = (data) => {
    dispatch(createInvoice(data));
  };

  const { invoiceCreated, invoice } = useSelector(selectNewInvoiceState);

  useEffect(() => {
    if (invoiceCreated && invoice?.id) {
      window.location.href = `/resident_payments/invoices/${invoice.id}`;
    }
  }, [invoiceCreated, invoice?.id]);

  return (
    <>
      <button className="dome-btn dome-btn-base dome-btn-dark-green pull-right" onClick={onRequestNewInvoiceModalOpen}>
        new invoice
      </button>
      <InvoiceModal open={newInvoiceModalVisible} onClose={onRequestNewInvoiceModalClose} onSubmit={onSubmit} />
    </>
  );
}
