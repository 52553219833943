import React, { Component } from "react"
export default  class UploadConfirmationModal extends Component {
  constructor(props) {
    super(props)

    this.modal = null

    this.setModalRef = element => { this.modal = element }
  }

  componentDidMount() {
    this.open()
  }

  componentWillUnmount() {
    this.close()
  }

  close() {
    $(this.modal).modal('hide')
  }

  open() {
    $(this.modal).modal('show')
  }

  renderUploadModal(){
    return (
      <div className="questionnaire-modal-container">
      
        <p className="center-this-tag">The following questionnaire will be sent to the requested party:</p>

      <div className="questionnaire-modal-center">
        <span>
          <button className="btn btn-danger" onClick={() => this.props.closeConfirmModal()}>Cancel</button>
        </span>

        <div className="divider"/>

        <span>
          <button className="btn btn-success payment-form-purchase-btn" onClick={() => this.props.confirmQuestionnaire()}>Send Questionnaire</button>            
        </span>
      </div>
    </div>
    ) 
  }

  render(){
    return (
      <div className="modal" ref={this.setModalRef}>
        <div className="modal-dialog modal-md questionnaire-modal-dialog">
          <div className="modal-body">
            <h3 className="text-center bopa-text questionnaire-form-header">Confirm and Send Attachments</h3>
                {this.renderUploadModal()}
          </div>
        </div>
      </div>
    )
  }
};
