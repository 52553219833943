import React from 'react'
import { connect } from 'react-redux'
import Store from './Store'

function truncate(str, n){
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

class Item extends React.Component {
  constructor(props) {
    super(props)
  }

  setModalUserDetails() {
    const { roles, buildingResponsibleParty: { id, first_name, last_name, email, phone_number, phone_ext, role_label, is_visible, is_phone_hidden } } = this.props

    this.props.setModalUserDetails({
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      phone_ext: phone_ext,
      title: role_label,
      is_visible: is_visible,
      is_phone_hidden: is_phone_hidden
    })

    this.props.setModalUserRoles(roles)
  }

  handleClickRemove() {
    this.setModalUserDetails()

    if (this.checkIfConfirmationIsNeededBeforeDeleting())
      return this.props.openModalForContext('Remove Role Confirmation And Delete')

    this.removeBuildingResponsibleParty()
  }

  toggleVisiblity() {
    const { buildingResponsibleParty, buildingId } = this.props
    const data = {
      building_responsible_party: { is_visible: !buildingResponsibleParty.is_visible }
    }

    $.ajax({
      url: `/buildings/${buildingId}/update_building_responsible_party/${buildingResponsibleParty.id}`,
      method: 'PATCH',
      dataType: 'json',
      data: data,
      context: this,
      success: res => this.props.updateBuildingResponsibleParty(res.building_responsible_party[0])
    })
  }

  selectedRoleNames() {
    const { roles } = this.props

    if (!roles) return []

    return Object.keys(roles).filter(role => roles[role].selected)
  }

  checkIfConfirmationIsNeededBeforeDeleting() {
    const { roles } = this.props

    if (!roles) return false

    if (roles.package_processor.selected)
      return true

    return false
  }

  removeBuildingResponsibleParty() {
    const { buildingId, buildingResponsibleParty } = this.props

    $.ajax({
      url: `/buildings/${buildingId}/remove_building_responsible_party/${buildingResponsibleParty.id}`,
      method: 'DELETE',
      dataType: 'json',
      context: this,
      success: () => {
        this.props.deleteBuildingResponsibleParty(buildingResponsibleParty.id)
      }
    })
  }

  openEditUserModal() {
    this.setModalUserDetails()
    this.props.openModalForContext('Edit Building Responsible Party')
  }

  renderHiddenContactIcon() {
    const { buildingResponsiblePartyType, buildingResponsibleParty: { is_visible } } = this.props,
          styling = { margin: '0 10px' },
          onClick = () => buildingResponsiblePartyType == 'BoardMembers' ? null : this.toggleVisiblity()
    let iconClasses = 'fa-light '
    iconClasses += buildingResponsiblePartyType == 'BoardMembers' || !is_visible ? 'fa-eye-slash' : 'fa-eye'
    const iconProperties = { className: iconClasses, style: styling, onClick: () => onClick() }

    if (buildingResponsiblePartyType == 'BoardMembers') {
      iconProperties['data-toggle'] = 'tooltip'
      iconProperties['data-placement'] = 'top'
      iconProperties['title'] = 'Board Members cannot be made visible on the public property page'
    }

    return <i {...iconProperties} />
  }

  renderNameAndRole() {
    const { first_name, last_name, role_label } = this.props.buildingResponsibleParty

    const truncatedFullName = truncate(`${first_name} ${last_name}`, 20)

    return <div style={{ maxWidth: 300, display: 'inline-block' }}>
      <div>{truncatedFullName}</div>
      <div>{snakeCaseToCapitalizedWords(role_label)}</div>
    </div>
  }

  renderSortIcon() {
    return <i className='fa-thin fa-grip-dots-vertical' />
  }

  renderEmailAndPhone() {
    const { email, phone_number } = this.props.buildingResponsibleParty,
          truncatedEmail = truncate(email, 20),
          emailTooltipProperties = { 'data-toggle': 'tooltip', 'data-placement': 'top', 'title': email }

    return <div>
      <div {...emailTooltipProperties}>{truncatedEmail}</div>
      <div>{phone_number}</div>
    </div>
  }

  renderRoles() {
    const style = { backgroundColor: '#F9F9F9', padding: 3 },
          rolesList = this.selectedRoleNames().map(role => <div key={role} style={style}>{capitalizeSentence(humanize(role))}</div>)

    return <div>{rolesList}</div>
  }

  renderDeleteIcon() {
    return <i className='fa-light fa-trash-can' onClick={() => this.handleClickRemove()} />
  }

  renderEditIcon() {
    return <i className='fa-light fa-pencil' onClick={() => this.openEditUserModal()} />
  }

  renderRowList() {
    const columnStyling={ display: 'table-cell', verticalAlign: 'middle', padding: '12px 0' },
          list = []

    list.push(<div key='sort' style={{...columnStyling, textAlign: 'center'}}>{this.renderSortIcon()}</div>)
    list.push(<div key='visibility' style={{...columnStyling, textAlign: 'center'}}>{this.renderHiddenContactIcon()}</div>)
    list.push(<div key='name' style={columnStyling}>{this.renderNameAndRole()}</div>)
    list.push(<div key='email' style={columnStyling}>{this.renderEmailAndPhone()}</div>)
    list.push(<div key='roles' style={columnStyling}>{this.renderRoles()}</div>)
    list.push(<div key='edit' style={columnStyling}>{this.renderEditIcon()}</div>)
    list.push(<div key='delete' style={columnStyling}>{this.renderDeleteIcon()}</div>)

    return list
  }

  render() {
    const { buildingResponsibleParty } = this.props
    const styling = {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DBDBDB',
      marginBottom: 10,
      borderRadius: 5,
      boxShadow: '3px 3px 5px #DBDBDB',
      display: 'table-row'
    }
    return <div key={this.props.buildingResponsibleParty.id}
      style={styling}
      data-id={buildingResponsibleParty.id}
      className='brp-item sortable-list-item'>
      {this.renderRowList()}
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buildingResponsiblePartyType: state.main.buildingResponsiblePartyType,
    buildingResponsibleParty: ownProps.buildingResponsibleParty,
    roles: state.main.buildingResponsiblePartyRolesGroupedByEmail ? state.main.buildingResponsiblePartyRolesGroupedByEmail[ownProps.buildingResponsibleParty.email] : null,
    buildingId: state.main.buildingId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModalForContext: context => dispatch(Store.openModalForContext(context)),
    setModalUserDetails: userDetails => dispatch(Store.setModalUserDetails(userDetails)),
    setModalUserRoles: roles => dispatch(Store.setModalUserRoles(roles)),
    deleteBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.deleteBuildingResponsibleParty(buildingResponsibleParty)),
    updateBuildingResponsibleParty: buildingResponsibleParty => dispatch(Store.updateBuildingResponsibleParty(buildingResponsibleParty))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item)
