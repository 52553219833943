// See https://reactcommunity.org/react-modal/#general-usage definition of options

import React, { useState, useEffect } from "react"
import ReactModal from 'react-modal'
import { useEventListeners } from '@deathbyjer/react-event-manager'

const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    minWidth: 400,
    padding: '20px 60px',
    borderRadius: 10
  },
  overlay: {

  }
};

const Modal = props => {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    window.addEventListener(`openModal-${props.modalId}`, openModal)
    window.addEventListener(`closeModal-${props.modalId}`, closeModal)

    return () => {
      window.removeEventListener(`openModal-${props.modalId}`, openModal)
      window.removeEventListener(`closeModal-${props.modalId}`, closeModal)
    }
  }, [])

  const openModal = () => setIsOpen(true)
  const afterOpen = () => typeof (props.afterOpen) == 'function' ? props.afterOpen() : null

  const closeModal = () => setIsOpen(false)
  const afterClose = () => typeof (props.afterClose) == 'function' ? props.afterClose() : null

  useEventListeners({
    [`open-modal-${props.modalId}`]: () => openModal(),
    [`close-modal-${props.modalId}`]: () => closeModal(),
  })

  const header =  <>
    {props.headerContent}
  </>

  const body = <>
    {props.bodyContent}
  </>

  const footer = <>
    {props.footerContent}
  </>

  // https://reactcommunity.org/react-modal/accessibility/
  ReactModal.setAppElement(document.getElementsByTagName('body'))

  styles.content = { ...styles.content, ...props.modalStyles || {} }

  return <div>
    <ReactModal isOpen={modalIsOpen} onAfterOpen={afterOpen} onRequestClose={closeModal} style={styles} overlayClassName='react-modal-overlay'>
      <div>
        {header}
      </div>
      <div>
        {body}
      </div>
      <div>
        {footer}
      </div>
    </ReactModal>
  </div>
}

export default Modal
