import React, { useContext, useState } from 'react'
import { BeaconSliderContext } from './slider'
import eNotaryIcon from '/app/assets/images/e-notary.svg'
import clsx from 'clsx'

const RESOURCES = [
  {
    title: 'insurance',
    description: 'Get an instant quote',
    icon: <i className="fa-light fa-umbrella"></i>,
    link: '/about/insurance',
  },
  {
    title: 'contractor report',
    description: 'Vet your contractor',
    icon: <i className="fa-light fa-screwdriver-wrench"></i>,
    link: '/about/contractorreport',
  },
  {
    title: 'e-notary',
    description: 'Any place, any time',
    icon: <img src={eNotaryIcon} width={16} className="dome-color-green-gradient" />,
    link: '/about/enotary',
  },
  {
    title: 'concierge',
    description: 'White-glove service',
    icon: <i className="fa-light fa-hand-wave"></i>,
    link: '/concierge',
  },
]

const BeaconResourceItem = ({ item, index, isActive, handleNavigation }) => {
  return (
    <li key={item.title}>
      <a
        href={item.link}
        onClick={() => handleNavigation(index)}
        className={clsx('beacon-resources-list-item', isActive ? 'active' : '')}
        target='_blank'
      >
        <div className="beacon-resources-list-item-icon dome-d-flex dome-align-center dome-justify-center">
          <span className="dome-color-green-gradient">{item.icon}</span>
        </div>
        <div>
          <div
            className={isActive ? 'dome-color-darkest-grey dome-text-w500' : 'dome-p2 dome-color-dark-grey'}
          >{`${item.title} >`}</div>
          <div className="dome-p3 dome-color-med-grey">{item.description}</div>
        </div>
      </a>
    </li>
  )
}

export default function BeaconResources() {
  const { prev, next, closeModal } = useContext(BeaconSliderContext)
  const [activeTab, setActiveTab] = useState()
  const handleNavigation = (index) => setActiveTab(index)

  return (
    <div className="beacon-resources">
      <div className="dome-d-flex dome-flex-column dome-gap18 dome-align-center">
        <div className="dome-d-flex dome-flex-column dome-align-center">
          <span className="dome-p2 dome-color-med-grey">before you go...</span>
          <span className="dome-h3 dome-color-green-gradient">check out more resources</span>
        </div>
        <ul className="beacon-resources-list">
          {RESOURCES.map((item, index) => {
            const isActive = activeTab === index
            return <BeaconResourceItem item={item} index={index} isActive={isActive} handleNavigation={handleNavigation}/>
          })}
        </ul>
        <div className="beacon-resources-navigation dome-full-width dome-d-flex dome-justify-between">
          <button
            type="button"
            onClick={prev}
            className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
          >
            back
          </button>
          <button onClick={closeModal} type="button" className="dome-btn btn-base dome-btn-grey-stroke">
            all done
          </button>
        </div>
      </div>
    </div>
  )
}
