import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

export default class QuestionnaireResponseTime extends Component {
  constructor(props) {
    super(props)
  }

  updateResponseTime() {
    const url = `/super/organizations/${this.props.organization.id}/questionnaires/questionnaire_response_time`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({time: this.props.responseTime})
    })
  }

  renderInput() {
    return <input type="text"
                  className="response-time-input"
                  onChange={e => this.props.updateResponseTime(e.target.value)}
                  placeholder={this.props.responseTime} />
  }

  renderSaveResponseTime() {
    return <button type="button" onClick={() => this.updateResponseTime()}>Update Response Time</button>
  }

  render() {
    return <div className="row questionnaire-admin-row">
      <div className="col-sm-4">Questionnaire Response Time</div>
      <div className="col-sm-2">
        {this.renderInput()} days
      </div>
      {this.renderSaveResponseTime()}
    </div>
  }
};
