import React from 'react'
import PropTypes from 'prop-types'

class FetchButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false }
  }

  setLoading(bool) {
    this.setState({ loading: bool })
  }

  approveRefund(skipStripeRefundValidations=false) {
    this.setLoading(true)

    $.ajax({
      url: this.props.approveRefundRequestPath,
      dataType: "JSON",
      method: "POST",
      data: { skip_stripe_refund_validation: skipStripeRefundValidations },
      context: this,
      success: () => {
        window.location.reload()
      }, error: xhr => {
        let error = xhr.responseJSON.error
        if (error == "No refund record found in Stripe") { this.noRefundRecordFoundSwal() }
      }, complete: () => {
        this.setLoading(false)
      }
    })
  }

  noRefundRecordFoundSwal() {
    swal({
      title: "No refund record found in Stripe",
      text: "The requested refund(s) could not be fetched since one or more of the associated charge(s) either does not show a refund date or there are not an equal number of Stripe refund events with refund requests. Please ensure all items have been refunded in Stripe. If so, click continue to notify the requester(s).",
      type: "error",
      showCancelButton: true,
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continue'
    }, isConfirm => {
      if(isConfirm) this.approveRefund(true)
    })
  }

  shouldBtnBeDisabled() {
    return this.state.loading
  }

  render() {
    return (
      <button className="btn btn-warning" onClick={() => this.approveRefund()} disabled={this.shouldBtnBeDisabled()} >
        <i className="fas fa-lg fa-dog"></i> Fetch Refund
      </button>
    )
  }
}

FetchButton.propTypes = {
  approveRefundRequestPath: PropTypes.string.isRequired
}

export default FetchButton
