import React from 'react'

import CollaboratorRow from './collaborator/row'

class CollaboratorsContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      collaborators: this.props.collaborators,
      packageRequirements: this.props.packageRequirements,
      packageHashedId: this.props.packageHashedId,
      visiblePopoverIdx: null
    }
    this.renderUserRows()
    this.toggleSelectedRequirement = this.toggleSelectedRequirement.bind(this)
    this.findUser = this.findUser.bind(this)
    this.getUpdatedPolicy = this.getUpdatedPolicy.bind(this)
    this.toggleAttachAllRequirementPolicies = this.toggleAttachAllRequirementPolicies.bind(this)
    this.updateMessage = this.updateMessage.bind(this)
    this.toggleReadOnly = this.toggleReadOnly.bind(this)
    this.selectAllRequirements=this.selectAllRequirements.bind(this)
    this.deSelectAllRequirements=this.deSelectAllRequirements.bind(this)
    this.resetForManualSelectClick=this.resetForManualSelectClick.bind(this)
    this.createPackageUser = this.createPackageUser.bind(this)
    this.destroyPackageUser = this.destroyPackageUser.bind(this)
    this.updatePackageUser = this.updatePackageUser.bind(this)
    this.updateVisiblePopover = this.updateVisiblePopover.bind(this)
    this.hasAllInformationForAttach = this.hasAllInformationForAttach.bind(this)
  }

  updateVisiblePopover (visiblePopoverIdx) {
    if (this.state.visiblePopoverIdx === visiblePopoverIdx) {
      this.setState({visiblePopoverIdx: null})
    } else {
      this.setState({visiblePopoverIdx})
    }
  }

  createPackageUser (user) {
    const sendData = (data) => {
      const event = new CustomEvent('attachUserEvent', { detail: data})
      window.dispatchEvent(event)
    }
    var params = this.buildParams(user)
   $.ajax({
     url: `/packages/${this.state.packageHashedId}/attach`,
     context: this,
     method: 'POST',
     dataType: 'JSON',
     data: params,
     success: (res) => {
      sendData(res)
       var updatedCollaborator = this.state.collaborators.find((collaborator) => collaborator.idx === res.idx )
       updatedCollaborator.packageUser = res.package_user
       updatedCollaborator.attached = true
       var collaborators = this.state.collaborators.map((collaborator) => {
         return (collaborator.idx === updatedCollaborator.idx ? updatedCollaborator : collaborator)
       })
       this.setState({collaborators: collaborators, visiblePopoverIdx: null})
       swal("Success!", `${updatedCollaborator.userMetadata.full_name} has successfully been added to the ${this.props.tag}`, "success")
     },
     error: (err) => {
       console.log('failed')
     }
   })
  }

  destroyPackageUser (user) {
    const sendData = (data) => {
      const event = new CustomEvent('rescindUserEvent', { detail: data })
      window.dispatchEvent(event)
    }
    var params = {
      user_id: user.packageUser.user_id,
      idx: user.idx,
      id: this.state.packageHashedId
    }
    $.ajax({
      url: `/packages/${this.state.packageHashedId}/rescind`,
      context: this,
      method: 'DELETE',
      dataType: 'JSON',
      data: params,
      success: (res) => {
        sendData(res)
        if (res.has_fees) {
          this.setState({ visiblePopoverIdx: null })
          swal({
            title: 'Cannot Remove Share',
            text: '<p>This deal party has fees assigned to them. If you wish to remove this party from the ' + this.props.tag +', you will need to first Reset fees and then attempt to remove the party. If you require further assistance, please contact <a class="text-bopa" target="_blank" href="https://boardpackager.freshdesk.com/support/tickets/new">info@boardpackager.com</a>.</p>',
            type: "warning",
            closeOnConfirm: true,
            confirmButtonColor: "#F68A41",
            html: true
          })
        } else {
          var removedCollaborator = this.state.collaborators.find((collaborator) => collaborator.idx === res.idx)
          removedCollaborator.packageUser = null
          removedCollaborator.attached = false
          var collaborators = this.state.collaborators.map((collaborator) => {
            return (collaborator.idx === removedCollaborator.idx ? removedCollaborator : collaborator)
          })
          this.setState({ collaborators: collaborators, visiblePopoverIdx: null })
          swal("Success!", `${removedCollaborator.userMetadata.full_name} has successfully been removed from the ${this.props.tag}`, "success")
        }
      },
      error: (err) => {
        console.log('failed')
        console.log(err)
      }
    })
  }

  updatePackageUser (user) {
    var params = {
      user_id: user.packageUser.user_id,
      read_only: user.readOnly,
      attach_all_requirement_policies: user.attachAllRequirementPolicies,
      policy: JSON.stringify(user.updatedPolicy),
      idx: user.idx
    }
    $.ajax({
      url: `/packages/${this.state.packageHashedId}/update_user_policy`,
      method: 'POST',
      dataType: 'json',
      data: params,
      success: (res) => {
        console.log(res)
        var collaborator = this.state.collaborators.find((collaborator) => collaborator.idx === res.idx )
        this.setState({visiblePopoverIdx: null})
        swal("Success!", `${collaborator.userMetadata.full_name} has successfully been updated`, "success")
      },
      error: (err) => {
        console.log(err)
      }
    })
  }


  buildParams (user) {
    var splitName = null
    var firstName = null
    var lastName = null
    var attachAllRequirementPolicies = user.attachAllRequirementPolicies || user.sharingRole == 'broker'

    if (user.userMetadata.full_name) {
      splitName = user.userMetadata.full_name.split(" ")
      lastName = splitName.pop()
      firstName = splitName.join(' ')
    }

    return {
      id: this.state.packageHashedId,
      email: user.userMetadata.email,
      full_name: user.userMetadata.full_name,
      first_name: firstName,
      last_name: lastName,
      sharing_role : user.sharingRole,
      role_label: user.userMetadata.section,
      section: user.userMetadata.section,
      message: user.message,
      attach_all_requirement_policies: attachAllRequirementPolicies,
      read_only: user.readOnly,
      idx: user.idx,
      apply_state: true,
      policy: JSON.stringify(user.updatedPolicy),
    }

  }

  resetForManualSelectClick (user) {
    user.readOnly = false
    user.attachAllRequirementPolicies = false
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  selectAllRequirements (user) {
    var updatedPolicy = user.updatedPolicy.map((requirement) => {
      requirement.visibility = true
      return requirement
    })
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  deSelectAllRequirements (user) {
    var updatedPolicy = user.updatedPolicy.map((requirement) => {
      requirement.visibility = false
      return requirement
    })
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  toggleSelectedRequirement (userIdx, requirementId) {
    let user = this.findUser(userIdx)
    let updatedPolicy = this.getUpdatedPolicy(user, requirementId)
    let collaborators = this.state.collaborators.map ((collaborator) => {
      if (collaborator.idx === userIdx) {
        collaborator.updatedPolicy = updatedPolicy
      }
      return collaborator
    })
    this.setState({collaborators})
  }

  getUpdatedPolicy (user, requirementId) {
    return user.updatedPolicy.map((requirement) => {
      if (requirement.requirementId === requirementId) { requirement.visibility = !requirement.visibility }
      return requirement
    })
  }

  updateMessage (user,message) {
    user.message = message
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  findUser(userIdx) {
    return this.state.collaborators.find((user) => user.idx === userIdx)
  }

  toggleAttachAllRequirementPolicies(user) {
    user.readOnly = false
    user.attachAllRequirementPolicies = !user.attachAllRequirementPolicies
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  toggleReadOnly (user) {
    user.attachAllRequirementPolicies = false
    user.readOnly = !user.readOnly
    var collaborators = this.state.collaborators.map((collaborator) => (collaborator.idx == user.idx ? user : collaborator))
    this.setState({collaborators})
  }

  hasAllInformationForAttach (collaborator) {
    if (!collaborator.userMetadata.full_name || !collaborator.userMetadata.email) return false
    return true
  }

  renderUserRows () {
      return this.state.collaborators.map((collaborator,i) => {
        return this.hasAllInformationForAttach(collaborator) ? <CollaboratorRow
          visiblePopover={this.state.visiblePopover}
          collaborator={collaborator}
          isAlteration={this.props.isAlteration}
          packageRequirements={this.state.packageRequirements}
          toggleSelectedRequirement={this.toggleSelectedRequirement}
          toggleAttachAllRequirementPolicies={this.toggleAttachAllRequirementPolicies}
          toggleReadOnly={this.toggleReadOnly}
          updateMessage={this.updateMessage}
          selectAllRequirements={this.selectAllRequirements}
          deSelectAllRequirements={this.deSelectAllRequirements}
          resetForManualSelectClick={this.resetForManualSelectClick}
          createPackageUser={this.createPackageUser}
          destroyPackageUser={this.destroyPackageUser}
          updatePackageUser={this.updatePackageUser}
          updateVisiblePopover={this.updateVisiblePopover}
          visiblePopoverIdx={this.state.visiblePopoverIdx}
          noRequirements={this.props.noRequirements}
          tag={this.props.tag}
          key={i}
        /> : null
      })
  }

  render () {
    return (<div className='col-xs-12' style={{paddingBottom: '30px', borderBottom:'1px solid'}}>
      {this.renderUserRows()}
    </div>
    )
  }
}

export default CollaboratorsContainer
