import React, { createContext, useEffect, useMemo, useState } from "react"
const ModalContext = createContext()

const ModalContextProvider = props => {
  const [currentModal, setCurrentModal] = useState(null)
  const [modalQueue, setModalQueue] = useState([])

  useEffect(() => {
    if (currentModal === null) {
      setModalQueue(prevQueue => prevQueue.slice(1))
    }
  }, [currentModal])

  useEffect(() => {
    if (modalQueue.length > 0) {
      const modal = modalQueue[0]
      setCurrentModal(modal)
    }
  }, [modalQueue, setCurrentModal])

  const addToModalQueue = (modal) => {
    setModalQueue(prevQueue => [...prevQueue, modal])
  }

  const value = useMemo(() => ({
    currentModal, setCurrentModal, addToModalQueue
  }))

  return (
    <ModalContext.Provider value={value}>
      {props.children}
      {currentModal}
    </ModalContext.Provider>
  )
}

export { ModalContextProvider, ModalContext }
