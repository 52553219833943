import { mapValues } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { addCSRF } from 'lib/utilities'
import useLoader from './hooks/useLoader'
import { updateComponentKeys } from '../features/components/componentSlice'
import { setFormName } from '../features/pages/pageSlice'
import SavingFormBuilderModal from '../modal/SavingModal'

const asPercentage = (v) => (String(v).slice(-1) == '%' ? String(v) : `${v}%`)

const useTransformData = () => {
  const templateData = useSelector(({ data }) => data.data)
  const components = useSelector(({ components }) => components.all)

  return mapValues(templateData, (template, id) => {
    const component = components[template.position]
    return {
      ...template,
      id,
      label: component?.behaviorInputs.label,
      placeholder: component?.behaviorInputs.placeholder,
      position: {
        ...mapValues(component.rect, asPercentage),
        page: parseInt(component.page),
      },
    }
  })
}

const useTransformSignatures = () => {
  const signatureData = useSelector(({ data }) => data.signature)
  const components = useSelector(({ components }) => components.all)

  return mapValues(signatureData, (template, id) => {
    return ({
      ...template,
      id,
      positions: mapValues(template.positions, (componentKey) => {
        return ({
          ...mapValues(components[componentKey]?.rect, asPercentage),
          page: parseInt(components[componentKey]?.page),
        })
      }),
    })
  })
}

const saveForm = async (params) => {
  return await axios.post('form_builder/save_form', addCSRF(params), {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
}

const Header = () => {
  const formName = useSelector(({ pages }) => pages.formName)
  const transformedData = useTransformData()
  const transformedSignatures = useTransformSignatures()
  const { withLoading, loading } = useLoader()
  const dispatch = useDispatch()
  const [openTextField, setOpenTextField] = useState(false)

  const handleSave = useCallback(async () => {
    const response = await withLoading(() =>
      saveForm({ field_positions: transformedData, signatures: transformedSignatures, form_name: formName })
    )
    const { generated_signatures, generated_positions } = response.data

    dispatch(updateComponentKeys({ deltaIds: generated_positions, type: 'data' }))
    dispatch(updateComponentKeys({ deltaIds: generated_signatures, type: 'signature' }))
  }, [transformedSignatures, transformedData, formName, withLoading, dispatch])

  const handleEditClick = () => {
    setOpenTextField(true)
  }

  const saveFormName = useCallback(
    (e) => {
      if (!e.target.value) {
        setOpenTextField(false)
      } else {
        dispatch(setFormName(e.target.value))
        setOpenTextField(false)
      }
    },
    [dispatch]
  )

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        saveFormName(e)
      }
    },
    [saveFormName]
  )

  return (
    <div className="frame-header">
      <div>
        {openTextField ? (
          <div className="inputs input-rounded">
            <input defaultValue={formName} onKeyDown={handleKeyDown} onBlur={saveFormName} />
          </div>
        ) : (
          <>
            <div className="form-name">{formName}</div>
            <div className="edit-btn">
              <button onClick={handleEditClick} className="btn-link">
                <i className="fa-light fa-pencil"></i>
                edit
              </button>
            </div>
          </>
        )}
      </div>
      <div className="save-btns">
        <button type='button' className="btn-base btn-green-stroke" onClick={handleSave}>save</button>
        <SavingFormBuilderModal onSubmit={handleSave} />
      </div>
    </div>
  )
}

export default Header
