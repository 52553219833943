import React from 'react'
import { toLower } from 'lodash-es'

const asTitle = s => s.split('_').filter(x => x).map(x => String(x)).map(x => x[0].toUpperCase() + x.substring(1)).join(" ")

class Category extends React.Component {
  constructor(props) {
    super(props)
  }

  getTitle() {
    return toLower(StoreDocument.TYPES[this.props.category] || asTitle(this.props.category))
  }

  render() {
    return <div className="building-docs-category panel panel-default">
      {this.renderTitle()}
      <div className="panel-body">
        {this.props.children}
      </div>
    </div>

  }

  renderTitle() {
    return <div className="panel-heading">
      <h4 className="panel-title">{this.getTitle()}</h4>
    </div>
  }
}

export default Category
