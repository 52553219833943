import React from "react"

export default function QuoteStatusContent({ quoteId, insuranceType }) {
  return (
    <>
      <div className="sidebar-headline-icon">
        <i className="fa-thin fa-party-horn dome-color-dark-green" style={{ fontSize: "39px" }}></i>
      </div>
      <div className="finalize-quote-status__title">your quote is ready!</div>
      <div className="dome-p2">{insuranceType}</div>
      <div className="dome-p2">{`quote id #${quoteId}`}</div>
    </>
  )
}

QuoteStatusContent.defaultProps = {
  quoteId: '123456',
  insuranceType: 'homeowners',
}
