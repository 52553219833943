import React from 'react'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'

import Thumbnail from '../page/thumbnail'
import { useScrollingTop } from '../utilities'
import { useEventManager } from '@deathbyjer/react-event-manager'

function SignatureLeft(props) {
  const signaturesToSign = useSelector(({form_signatures: state}) => state.signatures_to_sign)

  return <div className='thumbnail-signatures-remaining-count-container'>
    <div className='remaining-count'>{signaturesToSign.left.length}</div>
    <div className='remaining-text'><i className='fa fa-light fa-signature' /> Remaining Signature(s)</div>
  </div>
}

function SignatureMark({ position: {y: top, height}, isSigned}) {
  const style = {
    top, height
  }

  const classes = compact([
    'signature-mark',
    isSigned ? 'is-signed' : null
  ])

  return <div style={style} className={classes.join(" ")}>
    <div className="check-area">
      { isSigned ? <i className="fa-light fa-check"></i> : null }
    </div>
    <div>
      <i className="fa-light fa-signature"></i>
    </div>
  </div>
}

function SignatureMarks({page_id}) {
  const signaturesToSign = useSelector(({form_signatures: state}) => state.signatures_to_sign)

  if (!signaturesToSign)
    return null

  const signatures = signaturesToSign.pages[page_id]
  if (!signatures)
    return null

  return <div className="signature-marks">
    { signatures.map(s => <SignatureMark key={s.id} position={s.position} isSigned={s.isSigned} />)}
  </div>
}

export default function ({ form_id, parentRef, thumbnailHeaderContent }) {
  const events = useEventManager()

  const top = useScrollingTop(parentRef)
  const page_ids = useSelector(state => state.form_pages.ordered_pages)
  const isSigning = useSelector(({form_signatures: state}) => !!state.signing_as)

  const style = {
  //  top: top + "px"
  }

  const clickThumbnail = page_id => events.applyEventListeners(`form:${form_id}:scrollToPage`, page_id)

  return <div className="thumbnails-area" style={style}>
    {isSigning ? <div className='form-thumbnail-header-container'><SignatureLeft /></div> : null}
    <div>
      { page_ids.map(page_id => (
        <Thumbnail id={page_id} key={page_id} onClick={() => clickThumbnail(page_id)}>
          <SignatureMarks page_id={page_id} />
        </Thumbnail>
      ))}
    </div>
  </div>
}
