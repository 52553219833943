import React from 'react'
import { List, Input, Add, useInteractPropertyCodes } from './components'

/**
 * A Component to view and update property codes
 *
 * @param {string[]} initialValues Initial values of property codes
 * @param {string} htmlOutputElementId Id of the element storing updated values
 * */
export default function FormPropertyCodes({ initialValues, htmlOutputElementId }) {
  const { hasValues, propertyCodes, currentPropertyCode, onListItemRemove, onInputChange, onSubmit } =
    useInteractPropertyCodes(initialValues, htmlOutputElementId)

  const listClassNames = ['values__wrapper']
  if (!hasValues) listClassNames.push('no-items')

  return (
    <div className="property-codes-input__wrapper">
      <div className={listClassNames.join(' ')}>
        <List items={propertyCodes} onRemove={onListItemRemove} />
      </div>
      <div className="input__wrapper">
        <Input value={currentPropertyCode} onChange={onInputChange} onSubmit={onSubmit} />
        <Add onSubmit={onSubmit} />
      </div>
    </div>
  )
}
