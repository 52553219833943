import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { compact, last } from 'lodash'

import { offsetFromParent } from 'components/utility/event'

export function withSelector(method) {
  return { type: 'selector', method }
}

export function useReadApi(api, id) {
  const reader = api.read

  switch(typeof reader) {
    case 'undefined':
      return null
    case 'function':
      return reader(id)
    case 'object':
      const { method } = object
      if (!method)
        return null

      return object.type == "selector" ? useSelector(state => method(state, id)) : method(state, id)
  }

  return reader
}


const ensureObject = obj => typeof(obj) == 'object' ? obj : {}
const EmptyComponent = props => <div key={props.key}></div>

const DefaultApi = {
  create(data, id = null) {},
  read(id) {},
  update(id, data) {},
  destroy(id) {}
}

/*
 * In order for a component to be dragged and dropped, it needs
 * to interact with the DrawArea in a particular way. This custom
 * hook automatically builds up the required components for the container
 * DOM element.
 *
 */

export function useDraggable({id, draggable, classes}) {
  const [isDragging, setDragging] = useState(false)

  if (draggable)
    classes.push('draw-area-droppable')
  if (isDragging)
    classes.push("is-dragging")

  const onDragStart = evt => {
    const offset = offsetFromParent(evt)
    const rect = evt.target.getBoundingClientRect()

    evt.dataTransfer.setData("component-id", id.join(","))
    evt.dataTransfer.setData("starting-offset-x", offset.x)
    evt.dataTransfer.setData("starting-offset-y", offset.y)
    evt.dataTransfer.setData("component-width", rect.width)
    evt.dataTransfer.setData("component-height", rect.height)
  }
  const onDrag = _ => setDragging(true)
  const onDragEnd = _ => setDragging(false)

  return {
    draggable,
    onDragStart,
    onDrag,
    onDragEnd
  }
}

/*
 * Generate a toolbar for use in the toolGenerator
 *
 * arguments:
 *    component: this is the React component that is rendered as the toolbar
 *
 *    methods: a hash of methods used for this toolbar
 *      showForSelected - given a list of component ids, it returns a boolean value of whether we should
 *                        show this toolbar or not
 */

export function createToolbar(component, methods = {}) {
  if (typeof methods.showForSelected != "function")
    methods.showForSelected = (selectedComponents) => false

  return { ...methods, ...{ toolbar: component }}
}

/*
 *   Use this function to piece a tool together
 *   toolOpts:
 *      components - these are the components that get rendered onto the page. This object
 *                   takes the form of key: React Component, where the key, combined with the
 *                   tool's name combine to form a unique component identifier
 *                    signature/signature, signature/time, highlight/highlight
 *
 *      toolbars - these are toolbars that are rendered for the component. They allow the user to
 *                  edit aspects about the component, like a highlight's color.
 *
 *
 *   methods:
 *      The following list are methods that can be called to affect how the toolbars work
 *
 *      componentPropsFor - the props to be fed into the component. Defaults to just "props", but
 *                          can be overloaded to include extra parameters (see signature.js for example)
 *      isId - ids for tools are generally kept as arrays. the isId method lets a tool decide if the id for
 *             the component represents this particular tool
 *
 *      doCommand - Commands are captured by the page and can be sent to tools for processing. This method
 *                  takes the command run and the id of the selected element(s) and allows the tool to execute
 *                  an action
 *
 *  return: a function that can be executed to generate the tool. This function can take two arguments
 *      name (arg[0]) - This is the name of the tool.
 *      api (arg[1]) - This is an API (an extension of the DefaultApi earlier in this field) that allows whatever
 *                     React Component is rendering the tool to control the CRUD of the tool.
 *
 */
export function toolGenerator(toolOpts, methods = {}) {
  toolOpts.components ||= {}
  toolOpts.toolbars ||= {}

  methods.componentPropsFor ||= ({component, allSelected, props}) => props

  methods.isId ||= (id) => false
  methods.doCommand ||= (command, fullId) => null

  return function({name, api}) {
    api = { ...DefaultApi, ...ensureObject(api) }

    const supportsComponent = componentName => {
      const [parent, child] = componentName.split("/")
      if (!child && !name)
        return true

      if (parent == name)
        return true

      return false
    }

    const getLocalName = componentName => {
      const [parent, child] = componentName.split("/")
      if (!name && !child)
        return parent

      return child
    }

    const namesForProps = componentName => ({
      _toolName: name,
      _toolComponentLocalName: getLocalName(componentName),
      _toolComponentName: componentName
    })

    return {
      api,

      runCommand(command, fullId) {
        if (methods.isId(fullId))
          methods.doCommand({command, api, id: fullId})
      },

      generateComponent(component, props = {}, options = {}) {
        if (!supportsComponent(component))
          return false

        const componentName = getLocalName(component)
        const Component = toolOpts.components[componentName] || (options.allow_null ? null : EmptyComponent)

        const componentProps = methods.componentPropsFor({
          component: componentName,
          props: { ...props, ...namesForProps(component), ...{ api }},
          allSelected: options.allSelected
        })

        return Component ? <Component { ...componentProps } /> : null
      },

      gatherToolbarsForSelected(componentIds) {
        let {toolbars} = toolOpts
        toolbars = Object.entries(toolbars)
        toolbars = toolbars.filter(([_, toolbar]) => toolbar.showForSelected(componentIds))

        const props = { componentIds, api}
        return toolbars.map(([key, {toolbar: Component}]) => <Component {...props} key={`${name}/${key}`} />)
      },

      tryDroppingItem(componentId, point) {
        if (typeof methods.onItemDropped == "function")
          methods.onItemDropped(api, {componentId, point})
      }
    }
  }
}

/*
 * Merge tools together and run commands across all of them
 *
 * arguments:
 *     tools (arg[0]) - This is an array of tools, generated from the toolGenerator function above
 *
 * return: an object with the following keys
 *    apis - a list of all the tools' apis
 *    runCommand - a function that takes a command and the id of component and queries the tools to
 *                 run it
 *
 *    generateComponent - a function
 *
 */
export function mergeTools(tools = []) {
  return {
    apis: tools.map(tool => tool.api),

    runCommand(command, fullId) {
      return compact(tools.map(tool => tool.runCommand(command, fullId)))
    },

    generateComponent(component, props = {}, options = {}) {
      const opts = { ...options, allow_null: true }
      const components = compact(tools.map(tool => tool.generateComponent(component, props, opts)))
      return components[0] || <EmptyComponent  />
    },

    gatherToolbarsForSelected(componentIds) {
      return tools.map(tool => tool.gatherToolbarsForSelected(componentIds)).flat()
    },

    tryDroppingItem(componentId, point) {
      const tryDrop = tool => tool.tryDroppingItem(componentId, point)
      return tools.map(tryDrop)
    }
  }
}

function useSelectedComponents() {
  const [selected, setSelected] = useState([])

  return [
    selected,
    // Add
    s => {
      setSelected(selected.includes(s) ? selected : selected.concat([s]))
    },
    // Remove
    s => {
      setSelected(selected.filter(item => s != item))
    }
  ]
}


export function ComponentsArea({tools, components, onEmptyMousedown, onUpdatedSelected}) {
  const [selected, addSelected, removeSelected] = useSelectedComponents()

  const onDrop = (componentId, rect) => {
    tools.tryDroppingItem(componentId, rect)
  }

  const drawProps = {
    onEmptyMousedown,
    onUpdatedSelected,
    onDrop
  }

  return <DrawArea {...drawProps}>
    { components }
  </DrawArea>
}
