import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { castArray, compact, isPlainObject } from 'lodash-es'

import { compactHash } from 'lib/utilities'

import ConditionalField from 'components/form_field/conditional_field'
import Switch from 'components/utility/switch'
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { useSelector, Provider, useDispatch } from 'react-redux'

function formatDefaultValues(values) {
  const output = {
    homeowners: [],
    renters: [],
  }

  if (!isPlainObject(values)) values = {}

  for (let type in output) {
    if (!values[type]) continue

    output[type] = compact(castArray(values[type]))
  }

  return output
}

const initialState = {
  fields: {},
  values: {},
  opened: {},
}

const slice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    load(state, { payload: { fields, values } }) {
      state.fields = {
        homeowners: fields['homeowners_details']?.fields || {},
        renters: fields['renters_details']?.fields || {},
      }

      state.values = formatDefaultValues({
        homeowners: values['homeowners_details'] || [],
        renters: values['renters_details'] || [],
      })
    },

    openType(state, { payload: { type, enable } }) {
      if (!enable) state.values[type] = []
      else if (state.values[type].length == 0) state.values[type] = [{}]
    },

    updateConditions(state, { payload: { type, index, values } }) {
      for (let i = state.values[type].length; i < index; i++) state.values[type].push({})

      state.values[type][index] = values
    },
  },
})

const store = configureStore({
  reducer: slice.reducer,
})

const { load: loadData, openType, updateConditions } = slice.actions

const FIELDS = [
  { id: 'personal_liability' },
  { id: 'contents_coverage' },
  { id: 'broad_comprehensive_coverage' },
  { id: 'betterments_additions_alterations' },
  { id: 'umbrella_policy' },
]

function InsuranceType({ display, type }) {
  const opened = useSelector((state) => state.values[type].length > 0)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (enable) => {
      dispatch(openType({ type, enable }))
    },
    [dispatch, type]
  )

  return (
    <div className="insurance-type">
      <div>{display}</div>
      <div>
        <Switch onText="required" offText="optional" on={opened} onChange={handleChange} />
      </div>
    </div>
  )
}

function InsuranceDetails({ type, index }) {
  const dispatch = useDispatch()
  const values = useSelector((state) => state.values[type][index])
  const fields = useSelector((state) => state.fields[type])

  const handleChange = useCallback(
    (hash) => {
      dispatch(updateConditions({ type, index, values: compactHash({ ...values, ...hash }) }))
    },
    [dispatch, type, index, values]
  )

  const defaultProps = useMemo(() => ({
    values,
    onChange: handleChange,
  }))

  return (
    <div className="insurance-conditions">
      {FIELDS.map((field) => (
        <ConditionalField key={field.id} field={fields[field.id]} {...field} {...defaultProps} />
      ))}
    </div>
  )
}

function AsInputs() {
  const values = useSelector((state) => state.values)

  return (
    <>
      <input form="update-building-form" type="hidden" name="insurance" value={JSON.stringify(values)} />
    </>
  )
}

function Insurance() {
  const values = useSelector((state) => state.values)

  return (
    <div className="buildings-edit-insurance-component">
      <AsInputs />
      <div className="insurance-type-and-conditions">
        <InsuranceType type="homeowners" display="homeowner's insurance" />
        {values.homeowners.map((_, index) => (
          <InsuranceDetails key={index} type="homeowners" index={index} />
        ))}
        <InsuranceType type="renters" display="renter's insurance" />
        {values.renters.map((_, index) => (
          <InsuranceDetails key={index} type="renters" index={index} />
        ))}
      </div>
    </div>
  )
}

function Loader({ fields, values = {} }) {
  const [loaded, setLoaded] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadData({ fields, values }))
    setLoaded(true)
  }, [dispatch, setLoaded, fields, values])

  return loaded ? <Insurance /> : null
}

export default (props) => (
  <Provider store={store}>
    <Loader {...props} />
  </Provider>
)
