import React, { memo } from "react"
import ReactTooltip from "react-tooltip"

function InsuranceTooltip({ id, header, content, footer }) {
  return (
    <>
      <ReactTooltip id={id} place="bottom" effect="solid" type="light" className="insurance-tooltip">
        <div className="dome-p2 dome-text-w400">{header}</div>
        <div className="dome-p3 dome-text-w300">{content}</div>
        {footer && <div className="dome-p3 dome-text-w400 dome-color-dark-green font-italic">{footer}</div>}
      </ReactTooltip>
    </>
  )
}

export default memo(InsuranceTooltip)
