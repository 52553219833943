import React from 'react'
import $ from 'jquery'

import SelectModeCheckbox from './select_mode_checkbox'
import AutoCalculationField from './field'
import AutoCalculationToolInstructions from './tool_instructions'
import SaveAutoCalculationsButton from './save_button'
import OperatorDropdown from './operator_dropdown'

class AutoCalculationTool extends React.Component {
  static defaultProps = {
    fieldData: {
      operator: '',
      terms: []
    }
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    $('.input-selected').removeClass('input-selected');
    if (this.props.selectedFieldElementName != '') {
      var selectedInput = document.getElementsByName(this.props.selectedFieldElementName)[0];
      selectedInput.className += ' input-selected';
    }

    $('.terms-highlighted').removeClass('terms-highlighted')
    if(this.props.fieldData.terms.length > 0) {
      for (let i = 0; i < this.props.fieldData.terms.length; i++) {
        let term = document.getElementsByName(this.props.fieldData.terms[i])[0]
        term.className += ' terms-highlighted'
      }
    }
  }

  render () {
    const { selectedFieldElementName, handleUpdateSelectingFieldElement, selectingFieldElement, selectingTerms, handleUpdateSelectingTerms, handleUpdateField, fieldData, handleSave, savePath } = this.props;
    let checkboxList = []
    let checkboxModes = ['select_autocalculate_element', 'select_terms']
    for (let i = 0; i < checkboxModes.length; i++) {
      checkboxList.push(
        <SelectModeCheckbox
          key={'checkbox_'+i}
          mode={checkboxModes[i]}
          selectingFieldElement={selectingFieldElement}
          selectingTerms={selectingTerms}
          handleUpdateSelectingFieldElement={handleUpdateSelectingFieldElement}
          handleUpdateSelectingTerms={handleUpdateSelectingTerms}
          selectedFieldElementName={selectedFieldElementName} />
      )
    }

    let fieldsList = []
    fieldsList.push(
      <AutoCalculationField key='term_0' label='Selected field' value={selectedFieldElementName ? '______________________' : selectedFieldElementName} />
    )
    for (let i = 0; i < fieldData.terms.length; i++) {
      fieldsList.push(
        <AutoCalculationField key={'term_'+i+1} label={'Term '+i} value={fieldData.terms[i]} />
      )
    }
    return (
      <div className='col-md-6'>
        <SaveAutoCalculationsButton handleSave={handleSave} savePath={savePath} />
        <div className='div-table' id='auto_calc_col'>
          <div className='div-table-row form-group'>
            <div className='div-table-col'>
              <OperatorDropdown
                selectedFieldElementName={selectedFieldElementName}
                handleUpdateField={handleUpdateField}
                fieldData={fieldData} />
            </div>
            <div className='div-table-col'>
              <span>Operator</span>
            </div>
          </div>
          {checkboxList}
          {fieldsList}
        </div>
        <AutoCalculationToolInstructions />
      </div>
    );
  }
}

export default AutoCalculationTool