import React, { useState, useEffect, useCallback } from 'react'

import { fastHash } from 'lib/utilities'
import { findAppropriateSize } from 'lib/utilities/dom'
import MD5 from "crypto-js/md5";

const serializeWatching = watching => watching.map(w => String(w)).join("|")
const hashWatching = watching => fastHash(serializeWatching(watching))

export function calculateChecksum(arrOfVals) {
  return MD5(JSON.stringify(arrOfVals)).toString()
}

const checkWorking = ({isLocked, working, nextWorking}) => !isLocked && working != nextWorking

function calculateNextWorking(isChecksum, watching) {
  return isChecksum ? calculateChecksum(watching) : hashWatching(watching)
}

// defaultsize is passed from form, fontsize is passed from form instance
// watching is values we're watching
export function useFindFontSize(container, { defaultSize = 100, fontSize, minFont = 7, defaultChecksum, ratio = 1, locked = false, onResize, oneLine, checksumOverridesLock = false } = {}, watching = []) {

  const [size, setSize] = useState(fontSize)
  const isChecksum = !!defaultChecksum
  const isLocked = isChecksum ? locked && !checksumOverridesLock : locked

  const [ working, setWorking ] = useState(isChecksum ? defaultChecksum : null)
  const [ calculating, setCalculating ] = useState(!isLocked && working != calculateNextWorking(isChecksum, watching))

  const setUpdatedSize = useCallback((size, working) => {
    setCalculating(false)
    setSize(size)
    onResize ? onResize({ size, checksum: isChecksum ? working : null }) : null
  }, [isChecksum, onResize, setSize, setCalculating, setSize])

  useEffect(() => {
    if (isLocked || ratio == 0)
      return

    const nextWorking = calculateNextWorking(isChecksum, watching)
    if (working == nextWorking)
      return

    setWorking(nextWorking)
    setCalculating(true)
    findAppropriateSize(container.current, { defaultSize, minFont, ratio, oneLine }).then(size => setUpdatedSize(size, nextWorking))
  }, [isLocked, ratio, working, ...watching]) // We really only want to call this is the items we are watching change (or the element did)

  return { isWorking: calculating, fontSize: size * ratio, originalSize: size, checksum: working }
}
