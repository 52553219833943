
import React, { useCallback } from "react"

const MIN_SIZES =  {
  width: 0.5,
  height: 0.5,
  area: 1
}

export function useGenerateComponent(tools, selectedComponents, { generateKey }) {
  return useCallback(({type, id, props}) => {
    const key = generateKey ? generateKey(type, id) : [type, id].join("#")
    const opts = {
      allSelected: selectedComponents
    }
    
    return tools.generateComponent(type, { key, ...props }, opts)
  }, [ tools, selectedComponents ])
}


export function isMinimumSize(rect, { lockedHeight, lockedWidth } = {}) {
  if (!lockedHeight && rect.height < MIN_SIZES.height)
    return false

  if (!lockedWidth && rect.width < MIN_SIZES.width)
    return false

  if (!lockedWidth && !lockedHeight && rect.width * rect.height < MIN_SIZES.area)
    return false

  return true
}