import { configureStore } from '@reduxjs/toolkit'
import { listenerMiddleware } from 'components/global/listener'
import { combineReducers } from 'redux'

export const [store, injectReducers] = (() => {
  let reducers = {
    blank: (state = null, _action) => state, // TODO: Handle global states here
  }

  const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
    devTools: process.env.NODE_ENV === 'development',
  })

  const injectReducers = (newReducers) => {
    let shouldInject = false
    const currentKeys = Object.keys(reducers)

    for (const key in newReducers) {
      if (!currentKeys.includes(key)) {
        shouldInject = true
        break
      }
    }

    if (shouldInject) {
      Object.assign(reducers, { ...newReducers, ...reducers }) // We should not overwrite current reducers
      store.replaceReducer(combineReducers(reducers))
    }
  }

  return [store, injectReducers]
})()
