import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBuildingEditResidentPayments, toggleResidentPayments } from "../store/slice";

export default function BuildingEditEnableResidentPayments() {
  const dispatch = useDispatch();

  const { building } = useSelector(selectBuildingEditResidentPayments);

  const handleToggleResidentPayments = () => dispatch(toggleResidentPayments());

  if (!building) return null;

  return (
    <div className="row block building-edit-resident-payments-input-container">
      <div className="col-xs-4 col-md-2">
        <label className="building-edit-resident-payments-input-container__label">Make this service live?</label>
      </div>
      <div className="col-xs-8 col-md-10">
        <input
          type="checkbox"
          style={{ display: "inline-block" }}
          checked={building.enable_resident_payments || false}
          onChange={handleToggleResidentPayments}
        />
      </div>
    </div>
  );
}
