import React, { Component } from "react"

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}
export default class EnableUploadRequired extends Component {
  changeValue(upload_required) {
    $(".questionnaire-administration-generic-preview")[upload_required ? "addClass" : "removeClass"]("hidden")

    const url = `/super/organizations/${this.props.organization.id}/questionnaires/enable_upload_required`

    $.ajax({
      url: url,
      type: "post",
      data: addMeta({upload_required: upload_required ? 1 : 0}),
      success: res => { this.props.updateUploadRequired(upload_required) }
    })
  }

  renderChoices() {
    return <select defaultValue={this.props.uploadRequired ? "1" : "0"} onChange={e => this.changeValue(parseInt(e.target.value) == 1)}>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  }

  render() {
    return <div className="row">
      <div className="col-sm-4">Custom Questionnaire Required?</div>
      <div className="col-sm-4">
        {this.renderChoices()}
      </div>
    </div>
  }
};