import React from 'react'

export default class Activator extends React.Component {
  changeValue(enabled) {
    $(".rentals-administration")[enabled ? "removeClass" : "addClass"]("hidden")

    const url = `/super/organizations/${this.props.organization_id}/toggle_rentals`
    $.ajax({
      url: url,
      type: "post",
      data: this.addMeta({ enable: enabled ? 1 : 0 })
    })
  }

  addMeta(data) {
    data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
    return data
  }

  renderChoices() {
    return <select defaultValue={this.props.enabled ? "1" : "0"} onChange={e => this.changeValue(parseInt(e.target.value) == 1)}>
      <option value="1">Yes</option>
      <option value="0">No</option>
    </select>
  }

  render() {
    return <div>
      <div className="col-sm-9">
        <h4>
          Rentals Enabled?
        </h4>
      </div>
      <div className="col-sm-3">
        <h4>
          {this.renderChoices()}
        </h4>
      </div>
    </div>
  }
}
