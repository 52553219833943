import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateStructureApi } from "./apis";

const initialState = {
  structureEditModalVisible: false,
  building: null,
  structure: null,
  structureUpdated: false,
  structureUpdating: false,
  errorMessages: [],
  structureUpdatedModalVisible: false,
};

export const updateStructure = createAsyncThunk("buildingStructuresEditStructure/updateStructure", async (data, thunkAPI) => {
  try {
    const response = await updateStructureApi(data.building_id, data.id, data);
    const { building_structure } = response.data;
    return { building_structure };
  } catch (err) {
    if (err?.response?.data) {
      return thunkAPI.rejectWithValue(err.response.data);
    } else {
      throw err;
    }
  }
});

const structureEditSlice = createSlice({
  name: "buildingStructuresEditStructure",
  initialState,
  reducers: {
    openStructureEditModal: (state, action) => {
      if (action.payload) {
        const { building, building_structure } = action.payload;

        state.building = building;
        state.structure = building_structure;
      }

      state.structureEditModalVisible = true;
    },
    closeStructureEditModal: (state, action) => {
      state.structureEditModalVisible = false;
      state.errorMessages = [];
    },
    openStructureUpdatedModal: (state, action) => {
      state.structureUpdatedModalVisible = true;
    },
    closeStructureUpdatedModal: (state, action) => {
      state.structureUpdatedModalVisible = false;
      state.structureUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateStructure.pending, (state, action) => {
      state.structureUpdating = true;
    });
    builder.addCase(updateStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload;

      state.structure = building_structure;
      state.structureUpdating = false;
      state.structureUpdated = true;
    });
    builder.addCase(updateStructure.rejected, (state, action) => {
      if (action.payload?.error_messages) {
        const { error_messages } = action.payload;
        state.errorMessages = error_messages;
      } else {
        state.errorMessages = ["Some errors happened, please try again!"];
      }
      state.structureUpdating = false;
    });
  },
});

export const selectStructureEditState = (state) => state.buildingStructuresEditStructure;

export const {
  setInitialState,
  openStructureEditModal,
  closeStructureEditModal,
  openStructureUpdatedModal,
  closeStructureUpdatedModal,
} = structureEditSlice.actions;

export const buildingStructuresEditStructureReducer = structureEditSlice.reducer;
