import React from 'react'
import PropTypes from 'prop-types'

import AutoCalculationForm from './form'
import AutoCalculationTool from './tool'

class AutoCalculation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: this.props.fieldsPayload,
      selectedFieldElementName: '',
      selectingFieldElement: false,
      selectingTerms: false,
    }
    this.handleUpdateSelectedFieldName = this.handleUpdateSelectedFieldName.bind(this)
    this.handleUpdateSelectingFieldElement = this.handleUpdateSelectingFieldElement.bind(this)
    this.handleUpdateSelectingTerms = this.handleUpdateSelectingTerms.bind(this)
    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleUpdateSelectedFieldName(selectedFieldElementName) {
    if (selectedFieldElementName == this.state.selectedFieldElementName) {
      this.setState({selectedFieldElementName: ''})
    } else {
      this.setState({selectedFieldElementName})
    }
  }

  handleUpdateSelectingTerms(selectingTerms) {
    let newState;
    if (selectingTerms && this.state.selectingFieldElement) {
      newState = {selectingTerms: selectingTerms, selectingFieldElement: false}
    } else {
      newState = {selectingTerms: selectingTerms}
    }
    this.setState(newState)
  }

  handleUpdateField(field) {
    let newFields = this.state.fields;
    newFields[this.state.selectedFieldElementName] = field;
    this.setState({fields: newFields});
  }

  handleUpdateSelectingFieldElement(selectingFieldElement) {
    let newState;
    if (selectingFieldElement && this.state.selectingTerms) {
      newState = {selectingFieldElement: selectingFieldElement, selectingTerms: false}
    } else {
      newState = {selectingFieldElement: selectingFieldElement}
    }
    this.setState(newState)
  }

  handleSave() {
    let data = {}
    let fields = this.state.fields
    for (let key in fields) {
      if(fields[key].terms.length > 0 && fields[key].operator) {
        data[key] = fields[key]
      }
    }

    $.ajax({
      url: this.props.savePath,
      method: 'post',
      data: jQuery.param(fields),
      context: this,
      success: function(res) {
        window.location = res.redirect_path
      }
    })
  }


  render () {
    const { form_html, form_css, savePath } = this.props;
    const { fields, selectedFieldElementName, selectingTerms, selectingFieldElement } = this.state;
    return (
      <div>
        <AutoCalculationForm
          formHtml={form_html}
          formCss={form_css}
          handleUpdateSelectedFieldName={this.handleUpdateSelectedFieldName}
          selectingTerms={selectingTerms}
          handleUpdateSelectingTerms={this.handleUpdateSelectingTerms}
          selectingFieldElement={selectingFieldElement}
          fieldData={fields[selectedFieldElementName]}
          handleUpdateField={this.handleUpdateField} />
        <AutoCalculationTool
          selectedFieldElementName={selectedFieldElementName}
          handleUpdateSelectingFieldElement={this.handleUpdateSelectingFieldElement}
          selectingFieldElement={selectingFieldElement}
          selectingTerms={selectingTerms}
          fieldData={fields[selectedFieldElementName]}
          handleUpdateSelectingTerms={this.handleUpdateSelectingTerms}
          handleUpdateField={this.handleUpdateField}
          handleSave={this.handleSave}
          savePath={savePath} />
      </div>
    );
  }
}

AutoCalculation.propTypes = {
  form_html: PropTypes.string.isRequired,
  form_css: PropTypes.string
}


export default AutoCalculation