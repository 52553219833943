import React, { useEffect, useState } from 'react'
import { capitalize } from 'lodash-es'
import ImportModal from './ImportModal'
import BuildingUnitImportSuccessModal from './ImportModal/components/BuildingUnitImportSuccessModal'
import { useSelector } from 'react-redux'
import {
  importBuildingUnitModalState,
  resetErrorMessages,
  setBuildingUnitImportSuccessModalVisible,
} from './ImportModal/store/slice'
import { useDispatch } from 'react-redux'

const BuildingUnitTab = ({ tab, active, onClick, enabled }) => {
  const classNames = ['tab', 'dome-user-select-none']

  if (active) classNames.push('active')
  if (!enabled) classNames.push('disabled')

  return (
    <li className={classNames.join(' ')} onClick={onClick}>
      <span>{capitalize(tab)}</span>
    </li>
  )
}

const BuildingUnitsTabs = ({ tabs, activeTab, enabledTabs, setActiveTab }) => {
  return (
    <ul className="building-units-tabs">
      {tabs.map((tab) => {
        const enabled = enabledTabs.includes(tab)

        const onClick = () => {
          if (enabled) setActiveTab(tab)
        }

        return <BuildingUnitTab key={tab} tab={tab} active={tab === activeTab} onClick={onClick} enabled={enabled} />
      })}
    </ul>
  )
}

const BuildingUnitsHeader = ({
  structureId,
  tabs,
  activeTab,
  enabledTabs,
  setActiveTab,
  defaultSearchQuery,
  onSearch,
  address,
}) => {
  const dispatch = useDispatch()
  const { totalBuildingUnitsImported, buildingUnitImportSuccessModalVisible } =
    useSelector(importBuildingUnitModalState)

  useEffect(() => {
    if (buildingUnitImportSuccessModalVisible) setImportModalVisible(false)
  }, [buildingUnitImportSuccessModalVisible])

  const handleSearchSubmit = (e) => {
    e.preventDefault()

    const query = Object.fromEntries(new FormData(e.currentTarget))['q']
    onSearch(query)
  }

  const [importModalVisible, setImportModalVisible] = useState(false)
  const openImportModal = () => setImportModalVisible(true)
  const onCloseImportModal = () => {
    setImportModalVisible(false)
    dispatch(resetErrorMessages())
  }
  const onBuildingUnitImportSuccessModalClose = () => {
    dispatch(setBuildingUnitImportSuccessModalVisible(false))
  }

  return (
    <div className="building-units-header">
      <BuildingUnitsTabs tabs={tabs} activeTab={activeTab} enabledTabs={enabledTabs} setActiveTab={setActiveTab} />
      <form onSubmit={handleSearchSubmit}>
        <span className="dome-input-search">
          <input type="search" id="units-search" name="q" defaultValue={defaultSearchQuery} />
          <button type="submit">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </span>
      </form>
      <button className="dome-btn dome-btn-base dome-btn-grey-stroke" onClick={openImportModal}>
        <i className="fa-light fa-plus" /> import
      </button>
      <a
        className="dome-link dome-is-button dome-link-dark-green"
        href={`/building_structures/${structureId}/building_units/new`}
      >
        <i className="fa-light fa-plus" /> new unit
      </a>
      <ImportModal open={importModalVisible} onClose={onCloseImportModal} address={address} />
      <BuildingUnitImportSuccessModal
        open={buildingUnitImportSuccessModalVisible}
        onClose={onBuildingUnitImportSuccessModalClose}
        onSubmit={onBuildingUnitImportSuccessModalClose}
        totalBuildingUnitsImported={totalBuildingUnitsImported}
      />
    </div>
  )
}

export default BuildingUnitsHeader
