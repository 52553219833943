import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createInvoiceApi } from "./apis";

const initialState = {
  invoice: null,
  invoiceCreated: false,
};

export const createInvoice = createAsyncThunk("residentPaymentsNewInvoice/createInvoice", async (data) => {
  const response = await createInvoiceApi(data);
  const { invoice } = response.data;
  return { invoice };
});

const newInvoiceSlice = createSlice({
  name: "residentPaymentsNewInvoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      const { invoice } = action.payload;

      state.invoice = invoice;
      state.invoiceCreated = true;
    });
  },
});

export const selectNewInvoiceState = (state) => state.residentPaymentsNewInvoice;

export const { setInitialState } = newInvoiceSlice.actions;

export const residentPaymentsNewInvoiceReducer = newInvoiceSlice.reducer;
