import React from 'react'
import $ from 'jquery'
import { debounce } from 'lodash'

import DateRows from './date_rows'
import MarkAllButton from './mark_all_button'
import RolloverNote from 'components/buttons/rollover_note'

class NotificationsIndex extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			notificationRows: {},
			page: 0,
			moreDays: true,
			loaded: false,
			processing: false,
      afterDate: null,
			allNotifications: []
		}
	}

	componentDidMount() {

		this.fetchAllNotifications();
		this.fetchNotifications();
		window.addEventListener('scroll', debounce(() => this.infiniteScrollCall(), 20) );
	}

  fetchNotifications() {
		if (this.state.processing === false && this.state.moreDays === true){
			this.setState({
				processing: true
			})
			$.getJSON(
					this.props.notificationsPath,
					{
						after_date: this.state.afterDate
					},
					function(data){
						if (data.notification_rows.length === 0){
							this.setState({
								moreDays: false,
								processing: false
							})
						} else {
							var obj = this.notificationRowToObjectWithDateKey(data)
							var afterDate = this.latestDateFromRequest(obj);
							this.setState({
								notificationRows: $.extend(this.state.notificationRows,obj),
								page: this.state.page + 1,
								processing: false,
								afterDate: afterDate
							});
						}
					}.bind(this)
			);
		}
  }

  fetchSpecificDate(dateKey) {
    $.getJSON(
			this.props.dayUnreadPath,
			{
				date_key: dateKey
			}, function(data){
				if (data["time"]){
					var dateKey = new Date(Number(data["time"])).toString()
					var newNotificationRows = this.state.notificationRows
					delete newNotificationRows[dateKey]
					this.setState({
						notificationRows: newNotificationRows
					})
				} else {
					var obj = this.notificationRowToObjectWithDateKey(data)
					this.setState({
						notificationRows: $.extend(this.state.notificationRows,obj)
					});
				}
			}.bind(this)
		)
  }

	fetchAllNotifications() {
		$.getJSON(this.props.unreadNotificationsPath,
			function(data){
				this.setState({
					allNotifications: data.notifications
				})
			}.bind(this)
		)
	}

	handleAllReadClick() {
    var allNotificationIds = this.state.allNotifications.map(function(notification){
      return notification.id
    })
    $.ajax({
      url: 'notifications/read',
      type: 'POST',
      dataType: "json",
      data: {checked_ids: allNotificationIds },
      success: function(data){
        location.reload();
      }.bind(this)
   })
	}

	latestDateFromRequest(obj){
		var keys = [];

		for (var key in obj) {
			keys.push([Date.parse(key),key])
		};

		var sortedDates = keys.sort(function(a, b) {
			var time1 = a[0]
			var time2 = b[0]
			if (time1 > time2) return -1;
			if (time2 > time1) return 1;
			return 0;
		});
		return sortedDates[sortedDates.length - 1][0]
	}

	notificationRowToObjectWithDateKey(data) {
		var notificationRows = data.notification_rows
		var newDateRowObject = {}
		for (var i = 0; i < notificationRows.length; i++){
		  var date = new Date(notificationRows[i].year, notificationRows[i].monthNum - 1, notificationRows[i].day)
		  newDateRowObject[date] = notificationRows[i]
		}
		return newDateRowObject
	}

	infiniteScrollCall () {
		if (this.state.processing === false) {

	    $(window).on("scroll", () => {
	      if($(window).scrollTop() + $(window).height() >= $(document).height() - 200) {
 					this.fetchNotifications();
      	}
	    });
		}
  }

	rowsToSortedArrayByDateKey (sortKeys) {

    var keys = [];

    for (var key in this.state.notificationRows) {
  		keys.push([Date.parse(key),key])
		};

		var sortedKeys = keys.sort(function(a, b) {
			var time1 = a[0]
			var time2 = b[0]
    	if (time1 > time2) return -1;
    	if (time2 > time1) return 1;
			return 0;
		});

		var DateRows = sortedKeys.map(function(keyArray){
      var DateRow = this.state.notificationRows[keyArray[1]]
      DateRow.dateKey = keyArray[0]
			return DateRow
		}.bind(this))

		return DateRows

	}

  render(){
		return(
      <div className = "container">
      <div className = "alert hidden"></div>
      <div className = "check-notifications-row dome-d-flex dome-justify-between dome-align-center">
        <div>
          <h3 className= "notifications-header-text">notifications</h3>
        </div>
        <div className="mark-all-container dome-d-flex dome-justify-end dome-align-center dome-justify-between">
          <RolloverNote rolloverText="The 'mark all as read' button voids all un-actioned notifications on the page. You may also do so with individual days."/>
          <MarkAllButton handleAllReadClick={this.handleAllReadClick.bind(this)} />
        </div>
      </div>
      <div className="notifications-container">
				<DateRows
					dateRows={this.rowsToSortedArrayByDateKey()}
					fetchSpecificDate={this.fetchSpecificDate.bind(this)}
					fetchAllNotifications={this.fetchAllNotifications.bind(this)}
					fetchNotifications={this.fetchNotifications.bind(this)}
				/>
      </div>
    </div>
		)
  }
}

export default NotificationsIndex
