import React from 'react'
import PropTypes from 'prop-types'

const AMOUNT_FILTER_TYPES = ["equals to", "is between", "greater than", "less than"]

export default class AmountFilters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: "equals to"
    }

    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleBlurInput = this.handleBlurInput.bind(this)
  }

  componentDidMount() {
    this.setInitialTypeFromProps()
  }

  setInitialTypeFromProps() {
    const { amount } = this.props
    const initialState = this.state
    let initialType = null

    if (amount["equalsTo"]) initialType = "equals to"
    if (amount["min"] || amount["max"]) initialType = "is between"
    if (amount["greaterThan"]) initialType = "greater than"
    if (amount["lessThan"]) initialType = "less than"

    if (initialType) initialState["type"] = initialType

    if (initialState) this.setState(initialState)
  }

  handleChangeType(type) {
    this.setState({type: type})
  }

  handleBlurInput(input) {
    const $input = $(input)
    $input.val(parseFloat($input.val()).toFixed(2))
  }

  renderTypeSelect() {
    const typesList = AMOUNT_FILTER_TYPES.map(type => <option key={type} value={type}>{type}</option>)

    return <select className="type-select" onChange={e => this.handleChangeType(e.target.value)} value={this.state.type}>
      {typesList}
    </select>
  }

  renderAmountInput() {
    const inputAttributes = {
      min: "0.00",
      max: "99999999999",
      step: "0.01",
      type: "number",
      required: true,
      onBlur: (event) => this.handleBlurInput(event.target)
    }

    switch(this.state.type) {
      case "equals to":
        return <input {...inputAttributes}
          name="filters[amount][equalsTo]"
          defaultValue={this.props.amount["equalsTo"]} />
      case "is between":
        return <span>
          <input {...inputAttributes}
            className="input-min"
            name="filters[amount][min]"
            defaultValue={this.props.amount["min"]}
            placeholder="min" />
          <input {...inputAttributes}
            className="input-max"
            name="filters[amount][max]"
            defaultValue={this.props.amount["max"]}
            placeholder="max" />
        </span>
      case "greater than":
        return <input {...inputAttributes}
          name="filters[amount][greaterThan]"
          defaultValue={this.props.amount["greaterThan"]} />
      case "less than":
        return <input {...inputAttributes}
          name="filters[amount][lessThan]"
          defaultValue={this.props.amount["lessThan"]} />
      default:
        return
    }
  }

  render() {
    return <div key={this.state.type} className="amount-filter">
      {this.renderTypeSelect()}
      <div className="inputs-container">
        <i className="fa fa-long-arrow-right"></i>
        {this.renderAmountInput()}
      </div>
    </div>
  }
}

AmountFilters.propTypes = {
  amount: PropTypes.object
}

AmountFilters.defaultProps = {
  amount: {}
}

