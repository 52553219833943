import React, { useState, useEffect } from 'react'
import FieldsArea from './fields_area'
import { useSelector, useDispatch } from 'react-redux'
import { clone, remove } from './reducer/deal_party'
import { update } from './reducer/deal_party'

const Section = props => {
  const data = useSelector(state => state.dealParty.data),
        role = props.sectionMetadata.name
  const [numberOfInstances, setNumberOfInstances] = useState(data[role] ? data[role].length : 1)
  const dispatch = useDispatch()

  const duplicateSection = () => {
    dispatch(clone({ role: role, sectionMetadataFields: props.sectionMetadata.fields }))
  }

  const deleteSection = role_index => {
    dispatch(remove({ role: role, role_index: role_index }))
  }

  const renderDuplicateSectionButton = role_index => {
    if (role_index == null || role_index != 0)
      return null

    return <i className='fa fa-plus-square-o fa-2x'
              onClick={() => duplicateSection()}
              style={{ cursor: 'pointer' }} />
  }

  const rederDeleteSectionButton = role_index => {
    if (role_index == null || role_index < 1)
      return null

    return <i className='fa fa-minus-square-o fa-2x'
              onClick={() => deleteSection(role_index)}
              style={{ cursor: 'pointer' }} />
  }

  const renderLabel = () => {
    return <div className='col-xs-12 col-sm-3'>
      <label className='control-label'>{props.sectionMetadata.label}</label>
    </div>
  }

  const renderFieldsArea = (data, role_index) => {
    const data_key = `${role}[${role_index}]`
    const onUpdate = ({path, value}) => dispatch(update({ path, value }))
    
    // todo: pass data
    return <div className='col-xs-12 col-sm-8' style={{ backgroundColor: 'white', paddingLeft: 0, paddingRight: 0 }}>
      <FieldsArea fields={props.sectionMetadata.fields} data={data} data_key={data_key} onUpdate={onUpdate} />
    </div>
  }

  const renderIcon = role_index => {
    return <div className='col-sm-1'>
      {renderDuplicateSectionButton(role_index)}
      {rederDeleteSectionButton(role_index)}
    </div>
  }

  const sectionInstances = () => {
    const roleDataKeys = Object.keys(data).filter(key => key.startsWith(`${role}[`))
    const roleData = roleDataKeys.map(key => { return {[key]: data[key]} })
    const roleIndex = str => parseInt(str.split('.')[0].split('[')[1].split(']')[0])

    // show empty section if no sections exist
    const instanceCount = roleData.length == 0 ? 1 : Math.max(...roleData.map(d => roleIndex(Object.keys(d)[0]))) + 1,
              instances = []

    for (let i = 0; i < instanceCount; i++) {
      let sectionData = roleData.filter(d => Object.keys(d)[0].startsWith(`${role}[${i}]`))

      // convert array of objects to a single object
      sectionData = sectionData.reduce(function(result, current) {
        return Object.assign(result, current);
      }, {})
      // const sectionData = roleData.filter()

      // if (sectionData['deleted'])
      //   continue

      instances.push(
        <div className='row' style={{ marginBottom: 10 }} key={i}>
          {renderLabel()}
          {renderFieldsArea(sectionData, i)}
          {renderIcon(i)}
        </div>
      )
    }
    return instances
  }

  return <>
   {sectionInstances()}
  </>
}

export default Section