import React, { useEffect, useRef, useCallback } from 'react'

import { createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

import { min, omit } from 'lodash'
import { ajax } from 'jquery'

import { useCurrentTools, Provider as CurrentToolsProvider } from '../tools'
import { setPageFiles } from '../page'

import Thumbnails from './thumbnails'
import Main from './main'
import Sidebar from '../sidebar'

const initialState = {
  form: {},
  ratio: 0,
  thumbnailRatio: 0,
  default_width: null,
  page_widths: [],
  width: null,
  active_tool: null,
  page_selected_components: {},
  selected_components: []
}

const Store = createSlice({
  name: "form",
  initialState,
  reducers: {
    setForm(state, { payload: form }) {
      state.form = { ... form }
      state.default_width = form.default_width || form.page_width
      state.page_widths = form.page_widths
      state.thumbnailRatio = 150 / state.default_width
    },

    setFormWidth(state, { payload: width }) {
      state.width = width
    },

    setDOMWidth(state, { payload: width}) {
      state.ratio = width / state.default_width
    },

    setActiveTool(state, { payload: tool }) {
      state.active_tool = tool
    },

    setSelectedComponents(state, {payload: {page, selected}}) {
      const paged = { ... state.page_selected_components }

      if(selected.length > 0)
        paged[page] = selected
      else
        delete paged[page]

      state.page_selected_components = paged
      state.selected_components = Object.values(paged).flat()
    },

    clearSelectedComponents(state) {
      state.page_selected_components = {}
      state.selected_components = []
    },

    setUnlinkedFieldPosition(state, { payload: { fieldPositionId, unlinked, unlinkedValue } }) {
      if (unlinked) {
        state.form.unlinked_values[fieldPositionId] = unlinkedValue
      } else {
        state.form.unlinked_values = omit(state.form.unlinked_values, fieldPositionId)
      }
    },

    setFormValue(state, { payload: pathMap }) {
      state.form.values = { ...state.form.values, ...pathMap}
    }
  }
})

export const reducer = Store.reducer
const { setDOMWidth } = Store.actions
export const { setForm,
               setFormWidth,
               setActiveTool,
               setSelectedComponents,
               clearSelectedComponents,
               setUnlinkedFieldPosition,
               setFormValue} = Store.actions

function reloadPageSizes({form_id, width, success}) {
  const url = `/forms/v3/instance/${form_id}/page_files?width=${width}`
  ajax({
    url, success
  })
}

export function useFormWidthWatcher({form_id, passedRef}) {
  const ref = passedRef || useRef()
  const dispatch = useDispatch()
  const formWidth = useSelector(state => state.form.width)
  const pageWidths = useSelector(state => state.form.form.page_widths)
  const defaultWidth = useSelector(state => state.form.default_width)

  const dataLoaded = useCallback(data => {
    dispatch(setPageFiles(data.page_files))
    dispatch(setFormWidth(data.page_width))
  }, [dispatch])

  const monitorWidths = useCallback(() => {
    const width = ref.current?.offsetWidth
    dispatch(setDOMWidth(width))

    const largerForms = pageWidths.filter(possible => possible >= width)
    const bestWidth = min(largerForms) || defaultWidth
    // const bestWidth = min(largerForms) || 1000

    if (bestWidth == formWidth)
      return

    reloadPageSizes({
      form_id,
      width: bestWidth,
      success: dataLoaded
    })

    dispatch(setFormWidth(bestWidth))
  // }, [form_id, formWidth, pageWidths, ref.current])
  }, [form_id, formWidth, ...pageWidths, ref.current])

  useEffect(() => {
    monitorWidths()
    window.addEventListener('resize', monitorWidths)
    return () => window.removeEventListener('resize', monitorWidths)
  }, [monitorWidths])

  return ref
}

function useKeyboardEventWatcher() {
  const tools = useCurrentTools()
  const dispatch = useDispatch()
  const components = useSelector(state => state.form.selected_components)

  const eventWatcher = useCallback(evt => {
    switch(evt.which) {
      // Delete
      case 46:
        dispatch(clearSelectedComponents())
        components.forEach(component => {
          tools.runCommand("DELETE", component)
        })
        break
    }
  }, [components, tools])

  useEffect(() => {
    window.addEventListener('keydown', eventWatcher)
    return () => window.removeEventListener('keydown', eventWatcher)
  }, [eventWatcher])
}

function Form({ form_id, package_id, internalScroll, viewOnly, signing, thumbnailHeaderContent, canSign }) {
  const ref = useFormWidthWatcher({form_id})
  useKeyboardEventWatcher()

  return <div id={`form-${form_id}`} className="packages-form-component">
    <div className="form-row">
      {viewOnly ? null : <Thumbnails form_id={form_id} parentRef={ref} internalScroll={internalScroll} thumbnailHeaderContent={thumbnailHeaderContent} /> }
      <Main form_id={form_id} ref={ref} package_id={package_id} viewOnly={viewOnly} signing={signing} canSign={canSign} />
      { viewOnly ? null : <Sidebar parentRef={ref} /> }
    </div>
  </div>
}

export default function(props) {
  return <CurrentToolsProvider>
    <Form {...props} />
  </CurrentToolsProvider>
}
