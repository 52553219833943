import React from 'react'
import PropTypes from 'prop-types'

const VotesTable = (props) => {
  const boardmemberTableData = props.boardMembers.map((board_member) => {
    return [
      <tr key={board_member.id}>
        <td>{board_member.first_name} {board_member.last_name}</td>
        <td>{board_member.role}</td>
        <td>{board_member.email}</td>
        <td>{props.currentUserEmail == board_member.email ? props.voteValue || "Vote Pending" : board_member.vote_value || "Vote Pending"}</td>
      </tr>
    ]
  })
  return (
    <table className="table mobile-table-display" style={{border: 'none', marginTop: 50}}>
      <thead>
        <tr style={{borderBottom: '2px solid #F68A41'}}>
          <td>Name</td>
          <td>Role</td>
          <td>Email</td>
          <td>Vote</td>
        </tr>
      </thead>
      <tbody className="text-muted">
        {boardmemberTableData}
      </tbody>
    </table>
  )
}

VotesTable.propTypes = {
  boardMembers: PropTypes.array,
  currentUserEmail: PropTypes.string.isRequired,
  voteValue: PropTypes.string.isRequired
}

export default VotesTable
