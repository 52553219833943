import React, { useState, useEffect } from 'react';

const PackageAddress = ({ addresses, hashed_id, initialAddressId }) => {
  const [selectedAddress, setSelectedAddress] = useState(initialAddressId || 0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    setSelectedAddress(Number(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('/super/package_info/change_address', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          hashed_id: hashed_id,
          address_id: selectedAddress,
        }),
      });

      if (response.ok) {
        setMessage('Address changed successfully');
      } else {
        setMessage('Failed to change address');
      }
    } catch (error) {
      setMessage('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="container" style={{ paddingLeft: 0 }}>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="hashed_id" value={hashed_id} />
        <div className="col-xs-10" style={{ paddingLeft: 0 }}>
          <select
            className="form-control"
            name="address_id"
            value={selectedAddress}
            onChange={handleChange}
          >
            {addresses.map(([address, id]) => (
              <option key={id} value={id}>
                {address}
              </option>
            ))}
          </select>
        </div>
        <div className="col-xs-2">
          <button className="btn btn-warning" type="submit" disabled={loading} style={{ width: '135px' }}>
            {loading ? 'Processing...' : 'Change Address'}
          </button>
        </div>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default PackageAddress;
