import React from 'react'

function RolloverNote(props) {
	props.rolloverText ||= "We will write a helpful comment here in the future."

	return <div className="rollover-note">
		<i className="fa-light fa-question"></i>
		<div className="rollover-text">
			{props.rolloverText}
		</div>
	</div>
}

export default  RolloverNote
