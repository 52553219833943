import React from 'react'

const VisibleIcon = ({isVisible, updateVisiblePasswordState}) => {

  const handleClick = () => {
    updateVisiblePasswordState(!isVisible)
  }

  if (isVisible) {
    return <i onClick={handleClick} className='fa-light fa-eye-slash not-visible'></i>
  } else {
    return <i onClick={handleClick} className='fa-light fa-eye visible'></i>
  }
}

export default VisibleIcon