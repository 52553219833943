 import React from 'react'
import { compact, isPlainObject } from 'lodash'

import { asArray } from 'lib/utilities'
import { renderTemplate } from 'lib/utilities/form'

import { useOverrides } from '.'
import { useGlobalPathmap } from '../form_field'

function getStyles(props) {
  const style = { ... (props.style || props.styles || {}) }
  if (props.width)
    style.width ||= `${parseInt(props.width || 10) * 10}%`

  return style
}


function Html(props) {
  const { type, content } = props

  const Tag = TYPES[type] || Div

  const children = asArray(content)
                  .map(child => isPlainObject(child) ? child : { type: "value", value: child } )
                  .map((child, index) => <Html key={index} {...child} />)

  return <Tag {...props}>{children}</Tag>
}

const Template = ({template, fields}) => {
  const overrides = useOverrides()
  const pathMap = useGlobalPathmap(fields, overrides.globalPathmap || {})

  return renderTemplate(template, { pathMap })
}

const Value = ({value, template, fields}) => {
  if (template)
    return <Template template={template} fields={fields} />

  return value
}

function Div(props) {
  const classes = compact([
    props.className
  ]).join(" ")

  const style = getStyles(props)

  return <div style={style} className={classes}>
    { props.children }
  </div>
}

function Image(props) {
  const { url } = props
  const classes = compact([
    props.className
  ]).join(" ")

  const style = getStyles(props)
  
  return 
}

const TYPES = { 
  div: Div,
  value: Value
}

export default Html