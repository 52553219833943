import React from 'react'
import Pages from './sidebars/left/Pages'
import Fields from './sidebars/left/Fields'
import Properties from './sidebars/right/Properties'


const components = {
  Fields,
  Pages,
  Properties,
  // Add more components here
}

const SidebarContentRenderer = ({ componentName }) => {
  const Component = components[componentName]

  if (!Component) return

  return <Component />
}

export default SidebarContentRenderer
