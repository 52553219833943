import { createSlice, nanoid } from '@reduxjs/toolkit'
import { batchCreateBuildingUnits } from '../components/ImportModal/store/slice'

const initialState = {
  building: null,
  building_structure: null,
  tabs: ['active', 'history'],
  enabledTabs: ['active'],
  activeTab: 'active',
  randomVersion: nanoid(),
}

const buildingEditBuildingStructureSlice = createSlice({
  name: 'BuildingEditBuildingStructure',
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.building = action.payload.building
      state.building_structure = action.payload.building_structure
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(batchCreateBuildingUnits.fulfilled, (state, action) => {
      if (state.building_structure?.id == action.payload.building_structure_id) {
        state.randomVersion = nanoid()
      }
    })
  },
})

export const selectBuildingEditBuildingStructure = (state) => state.buildingEditBuildingStructure

export const { setInitialState, setActiveTab } = buildingEditBuildingStructureSlice.actions

export const buildingEditBuildingStructureReducer = buildingEditBuildingStructureSlice.reducer
