import React from 'react'
import PropTypes from 'prop-types'

const AccountOptions = ({ accounts }) => {
  return (
    <>
      <option value="-1" disabled></option>
      {accounts.map((account) => (
        <option
          key={account.id}
          value={account.id}
        >
          {account.account_description} - {account.remote_merchant_id}
        </option>
      ))}
    </>
  )
}

const TransferAccountsSection = ({
  source,
  destination,
  values,
  onValueSelect,
}) => {
  return (
    <div>
      <div>
        Org: {source.name} (ID: {source.id})
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Account</th>
              <th>Destination</th>
            </tr>
          </thead>
          <tbody>
            {source.accounts.map((account) => (
              <tr key={account.remote_merchant_id}>
                <td>
                  {account.account_description} - {account.remote_merchant_id}
                </td>
                <td>
                  <select
                    value={values[account.id] || "-1"}
                    onChange={(e) =>
                      onValueSelect(account.id, e.currentTarget.value)
                    }
                  >
                    <AccountOptions accounts={destination.accounts} />
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TransferAccountsSection.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      remote_merchant_id: PropTypes.string,
      account_description: PropTypes.string,
    })
  ),
}

export default TransferAccountsSection
