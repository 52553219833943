import React, { useEffect } from 'react'

function Button(props) {
  // Expects props.setAppState

  useEffect(() => {
    $("#collected-fees-btn").hide()
    return () => $("#collected-fees-btn").show()
  })

  return  <button id="initiate-refund-btn" className="btn btn-success" data-toggle="modal" data-target="#refunds-modal">
    Initiate Refund
  </button>
}

export default Button