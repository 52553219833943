import { mapValues } from 'lodash'
import { renderTemplate, pathMapToHash } from 'lib/utilities/form'
import { translateValue } from 'lib/utilities/form/utilities'
import { addUser, updateSimilarPeople } from './users'


function personName(state, person_id) {
  const party_id = state.person_parties[person_id]
  const party = state.deal_party_metadata[party_id]
  const person = state.data_people[person_id]

  return renderTemplate(party.name_template, { pathMap: person })
}

function personEmail(state, person_id) {
  const party_id = state.person_parties[person_id]
  const party = state.deal_party_metadata[party_id]
  const person = state.data_people[person_id]

  return translateValue(party.email_path, { pathMap: person })
}

function getUserFromPerson(state, person_id ) {
  const party_id = state.person_parties[person_id]
  const party = state.deal_party_metadata[party_id]
  const person = state.data_people[person_id]
  const email = state.people_emails[person_id]

  return {
    first_name: translateValue(party.first_name_path, { pathMap: person }),
    last_name: translateValue(party.last_name_path, { pathMap: person}),
    email
  }
}

export function updateSimilarPerson(state, person_id, { first_name, last_name }) {
  const person = state.data_people[person_id]
  const party_id = state.person_parties[person_id]
  const party = state.deal_party_metadata[party_id]

  state.data_people[person_id] = { ... person, 
      [party.first_name_path]: first_name, 
      [party.last_name_path]: last_name 
  }

  state.people_names[person_id] = personName(state, person_id)
  state.people_hash[person_id] = pathMapToHash(state.data_people[person_id])
}

function getPersonFromUser(state, { party_id, email }) {
  const party = state.deal_party_metadata[party_id]
  const user = state.user_people[email]

  return {
    [party.email_path]: user.email,
    [party.first_name_path]: user.first_name,
    [party.last_name_path]: user.last_name
  }
}

function generateUpdates(state, person_id, data) {
  const updates = state.people_updates[person_id] ? { ... state.people_updates[person_id] } : {}

  for (let path in data) {
    updates[path] = [
      updates[path] ? updates[path][0] : state.data_people[person_id][path],
      data[path]
    ]
  }

  state.people_updates[person_id] = updates
  state.data_people[person_id] = {
    ...state.data_people[person_id],
    ...mapValues(updates, v => v[1])
  }
  
  state.people_hash[person_id] = pathMapToHash(state.data_people[person_id])
}

function checkForNameAndEmailUpdates(state, person_id) {
  const name = personName(state, person_id)
  const email = personEmail(state, person_id)
  const old_email = state.people_emails[person_id]

  // Remove from old people
  state.user_possible_people[old_email] ||= []
  state.user_possible_people[old_email] = state.user_possible_people[old_email].filter(id => id != person_id)
  if (state.user_possible_people[old_email].length == 0) {
    delete state.user_possible_names[old_email]
    delete state.user_possible_people[old_email]
  }

  state.people_names[person_id] = name
  state.people_emails[person_id] = email
  state.user_possible_names[email] = name
  state.user_possible_people[email] ||= []
  state.user_possible_people[email].push(person_id)
}

function addToPartyPeople(state, {party_id, person_id}) {
  const party = state.deal_party_metadata[party_id]
  const { type_path, type_value } = party.data

  generateUpdates(state, person_id, {[type_path]: type_value})

  state.person_parties[person_id] = party_id

  state.party_people[party_id] ||= []
  if (!state.party_people[party_id].includes(person_id))
    state.party_people[party_id].push(person_id)
}

function removeFromPartyPeople(state, person_id) {
  const party_id = state.person_parties[person_id]
  delete state.person_parties[person_id]

  state.party_people[party_id] = state.party_people[party_id].filter(id => id != person_id)
  if (state.party_people[party_id].length == 0)
    delete state.party_people[party_id]
}

export function initializePeople(state, { people, person_parties, deal_party_mapping }) {
  clearPeople(state)

  for (let person_id in people) {
    const party_id = person_parties[person_id]

    if (!party_id || !people[person_id])
      continue

    addPerson(state, { party_id, person_id, data: people[person_id]})
  }

  state.deal_party_people = deal_party_mapping
  state.person_deal_parties = Object.fromEntries(Object.entries(deal_party_mapping).map(([k,v]) => [v,k]))
}

export function clearPeople(state) {
  state.data_people = {}
  state.people_updates = {}
  state.people_hash = {}
  state.people_names = {}
  state.people_emails = {}
  state.user_possible_names = {}
  state.user_possible_people = {}
  state.new_person_index = -1

  state.person_parties = {}
  state.party_people = {}

  state.person_deal_parties = {}
  state.deal_party_people = {}

  state.new_parties = {}
}

export function addPerson(state, { party_id, data, person_id = null}) {
  const party = state.deal_party_metadata[party_id]

  if (!person_id) {
    person_id = `${party.data.path}[${state.new_person_index}]`
    state.new_parties[party_id] ||= []
    state.new_parties[party_id].push( person_id )
    state.new_person_index --
  }

  state.data_people[person_id] = {}
  
  generateUpdates(state, person_id, data)
  addToPartyPeople(state, { party_id, person_id })
  checkForNameAndEmailUpdates(state, person_id)

  const email = state.people_emails[person_id]
  updateSimilarPeople(state, email, getUserFromPerson(state, person_id))

  return person_id
}

export function addPersonFromUser(state, { party_id, email }) {
  const data = getPersonFromUser(state, { party_id, email })

  return addPerson(state, { party_id, data })
}

export function addUserFromPerson(state, { email }) {
  const person_id = (state.user_possible_people[email] || []) [0]
  if (!person_id)
    return false

  const data = getUserFromPerson(state, person_id)
  return addUser(state, { data })
}

export function editPerson(state, { person_id, data }) {
  const party_id = state.person_parties[person_id]

  generateUpdates(state, person_id, data)
  addToPartyPeople(state, { party_id, person_id })
  checkForNameAndEmailUpdates(state, person_id)
  
  const email = state.people_emails[person_id]
  updateSimilarPeople(state, email, getUserFromPerson(state, person_id))
}

export function changePersonParty(state, {person_id, party_id}) {
  removeFromPartyPeople(state, person_id)
  addToPartyPeople(state, { party_id, person_id })
}

export function removePerson(state, person_id) {
  const email = state.people_emails[person_id]

  removeFromPartyPeople(state, person_id)

  delete state.people_names[person_id]
  delete state.people_emails[person_id]
  state.to_remove.push({ person_id })

  if (!email)
    return

  state.user_possible_people[email] = state.user_possible_people[email].filter(id => id != person_id)
  if (state.user_possible_people[email].length == 0) {
    delete state.user_possible_names[email]
    delete state.user_possible_people[email]
  }
}