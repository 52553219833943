import React, { memo } from 'react'
import { ceil } from 'lodash-es'

import { formattedMoney, formattedNumber } from 'components/insurance/get_quote/utils'

const HOMEOWNERS_DWELLING_AMOUNT_MULTIPLIER = 1300
const RENTERS_DWELLING_AMOUNT_MULTIPLIER = 0.05

export const HomeownersDwellingAmountTooltipContent = memo(({ squareFootageWatch, minimumDwellingAmount }) => {
  let dwellingAmount = squareFootageWatch * HOMEOWNERS_DWELLING_AMOUNT_MULTIPLIER
  dwellingAmount = ceil(dwellingAmount, -2)

  const exampleSqft = 800
  const exampleAmount = HOMEOWNERS_DWELLING_AMOUNT_MULTIPLIER * exampleSqft
  return (
    <>
      <div>
        The additions and alterations (dwelling) amount should be{' '}
        {formattedMoney(HOMEOWNERS_DWELLING_AMOUNT_MULTIPLIER)} x sq footage value rounded up to the nearest hundred and
        must have a minimum at {formattedMoney(minimumDwellingAmount)}.
      </div>
      <br />
      {!squareFootageWatch && (
        <div>
          Example: For {exampleSqft} sq ft, the initial amount is{' '}
          {formattedMoney(HOMEOWNERS_DWELLING_AMOUNT_MULTIPLIER)} x {exampleSqft} ={' '}
          {formattedMoney(ceil(exampleAmount, -2))}.
        </div>
      )}
      {squareFootageWatch > 0 && (
        <div>
          Your recommended amount for {formattedNumber(squareFootageWatch)} sq ft is{' '}
          {formattedMoney(Math.max(minimumDwellingAmount, dwellingAmount))}
        </div>
      )}
    </>
  )
})

export const RentersDwellingAmountTooltipContent = memo(({ contentCoverageWatch, minimumDwellingAmount }) => {
  let dwellingAmount = contentCoverageWatch * RENTERS_DWELLING_AMOUNT_MULTIPLIER
  dwellingAmount = ceil(dwellingAmount, -2)

  const exampleContentCoverage = 50000
  const exampleAmount = RENTERS_DWELLING_AMOUNT_MULTIPLIER * exampleContentCoverage

  return (
    <>
      <div>The alterations and additions (dwelling) amount should be 5% of contents amount.</div>
      <br />
      {!contentCoverageWatch && (
        <div>
          For example, the dwelling amount would be {formattedMoney(exampleAmount)} for{' '}
          {formattedMoney(exampleContentCoverage)} of contents value.
        </div>
      )}
      {contentCoverageWatch > 0 && (
        <div>
          Your recommended amount for a {formattedMoney(contentCoverageWatch)} contents amount is{' '}
          {formattedMoney(Math.max(minimumDwellingAmount, dwellingAmount))}.
        </div>
      )}
    </>
  )
})
