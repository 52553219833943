import { useCallback, useContext, useLayoutEffect, useMemo } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { InterstitialsContext } from './wrapper'

const DEFAULT_TRANSITION_TIME = 666 // default transition time, in ms

export const useDomecileInterstitial = ({ id = null, transition_time = DEFAULT_TRANSITION_TIME } = {}) => {
  const interstitialId = useMemo(() => (id ? id : nanoid()), [id])
  const { interstitials, setInterstitials } = useContext(InterstitialsContext)

  const setValues = useCallback(
    (newValues) =>
      setInterstitials((interstitials) => {
        return {
          ...interstitials,
          [interstitialId]: {
            ...interstitials[interstitialId],
            ...newValues,
          },
        }
      }),
    [interstitialId, setInterstitials]
  )

  const open = useCallback(() => {
    setValues({ 'show': true })
  }, [setValues])

  const close = useCallback(() => {
    setValues({ 'hiding': true })
  }, [setValues])

  const { show, hiding } = useMemo(() => {
    const state = interstitials[interstitialId]
    return {
      show: state?.show || false,
      hiding: state?.hiding || false,
    }
  }, [interstitials, interstitialId])

  useLayoutEffect(() => {
    if (hiding) {
      const setHidden = () => setValues({ 'show': false, 'hiding': false })
      const timeout = setTimeout(setHidden, transition_time)

      return () => clearTimeout(timeout)
    }
  }, [setValues, hiding])

  return useMemo(() => ({ interstitialId, show, open, close, hiding }), [interstitialId, show, open, close, hiding])
}
