import { useCallback, useMemo, useState } from 'react'

export default function useServerSortable(defaultSortField, defaultSortOrder) {
  const [sortField, setSortField] = useState(defaultSortField)
  const [sortOrder, setSortOrder] = useState(defaultSortOrder)

  const handleSorting = useCallback((sortField, sortOrder) => {
    setSortField(sortField)
    setSortOrder(sortOrder)
  }, [])

  const clearSorting = useCallback(() => handleSorting(null, 'asc'), [handleSorting])

  return useMemo(
    () => ({
      handleSorting,
      sortField,
      clearSorting,
      sortOrder,
    }),
    [handleSorting, sortField, clearSorting, sortOrder]
  )
}
