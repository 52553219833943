import React from 'react'

const InfiniteScrollLoader = ({ style }) => {
  return (
    <div className='dome-infinite-scroll-loader' style={style}>
      <span className="loading__dot1"></span>
      <span className="loading__dot2"></span>
      <span className="loading__dot3"></span>
    </div>
  )
}

export default InfiniteScrollLoader
