import React, { Component } from "react"
export default class Paginate extends Component {
  constructor(props) {
    super(props)

  }

  handlePageChange(page){
    this.setState({current_page: page})
    this.props.set_page(page)
  }

  renderPageLink(page_number){
    if (page_number < 0 || page_number > this.lastPage())
      return ""

    if (page_number == this.props.current_page)
      return <li className="active"><span>{page_number+1}</span></li>

    return <li>
              <a key={page_number} onClick={ (e) => this.handlePageChange(page_number)}>
                {page_number+1}
              </a>
            </li>
  }

  lastPage(){
    return Math.max(0, parseInt((this.props.questionnaire_count - 1) / this.props.page_limit) || 0)
  }

  renderNextButton(){
    if (this.props.current_page == this.lastPage())
      return ""
    
    const next_page = this.props.current_page + 1
    return <li><a onClick={ (e) => this.handlePageChange(next_page)}>Next <span>&#8594;</span></a></li>
  }

  renderPreviousButton(){
    if (this.props.current_page == 0)
      return ""

    const previous_page = this.props.current_page - 1
    return <li><a onClick={ (e) => this.handlePageChange(previous_page)}><span>&#8592;</span> Previous</a></li>
  }

  render() {
    const pages = [];
    let page = 0;
    
    if (this.props.questionnaire_count <= this.props.page_limit)
      return null

    const three_before = this.props.current_page - 3
    const three_after = this.props.current_page + 3
    
    const first_possible_element = Math.max(1, three_before)
    const last_possible_element = Math.min(three_after, this.lastPage())

    for (; page < Math.min(3, this.props.current_page - 3); page++)
      pages.push(this.renderPageLink(page))

    if (page + 1 < this.props.current_page - 3)
      pages.push(<li><span key="break-1">...</span></li>)

    for (page = Math.max(0, this.props.current_page - 3); page <= Math.min(this.lastPage(), this.props.current_page + 3); page++)
      pages.push(this.renderPageLink(page))

    if (page < this.lastPage() - 3)
      pages.push(<li><span key="break-2">...</span></li>)

    for (page = Math.max(page, this.lastPage() - 3); page <= this.lastPage(); page++)
      pages.push(this.renderPageLink(page))

    return <div className="questionnaire-pagination">
      <ul className="pagination">
      {this.renderPreviousButton()}
      {pages}
      {this.renderNextButton()}
      </ul>
    </div>

  }
};