import React from 'react'
import ResourceSectionWrapper from './section_wrapper'
import eNotaryIcon from '/app/assets/images/e-notary.svg'
import chubbLogo from '/app/assets/images/pages/home/insurance/chubb.png'
import lemonadeLogo from '/app/assets/images/pages/home/insurance/lemonade.png'
import viaffinityLogo from '/app/assets/images/pages/home/insurance/viaffinity.png'
import InstantQuoteLookupOverlayButton from 'components/insurance/get_quote/instant_quote_lookup/overlay_button'
import Steps_for_modals_button from 'components/e_notary/steps_for_modals_button'

const CONTRACTOR_REPORT_COVERS = [
  ':: disciplinary issues',
  ':: license status',
  ':: experience',
  ':: litigation history',
  ':: past estimates',
  ':: references',
]
const ENOTARY_COVERS = ['set a time', 'verify id', 'e-notarize']
const CONCIERGE_COVERS = ['choose service level', 'request quote', 'let us take it from there']

export const ResourceInsuranceSection = ({ onBack, property, packageId, user, insurance_type }) => {
  return (
    <ResourceSectionWrapper className="dome-gap12">
      <ResourceSectionWrapper.Header>
        <div className="dome-d-flex dome-align-center dome-justify-between dome-full-width">
          <div className="dome-d-flex dome-align-center dome-gap6">
            <div className="resource-section-icon dome-d-flex dome-align-center dome-justify-center">
              <span className="dome-color-dark-grey"><i className="fa-xl fa-thin fa-umbrella"></i></span>
            </div>
            <span className="dome-p1 dome-color-dark-grey">insurance</span>
          </div>
          <div>
            <em className="dome-color-med-grey dome-p4">by</em>
            <img src={viaffinityLogo} style={{ width: '56px', objectFit: 'contain' }} />
          </div>
        </div>
      </ResourceSectionWrapper.Header>
      <ResourceSectionWrapper.Title title="Get an instant quote that matches property requirements" />
      <div className='dome-d-flex dome-flex-column'>
        <div>
          <em className='dome-color-med-grey dome-p4'>insurance provided by</em>
        </div>
        <div className="dome-d-flex dome-align-center dome-gap6">
          <img src={chubbLogo} style={{ height: '6px', objectFit: 'contain' }} alt="chubb logo" />
          <img src={lemonadeLogo} style={{ height: '10px', objectFit: 'contain' }} alt="lemonade logo" />
        </div>
      </div>
      <ResourceSectionWrapper.Footer hasButton={false} onBack={onBack}>
        <InstantQuoteLookupOverlayButton currentUser={user} building={property} packageId={packageId} insuranceType={insurance_type} buttonText='get started' buttonClasses='btn-base btn-dark-green' />
      </ResourceSectionWrapper.Footer>
    </ResourceSectionWrapper>
  )
}

export const ResourceContractorReportSection = ({ onBack }) => {
  return (
    <ResourceSectionWrapper className="dome-gap12">
      <ResourceSectionWrapper.Header
        icon={<i className="fa-xl fa-thin fa-screwdriver-wrench"></i>}
        label="contractor report"
      />
      <ResourceSectionWrapper.Title title="Vet your contractor. Every report covers:" />
      <ul className="resource-section-contractor-report-content dome-flex-1">
        {CONTRACTOR_REPORT_COVERS.map((item) => (
          <li key={item} className="dome-p3 dome-color-dark-grey dome-text-left">
            {item}
          </li>
        ))}
      </ul>
      <ResourceSectionWrapper.Footer
        btnClassName="dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right"
        btnName="request report"
        onBack={onBack}
        onClick={() => window.open('/about/contractorreport', '_blank')}
      />
    </ResourceSectionWrapper>
  )
}

export const ResourceENotarySection = ({ onBack, enotary_price }) => {
  return (
    <ResourceSectionWrapper className="dome-gap6">
      <ResourceSectionWrapper.Header
        icon={<img src={eNotaryIcon} width={21} className="dome-color-green-gradient" />}
        label="e-notary"
      />
      <ResourceSectionWrapper.Title title="Notarize your documents in three simple steps:" />
      <ul className="dome-d-flex dome-flex-1 dome-gap12">
        {ENOTARY_COVERS.map((item, index) => (
          <li key={item} className="dome-flex-1">
            <div className="dome-d-flex dome-flex-column dome-align-center dome-gap6 dome-text-center">
              <span className="dome-p2 dome-color-aqua">{index + 1}</span>
              <span className="dome-p3 dome-color-dark-grey">{item}</span>
            </div>
          </li>
        ))}
      </ul>
      <ResourceSectionWrapper.Footer hasButton={false} onBack={onBack}>
        <Steps_for_modals_button hasButton={false} text="request quote" enotary_price={enotary_price}/>
      </ResourceSectionWrapper.Footer>
    </ResourceSectionWrapper>
  )
}

export const ResourceConciergeSection = ({ onBack }) => {
  return (
    <ResourceSectionWrapper className="dome-gap6">
      <ResourceSectionWrapper.Header icon={<i className="fa-xl fa-thin fa-hand-wave"></i>} label="concierge" />
      <ResourceSectionWrapper.Title title="Need help with completing your package?" />
      <ul className="dome-d-flex dome-flex-1 dome-gap12">
        {CONCIERGE_COVERS.map((item, index) => (
          <li key={item} className="dome-flex-1">
            <div className="dome-d-flex dome-flex-column dome-align-center dome-gap6 dome-text-center">
              <span className="dome-p2 dome-color-aqua">{index + 1}</span>
              <span className="dome-p3 dome-color-dark-grey">{item}</span>
            </div>
          </li>
        ))}
      </ul>
      <ResourceSectionWrapper.Footer
        btnClassName="dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right"
        btnName="learn more"
        onBack={onBack}
        onClick={() => window.open('/concierge', '_blank')}
      />
    </ResourceSectionWrapper>
  )
}
