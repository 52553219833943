import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { omit } from "lodash-es";

import {
  buildingUnitEditPageState,
  loadUnit,
  removeResident,
  resetErrorMessage,
  setIsBuildingUnitRemoveResidentConfirmationModalShown,
  setIsBuildingUnitUpdatedModalShown,
  setIsOwnerFormShown,
  setIsRenterFormShown,
  setOwnerFormMode,
  setRenterFormMode,
  updateUnit,
} from "./store/slice";
import BuildingResidentForm from "./components/BuildingResidentForm";
import BuildingResidentTable from "./components/BuildingResidentTable";
import BuildingUnitRemoveResidentConfirmationModal from "../components/modals/BuildingUnitRemoveResidentConfirmationModal";
import AddResidentButton from "../components/shared/AddResidentButton";
import BuildingUnitEditConfirmationModal from "./components/modals/BuildingUnitEditConfirmationModal";
import BuildingUnitUpdatedModal from "./components/modals/BuildingUnitUpdatedModal";
import ErrorMessages from "../components/shared/ErrorMessages";

export default function BuildingUnitEdit({
  buildingUnitId,
  buildingStructureId,
  address,
  buildingStructureName,
}) {
  const [isSubmitConfirmationModalShown, setIsSubmitConfirmationModalShown] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      unit_number: "",
      square_footage: "",
      bedroom_count: "",
      bathroom_count: "",
    },
  });
  const dispatch = useDispatch();
  const {
    buildingResidents,
    isOwnerFormShown,
    isRenterFormShown,
    buildingUnit,
    errorMessages,
    isBuildingUnitRemoveResidentConfirmationModalShown,
    currentResidentAttemptToRemove,
    isBuildingUnitUpdatedModalShown
  } = useSelector(buildingUnitEditPageState);

  useEffect(() => {
    dispatch(loadUnit(buildingUnitId));
  }, [])

  useEffect(() => {
    reset({
      unit_number: buildingUnit?.unit_number,
      square_footage: buildingUnit?.square_footage,
      bedroom_count: buildingUnit?.bedroom_count,
      bathroom_count: buildingUnit?.bathroom_count,
    }, { keepDefaultValues: true })
  }, [buildingUnit]);

  // handle memoized variables
  const owners = useMemo(() => {
    return buildingResidents.filter(
      (resident) => resident.resident_role === 'owner'
    );
  }, [buildingResidents]);

  const renters = useMemo(() => {
    return buildingResidents.filter(
      (resident) => resident.resident_role === 'renter'
    );
  }, [buildingResidents]);

  const submitButtonDisabled = isOwnerFormShown || isRenterFormShown;

  const handleClickAddOwner = () => {
    dispatch(setIsOwnerFormShown(true));
    dispatch(setOwnerFormMode("add"));
  };

  const handleClickAddRenter = () => {
    dispatch(setIsRenterFormShown(true));
    dispatch(setRenterFormMode("add"));
  };

  const onBuildingUnitRemoveResidentConfirmationModalClose = () => {
    dispatch(setIsBuildingUnitRemoveResidentConfirmationModalShown(false));
  };

  const onBuildingUnitRemoveResidentConfirmationModalSubmit = () => {
    dispatch(removeResident());
    dispatch(setIsBuildingUnitRemoveResidentConfirmationModalShown(false));
  };

  const onSubmitConfirmation = () => {
    setIsSubmitConfirmationModalShown(true);
  }

  const onBuildingUnitEditConfirmationModalClose = () => {
    setIsSubmitConfirmationModalShown(false);
  }
  
  const onBuildingUnitEditConfirmationModalSubmit = () => {
    const params = {
      buildingUnitId: buildingUnitId,
      payload: {
        building_unit: {
          ...getValues(),
          building_residents_attributes: buildingResidents.map(br => omit(br, 'f_id')),
        }
      }
    }
    
    dispatch(updateUnit(params));
    onBuildingUnitEditConfirmationModalClose();
  }
  
  const onBuildingUnitUpdatedModalClose = () => {
    dispatch(setIsBuildingUnitUpdatedModalShown(false));
    dispatch(resetErrorMessage());
  }
  
  const onBuildingUnitUpdatedModalSubmit = () => {
    dispatch(loadUnit(buildingUnitId));
    onBuildingUnitUpdatedModalClose();
  }

  return (
    <>
      <div className="container building-unit-container">
        <div className="building-unit-header">
          <span className="dome-h2" style={{ marginRight: "10px" }}>
            {address}
          </span>
          <span className="dome-p2">{buildingStructureName}</span>
        </div>
        <div className="building-unit-body">
          <ErrorMessages errorMessages={errorMessages}/>
          <div className="building-unit-form">
            <div className="dome-h3">details</div>
            <div className="building-unit-form-group-wrapper">
              <form onSubmit={handleSubmit(onSubmitConfirmation)} className="building-unit-form-group-wrapper__form" id="building_unit_form">
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_unit_number"
                    className="dome-p3"
                  >
                    unit number*
                  </label>
                  <input
                    {...register("unit_number", {
                      required: "This is a required field",
                    })}
                    type="text"
                    id="building_unit_unit_number"
                  ></input>
                  {errors?.unit_number && (
                    <p className="dome-color-red-alert">
                      {errors.unit_number.message}
                    </p>
                  )}
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_square_footage"
                    className="dome-p3"
                  >
                    sq. footage
                  </label>
                  <input
                    {...register("square_footage")}
                    type="text"
                    id="building_unit_square_footage"
                  ></input>
                  {errors?.square_footage && (
                    <p className="dome-color-red-alert">
                      {errors.square_footage.message}
                    </p>
                  )}
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_bedroom_count"
                    className="dome-p3"
                  >
                    bedrooms
                  </label>
                  <input
                    {...register("bedroom_count")}
                    type="text"
                    id="building_unit_bedroom_count"
                  ></input>
                  {errors?.bedroom_count && (
                    <p className="dome-color-red-alert">
                      {errors.bedroom_count.message}
                    </p>
                  )}
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_bathroom_count"
                    className="dome-p3"
                  >
                    bathrooms
                  </label>
                  <input
                    {...register("bathroom_count")}
                    type="text"
                    id="building_unit_bathroom_count"
                  ></input>
                  {errors?.bathroom_count && (
                    <p className="dome-color-red-alert">
                      {errors.bathroom_count.message}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="building-resident-form">
            <div className="building-resident-form__header">
              <div className="dome-h3">owners</div>
              <AddResidentButton role={'owner'} isShown={!isOwnerFormShown && owners.length === 0} onClick={handleClickAddOwner} />
            </div>
            <div className="building-resident-form__body">
              <div className="building-resident-form-body-table">
                <BuildingResidentTable residents={owners} />
              </div>
              <div className="building-resident-form-group-wrapper">
                <BuildingResidentForm
                  role={"owner"}
                  formVisibility={isOwnerFormShown}
                  setFormVisibility={(val) =>
                    dispatch(setIsOwnerFormShown(val))
                  }
                />
                <AddResidentButton role={'owner'} isShown={!isOwnerFormShown && owners.length > 0} onClick={handleClickAddOwner} />
              </div>
            </div>
          </div>
          <div className="building-resident-form">
            <div className="building-resident-form__header">
              <div className="dome-h3">renters</div>
              <AddResidentButton role={'renter'} isShown={!isRenterFormShown && renters.length === 0} onClick={handleClickAddRenter} />
            </div>
            <div className="building-resident-form__body">
              <div className="building-resident-form-body-table">
                <BuildingResidentTable residents={renters} />
              </div>
              <div className="building-resident-form-group-wrapper">
                <BuildingResidentForm
                  role={"renter"}
                  formVisibility={isRenterFormShown}
                  setFormVisibility={(val) =>
                    dispatch(setIsRenterFormShown(val))
                  }
                />
                <AddResidentButton role={'renter'} isShown={!isRenterFormShown && renters.length > 0} onClick={handleClickAddRenter} />
              </div>
            </div>
          </div>
          <div className="building-unit-form-footer">
            <button
              className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-cancel"
              onClick={() =>
                (window.location.href = `/building_structures/${buildingStructureId}/building_units/${buildingUnitId}`)
              }
            >
              cancel
            </button>
            <button
              type="submit"
              className={`dome-btn dome-btn-base ${
                submitButtonDisabled ? "dome-btn-disabled" : "dome-btn-go-green"
              }`}
              form="building_unit_form"
              disabled={submitButtonDisabled}
            >
              save changes
            </button>
          </div>
        </div>
      </div>
      <BuildingUnitEditConfirmationModal open={isSubmitConfirmationModalShown} onSubmit={onBuildingUnitEditConfirmationModalSubmit} onClose={onBuildingUnitEditConfirmationModalClose}/>
      <BuildingUnitUpdatedModal open={isBuildingUnitUpdatedModalShown} onSubmit={onBuildingUnitUpdatedModalSubmit} onClose={onBuildingUnitUpdatedModalClose} unitNumber={getValues('unit_number')}/>
      <BuildingUnitRemoveResidentConfirmationModal
        open={isBuildingUnitRemoveResidentConfirmationModalShown}
        resident={currentResidentAttemptToRemove}
        onClose={onBuildingUnitRemoveResidentConfirmationModalClose}
        onSubmit={onBuildingUnitRemoveResidentConfirmationModalSubmit}
      />
    </>
  );
}
