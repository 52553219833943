import React from 'react'

export default function ErrorMessages({ errorMessages }) {
  if (errorMessages.length === 0) return <></>;

  return (
    <>
      <ul style={{paddingLeft: '0px'}}>
        {errorMessages.map((message, index) => {
          return (
            <li key={`error-${index}`} className="dome-color-red-alert" style={{listStyleType: 'none', display: 'flex', gap: '5px', alignItems: 'center'}}>
              <i className="fa-thin fa-circle-exclamation"></i>
              {message}
            </li>
          )})
        }
      </ul>
    </>
  )
}
