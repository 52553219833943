import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  archiveUnit,
  buildingUnitShowPageState,
  loadUnit,
} from "./store/slice";
import BuildingResidentTable from "./components/BuildingResidentTable";
import BuildingUnitArchiveConfirmationModal from "./components/modals/BuildingUnitArchiveConfirmationModal";
import BuildingUnitArchivedModal from "./components/modals/BuildingUnitArchivedModal";

export default function BuildingUnitShow({
  buildingUnitId,
  buildingStructureId,
  buildingId,
  address,
  buildingStructureName,
  archive_visible: archiveVisible,
}) {
  const [isBuildingUnitArchiveConfirmationModalShown, setIsBuildingUnitArchiveConfirmationModalShown] = useState(false);
  const [isBuildingUnitArchivedModalShown, setIsBuildingUnitArchivedModalShown] = useState(false);
  const { buildingUnit, buildingResidents, isUnitArchived } = useSelector(buildingUnitShowPageState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUnit({buildingUnitId}));
  }, []);

  useEffect(() => {
    if(isUnitArchived) {
      setIsBuildingUnitArchivedModalShown(true);
      onBuildingUnitArchiveConfirmationModalClose();
    }
  }, [isUnitArchived])

  // handle memoized variables
  const owners = useMemo(() => {
    return buildingResidents.filter(
      (resident) => resident.resident_role === 'owner'
    );
  }, [buildingResidents]);

  const renters = useMemo(() => {
    return buildingResidents.filter(
      (resident) => resident.resident_role === 'renter'
    );
  }, [buildingResidents]);

  const onBuildingUnitArchiveConfirmationModalSubmit = () => {
    dispatch(archiveUnit(buildingUnitId));
  }

  const onBuildingUnitArchiveConfirmationModalClose = () => {
    setIsBuildingUnitArchiveConfirmationModalShown(false);
  }

  const onBuildingUnitArchivedModalClose = () => {
    setIsBuildingUnitArchivedModalShown(false);
  }

  const onBuildingUnitArchivedModalSubmit = () => {
    window.location.href = `/buildings/${buildingId}/building_structures/${buildingStructureId}`;
  }

  return (
    <>
      <div className="container building-unit-container">
        <div className="building-unit-header">
          <span className="dome-h2" style={{ marginRight: "10px" }}>
            {address}
          </span>
          <span className="dome-p2">{buildingStructureName}</span>
        </div>
        <div className="building-unit-body">
          <div className="building-unit-form">
            <div className="dome-h3">details</div>
            <div className="building-unit-form-group-wrapper">
              <div className="building-unit-form-group-wrapper__form">
                <div className="building-unit-form-group">
                  <label htmlFor="building_unit_unit_number" className="dome-p3">
                    unit number*
                  </label>
                  <input
                    type="text"
                    id="building_unit_unit_number"
                    value={buildingUnit?.unit_number || ""}
                    disabled
                  ></input>
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_square_footage"
                    className="dome-p3"
                  >
                    sq. footage
                  </label>
                  <input
                    type="text"
                    id="building_unit_square_footage"
                    value={buildingUnit?.square_footage || ""}
                    disabled
                  ></input>
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_bedroom_count"
                    className="dome-p3"
                  >
                    bedrooms
                  </label>
                  <input
                    type="text"
                    id="building_unit_bedroom_count"
                    value={buildingUnit?.bedroom_count || ""}
                    disabled
                  ></input>
                </div>
                <div className="building-unit-form-group">
                  <label
                    htmlFor="building_unit_bathroom_count"
                    className="dome-p3"
                  >
                    bathrooms
                  </label>
                  <input
                    type="text"
                    id="building_unit_bathroom_count"
                    value={buildingUnit?.bathroom_count || ""}
                    disabled
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="building-resident-form">
            <div className="building-resident-form__header">
              <div className="dome-h3">owners</div>
            </div>
            <div className="building-resident-form__body">
              <div className="building-resident-form-body-table">
                <BuildingResidentTable residents={owners}/>
              </div>
            </div>
          </div>
          <div className="building-resident-form">
            <div className="building-resident-form__header">
              <div className="dome-h3">renters</div>
            </div>
            <div className="building-resident-form__body">
              <div className="building-resident-form-body-table">
                <BuildingResidentTable residents={renters}/>
              </div>
            </div>
          </div>
          <div className="building-unit-form-footer">
            <a
              className="dome-link dome-link-grey dome-link-chevron-left"
              href={`/buildings/${buildingId}/building_structures/${buildingStructureId}`}
            >
              back
            </a>
            <div>
              {archiveVisible &&
                <button
                  type="button"
                  className="dome-btn dome-btn-base dome-btn-red-stroke"
                  onClick={() => setIsBuildingUnitArchiveConfirmationModalShown(true)}
                >
                  archive
                </button>
              }
              <a
                href={`/building_structures/${buildingStructureId}/building_units/${buildingUnitId}/edit`}
                className="dome-link dome-is-button dome-link-aqua-stroke"
                style={{marginLeft: '6px'}}
              >
                edit
              </a>
            </div>
          </div>
        </div>
      </div>
      <BuildingUnitArchiveConfirmationModal open={isBuildingUnitArchiveConfirmationModalShown} onSubmit={onBuildingUnitArchiveConfirmationModalSubmit} onClose={onBuildingUnitArchiveConfirmationModalClose}/>
      <BuildingUnitArchivedModal open={isBuildingUnitArchivedModalShown} onClose={onBuildingUnitArchivedModalClose} onSubmit={onBuildingUnitArchivedModalSubmit} unitNumber={buildingUnit?.unit_number}/>
    </>
  );
}
