import React from 'react'
import PropTypes from 'prop-types'

export default class VotesSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voting_enabled: this.props.stage_data.voting_enabled,
      pending_votes: this.props.stage_data.pending_votes
    }
  }

  UNSAFE_componentWillMount() {
    // Sets states only if voting is enabled
    if (this.state.voting_enabled) {
      this.setState({
        vote_counts: this.props.stage_data.vote_counts,
        votes_status: this.props.stage_data.votes_status
      })
    }
  }

  render() {
    // Skips rendering if voting is not enabled
    if (!this.state.voting_enabled) return (null);

    const { vote_counts, votes_status } = this.state;
    let votesList = [];
    let pendingVotesSpan;
    for (let key in vote_counts) {
      let hasVotes = vote_counts[key] > 0
      votesList.push(
        <p key={`votes-list-${key}`} className="no-margin-bottom">
          <span style={{ display: 'inline-block', width: 60 }}>{key}:</span><span style={{ color: hasVotes ? "#F28500" : "" }}>{vote_counts[key]}</span>
        </p>
      )
    }
    if (this.state.pending_votes > 0) {
      pendingVotesSpan = (<i><span style={{ display: 'inline-block', width: 60 }}>Pending:</span><span>{this.state.pending_votes.toString()}</span></i>)
    }
    return (
      <div id="voting-sidebar">
        <strong>Voting Results</strong>
        <p><span style={{ display: 'inline-block', width: 60 }}>Status:</span>{votes_status}</p>
        {votesList}
        <br />
        {pendingVotesSpan}
      </div>
    )
  }
}

VotesSidebar.propTypes = {
  stage_data: PropTypes.object.isRequired
}
