import { createStore, combineReducers } from 'redux'

// Action Definitions
const SET_BUILDING_RESPONSIBLE_PARTY_TYPE = "SET_BUILDING_RESPONSIBLE_PARTY_TYPE"
const SET_BUILDING_RESPONSIBLE_PARTIES = "SET_BUILDING_RESPONSIBLE_PARTIES"
const SET_BUILDING_RESPONSIBLE_PARTY_TITLES = "SET_BUILDING_RESPONSIBLE_PARTY_TITLES"
const SET_BUILDING_RESPONSIBLE_PARTY_ROLES_GROUPED_BY_EMAIL = "SET_BUILDING_RESPONSIBLE_PARTY_ROLES_GROUPED_BY_EMAIL"
const SET_BUILDING_ID = "SET_BUILDING_ID"
const ADD_BUILDING_RESPONSIBLE_PARTY = "ADD_BUILDING_RESPONSIBLE_PARTY"
const DELETE_BUILDING_RESPONSIBLE_PARTY = "REMOVE_BUILDING_RESPONSIBLE_PARTY"
const UPDATE_BUILDING_RESPONSIBLE_PARTY = "UPDATE_BUILDING_RESPONSIBLE_PARTY"
const UPDATE_BUILDING_RESPONSIBLE_PARTY_ROLES = "UPDATE_BUILDING_RESPONSIBLE_PARTY_ROLES"

const OPEN_MODAL_FOR_CONTEXT = "OPEN_MODAL_FOR_CONTEXT"
const CLOSE_MODAL = "CLOSE_MODAL"
const TOGGLE_ROLE_ON = "TOGGLE_ROLE_ON"
const TOGGLE_ROLE_OFF = "TOGGLE_ROLE_OFF"
const TOGGLE_ALTERATIONS_ROLE_ON = "TOGGLE_ALTERATIONS_ROLE_ON"
const UPDATE_MODAL_USER_DETAILS = "UPDATE_MODAL_USER_DETAILS"
const TOGGLE_ROLE_CUSTOM_PACKAGE_TYPES = "TOGGLE_ROLE_CUSTOM_PACKAGE_TYPES"
const SET_ROLE_PACKAGE_TYPE = 'SET_ROLE_PACKAGE_TYPE'
const TOGGLE_QUESTIONNAIRE_PROCESSOR_WITH_FILES = "TOGGLE_QUESTIONNAIRE_PROCESSOR_WITH_FILES"
const TOGGLE_QUESTIONNAIRE_PROCESSOR_WITHout_FILES = "TOGGLE_QUESTIONNAIRE_PROCESSOR_WITHout_FILES"
const SET_MODAL_USER_DETAILS = 'SET_MODAL_USER_DETAILS'
const SET_MODAL_USER_ROLES = 'SET_MODAL_USER_ROLES'
const SET_ERROR_MODAL_DETAILS = 'SET_ERROR_MODAL_DETAILS'

const mainActions = {
  setBuildingResponsiblePartyType(buildingResponsiblePartyType) { return { type: SET_BUILDING_RESPONSIBLE_PARTY_TYPE, buildingResponsiblePartyType } },
  setBuildingResponsibleParties(buildingResponsibleParties) { return { type: SET_BUILDING_RESPONSIBLE_PARTIES, buildingResponsibleParties } },
  setBuildingResponsiblePartyTitles(titles) { return { type: SET_BUILDING_RESPONSIBLE_PARTY_TITLES, titles } },
  setBuildingResponsiblePartyRolesGroupedByEmail(roles) { return { type: SET_BUILDING_RESPONSIBLE_PARTY_ROLES_GROUPED_BY_EMAIL, roles } },
  setBuildingId(buildingId) { return { type: SET_BUILDING_ID, buildingId } },
  addBuildingResponsibleParty(buildingResponsibleParty) { return { type: ADD_BUILDING_RESPONSIBLE_PARTY, buildingResponsibleParty } },
  deleteBuildingResponsibleParty(buildingResponsiblePartyId) { return { type: DELETE_BUILDING_RESPONSIBLE_PARTY, buildingResponsiblePartyId } },
  updateBuildingResponsibleParty(buildingResponsibleParty) { return { type: UPDATE_BUILDING_RESPONSIBLE_PARTY, buildingResponsibleParty } },
  updateBuildingResponsiblePartyRoles(userEmail, roles) { return { type: UPDATE_BUILDING_RESPONSIBLE_PARTY_ROLES, userEmail, roles } }
}

const modalActions = {
  toggleRoleOn(role) { return { type: TOGGLE_ROLE_ON, role } },
  toggleRoleOff(role) { return { type: TOGGLE_ROLE_OFF, role } },
  toggleAlterationsRoleOn(role) { return { type: TOGGLE_ALTERATIONS_ROLE_ON, role } },
  openModalForContext(context) { return { type: OPEN_MODAL_FOR_CONTEXT, context } },
  closeModal() { return { type: CLOSE_MODAL } },
  updateModaluserDetails(propertyName, value) { return { type: UPDATE_MODAL_USER_DETAILS, propertyName, value } },
  toggleRoleCustomPackageTypes(role, bool) { return { type: TOGGLE_ROLE_CUSTOM_PACKAGE_TYPES, role, bool } },
  setRolePackageType(role, package_types) { return { type: SET_ROLE_PACKAGE_TYPE, role, package_types } },
  toggleQuestionnaireProcessorWithFiles(bool) { return { type: TOGGLE_QUESTIONNAIRE_PROCESSOR_WITH_FILES, bool } },
  toggleQuestionnaireProcessorWithoutFiles(bool) { return { type: TOGGLE_QUESTIONNAIRE_PROCESSOR_WITHout_FILES, bool } },
  setModalUserDetails(userDetails) { return { type: SET_MODAL_USER_DETAILS, userDetails } },
  setModalUserRoles(roles) { return { type: SET_MODAL_USER_ROLES, roles } },
  setErrorModalDetails(details) { return { type: SET_ERROR_MODAL_DETAILS, details } }
}

// Initial State
const mainInitialState = {
  buildingResponsiblePartyType: null,
  buildingId: null,
  buildingResponsibleParties: [],
  buildingResponsiblePartyTitles: {},
  buildingResponsiblePartyRolesGroupedByEmail: {}
}

const modalInitialState = {
  roles: {
    package_processor: { selected: false, select_custom_package_types: false, package_types: [] },
    property_executive: { selected: false },
    minutes_processor: { selected: false },
    questionnaire_processor: { selected: false, withFiles: false, withoutFiles: false },
    alterations_processor: { selected: false, select_custom_package_types: false, package_types: [] },
    alterations_property_architect: { selected: false },
    alterations_viewer: { selected: false },
    resident_payments_processor: { selected: false },
  },
  modalOpenForContext: null,
  modalUserDetails: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    phone_ext: null,
    title: null,
    is_visible: false,
    is_phone_hidden: false
  },
  modalErrorDetails: {
    name: null,
    package_types: [],
  }
}

/*
  * Reducers
  *
  ******************************************/

function reducer(state = mainInitialState, action) {
  let newState
  switch(action.type) {
  case SET_BUILDING_RESPONSIBLE_PARTY_TYPE:
    return { ...state, buildingResponsiblePartyType: action.buildingResponsiblePartyType }
  case SET_BUILDING_RESPONSIBLE_PARTIES:
    return { ...state, buildingResponsibleParties: action.buildingResponsibleParties }
  case SET_BUILDING_RESPONSIBLE_PARTY_TITLES:
    return {...state, buildingResponsiblePartyTitles: action.titles }
  case SET_BUILDING_RESPONSIBLE_PARTY_ROLES_GROUPED_BY_EMAIL:
    return { ...state, buildingResponsiblePartyRolesGroupedByEmail: action.roles }
  case SET_BUILDING_ID:
    return {...state, buildingId: action.buildingId}
  case ADD_BUILDING_RESPONSIBLE_PARTY:
    newState = $.extend(true, {}, state)
    newState.buildingResponsibleParties.push(action.buildingResponsibleParty)
    return newState
  case DELETE_BUILDING_RESPONSIBLE_PARTY: {
    newState = $.extend(true, {}, state)
    const index = newState.buildingResponsibleParties.findIndex(buildingResponsibleParty => buildingResponsibleParty.id == action.buildingResponsiblePartyId)
    newState.buildingResponsibleParties.splice(index, 1)
    return newState
  }
  case UPDATE_BUILDING_RESPONSIBLE_PARTY: {
    newState = $.extend(true, {}, state)
    const index = newState.buildingResponsibleParties.findIndex(pc => pc.id == action.buildingResponsibleParty.id)
    newState.buildingResponsibleParties[index] = action.buildingResponsibleParty
    return newState
  }
  case UPDATE_BUILDING_RESPONSIBLE_PARTY_ROLES:
    newState = $.extend(true, {}, state)
    newState.buildingResponsiblePartyRolesGroupedByEmail[action.userEmail] = action.roles
    return newState
  default:
    return state
  }
}

function modalReducer(state = modalInitialState, action) {
  let newState = $.extend(true, {}, state)

  switch(action.type) {
  case TOGGLE_ROLE_ON:
    newState.roles[action.role].selected = true
    return newState
  case TOGGLE_ROLE_OFF:
    console.log(action.role)
    newState.roles[action.role].selected = false
    return newState
  case TOGGLE_ALTERATIONS_ROLE_ON:
    let rolesToDeselect = ['alterations_processor', 'alterations_property_architect', 'alterations_viewer']

    rolesToDeselect.splice(rolesToDeselect.indexOf(action.role), 1)
    action.role ? newState.roles[action.role].selected = true : null
    rolesToDeselect.forEach(role => { newState.roles[role].selected = false })
    return newState
  case OPEN_MODAL_FOR_CONTEXT:
    newState.modalOpenForContext = action.context
    return newState
  case CLOSE_MODAL:
    return $.extend(true, {}, modalInitialState)
  case UPDATE_MODAL_USER_DETAILS:
    if (!(action.propertyName in state.modalUserDetails))
      throw new Error(`${action.propertyName} is not a valid property for the user object.`)

    newState.modalUserDetails[action.propertyName] = action.value
    return newState
  case TOGGLE_ROLE_CUSTOM_PACKAGE_TYPES:
    if (!newState.roles[action.role].hasOwnProperty('select_custom_package_types'))
      throw new Error(`${action.role} does not support custom package types`)

    newState.roles[action.role].select_custom_package_types = action.bool
    if (action.bool == false)
      newState.roles[action.role].package_types = []

    return newState
  case SET_ROLE_PACKAGE_TYPE:
    newState.roles[action.role].package_types = action.package_types
    return newState
  case TOGGLE_QUESTIONNAIRE_PROCESSOR_WITH_FILES:
    newState.roles.questionnaire_processor.withFiles = action.bool
    return newState
  case TOGGLE_QUESTIONNAIRE_PROCESSOR_WITHout_FILES:
    newState.roles.questionnaire_processor.withoutFiles = action.bool
    return newState
  case SET_MODAL_USER_DETAILS:
    newState.modalUserDetails = {...modalInitialState.modalUserDetails, ...action.userDetails}
    return newState
  case SET_MODAL_USER_ROLES:
    newState.roles = {...modalInitialState.roles, ...action.roles}
    newState.roles.package_processor.select_custom_package_types = newState.roles.package_processor.package_types.length > 0
    newState.roles.alterations_processor.select_custom_package_types = newState.roles.alterations_processor.package_types.length > 0
    return newState
  case SET_ERROR_MODAL_DETAILS:
    newState.modalErrorDetails = {...newState.modalErrorDetails, ...action.details}
    return newState
  default:
    return newState
  }
}

const getStore = () => createStore(combineReducers({
  main: reducer,
  modal: modalReducer
}))

export default Object.assign({getStore}, mainActions, modalActions)
