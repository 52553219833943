import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react'

import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import first from 'lodash/first'

import { viewContext } from './viewContext'; // adjust the path
import { selectOwner, useFetchParticipantsQuery, useGetInitialLoadQuery } from '../features/api/apiSlice'


const filterGroups = recipients => recipients.filter(recipient => !recipient.user)
const filterUsers = recipients => recipients.filter(recipient => recipient.user)

const RecipientContext = createContext()

export function useRecipients() {
  const context = useContext(RecipientContext);
  if (!context) {
    throw new Error('useRecipients must be used within a RecipientProvider');
  }
  return context;
}

export function RecipientProvider({ children }) {
  const [allRecipients, setAllRecipients] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set());

  const setRecipients = useCallback((recipients) => {
    setAllRecipients(recipients);
    setSelectedIds(new Set()); // clear selections on update
  }, []);

  const selectRecipients = useCallback((ids) => {
    setSelectedIds(prev => {
      const updated = new Set(prev);
      ids.forEach(id => updated.add(id));
      return updated;
    });
  }, []);

  const deselectRecipients = useCallback((ids) => {
    setSelectedIds(prev => {
      const updated = new Set(prev);
      ids.forEach(id => updated.delete(id));
      return updated;
    });
  }, []);
  

  const selectedRecipients = useMemo(() => {
    return allRecipients.filter(r => selectedIds.has(r.value));
  }, [allRecipients, selectedIds]);

  const unselectedRecipients = useMemo(() => {
    return allRecipients.filter(r => !selectedIds.has(r.value));
  }, [allRecipients, selectedIds]);

  const recipientsHash = useMemo(() => mapValues(groupBy(allRecipients, 'value'), first), [ allRecipients ])
  const usersByRole = useMemo(() => groupBy(filterUsers(allRecipients), 'role'), [ allRecipients ])

  const value = {
    allRecipients,
    recipientsHash,
    usersByRole,
    selectedRecipients,
    unselectedRecipients,
    setRecipients,
    selectRecipients,
    deselectRecipients,
  };

  return (
    <RecipientContext.Provider value={value}>
      {children}
    </RecipientContext.Provider>
  );
}

export function RecipientSyncer({ page = 1 }) {
  const { unique_owner_id, threadsPerPage } = useContext(viewContext);
  const { data, refetch } = useFetchParticipantsQuery({ unique_owner_id }, {
    refetchOnMountOrArgChange: true,
  });

  const { owner } = useGetInitialLoadQuery({
    unique_owner_ids: [unique_owner_id],
    per_page: threadsPerPage,
    page,
  }, {
    selectFromResult: (result) => ({
      owner: selectOwner(result, unique_owner_id),
    }),
  });

  const { setRecipients } = useRecipients();

  useEffect(() => {
    if (!data || !owner) return;

    const allowedGroups = data.my_allowed_groups || [];
    const allowedUsers = data.my_allowed_users || [];
    const roles = owner.roles || {};

    const groupRecipients = allowedGroups.map((value) => ({
      value,
      id: value,
      label: roles[value]?.display_select,
    }));

    const userRecipients = allowedUsers.map((user) => ({
      id: user.unique_id,
      value: user.unique_id,
      label: user.full_name,
      user: true,
      role: user.role,
      role_label: user.label
    }));

    const all = [...groupRecipients, ...userRecipients];
    setRecipients(all);
  }, [data, owner, setRecipients]);

  useEffect(() => {
    const handleAttachUserEvent = (event) =>refetch()

    window.addEventListener('attachUserEvent', handleAttachUserEvent)
    window.addEventListener('rescindUserEvent', handleAttachUserEvent)

    return () => {
      window.removeEventListener('attachUserEvent', handleAttachUserEvent)
      window.removeEventListener('rescindUserEvent', handleAttachUserEvent)
    }

  }, [])

  return null; // this component just syncs; it doesn’t render anything
}