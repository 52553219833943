import React, { useState, useCallback } from 'react'
import FormField from 'components/form_field'

function Condition({isOpen, display, children, onChange}) {
  const [ opened, setOpened ] = useState(isOpen)

  const handleClick = useCallback(() => {
    onChange ? onChange(!opened) : null
    setOpened(!opened)
  }, [opened, setOpened, onChange])

  return <>
    <input type="checkbox" value="1" checked={!!opened} onChange={() => {}} onClick={handleClick} />
    <div className="display">{display}</div>
    <div className="input">
      { opened ? children : null }
    </div>
  </>
}

function ConditionalField({field, id, values, onChange, display}) {
  const isBoolean = field.type == "boolean"

  const handleChange = useCallback(hash => {
    onChange ? onChange(hash) : null
  }, [onChange])

  const handleCheck = useCallback(enable => {
    if (!enable) return onChange ? onChange({[id]: null}) : null
    if (isBoolean) return onChange ? onChange({[id]: 1}) : null
  }, [ isBoolean, id, onChange ])

  display ||= field.label

  return <div className="conditional-field-component">
  <Condition isOpen={values[id]} display={display} onChange={handleCheck}>
    { isBoolean ? null : <FormField skipLabel={true} field={field} id={id} value={values} onChange={handleChange} /> }
  </Condition>
</div>
}

export default ConditionalField