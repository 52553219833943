import React, { useState } from 'react'

const Nav = ({navLabels, onLabelClick, classes}) => {
  const [activeLabel, setActiveLabel] = useState(navLabels[0])

  const handleClick = (e) => {
    onLabelClick(e.target.id)
    setActiveLabel(e.target.id)
  }

  return (
    <div className={`sidebar-nav ${classes ?? ""}`}>
      {navLabels.map((label, idx) => (
        <div
          key={idx}
          id={label}
          className={`nav-link ${activeLabel === label ? "active" : ""}`}
          onClick={handleClick}
        >
          {label.toLowerCase()}
        </div>
      ))}
    </div>
  )
}

export default Nav
