import React from 'react'

class ConfirmTab extends React.Component {
  constructor(props) {
    super(props)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleConfirmClick = this.handleConfirmClick.bind(this)
  }

  handleCancelClick () {
    this.props.changeTab('DEFAULTTAB')
  }

  handleConfirmClick () {
    this.props.createPackageUser(this.props.collaborator)
  }

  renderText() {
    if (this.props.noRequirements)
      return <p>This will send an email invitation to the Deal Party. Are you sure you want to proceed?</p>

    return <div>
      <p>This will send an email invitation to the {this.props.isAlteration ? "Resident Team" : "Deal Party"} allowing them to access the Documents and Requirements that you have selected.</p>
      <p>You can edit the visibility settings at any time.</p>
    </div>
  }

  render () {
    return (
      <div className='confirm-package-user-tab'>
        {this.renderText()}
        <button className='pull-left margin-left swal-neutral-button collaborator-hover' onClick={this.handleCancelClick}>Cancel</button>
        <button className='pull-right  margin-right swal-success-button collaborator-hover' onClick={this.handleConfirmClick}>Confirm</button>
      </div>
    )
  }
}

export default ConfirmTab
