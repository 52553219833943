import React, { Component } from "react"
export default class RefundPrice extends Component {
  constructor(props) {
    super(props)

  }

  render(){
    return(
      <div>
        <h2>Refund Price</h2>
      </div>
    )
  }
};
    


