import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { uniqBy, differenceBy, intersection } from 'lodash'
import { asArray } from 'lib/utilities'

import { switchableParties } from '../utilities'
import Person from './person'


function useCanPersonBeDealParty() {
  const dealParties = useSelector(({roles}) => roles.deal_parties)
  const canPersonBeParty = useCanPersonBeParty()

  return useCallback((person, deal_party_id) => {
    if (!deal_party_id)
      return false

    return canPersonBeParty(person, dealParties[deal_party_id].party)
  }, [dealParties])
}

function useCanPersonBeParty() {
  const metadata = useSelector(({roles}) => roles.deal_party_metadata)

  return useCallback((person, party_id) => {
    if (!person.party_id || !party_id)
      return false

    return switchableParties(metadata[party_id], metadata[person.party_id])
  }, [metadata])
}

function useCanPersonBeRole() {
  return useCallback((user, role_id) => {
    if (!user || !role_id)
      return false

    return true
  }, [])
}

function usePersonCheck({deal_party_id, party_id, role_id}) {
  const canPersonBeDealParty = useCanPersonBeDealParty()
  const canPersonBeParty = useCanPersonBeParty()
  const canPersonBeRole = useCanPersonBeRole()
  
  return useCallback(person => {
    if (canPersonBeDealParty(person, deal_party_id))
      return true

    if (canPersonBeParty(person, party_id))
      return true

    if (canPersonBeRole(person, role_id))
      return true

    return false
  }, [deal_party_id, party_id, role_id, canPersonBeDealParty, canPersonBeParty, canPersonBeRole])
}

function useFilteredGroupPeople(group_id, person) {
  const groupPeople = useSelector(({roles}) => roles.group_people[group_id])
  const personCheck = usePersonCheck(person)
  
  return useMemo(() => {
    if (!groupPeople)
      return []

    const filteredPeople = asArray(groupPeople).filter(personCheck)
    return uniqBy(filteredPeople, 'email')
  }, [groupPeople, personCheck]) 
}

function useLeftOutPeople(group_ids, person) {
  const groupOrder = useSelector(({roles}) => roles.group_order)
  const groupPeople = useSelector(({roles}) => roles.group_people)
  const personCheck = usePersonCheck(person)

  return useMemo(() => {
    const orderedGroups = intersection(groupOrder, asArray(group_ids))
    const selectedPeople = uniqBy(orderedGroups.map(group_id => asArray(groupPeople[group_id]).filter(personCheck)).flat(), 'email')
    const allPeople = uniqBy(Object.values(groupPeople).flat(), 'email')

    return uniqBy(differenceBy(allPeople, selectedPeople, 'email'), 'email')
  }, [group_ids, groupPeople, personCheck, groupOrder ]) 
}

function Group({label, people, onSelect, selectedEmail, defaultOpen}) {
  const [ open, setOpen ] = useState(defaultOpen)

  return <div className="person-dropdown-group">
    { label && <div className="title" onClick={() => setOpen(!open)}>
      <div>{label}</div>
      <div className="carot">
        <i className={`fa-light fa-chevron-${open ? 'up' : 'down'}`}></i>
      </div>
    </div> }
    { open ? <div className="list">{ people.map(person => <Person key={person.person_id || person.email} selected={person.email == selectedEmail} person={person} onClick={onSelect} />) }</div> : null}
  </div>
}

const personComparator = item => {
  return JSON.stringify(Object.keys(item).sort().map(key => [key, item[key]]))
}

export function AllPeople({defaultOpen, role_id, onSelect, showIfEmpty = false, label=null}) {
  const allGroupPeople = useSelector(({roles}) => roles.group_people)
  const userRoles = useSelector(({roles}) => roles.user_roles)
  
  const people = useMemo(() => {
    return uniqBy(Object.values(allGroupPeople).flat(), 'email')
  }, [allGroupPeople])

  const filteredPeople = useMemo(() => {
    return people.filter(person => {
      const roles = userRoles[person.email] || []
      return !roles.includes(role_id)
    })
  }, [people, role_id, userRoles])

  if (filteredPeople.length == 0 && !showIfEmpty)
    return null

  return <Group label={label} people={filteredPeople} defaultOpen={defaultOpen} onSelect={onSelect} /> 
}

export function GroupPeople({group_id, deal_party_id, party_id, defaultOpen, onSelect, selectedEmail, showIfEmpty = false}) {
  const group = useSelector(({roles}) => roles.groups[group_id])  
  const people = useFilteredGroupPeople(group_id, { deal_party_id, party_id})

  if (people.length == 0 && !showIfEmpty)
    return null

  return <Group label={group.label} people={people} defaultOpen={defaultOpen} selectedEmail={selectedEmail} onSelect={onSelect} />
}

export function OtherPeople({label = "Other People", group_ids, deal_party_id, party_id, role_id, onSelect, selectedEmail, defaultOpen }) {

  const people = useLeftOutPeople(group_ids, { deal_party_id, party_id, role_id})

  return <Group label={label} people={people} defaultOpen={defaultOpen} selectedEmail={selectedEmail} onSelect={onSelect} />
}

export default GroupPeople