import React, { useMemo } from 'react'
import Form from './Form'
import SSOCheck from './SSOCheck'
import StandardLogin from './StandardLogin'
import ForgotPassword from './ForgotPassword'
import PasswordField from './PasswordField'
import EmailField from './EmailField'

const Forms = (props) => {
  const { currentStep, checkSSO, postLogin, sendRecovery, handleSubmit, isSubmitSuccessful, updatePasswordFieldState } =
    props

  const formProps = useMemo(() => {
    switch (currentStep) {
      case 1:
        return {
          submitFn: checkSSO,
        }
      case 2:
        return {
          submitFn: postLogin,
          action: '/sessions_create_with_json',
          method: 'POST',
        }
      case 3:
        return {
          submitFn: sendRecovery,
          action: '/users/password',
          method: 'POST',
        }
      default:
        break
    }
  }, [currentStep, checkSSO, postLogin, sendRecovery])

  return (
    <Form {...formProps} handleSubmit={handleSubmit}>
      <>
        <div className="login">
          <EmailField
            ref={props.inputRef}
            register={props.register}
            currentStep={props.currentStep}
            errors={props.errors}
            reset={props.reset}
            isDirty={props.isDirty}
            onClick={props.updateStep}
            resetPassword={props.resetPassword}
          />
          {currentStep === 1 && <SSOCheck isLoading={props.isLoading} isValidEmail={props.isValidEmail} />}
        </div>
        <PasswordField
          register={props.register}
          ref={props.inputRefPassword}
          onKeyUp={updatePasswordFieldState}
          updateStep={props.updateStep}
          currentStep={props.currentStep}
          hidden={props.currentStep === 1 || props.currentStep === 3}
        />
        {currentStep === 2 && <StandardLogin updateStep={props.updateStep} isLoading={isSubmitSuccessful} hasInput={props.hasInput} />}
        {currentStep === 3 && (
          <ForgotPassword
            updateStep={props.updateStep}
            resetPassword={props.resetPassword}
            isValidEmail={props.isValidEmail}
          />
        )}
      </>
    </Form>
  )
}

export default Forms
