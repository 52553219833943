import { v4 as uuidv4 } from 'uuid'
// import { init } from '../contexts/FeeContext'

export const sortFees = arr => [...arr].sort((a,b) => a.order-b.order)

export const feeReducer = (state, action) => {
  switch(action.type) {
    case 'ADD_FEE':
      return [
        ...state,
        {
          id: null,
          name: "",
          description: "",
          type: action.payload.feeType,
          editable: true,
          amount: 0,
          waived: false,
          sub_merchant_id: (action.payload.subMerchants.find(sm => sm.default == true))?.id,
          gl_code: "",
          charge_code: "",
          payor: "",
          money_order_num: "",
          date_received: "",
          order: state.length,
          uuid: uuidv4()
        },
      ]
    case 'REMOVE_FEE':
      const filteredAndReorderedFees = state
        .filter(fee => fee.uuid !== action.id)
        .map((fee, i) => ({ ...fee, order: i }))
      return filteredAndReorderedFees
    case 'EDIT_FEES':
      const feeIds = action.editedFees.map(fee => fee.uuid)
      const feesCopy = state.filter(fee => !feeIds.includes(fee.uuid))
      return sortFees([...feesCopy, ...action.editedFees])
    // case 'RESET_FEES':
    //   return init(action.payload)
    case 'RESET_DEFAULT_FEES':
      const fees = action.editedFees.map((fee, idx) => ({ ...fee, order: idx, uuid: uuidv4() }))
      return sortFees(fees)
    case 'UPDATE_FEES':
      return sortFees(action.updatedFees.map((fee, idx) => ({ ...fee, order: fee.order, uuid: uuidv4() })))
    default:
      return state

  }
}
