import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pick, isPlainObject} from 'lodash'

import { useEventListeners } from '@deathbyjer/react-event-manager'

import { removeComponents, setSelected, useComponentCreator } from './componentSlice'

import DrawArea, { DUPLICATE_KEYS } from 'components/utility/draw_area'
import usePreparedComponents from './usePreparedComponents'
import { Tools } from '../../common/toolbar/Tools'
import useZoomHandler from 'components/form_builder/common/hooks/useZoomHandler'

const DELETE_KEY = 8 || 46
const CTRL_KEY = 17
const PLUS_KEY = 187
const MINUTE_KEY = 189

const EMPTY_FUNCTION = () => {}

function useGenerateCallback(page) {
  const createComponent = useComponentCreator()
  const activeToolName = useSelector(({tools}) => tools.active)
  const tool = Tools[activeToolName]

  return useCallback((point) => {
    if (!tool?.createsComponent)
      return

    const component = {
      page,
      type: tool.name,
      ...pick(tool.defaultAttributes, ['options', 'rect']),
      ...( isPlainObject(tool.createsComponent) ? tool.createsComponent : {} )
    }
    component.rect = { ...point, ...component.rect }

    createComponent(component)
  }, [ createComponent, page, tool ])
}

function usePageComponents(page) {
  const components = usePreparedComponents()

  return useMemo(() => {
    return components?.filter(component => component.props.page == page)
  }, [components, page])
}

function useEventMonitors() {
  const dispatch = useDispatch()
  const selectedComponents = useSelector(state => state.components.selected )
  const { zoomIn, zoomOut } = useZoomHandler()
  const isHoldingCrl = useRef(false)

  const removeSelected = useCallback(() => {
    dispatch(removeComponents(selectedComponents))
  }, [dispatch, selectedComponents])

  const onClick = useCallback(evt => {}, [])

  const onKeyDown = useCallback(evt => {
    switch(evt.keyCode) {
      case CTRL_KEY:
        isHoldingCrl.current = true
        break;
      case PLUS_KEY:
        if(!isHoldingCrl.current) return
        evt.preventDefault() // prevent zoom of browser
        zoomIn()
        break;
      case MINUTE_KEY:
        if(!isHoldingCrl.current) return
        evt.preventDefault() // prevent zoom of browser
        zoomOut()
        break
      default:
        break;
    }
  }, [ zoomIn, zoomOut ])

  const onKeyUp = useCallback(evt => {
    switch(evt.keyCode) {
      case DELETE_KEY:
        removeSelected()
        break;
      case CTRL_KEY:
        isHoldingCrl.current = false
        break
      default:
        break;
    }
  }, [removeSelected])


  return useMemo(() => ({
    onClick, onKeyDown, onKeyUp
  }), [onClick, onKeyDown, onKeyUp])
}

const DocumentArea = ({page, id}) => {
  const dispatch = useDispatch()
  const currentPageWidth = useSelector(({pages}) => pages.pageWidth)
  const {width: pageWidth, height: pageHeight } = useSelector(({pages}) => pages.all[page].page_sizes[currentPageWidth])
  const {url: imageUrl } = useSelector(({pages}) => pages.all[page])
  const activeToolName = useSelector(({ tools }) => tools.active)
  const tool = Tools[activeToolName]
  const drawArea = useRef()

  useEventListeners({
    selectComponent: (componentId) => {
      drawArea.current?.selectChildren(componentId)
    },
    duplicateComponent: (componentIds) => {
        // this function only runs after new duplicate elements have been created on the DOM 
        setTimeout(() => {
          drawArea.current?.stopDragging()
          drawArea.current?.unselectAll()
          componentIds.map(componentId => drawArea.current?.selectChildren(componentId))
        }, 0);
    },
    resizeSelf: () => {
      drawArea.current?.resizeSelf()
    }
  })

  const eventActions = useEventMonitors()

  const components = usePageComponents(page)
  const generate = useGenerateCallback(page)

  const updateSelected = useCallback(list => {
    dispatch(setSelected(list))
  }, [dispatch])

  const style = {
    aspectRatio: pageWidth / pageHeight,
    backgroundImage: `url(${imageUrl})`,
    cursor: tool?.defaultAttributes?.options?.cursor ?? 'default'
  }

  return (
    <div id={id} className="document-area" style={style}>
      <DrawArea
        ref={drawArea}
        onEmptyMousedown={generate}
        onUpdatedSelected={updateSelected}
        {...eventActions}
      >
        { components }
      </DrawArea>
    </div>

  )
}

export default DocumentArea
