import React, { useEffect, useRef } from 'react'
import useLoader from '../../../plugins/leases_plugin/javascripts/fees/hooks/useLoader'


const StripeMandateModal = ({setCurrentModal, onMandateAccepted, onModalCancel}) => {
  const ref = useRef()
  const { loading, finishedLoading, withLoader } = useLoader()

  const cleanup = () => $(ref.current).modal("hide")
  const handleCancel = () => {
    onModalCancel()
    cleanup()
  }
  const execute = async () => {
    await onMandateAccepted()
    cleanup()
  }

  const executeWithLoader = () => {
    withLoader(execute)
  }

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <>
      <div className='lease-fees-modal'>
        <div ref={ref} className='modal fade'>
          <div className='modal-box mandate'>
            <div className='header-with-logo'>
              <i className='stripe-wordmark'></i>
              <h3>Mandate</h3>
            </div>
            <div className='body'>
              <p>
                By selecting <span>accept</span>, you authorize BoardPackager to debit the
                bank account specified for any amount owed for charges arising
                from your use of BoardPackager's website and terms, until this
                authorization is revoked.
              </p>
              <p>
                You may amend or cancel this authorization at any time by
                providing notice to BoardPackager with 30 (thirty) days notice.
              </p>
              <p>
                If you use BoardPackager's services or purchase additional
                products periodically pursuant to BoardPackager's terms, you
                authorize BoardPackager to debit periodically.
              </p>
              <p>
                <em>
                  Payments that fall outside of the regular debits authorized
                  above will only be debited after your authorization is obtained.
                </em>
              </p>
            </div>
            <div className="button-container">
              <button className='cancel' disabled={loading || finishedLoading} onClick={handleCancel}>
                Cancel
              </button>
              <button className='stripe-fill' onClick={executeWithLoader}>
                {loading ? <i
                  className='fa-duotone fa-loader fa-spin-pulse'
                  style={{"--fa-primary-color": '#f7f7f7', "--fa-secondary-color": '#c9c9c9'}}
                ></i> : 'Accept'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StripeMandateModal
