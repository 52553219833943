import React from 'react'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import lemonadeLogo from '/app/assets/images/insurance/logos/lemonade.png'

export default function InsuranceAd() {
  return (
    <div className="insurance-ad dome-d-flex dome-align-center dome-gap9 dome-light-shadow">
      <div className="ad-icon-wrapper display-only-desktop">
        <i className="ad-icon fa-light fa-umbrella dome-color-green-gradient"></i>
      </div>
      <div className="dome-d-flex dome-flex-column dome-align-start dome-full-width">
        <div className="dome-d-flex dome-align-center dome-gap12 dome-justify-between dome-full-width">
          <a
            href="/about/insurance"
            target="_blank"
            className="dome-text-w500 dome-link dome-link-aqua dome-link-chevron-right dome-color-green-gradient"
          >
            insurance
          </a>
          <img src={chubbLogo} alt="chubb logo" style={{ width: '59px' }} />
        </div>
        <div className="dome-d-flex dome-align-center dome-gap12 dome-justify-between dome-full-width">
          <span className="dome-text-w300 dome-p3">Match requirements instantly!</span>
          <img src={lemonadeLogo} alt="lemonade logo" style={{ width: '58px' }} />
        </div>
      </div>
    </div>
  )
}
