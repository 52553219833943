import React from 'react'

const Header = ({headerTitle, children}) => {
  return (
    <div className='header-container dome-d-flex dome-justify-between dome-align-center'>
      <div className="dome-h4">{headerTitle}</div>
      {children}
    </div>
  )
}

export default Header
