import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

export default function Tooltip({ placement, title, children }) {
  const tooltipRef = useWatchableRef(null);

  const childrenProps = {
    ref: tooltipRef.ref,
  };

  useEffect(() => {
    if (!tooltipRef.current) return;

    $(tooltipRef.current).tooltip({
      placement,
      title,
    });
  }, [tooltipRef.version]);

  return React.cloneElement(React.Children.only(children), childrenProps);
}

Tooltip.propTypes = {
  placement: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
