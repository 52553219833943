import React from "react";

export default function EnableRpLeaseId({ enabled, changeValue }) {
  const handleChange = (e) => {
    changeValue(parseInt(e.currentTarget.value) == 1);
  };

  return (
    <div className="row">
      <div className="col-sm-4">Enable Lease Id?</div>
      <div className="col-sm-4">
        <select value={enabled ? "1" : "0"} onChange={handleChange}>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
    </div>
  );
}
