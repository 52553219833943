import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SectionDivider from '../SectionDivider'
import { updateTemplate, useDataTemplate } from '../../../features/data/dataSlice'
import { useDispatch } from 'react-redux'
import { isValidJson } from 'components/form/utilities'
import { omit } from 'lodash-es'

const INDENT = 4

const Templating = ({id, component}) => {
  const template = useDataTemplate(...id)
  const dispatch = useDispatch()
  const textAreaRef = useRef()
  const [input, setInput] = useState(JSON.stringify(omit(template, 'fields'), null, INDENT))
  const isValid = useMemo(() => isValidJson(input), [ input ])
  const [inputInFocus, setInputInFocus] = useState(false)
  const prevFocusState = useRef(false)

  const templateString = useMemo(() => {
    return JSON.stringify(omit(template, 'fields'), null, INDENT)
  }, [template])

  useEffect(() => {
    if (templateString) {
      setInput(templateString)
    }
  }, [templateString])

  const handleInputChange = e => {
    const { value } = e.target
    setInput(value)
  }

  const handleKeyDown = useCallback((e) => {
    if (inputInFocus){
      if (e.ctrlKey || e.metaKey) {
        switch (e.code) {
          case "KeyS":
            e.preventDefault()
            storeIfValid()
            break
        }
      }
    }

    if (e.target === textAreaRef.current) {
      e.stopPropagation();
    }
  }, [textAreaRef, storeIfValid, input, inputInFocus])

  const storeIfValid = useCallback(() => {
    try {
      const data = JSON.parse(input)
      dispatch(updateTemplate({id: component.id, type: component.type, template: data}))
    } catch (e) {}
  }, [input, dispatch, component])

  const handleBlur = () => {
    prevFocusState.current = inputInFocus
    setInputInFocus(false)
  }

  const handleFocus = () => {
    prevFocusState.current = inputInFocus
    setInputInFocus(true)
  }

  useEffect(() => {
    if (isValid && prevFocusState.current && !inputInFocus) {
      storeIfValid()
    }
  }, [inputInFocus, prevFocusState])

  const templateStyle = {
    borderColor: isValid ? "#dbdbdb" : "#d7272d",
  }
  return (
    <SectionDivider sectionName='templating'>
      <textarea
        ref={textAreaRef}
        className='scrollable'
        value={input || "{}"}
        style={templateStyle}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
    </SectionDivider>
  )
}

export default Templating
