import React from 'react'
import { Provider } from 'react-redux'
import App from './app/App'
import store from './app/store'
import { UserContextProvider } from './context/userContext'
import { ViewContextProvider } from './context/viewContext'

const Main = (props) => {
  if (!props.current_user) return <div>You must be logged in to use messenger.</div>

  return (
    <Provider store={store}>
      <UserContextProvider current_user={props.current_user}>
        <ViewContextProvider
          owner_id={props.owner_id}
          owner_class={props.owner_class}
          messagesPerPage={props.messagesPerPage}
          threadsPerPage={props.threadsPerPage}
        >
          <App defaultOpen={props.defaultOpen} newMessages={props.total_unread_messages} />
        </ViewContextProvider>
      </UserContextProvider>
    </Provider>
  )
}

export default Main
