import React, { useCallback, useState } from 'react'
import { BuildingNotesModal } from './BuildingNotesModal'
import { OrgNotesModal } from './OrgNotesModal'

export function BuildingNotes(props) {
  const [open, setOpen] = useState(false)

  const openBuildingNotesModal = useCallback(() => setOpen(true), [setOpen])
  const closeBuildingNotesModal = useCallback(() => setOpen(false), [setOpen])

  return (
    <>
      <button
        className="dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right"
        onClick={openBuildingNotesModal}
      >
        view all org notes
      </button>
      {props.building_id ? (
        <BuildingNotesModal open={open} onClose={closeBuildingNotesModal} buildingId={props.building_id} orgLogoUrl={props.org_id_property_logo} />
      ) : (
        <OrgNotesModal open={open} onClose={closeBuildingNotesModal} orgLogoUrl={props.org_id_property_logo} />
      )}
    </>
  )
}
