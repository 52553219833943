
import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reject, isEmpty, uniq, difference } from 'lodash'


import Dropdown, { AllPeopleDropdown } from './dropdown'
import { openEditPerson, assignUserRole, store } from './state'

function useName(props) {
  const { deal_party_id, email } = props
  const person_id = useSelector(({roles}) => deal_party_id ? roles.deal_party_people[deal_party_id] : null) || props.person_id

  const personName = useSelector(({roles}) => person_id ? roles.people_names[person_id] : null)
  const roleName = useSelector(({roles}) => email ? roles.user_names[email] : null)

  return personName || roleName
}

function PersonSelect(props) {
  const container = useRef()
  const [open, setOpen] = useState(false)
  const {deal_party_id, party_id, role_id, email } = props
  const party = useSelector(({roles}) => roles.deal_party_metadata[deal_party_id ? roles.deal_parties[deal_party_id].party : party_id])
  const role = useSelector(({roles}) => roles.roles[role_id])

  const name = useName(props)
  const onClose = useCallback(() => setOpen(false), [setOpen])

  useEffect(() => {
    if (!open || !container.current)
      return

    const close = e => {
      if (container.current.contains(e.target))
        return

      setOpen(false)
    }

    window.addEventListener('click', close)
    return () => window.removeEventListener('click', close)
  }, [container.current, open, setOpen])

  const onSelect = useCallback(choice => {
    props.onSelect(choice)
    onClose()
  }, [ props.onSelect, onClose ])

  const dropdown = props.allUsers ?
    <AllPeopleDropdown {...props} onSelect={onSelect} /> :
    <Dropdown {...props} selectedEmail={email} onSelect={onSelect} />

  return <div className="person-select" ref={container}>
    <div className="current-person" onClick={() => setOpen(!open)}>
      <span>{ name || `Select a ${party?.label || role?.label}` }</span>
      <i className={`fa-light fa-chevron-${open ? 'up' : 'down'}`}></i>
    </div>
    { open && dropdown }
  </div>
}

function DealPartyActionDropdown({person_id, deal_party_id}) {
  const dispatch = useDispatch()
  const dealParty = useSelector(({roles}) => roles.deal_parties[deal_party_id])
  const party_id = dealParty.party
  const { changeDataPersonParty, openEditPerson} = store.actions

  const handleEdit = () => dispatch(openEditPerson({person_id, party_id}))
  const handleRemove = () => dispatch(changeDataPersonParty({ person_id, deal_party_id: null }))

  return <div className="action-list">
    <div onClick={handleEdit}>
      <i className="fa-light fa-pencil" />
      <span>Edit Participant Info</span>
    </div>
    <div onClick={handleRemove}>
      <i className="fa-light fa-xmark"></i>
      <div>Unassign from {dealParty.label}</div>
    </div>
  </div>
}

function PersonActions({children}) {
  const [ open, setOpen ] = useState(false)
  const container = useRef()

  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  useEffect(() => {
    if (!open || !container.current)
      return

    const click = e => {
      if (container.current.contains(e.target))
        return

      setOpen(false)
    }

    window.addEventListener('click', click)
    return () => window.removeEventListener('click', click)
  }, [container.current, open, setOpen])

  return <div className="actions" ref={container} onClick={handleClick}>
    <i className="fa-solid fa-ellipsis-vertical"></i>
    { open ? children : null}
  </div>
}

function DealPartyPerson({email, deal_party_id}) {
  const dispatch = useDispatch()
  const dealParty = useSelector(({roles}) => roles.deal_parties[deal_party_id])
  const person_id = useSelector(({roles}) => roles.deal_party_people[deal_party_id])

  const onSelect = useCallback(({person_id, role_id, email}) => {
    if (person_id)
      dispatch(store.actions.changeDataPersonParty({ person_id, deal_party_id }))
    else
      dispatch(store.actions.assignUserToParty({ email, deal_party_id }))
  }, [deal_party_id])

  return <div className="group-person deal-party-person">
    <div className="deal-party">
      <div className="number">
        <span>{dealParty.number_label}</span>
        { dealParty.required ?<span className="required">*</span> : null}
      </div>
      <PersonSelect deal_party_id={deal_party_id} email={email} onSelect={onSelect} />
      <div className="email">{email}</div>
      <div className="phone"></div>
      { person_id ? <PersonActions><DealPartyActionDropdown deal_party_id={deal_party_id} person_id={person_id} /></PersonActions> : <div className="actions" /> }
    </div>
  </div>
}

function PartyActionDropdown({person_id, party_id, email}) {
  const dispatch = useDispatch()

  const handleEdit = () => dispatch(openEditPerson({person_id, party_id}))

  const handleRemove = useCallback(() => {
    dispatch(store.actions.deleteDataPerson({ person_id }))
  }, [dispatch, person_id])

  return <div className="action-list">
    <div onClick={handleEdit}>
      <i className="fa-light fa-pencil" />
      <span>Edit Info</span>
    </div>
    <div onClick={handleRemove}>
      <i className="fa-light fa-xmark"></i>
      <div>Remove</div>
    </div>
  </div>

}

function UserActionDropdown({email, role_id}) {
  const dispatch = useDispatch()
  const isEditable = useSelector(({roles}) => !roles.locked_users[email])

  const handleEdit = () => dispatch(openEditPerson({email, role_id}))

  const handleRemove = useCallback(() => {
    dispatch(store.actions.deleteUser({email, role_id}))
  }, [dispatch, email, role_id])

  return <div className="action-list">
    { isEditable && <div onClick={handleEdit}>
    <i className="fa-light fa-pencil" />
      <span>Edit Info</span>
    </div> }
    <div onClick={handleRemove}>
      <i className="fa-light fa-xmark"></i>
      <div>Remove</div>
    </div>
  </div>
}

function UserPersonSelect({role_id}) {
  const dispatch = useDispatch()
  const onSelect = ({email}) => dispatch(assignUserRole({ email, role_id }))
  return  <div className="group-person party-person">
      <div className="deal-party">
      <div className="number"></div>
      <PersonSelect role_id={role_id} onSelect={onSelect} allUsers={true} />
      <div className="email"></div>
      <div className="phone"></div>
      <div className="actions" />
    </div>
  </div>
}

function UserPerson({email, person_id, party_id, role_id}) {
  const dispatch = useDispatch()
  const onSelect = useCallback(person => {

  }, [party_id, role_id])

  const name = useName({ email, person_id })

  return <div className="group-person party-person">
  <div className="deal-party">
    <div className="number"></div>
    <div className="name">{ name }</div>
    <div className="email">{email}</div>
    <div className="phone"></div>
    <PersonActions>
    { person_id ?
      <PartyActionDropdown email={email} party_id={party_id} person_id={person_id} /> :
      <UserActionDropdown email={email} role_id={role_id} />
    }</PersonActions>
  </div>
</div>
}

function Person(props) {
  if (props.deal_party_id)
    return <DealPartyPerson {...props} />

  return <UserPerson {...props} />
}

function groupPersonKey({deal_party_id, party_id, person_id, email, role_id}) {
  return `${deal_party_id}|${party_id}|${person_id}|${email}|${role_id}`
}

const isDealPartyAssigned = ({person_id}) => person_id ? true : false

function useFilterDealParties() {
  const dealParties = useSelector(({roles}) => roles.deal_parties)
  const partyDealParties = useSelector(({roles}) => roles.party_deal_parties)

  // For each party, what is the last filled deal party
  const generateMaxIndex = useCallback(people => people.reduce((hash, { deal_party_id }) => {
    const party_id = dealParties[deal_party_id].party
    const index = partyDealParties[party_id].indexOf(deal_party_id) + 1

    return {
      ...hash,
      [party_id]: Math.max(hash[party_id] || 0, index)
    }
  }, {}), [dealParties, partyDealParties])

  // The total amount of each filled deal party type
  const generatePartyCount = useCallback(people => people.reduce((hash, person) => {
    const party_id = dealParties[person.deal_party_id].party
    return {
      ...hash,
      [party_id]: (hash[party_id] || 0) + 1
    }
  }, {}), [dealParties])

  return useCallback(people => {
    const filteredPeople = people.filter(isDealPartyAssigned)
    const maxParty = generateMaxIndex(filteredPeople)
    const partyCount= generatePartyCount(filteredPeople)

    const isRequired = ({deal_party_id}) => dealParties[deal_party_id]?.required

    function showIfEmpty({deal_party_id}) {
      const party_id = dealParties[deal_party_id].party
      if (!deal_party_id)
        return false

      const index = partyDealParties[party_id].indexOf(deal_party_id) + 1
      const max = maxParty[party_id]
      const count = partyCount[party_id]

      if ((!count || count == 0) && index == 1)
        return true

      // If our index is less than the max
      if (index < max)
        return true

      // If we are all full and our index is the next one
      if (index == max + 1 && max == count)
        return true

      return false
    }

    return people.filter(person => isDealPartyAssigned(person) || isRequired(person) || showIfEmpty(person))
  }, [dealParties, partyDealParties, generateMaxIndex, generatePartyCount])
}

function useGroupDealPartyPeople(group_id) {
  const groupDealParties = useSelector(({roles}) => roles.group_deal_parties[group_id])
  const dealPartyPeople = useSelector(({roles}) => roles.deal_party_people)
  const peopleEmails = useSelector(({roles}) => roles.people_emails)
  const peopleNames = useSelector(({roles}) => roles.people_names)

  const filterPeople = useFilterDealParties()

  return useMemo(() => {
    if (isEmpty(groupDealParties))
      return []

    // Create people for group list
    const people = groupDealParties.map(deal_party_id => ({
      deal_party_id,
      person_id: dealPartyPeople[deal_party_id]
    })).map(person => ({
      ...person,
      email: peopleEmails[person.person_id],
      name: peopleNames[person.person_id]
    // Generate keys
    })).map(person => ({
      ...person,
      key: groupPersonKey(person)
    // Filter deal parties that do not have matching person_ids
    }))

    return filterPeople(people)
  }, [groupDealParties, dealPartyPeople, peopleEmails, peopleNames])
}

function sortPeople(person_a, person_b) {
  return person_a.name.localeCompare(person_b.name)
}

function useGroupPeople(group_id) {
  const groupPeople = useSelector(({roles}) => roles.group_people[group_id])
  const personDealParties = useSelector(({roles}) => roles.person_deal_parties)
  const peopleNames = useSelector(({roles}) => roles.people_names)
  const userNames = useSelector(({roles}) => roles.user_names)

  return useMemo(() => {
    if (isEmpty(groupPeople))
      return []

    const people = reject(groupPeople, ({person_id}) => {
      return personDealParties[person_id]
    }).map(person => ({
      ...person,
      name: String(peopleNames[person.person_id] || userNames[person.email]),
      key: groupPersonKey(person)
    }))

    return people.sort(sortPeople)
  }, [groupPeople, personDealParties, peopleNames, userNames])
}

function DealPartyPeople({group_id}) {
  const dealPartyPeople = useGroupDealPartyPeople(group_id)
  const otherPeople = useGroupPeople(group_id)

  return <>
    <div className="active-people">
      { dealPartyPeople.map(person => <Person key={person.key} { ...person } />) }
    </div>
    <div className="inactive-people">
      { otherPeople.map(person => <Person key={person.key} { ...person } />) }
    </div>
  </>
}

function RolePeople({group_id}) {
  const people = useGroupPeople(group_id)
  const possibleTypes = usePossibleGroupTypes(group_id)

  return <>
    <div className="active-people">
      { people.map(person => <Person key={person.key} { ...person } />) }
      { possibleTypes.length == 1 && <UserPersonSelect {...possibleTypes[0]} />}
    </div>
  </>
}

function AddPerson({party_id, role_id}) {
  const dispatch = useDispatch()
  const parties = useSelector(({roles}) => roles.deal_party_metadata)
  const roles = useSelector(({roles}) => roles.roles)
  const name = parties[party_id]?.label || roles[role_id]?.label

  const clickAdd = useCallback(e => {
    e.preventDefault()
    dispatch(openEditPerson({party_id, role_id}))
  }, [dispatch, party_id, role_id])

  return <>
    <a href="#" onClick={clickAdd}>Add a {name}</a>
  </>
}

function usePossibleGroupTypes(group_id) {
  const parties = useSelector(({roles}) => roles.group_parties[group_id])
  const roles = useSelector(({ roles }) => roles.group_roles[group_id])

  return useMemo(() => {
    const types = []
    if (parties)
      types.push(parties.map(party_id => ({ party_id })))
    if (roles)
      types.push(roles.map(role_id => ({role_id})))
    return types.flat()
  }, [group_id, parties, roles])
}

function AddToGroup({group_id}) {
  const possibleTypes = usePossibleGroupTypes(group_id)

  if (possibleTypes.length == 0)
    return null

  return <div className="add-people">
    { possibleTypes.map(person => <AddPerson key={groupPersonKey(person)} {...person} /> ) }
  </div>
}

export default function({group_id}) {
  const isDealParty = useSelector(({roles}) => !isEmpty(roles.group_deal_parties[group_id]))
  const group = useSelector(({roles}) => roles.groups[group_id])

  return <div className="group">
    <h3>{group.label}</h3>
    { isDealParty ? <DealPartyPeople group_id={group_id} /> : <RolePeople group_id={group_id} />}

    <AddToGroup group_id={group_id} />
  </div>
}

export function useOtherPeople(groups) {
  const groupPeople = useSelector(({roles}) => roles.group_people)

  return useMemo(() => {
    const people = Object.values(
      omit(groupPeople, groups)
    ).map(person => ({
      ...person,
      name: String(peopleNames[person.person_id] || userNames[person.email]),
      key: groupPersonKey(person)
    }))

    return people.sort(sortPeople)
  }, [groupPeople, ...groups])
}
