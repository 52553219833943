import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compact, isEmpty, mapKeys } from 'lodash'
import { Modal } from 'react-bootstrap'

import { sortObjectKeys } from 'lib/utilities'
import { metadataByPath, valueByPath, hashToPathMap } from 'lib/utilities/form'
import InternalField from 'components/form_field'
import { showError } from 'components/form_field/validations'

import { addPerson, removePerson, usePerson, updateValues, checkErrors, clearPathErrors } from '..'
import { useHideOnClickoutDropdown } from '../utilities'

export default function({label}) {
  const parties = useSelector(({data}) => data.deal_party_metadata)
  const sortedParties = sortObjectKeys(parties)

  return <div className="fftc-section deal-parties-component">
    <div className="fftc-title-area">
      <NewPartyButton />
    </div>
    { sortedParties.map(party_id => <Role key={party_id} party_id={party_id} />)}
  </div>
}

function NewPartyButton() {
  const parties = useSelector(({data}) => data.deal_party_metadata)
  const { container, show, setShow } = useHideOnClickoutDropdown()
  const clickButton = () => setShow(!show)

  useEffect(() => {
    if (!show || !container.current)
      return

    const windowClick = e => container.current.contains(e.target) ? null : setShow(false)
    window.addEventListener('click', windowClick)
    return () => window.removeEventListener('click', windowClick)
  }, [show, setShow, container.current])

  const dropDown = <div className="dropdown">
    { Object.keys(parties).map(party_id => <NewPartyButtonItem key={party_id} role_id={party_id} label={parties[party_id].label} onClick={() => setShow(false)} />)}
  </div>

  return <div className="new-party" ref={container}>
    <button className="button" onClick={clickButton}>
      <span>New Deal Party</span>
      <i className="fa-solid fa-angle-down"></i>
    </button>
    {show ? dropDown : null}
  </div>
}

function NewPartyButtonItem({role_id, label, onClick}) {
  const dispatch = useDispatch()
  const [hover, setHover] = useState(false)
  const clickRole = useCallback(() => {
    dispatch(addPerson(role_id))
    onClick()
  }, [dispatch, role_id, onClick])

  const classes = compact([
    hover ? 'hover' : null
  ])

  return <div className={classes.join(" ")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={clickRole}>
    {label}
  </div>
}

function Role({party_id}) {
  const { label, fields } = useSelector(({data}) => data.deal_party_metadata[party_id])
  const people = useSelector(({data}) => data.people_by_party[party_id] || [])

  return <div className="role">
    <h4>{label}</h4>
    <div>
      { people.map(person_id => <DealParty key={person_id} person_id={person_id} party_id={party_id} fields={fields} />)}
    </div>
  </div>
}

function DealParty(props) {
  const { person_id } = props
  const { container, show, setShow } = useHideOnClickoutDropdown()

  const assignedAs = useSelector(({data}) => data.deal_party_for_person[person_id])

  const classes = compact([
    'deal-party',
    assignedAs ? 'is-assigned' : 'is-unassigned',
    show ? 'is-opened' : 'is-closed'
  ])

  return <div className={classes.join(" ")} ref={container}>
    <PartyCard {...props} show={show} onClick={() => setShow(!show)} />
    { show ? <PartyDropdown {...props} /> : null }
  </div>
}

function lookupErrorsFor({data}, path) {
  const values = valueByPath(data.valuesHash, path)
  const pathMap = hashToPathMap(values || {})
  const errors = Object.keys(pathMap).map(key => `${path}.${key}`).concat([path]).filter(key => data.errors[key])
  return errors.reduce((allErrors, key) => allErrors.concat([data.errors[key]]), []).flat()
}

function PartyCard({person_id, onClick, show}) {
  const {name, email} = usePerson(person_id)
  const assignedAs = useSelector(({data}) => data.deal_party_for_person[person_id])
  const allErrors = useSelector(state => lookupErrorsFor(state, person_id))
  const hasErrors = allErrors.length > 0

  const classes = compact([
    'party-card'
  ])

  return <div className={classes.join(" ")} onClick={onClick}>
    <div>
      <div className="name">
        { name ? name : <i className="required">Full Name Required</i> }
      </div>
      <div className="email">
        { email }
      </div>
    </div>
    { hasErrors ? <div className="has-errors"><i className="fa-light fa-circle-exclamation" /></div> : null }
    <div className="arrow">
      { <i className={`fa-light fa-angle-${show ? 'up' : 'down'}`} /> }
    </div>
  </div>
}

function PartyDropdown({person_id, party_id, fields}) {
  const props = {
    person_id, party_id
  }

  const errors = useSelector(({data}) => data.errors[person_id])
  const generateField = (field, index) => <Field key={field.id} field={field} {...props} />

  return <div className="party-dropdown">
    { /*fieldLines.map(fieldLine => fieldLine.map(generateField)).flat()*/ }
    { isEmpty(errors) ? null : <div className="party-errors">{errors}</div> }
    <div className="fields">
      { fields.map(generateField) }
    </div>
    <RemoveButton person_id={person_id} party_id={party_id} />
  </div>
}

function RemoveButton({person_id, party_id}) {
  const dispatch = useDispatch()
  const [ show, setShow] = useState(false)

  const remove = useCallback(() => {
    dispatch(removePerson({ person_id, role_id: party_id }))
    setShow(false)
  }, [person_id, dispatch, person_id, party_id])

  return <>
    <div className="remove" onClick={() => setShow(true)}>
      Remove
    </div>
    <Modal show={show} size="sm" centered={true} onExit={() => setShow(false)}>
      <Modal.Body>
        <div className="deal-party-removal-confirmation-modal">
          <i className="fa-light fa-circle-exclamation"></i>
          <div>Are you sure?</div>
          <div className="buttons">
            <button className="btn btn-default" onClick={() => setShow(false)}>No</button>
            <button className="btn btn-danger" onClick={() => remove()}>Yes</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
}

const globalPathmap = { getState: state => state.values }

function Field({party_id, field, person_id, firstInLine}) {
  const id = `${person_id}.${field.id}`
  const field_id = [party_id, field.id].join(".")
  const fieldMetadata = useSelector(({data}) => metadataByPath(data.metadata, field_id))
  const value = useSelector(({data}) => valueByPath(data.valuesHash, id))
  const error = useSelector(({data}) => valueByPath(data.errorsHash, id))
  const dispatch = useDispatch()

  const mergedField = useMemo(() => ({ ...fieldMetadata,  ...field }), [fieldMetadata, field])

  const handleBlur = useCallback(() => {
    dispatch(checkErrors({ path: id, required: mergedField.required}))
  }, [dispatch, id, mergedField.required])

  const handleChange = useCallback(hash => {
    dispatch(updateValues(hash))
    dispatch(clearPathErrors(id))
  }, [dispatch, id])

  const classes = compact([
    firstInLine ? 'is-first' : null
  ]).join(" ")

  return <InternalField id={id} className={classes} field={mergedField} onBlur={handleBlur} onChange={handleChange} error={showError(error)} value={value} globalPathmap={globalPathmap} />
}
