import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store/store'
import PackageTemplatesForm from './PackageTemplatesForm'

export default (props) => (
  <Provider store={store}>
    <PackageTemplatesForm {...props} />
  </Provider>
)
