import React, { useCallback, useMemo } from 'react'

function HeaderRow({ title, onSaveAll, onPreview, building, isSuper }) {
  return (
    <div className="row">
      <div className="col-xs-6" style={{ paddingLeft: 0 }}>
        <h4>{title}</h4>
      </div>

      <div className="col-xs-6">
        <button className="btn btn-success pull-right" style={{ marginLeft: '10px' }} onClick={onSaveAll}>
          Save
        </button>
        {typeof onPreview === 'function' && (
          <span>
            <a className="btn btn-warning btn-back pull-right" onClick={onPreview}>
              Preview
            </a>
          </span>
        )}
        {building?.id && (
          <a href={`/buildings/${building.id}/edit`} className="btn btn-warning btn-back pull-right">
            <i className="fa fa-arrow-left"></i>
            Back to {building.address || 'Building'}
          </a>
        )}
        <a href={`${isSuper ? '/super' : ''}/package_templates`} className="btn btn-warning btn-back pull-right">
          <i className="fa fa-arrow-left"></i>
          Back to Library
        </a>
      </div>
      <hr style={{ marginTop: 40, marginBottom: 20, borderTop: '1px solid #717171' }} />
    </div>
  )
}

export default function useHeaderRow({ editing, label, packageTypeLabel, onSaveAll, onPreview, building, isSuper }) {
  const title = useMemo(
    () => (editing ? `Edit ${label}` : `Create New ${packageTypeLabel} Template`),
    [editing, label, packageTypeLabel]
  )

  return useCallback(
    () => <HeaderRow title={title} onSaveAll={onSaveAll} onPreview={onPreview} isSuper={isSuper} building={building} />,
    [title, onSaveAll, onPreview, isSuper, building]
  )
}
