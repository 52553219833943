import React from 'react'
import $ from 'jquery'

import { TYPES as QUESTIONNAIRE_TYPES, transformWithFile } from './utilities'

function addMeta(data) {
  data[$("meta[name=csrf-param]").attr("content")] = $("meta[name=csrf-token]").attr("content")
  return data
}

const transformEmail = email => {
  email.requested_with_file = email.with_file
  return transformWithFile(email, email.requested_with_file)
}

const displayType = email => QUESTIONNAIRE_TYPES[email.requested_with_file]

class Emails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.items = (props.items || []).map(item => transformEmail(item))
    this.state.new_item = {}
  }

  openItem(item) {
    this.__updateItem(item, new_item => new_item.dirty = {})
  }

  updateItem(item, attribute, value) {
    this.__updateItem(item, new_item => new_item.dirty[attribute] = value)
  }

  closeItem(item) {
    this.__updateItem(item, new_item => delete new_item.dirty)
  }

  __updateItem(item, method, additional_state) {
    const items = this.state.items.concat([]),
          index = items.indexOf(item),
          new_item = Object.assign({}, item)

    method(new_item)

    if (item == this.state.new_item)
      return this.setState({new_item: new_item})

    index == -1 ? items.push(new_item) : items.splice(index, 1, new_item)

    if (additional_state && typeof(additional_state) == "object")
      additional_state = {}

    this.setState(Object.assign({}, additional_state, { items: items }))
  }

  url(item) {
    return `/buildings/${this.props.building_id}/questionnaire/email`
  }

  canSave(item, original) {
    if (!item.email)
      return false
    else if (!item.email.match(/^.+@.+\..+$/))
      return false
    else if (this.state.items.some(i => i.email == item.email && i != original))
      return false

    if (!item.with_file && !item.without_file)
      return false

    return true
  }

  prepareData(item) {
    const data = { email: item.email }
    data.with_file = item.with_file ? (item.without_file ? "both" : "with_file") : "without_file"
    return data
  }

  save(item) {
    const mergedItem = Object.assign({}, item, item.dirty)

    if (!this.canSave(mergedItem, item))
      return

    const data = this.prepareData(mergedItem)

    $.ajax({
      url: this.url(item),
      type: item.questionnaire_manager_email_key ? "put" : "post",
      data: addMeta({questionnaire_email: data, questionnaire_manager_email_key: mergedItem.questionnaire_manager_email_key}),
      success: res => this.successfulSave(item, res)
    })
  }

  successfulSave(item, res) {
    const items = this.state.items.concat([]),
          index = items.indexOf(item),
          new_item = transformEmail(res),
          state = {}

    index == -1  ? items.push(new_item) : items.splice(index, 1, new_item)
    state.items = items
    if (!item.id)
      state.new_item = {}

    this.setState(state)
  }

  remove(item) {
    const swalOptions = {
      type: "warning",
      title: "Remove Processor",
      text: `Are you sure you want to remove the questionnaire processor: ${item.email}?`,
      showCancelButton: true
    }

    const ajaxOptions = {
      url: this.url(item),
      method: "delete",
      data: addMeta({questionnaire_manager_email_key: item.questionnaire_manager_email_key}),
      success: res => this.completeRemoval(item)
    }

    swal(swalOptions, confirm => confirm ? $.ajax(ajaxOptions): null)
  }

  completeRemoval(item) {
    const items = this.state.items.concat([]),
          index = items.indexOf(item)

    items.splice(index, 1)
    this.setState({items: items})
  }

  renderActiveItem(item) {
    return <div className="active" key={item.email + "-active"}>
      <div>
        <input type="text" placeholder="Email" defaultValue={item.email} onChange={e => this.updateItem(item, "email", e.target.value)} />
      </div>
      <div>
        <div>
          <label>
            <input type="checkbox" defaultChecked={item.with_file} onChange={e => this.updateItem(item, "with_file", e.target.checked)} />
            <span>
              Processes Incoming Requests With Files Attached
            </span>
          </label>
          <label>
            <input type="checkbox" defaultChecked={item.without_file} onChange={e => this.updateItem(item, "without_file", e.target.checked)} />
            <span>
              Processes Incoming Requests Without Files Attached
            </span>
          </label>
        </div>
        <div>
          <button className="cancel" onClick={e => this.closeItem(item)}>Cancel</button>
          <button className="update" onClick={e => this.save(item)}>Update</button>
        </div>
      </div>
    </div>
  }

  renderItem(email) {
    return <div className="inactive" key={email.email}>
      <div className="email">{email.email}</div>
      <div className="type">{displayType(email)}</div>
      <div className="buttons">
        <i className="fa fa-pencil" onClick={e => this.openItem(email)} />
        <i className="fa fa-trash" onClick={e => this.remove(email)} />
      </div>
    </div>
  }

  renderList() {
    const list = []
    for (let item of this.state.items) {
      list.push(this.renderItem(item))
      if (item.dirty)
        list.push(this.renderActiveItem(item))
    }

    return <div className="list">{list}</div>
  }

  renderNewItem() {
    return <div className="new-item">
      {this.state.new_item.dirty ? this.renderActiveItem(this.state.new_item) : ""}
      <button className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={e => this.openItem(this.state.new_item)}>add new email</button>
    </div>
  }

  render() {
    return <div className="building-questionnaire-emails">
      {this.renderList()}
      {this.renderNewItem()}
    </div>
  }
}

export default Emails
