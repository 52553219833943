import React from 'react'
import XIcon from '/app/assets/images/insurance/icons/x-icon.svg'

export default function CloseButton({ onClose }) {
  return (
    <div className='interstitial-close-btn-wrapper'>
      <button className="interstitial-close-btn-desktop dome-btn dome-btn-link dome-btn-link-cancel" onClick={onClose}>
        close
      </button>
      <button className='interstitial-close-btn-mobile dome-btn dome-btn-link' onClick={onClose}>
        <img src={XIcon}/>
      </button>
    </div>
  )
}
