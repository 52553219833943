export function errorPopup(errors) { 
  errors = Array.isArray(errors) ? errors.join(`<br /><br />`) : errors

  const text = []
  text.push(`<p style="padding-bottom: 15px;">The following errors have prevented the request from being completed</p>`)
  text.push(`<p style="padding-bottom: 15px;" class="text=center">${errors}</p>`)
  text.push(`<p>Please resolve the errors before attempting again. If you have any questions, please feel free to reach out to <a href="mailto:info@boardpackager.com">mailto@boardpackager.com</a></p>`)

  swal({
    type: "error",
    title: "Request Failed",
    html: true,
    text: text.join(""),
    closeOnConfirm: true
  })
}

export function confirmRemoval(on_remove, all = true) {
  const text = []
  text.push(`By clicking "Remove these Items", you acknowledge that ${all ? 'all the' : 'the selected'} items that were associated with the above transactions will be cancelled. This means if you wish to go forward with the original purchase, you may need to do one of the following:`)
  
  const list = []
  list.push(`If they were fees on a package, you will need to resubmit from your package's fees tab`)
  list.push(`If they were a document request, you will need to re-request the document`)
  text.push(`<ul style="text-align: left;">${list.map(item => `<li style="padding: 5px 0px;">${item}</li>`).join("")}</ul>`)

  swal({
    type: "warning",
    title: "Remove Payment Items",
    html: true,
    text: text.map(t => `<p>${t}</p>`),
    showCancelButton: true,
    closeOnConfirm: false,
    showLoaderOnConfirm: true
  }, confirm => confirm ? on_remove() : null)
}