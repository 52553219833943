import React, { useEffect, useMemo, useRef } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { uniq, sortBy } from 'lodash'

import { useEventManager, useEventListeners } from '@deathbyjer/react-event-manager'

import DrawArea from 'components/utility/draw_area'

import { useToolPencil, usePageToolComponents } from '../tools'
import { setSelectedComponents,
         clearSelectedComponent } from '../form'

import Position from '../field_position/field_positions'

import SignatureGroup from '../signature/groups'
import Signature from '../signature/signature'
import SignatureTime from '../signature/time'
import SignatureName from '../signature/name'

const initialState = {
  pages: {},
  page_files: {},
  ordered_pages: [],
  activeComponent: null,
  activeComponentType: null
}

const Store = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPages(state, { payload: [pages, page_files] }) {
      state.pages = { ... pages }
      state.page_files = { ... page_files }
      state.ordered_pages = sortBy(Object.values(pages), 'order').map(page => page.id)
    },

    setPageFiles(state, { payload: page_files }) {
      state.page_files = { ... page_files }
    },

    setActiveComponent(state, { payload: component }) {
      state.activeComponent = component
    },

    setActiveComponentType(state, { payload: componentType }) {
      state.activeComponentType = componentType
    }
  }
})

export const reducer = Store.reducer
export const { setPages, setPageFiles, setActiveComponent } = Store.actions

export const PageWrapper = React.forwardRef(({page, page_file, children}, ref) => {
  const page_width = useSelector(state => state.form.width)
  const { width, height } = page.page_sizes[page_width] || {}

  const classes = ["form-component-page-wrapper", `page-${page.id}`]

  const style = {
//    paddingTop: `${height * 100.0 / width}%`,
    aspectRatio: `${width} / ${height}`,
    backgroundImage: `url(${page_file})`
  }

  return <div ref={ref} style={style} className={classes.join(" ")}>
    { children }
  </div>
})

export function uniqueComponentId(type, id) {
  switch(type) {
  case 'signature_group':
    return `sg-${id}`
  case 'signature':
    return `sig-${id}`
  case 'signature-time':
    return `sig-${id}-time`
  case `signature-name`:
    return `sig-${id}-name`
  case 'field_position':
    return `fpos-${id}`
  }
}

const renderSignature = ({id, signatures, activeComponent, props}) => {
  const key = uniqueComponentId('signature', id)
  const rect = signatures[id].positions.signature
  const active = key == activeComponent

  return <Signature key={key} id={id} rect={rect} active={active} {...props} />
}

const renderFieldPosition = ({field_position, activeComponent, props}) => {
  const key = uniqueComponentId('field_position', field_position.id)
  const active = key == activeComponent

  return  <Position key={key} field_position={field_position} active={active} rect={field_position.position} {...props} />
}

export function usePageComponents({page_id, frozen, thumbnail}) {
  const field_positions = useSelector(state => state.form_field_positions.by_page[page_id] || [], shallowEqual)
  const signature_ids = useSelector(state => state.form_signatures.by_page[page_id] || {}, shallowEqual)
  const signature_group_ids = useSelector(state => state.form_signatures.groups_by_page[page_id] || [], shallowEqual)
  const signatures = useSelector(({form_signatures: state}) => state.signatures )
  const activeComponent = useSelector(({form_pages: state}) => state.activeComponent)

  // The signature group positions on this page
  const signature_groups = useSelector(({form_signatures: state}) => state.signature_groups)
  const signature_group_positions = useMemo( () => Object.fromEntries(signature_group_ids.map(id => {
    return [id, signature_groups[id].positions.filter(p => p.page == page_id)[0]]
  })), [signature_group_ids, signature_groups, page_id])

  const props = { frozen, locked: true, thumbnail, page_id }
  const toolComponents = usePageToolComponents({page_id})

  // These components never need to be interacted with
  const staticComponents = [
    signature_group_ids.map(id => <SignatureGroup key={uniqueComponentId('signature_group', id)} id={id} rect={signature_group_positions[id]} {...props} />),
    (signature_ids.time || []).map(id => <SignatureTime key={uniqueComponentId('signature-time', id)} id={id} rect={signatures[id].positions.time} {...props} />),
    (signature_ids.name || []).map(id => <SignatureName key={uniqueComponentId('signature-name', id)} id={id} rect={signatures[id].positions.name} {...props} />)
  ]

  // The user may want to interact these
  const dynamicComponents = [
    field_positions.map((field_position, id) => renderFieldPosition({field_position, activeComponent, props})),
    (signature_ids.signature || []).map(id => renderSignature({id, signatures, activeComponent, props})),
    toolComponents
  ]

  return [
    staticComponents,
    dynamicComponents
  ].flat()
}

export default React.forwardRef(({id, frozen, children, locked}, ref) => {
  const dispatch = useDispatch()
  const eventManager = useEventManager()
  const drawAreaRef = useRef()
  const page = useSelector(state => state.form_pages.pages[id])
  const page_file = useSelector(state => state.form_pages.page_files[id])

  const activeTool = useSelector(state => state.form.active_tool)
  const onEmptyMousedown = useToolPencil(id, activeTool)
  //const onDrop = useDrawPageDrop(tools, page_id)

  const pageComponents = usePageComponents({page_id: id, frozen})
  const container = drawAreaRef.current

  const drawProps = {
    onEmptyMousedown,
    onUpdatedSelected: selected => {
      dispatch(setSelectedComponents({page: id, selected}))
    },
    onUnselectAll: () => eventManager.applyEventListeners('unselectAllComponents', { container }),
    locked: locked
  }

  useEventListeners({
    'unselectAllComponents': evt => {
      if (evt?.container != container)
        drawAreaRef.current?.unselectAll()
    }
  })

  return <PageWrapper ref={ref} page={page} page_file={page_file}>
    <DrawArea {...drawProps} ref={drawAreaRef}>
      { pageComponents }
    </DrawArea>
    {children}
  </PageWrapper>
})
