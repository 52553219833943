import React from 'react'

function errorsFromXhr(xhr) {
  try { return JSON.parse(xhr.responseText) }
  catch(e) {}

  return {error: xhr.responeText}
}

class PdfView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  loadPdf() {
    const url = `/packages/${this.props.hashedId}/get_or_generate_template_preview`

    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        success: data => resolve(data),
        error: xhr => reject(errorsFromXhr(xhr))
      })
    })
  }

  setLoading(bool) {
    this.setState({loading: bool})
  }

  renderLoading() {
    return <div className="loading-dots"></div>
  }

  renderIframe() {
    if (!this.props.previewUrl) return null

    return <iframe
      src={this.props.previewUrl}
      frameBorder="0"
      width="100%"
      height="100%" />
  }

  render() {
    return <div className="packages-preview-pdf-view">
      {this.renderIframe()}
    </div>
  }
}

export default PdfView
