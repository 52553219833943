import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { compact, isPlainObject } from 'lodash'
import axios from 'axios'

import { addCSRF } from 'lib/utilities'

function startGenerating(packageId, conditions = {}) {
  const url = `/packages/v3/${packageId}/download/start`

  return new Promise((res, rej) => {
    axios.post(url, addCSRF({ conditions })).then(response => {
      console.log(response)
      res(response)
    }).catch(rej)
  })
}

function checkStatus(packageId, conditions = {}) {
  const url = `/packages/v3/${packageId}/download/progress`
  
  return new Promise((res, rej) => {
    axios.post(url, addCSRF({ conditions })).then(response => {
      res(response)
    }).catch(rej)
  })
}

function useMonitorProgress({packageId, conditions, setShow, setError, setUrl}) {
  return useMemo(() => {
    const completed = url => {
      setUrl(url)
    }

    const failed = () => {
      setError(true)
      setShow(false)
    }

    const processing = () => {
      setTimeout(() => checkStatus(packageId, conditions).then(callback), 4000)
    }

    const callback = ({data: { status, url } }) => {
      switch(status) {
        case 'completed': return completed(url)
        case 'failed': return failed()
        case 'processing': return processing()
      }
    }

    return callback
  }, [packageId, conditions, setShow, setError])
}

function GeneratingDocuments() {
  return <>
    <div className="main-icon processing-icon" />
    <h3>Generating Documents</h3>
    <p>Please wait while all documents, edits and signatures are compiled into one file.</p>
    <p>This could take several minutes.</p>
  </>
}

function DocumentGenerated({url, hide}) {
  const open = () => {
    window.open(url)
    hide()
  }

  return <>
    <div className="main-icon ready-icon" />
    <h3>Documents Ready!</h3>
    <p>Documents generated &amp; ready for download</p>
    <div className="success-buttons">
      <button onClick={hide} className="btn btn-neutral-secondary">Close</button>
      <button onClick={open} className="btn btn-positive-secondary btn-bold">Download</button>
    </div>
  </>
}

export function DownloadModal({ defaultShow, onClose, onError, packageId, conditions = {} }) {
  const [show, setShow] = useState(defaultShow)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)
  const progress = useMonitorProgress({packageId, conditions, setShow, setError, setUrl})

  const hide = useCallback(() => setShow(false), [setShow])

  const onExited = useCallback(() => {
    if (error && onError) 
      onError()

    onClose ? onClose() : null
  }, [onClose, onError, error])


  useEffect(() => {
    startGenerating(packageId, conditions).then(progress)
  }, [packageId, conditions])

  return <Modal show={show} centered={true} onExited={onExited} className="packages-download-modal">
    <Modal.Body>
      { !url ? <GeneratingDocuments /> : null }
      { url ? <DocumentGenerated url={url} hide={hide} /> : null }
    </Modal.Body>
  </Modal>
}

export function DownloadModalButton({ packageId, conditions = {}, style, className, children }) {
  const [ show, setShow ] = useState(false)

  const classes = compact([
    'download-package-button',
    className
  ]).join(" ")

  const styles = isPlainObject(style) ? style : {}

  return <>
    <button type="button" style={styles} className={classes} onClick={() => setShow(true)}>{children}</button>
    { show ? <DownloadModal defaultShow={show} onClose={() => setShow(false)} packageId={packageId} conditions={conditions} /> : null }
  </>
}