import React from 'react'
import PropTypes from 'prop-types'

class Searchbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      typingTimer: null,
      doneTypingInterval: 500,
      searchText: this.props.searchText,
      handleBlurActive: true,
      live: this.props.liveOnly
    }

    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)

    this.handleCheckLive = this.handleCheckLive.bind(this)
    this.sendSearchTextToParent = this.sendSearchTextToParent.bind(this)
  }

  componentDidMount() {
    if (!this.props.fromModal) return

    $("#custom-application-search-modal").on("hidden.bs.modal", function () {
      $("#custom-application-search-modal input").val("")
      this.setState({searchText: ""})
      this.sendSearchTextToParent()
    }.bind(this))
  }

  handleKeyUp(e) {
    clearTimeout(this.state.typingTimer)
    const interval = setTimeout(this.sendSearchTextToParent, this.state.doneTypingInterval)

    this.setState({ typingTimer: interval, searchText: e.target.value })
  }

  handleCheckLive(e) {
    console.log(e.target.checked)
    clearTimeout(this.state.typingTimer)
    this.setState({live: e.target.checked, typingTimer: setTimeout(this.sendSearchTextToParent, 50)})
  }


  handleBlur() {
    if (this.props.preventSearchbarBlurEvent) return
    if (this.blurring) return

    this.blurring = setTimeout(() => {
      this.props.setAppState({ searchSuggestionsHidden: true })
      delete this.blurring
    }, 150)
  }

  handleFocus() {
    clearTimeout(this.blurring)
    delete this.blurring

    this.props.setAppState({ searchSuggestionsHidden: false })
  }

  sendSearchTextToParent() {
    this.props.setAppState({ searchText: this.state.searchText, liveOnly: this.state.live })
  }

  renderLive() {
    if (!this.props.askLive)
      return null

    const classes = ["ask-live"]
    if (this.state.live)
      classes.push("checked")

    return <label className={classes.join(" ")} onBlur={this.handleBlur} onFocus={this.handleFocus}>
      <input  type="checkbox"
              defaultChecked={this.props.liveOnly}
              onChange={this.handleCheckLive}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus} />
      <span>Live Only</span>
    </label>
  }

  render() {
    return (
      <div className="searchbar">
        <i className="fa-light fa-magnifying-glass fa-lg fa-pull-right" style={{color: "#4A4A4A"}}></i>
        <input
          type="text"
          defaultValue={this.props.searchText}
          name={this.props.searchbarName}
          placeholder={this.props.placeholderText}
          autoComplete="off"
          onKeyUp={this.handleKeyUp}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}></input>
        {this.renderLive()}
      </div>
    )
  }
}

Searchbar.propTypes = {
  setAppState: PropTypes.func.isRequired,
  preventSearchbarBlurEvent: PropTypes.bool,
  placeholderText: PropTypes.string.isRequired,
  fromModal: PropTypes.bool,
  searchbarName: PropTypes.string,
  searchText: PropTypes.string,
  liveOnly: PropTypes.bool
}

export default Searchbar