import { createSlice } from '@reduxjs/toolkit'
import { createStructure } from '../../NewStructure/store/slice'
import { updateStructure } from '../../StructureEdit/store/slice'
import { archiveStructure } from '../../StructureArchive/store/slice'

const initialState = {
  building: null,
  building_structures: [],
}

const buildingEditAdditionalAddresses = createSlice({
  name: 'buildingEditAdditionalAddresses',
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.building = action.payload.building
      state.building_structures = action.payload.building_structures
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload

      if (state.building?.id === building_structure?.building_id) {
        state.building_structures.push(building_structure)
      }
    })
    builder.addCase(updateStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload

      if (state.building?.id !== building_structure?.building_id) return

      const structureIndex = state.building_structures.findIndex((structure) => structure.id === building_structure?.id)

      if (structureIndex !== -1) {
        state.building_structures[structureIndex] = building_structure
      }
    })
    builder.addCase(archiveStructure.fulfilled, (state, action) => {
      const { building_structure } = action.payload

      if (state.building?.id !== building_structure?.building_id || building_structure.active === true) return

      const structureIndex = state.building_structures.findIndex((structure) => structure.id === building_structure?.id)

      if (structureIndex !== -1) {
        state.building_structures.splice(structureIndex, 1)
      }
    })
  },
})

export const selectBuildingEditAdditionalAddressesState = (state) => state.buildingEditAdditionalAddresses

export const { setInitialState } = buildingEditAdditionalAddresses.actions

export const buildingEditAdditionalAddressesReducer = buildingEditAdditionalAddresses.reducer
