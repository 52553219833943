import { useContext, useMemo } from "react"
import { userContext } from "../context/userContext"

export function useDisplayParticipants(participantList, users, owner) {
  const { currentUser } = useContext(userContext)

  return useMemo(() => {
    const filteredParticipants = participantList
      ?.filter(participant => participant.user_id !== currentUser)
      ?.map(participant => {
        const isUser = !!participant.user_id
        return {
          label: users?.[participant?.user_id]?.name || `${owner?.roles?.[participant.role]?.display_select}`,
          role: participant?.role,
          isUser,
        }
      })

    return filteredParticipants
  }, [participantList, currentUser, owner, users])
}
