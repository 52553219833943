import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestIssuePolicy, selectCurrentBuildingStructure, selectInsuranceQuoteInterstitialState, setSignatureFormData } from 'components/insurance/get_quote/slice/slice'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import MonetaryAmount from '../../MonetaryAmount'
import { formattedMoney } from 'components/insurance/get_quote/utils'
import clsx from 'clsx'

import viaffinityLogo from '/app/assets/images/insurance/logos/viaffinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import lemonadeLogo from '/app/assets/images/insurance/logos/lemonade.png'
import ConfirmBackToPreviousStepModal from '../FinalizeQuoteForm/ConfirmBackToPreviousStepModal'

const ErrorsFromServer = React.memo(({ error }) => {
  return <div className="p3 dome-color-red-alert">{error}</div>
})

const formatDate = (dateStr) => {
  return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' })
}

const useDetails = () => {
  const { building, formData, insuranceConstants } = useSelector(selectInsuranceQuoteInterstitialState)
  const buildingStructure = useSelector(selectCurrentBuildingStructure)

  return useMemo(() => {
    return {
      title: building?.title,
      address: buildingStructure?.default_additional_address?.address,
      city: building?.city,
      state: building?.state,
      zip: building?.zip,
      buildingTypeDisplay: insuranceConstants?.BUILDING_TYPES_DISPLAY
        ? insuranceConstants?.BUILDING_TYPES_DISPLAY[building?.building_type]
        : null,
      buildingUnit: formData.building_unit,
      squareFootage: formData.square_footage,
      firstName: formData.first_name,
      middleName: formData.middle_name,
      lastName: formData.last_name,
      phoneNumber: formData.phone_number,
      email: formData.email,
      occupancy: formData.occupancy,
    }
  }, [building, formData, insuranceConstants, buildingStructure])

}

const HeaderSection = ({ quoteNumber }) => {
  return (
    <div className="quote-header dome-d-flex dome-flex-column dome-align-start">
      <i className="fal fa-party-horn dome-color-dark-green" style={{ fontSize: '39px' }} />
      <div className="dome-h2 dome-color-dark-green">your quote is ready!</div>
      <div className="dome-p2 dome-color-dark-grey">quote id # {quoteNumber}</div>
    </div>
  )
}

const QuoteAmountSection = ({ monthlyAmount, writingCompany }) => {
  return (
    <div style={{ paddingBlock: '18px', borderBottom: '1px solid var(--sage)' }}>
      <div className="quote-amount-section dome-d-flex dome-align-end">
        <div className="dome-text-w500 dome-color-sage dome-d-flex dome-align-start" style={{ lineHeight: 1 }}>
          <MonetaryAmount amount={monthlyAmount} dollarsClass={'text-40'} />
        </div>
        <span>/month</span>
      </div>
      {writingCompany && (
        <div className="dome-p3">
          <em>{`Insurance underwritten by: ${writingCompany}`}</em>
        </div>
      )}
    </div>
  )
}

const QuoteCoverageSection = ({ quote: { policyEffectiveDate, policyExpirationDate, liabilityLimit, homeAndContentsDeductible, contentsAmount } }) => {
  return (
    <div style={{ paddingBottom: '18px', borderBottom: '1px solid var(--sage)' }}>
      <div className="quote-coverage-section dome-d-flex dome-flex-column dome-gap12">
        <div className="dome-p1 dome-color-dark-green">your coverage:</div>
        <div className="coverage-amounts dome-d-flex dome-align-center dome-color-med-grey dome-text-w300 dome-justify-between">
          <div className="dome-d-flex dome-align-start dome-flex-column" style={{ width: '50%' }}>
            <div>Effective: {`${formatDate(policyEffectiveDate)}-${formatDate(policyExpirationDate)}`}</div>
            <div>Liability Limit: {formattedMoney(liabilityLimit)}</div>
          </div>
          <div className="dome-d-flex dome-align-start dome-flex-column" style={{ width: '50%' }}>
            <div>Base Deductible: {formattedMoney(homeAndContentsDeductible)}</div>
            <div>Content Amount: {formattedMoney(contentsAmount)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const QuoteInfoSection = () => {
  const {
    title,
    address,
    city,
    state,
    zip,
    buildingTypeDisplay,
    buildingUnit,
    squareFootage,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    email,
    occupancy,
  } = useDetails()

  return (
    <div className="quote-info-section dome-d-flex dome-flex-column dome-gap12">
      <div className="dome-p1 dome-color-dark-green">information</div>
      <div className="details-container">
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> property
          </summary>
          <div>{title}</div>
          <div>{address}</div>
          <div>{`${city}, ${state} ${zip}`}</div>
          <div>{`Type: ${buildingTypeDisplay}`}</div>
        </details>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> unit
          </summary>
          <div>Unit {buildingUnit}</div>
          <div>{`${squareFootage?.toLocaleString()} sq. ft`}</div>
        </details>
        <details>
          <summary>
            <i className="fa-light fa-chevron-down" /> policy holder
          </summary>
          <div>{`${firstName} ${middleName} ${lastName}`}</div>
          <div>{phoneNumber}</div>
          <div>{email}</div>
          <div>{`Type: ${occupancy}`}</div>
        </details>
      </div>
    </div>
  )
}

const QuoteApplicationAttachSection = ({ handleSubmit, register, onFormSubmit, package_requirement_title, setBackToPreviousModalVisibility }) => {
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {package_requirement_title && (
        <div className="quote-application-attach-section dome-d-flex dome-gap6 dome-align-start">
          <input
            type="checkbox"
            {...register('automatic_opted_in')}
            id="finalize-quote_automatic_opt_in"
            className="finalize-quote-form-check-input"
          />
          <label htmlFor="finalize-quote_automatic_opt_in" className="dome-p2" role="button">
            Upon completion of this process, attach my certificate to this application requirement:{' '}
            {package_requirement_title}
          </label>
        </div>
      )}
      <div className="dome-d-flex dome-align-center dome-justify-between">
        <button
          type="button"
          className="dome-btn dome-btn-link dome-btn-link-back"
          onClick={() => setBackToPreviousModalVisibility(true)}
        >
          back
        </button>
        <button type="submit" className="dome-btn dome-btn-base dome-btn-dark-green">
          request to issue policy
        </button>
      </div>
    </form>
  )
}

const HangingFooter = () => {
  return (
    <div className="hanging-footer dome-bg-white dome-rounded-border dome-light-shadow dome-d-flex dome-flex-column dome-align-start dome-gap6">
      <div className="copyright-info dome-d-flex dome-align-center dome-justify-between dome-full-width">
        <div>&#169; 2024 Viaffinity</div>
        <em className="dome-p4 dome-text-w300">
          by <img src={viaffinityLogo} alt="viaffinity logo" style={{ width: '56px' }} />
        </em>
      </div>
      <em className="license-info dome-color-med-grey">
        Insurance described is offered by Viaffinity, LLC (California license 6011183 d/b/a Viaffinity Insurance
        Services).
      </em>
      <div className="providers-info dome-d-flex dome-flex-column">
        <em className="dome-color-med-grey dome-text-w300 dome-p4">insurance provided by</em>
        <div className="dome-d-flex dome-align-center dome-gap6">
          <img src={chubbLogo} alt="chubb logo" style={{ width: '49px' }} />
          <img src={lemonadeLogo} alt="lemonade logo" style={{ width: '49px' }} />
        </div>
      </div>
    </div>
  )
}

const NoTermsFinalizeQuote = ({ onBack }) => {
  const {
    quote,
    building,
    signatureFormData,
    formData,
    errorDescription,
    currentUser,
    package_requirement_title,
  } = useSelector(selectInsuranceQuoteInterstitialState)

  const {
    quoteNumber,
    premium,
    writingCompany
  } = quote

  const dispatch = useDispatch()
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      opt_in: true,
      automatic_opted_in: !package_requirement_title || signatureFormData?.automatic_opted_in === false ? false : true,
    },
  })

  const [backToPreviousModalVisibility, setBackToPreviousModalVisibility] = useState(false)

  const onFormSubmit = (data) => {
    const updatedSignatureFormData = {
      ...signatureFormData,
      user_id: currentUser?.id,
      signed_at: new Date(),
    }

    dispatch(setSignatureFormData(updatedSignatureFormData))
    dispatch(
      requestIssuePolicy({
        quote_id: quote.id,
        data: { ...updatedSignatureFormData, quote_attributes: { automatic_opted_in: data.automatic_opted_in } },
      })
    )

    window.dataLayerPush({
      event: 'insurance-interstitial.finalize-quote.request-issue-policy',
      url: window.location.href,
      buildingId: building.id,
    })
  }

  const handleBackToPreviousModalClose = () => {
    setBackToPreviousModalVisibility(false)
  }

  const handleBackToPreviousModalSubmit = () => {
    onBack(formData)
  }

  return (
    <>
      <div className="finalize-quote-wrapper dome-relative dome-full-width">
        <div className="no-terms-finalize-quote-container dome-absolute dome-d-flex dome-flex-column dome-gap12">
          <div className="no-terms-finalize-quote dome-rounded-border dome-bg-white dome-light-shadow dome-d-flex dome-flex-column dome-gap24">
            <HeaderSection quoteNumber={quoteNumber} />
            <QuoteAmountSection monthlyAmount={monthlyAmount} writingCompany={writingCompany} />
            <QuoteCoverageSection quote={quote} />
            <QuoteInfoSection />
            <QuoteApplicationAttachSection
              handleSubmit={handleSubmit}
              register={register}
              onFormSubmit={onFormSubmit}
              package_requirement_title={package_requirement_title}
              setBackToPreviousModalVisibility={setBackToPreviousModalVisibility}
            />
            {errorDescription ? <ErrorsFromServer error={errorDescription} /> : null}
          </div>
          <HangingFooter />
        </div>
      </div>
      <ConfirmBackToPreviousStepModal
        open={backToPreviousModalVisibility}
        onClose={handleBackToPreviousModalClose}
        onSubmit={handleBackToPreviousModalSubmit}
      />
    </>
  )
}

export default NoTermsFinalizeQuote
