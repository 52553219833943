import React, {useState, useEffect} from 'react'

const BadgeIcon = (props) => {
  const [toggleSuccess, setToggleSuccess] = useState(false)

  useEffect(() => {
    const value = props?.watchedField?.length > 6
    setToggleSuccess(value)
  }, [props.watchedField])
  return <i style={{color: toggleSuccess ? '#56a256' : '#b7b7b7'}} className={`fa-light fa-id-badge ${props.errors ? 'error-icon' : ''}`}></i>
}

export default BadgeIcon