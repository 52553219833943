import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

export default function SavingFormBuilderModal({ onSubmit }) {
  const [show, setShow] = useState(false)
  const hideOverlay = () => setShow(false)

  return (
    <>
      <button className="btn-base btn-darkest-grey" onClick={() => setShow(true)}>
        save and publish
      </button>
      <Modal show={show} centered={true} onHide={hideOverlay} className="form-builder-modal">
        <div className="dome-d-flex dome-flex-column dome-gap24">
          <div className="dome-h2 dome-color-dark-green">are you sure?</div>
          <div className="dome-p1-lt dome-color-darkest-grey">
            Publishing a form compiles all input fields + values into one file.{' '}
          </div>
          <div className="dome-d-flex dome-justify-between">
            <button
              type="button"
              onClick={hideOverlay}
              className="dome-btn dome-btn-link dome-btn-link-med-grey dome-btn-link-chevron-left"
            >
              back
            </button>
            <button
              onClick={() => {
                onSubmit()
                setShow(false)
              }}
              type="button"
              className="dome-btn dome-btn-base dome-btn-go-green"
            >
              publish form
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
