import React from 'react'
import PropTypes from 'prop-types'

const FilterButton = (props) => {
  return <button className="btn dashboard-btns filter-btn" onClick={() => props.handleClick()}>
    <img className="filter-btn btn-icon"
      src={props.filterButtonIconPath}
      alt="Financial Filter Icon"/>
    Filter
  </button>
}

FilterButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  filterButtonIconPath: PropTypes.string
}

export default FilterButton