import React from 'react'

import PolicyRow from './policy_row'

class PolicyTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectNone: false,
      selectAll: false
    }
    this.renderRequirementRows = this.renderRequirementRows.bind(this)
    this.handleSelectAllClick=this.handleSelectAllClick.bind(this)
    this.handleSelectNoneClick=this.handleSelectNoneClick.bind(this)
  }

  renderRequirementRows () {
    return this.props.packageRequirements.map((requirement, index) => {
      return <PolicyRow 
          key = {requirement.id}
          index = {index}
          requirement={requirement}
          policyVisibility={this.props.collaborator.updatedPolicy}
          packageRequirements={this.props.packageRequirements}
          collaborator={this.props.collaborator}
          toggleSelectedRequirement={this.props.toggleSelectedRequirement}
        />
    })
  }

  handleSelectAllClick () {
    this.setState({selectAll: true, selectNone: false})
    setTimeout(function() {
      this.setState({selectAll: false})
    }.bind(this),2000)
    this.props.selectAllRequirements(this.props.collaborator)
  }

  handleSelectNoneClick () {
    this.setState({selectNone: true, selectAll: false})
    setTimeout(function() {
      this.setState({selectNone: false})
    }.bind(this),2000)
    this.props.deSelectAllRequirements(this.props.collaborator)
  }

  render () {
    return (
      <ul style={{listStyle: 'none', paddingLeft: '0px', paddingTop:'10px', paddingBottom: '20px'}}>
        <li style={{paddingBottom: '10px'}}>
          <span style={{color: 'orange'}} className='collaborator-hover' onClick={this.handleSelectAllClick}>Select All</span>&nbsp;|&nbsp;
          <span style={{color: 'orange'}} className='collaborator-hover' onClick={this.handleSelectNoneClick}>Select None</span>
          <br/>
          <strong style={(this.state.selectNone || this.state.selectAll) ? {display: 'inline', color: 'green'} : {display: 'none'}} className='cleared-flash'>{this.state.selectNone ? "Cleared" : this.state.selectAll ? "Selected All" : ""}</strong>
        </li>
        <li style={{borderBottom: '1px solid #000', marginBottom: '1px'}}>
          <strong>Requirement Visibility</strong>
        </li>
        {this.renderRequirementRows()}
      </ul>
    )
  }
}

export default PolicyTab
