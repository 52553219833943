import React, { createContext, useEffect, useState } from "react";
import { VIEWS } from '../features/views/views'

export const viewContext = createContext(null)

export const ViewContextProvider = ({ threadsPerPage, messagesPerPage, owner_id, owner_class, children }) => {
  const [currentView, setCurrentView] = useState(VIEWS.LIST_VIEW)
  const [focusId, setFocusId] = useState(() => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)

    return params.get("focus_thread")
  })

  return (
    <viewContext.Provider value={{ currentView, setCurrentView, threadsPerPage, messagesPerPage, owner_id, unique_owner_id: owner_id, focusId, setFocusId }}>
      {children}
    </viewContext.Provider>
  )
}
