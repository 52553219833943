import React, { Component } from "react"
import BuildingSelect from 'components/utility/building_select'
import UploadArea from 'components/utility/upload_area'

const CONTACT_FIELDS = [
  { name: "buyer_name", label: "name", required: true },
  { name: "buyer_email", label: "email", required: true },
  { name: "buyer_phone", label: "phone number", required: true },
  { name: "company_name", label: "bank or company name (if applicable)" },
]

const REQUIRED_FIELDS = ["unit_id", "buyer_name", "buyer_email", "buyer_phone"]
const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

const allowedFileTypes = ['doc', 'docx', 'pdf', 'csv', 'xls', 'xlsx', 'jpg', 'jpeg', 'png']

export default class MakeRequest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      questionnaire_type: null,
      errors: {},
      file: null
    }

    if (this.props.organization.uploadRequired)
      this.state.questionnaire_type = "custom"

    if (this.props.building && this.props.building.addresses.length == 1)
      this.state.address = this.props.building.address
  }

  componentDidMount() {
    this.updateAttribute('address', this.props.building.address)
  }

  building() {
    return this.props.building || this.state.building
  }

  updateAttribute(name, value) {
    const state = {}
    const errors = Object.assign({}, this.state.errors)

    state[name] = value

    delete errors[name]
    state.errors = errors

    this.setState(state)
  }

  checkSubmitting() {
    if (this.state.submitting)
      return true

    this.setState({ submitting: true })

    return false
  }

  doneSubmitting() {
    this.setState({ submitting: false })
  }

  cancel() {
    if (typeof (this.props.onCancel) == "function") {
      this.setState({
        questionnaire_type: null,
        errors: {}
      })
      this.removeFile()
      this.props.onCancel()
    }
  }

  submit() {
    if (this.checkSubmitting())
      return

    if (this.checkErrors(true))
      return this.doneSubmitting()

    const url = "/questionnaire_requests/create_questionnaire_request",
      data = new FormData()

    if (this.props.building)
      data.append("questionnaire_request[building_id]", this.props.building.id)
    else if (this.state.building)
      data.append("questionnaire_request[building_id]", this.state.building.id)

    data.append("questionnaire_request[property_address]", this.state.address)
    data.append("questionnaire_request[property_manager_id]", this.props.organization.id)

    data.append("questionnaire_request[unit_id]", this.state.unit_id)
    for (let field of CONTACT_FIELDS)
      data.append(`questionnaire_request[${field.name}]`, this.state[field.name])

    if (this.state.file)
      data.append("questionnaire_request[questionnaire_form]", this.state.file.file)

    data.append($("meta[name=csrf-param]").attr("content"), $("meta[name=csrf-token]").attr("content"))

    this.setState({ errors: {} })

    $.ajax({
      url,
      data,
      type: "post",
      processData: false,
      contentType: false,
      complete: () => this.doneSubmitting(),
      success: res => this.success(),
      error: xhr => this.errored(xhr)
    })
  }

  checkFieldsExistences() {
    const errors = {}
    const is_empty = val => !this.state[val] || this.state[val].match(/^\s*$/)

    for (let field of REQUIRED_FIELDS)
      if (is_empty(field))
        errors[field] = "This field is required"

    if (!this.building())
      errors.building = "This field is required"
    else if (this.building().addresses.length != 0 && is_empty('address'))
      errors.address = "This field is required"

    if (this.props.organization.uploadRequired || this.state.questionnaire_type == "custom")
      if (!this.state.file || this.state.file.length == 0)
        errors.file = "An uploaded blank questionnaire is required"

    return errors
  }

  checkErrors(should_we_check_existence) {
    const errors = Object.assign({},
      this.state.errors,
      should_we_check_existence ? this.checkFieldsExistences() : {})

    const is_filled = val => this.state[val] && !this.state[val].match(/^\s*$/)

    if (is_filled("buyer_email") && !this.state.buyer_email.match(EMAIL_REGEX))
      errors.buyer_email = "Invalid email. Please try again."

    if (is_filled("buyer_phone") && !this.state.buyer_phone.match(PHONE_REGEX))
      errors.buyer_phone = "Invalid phone number. Please try again."

    this.setState({ errors: errors })

    return Object.keys(errors).length > 0
  }

  success() {
    if (typeof (this.props.onSubmit) == "function")
      this.props.onSubmit()
  }

  errored(xhr) {
    const standard_error = "We're sorry! Something seems to have gone wrong!"
    try {
      const res = JSON.parse(xhr.responseText) || {}
      if (typeof (res.error) == "object")
        return this.setState({ errors: res.error })

      this.setState({ errors: { server_error: res.error || standard_error } })
    } catch (e) {
      this.setState({ errors: { server_error: standard_error } })
    }
  }

  renderBuildingInput() {
    if (this.props.building) return null

    return <div key="building">
      <span className="required-star">*</span>
      <BuildingSelect show_empty_message={true} params={{ include_addresses: 1, has_questionnaires: 1, page_name: "" }} organization={this.props.organization.id} onChange={e => this.setState({ building: e.building, address: e.address ? e.address.address : null })} />
    </div>
  }

  renderInput(name, label, required) {
    const items = []
    const classes = ["questionnaire-input"]
    const spanClasses = ["required-star"]
    const disabledInput = this.state.questionnaire_type == null

    if (required) {
      if (disabledInput)
        spanClasses.push("disabled")
      items.push(<span key="required" className={spanClasses.join(" ")}>*</span>)
    }

    return (
      <div key={name} className="form-group-item">
        <label
          className={['questionnaire-request-label', required ? 'require' : null].join(' ')}
          htmlFor={`questionnaire_request-${name}`}
        >
          {label}
        </label>
        <input
          key="input"
          type="text"
          id={`questionnaire_request-${name}`}
          className={classes.join(" ")}
          required={required}
          disabled={disabledInput}
          onChange={e => this.updateAttribute(name, e.target.value)}
          onBlur={(e) => this.checkErrors()}
        />
      </div>
    )
  }

  fileTypeErrors() {
    const file_error = `This file type is not supported, please try one of the following ${allowedFileTypes.join(", ")}`

    this.setState({ errors: { file: file_error } })
  }

  renderFileError(file) {
    const uploadedFileTypes = file.name.split(".").pop()

    if (!allowedFileTypes.includes(uploadedFileTypes)) {
      this.fileTypeErrors()
      this.removeFile()
    }
  }

  addedFiles(files) {
    this.setState({ file: files[0] })
    this.updateAttribute("file", files[0])
    this.renderFileError(files[0])
  }

  removeFile() {
    this.setState({ file: [] })
    this.renderFileName();
  }

  renderFileName() {
    if (!this.state.file || this.state.file.length < 1)
      return ""

    let trashIcon = { fontSize: '16px', display: 'inline-block', marginLeft: '15px', border: 'none', background: 'transparent', color: 'red' }
    return <div className="list">
      <div className="file-name">
        {this.state.file.name}
        <button className="fa-light fa-xmark" style={trashIcon} onClick={() => this.removeFile()}></button>
      </div>
    </div>
  }

  renderFileUpload() {
    const fileUploaded = !this.state.file ? "" : this.renderFileName()
    const hiddenUploadArea = { display: 'none' }

    const uploadAreaContent = <div className="questionnaire-request-modal-upload-section">
      <i className="fa-thin fa-cloud-arrow-up"></i>
      upload blank questionnaire
    </div>

    if (this.state.questionnaire_type == null || this.state.questionnaire_type == "generic")
      return <div>
        <div className="file-upload" style={hiddenUploadArea}>
          <UploadArea inputChanged={files => this.addedFiles(files)} exclude_icons={true} key="file" text={uploadAreaContent} />
        </div>
      </div>

    if (!this.state.file || this.state.file.length < 1)
      return <div className="file-upload">
        <UploadArea inputChanged={files => this.addedFiles(files)} exclude_icons={true} key="file" text={uploadAreaContent} />
      </div>

    return <div>
      <div className="file-upload" style={hiddenUploadArea}>
        <UploadArea inputChanged={files => this.addedFiles(files)} exclude_icons={true} key="file" text={uploadAreaContent} />
      </div>
      <div className="list" style={{ display: 'inline-block' }}>
        <div>
          <span style={{fontWeight: 500}}>attached document:</span>
        </div>
        <div>
          {fileUploaded}
        </div>
      </div>
    </div>
  }

  renderButtons() {
    const submitClasses = ["btn-base", "btn-dark-green"]
    const errors = this.checkFieldsExistences()
    const disabled = this.state.submitting || Object.keys(errors).length > 0
    if (disabled)
      submitClasses.push("btn-disabled")

    return <div className="buttons">
      <button type="button" onClick={() => this.cancel()} className="btn-link cancel-link">cancel</button>
      <button type="button" onClick={() => this.submit()} className={submitClasses.join(" ")}>submit</button>
    </div>
  }

  renderServerError() {
    if (!this.state.errors.server_error)
      return null

    return <div className="errors">
      <div>{this.state.errors.server_error}</div>
    </div>
  }

  renderGenericPreview() {
    const props = this.props.organization
    const url = this.props.organization.genericPreview

    const genericPreviewEnabled = props.genericPreview_enabled && url && !props.uploadRequired && this.state.questionnaire_type == "generic"
    if (!genericPreviewEnabled) return null

    return (
      <div>
        <a href={url} target="_blank" rel="noopener noreferrer" className="link-aqua">
          <i className="fa-solid fa-magnifying-glass fa-fw"></i>preview questionnaire
        </a>
      </div>
    )
  }

  renderBuildingAddress() {
    if (!this.props.building || this.building().addresses.length >= 2) return null

    const address_line = this.props.building.addresses.length == 1 ? <div>{this.props.building.address}</div> : ""

    return (
      <div className="questionnaire-building-address">
        <h6 className="questionnaire-address-display">
          {address_line}
          {this.props.building.city}, {this.props.building.state} {this.props.building.zip}
        </h6>
      </div>
    )
  }

  renderAddressSelect() {
    if (!this.building() || this.building().addresses.length < 2) return null
    const addresses = []
    for (let address of this.building().addresses)
      addresses.push(<option key={address.id} value={address.address}>{address.address}</option>)

    return (
      <div className="address-select">
        <label className="questionnaire-request-label require" htmlFor="questionnaire_request-property_address">select an address</label>
        <select className="questionnaire-address-select" id="questionnaire_request-property_address" name="questionnaire_request[property_address]" onChange={e => this.updateAttribute('address', e.target.value)} defaultValue={this.building().address ? this.building().address : ""}>{addresses}</select>
      </div>
    )
  }

  renderFormGroup(key, input, defaultClasses = []) {
    if (!input) return null

    const classes = ["form-group", ...defaultClasses]
    let error = null
    if (this.state.errors[key]) {
      classes.push("has-error")
      error = <div className="error-message"><span>{this.state.errors[key]}</span></div>
    }

    return <div className={classes.join(" ")} key={key}>
      {input}
      {error}
    </div>
  }

  setQuestionnaireType(type) {
    switch (type) {
      case "generic":
        if (this.state.errors.file)
          delete this.state.errors.file
        return this.setState({ questionnaire_type: "generic", file: [] });
      case "custom":
        return this.setState({ questionnaire_type: "custom" })
    }
  }

  renderQuestionnaireTypeOption() {
    if (this.props.organization.uploadRequired)
      return ""

    return <div className="questionnaire-type">
      <p>select one: </p>
      <div className="questionnaire-type-buttons">
        <button className={this.state.questionnaire_type == "generic" ? "select-questionnaire-option selected-type" : "select-questionnaire-option"} onClick={() => this.setQuestionnaireType("generic")}>generic questionnaire</button>
        <button className={this.state.questionnaire_type == "custom" ? "select-questionnaire-option selected-type" : "select-questionnaire-option"} onClick={() => this.setQuestionnaireType("custom")}>custom questionnaire</button>
      </div>
    </div>
  }

  renderModalHeaderCopy() {
    if (this.props.organization.uploadRequired)
      return <p className="questionnaire-modal-text">
        Please fill out the following information below and upload a blank questionnaire to request {this.props.organization.name} to complete.
      </p>

    return <p className="questionnaire-modal-text">
      Please select a questionnaire type, then fill out the information below to request a questionnaire from {this.props.organization.name}.
    </p>
  }

  render() {
    return <div className="questionnaire-submit">
      {this.renderModalHeaderCopy()}
      {this.renderServerError()}

      <div className="questionnaire-modal-form">
        {this.renderQuestionnaireTypeOption()}
        <div className="select-building-section-wrapper">
          {this.renderFormGroup("building", this.renderBuildingInput())}
          <div className="select-building-section">
            {this.renderBuildingAddress()}
            {this.renderFormGroup("address", this.renderAddressSelect(), ['address-select-container'])}
            {this.renderFormGroup("unit_id", this.renderInput("unit_id", "unit #", true), ['unit-number-container'])}
          </div>
          {this.renderGenericPreview()}
        </div>
        <p className="bopa-text">contact information</p>
        <div className="questionnaire-input-div">
          {CONTACT_FIELDS.map(field => this.renderFormGroup(field.name, this.renderInput(field.name, field.label, field.required)))}
          {this.renderFormGroup("file", this.renderFileUpload())}
        </div>
      </div>
      {this.renderButtons()}
    </div>
  }
};
