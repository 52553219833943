import React from 'react'

class Preview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.modal = $(this.refs.modal)

    this.getObjectUrl()

    this.modal.modal("show")
    this.modal.on("hidden.bs.modal", () => {
      if (typeof this.props.onClose == "function")
        this.props.onClose()
    })
  }

  componentWillUnmount() {
    delete this.modal
  }

  componentDidUpdate() {
    this.tryToClickDownload()
  }

  getObjectUrl() {
    this.lookupUrl().then(url => this.setState({url}))
  }

  tryToClickDownload() {
    if (!this.refs.download_link)
      return

    this.refs.download_link.click()
    this.setState({download_url: null})
  }

  lookupUrl() {
    return new Promise((res, rej) => {
      $.ajax({
        url: `/store_documents/${this.props.document.id}/download_url`,
        success: data => res(data.url)
      })
    })
  }

  addToCart() {
    StoreDocument.BuildingPage.addToCart(
      this.props.document.id,
      this.props.building_id,
      this.props.addToCart
    )
  }

  render() {
    return <div ref="modal" className="modal building-document-preview-component" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            {this.renderDownloadLink()}
            {this.renderPreviewArea()}
            {this.renderLoading()}
          </div>
          {this.renderButtons()}
        </div>
      </div>
    </div>
  }

  renderDownloadLink() {
    if (!this.state.download_url)
      return

    return <a href={this.state.download_url} target="_blank" download={true} ref="download_link"></a>
  }

  renderPreviewArea() {
    if (!this.state.url)
      return

    return <object type="application/pdf" data={this.state.url} />
  }

  renderLoading() {
    if (this.state.url)
      return

    return
  }

  renderButtons() {
    const action_buttons = []

    if (this.props.document.in_cart)
      action_buttons.push(this.renderInCart())
    else if (this.props.document.price != 0)
      action_buttons.push(this.renderAddToCartButton())

    if (this.props.document.in_library)
      action_buttons.push(this.renderGoToLibraryButton())

    action_buttons.push(this.renderDownloadButton())

    return <div className="modal-footer">
      <div>
        <button type="button" className="dome-btn dome-btn-link dome-btn-link-cancel" data-dismiss="modal">close</button>
      </div>
      <div>
        {action_buttons}
      </div>
    </div>
  }

  renderInCart() {
    return <button key="in_cart" type="button" className="btn btn-default disabled" disabled={true}>
      <span>in cart</span>
    </button>
  }

  renderAddToCartButton() {
    const click = () => this.props.clickAddToCart()

    return <button key="add_to_cart" type="button" className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={click}>
      <span>re-purchase doc</span>
    </button>
  }

  renderGoToLibraryButton() {
    const click = () => window.location = "/library"

    return <button key="library" type="button" className="dome-btn dome-btn-link dome-btn-link-aqua dome-btn-link-chevron-right" onClick={click}>
      <span>go to library</span>
    </button>
  }

  renderDownloadButton() {
    const click = () => this.setState({download_url: this.state.url})

    return <button key="download" type="button" className="dome-btn dome-btn-base dome-btn-go-green" onClick={click}>
      <span>open</span>
    </button>
  }
}

export default Preview
