import React from 'react'

const arrayDifference = (arr, other_arr) => (arr || []).filter(item => other_arr.includes(item))
class Single extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.requests = {}
  }

  approveRequest(request_id) {
    const requests = Object.assign({}, this.state.requests)
    requests[request_id] == 1 ? (delete requests[request_id]) : (requests[request_id] = 1)
    this.setState({requests})
  }

  denyRequest(request_id) {
    const requests = Object.assign({}, this.state.requests)
    requests[request_id] == 0 ? (delete requests[request_id]) : (requests[request_id] = 0)
    this.setState({requests})
  }

  anyBeingDenied() {
    return Object.values(this.state.requests).some(opt => opt == 0)
  }

  successfulApproval(res, list) {
    const requests = {...this.state.requests}

    this.props.startProcessing(list)
    this.setState({requests})

    this.approve_list = arrayDifference(list, res.unprocessed_requests)
  }

  submitRequests() {
    if (this.processing || this.state.processing)
      return

    this.setState({processing: (this.processing = true)})

    const approve_list = []
    const deny_list = []
    for (let request_id in this.state.requests) {
      if (this.state.requests[request_id] == 1)
        approve_list.push(request_id)
      if (this.state.requests[request_id] == 0)
        deny_list.push(request_id)
    }

    this.setState({awaiting_confirm: approve_list.length > 0 || null, awaiting_deny: deny_list.length > 0 || null})

    // These methods will set states
    if (approve_list.length > 0) {
      this.process("confirm", approve_list, {
        complete: () => this.setState({awaiting_confirm: null}, () => this.processCompleted()),
        success: res => this.successfulApproval(res, approve_list)
      })
    }

    if (deny_list.length > 0) {
      this.process("deny", deny_list, {
        complete: () => this.setState({awaiting_deny: null}, () => this.processCompleted()),
        success: () => this.deny_list = deny_list
      })
    }

    if (approve_list.length == 0 && deny_list.length == 0)
      this.setState({processing: (this.processing = false) || null})
  }

  preauthorizeUser(e) {
    e.preventDefault()

    const url = `/store_document_request/preauthorize`
    const data = hashToPostQueryString({ email: this.props.user.email })

    const on_confirm = action_confirmed => {
      if (!action_confirmed)
        return

      if (this.processing || this.state.processing)
        return

      this.setState({awaiting_confirm: true, processing: (this.processing = true)})

      const list = this.props.requests.map(r => r.id)

      $.ajax({
        url, data, type: "post",

        complete: () => this.setState({awaiting_confirm: null}, () => this.processCompleted()),
        success: res => this.successfulApproval(res, list)
      })
    }

    const message = [`Add this user to the preapprovals list. They will no longer need confirmation for any documents associated with this organization's buildings.`]
    message.push(`Also, all the documents awaiting approval will be immediately confirmed.`)
    swal({
      title: `Preauthorize ${this.props.user.full_name}`,
      html: true,
      text: message.join("<br /><br />"),
      showCancelButton: true
    }, on_confirm)
  }

  approveAll(e) {
    e.preventDefault()

    if (this.props.requests.length == 0)
      return

    const on_confirm = action_confirmed => {
      if (!action_confirmed)
        return

      if (this.processing || this.state.processing)
        return

      this.setState({awaiting_confirm: true, processing: (this.processing = true)})

      const list = this.props.requests.map(r => r.id)

      this.process("confirm", list, {
        complete: () => this.setState({awaiting_confirm: null}, () => this.processCompleted()),
        success: res => this.successfulApproval(res, list)
      })
    }

    const message = [`Confirm approval for all request documents for this user.`]
    message.push(`<small>Please note, this is a one-time action and will need to be repeated if ${this.props.user.full_name} requests additional documents that require approval. To permanently approve this user, please select that option instead.</small>`)
    swal({
      title: `Approve All for ${this.props.user.full_name}`,
      html: true,
      text: message.join("<br /><br />"),
      showCancelButton: true
    }, on_confirm)
  }

  denyAll() {
    if (this.props.requests.length == 0)
      return

    const on_confirm = action_confirmed => {
      if (!action_confirmed)
        return

      if (this.processing || this.state.processing)
        return

      this.setState({awaiting_deny: true, processing: (this.processing = true)})

      const list = this.props.requests.map(r => r.id)
      this.process("deny",  list, {
        complete: () => this.setState({awaiting_deny: null}, () => this.processCompleted()),
        success: () => this.deny_list = list
      })
    }

    const message = [`Reject approval for all request documents for this user.`]
    swal({
      type: "warning",
      title: `Reject All for ${this.props.user.full_name}`,
      html: true,
      text: message.join("<br /><br />"),
      showCancelButton: true
    }, on_confirm)
  }

  process(type, requests, options) {
    const url = `/store_document_request/${type}`
    const data = {
      user_id: this.props.user.id,
      requests: requests
    }

    if (type == "deny")
      data.reason = this.state.denial_reason

    $.ajax({...options, ...{url, type: "post", data: hashToPostQueryString(data)}})
  }

  processCompleted() {
    if (this.awaiting_confirm || this.awaiting_deny)
      return

    const processed_requests = (this.approve_list || []).concat(this.deny_list || [])

    // Clear these for next time
    this.approve_list = null
    this.deny_list = null

    this.props.removeRequests(processed_requests)
    this.setState({processing: (this.processing = false) || null, requests: {}, deny_all: false})
  }

  requestDetails(request_id) {
    return (this.props.details || {})[request_id] || {}
  }

  isApproving(request_id) {
    return this.requestDetails(request_id).approving
  }

  requestError(request) {
    return this.requestDetails(request.id).error || request.last_payment_error
  }

  renderRequest(request) {
    const building = this.props.buildings[request.building_id]
    const doc = this.props.documents[request.store_document_id]
    const price = request.price == 0 ? "Free" : asMoney(request.price)

    const classes = ["request"]
    if (this.state.requests[request.id] == 1)
      classes.push("approved")
    if (this.state.requests[request.id] == 0)
      classes.push("denied")

    const error = this.requestError(request) ? <div className="error">{this.requestError(request)}</div> : null

    return <div className={classes.join(" ")} key={request.id}>

      <div className="building">
        {building.title || building.address}
      </div>
      <div className="file-type">
        {doc.display_type}
      </div>
      <div className="title">
        {doc.label}
      </div>
      <div className="price">
        {price}
      </div>
      <div className="options">
        {this.renderRequestButtons(request)}
        {this.isApproving(request.id) ? null : error}
      </div>
    </div>
  }

  renderRequestButtons(request) {
    if (this.isApproving(request.id))
      return <div className="approving">
        <div>approving</div>
        <div className="spinner-border"></div>
      </div>

    const disabled = this.state.processing
    return <div className="buttons">
      <button disabled={disabled} className="approve" onClick={() => this.approveRequest(request.id)}>
        <div className='approve-radio-button' aria-hidden="true" />
        approve
      </button>
      <button disabled={disabled} className="deny" onClick={() => this.denyRequest(request.id)}>
        <div className='deny-radio-button' aria-hidden="true" />
        decline
      </button>
    </div>
  }

  renderDenialReason() {
    if (!this.anyBeingDenied() && !this.state.deny_all)
      return null

    return <div className="denial-reason">
      <label className="form-label">denial reason</label>
      <textarea className="form-control dome-rounded-border" defautValue={this.state.denial_reason} onChange={e => this.setState({denial_reason: e.target.value})} placeholder='Enter the reason for request denial. (optional)' />
    </div>
  }

  renderRequestList() {
    if (!this.state.open)
      return

    return <div>
      <small className="text-muted">
        After deciding which requests to confirm or deny, you must verify your selections by pressing the "Verify Choices" button at the bottom of the request list.
      </small>
      <div className="requests">
        {this.props.requests.map(request => this.renderRequest(request))}
      </div>
    </div>
  }

  renderExpandable() {
    const verify_enabled = Object.values(this.state.requests).some(x => [0,1].includes(x))
    const disabled = this.state.processing
    const deny_reason = this.renderDenialReason()

    const buttons = []
    if (this.state.open && verify_enabled) {
      buttons.push(<button onClick={() => this.submitRequests()} disabled={disabled} className="dome-btn dome-btn-base dome-btn-green-stroke" key="verify">verify choices</button>)
    }
    if (this.state.deny_all)
      buttons.push(<button key="deny-all" onClick={() => this.denyAll()} disabled={disabled} className="dome-btn dome-btn-base dome-btn-red-stroke">deny all</button>)

    const children_style = {}
    if (this.state.open || this.state.deny_all)
      children_style.height = "auto"

    return <div className="expandable" style={children_style}>
      {this.renderRequestList()}
      <div className="dome-d-flex dome-align-center dome-gap6">
        {deny_reason}
        <div className="buttons">
          {buttons}
        </div>
      </div>
    </div>
  }

  clearRequests() {
    const requests = {...this.state.requests}
    for (let request_id in requests)
      if ([0,1].includes(requests[request_id]))
        delete requests[request_id]

    this.setState({requests})
  }

  toggleExpand() {
    this.clearRequests()
    this.setState({open: !this.state.open, deny_all: false})
  }

  toggleDenyAll(e) {
    e.preventDefault()
    this.clearRequests()
    this.setState({deny_all: !this.state.deny_all, open: false})
  }

  renderButton() {
    const button_text = this.state.open ? "close" : "open >"
    const disabled = this.state.processing

    return <div className="btn-group" role="group">
      <button onClick={() => this.toggleExpand()} type="button" className={`dome-btn dome-btn-base dome-btn-${this.state.open ? 'grey-stroke' : 'green-stroke'}`}>
        {button_text}
      </button>

      <button disabled={disabled} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa-light fa-ellipsis-vertical"></i>
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
        <a className="dropdown-item" onClick={e => this.preauthorizeUser(e)} href="#">Add <strong>{this.props.user.full_name}</strong> as Preauthorized User</a>
        <a className="dropdown-item" onClick={e => this.approveAll(e)} href="#">Confirm All Requests</a>
        <a className="dropdown-item" onClick={e => this.toggleDenyAll(e)} href="#">Deny All Requests</a>
      </div>
    </div>
  }

  render() {

    return <div className="store-document-request">
      <div>
        <div className="user-name">{this.props.user.full_name}</div>
        <div className="email">{this.props.user.email}</div>
        <div className="number-of-documents">{this.props.requests.length}</div>
        <div className="number-of-buildings">{this.props.building_count}</div>
        <div className="button">
          {this.renderButton()}
        </div>
      </div>
      {this.renderExpandable()}
    </div>
  }
}

export default Single
