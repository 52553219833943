import React from 'react'

class MyLibraryDocuments extends React.Component {

  handleTypeChange(event) {
    this.props.onLibraryInputTypeChange(event.target.value)
  }

  handleSearchChange(event) {
    this.props.onLibrarySearchChange(event.target.value)
  }

  handleLibrarySearch() {
    event.preventDefault()
    this.props.onLibrarySearch()
  }

  onAttachDocument(){
    this.props.handleAttachDocument(this.props.document.id)
  }

  render(){
    const docTypeOptions = Object.keys(this.props.docTypes).map((key,i) => {
      return ( <option key={i} value={key}>{this.props.docTypes[key]}</option> )
    })
    docTypeOptions.push(( <option key={docTypeOptions.length + 1} value={'all'}>all</option> ))

    let formInputStyle={width: '100%'};

    return (
      <div className='my-library-documents row'>
        <div className='col-xs-12'>
          <p>Use this section to upload existing documents currently stored in your Library.</p>
        </div>
        <div className='col-xs-6'>
          <input type='text'
            value={this.props.librarySearch}
            onChange={this.handleSearchChange}
            className='form-control'
          />
        </div>
        <div className='col-xs-3'>
          <select defaultValue={this.props.librarySearchType}
            value={this.props.librarySearchType}
            name='librarySearchType'
            onChange={this.handleTypeChange}
            className='form-control'
          >
            {docTypeOptions}
          </select>
        </div>
        <div className='col-xs-2'>
          <button onClick={this.handleLibrarySearch} className='form-control btn btn-success'>search</button>
        </div>
      </div>
    )
  }
}

export default MyLibraryDocuments
