import React from 'react'

import { useEventListeners } from '@deathbyjer/react-event-manager'

import { eventForId } from 'components/utility/draw_area'

import { isMinimumSize } from '.'
import { useReadApi, createToolbar, toolGenerator } from './base'
import { SelectTag, SliderTag } from './utilities'


function isId(id) {
  const idArray = Array.isArray(id) ? id : String(id).split(",")
  return idArray[0] == 'strikethrough'
}

function extractId(id) {
  if (Array.isArray(id))
    return id[1]

  return String(id).split(",")[1]
}

export const COLORS = {
  "black": "Black",
  "red": "Red"
}

export const STYLES = {
  "solid": "Solid Line",
  "dotted": "Dots",
  "dashed": "Dashes"
}

export const generateEmpty = () => ({
  position: { height: 0.5 },
  options: {
    color: "red",
    width: 1,
    style: 'solid'
  }
})

function Component({id, setId, api}) {
  const fullId = ["strikethrough", id]
  setId ? setId(fullId) : null

  const strike = useReadApi(api, id)

  useEventListeners({
    [eventForId('rect-updated', fullId)]: ({rect}) => {
      if (isMinimumSize(rect, { lockedHeight: true })) {
        const position = { ...strike.position, ...rect }
        api.update(id, {...strike, position, ...strike.options})
      } else {
        api.destroy(id)
      }
    }
  })

  const classes = ["strikethrough-tool-component"]
  const style = {
    borderTopColor: strike.options.color,
    borderTopWidth: strike.options.width + "px",
    marginTop: `-${strike.options.width / 2}px`,
    borderTopStyle: strike.options.style
  }

  return <div className={classes.join(" ")}>
    <div style={style}></div>
  </div>
}

function Toolbar({api, componentIds}) {
  const id = componentIds[0][1]

  const strike = useReadApi(api, id)
  const onChangeColor = color => api.update(id, { ...strike, options: { ...strike.options, color}})
  const onChangeStyle = style => api.update(id, { ...strike, options: { ...strike.options, style}})

  const widths = []
  for (let i = 1; i <= 6; i++)
    widths.push(i)

  const onChangeWidth = width => {
    const options = { ...strike.options, width}
    api.update(id, { ...strike, options })
  }

  return <div className="strikethrough-tool-toolbar-component">
    <SelectTag label="Color" value={strike.options.color} options={Object.entries(COLORS)} onChange={onChangeColor} />
    <SliderTag label="Width" value={strike.options.width} marks={widths} min={1} max={6} onChange={onChangeWidth} />
    <SelectTag label="Style" value={strike.options.style} options={Object.entries(STYLES)} onChange={onChangeStyle} />
  </div>
}

function doCommand({api, command, id}) {
  switch(command) {
  case 'DELETE':
    return api.destroy ? api.destroy(extractId(id)) : null
  }
}

export function prepareComponents(type, strikethroughs) {
  return strikethroughs.map(strike => ({
    type: `strikethrough/${type}`,
    id: strike.id,
    props: {
      id: strike.id,
      rect: strike.position,
      drawOnCreate: strike.newlyAdded,
      lockVerticalScale: true
    }
  }))
}


export default toolGenerator({
  components: {
    strikethrough: Component
  },

  toolbars: {
    single: createToolbar(Toolbar, {
      showForSelected(componentIds) {
        // Only if one is selected
        if (componentIds.length != 1)
          return false

        return isId(componentIds[0])
      }
    })
  }
}, {
  componentPropsFor({allSelected, props}) {
    return props
  },
  isId,
  doCommand
})
