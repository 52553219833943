import React, { useCallback, useMemo } from 'react'
import { useSelector } from "react-redux"

import { compact } from 'lodash'

// A list of all people (data people) that a user is representing
function useUserAssignedPeople(email) {
  const people = useSelector(({roles}) => roles.user_data_people[email])
  const peopleNames = useSelector(({roles}) => roles.people_names)

  const dealParties = useSelector(({roles}) => roles.deal_parties)
  const metadata = useSelector(({roles}) => roles.deal_party_metadata)
  const personParties = useSelector(({roles}) => roles.person_parties)
  const personDealParties = useSelector(({roles}) => roles.person_deal_parties)

  return useMemo(() => (people || []).map(person_id => {
    const name = peopleNames[person_id]
    const partyId = personParties[person_id]
    const dealPartyId = personDealParties[person_id]

    return dealPartyId ?
      // The user they are representing is assigned to a deal party
      { key: `deal-party|${dealPartyId}`, value: `Representing ${dealParties[dealPartyId].label} (${name})` } :
      // The user they are representing is not assigned to a deal party
      { key: `person|${person_id}`, value: `Representing ${name} (${metadata[partyId].label})`}
  }), [people, peopleNames, personParties, personDealParties])
}

// A list of all the roles that a user is representing
function useUserAssignedRoles(email) {
  const roles = useSelector(({roles}) => roles.roles)
  const userRoles = useSelector(({roles}) => roles.user_roles[email])

  return useMemo(() => (userRoles || []).filter(role_id => roles[role_id]).map(role_id => ({
    key: `role|${role_id}`, value: (roles[role_id].signatory ? "Representing " : "") + roles[role_id].label_singular
  })), [userRoles])
}

function useUserRoles(email) {
  const assignedPeople = useUserAssignedPeople(email)
  const assignedRoles = useUserAssignedRoles(email)

  return useMemo(() => {
    const roles = [assignedPeople, 
      assignedRoles
    ]

    return compact(roles.flat())
  }, [assignedPeople, assignedRoles])
}

function usePersonRoles(person_id, email) {
  const dealParty = useSelector(({roles}) => roles.deal_parties[roles.person_deal_parties[person_id]])
  
  const partyRole = dealParty ? { key: "deal-party", value: dealParty.label } : null
  const roles = useUserRoles(email)

  return useMemo(() => compact([partyRole, ...roles]), [partyRole?.key, partyRole?.value, roles])
}

function Person({name, roles, onClick, selected}) {
  const classes = compact([
    "dropdown-person",
    selected ? "dropdown-selected" : null
  ]).join(" ")

  return <div className={classes} onClick={onClick}>
    <div className="name">{name}</div>
    <div className="roles">
      { roles.map(role => <div key={role.key}>{role.value}</div>)}
    </div>
  </div>
}

export function DropdownPerson({ person_id, onClick, selected }) {
  const name = useSelector(({roles}) => roles.people_names[person_id])
  const email = useSelector(({roles}) => roles.people_emails[person_id])
  const roles = usePersonRoles(person_id, email)

  return <Person name={name} selected={selected} roles={roles} onClick={onClick} />
}

export function DropdownUser({email, onClick, selected}) {
  const name = useSelector(({roles}) => roles.user_names[email])
  const roles = useUserRoles(email)

  return <Person name={name} selected={selected} roles={roles} onClick={onClick} />
}

export default function({person, onClick, selected}) {
  const {person_id, email } = person
  const handleClick = useCallback(() => onClick ? onClick(person) : null, [person, onClick])

  const props = {
    selected,
    onClick: handleClick                                              
  }

  if (person_id)
    return <DropdownPerson person_id={person_id} {...props} />
  else if (email)
    return <DropdownUser email={email} {...props} />

  return null
}