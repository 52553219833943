import React, { memo } from "react";

const CategoryFilterButton = memo(({ categoryId, categoryTitle, onFilter, active }) => {
  const classes = ["btn", "btn-xs", "goods-services__filter-categories-btn"];

  if (active) classes.push("active");

  return (
    <button className={classes.join(" ")} onClick={() => onFilter(categoryId)}>
      {categoryTitle}
    </button>
  );
});

export default function CategoriesFilter({ categories, activeCategoryId, onFilter }) {
  const buttonsWithViewAll = [{ id: null, title: "View all" }, ...categories];

  return (
    <>
      {buttonsWithViewAll.map((button) => (
        <CategoryFilterButton
          key={button.id}
          categoryId={button.id}
          categoryTitle={button.title}
          onFilter={onFilter}
          active={activeCategoryId === button.id}
        />
      ))}
    </>
  );
}
