import React from "react";
import BuildingSelect from "components/utility/building_select";
import { chunk, padEnd } from "lodash";

const onlyNumbers = (str) => String(str).replaceAll(/[^0-9.]/g, "");

const REQUIRED_FIELDS = [
  "unit",
  "name",
  "email",
  "refund_type",
  "refund_amount",
  "refund_fee_name",
  "reason",
];

const REFUND_TYPES = [
  "Package Initiation",
  "Building Fees",
  "Doc Purchase",
  "Questionnaire",
  "Concierge",
  "5% CC Fee",
  "Board Minutes",
  "Management Fees",
  "Digital Submission Fee",
  "All Package Fees",
  "Other",
].map((type) => ({ id: type, name: type }));

function addCommas(number) {
  const asArray = number.split("");
  const chunkedArray = chunk(asArray.reverse(), 3)
    .reverse()
    .map((num) => num.reverse().join(""));
  return chunkedArray.join(",");
}

function prettyNumber(string) {
  const digits_only = onlyNumbers(string);
  const [dollars, cents] = digits_only.split(".");
  const croppedCents = padEnd(String(cents || "").substring(0, 2), 2, "0");

  return [addCommas(dollars) || '0', croppedCents].join(".");
}

class RequestRefundModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.errors = {};
    this.state.property_management_firms = [];
  }

  componentDidMount() {
    $(this.refs.modal).modal("show");
    $(this.refs.modal).on("hidden.bs.modal", () => this.props.onClose());
  }

  componentWillUnmount() {
    $(this.refs.modal).off("hidden.bs.modal", () => this.props.onClose());
  }

  close() {
    $(this.refs.modal).modal("hide");
    this.props.onClose(null);
  }

  setValue(e, id, options = {}) {
    const state = {};
    const errors = Object.assign({}, this.state.errors);
    delete errors[id];

    state[id] = e.target.value ? String(e.target.value).trim() : null;
    if (options.isNumber) state[id] = onlyNumbers(state[id]);

    state.errors = errors;

    this.setState(state);
  }

  renderText(id, label, required) {
    const input = (
      <input
        key="value"
        type="text"
        defaultValue={this.state[id]}
        required={required}
        onChange={(e) => this.setValue(e, id)}
      />
    );
    return this.renderLine(id, input, required, label);
  }

  renderTextArea(id, label, required) {
    const input = (
      <textarea
        key="value"
        type="text"
        defaultValue={this.state[id]}
        required={required}
        onChange={(e) => this.setValue(e, id)}
      />
    );
    return this.renderLine(id, input, required, label);
  }

  renderSelect(id, label, values, required) {
    const options = values.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name || item.label}
      </option>
    ));
    options.unshift(<option className="placeholder" value="" key="__placeholder">--</option>);

    const classes = [];
    if (!this.state[id] || this.state[id] == "") classes.push("placeholder");

    const input = (
      <select
        className={classes.join(" ")}
        key={id}
        onChange={(e) => this.setValue(e, id)}
        defaultValue={this.state[id]}
      >
        {options}
      </select>
    );
    return this.renderLine(id, input, required, label);
  }

  renderMoney(id, label, required) {
    const onBlur = (e) => (e.target.value = prettyNumber(e.target.value));
    const onFocus = (e) => (e.target.select());

    const onChange = (e) => this.setValue(e, id, { isNumber: true });

    const input = (
      <div key="value" className="money-type">
        <i className="fa-light fa-dollar" />
        <input
          type="text"
          defaultValue={this.state[id]}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </div>
    );

    return this.renderLine(id, input, required, label);
  }

  handleSetBuilding(evt) {
    const state = {};
    const errors = Object.assign({}, this.state.errors);
    delete errors.building;

    state.building = evt.building;
    state.custom_building = evt.custom_building;
    state.errors = errors;

    if (state.building) {
      this.loadPropertyManagementFirm(state.building.id);
    } else {
      state.property_management_firms = [];
      state.property_management_firm = null;
    }
    this.setState(state);
  }

  loadPropertyManagementFirm(building_id) {
    const ajax_options = {
      url: `/buildings/${building_id}/organizations`,
      type: "GET",
      dataType: "json",
      success: (response) => {
        const data = [response.organization];
        this.setState({
          property_management_firms: data.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        });
      },
      error: (xhr) => {
        try {
          const errors = JSON.parse(xhr.responseText).error;
          if (typeof errors == "object") this.setState({ errors: errors });
          else this.setState({ server_error: errors });
        } catch (e) {
          this.setState({
            server_error:
              "Something went wrong with the request. Please try again later or contact BoardPackager by emailing info@boardpackager.com",
          });
        }
      },
      contentType: false,
      processData: false,
    };

    $.ajax(ajax_options);
  }

  renderLine(id, input, required, label) {
    const children = [];
    const labelChildren = []

    labelChildren.push(label)
    if (required)
      labelChildren.push(
        <span className="required-star" key="required">
          *
        </span>
      );
    children.push(input);

    const fields = [];
    const classes = ["form-line"];
    if (label)
      classes.push(label.split(' ').join('-'))

    fields.push(
      <div className="field input-field" key="input">
        {children}
      </div>
    );

    if (this.state.errors[id]) {
      classes.push("has-error");
      fields.push(
        <div key="error" className="error">
          {this.state.errors[id]}
        </div>
      );
    }

    return (
      <div className='field-container' key={id}>
        <label id={id} htmlFor={id}>
          {labelChildren}
        </label>
        <div className={classes.join(" ")} key={id}>
          {fields}
        </div>
      </div>
    )
  }

  renderBuilding() {
    const input = (
      <BuildingSelect
        key="building"
        show_magnifying_glass={false}
        show_empty_message={true}
        allow_custom={true}
        ref="building_select"
        params={{ include_addresses: 1 }}
        onChange={(evt) => this.handleSetBuilding(evt)}
      />
    );
    return this.renderLine("building", input, true, "property");
  }

  renderUnit() {
    const buildingSelected = this.state.building
    const input = <input key="value" className={buildingSelected ? 'building-is-selected' : null} defaultValue={this.state['unit']} type="text" required={true} onChange={e => this.setValue(e, 'unit')} />

    return buildingSelected ? this.renderLine('unit', input, true, 'unit') : null
  }

  renderInlineRow(fields) {
    return (
      <div className='form-inline-row'>
        {fields}
      </div>
    )
  }

  formElements() {
    const elements = [];
    elements.push(this.renderInlineRow([this.renderBuilding(), this.renderUnit()]));
    // elements.push(this.renderText("unit", "unit #", true))
    elements.push(
      this.renderSelect(
        "property_management_firm",
        "select management company",
        this.state.property_management_firms,
        false
      )
    );
    elements.push(<h4 key="contact-info">contact information</h4>);
    elements.push(this.renderText("name", "refund recipient", true));
    elements.push(this.renderText("email", "email", true));
    elements.push(this.renderMoney("refund_amount", "refund amount", true));
    elements.push(
      this.renderInlineRow([
        this.renderText("refund_fee_name", "fee name", true),
        this.renderSelect("refund_type", "refund type", REFUND_TYPES, true)
      ])
    );
    elements.push(
      this.renderTextArea(
        "reason",
        "briefly describe what you'd like help with",
        true
      )
    );

    return elements;
  }

  renderSuccess() {
    if (!this.state.success) return;

    return (
      <div className='success'>
        <div className='header'>
          <i className='fa-thin fa-circle-check'></i>
          <h2>success!</h2>
        </div>

        <p className='description'>
          Your request for a refund has been received. You will receive a
          confirmation email and a member of our team will be in touch shortly.
        </p>

        <div className='buttons'>
          <button
            type='button'
            className='btn-base btn-aqua-stroke'
            onClick={() => this.close()}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  all_filled() {
    const is_filled = (item) =>
      this.state[item] && !String(this.state[item]).match(/^\s*$/);
    if (!REQUIRED_FIELDS.every((field) => is_filled(field))) return false;

    if (!is_filled("building") && !this.state.custom_building) return false;

    return true;
  }

  gatherErrors() {
    const errors = {};

    const is_blank = (item) =>
      !this.state[item] || String(this.state[item]).match(/^\s*$/);

    for (let field of REQUIRED_FIELDS)
      if (is_blank(field)) errors[field] = "This field is required";

    if (!this.state.building && !this.state.custom_building)
      errors.building = "This field must be fully completed";

    if (!errors.email && !this.state.email.match(/^.+@.+\..+$/))
      errors.email = "This is not a valid email address";

    this.setState({ errors: errors });
    return Object.keys(errors).length > 0;
  }

  isSubmitting() {
    if (this.state.submitting) return true;

    this.setState({ submitting: true });
    return false;
  }

  checkSubmitting() {
    return this.state.submitting;
  }

  finishedSubmitting() {
    this.setState({ submitting: false });
  }

  submit() {
    if (this.isSubmitting()) return;

    if (this.gatherErrors()) return this.finishedSubmitting();

    const form_data = new FormData();
    for (let attr of REQUIRED_FIELDS)
      form_data.append(`request[${attr}]`, this.state[attr]);

    if (this.state.property_management_firm) {
      form_data.append(
        `request[property_management_firm]`,
        this.state["property_management_firm"]
      );
    }

    if (this.state.custom_building) {
      for (let attr in this.state.custom_building)
        form_data.append(
          `request[custom_building][${attr}]`,
          this.state.custom_building[attr]
        );
    } else form_data.append("request[building]", this.state.building.id);

    const ajax_options = {
      url: "/request_refunds",
      type: "POST",
      data: form_data,
      dataType: "json",
      success: () => this.setState({ success: true }),
      error: (xhr) => {
        try {
          const errors = JSON.parse(xhr.responseText).error;
          if (typeof errors == "object") this.setState({ errors: errors });
          else this.setState({ server_error: errors });
        } catch (e) {
          this.setState({
            server_error:
              "Something went wrong with the request. Please try again later or contact BoardPackager by emailing info@boardpackager.com",
          });
        }
      },
      complete: () => this.finishedSubmitting(),
      contentType: false,
      processData: false,
    };

    $.ajax(ajax_options);
  }

  renderForm() {
    if (this.state.success) return;

    const submission_classes = ["btn-base", "btn-go-green"];
    if (!this.all_filled() || this.checkSubmitting())
      submission_classes.push("btn-disabled");

    if (this.checkSubmitting()) submission_classes.push("submitting");

    return (
      <div>
        <div className='header'>
          <i className='icon fa-thin fa-messages-dollar'></i>
          <h2 className='color-dark-green'>request a refund</h2>
        </div>
        <p className='description' style={{ fontWeight: 400 }}>
          Please fill out the following information to request a refund.
        </p>
        <div className='form-area'>{this.formElements()}</div>

        <div className='buttons'>
          <button
            type='button'
            className='btn-link cancel-link'
            onClick={() => this.close()}
          >
            cancel
          </button>
          <button
            type='button'
            className={submission_classes.join(" ")}
            onClick={() => this.submit()}
          >
            submit
          </button>
        </div>
      </div>
    )
  }

  render() {
    const submission_classes = ["btn", "submit", "btn-success"];

    return (
      <div
        id='refund-request-modal'
        ref='modal'
        className='modal'
        tabIndex='-1'
        role='dialog'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              {!this.state.success ? this.renderForm() : this.renderSuccess()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RequestRefundModal;
