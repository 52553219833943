import React, { useMemo } from 'react'
import clsx from 'clsx'

function moneyToParts(amount) {
  const options = { style: 'currency', currency: 'USD', trailingZeroDisplay: 'stripIfInteger' }
  const formatter = new Intl.NumberFormat('en-US', options)
  let amountParts = { dollars: '', cents: '' }
  formatter.formatToParts(amount).forEach(({ type, value }) => {
    switch (type) {
      case 'fraction':
        amountParts['cents'] += value
        break
      case 'decimal':
        break
      default:
        amountParts['dollars'] += value
        break
    }
  })

  return amountParts
}

export default function MonetaryAmount({ amount, dollarsClass, centsClass }) {
  const { dollars, cents } = useMemo(() => {
    return moneyToParts(amount)
  }, [amount])

  return (
    <>
      <span className={clsx(dollarsClass)}>{dollars}</span>
      {cents && <span className={clsx(centsClass)}>{`.${cents}`}</span>}
    </>
  )
}

MonetaryAmount.defaultProps = {
  amount: '',
  dollarsClass: '',
  centsClass: 'dome-p2 dome-text-w500',
}
