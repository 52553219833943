import React, { useState } from "react"

const useLoader = () => {
  const [loading, setLoading] = useState(false)
  const [finishedLoading, setFinishedLoading] = useState(false)

  const withLoader = async callback => {
    try {
      setLoading(true)
      await callback()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setFinishedLoading(true)
    }
  }

  return { loading, finishedLoading, withLoader }
}

export default useLoader
