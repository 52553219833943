import React, { useContext, useState, useEffect, useRef, useMemo, useCallback, Fragment } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import { useEventManager } from '@deathbyjer/react-event-manager'

import { compact, mapKeys, groupBy, startsWith, pick, forEach } from 'lodash'

import { addCSRF } from 'lib/utilities'
import { metadataByPath, pathMapToHash, firstPathSegment, extractArrayFromPathMap } from 'lib/utilities/form'
import InternalField from 'components/form_field'

import { useRenderedPositionValue } from './field_position/field_positions'
import { addIdsToValues } from './utilities'

const initialState = {
  metadata: {},
  fields: {},
  values: {},
  updates: {},
  valuesHash: {},
  unlinkedValues: {},
  updatingPositions: {}
}

const Store = createSlice({
  name: "fields",
  initialState,
  reducers: {
    setFields(state, { payload: {field_defs: fields, metadata, values, unlinked_values, deal_parties} }) {
      state.metadata = metadata
      state.fields = addIdsToValues(fields)
      state.updates = {}
      state.values = values
      state.valuesHash = pathMapToHash(values)
      state.unlinkedValues = unlinked_values
    },

    updateValues(state, { payload: hash }) {
      state.updates = { ...state.updates, ...hash }
      state.values = { ... state.values, ... hash }
      state.valuesHash = pathMapToHash(state.values)
    },

    setPositionUpdating(state, { payload: { id, updating}}) {
      updating ? state.updatingPositions[id] = true : delete state.updatingPositions[id]
    },

    setUnlinkedValue(state, { payload: { id, value }}) {
      state.unlinkedValues[id] = value
    }
  }
})

const { setPositionUpdating } = Store.actions
export const { updateValues } = Store.actions

export const reducer = Store.reducer
export const { setFields, setUnlinkedValue } = Store.actions

const FieldPositionContext = React.createContext({})

function mappedPathFor(mapping, path) {
  for (let field in mapping) {
    if (!startsWith(path, field))
      continue

    return mapping[field] ? (mapping[field] + path.substr(field.length)) : null
  }

  return path
}

export function useMappedFieldIds(fieldIds) {
  const mapping = useSelector(({deal_parties: state}) => state.deal_party_mapping)

  return useMemo(() => Object.fromEntries(fieldIds.map(id => [id, mappedPathFor(mapping, id)])), [fieldIds, mapping])
}

export function useReverseMappedFieldIds(fieldIds) {
  const mapping = useSelector(({deal_parties: state}) => state.deal_party_mapping)

  return useMemo(() => Object.fromEntries(fieldIds.map(id => [mappedPathFor(mapping, id), id])), [fieldIds, mapping])
}

export function useMergedFields(fieldIds) {
  const mappedFieldIds = useMappedFieldIds(fieldIds)

  const fieldsMetadata = useSelector(({form_fields: state}) => {
    const fieldsLookup = fieldIds.map(field_id => [field_id, metadataByPath(state.metadata, mappedFieldIds[field_id])])
    return Object.fromEntries(fieldsLookup)
  }, shallowEqual)

  const fields = useSelector(({form_fields: state}) => {
    const fieldsLookup = fieldIds.map(field_id => [ field_id, state.fields[field_id]] )
    return Object.fromEntries(fieldsLookup)
  }, shallowEqual)

  return useMemo(() => {
    const merged = fieldIds.map(field_id => [field_id, { ...(fieldsMetadata[field_id] || {}), ...(fields[field_id] || {})}])
    return Object.fromEntries(merged)
  }, [ fieldsMetadata, fields])
}

export function useFieldValues(fieldIds) {
  const fields = useMergedFields(fieldIds)
  const mappedIds = useMappedFieldIds(fieldIds)

  return useSelector(({form_fields: state}) => Object.fromEntries(fieldIds.map(fieldId => {
    const field = fields[fieldId]
    const mappedId = mappedIds[fieldId]

    return [ fieldId, field.multiple ? extractArrayFromPathMap(state.values, mappedId) : state.values[mappedId]]
  })), shallowEqual)
}

function FieldPositionProvider({ field_position, children }) {
  const { fields: fieldIds } = field_position

  const mergedFields = useMergedFields(fieldIds)
  const mappedFieldIds = useMappedFieldIds(fieldIds)
  const originalValues = useFieldValues(fieldIds)

  const [ values, setValues ] = useState(originalValues)
  const [ updates, setUpdates ] = useState({})
  const updateValues = useCallback(hash => {
    setValues({ ...values, ... hash}) // Values are kept relative to the field position

    setUpdates({ ... updates, ... mapKeys(hash, (_, key) => mappedFieldIds[key]) }) // Updates are kept relative to the global data
  }, [ updates, values, setUpdates, setValues ])

  const [ errors, setErrors ] = useState({})

  const value = useMemo(() => ({
    mappedFieldIds,
    mergedFields,
    updates, values, updateValues,
    errors, setErrors
  }), [ mappedFieldIds, mergedFields, updates, values, updateValues, errors, setErrors ])

  return <FieldPositionContext.Provider value={value}>
    {children}
  </FieldPositionContext.Provider>
}

/** API CALLS */

export function save({ instance_id, updates, unlinkedValues }) {
  const url = `/forms/v3/instance/${instance_id}/data`

  const form = {
    updates: JSON.stringify(updates),
    unlinked_values: JSON.stringify(unlinkedValues)
  }

  return new Promise((res, rej) => {
    $.ajax({
      url,
      data: addCSRF({ form }),
      method: 'put',
      dataType: 'json',
      success: data => res(data)
    })
  })
}

/************ */

function InternalSingleField({field_id, locked}) {
  const { mergedFields, values, errors, updateValues } = useContext(FieldPositionContext)

  const mergedField = mergedFields[field_id]

  const globalPathmap = useSelector(({form_fields: state}) => mergedField.linked_fields ? state.values : {}, shallowEqual)
  const value = values[field_id]
  const error = errors[field_id]
  const handleChange = updateValues

  const classes = compact([

  ]).join(" ")

  return <InternalField id={field_id} locked={locked} className={classes} field={mergedField} onChange={handleChange} error={error} value={value} globalPathmap={globalPathmap} />
}

const SingleField = React.memo(InternalSingleField)

function useFieldsByParties(field_position) {
  const { fields } = field_position
  const dealParties = useSelector(({deal_parties: state}) => state.deal_parties)
  const mapping = useSelector(({deal_parties: state}) => state.deal_party_mapping )

  const groupedFields = groupBy(fields, field => firstPathSegment(field))

  const out = {}

  forEach(groupedFields, ( fields, id) => {
    const party = dealParties[id]
    const partyId = party ? id : "_default"
    out[partyId] ||= { id: partyId, label: party?.label, party: party?.party, fields: [], isMapped: mapping[party?.id] }
    out[partyId].fields = out[partyId].fields.concat(fields)
  })

  return out
}

const UnlinkedLabel = React.memo(() => {
  return <div style={{ color: '#898989', padding: '5px 10px'}}>
    Not linked to data
  </div>
})

function Label({field_position, unlinked}) {
  if (unlinked)
    return <UnlinkedLabel />

  // Ok
  return <div className='field-position-label'>
    <div>Linked Data</div>
    <div>
      {field_position.label}
      {/* PATH GOES HERE. IT WILL BE A ATTRIBUTE IN FIELD POSITION. TBD */}
    </div>
  </div>
}

function Group({group}) {
  const events = useEventManager()
  const personId = useSelector(({deal_parties: state}) => state.deal_party_mapping[group.id])
  const personName = useSelector(({deal_parties: state}) => state.deal_party_names[personId])
  const isPartyGroup = group.id != "_default"

  const clickAssignment = () => {
    events.applyEventListeners('openRoleAssignment')
  }

  return <>
    <hr className="heavy" />
    { isPartyGroup ? (
      <div className="field-position-group-label">
        <div>{group.label}</div>
        <div>{personName || "Unassigned"}</div>
      </div>
    ) : null }
    { isPartyGroup && !personId ? null : <GroupFields group={group} hideFirstLine={!isPartyGroup} /> }
    { isPartyGroup ? (<>
      <hr />
      <div className="field-position-action" onClick={clickAssignment}>
        <i className="fal fa-user-cog icon"></i>
        <span>{ personId ? "Update Role Assignment" : "Assign Role" }</span>
        <i className="fal fa-chevron-right arrow"></i>
      </div>
    </>) : null }
  </>
}

const SPECIAL_PATHS = ['email_path', 'first_name_path', 'last_name_path']

function useSpecialFields({ id, party: party_id}) {
  const metadata = useSelector(({deal_parties: state}) => state.metadata[party_id])

  if (!metadata)
    return []

  return Object.fromEntries(
      SPECIAL_PATHS.map(attr => metadata[attr])
        .concat(metadata.special_fields || [])
        .map(field => [`${id}.${field}`, true])
  )
}

function GroupFields({group, hideFirstLine}) {
  const specialFields = useSpecialFields(group)

  const fields = useSelector(({form_fields: state}) => {
    return Object.fromEntries(group.fields.map(field_id => [field_id, state.fields[field_id]]))
  }, shallowEqual)

  const visible_fields = group.fields.filter(field_id => !fields[field_id]?.hidden)

  return <>
    {visible_fields.map((field_id, index) => <Fragment key={field_id}>
      { hideFirstLine && index == 0 ? null : <hr /> }
      <SingleField field_id={field_id} locked={fields[field_id].locked || specialFields[field_id]} />
    </Fragment>)}
  </>
}

function LinkingButton({ children, onClick, icon}) {
  const style = { padding: '5px 10px'}
  const iconClass = [ 'fa-light', `fa-${icon}` ].join(" ")

  return <div className='hover-use-pointer-cursor' style={style}>
    <div onClick={onClick}>
      <i className={iconClass} style={{marginRight: 3}} />
      { children }
      <i className='fa-light fa-circle-question' style={{marginLeft: 3 }} />
      <i className='fa-thin fa-chevron-right' style={{ float: 'right' }} />
    </div>
  </div>
}

function getElementOverflow(el) {
  const parent = el.offsetParent
  const ancestor = el.closest('.draw-area-component')

  const rect = el.getBoundingClientRect()
  const parentRect = parent.getBoundingClientRect()
  const ancestorRect = ancestor.getBoundingClientRect()

  return {
    x: ((parentRect.x - ancestorRect.x) + rect.width) > ancestorRect.width,
    y: (((parentRect.y + parentRect.height) - ancestorRect.y) + rect.height) > ancestorRect.height
  }
}

function InternalFieldsDropdown(props) {
  const { field_position, show, onClose } = props
  const { id } = field_position
  const dispatch = useDispatch()
  const fields = useSelector(state => [field_position.fields].flat().map(id => state.form_fields.fields[id]))
  const formInstance = useSelector(state => state.form.form)
  const instanceId = useSelector(({form: state}) => state.form.id)
  const editableFields = fields.filter(field => field && field.editable !== false)

  const groups = useFieldsByParties(field_position)

  const unlinked = useSelector(({form_fields: state}) => state.unlinkedValues[id] != null)
  const unlinkedValue = useSelector(({form_fields: state}) => state.unlinkedValues[id])
  const renderedValue = useRenderedPositionValue(field_position)
  const container = useRef()
  const [ overflows, setOverflows ] = useState({x: false, y: false})

  const { updates } = useContext(FieldPositionContext)

  useEffect(() => {
    if (show)
      return

    dispatch(setPositionUpdating({id, updating: true}))
    save({ instance_id: instanceId, updates, unlinkedValues: { [id]: unlinkedValue } }).then(() => {
      dispatch(updateValues(updates))
      onClose()
    }).finally(() => {
      dispatch(setPositionUpdating({id, updating: false}))
    })
  }, [ instanceId, show, onClose, updates, unlinkedValue ])

  useEffect(() => {
    if (!container.current)
      return

    setOverflows(getElementOverflow(container.current))

    const resetOverflows = () => setOverflows(getElementOverflow(container.current))
    window.addEventListener('resize', resetOverflows)
    return () => window.removeEventListener('resize', resetOverflows)
  }, [container.current, setOverflows])

  const linkField = useCallback(() => {
    save({ instance_id: formInstance.id, unlinkedValues: { [id]: null} }).then(() => {
      dispatch(setUnlinkedValue({ id, value: null }))
    })
  }, [ instanceId, id ])

  const unlinkField = useCallback(() => {
    save({ instance_id: formInstance.id, unlinkedValues: { [id]: renderedValue} }).then(() => {
      dispatch(setUnlinkedValue({ id, value: renderedValue }))
    })
  }, [ instanceId, id, renderedValue ])

  const classes = compact([
    "fields-dropdown",
    `${editableFields.length}-fields`,
    props.className,
    overflows.x ? 'overflow-x' : null,
    overflows.y ? 'overflow-y' : null
  ]).join(" ")

  const style = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '0.5px solid #DBDBDB',
    boxShadow: '2px 2px 7px #00000033',
    borderRadius: 6,
    font: 'normal normal normal 12px/17px Open Sans',
  }

  if (!props.show) {
    style.opacity = 0
    style.transition = "opacity 0s"
  }

  return <div ref={container} className={classes} style={style}>
      <div className="fields">
        <Label field_position={field_position} unlinked={unlinked} />
        { unlinked ? null : Object.entries(groups).map(([group_id, group]) => <Group key={group_id} group={group} />) }
        <hr className="heavy" />
      </div>
      {unlinked ?
        <LinkingButton onClick={linkField} icon="link-simple"> Re-Link Field</LinkingButton> :
        <LinkingButton onClick={unlinkField} icon="link-simple-slash"> Unlink Field</LinkingButton> }
    </div>
}

function DropdownContainer(props) {
  const { show, field_position } = props
  const [internalShow, setInternalShow] = useState(show)

  useEffect(() => {
    if (show)
      setInternalShow(true)
  }, [ show, setInternalShow ])

  return internalShow ? (
    <FieldPositionProvider field_position={field_position}>
      <InternalFieldsDropdown {...props} onClose={() => setInternalShow(false)} />
    </FieldPositionProvider>
  ) : null
}

export const FieldsDropdown = React.memo(DropdownContainer)
