import React, { useEffect, useLayoutEffect, useRef } from "react"
import SignatureCanvas from "react-signature-canvas"

export default function SignaturePad({
  isSignatureEmpty,
  setIsSignatureEmpty,
}) {
  const containerRef = useRef(null)
  const canvasRef = useRef(null)

  useLayoutEffect(() => {
    trackSize()
    setTimeout(trackSize, 500)
    window.addEventListener("resize", trackSize)

    return () => {
      window.removeEventListener("resize", trackSize)
    }
  }, [])

  useEffect(() => {
    if (!canvasRef.current) return

    if (isSignatureEmpty) {
      canvasRef.current.clear()
    }
  }, [isSignatureEmpty])

  const trackSize = () => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current.getCanvas()
    canvas.height = canvas.offsetHeight || containerRef.current.offsetHeight
    canvas.width = canvas.offsetWidth || containerRef.current.offsetWidth
  }

  const handleSignatureChange = () => {
    return setIsSignatureEmpty(canvasRef.current.isEmpty())
  }

  return (
    <>
      <div className="draw-signature" ref={containerRef}>
        <SignatureCanvas ref={canvasRef} onEnd={handleSignatureChange} />
      </div>
    </>
  )
}
