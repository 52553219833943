import React from 'react'

function MessageTab(props) {
  const style = {width: '100%', height: '150px'}
  const onChange = e => props.updateMessage(props.collaborator, e.target.value)

  return <ul>
    <pre>
      <textArea style={ style } onChange={onChange}>
          {props.collaborator.message}
      </textArea>
    </pre>
  </ul>
}

export default MessageTab
