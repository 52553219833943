import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setBuilding, setStructure, setUnit, setCurrent } from './state'

import BuildingSearch from 'components/utility/building_search'
import { startsWith } from 'lodash'
import { useClasses } from 'lib/utilities/react'
import { useWatchableRef } from 'lib/hooks'

const itemWithId = (items, id) => items.filter(item => item.id == id)[0]

function AssocitionSelection({ singleBuilding = false }) {
  const dispatch = useDispatch()
  const building = useSelector(state => state.building)
  const structure = useSelector(state => itemWithId(state.building_structures, state.selected_structure))
  const unit = useSelector(state => state.selected_unit)

  const canSelect = !!building

  const handleDataSet = useCallback(() => {
    if (!canSelect)
      return

    dispatch(setCurrent({ building, structure, unit }))
  }, [dispatch, building, structure, unit, canSelect])

  const buttonClasses = useClasses([
    'btn', 'btn-primary',
    canSelect ? null : 'disabled'
  ])

  return <div className="association-selection">
    <Building singleBuilding={singleBuilding} />
    <BuildingStructures />
    <BuildingUnits />
    <div className="buttons">
      <button disabled={!canSelect} className={buttonClasses} onClick={handleDataSet}>Set Data Source</button>
    </div>
  </div>
}

function Building({ singleBuilding = true }) {
  const building = useSelector(state => state.building)
  const dispatch = useDispatch()
  const selectBuilding = useCallback(selected => {
    if (building?.id == selected?.id)
      return

    dispatch(setBuilding({ 
      building: selected, 
      building_structures: selected.building_structures,
      building_structure: null, 
      building_unit: null 
    }))

  }, [building, dispatch])

  const display = useMemo(() => (
    <div className="display-building">
      {building.title} ({building.address}, {building.city})
    </div>
  ), [building])

  return <div>
    <div className="title">Building</div>
    { building ? display : null }
  </div>
}

function BuildingStructures() {
  const dispatch = useDispatch()
  const structures = useSelector(state => state.building_structures)
  const current = useSelector(state => state.selected_structure)

  const updateStructure = useCallback(evt => {
    const selected_structure = evt.target.value
    dispatch(setStructure({selected_structure}))    
  }, [current, structures])

  if (structures.length <= 1)
    return null

  return <div>
    <div className="title">Structure / Address</div>
    <select value={current} onChange={updateStructure}>
      { structures.map (structure => <option value={structure.id} key={structure.id}>{structure.name}</option>) }
    </select>
  </div>
}

function BuildingUnits() {
  const dispatch = useDispatch()
  const units = useSelector(state => state.building_units || [], shallowEqual)
  const selected_unit = useSelector(state => state.selected_unit)
  const [query, setQuery] = useState(selected_unit)
  const ref = useWatchableRef()
  const [ focused, setFocused ] = useState(false)

  useEffect(() => {
    const listener = evt => setFocused(ref.current.contains(evt.target))

    window.addEventListener('click', listener)
    return () => window.removeEventListener('click', listener)
  }, [ref.version, setFocused])

  const persistUnit = useCallback(unit => {
    dispatch(setUnit(unit))
    setQuery(unit)
  }, [dispatch, setQuery])

  useEffect(() => {
    if (query == selected_unit)
      return

    const timeout = setTimeout(() => persistUnit(query), 400)
    return () => clearTimeout(timeout)
  }, [query, selected_unit, persistUnit])
 
  return <div ref={ref} className="unit">
    <div className="title">Unit</div>
    <div className="has-dropdown">
      <input type="text" onChange={evt => setQuery(evt.target.value)} value={query || ""} />
      { focused ? <SuggestionArea query={query} items={units} onSelect={unit => persistUnit(unit)} /> : null }
    </div>
    { selected_unit ? <button className="btn btn-sm" onClick={() => persistUnit('')}>Clear Unit</button> : null }
  </div>
}

function SuggestionArea({ items, onSelect, query }) {
  const [open, setOpen] = useState(false)
  const [lastSelected, setLastSelected] = useState(null)
  const visibleItems = useMemo(() => (items || []).filter(item => startsWith(item, query)), [items, query])

  useEffect(() => {
    if (lastSelected == query)
      return

    setOpen(true)
  }, [query, items, setOpen, lastSelected])

  const handleClick = useCallback(item => {
    setOpen(false)
    setLastSelected(item)
    onSelect(item)
  }, [setOpen, onSelect, setLastSelected])

  if (!open || visibleItems.length == 0)
    return null

  return <div className="dropdown">
    { visibleItems.map(item => <div key={item} className={query == item ? "current" : null} onClick={() => handleClick(item)}>{item}</div>) }
  </div>
}

export default AssocitionSelection