import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import List from './List'
import { selectBuildingEditAdditionalAddressesState, setInitialState } from '../store/slice'
import NewStructure from 'components/buildings/edit/NewStructure'
import { openStructureEditModal as openStructureEditModalAction } from 'components/buildings/edit/StructureEdit/store/slice'
import StructureEdit from 'components/buildings/edit/StructureEdit'
import { openStructureArchiveModal as openStructureArchiveModalAction } from 'components/buildings/edit/StructureArchive/store/slice'
import StructureArchive from 'components/buildings/edit/StructureArchive'

export default function AdditionalAddresses(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setInitialState(props))
  }, [dispatch])

  const { building, building_structures } = useSelector(selectBuildingEditAdditionalAddressesState)

  const onStructureEditModalOpen = useCallback(
    (building_structure) => {
      dispatch(openStructureEditModalAction({ building, building_structure }))
    },
    [dispatch, building]
  )

  const onStructureArchiveModal = useCallback(
    (building_structure) => {
      dispatch(openStructureArchiveModalAction({ building, building_structure }))
    },
    [dispatch, building]
  )

  if (!building) return null

  return (
    <div className="building-edit-additional-addresses-wrapper">
      <div className="building-edit-additional-addresses-wrapper__header">
        <span>additional addresses</span>
        <NewStructure building={building} />
      </div>
      <List
        building_structures={building_structures}
        onStructureEditModalOpen={onStructureEditModalOpen}
        onStructureArchiveModalOpen={onStructureArchiveModal}
        archiveVisible={props.archive_visible}
      />
      <StructureEdit />
      <StructureArchive />
    </div>
  )
}
