import React from 'react'

import RemoveCollaboratorButton from './remove_collaborator_button'

class ShowAllTab extends React.Component {
  constructor(props) {
    super(props)
    this.renderOptionStyle = this.renderOptionStyle.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  renderOptionStyle () {
    return (this.props.collaborator.attached ? {fontWeight: 'bold', color: 'orange'} : {fontWeight: 'bold'})
  }

  handleRemoveClick () {
    this.props.changeTab("REMOVETAB")
  }

  render () {
    var hrStyle = {
      marginTop: '10px',
      marginBottom: '10px',
      borderBottom: '1px solid black'
    }
    let { userMetadata } = this.props.collaborator
    return (
      <div className='default-tab'>
        <span style={{marginBottom: '0px', paddingTop: '10px'}}>Visibility Options</span>
        <hr style={{marginBottom: '10px', borderBottom: '1px solid black', marginTop:'0px'}}></hr>
        <ul style={{listStyle: 'none', paddingLeft: '0px', paddingBottom: '15px'}}>
          <li style={this.renderOptionStyle()}>
            <input
              type='checkbox'
              checked={true}
              >
            </input>
            <span>Show All</span>
          </li>
        </ul>
        <p style={{paddingBottom: '15px'}}>
          Note: {userMetadata.section}s must be able to see all documents and requirements
        </p>
            {
              this.props.collaborator.attached
              ? <RemoveCollaboratorButton
                handleRemoveClick={this.handleRemoveClick}
                hrStyle={hrStyle}
              /> : <hr style={hrStyle}></hr>
            }
      </div>
    )
  }
}

export default ShowAllTab
