import React, { Component } from "react"


function formatDate(timeStamp) { 
  const options = { 
    year: "numeric", 
    month: "numeric", 
    day: "numeric"
  }

  return new Date(timeStamp).toLocaleDateString(undefined, options)
}

export default class PendingPaymentRequest extends Component {
  constructor(props) {
    super(props)

  }

  renderPaid() {
    const { price } = this.props

    const text = price == 0 ? "The payment for this questionnaire was waived." : `Payment: $${price} has been paid.`

    return <div>
      <h6 className="text-left" >{text}</h6> 
    </div>
  }

  renderPendingPayment() {
    const { price, request, autoPricing, respondedAt } = this.props
    const hasSetPrice = price !== null

    if (price == null)
      return null

    return <div>
      <h6 className="text-left">Payment: ${hasSetPrice ? price : request.price} is due.</h6>
      <small><i className="far fa-clock" />{ autoPricing ? "Price was auto set" : `Set at ${formatDate(respondedAt)}`}</small>
    </div>
  }

  renderButtons() {
    if (this.props.request.is_finished || this.props.request.paid )
      return null

    return <button type="submit" className="btn btn-secondary" onClick={() => this.props.reset()} >Reset Price</button>
  }

  render() {
    return(
        <div className="questionnaire-reset-btn-dev">
          { this.props.request.needs_payment ? this.renderPendingPayment() : this.renderPaid() } 
          { this.renderButtons() }
        </div>
    )
  }
};
