import React, { useCallback, useState } from 'react'

const useMultiControlledInput = (initialValue = "") => {
  const [values, setValues] = useState(initialValue)

  const handleChange = useCallback(
    e => {
      setValues(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    },
    [setValues]
  )

  return [values, setValues, handleChange]
}

export default useMultiControlledInput
