import React from 'react'

function NeedsSave({ onSaveAll }) {
  return (
    <div id="alert-needs-save" className="alert alert-warning container row">
      <div className="col-xs-12">
        <p className="pull-left">Looks like you've made some changes...</p>
        <button className="btn btn-default pull-right" onClick={onSaveAll}>
          Save
        </button>
      </div>
    </div>
  )
}

export default NeedsSave
