import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import ContactDetails from './Form/ContactDetails'
import RolesAndPermissions from './Form/RolesAndPermissions'
import RequirementVisibility from './Form/RequirementVisibility'
import { submit, updateVisibleRequirements } from './api'
import Store, { actions } from './Store'

const useSubmit = packageId => {
  const { addUser, updateUserVisibleRequirements } = actions
  const { mode } = useSelector(state => state.form)
  const latestFormData = Store.getState().form.formData
  const dispatch = useDispatch()

  const currentSentAt = () => {
    const date = new Date()
    const ampm = date.getHours() >= 12 ? 'pm' : 'am'
    let hours = date.getHours()
    hours = hours > 12 ? hours - 12 : hours

    return `${hours}:${date.getMinutes()} ${ampm}, ${date.getMonth()}/${date.getDate()}/${date.getYear()}`
  }

  const submitAsync = async () => {
    switch (mode) {
      case 'create': {
        await submit(packageId, latestFormData)
        const role = latestFormData.roles.participant && latestFormData.roles.viewer ? 'participant_and_viewer' : latestFormData.roles.participant ? 'participant' : 'viewer'

        dispatch(addUser({ ...latestFormData, role: role, sent_at: currentSentAt() }))

        $(function () { $('[data-toggle="tooltip"]').tooltip() })
        break
      }
      case 'edit_requirements': {
        await updateVisibleRequirements(packageId, latestFormData)
        dispatch(updateUserVisibleRequirements(latestFormData))

        break
      }
    }
  }

  return { submitAsync }
}

export default function Form(props) {
  const { setCurrentStep, setFormData, prevStep, jumpToStepName, setVisibleRequirements } = actions
  const { currentStepIndex, formData, mode, steps, submitStep } = useSelector(state => state.form)
  const { register, setValue, handleSubmit, reset, getValues, formState: { isValid, errors } } = useForm({
    defaultValues: {
      email: formData.email,
      first_name: formData.first_name,
      last_name: formData.last_name,
      requirement_visibility: formData.requirement_visibility
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const dispatch = useDispatch()
  const { submitAsync } = useSubmit(props.packageId)

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    reset(formData)
  }, [formData])

  useEffect(() => {
    if (submitting) {
      submitAsync()
      setSubmitting(false)
    }
  }, [submitting])

  const handlePrevStep = () => {
    dispatch(prevStep())
  }

  const updateFormData = (data) => {
    switch (steps[currentStepIndex]) {
      case 'requirements':
        return dispatch(setVisibleRequirements(data.requirement_visibility))
      default:
        return dispatch(setFormData({ ...formData, ...data }))
    }
  }

  const onSubmit = (data) => {
    if (submitStep !== steps[currentStepIndex]) {
      updateFormData(data)
      dispatch(setCurrentStep(currentStepIndex + 1))
    } else {
      updateFormData(data)
      setSubmitting(true)
      dispatch(setCurrentStep(currentStepIndex + 1))
    }
  }

  const currentStepContents = () => {
    if (submitting)
      return renderSubmitting()

    switch (steps[currentStepIndex]) {
      case 'contact-details':
        return <ContactDetails register={register} contactTitles={props.contactTitles} close={props.close} isValid={isValid} errors={errors} getValues={getValues} />
      case 'roles-and-permissions':
        return <RolesAndPermissions register={register} close={props.close} />
      case 'requirements':
        return <RequirementVisibility register={register} setValue={setValue} close={props.close} requirementsAndDocuments={props.requirementsAndDocuments} />
      case 'review':
        return renderReviewChoices()
      case 'success':
        return renderSuccess()
    }
  }

  const renderSubmitting = () => {
    return <div>Submitting...</div>
  }

  const renderReviewChoices = () => {
    const permissionsReview = () => {
      const permissionsList = []
      if (formData.roles?.participant)
        permissionsList.push(<div key='participant'>
          <div><b>Participant</b></div>
          <div>May sign</div>
        </div>)

      if (formData.roles?.viewer)
        permissionsList.push(<div key='viewer'>
          <div><b>Viewer</b></div>
          <div>May view compiled application + comment.</div>
        </div>)

      return permissionsList
    }

    return <div className='review-choices-container'>
      {/* Contact info */}
      <div className='review-section'>
        <div className='review-section-header'>
          <span>Contact Info</span>
          <span style={{ float: 'right' }}>
            <i className='fa fa-pencil bopa-orange' onClick={() => dispatch(jumpToStepName('contact-details'))} />
          </span>
        </div>
        <div className='review-section-body'>
          <div>{formData.first_name} {formData.last_name}</div>
          <div>{formData.title}</div>
          <div>{formData.email}</div>
          <div>{formData.phone_number} {formData.phone_number_ext ? `EXT ${formData.phone_number_ext }`: ''}</div>
        </div>
      </div>

      {/* Permissions */}
      <div className='review-section'>
        <div className='review-section-header'>
          <span>Permissions</span>
          <span style={{ float: 'right' }}>
            <i className='fa fa-pencil bopa-orange' onClick={() => dispatch(jumpToStepName('roles-and-permissions'))} />
          </span>
        </div>
        <div className='review-section-body'>
          { permissionsReview() }
        </div>
      </div>
      <br />
      <button type='button' className='btn btn-default' onClick={handlePrevStep}>Back</button>
      <button type="submit" className='btn btn-success pull-right'>Share</button>
    </div>
  }

  const renderSuccess = () => {
    switch (mode) {
      case 'create':
        return createSuccess()
      case 'edit_requirements':
        return editRequirementSuccess()
    }
  }

  const createSuccess = () => {
    return <>
      <div>{formData.first_name} {formData.last_name} was notified to review Application</div>
      <div><b>Note</b>: You may update access to requirements by selecting <i className='fa fa-user-plus bopa-orange' />, then <b>Manage Access</b>.</div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <button type="button" className='btn btn-default' onClick={props.close}>Close</button>
      </div>
    </>
  }

  const editRequirementSuccess = () => {
    return <div className='text-center'>
      <div>Document visibility has been updated.</div>
      <br />
      <div>
        <button type="button" className='btn btn-default' onClick={props.close}>Close</button>
      </div>
    </div>
  }

  const headerText = () => {
    switch (steps[currentStepIndex]) {
      case 'contact-details':
        return <h3 style={{ textAlign: 'center' }}>New Contact Details</h3>
      case 'roles-and-permissions':
        return <div style={{ textAlign: 'center' }}>
          <h3>Role + Permissions</h3>
          <span>{formData.first_name} {formData.last_name}</span>
        </div>
      case 'requirements':
        return <div>
          <div className='share-forward'></div>
          <h3>Document Visibility</h3>
          <span>{formData.first_name} {formData.last_name}</span>
        </div>
      case 'review':
        return <h3>Confirm Choices</h3>
      case 'success': {
        const text = mode == 'edit_requirements' ? 'Requirement Visibilility Updated!' : 'Application Shared!'
        return <div>
          <div><i className='fa-thin fa-circle-check' style={{ color: '#56A256', fontSize: 64 }} /></div>
          <h3 style={{ textAlign: 'center' }}>{text}</h3>
        </div>
      }
    }
  }

  return <>
    <div className="modal-header" style={{ textAlign: 'center', borderBottom: 'none' }}>
      { headerText() }
    </div>

    <div className="modal-body">
      <form id='additional-contacts-form' onSubmit={handleSubmit(onSubmit)}>
        {currentStepContents()}
      </form>
    </div>
  </>
}
