import React, { useCallback, useMemo } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import Mustache from 'mustache'
import { uniq, compact, intersection } from 'lodash'
import { useEventManager } from '@deathbyjer/react-event-manager'

import { sortObjectKeys } from 'lib/utilities'
import { valueByPath, checkPermissions } from 'lib/utilities/form'

import { usePermissionsChecks } from '.'

function orderedUniq(arr) {
  const newArr = []
  for (let item of arr)
    if (!newArr.includes(item))
      newArr.push(item)

  return newArr
}

const initialState = {
  deal_parties: {},
  deal_party_groups: {},
  metadata: {},
  group_order: [],
  deal_party_mapping: {},
  deal_party_names: {},

  parties_by_group: {},


  ordered: [],
  selected: null
}

const Store = createSlice({
  name: "deal_parties",
  initialState,
  reducers: {
    loadParties(state, { payload: {deal_parties, deal_party_groups, deal_party_metadata, deal_party_mapping, deal_party_names} }) {
      state.deal_parties = deal_parties
      state.deal_party_groups = deal_party_groups
      state.deal_party_mapping = deal_party_mapping
      state.deal_party_names = deal_party_names

      state.metadata = deal_party_metadata
      state.party_order = sortObjectKeys(deal_party_metadata)
      state.group_order = sortObjectKeys(deal_party_groups).concat([undefined])
      state.parties_by_group = Object.fromEntries(state.group_order.map(group_id => [group_id, []]))

      for(let party_id in deal_parties) 
        state.parties_by_group[deal_parties[party_id].group].push(party_id)

      state.ordered = Object.keys(deal_parties).sort((a,b) => deal_parties[a].order - deal_parties[b].order)
    },

    selectDealParty(state, { payload: deal_party_id }) {
      state.selected = deal_party_id
    }
  }
})

export const { reducer } = Store
export const { loadParties, selectDealParty } = Store.actions

function usePermissions() {
  const userPermissions = useSelector(({global}) => global.user_permissions)

  return checkPermissions(userPermissions, {
    write: ["basic", "roles", "roles_self"]
  })
}

function SidebarParty({id}) {
  const party = useSelector(({deal_parties: state}) => state.deal_parties[id])
  const person_id = useSelector(({deal_parties: state}) => state.deal_party_mapping[id])
  const selected = useSelector(({deal_parties: state}) => state.selected == id)
  const personName = useSelector(({deal_parties: state}) => state.deal_party_names[person_id])

  
  const classes = compact(['sidebar-deal-party', selected ? 'selected' : null])

  return <div className={classes.join(' ')}>
    <div>{ party.label }</div>
    <div>{personName}</div>
  </div>
}

function EditRoleAssignment(props) {
  const permissions = usePermissionsChecks(['form:assign_roles'])
  const events = useEventManager()

  const click = useCallback(evt => {
    evt.preventDefault()
    events.applyEventListeners('openRoleAssignment')
  })

  if (!permissions['form:assign_roles'])
    return

  return <a href="#" className="edit-role-assignment" onClick={click}>
    <i className="fa fa-pencil" aria-hidden="true" />
    <span>Edit Role Assignments</span>
  </a>
}

function Group({group_id, label}) {
  const parties = useSelector(({deal_parties: state}) => state.parties_by_group[group_id])

  if (parties.length == 0)
    return null

  return <>
    <div className="role">
      {label}
    </div>
    <div className="deal-parties">{parties.map(id => <SidebarParty key={id} id={id} />)}</div>
  </>
}

export function Sidebar(props) {
  const group_order = useSelector(({deal_parties: state}) => state.group_order)
  const groups = useSelector(({deal_parties: state}) => state.deal_party_groups)

  return <div className="deal-parties-sidebar">
    <div className="title-area">
      <div className="title">Deal Party Role Assignments</div>
      <EditRoleAssignment />
    </div>

    { group_order.map(group_id => <Group key={group_id || "-"} group_id={group_id} label={groups[group_id]?.label || "Additional"} />)}
  </div>
}