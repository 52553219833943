Components.BuildingSearch.MainSearchHomepage = class extends React.Component {
  constructor(props) {
    super(props)
  }

  redirectToUrl(e) {
   window.location.href = `/buildings/${e.text_url}`
  }
  
  render() {
    return(<Utility.BuildingSearch 
      customApplicationImage={this.props.customApplicationImage}
      onSelect={e => this.redirectToUrl(e)}
      show_magnifying_glass={this.props.show_magnifying_glass}
      show_empty_message={this.props.show_empty_message}
      placeholderText={this.props.placeholderText}
      clickHandlerNameForCustomAppSuggestion="showCustomApplicationModalWithSearchedText"
      classes={this.props.classes}
    />)
    
  }
}