import React from 'react'

const Button = ({id, type, onClick, className, children, ...rest}) => {
  return (
    <button id={id} type={type} onClick={onClick} className={className} {...rest}>
      {children}
    </button>
  )
}

export default Button
