import { useEventManager } from '@deathbyjer/react-event-manager'
import { useCallback, useEffect, useRef, useState } from 'react'

let percentLeftDiffer = 0
let percentBottomDiffer = 0

const useZoomHandler = () => {
  const events = useEventManager()

  const zoomIn = useCallback((center) => handleZoom(true, center), [])
  const zoomOut = useCallback((center) => handleZoom(false, center), [])

  const valueTransfrom = useRef(1)
  const leftPercentage = useRef(0)
  const bottomPercentage = useRef(0)
  const prevRight = useRef(0)
  const prevBottom = useRef(0)

  const wapperElRef = useRef(null)
  const areaElRef = useRef(null)

  useEffect(() => {
    wapperElRef.current = document.querySelector('.document-area-wrapper-body')
    areaElRef.current = document.querySelector('.document-area-wrapper')
  }, [])

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!wapperElRef.current) return
      const wapperEl = wapperElRef.current
      leftPercentage.current =
        ((wapperEl.getBoundingClientRect().width - (wapperEl.getBoundingClientRect().right - e.clientX)) /
          wapperEl.getBoundingClientRect().width) *
        100
      bottomPercentage.current =
        ((wapperEl.getBoundingClientRect().height - (wapperEl.getBoundingClientRect().bottom - e.clientY)) /
          wapperEl.getBoundingClientRect().height) *
        100

      prevRight.current = wapperEl.getBoundingClientRect().right
      prevBottom.current = wapperEl.getBoundingClientRect().bottom
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  // Handle zoom on mouse wheel
  useEffect(() => {
    const areaEl = areaElRef.current
    if (!areaEl) return
    const handleMouseZoom = (event) => {
      if (event.ctrlKey || event.shiftKey) {
        if (event.deltaY < 0) {
          zoomOut(false)
        } else if (event.deltaY > 0) {
          zoomIn(false)
        }
      }
    }
    areaEl.addEventListener('wheel', handleMouseZoom)
    return () => {
      areaEl.removeEventListener('wheel', handleMouseZoom)
    }
  }, [zoomIn, zoomOut])

  const getPercentPosition = useCallback((center) => {
    if (!center) return [leftPercentage.current, bottomPercentage.current, 200]
    const wapperEl = wapperElRef.current
    const areaEl = areaElRef.current
    const clientXAtCenter = wapperEl.getBoundingClientRect().width / 2
    const clientYAtCenter =
      wapperEl.getBoundingClientRect().bottom -
      (window.innerHeight - areaEl.getBoundingClientRect().top) / 2 -
      areaEl.getBoundingClientRect().top

    return [
      ((wapperEl.getBoundingClientRect().width - clientXAtCenter) / wapperEl.getBoundingClientRect().width) * 100,
      ((wapperEl.getBoundingClientRect().height - clientYAtCenter) / wapperEl.getBoundingClientRect().height) * 100,
      100,
    ]
  }, [])

  const handleZoom = useCallback(
    (increase, center) => {
      const wapperEl = wapperElRef.current
      const areaEl = areaElRef.current
      if (!wapperEl || !areaEl) return
      const [leftPercent, rightPercent, proportion] = getPercentPosition(center)

      if (increase) {
        valueTransfrom.current += 0.1
        wapperEl.style.transform = `scale(${valueTransfrom.current})`

        if (percentLeftDiffer === 0 && percentBottomDiffer === 0) {
          percentLeftDiffer = wapperEl.getBoundingClientRect().right - prevRight.current
          percentBottomDiffer = wapperEl.getBoundingClientRect().bottom - prevBottom.current
        }

        areaEl.scrollLeft += (percentLeftDiffer * leftPercent) / proportion
        areaEl.scrollTop += (percentBottomDiffer * rightPercent) / proportion
      } else {
        if (valueTransfrom.current === 1) return
        valueTransfrom.current -= 0.1
        wapperEl.style.transform = `scale(${valueTransfrom.current})`

        areaEl.scrollLeft -= (percentLeftDiffer * leftPercent) / proportion
        areaEl.scrollTop -= (percentBottomDiffer * rightPercent) / proportion
      }
      events.applyEventListeners('resizeSelf')
    },
    [events, getPercentPosition]
  )

  return { zoomIn, zoomOut }
}

export default useZoomHandler
