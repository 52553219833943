import React from 'react'
import PropTypes from 'prop-types'
import MultiSelect from 'components/utility/multi_select'

export default class AccountFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  renderAccountMultiSelect() {
    const options = {}
    this.props.accounts.forEach(account => options[account["id"]] = account["account_description"])

    return <MultiSelect
      max="99"
      name="filters[account][]"
      defaultValue={this.props.selectedAccounts}
      ref="account_select"
      options={options}
      placeholder=" -- Select accounts --" />
  }

  render() {
    return <div className="account-filter">
      {this.renderAccountMultiSelect()}
    </div>
  }
}

AccountFilter.propTypes = {
  selectedAccounts: PropTypes.array,
  accounts: PropTypes.array
}

AccountFilter.defaultProps = {
  selectedAccounts: []
}
