import React from 'react'

const Field = props => {
  const { register, fieldType, fieldName, required, placeholder, style, select: { selectItemArray, hasEmptyOption, emptyOptionText } = props } = props

  const FieldByFieldType = () => {
    switch (fieldType) {
      case 'select':
        return <select {...register(fieldName, { select: true, defaultValue: '', required: required })}
          className='form-control'
          style={style}>
            { hasEmptyOption ? <option value=''>{emptyOptionText}</option> : null }
            {
              selectItemArray.map(item => <option key={item} value={item}>{item}</option>)
            }
        </select>
      case 'email':
        return <input {...register(fieldName, {
          required: required,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Please enter a complete email address.",
          }})}
          type='text'
          placeholder={placeholder}
          className='form-control'
          style={style}
          autoFocus
        />
      default:
        return <input {...register(fieldName, { required: required })}
          type={fieldType}
          placeholder={placeholder}
          className='form-control'
          style={style}/>
    }
  }

    return <FieldByFieldType />
}

export default Field
