import React from 'react'
import { isEmpty } from 'lodash'

import SignatureView from 'components/signatures/image'

export default function SignatureImage({ signature, onClear }) {
  if (isEmpty(signature)) return null

  return (
    <>
      <div className="draw-signature">
        <div className="line" />
        <SignatureView signature={signature} />
        {typeof onClear === 'function' && (
          <>
            <i
              className="fa-light fa-xmark dome-color-red-alert clear-signature-x-mark"
              role="button"
              onClick={onClear}
            />
          </>
        )}
      </div>
    </>
  )
}
