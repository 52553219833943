export const ContractorFields = [
  {
    label: 'contractor’s full name*',
    name: 'contractor.full_name',
    validation: {
      required: 'required',
    },
    errorMessage: ({ errors }) => errors?.contractor?.full_name?.message,
  },
  {
    label: 'contractor’s business name*',
    name: 'contractor.business_name',
    validation: {
      required: 'required',
    },
    errorMessage: ({ errors }) => errors?.contractor?.business_name?.message,
  },
  {
    label: 'contractor’s license number',
    name: 'contractor.license_number',
  },
]

export const RequesterFields = [
  {
    label: 'your name*',
    name: 'requester.full_name',
    validation: {
      required: 'required',
    },
    errorMessage: ({ errors }) => errors?.requester?.full_name?.message,
  },
  {
    label: 'your email*',
    name: 'requester.email',
    validation: {
      required: 'required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid Email. Please try again.',
      },
    },
    errorMessage: ({ errors }) => errors?.requester?.email?.message,
  },
  {
    label: 'confirm email*',
    name: 'requester.confirm_email',
    validation: ({ watch }) => {
      const email = watch('requester.email')
      return {
        required: 'Please confirm your email',
        validate: (value) => value === email || 'The emails do not match',
      }
    },
    errorMessage: ({ errors }) => errors?.requester?.confirm_email?.message,
  },
  {
    label: 'your phone number',
    name: 'requester.phone_number',
  },
]

export const ContractorReportFileds = {
  contractor: {
    title: 'contractor info',
    name: 'contractor',
    fields: ContractorFields,
  },
  requester: {
    title: 'about you',
    name: 'requester',
    fields: RequesterFields,
  },
}
